<template>
  <list-page
    :loadStatus="loadStatus"
    :error="error"
    :numRows="numRows"
    title="Culturas"
    subTitle="Lista de culturas para manejo de irrigação."
    
    @load-retry="loadData()"
  >

  <!-- :backgroundImage="require('./assets/background.jpg')" -->
    <template v-slot:button>
      <b-button
        v-if="$can('create', 'Culture')"
        class="shadow-sm"
        pill
        @click="newCulture"
        variant="success"
        >+ nova</b-button
      >
    </template>

    <!---------------- LISTA ----------------------------------->
    <template v-slot:list="props">
      <cultureTableList
        :per-page="props.perPage"
        :current-page="props.currentPage"
        :cultureList="cultureList"
        :filter="props.filter"
        v-on:removeculture="removeCulture($event)"
        v-on:editculture="editCulture($event)"
        v-on:copyculture="copyCulture($event)"
        v-on:onnumrows="onNumRows($event)"
      />

      <router-view
        :cultureList="cultureList"
        v-on:update:culture="updateList($event)"
      ></router-view>
    </template>
  </list-page>
</template>

<script>
//usado apenas para testes. comente esta linha caso não esteja testando
//import dataTeste from "./testes/data.json";

import cultureTableList from "./cultureTableList";


export default {
  data() {
    return {
      cultureList: [],
      loadStatus: "loading",
      error : undefined,
      numRows: 1,
    };
  },
  beforeMount() {
    this.loadData();
  },
  components: {
    cultureTableList,
  },
  methods: {
    loadData() {
      this.loadStatus = "loading";
      this.$http.get("irrig/culture").then(
        (data) => {
          this.cultureList = data;
          this.loadStatus = "loaded";
          this.numRows = this.cultureList.length;
        },
        (error) => {
          console.error("culture error:", error);
          this.loadStatus = "error";
          this.error = error;
        }
      );
    },

    onNumRows(numrows) {
      this.numRows = numrows;
    },

    //atualiza os valores do solo da lista a partir de um novo solo
    updateList(newCulture) {
      const index = this.cultureList.findIndex((e) => e.id === newCulture.id);
      if (index === -1) {
        this.cultureList.push(newCulture);
        this.numRows = this.cultureList.length;
      } else {
        let dataObj = this.cultureList[index];
        Object.entries(newCulture).forEach(([key, value]) => {
          dataObj[key] = value;
        });
      }
    },

    editCulture(id) {
      this.$router.push({ name: "cultureEdit", params: { id: id } });
    },

    removeCulture(id) {
      this.$bvModal
        .msgBoxConfirm(
          "Deseja realmente apagar este registro permanentemente?",
          {
            title: "Por favor confirme",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "Sim",
            cancelTitle: "Não",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value === true) {
            this.$http.delete("irrig/culture/" + id).then(
              () => {
                //var data
                let index = this.cultureList.findIndex((e) => e.id === id);
                this.cultureList.splice(index, 1);
                this.numRows = this.cultureList.length;
              },
              (error) => {
                console.error("delete culture error", error);
                //var err
                //API error
              }
            );
          }
        });
    },

    copyCulture(id) {
      this.$http.get("irrig/culture/" + id + "/copy").then(
        (data) => {
          this.updateList(data);
          this.$router.push({ name: "cultureEdit", params: { id: data.id } });
        },
        (error) => {
          console.error("copy culture error", error);
          //API error
        }
      );
    },

    newCulture() {
      this.$router.push({ name: "cultureNew" });
    },
  },
};
</script>

