<template>
  <div >
    <vl-layer-vector :z-index="zIndex">
      <vl-source-vector
        :features.sync="drawnFeatures"
         :ident="sourceIdent"
      ></vl-source-vector>
      <vl-style-box v-if="imgPoint">
        <vl-style-icon
          :scale="1"
          :anchor="[0.5, 1]"
          :size="[40, 40]"
          :src="imgPoint"
        ></vl-style-icon>
      </vl-style-box>
    </vl-layer-vector>
    <vl-interaction-modify  :source="sourceIdent"></vl-interaction-modify>
    <vl-interaction-draw
      :max-points="1"
      :source="sourceIdent"
      type="Point"
    ></vl-interaction-draw>
    <vl-interaction-snap
      :source="sourceIdent"
      :priority="10"
    ></vl-interaction-snap>


    <div class="editPanel d-flex ">
         <ValidationProvider
        slim
        vid="ele"
        name="elevação"
        :rules="{ required: true, between: [0, 10000] }"
        v-slot="v"
      >
        <b-form-group  label="Elevação" class="col-sm-4">
          <b-input-group size="sm" append="m" :state="$validateState(v)">
            <b-form-input
              size="sm"
              name="location.ele"
              v-model="location.ele"
              type="number"
              :state="$validateState(v)"
            ></b-form-input>
          </b-input-group>
          <span class="invalid-input">{{ v.errors[0] }}</span>
        </b-form-group>
      </ValidationProvider>

      <!-- LATITUDE -->
      <ValidationProvider
        slim
        vid="lat"
        name="latitude"
        :rules="{ required: true, between: [-90, 90] }"
        v-slot="v"
      >
        <b-form-group label="Latitude" class="col-sm-4">
          <b-input-group size="sm" append="º" :state="$validateState(v)">
            <b-form-input
              size="sm"
              name="location.lat"
              v-model="lat"
              type="number"
              :state="$validateState(v)"
            ></b-form-input>
          </b-input-group>
          <span class="invalid-input">{{ v.errors[0] }}</span>
        </b-form-group>
      </ValidationProvider>

      <!-- LONGITUDE -->
      <ValidationProvider
        slim
        vid="long"
        name="longitude"
        :rules="{ required: true, between: [-180, 180] }"
        v-slot="v"
      >
        <b-form-group label="Longitude" class="col-sm-4">
          <b-input-group size="sm" append="º" :state="$validateState(v)">
            <b-form-input
              size="sm"
              name="location.lon"
              v-model="lon"
              type="number"
              :state="$validateState(v)"
            ></b-form-input>
          </b-input-group>
          <span class="invalid-input">{{ v.errors[0] }}</span>
        </b-form-group>
      </ValidationProvider>
    </div>



  </div>
</template>

<script>
import makeId from '@/utils/makeId'

export default {
  data() {
    return {
      // position: [],
      sourceIdent:"",
      drawnFeatures: [],
      location:undefined,
      lat:undefined,
      lon:undefined,
    };
  },

  props: {
    value: { type: Object, default: function() { return { ele: 0, lat: 0, lon: 0 };}},
    zIndex: { type: Number, default: 3 },
    imgPoint:{type:String,default:undefined}
  },
  
  beforeMount(){
    this.sourceIdent = "position-source-"+makeId(6);
    this.location = Object.assign({}, this.value);
    this.lat = this.location.lat;
    this.lon = this.location.lon;

    this.drawnFeatures = [{
        type: "Feature",
        geometry: {type:"Point",coordinates:[this.lon,this.lat]},
        id: "31ac4169-c545-4764-8b7b-baba933203e7"

    }]
  },

  watch: {
    drawnFeatures(v) {
      
      if(v.length>1){
          // console.log("max",v)
          let obj = v[v.length-1]
          this.drawnFeatures = []
          this.drawnFeatures.push(obj)
          return
      }
    
      let pos = v[0].geometry.coordinates
      this.location.lon = pos[0]
      this.location.lat = pos[1]

      while (this.location.lon > 180)
        this.location.lon -= 360
      while (this.location.lon < -180)
        this.location.lon += 360


      this.lat = this.location.lat
      this.lon = this.location.lon
      this.$emit("input",this.location)
    },

    lat(v){
    this.location.lat = Number(v)
    this.$emit("input",this.location);
    this.drawnFeatures[0].geometry.coordinates[1] = this.location.lat

    },
    lon(v){
      this.location.lon = Number(v)
      this.$emit("input",this.location);
      this.drawnFeatures[0].geometry.coordinates[0] = this.location.lon
    }
  },
};
</script>

<style lang="scss" scoped>

.editPanel{

    max-width: 400px;
    position: absolute;
    top: 0.5em;
    right: 0.5em;
    z-index: 3;
    background-color: #fff;

    .form-group {
        margin-bottom: 0.5rem;
        padding-right: 5px;
        padding-left: 5px;
    }
}

</style>
