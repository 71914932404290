<template>
  <div>
    <div v-if="pageStatus == 'loaded'">
      <validation-observer ref="formObserver">
        <b-row>

          <!-- sensorId -->
          <ValidationProvider
            slim
            vid="datastream.sensorId"
            name="ID"
            :rules="{ required: true}"
            v-slot="v"
          >
            <b-form-group label="sensorId" class="col-sm-4">
              <b-form-input
                name="datastream.sensorId"
                v-model="dataStream.sensorId"
                type="number"
                :state="$validateState(v)"
              ></b-form-input>
              <span class="invalid-input">{{ v.errors[0] }}</span>
            </b-form-group>
          </ValidationProvider>


          <!-- NOME -->
          <ValidationProvider
            slim
            vid="datastream.name"
            name="Nome"
            :rules="{ required: true, min: 3 }"
            v-slot="v"
          >
            <b-form-group label="Nome" class="col-sm-8">
              <b-form-input
                name="datastream.name"
                v-model="dataStream.name"
                type="text"
                maxlength="30"
                placeholder="nome"
                :state="$validateState(v)"
              ></b-form-input>
              <span class="invalid-input">{{ v.errors[0] }}</span>
            </b-form-group>
          </ValidationProvider>

          <!-- Unidade -->
          <ValidationProvider
            slim
            vid="datastream.unit"
            name="Unidade"
            :rules="{ required: false }"
            v-slot="v"
          >
            <b-form-group label="Unidade" class="col-sm-6">
              <b-form-input
                name="datastream.units"
                v-model="dataStream.units"
                type="text"
                maxlength="15"
                :state="$validateState(v)"
              ></b-form-input>
              <span class="invalid-input">{{ v.errors[0] }}</span>
            </b-form-group>
          </ValidationProvider>

          <!-- Simbolo -->
          <ValidationProvider
            slim
            vid="datastream.unitSymbol"
            name="datastream.unitSymbol"
            :rules="{ required: false, min: 1 }"
            v-slot="v"
          >
            <b-form-group label="Símbolo" class="col-sm-6">
              <b-form-input
                name="datastream.unitSymbol"
                v-model="dataStream.unitSymbol"
                type="text"
                maxlength="4"
                :state="$validateState(v)"
              ></b-form-input>
              <span class="invalid-input">{{ v.errors[0] }}</span>
            </b-form-group>
          </ValidationProvider>

          <!-- Valor máximo-->
          <ValidationProvider
            slim
            vid="flowRate"
            name="Max. Valor"
            :rules="{ required: false }"
            v-slot="v"
          >
            <b-form-group label="valor máximo" class="col-sm-6">
              <b-form-input
                name="datastream.maxValue"
                v-model="dataStream.maxValue"
                type="number"
                :state="$validateState(v)"
              ></b-form-input>
              <!-- <b-form-invalid-feedback>{{ v.errors[0] }}</b-form-invalid-feedback> -->
              <span class="invalid-input">{{ v.errors[0] }}</span>
            </b-form-group>
          </ValidationProvider>

          <!-- Valor mínimo-->
          <ValidationProvider
            slim
            vid="flowRate"
            name="Min. Valor"
            :rules="{ required: false }"
            v-slot="v"
          >
            <b-form-group label="valor mínimo" class="col-sm-6">
              <b-form-input
                name="datastream.minValue"
                v-model="dataStream.minValue"
                type="number"
                :state="$validateState(v)"
              ></b-form-input>
              <!-- <b-form-invalid-feedback>{{ v.errors[0] }}</b-form-invalid-feedback> -->
              <span class="invalid-input">{{ v.errors[0] }}</span>
            </b-form-group>
          </ValidationProvider>

          
          <b-form-group label="Tipo" class="col-sm-4">
            <b-form-select
              v-model="dataStream.dataType"
              :options="dataStreamTypes"
              name="datastream.dataType"
            ></b-form-select>
          </b-form-group>

          <div class="col-sm-8">
            <label for="tags-basic">Tags</label>
            <b-form-input v-model="dataStream.tags" input-type="text"></b-form-input>
          </div>

          <!-- {{dataStream.tags}} -->


        </b-row>
      </validation-observer>

      <hr />
      <div class="d-flex">
        <b-button-toolbar class="mr-auto">
          <b-button @click="$emit('chartmode')" variant="secondary"
            >Cancelar</b-button
          >
          <b-button @click="submit()" variant="success">Salvar</b-button>
        </b-button-toolbar>
      </div>
    </div>

    <div v-else-if="pageStatus == 'saving'">
      salvando ...
    </div>

    <c-loading :status="pageStatus" v-else></c-loading>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dataStream: undefined,
      pageStatus: undefined,
      dataStreamTypes: [
        { value: null, text: "Selecione" },
        { value: "line", text: "Linha" },
        { value: "bar", text: "Barra" },
        { value: "location", text: "Localização" },
      ],
    };
  },
  props: {
    dataStreamId: { type: Number, required: true },
  },

  mounted() {
    this.$http.get("/sens/dataStream/" + this.dataStreamId).then(
      (data) => {
        this.dataStream = data;
        this.pageStatus = "loaded";
      },
      (error) => {
        console.log("load datapoints error:", error);
        this.pageStatus = "error";
      }
    );
  },

  methods: {
    submit() {
      this.$refs.formObserver.validate().then((result) => {
        if (!result) {
          this.$bvModal.msgBoxOk("Verifique se todos os dados estão corretos", {
            title: "Erro!",
          });
          return;
        }
        this.pageStatus = "saving";
        this.$http
          .save("/sens/dataStream", this.dataStream, this.dataStream.id)
          .then(
            (data) => {
              this.pageStatus = "loaded";
              this.$emit("updatedatastream", data);
              this.$emit("chartmode");
            },
            (error) => {
              this.pageStatus = "error";
              console.error("save Datastream error", error);
              this.$bvModal.msgBoxOk(
                "Verifique se todos os dados estão corretos",
                { title: "Erro!" }
              );
            }
          );
      });
    },
  },
};
</script>

<style></style>
