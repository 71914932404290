<template>
  <b-container fluid="lg">
    <div class="equipment" v-for="equipment in equipments" :key="equipment.id">
      <div class="equipment-name">
        <b-link :to="{ name: 'equipmentDetails', params: { id: equipment.id } }">{{ equipment.name }}</b-link>
      </div>
      <div>
        <div class="no-field" v-if="equipment.fields === undefined || equipment.fields.length == 0">
          <font-awesome-icon icon="info-circle" />
          Sem parcelas ativas
        </div>

        <div class="field" v-else v-for="field in equipment.fields" :key="field.id">
          <div class="field-name">
            <div>

              <img v-b-tooltip.hover :title="field.culture.name" class="culture-img"
                :src="require('@irrigcomponents/culture/assets/48/' + field.culture.icon + '.png')" />
            </div>
            <b-link :to="{ name: 'fieldDetails', params: { id: field.id } }">{{ field.name }}</b-link>
          </div>

          <!-- DESENHA A PREVISÃO DE IRRIGAÇÃO OU UMA MENSAGEM COM ALERTA -->
          <irrigationSchedule v-if="field.status.dataOK" :field="field" />
          <div class="no-data" v-else>
            <font-awesome-icon class="text-warning" icon="exclamation-triangle" />
            <span> Lançamentos Pendentes. </span>
            <b-link :to="{ name: 'insertData', params: { date: field.status.dateToInsert } }">
              Lançar Dados</b-link>
          </div>


        </div>
      </div>
    </div>
  </b-container>
</template>

<script>
import irrigationSchedule from "../field/schedule";

export default {
  props: {
    equipments: { type: Array, required: true }
  },
  components: {
    irrigationSchedule
  }
};
</script>



<style lang="scss" scoped>
.field {
  display: flex;
  flex-wrap: wrap;


  &:not(:last-child) {
    border-bottom: dotted 2px rgb(199, 207, 206);
    margin-bottom: 10px;
  }

  .field-name {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    padding-right: 10px;
    min-width: 100px;
    position: relative;
    align-items: center;
  }
}

.equipment {
  border-bottom: solid 2px rgb(199, 207, 206);
  margin-bottom: 20px;
  padding: 10px;
  box-shadow: 0 0 8px #a0a0a0;
  border-radius: 5px;
  position: relative;

  .equipment-name {

    min-width: 110px;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #3c4d5a;
    color: white;
    padding: 2px 5px;
    text-align: center;
    border-radius: 5px 0 20px 0;

    a {
      color: white
    }
  }

  .no-field {
    margin-left: 120px;
  }

  .no-data {
    margin-top: 40px;

  }
}

.culture-img {
  height: 30px;
  border-radius: 50%;
  border: solid 1px #888;
  margin-right: 10px;
}
</style>