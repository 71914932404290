<template>
<tbody v-if="dataIsValid">

  <vl-feature  v-if="showLocation" :id="'client-point-'+client.id" :properties="{name:client.name, client: client,onClick:onClick}">
    <vl-geom-point :coordinates="[client.location.lon,client.location.lat]"></vl-geom-point>
    <vl-style-box :z-index="5">
       <vl-style-icon :scale="1" :anchor="[0.5, 1]" :size="[40, 40]"
            :src="require('./assets/clientPoint.png')"  :opacity="client.active?1:0.3"
        ></vl-style-icon>
      </vl-style-box>
  </vl-feature>

  <vl-feature  v-if="showArea"  :id="'client-area-'+client.id" :properties="{client: client}">
     <vl-geom-multi-polygon v-if="geometry!=null" :coordinates="[geometry]"></vl-geom-multi-polygon>
      <vl-style-box :z-index="1">
        <vl-style-stroke color="rgba(250,255,0,0.5)" :width="2" :lineDash="[1,2]"></vl-style-stroke>
         <!-- <vl-style-fill  color="rgba(250,255,0,0.1)" ></vl-style-fill> -->
      </vl-style-box>
  </vl-feature>

  <vl-feature  v-if="showLines"  :id="'client-lines-'+client.id" :properties="{client: client}">
     <vl-geom-multi-line-string v-if="lines!=null" :coordinates="lines"></vl-geom-multi-line-string>
      <vl-style-box :z-index="4">
        <vl-style-stroke color="rgba(250,255,255,0.5)" :width="2" :lineDash="[1,2]"></vl-style-stroke>
         <!-- <vl-style-fill  color="rgba(250,255,0,0.1)" ></vl-style-fill> -->
      </vl-style-box>
  </vl-feature>
      
  
</tbody>
</template>

<script>

export default {
  props: {
    client: {type:Object, required:true},
    showLocation: {type:Boolean, required:false, default:true},
    showArea: {type:Boolean, required:false, default:true},
    showLines: {type:Boolean, required:false, default:true}
  },
  computed: {
    dataIsValid(){
      if(this.client.location == undefined){
          return false
      }else{
        return true
      }
    },
    geometry(){
      if(this.client.geometryArea && this.client.geometryArea.length>0){
        return this.client.geometryArea
      }else
        return null
    },
    lines(){
      if(this.client.geometryLines && this.client.geometryLines.length>0){
        return this.client.geometryLines
      }else
        return null
    }
  },
  methods :{
    onClick(){
      this.$emit("click");
    }
  },
};
</script>

<style lang="css" scoped>
</style>
