<template>
  <v-chart style="width:100%;height:300px;" :option="serie" autoresize />
</template>

<script>
import { DateTime } from "luxon";

var dateFormatter = function(value) {
  let _value = typeof value === "object" ? value.value : value;
  return DateTime.fromISO(_value).toLocaleString({
    month: "2-digit",
    year: "2-digit",
    day: "2-digit",
  });
};

export default {
  props: {
    data: Array,
    unit: String,
  },

  computed: {

    serie: function() {
      let xSerie = this.data.map((item) => 
        item.date
      );

      let volumeSerie = this.data.map((item) => 
        item.volume,
      );

      let goalSerie = this.data.map((item) => 
        item.goal ,
      );

      return {
        grid: [{ right: 0, bottom: 40, top: 25, left: 0, containLabel: true }], 
        tooltip: {
          trigger: "axis",
          axisPointer: { type: "cross", crossStyle: { color: "#999" } },
          valueFormatter: (v) => this.$filters.localeNumber(v,0)+" "+this.unit,
        },

        legend:{
          show: true,
          bottom: 0,
          data:['Volume','Meta']
        },

        xAxis: [
          {
            type: "category",
            data: xSerie,
            axisLabel: {
              formatter: dateFormatter,
            },
            axisPointer: {
              label: {
                formatter: dateFormatter,
              },
            },
          },
        ],

        yAxis: [{
           name: this.unit, 
           type: "value",
           axisLabel: { formatter: (v)=> this.$filters.localeNumber(v,0)},
           }],
        series: [
          {
            name: "Volume",
            data: volumeSerie,
            type: "bar",
            
          },

          {
            name: "Meta",
            data: goalSerie,
            type: "line",
            color: "#af4534",
          },
        ],
      };
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
