<template>
  <b-table-simple hover small caption-top responsive>
    <b-thead head-variant="light">
      <b-tr>
        <b-th>Descrição</b-th>
        <b-th>Quantidade</b-th>
        <b-th>Valor (R$)</b-th>
        <b-th v-if="showTarifa">Tarifa (R$)</b-th>
      </b-tr>
    </b-thead>

    <b-tbody>
      <b-tr v-for="(item, index) in itens" :key="index" :variant="item.color">
        <b-td class="text-left"
          >{{ item.name }} <small v-if="item.unit">({{ item.unit }})</small>
        </b-td>
        <b-td>{{ $filters.localeNumber(item.power ,2) }} </b-td>
        <b-td>{{  $filters.currency(item.price,2) }}</b-td>
        <b-td v-if="showTarifa">{{  $filters.currency(item.rate,3) }}</b-td>
      </b-tr>
    </b-tbody>
    
  </b-table-simple>
</template>

<script>

import billCompositionItens from './billCompositionItens.js'



export default {
  props: {
    billComposition: Object,
    showTarifa: { type: Boolean, default: true },
  },

  computed: {
    itens() {
      return billCompositionItens(this.billComposition)
    },
  },
};
</script>

<style></style>
