<template>
    <page-float-card fluid title="NDVI" subTitle="Áreas com histórico NDVI">

        <template v-slot:head-tools>
            <b-button v-if="$can('create', 'NDVI')" @click="newArea()" size="sm" variant="primary"> Nova Área</b-button>
        </template>

        <div v-if="loadStatus == 'loaded'">
            <div class="area-list" v-if="areas.length > 0">
                <div class="area-item" v-for="area in areas" :key="area.id">
                    <b-link :to="{ name: 'ndviArea', params: { id: area.id } }">
                        <span class="area-name"> {{ area.name }} </span>

                        <div>

                            <div class="ndvi-box">
                                <ndviImage v-if="area.lastNdviDate" :src="imageSrc(area.id, area.lastNdviDate)" :width="180"
                                    :height="180" :colorPalette="palette" />

                                <div class="no-image" v-else>
                                    <font-awesome-icon icon="image" size="lg"/>
                                    <div> Imagem indisponível </div>
                                </div>

                                <div class="ndvi-date">
                                    {{ $filters.localeDate(area.lastNdviDate) }}
                                    <!-- {{ $filters.localeTime(image.time) }} -->
                                </div>
                            </div>
                        </div>

                        <!-- <div class="date-range"> {{ $filters.localeDate(area.startDate) }} ~
                            {{ $filters.localeDate(area.lastNdviDate) }} </div> -->
                    </b-link>



                </div>
            </div>
            <div v-else class="text-center m-5">
                <div>
                    <img style="width: 140px;" src="@/assets/ndvi.png" alt="" />
                </div>
                <div class="mt-4 mx-auto" style="max-width: 460px;">
                    <h5>Sem áreas de NDVI</h5>
                    <p>
                        Para visualizar o histórico de NDVI, primeiro é necessário criar uma nova área a
                        partir de um equipamento de irrigação.
                    </p>
                    <b-button v-if="$can('create', 'NDVI')" pill variant="success" @click="newArea()">
                        Criar área
                    </b-button>
                </div>
            </div>


            <b-modal lazy ref="new-area" title="Nova área">
                <validation-observer ref="formObserver">
                    <input-select label="Equipamento" v-model="createAreaForm.equipmentId" :options="equipmentsList"
                        :rules="{ required: true }"></input-select>
                    <input-date label="Data Inicial" v-model="createAreaForm.startDate"
                        :rules="{ required: true }"></input-date>
                </validation-observer>
                <template v-slot:modal-footer>
                    <b-button @click="submit" variant="primary">Salvar</b-button>
                    <b-button @click="close">Cancelar</b-button>
                </template>
            </b-modal>

        </div>
        <c-loading :status="loadStatus" v-else></c-loading>


    </page-float-card>
</template>

<script>
import { DateTime } from 'luxon';
import ndviImage from "./ndviImage.vue";
import { mapState } from "vuex";
export default {

    data() {
        return {
            loadStatus: "",
            areas: [],
            createAreaForm: { equipmentId: null, startDate: null },
            palette:
                [
                    [50, 0, 0],		 //  < -.2 = #000000 (black)
                    [165, 0, 38],    //  -> 0 = #a50026
                    [215, 48, 39],   //  -> .1 = #d73027
                    [244, 109, 67],  //  -> .2 = #f46d43
                    [253, 174, 97],  //  -> .3 = #fdae61
                    [254, 224, 139], //  -> .4 = #fee08b
                    [255, 255, 191], //  -> .5 = #ffffbf
                    [217, 239, 139], //  -> .6 = #d9ef8b
                    [166, 217, 106], //  -> .7 = #a6d96a
                    [102, 189, 99],  //  -> .8 = #66bd63
                    [26, 152, 80],   //  -> .9 = #1a9850
                    [0, 104, 55]
                ],
        }
    },

    components: {
        ndviImage,
    },

    mounted() {
        this.loadAreas()
    },

    methods: {

        imageSrc(areaId, date) {
            return '/app-files/ndvi/' + areaId + '/' + date + '.png'
        },

        loadAreas() {
            this.loadStatus = "loading"
            this.$http.get("/irrig/ndvi/area/").then(
                (data) => {
                    this.areas = data
                    this.loadStatus = "loaded"
                },
                () => {
                    this.loadStatus = "error";
                }
            )
        },

        newArea() {
            this.createAreaForm = { equipmentId: this.equipmentsList[0].value, startDate: DateTime.now().toFormat('yyyy-MM-dd') }
            this.$refs['new-area'].show()
        },

        close() {
            this.$refs['new-area'].hide()
        },

        submit() {
            this.$refs.formObserver.validate().then(result => {
                if (!result) {
                    return;
                }

                this.$http.post("/irrig/ndvi/area/", this.createAreaForm).then(
                    data => {
                        this.$refs["new-area"].hide();
                        this.areas.push(data)
                        this.$router.push({ name: 'ndviArea', params: { id: data.id } })
                    },
                    () => {
                        // this.$refs.formObserver.setErrors(this.$validServer(error))
                        this.$bvModal.msgBoxOk(
                            "Verifique se todos os dados estão corretos",
                            {
                                title: "Erro!"
                            }
                        );
                    }
                );
            });
        },


    },

    computed: {

        equipmentsList() {
            let list = [];
            // list.push({ value: null, text: "selecione" })
            this.equipments.forEach((v) => {

                if (this.areas.filter(e => e.name == v.name).length == 0) {
                    list.push({ value: v.id, text: v.name });
                }
            });
            return list
        },

        ...mapState({
            equipments: (state) => state.equipments.equipments,
        }),
    }

}
</script>


<style lang="scss">
.area-list {

    display: flex;
    flex-wrap: wrap;
    // justify-content:space-evenly;
    align-items: flex-start;



    .area-item {
        position: relative;
        margin: 10px 10px;
        padding: 2px;
        box-shadow: 2px 3px 5px #a0a0a0;
        border-radius: 0.25rem;
        align-items: center;
        cursor: pointer;


        .no-image {
            width: 180px;
            height: 180px;
            margin-bottom: 6px;
            background-color: #cac9c9;
            text-align: center;
            padding-top: 70px;
            color: rgba(0, 0, 0, .65);
        }

        a {
            // color: rgba(0, 0, 0, .65);
            color: inherit;
            text-decoration: none;

        }

        .date-range {
            text-align: center;
        }

        .area-name {
            margin-left: 0.5rem;
            // transition: opacity .6s ease;
            // opacity: 1;
        }

        .ndvi-box {
            position: relative;
            border-radius: 0.25rem;

            &.selected {
                box-shadow: inset 0px 0px 2px 2px rgb(223, 130, 44);
            }

            .ndvi-date {
                display: inline-block;
                padding: 0.25em 0.4em;
                font-size: 75%;
                font-weight: 700;
                line-height: 1;
                text-align: center;
                white-space: nowrap;
                vertical-align: baseline;
                border-radius: 0.25rem;
                color: white;
                background-color: $primary;
                position: absolute;
                top: 4px;
                left: 4px;
            }



        }
    }

}
</style>