<template lang="html">


  <sensorWidget :sensor="sensor" :color="'#b7dad7'">
    <template v-slot:icon>
    <wellSvg :width="100" :height="100" :sensor="sensor"/>
    </template>

    <template v-slot:content>
      <div>
      <div class="widget-box-value">
        <span class="label"> nível </span>
        <span class="value">
          {{ $filters.localeNumber(level,2)}}<small> m </small>
        </span>
      </div>
      <div class="widget-box-value">
        <span class="label"> máximo do dia</span>
        <span class="value">
          {{ $filters.localeNumber(maxDayLevel,2)}}<small> m </small>
        </span>
      </div>
      <div class="widget-box-value">
        <span class="label"> mínimo do dia</span>
        <span class="value">
          {{ $filters.localeNumber(maxDayLevel,2)}}<small> m </small>
        </span>
      </div>
      </div>
    </template>
  </sensorWidget>


</template>

<script>
import sensWell from './sensWell.js'
import wellSvg from './wellSvg'
import sensorWidget from '../sensorWidget.vue'
export default {
    extends:sensWell,
    methods: {
      showModal() {
        this.$store.dispatch('select/setSensor',this.sensor)
      }
    },

    components:{
      wellSvg,
      sensorWidget
    }
}
</script>

