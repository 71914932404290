<template>
  <b-modal no-fade hide-footer @hidden="close" scrollable ref="fieldDetails-modal" size="max-xl">

    <template v-slot:modal-title>
      <span v-if="loadStatus == 'loaded'">
        <b-link :to="{ name: 'equipmentDetails', params: { id: field.equipmentId } }">{{ equipment.name }}</b-link> /
        {{ field.name }} (Histórico)
      </span>
    </template>

    <div v-if="loadStatus == 'loaded'">
      <b-row>
        <b-col lg="4">

          <b-card class="field-info" no-body>

            <b-row no-gutters>
              <b-col class="mb-2" cols="12" sm="6" lg="12">
                <div class="map-div">
                  <simple-map>
                    <fieldMap :field="field" />
                  </simple-map>
                </div>
              </b-col>

              <b-col cols="12" sm="6" lg="12">

                <img class="mr-2" :src="require('@irrigcomponents/culture/assets/48/' +
                  field.culture.icon +
                  '.png')
                  " />

                <span class="culture-name">{{ field.culture.name }}</span>

                <ul class="field-info-group">
                  <li>
                    <b>Data do manejo</b>
                    <span>{{ $filters.localeDate(field.startDate) }}</span>
                  </li>
                  <li>
                    <b>Data do plantio</b>
                    <span>{{ $filters.localeDate(field.datePlanting) }}</span>
                  </li>
                  <li>
                    <b>Data da colheita</b>
                    <span>{{ $filters.localeDate(field.endCycle.harvestDate) }}</span>
                  </li>
                  <li>
                    <b>Produtividade</b>
                    <span>{{ field.endCycle.production }} {{ field.endCycle.unit }}</span>
                  </li>
                  <li>
                    <b>Área</b>
                    <span>{{ $filters.round((field.area / 10000), 2) }} ha</span>
                  </li>
                </ul>

                <div class="mt-1 p-2">

                  <b-button block size="sm" v-if="$can('edit', 'Field')" variant="primary" v-b-modal.finalize-modal>
                    <font-awesome-icon icon="edit" /> Editar
                  </b-button>

                  <b-button block size="sm" v-if="$can('create', 'Field')" variant="secondary"
                    :to="{ name: 'fieldNew', params: { equipmentId: field.equipmentId, cloneId: field.id } }">
                    <font-awesome-icon icon="copy" /> Copiar
                  </b-button>

                  <b-button block size="sm" v-if="$can('edit', 'Field')" variant="success" @click="reactive()">
                    <font-awesome-icon icon="leaf" /> Reativar
                  </b-button>


                  <b-button block size="sm" v-if="$can('remove', 'Field')" v-b-tooltip.hover title="Apagar do histórico"
                    variant="danger" @click="removeField">
                    <font-awesome-icon icon="trash" /> Excluir
                  </b-button>

                </div>

              </b-col>
            </b-row>

          </b-card>
        </b-col>

        <b-col lg="8">
          <fieldMetrics :field="field" />

          <b-card class="mt-3" header="Notas">
            {{ field.endCycle.note }}
          </b-card>
        </b-col>
      </b-row>
    </div>
    <c-loading v-else :status="loadStatus"></c-loading>


    <b-modal id="finalize-modal" title="Editar Parcela" hide-footer>
      <fieldEndEdit :field="field" v-on:cancel="$bvModal.hide('finalize-modal')"
        v-on:finalized="editFinalized($event); $bvModal.hide('finalize-modal');" />
    </b-modal>


  </b-modal>
</template>

<script>
import fieldMetrics from "./fieldMetrics";
import fieldMap from "../field/fieldMap";
import fieldEndEdit from "./fieldEndEdit";
export default {
  data() {
    return {
      field: undefined,
      loadStatus: "",
      loadDaysStatus: "",
      fieldDays: {}
    };
  },
  props: {
    fieldId: { type: String, required: true }
  },
  mounted() {
    this.$refs["fieldDetails-modal"].show();
    this.loadField();
  },
  components: {
    fieldMap,
    fieldMetrics,
    fieldEndEdit
  },

  computed: {
    equipment() {
      return this.$store.getters['equipments/getById'](this.field.equipmentId);
    }
  },

  methods: {

    editFinalized(endCycle) {
      this.loadStatus = "loading";
      this.$http.post("irrig/equipment/field/" + this.fieldId + '/finalize', endCycle).then(
        () => {
          this.loadField();
        },
        error => {
          console.error("edit finalized error", error)
          this.loadStatus = "error";
        }
      );
    },

    reactive() {
      this.loadStatus = "loading";
      this.$http.get("irrig/equipment/field/" + this.fieldId + '/reactive').then(
        (data) => {
          this.$router.push({ name: 'fieldDetails', params: { id: data.id } })
          this.loadStatus = "loaded";
        },
        error => {
          console.error("reactive field error", error)
          this.loadStatus = "error";
        }
      );
    },

    loadField() {
      this.$http.get("irrig/equipment/field/" + this.fieldId).then(
        data => {
          this.field = data;
          this.loadStatus = "loaded";
        },
        error => {
          console.error("load field datails error", error)
          this.loadStatus = "error";
        }
      );
    },

    removeField() {
      this.$deleteConfirm.show("excluir-historico").then((value) => {
        if (value === true) {
          this.$http.delete('irrig/equipment/field/' + this.fieldId).then(() => {
            this.$router.go(-1);
          }, (error) => {
            console.error("delete equipment field error", error)
            //API error
          });
        }
      })
    },

    close() {
      this.$router.push({ name: "home" });
    },


  }
};
</script>

<style lang="scss" scoped>
.field-notes {
  border: 1px solid $gray-300;
  padding: 1em;
  background-color: white;
  margin-bottom: 1em;

}

.field-info {

  .culture-icon {
    display: block;
    margin: 0 auto;
    width: 50px;
    padding: 3px;
    //border: 3px solid #d2d6de;
    border-radius: 30%;
    background-color: white;
    box-shadow: 2px 3px 5px #a0a0a0;
  }

  .culture-name {
    font-size: 21px;
    margin-top: 5px;
    text-align: center;
  }



  .field-info-group {
    padding-left: 0;
    border-left: 0;
    border-right: 0;
    border-radius: 0;
    padding-left: 0;
    padding-right: 0;

    li {
      position: relative;
      display: block;
      padding: 5px 10px;
      margin-bottom: -1px;
      border-bottom: 1px solid #ddd;

      span {
        float: right !important;
        color: #3c8dbc;
      }
    }
  }
}
</style>