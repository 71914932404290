<template>
  <b-row>
    <!-- unidade  -->
    <ValidationProvider
      slim
      vid="unit"
      name="Unidade"
      :rules="{ required: true, min: 1 }"
      v-slot="v"
    >
      <b-form-group label="Unidade" class="col-sm-4">
        <b-form-input
          name="sensor.unit"
          v-model="editable.unit"
          type="text"
          maxlength="10"
          placeholder="unidade"
          :state="$validateState(v)"
        ></b-form-input>
        <span class="invalid-input">{{ v.errors[0] }}</span>
      </b-form-group>
    </ValidationProvider>


     <!-- Poço  -->
     <ValidationProvider
      slim
      vid="sensorWell"
      name="sensorWell"
      :rules="{ required: false, min:36, max: 36 }"
      v-slot="v"
    >
      <b-form-group label="ID do sensor de poço" class="col-sm-4">
        <b-form-input
          name="sensor.sensorWell"
          v-model="editable.sensorWell"
          type="text"
          maxlength="36"
          placeholder="UUID"
          :state="$validateState(v)"
        ></b-form-input>
        <span class="invalid-input">{{ v.errors[0] }}</span>
      </b-form-group>
    </ValidationProvider>

    <!-- ERROR  -->
    <ValidationProvider
      slim
      vid="minValueError"
      name="minValueError"
      :rules="{ required: true, min: 0 }"
      v-slot="v"
    >
      <b-form-group label="error de valor minímo" class="col-sm-4">
        <b-form-input
          name="sensor.minValueError"
          v-model="editable.minValueError"
          type="number"
          min="0"
          step="1"
          :state="$validateState(v)"
        ></b-form-input>
        <span class="invalid-input">{{ v.errors[0] }}</span>
      </b-form-group>
    </ValidationProvider>



    <div
      class="col-12"
      v-for="(hydrometer, index) in editable.hydrometers"
      :key="index"
    >
      <hr />
      <b-row>
        <!-- dataStrem Id  -->
        <ValidationProvider
          slim
          vid="datastream"
          name="Datastream ID"
          :rules="{ required: true, between: [0, 100] }"
          v-slot="v"
        >
          <b-form-group label="DatastreamId" class="col-sm-6 col-lg-2">
            <b-form-input
              name="sensor.datastreamId"
              v-model="hydrometer.dataStreamId"
              type="number"
              min="0"
              step="1"
              :state="$validateState(v)"
            ></b-form-input>
            <span class="invalid-input">{{ v.errors[0] }}</span>
          </b-form-group>
        </ValidationProvider>

        <!-- nome -->
        <ValidationProvider
          slim
          vid="name"
          name="Nome"
          :rules="{ required: true, min: 1, max: 15 }"
          v-slot="v"
        >
          <b-form-group label="Nome" class="col-sm-6 col-lg-5">
            <b-form-input
              name="sensor.name"
              v-model="hydrometer.name"
              type="text"
              maxlength="15"
              :state="$validateState(v)"
            ></b-form-input>
            <span class="invalid-input">{{ v.errors[0] }}</span>
          </b-form-group>
        </ValidationProvider>

        <!-- Vazão  -->
        <ValidationProvider
          slim
          vid="flow"
          name="Vazão"
          :rules="{ required: true, min: 1 }"
          v-slot="v"
        >
          <b-form-group label="Vazão" class="col-sm-6 col-lg-3">
            <b-input-group :append="editable.unit">
              <b-form-input
                name="sensor.maxFlow"
                v-model="hydrometer.maxFlow"
                type="number"
                min="0"
                step="1"
                :state="$validateState(v)"
              ></b-form-input>
            </b-input-group>
            <span class="invalid-input">{{ v.errors[0] }}</span>
          </b-form-group>
        </ValidationProvider>

        <b-col>
          <b-button
            class="mt-4"
            size="sm"
            title="remover Medidor"
            variant="danger"
            @click="removeHydro(index)"
          >
            remover</b-button
          >
        </b-col>
      </b-row>
    </div>
    <b-col cols="12">
      <hr />
      <b-button variant="primary" @click="addHydro()">
        Adicionar Medidor
      </b-button>
    </b-col>
    <!-- {{ form }} -->
    <b-col cols="12">
      <hr />
      <grantEdit v-model="editable.grantTable" />
    </b-col>

    <!-- {{editable}} -->
  </b-row>
</template>

<script>
import grantEdit from "./grantEdit";

export default {
  data() {
    return {
      editable: undefined,
    };
  },
  props: {
    value: { type: Object, required: true },
  },
  beforeMount() {
    this.editable = JSON.parse(JSON.stringify(this.value));
  },
  methods: {
    addHydro() {
      if (this.editable.hydrometers == undefined)
        this.$set(this.editable, "hydrometers", []);
      this.editable.hydrometers.push({
        maxFlow: 1000,
        dataStreamId: this.editable.hydrometers.length + 1,
      });
    },

    removeHydro(index) {
      this.editable.hydrometers.splice(index, 1);
    },
  },
  watch: {
    editable: {
      deep: true,
      handler(v) {
        this.$emit("input", v);
      },
    },
  },
  components: {
    grantEdit,
  },
};
</script>

<style></style>
