<template>
  <div>
    <b-table-simple class="bill-table" hover small caption-top responsive>
      <b-thead head-variant="light">
        <b-tr>
          <b-th>Descrição</b-th>
          <b-th>Quantidade</b-th>
          <b-th>Valor(R$)</b-th>
          <b-th>Tarifa(R$)</b-th>
        </b-tr>
      </b-thead>

      <b-tbody >
        <insertDataRow
          name="Demanda Ativa"
          unit="kW"
          v-model="editable.activeDemand"
        />
         <insertDataRow
          name="Ultrapassagem de Demanda"
          unit="kW"
          v-model="editable.exceedingDemand"
        />
        <insertDataRow
          name="Demanda Complementar"
          unit="kW"
          v-model="editable.complementaryDemand"
        />

        <insertDataRow
          name="Demanda Reativa Excedente"
          unit="kVAR"
          v-model="editable.surplusReactiveDemand"
        />
      </b-tbody>

      <insertConsumoRow class="field-top-border"
        name="Consumo Ativo"
        unit="kWh"
        v-model="editable.activeConsumption"
      />

      <insertConsumoRow class="field-top-border"
        name="Consumo Reativo"
        unit="kVARh"
        v-model="editable.reactiveConsumption"
      />

      <insertConsumoRow class="field-top-border"
        v-if="editInjectedEnergy"
        name="Energia Injetada"
        unit="kWh"
        v-model="editable.injectedEnergy"
      />

      <b-tbody class="field-top-border">
        <tr>
          <td class="id-r-line field-label"><span>Multas</span></td>
          <td></td>
          <td class="td-input">
            <ValidationProvider
              :rules="{ required: false, minValue: 0 }"
              v-slot="v"
            >
              <b-form-input
                name="penalties"
                size="sm"
                v-model="editable.penalties"
                type="number"
                min="0"
                step="any"
                :state="$validateState(v)"
              ></b-form-input>
            </ValidationProvider>
          </td>
        </tr>

        <tr>
          <td class="id-r-line field-label"><span>Acréscimos</span></td>
          <td></td>
          <td class="td-input">
            <ValidationProvider
              :rules="{ required: false, minValue: 0 }"
              v-slot="v"
            >
              <b-form-input
                name="additions"
                size="sm"
                v-model="editable.additions"
                type="number"
                min="0"
                step="any"
                :state="$validateState(v)"
              ></b-form-input>
            </ValidationProvider>
          </td>
        </tr>

        <tr>
          <td class="id-r-line field-label"><span>Impostos</span></td>
          <td></td>
          <td class="td-input">
            <ValidationProvider
              :rules="{ required: false, minValue: 0 }"
              v-slot="v"
            >
              <b-form-input
                name="tax"
                size="sm"
                v-model="editable.tax"
                type="number"
                min="0"
                step="any"
                :state="$validateState(v)"
              ></b-form-input>
            </ValidationProvider>
          </td>
        </tr>

        <tr>
          <td class="id-r-line field-label"><span>Descontos</span></td>
          <td></td>
          <td class="td-input">
            <ValidationProvider
              :rules="{ required: false, minValue: 0 }"
              v-slot="v"
            >
              <b-form-input
                name="discounts"
                size="sm"
                v-model="editable.discounts"
                type="number"
                min="0"
                step="any"
                :state="$validateState(v)"
              ></b-form-input>
            </ValidationProvider>
          </td>
        </tr>
        <tr class="field-total">
          <td class="id-r-line field-label"> Total</td>
          <td></td>
          <td> {{$filters.currency(total)}}</td>
        </tr>
      </b-tbody>
    </b-table-simple>
  </div>
</template>

<script>
import insertDataRow from "./insertDataRow";
import insertConsumoRow from "./insertConsumoRow";


const returnPrice = function(v){
  if(v != undefined){
    let value = Number(v.price)
    if (!isNaN(value)){
      return value
    }
  }
  return 0
}

const returnValue = function(v){
  if(v != undefined){
    let value = Number(v)
    if (!isNaN(value)){
      return value
    }
  }
  return 0
}


export default {
  data() {
    return {
      editable: {},
    };
  },
  props: {
    value: {
      type: Object,
      required: false,
      default: function() {
        return {};
      },
    },
    editInjectedEnergy:{typeo:Boolean, default:false}
  },
  beforeMount() {
    this.editable = JSON.parse(JSON.stringify(this.value));
  },
  watch: {
    editable: {
      deep: true,
      handler(v) {
        this.$emit("input", JSON.parse(JSON.stringify(v)));
      },
    },
  },
  components: {
    insertDataRow,
    insertConsumoRow,
  },

  computed:{
    total(){

      let total = 0

      total+= returnPrice (this.editable.activeDemand)
      total+= returnPrice (this.editable.exceedingDemand)
      total+= returnPrice (this.editable.complementaryDemand)
      total+= returnPrice (this.editable.surplusReactiveDemand)


      if(Array.isArray(this.editable.activeConsumption)){
        this.editable.activeConsumption.forEach(e=>{
          total+= returnPrice (e.consumption)
        })
      }

      if(Array.isArray(this.editable.reactiveConsumption)){
        this.editable.reactiveConsumption.forEach(e=>{
          total+= returnPrice (e.consumption)
        })
      }
     


      total+= returnValue (this.editable.penalties)
      total+= returnValue (this.editable.additions)
      total+= returnValue (this.editable.tax)
      total-= returnValue (this.editable.discounts)


      if(Array.isArray(this.editable.injectedEnergy)){
        this.editable.injectedEnergy.forEach(e=>{
          total-= returnPrice (e.consumption)
        })
      }

      
     


      return total
    }
  }

};
</script>

<style lang="scss" scoped>
:deep(.bill-table) {
  font-size: 13px;

  .field-top-border{
    border-top: 2px solid rgb(184, 184, 184) !important;
  }
  

  .field-total{
    font-size: 18px;
    color: $danger;
    font-weight: bold;
  }


  .field-label {
    padding-left: 1rem;
    min-width: 6rem;
  }



  .td-input {
    // min-width: 1rem;
    padding: 0px;
    width: 120px;
  }
}
</style>
