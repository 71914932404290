<template>
  <div id="printDiv" class="equipment-datasheet">


    <report-header class="print-only"  label="Funcionamento do Aspersor" :title="equipment.name" />

    <div class="mb-3 d-flex">
      <div>
        <h5>Ficha técnica</h5>
      </div>
      <div class="ml-auto non-print">
        <b-button size="sm" variant="outline-primary" title="imprimir" @click="$printDiv('printDiv')"> <font-awesome-icon
            icon="print" /></b-button> 
      </div>
    </div>

    <div>
      <ul class="equipment-datatsheet-list">
        <li>
          <b>Vazão</b>
          <span>{{ $filters.round(equipment.flowRate, 2) }} m³/h</span>
        </li>
        <li>
          <b>Potência</b>
          <span>{{ $filters.round(equipment.powerKw, 2) }} kW</span>
        </li>
        <li>
          <b>Área Irrigada</b>
          <span>{{ $filters.round((equipment.irrigatedArea / 10000), 2) }} ha</span>
        </li>
        <li>
          <b>Eficiência:</b>
          <span>{{ $filters.round(equipment.efficiency,1) }} %</span>
        </li>
        <li>
          <b>Coordenadas:</b>
          <span>{{ equipment.location.lat }} , {{ equipment.location.lon }}</span>
        </li>
      </ul>

      <b-table v-if="equipment.irrigatedArea>0" class="text-center" id="datasheettable" responsive head-variant="dark" small :items="items" :fields="fields">

        <template #thead-top="">
          <b-tr>
            <b-th ></b-th>
            <b-th :colspan="fields.length">Tempos</b-th>
          </b-tr>
        </template>

        <template v-slot:cell(percent)="data"> {{ data.value }} % </template>
        <template v-slot:cell(depth)="data">
          {{ $filters.round(data.value, 2) }} mm
        </template>
      </b-table>
    </div>

    <hr />
  </div>
</template>

<script>

import { mapGetters } from "vuex";
import reportHeader from "@reportcomponents/comum/reportHeader.vue";


export default {
  props: {
    equipment: { type: Object, required: true },
  },
  methods: {

    convertDate(v) {
      return this.$filters.decimalhourToTime(v, "h ", "m");
    },

  },

  components:{
    reportHeader
  },

  computed: {
    fields() {
      let ret = [];
      ret.push({ key: "depth", label: "Lâmina Bruta" });
     

      this.equipment.fields.forEach((element) => {
        ret.push({
          key: element.name,
          label: element.name,
          formatter: this.convertDate,
        });
      });

      return ret;
    },

    ...mapGetters({
      user: "accounts/user",
      client: "accounts/client",
      workspace: "accounts/workspace",
    }),


    items() {
      let ret = [];
      
      for (let i = 30; i >= 5; i -= 1) {
  
        let depth = i;
        let obj = {
          depth:depth,
        };

        this.equipment.fields.forEach((element) => {
          obj[element.name] = depth * element.volumeToTimeConstant;
        });

        ret.push(obj);
      }

      return ret;
    },
  },
};
</script>

