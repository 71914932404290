<template>
  <b-modal
    ref="object-edit-modal"
    title="Gerar Relatório Mensal"
    @hidden="close"
    @show="onShow"
  >
    <b-alert show>
      O período de geração de relatório considera os dias de intervalo das
      contas de energia elétrica. Caso não exista contas no mês escolhido, o
      intervalo será o período normal deste mês.
    </b-alert>
    <validation-observer ref="formObserver">
      <ValidationProvider
        slim
        name="Mês"
        :rules="{ required: true }"
        v-slot="v"
      >
        <b-form-group label="Mês">
          <monht-select  v-model="month" />
          <span class="invalid-input">{{ v.errors[0] }}</span>
        </b-form-group>
      </ValidationProvider>
    </validation-observer>
     

    <template v-slot:modal-footer>
      <b-button :disabled="savingObject" @click="submit" variant="primary">
        Gerar
        <font-awesome-icon v-if="savingObject" icon="spinner" spin />
      </b-button>
      <b-button @click="close">Cancelar</b-button>
    </template>
  </b-modal>
</template>

<script>
import { formModalObject } from "@components/mixins/FormModalObject";
import { mapGetters } from "vuex";

export default {
  mixins: [formModalObject],
  data() {
    return {
      month: undefined,
    };
  },
  mounted() {
    this.objectUrl = "report/irrigation-report";
    // this.idField = "month";
    this.editableObject.referenceMonth = this.month;
    this.editableObject.author = this.user.name;
  },
  computed: {
    ...mapGetters({
      user: "accounts/user",
    }),
  },

  watch: {
    month() {
      this.editableObject.referenceMonth = this.month;
      // console.log(this.month)
    },
    editableObject() {
      this.editableObject.author = this.user.name;
    },
  },
  methods:{
     onShow() {
      this.month = undefined
    },
  }
};
</script>

<style></style>
