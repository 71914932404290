import memberPageList from './memberPageList'
import memberPageInvite from './memberPageInvite'
import memberPageNew from './memberPageNew'

var member = {
  path: 'member',
  component: memberPageList,
  name: 'memberList',
  meta: {authorize:{action:'read',resource:'Member'}},
}

var membernew = {
      path: 'member/new', name: 'memberNew', component: memberPageNew,
      meta: {authorize:{action:'create',resource:'Member'}},
}

var memberInvite = {
  path: 'member/invite', name: 'memberInvite', component: memberPageInvite,
  meta: {authorize:{action:'read',resource:'Invite'}},
}



export { member, membernew,memberInvite}

