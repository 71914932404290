<template>
  <canvas :style="{ width:width, height:height}" class="chart" ref="canvas"></canvas>
</template>

<script>
import {IrrigChart} from "./irrigChart.js";

export default {
  data() {
    return {
      irrigChart: undefined,
    };
  },
  props: {
    data: Array,
    startAngle: { type: Number, default: 0 },
     width:{type:[Number,String], default:'100%'},
    height:{type:[Number,String], default:'200px'}
  },

  mounted() {
    this.irrigChart = new IrrigChart(this.$refs.canvas);
    this.irrigChart.startAngle = this.startAngle;
    this.irrigChart.data = this.data;//.map(v=>{v[0]});
    window.addEventListener('resize', this.handleResize)
   },

   methods:{
     handleResize(){
       this.irrigChart.resize()
     }
   },


  beforeUnmount: function () {
    window.removeEventListener('resize', this.handleResize)
  }
};
</script>

