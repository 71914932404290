import { AbilityBuilder, Ability } from "@casl/ability";

function subjectName(item) {
  if (!item || typeof item === "string") {
    return item;
  }
  return item.__type;
}

/**
 * manage and all are special keywords in CASL.
 * manage represents any action and all represents any subject
 *
 * opções : manage , remove, create, edit, read
 */

export function defineRulesFor(auth) {
  const { can, cannot, rules } = new AbilityBuilder();

  var userRole = auth.role;
  // var clientId = auth.clientId
  var workspaceId = auth.workspaceId;
  var userId = auth.userId;

  if (userRole === "MASTER") {
    can("manage", "all");

    ////////////////////////////////////////////
  } else if (userRole === "OWNER") {
    can("manage", [
      "Member",
      "Client",
      "Workspace",
      "Invite",
      "Pluvio",
      "Equipment",
      "Field",
      "Irrigation",
      "InsertData",
      "EnergyBill",
      "ConsumerStation",
      "Report",
      "sensorData",
      "NDVI"
    ]);
    can("read", "Soil");
    can("create", ["WeatherStation", "Culture"]);
    can("manage", "Culture", { workspace: workspaceId });
    can("manage", "WeatherStation", { workspace: workspaceId });
    cannot("edit", "Member", { "user.id": userId });
  }
  /////////////////////////////////////////////////
  else if (userRole === "ADMIN") {
    can("read", ["Member", "Client", "Invite","Soil"]);
    can("create", ["Member", "Invite", "Culture"]);
    can("disable", ["member_client"]);
    can("edit", ["Member", "Client"]);
    can("manage", "Culture", { workspace: workspaceId });
    can("manage", "WeatherStation", { workspace: workspaceId });
    can("manage", [
      "Equipment",
      "Field",
      "Irrigation",
      "InsertData",
      "Report",
      "ConsumerStation",
      "EnergyBill",
      "sensorData",
      "NDVI"
    ]);
    cannot("edit", "Member", { role: "OWNER" });
    cannot("edit", "Member", { "user.id": userId });
  }
  ////////////////////////////////////////////////////
  else if (userRole === "MANAGER") {
    can("read", ["Report","Soil"]);
    can("create", ["Member", "Invite", "Culture"]);
    can("manage", "Culture", { workspace: workspaceId });
    can("manage", "WeatherStation", { workspace: workspaceId });
    // can('manage', 'WeatherStation', { client: clientId });
    can("manage", [
      "Equipment",
      "Field",
      "Irrigation",
      "InsertData",
      "Report",
      "ConsumerStation",
      "EnergyBill",
      "sensorData",
      "NDVI"
    ]);
    can("edit", ["Member", "Client"]);
    can(["read", "edit"], "data");
    cannot("edit", "Member", { role: "OWNER" });
    cannot("edit", "Member", { "user.id": userId });
  }
  //////////////////////////////////////////////////////
  else if (userRole === "CLIENT") {
    can("manage", ["InsertData","Irrigation"]);
    can(["read", "edit"], ["data","EnergyBill"]);
    can(["read"], ["ConsumerStation","Report"]);
    can("manage", "profile");
  }
  /////////////////////////////////////////////////////
  else {
    can("read", ["data", "Report","ConsumerStation"]);
  }
  return rules;
}

export function createAbility(user) {
  var rules = {};
  if (user != undefined) {
    rules = defineRulesFor(user);
  }

  return new Ability(rules, { subjectName });
}
