<template>
    <div>
        <div class="d-flex justify-content-between flex-wrap mb-2">
            <div>
                <slot name="button"> </slot>
            </div>

            <div v-if="!noSearch">
                <b-form-input size="sm" v-model="filter" type="search" id="filterInput" autocomplete="off"
                    placeholder="Pesquisar"></b-form-input>
            </div>
        </div>

        <slot  name="list" v-bind="{ perPage, currentPage, filter }"></slot>
        <hr />
        <div class="d-flex flex-wrap">
            <div class="pr-4">
                <b-form-select v-model="perPage">
                    <b-form-select-option :value="10">10</b-form-select-option>
                    <b-form-select-option :value="25">25</b-form-select-option>
                    <b-form-select-option :value="50">50</b-form-select-option>
                    <b-form-select-option :value="100">100</b-form-select-option>
                    <b-form-select-option :value="9999">máximo</b-form-select-option>
                </b-form-select>
            </div>
            <b-pagination class="mb-1" v-model="currentPage" :total-rows="numRows" :per-page="perPage"></b-pagination>
        </div>

    </div>
</template>
<script>
export default {
    data() {
        return {
            currentPage: 1,
            filter: null,
            perPage: 25
        };
    },
    props: {
        numRows: Number,
        page: Number,
        noSearch: { type: Boolean, default: false },
    },
    beforeMount() {
        //this.currentPage = this.value;
    },
    watch: {
        currentPage(v) {
            if (v) this.$emit("page-update", v);
        },
    },

}
</script>
