import Vue from 'vue'
import Vuex from 'vuex'
import sensors from '@sensorModule/store/sensors'
import equipments from '@irrigcomponents/store/equipments'
import accounts from '@accountcomponents/store/accounts'

//import products from './modules/products'
//import createLogger from '../../../src/plugins/logger'


Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({

  modules: {
    sensors,
    equipments,
    accounts
  },
  strict: debug,
//  plugins: debug ? [createLogger()] : []
})
