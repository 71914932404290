import { render, staticRenderFns } from "./selectArea.vue?vue&type=template&id=a44f6134&scoped=true"
import script from "./selectArea.vue?vue&type=script&lang=js"
export * from "./selectArea.vue?vue&type=script&lang=js"
import style0 from "./selectArea.vue?vue&type=style&index=0&id=a44f6134&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a44f6134",
  null
  
)

export default component.exports