<template>
  <vl-layer-image v-if="client && client.geometryArea" :z-index="zIndex">
    <heat-map
      :points="points"
      :area="client.geometryArea"
      :colorShift="3"
      :power="2"
      :minValue="0"
      :maxValue="8"
    ></heat-map>
  </vl-layer-image>
</template>

<script>
import { mapGetters } from "vuex";
import heatMap from "@components/map/heatMap";
import Vue from "vue";
Vue.use(heatMap);

export default {
  data() {
    return {
      pluvios: []
    };
  },

  mounted() {
    this.updateList();
  },

  methods: {
    updateList() {
      if (this.pluvioList) {
        let list = [];
        this.pluvioList.forEach((pluvio) => {
          let elapsedTime = (this.$store.state.sensors.serverTime - pluvio.lastTimeSync) / 1000;
          if (elapsedTime < pluvio.offlineTime) {
            list.push(pluvio);
          }
        });
        this.pluvios = list;
      }
    },
  },

  watch: {
    pluvioCount() {
      this.updateList();
    },

    pluvioList: {
      deep: true,
      handler() {
        this.updateList();
      },
    },
  },

  computed: {
    pluvioCount() {
      let count = 0;
      if (this.pluvioList) {
        this.pluvioList.forEach((pluvio) => {
          let elapsedTime =
            (this.$store.state.sensors.serverTime - pluvio.lastTimeSync) / 1000;
          if (elapsedTime < pluvio.offlineTime) {
            count++;
          }
        });
      }
      return count;
    },

    points() {
      let coordPoints = [];
      if (this.pluvios) {
        this.pluvios.forEach((pluvio) => {
          let total = 0;
          if (pluvio.hourlyRain != undefined) {
            pluvio.hourlyRain.forEach(function(e) {
              total += e;
            });
          }
          coordPoints.push({
            pos: [pluvio.location.lon, pluvio.location.lat],
            val: total,
          });
        });
      }
      return coordPoints;
    },
    ...mapGetters({
      pluvioList: "sensors/pluvioList",
      client: "accounts/client",
    }),
  },

  props: {
    zIndex: { type: Number, default: 2 },
  },
};
</script>

<style></style>
