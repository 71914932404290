import { render, staticRenderFns } from "./inemaTable.vue?vue&type=template&id=562c1fb8"
import script from "./inemaTable.vue?vue&type=script&lang=js"
export * from "./inemaTable.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports