import { render, staticRenderFns } from "./loggedPage.vue?vue&type=template&id=415c1acf"
import script from "./loggedPage.vue?vue&type=script&lang=js"
export * from "./loggedPage.vue?vue&type=script&lang=js"
import style0 from "./loggedPage.vue?vue&type=style&index=0&id=415c1acf&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports