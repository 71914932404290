<template>
  <div class="wellInfo">

    <div class="widget-box-header">
      <span class="name">{{sensor.name}}</span>
      <span class="time">
        <font-awesome-icon icon="plug-circle-xmark"/> {{$filters.secondsToStr(elapsedTime)}}
      </span>
    </div>


    <div class="widget-box-value">
      <hydroFlow :width="'190px'" :height="'100px'" :sensor="sensor"/>
    </div>

  </div>
</template>

<script>
import sensHydro from './sensHydro.js'
import hydroFlow from './hydroFlow'
export default {
  extends:sensHydro,
  components:{
    hydroFlow
  }
}
</script>

<style lang="scss" scoped>

</style>
