<template>
    <b-container class="workspace-page">
      <div class="header">
        <h2>Grupos </h2>
        <small>
          <font-awesome-icon icon="info-circle" />
          Lista de todos os Grupos cadastrados no sistema
        </small>
      </div>

      <!-- LISTA -->
      <div v-if="loadStatus=='loaded'">



         <!-- MENU de ferramentas  -->
      <b-row class="group-page-header">
        <b-col cols="2" v-if="$can('create','Workspace')">
          <b-button @click="newWorkspace" variant="primary">novo</b-button>
        </b-col>

        <b-col>
          <div class="float-right">
            <b-pagination
              v-model="currentPage"
              :total-rows="numRows"
              :per-page="perPage"
              aria-controls="my-table"
            ></b-pagination>
          </div>
        </b-col>

        <b-col md="6">
          <b-form-group
            label="Pesquisar"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="filterInput"
          >
            <b-input-group size="sm">
              <b-form-input
                v-model="filter"
                type="search"
                id="filterInput"
                placeholder="Digite o que procurar"
              ></b-form-input>
              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''">Limpar</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>

        <workspaceTableList
          :per-page="perPage"
          :current-page="currentPage"
          :workspaceList="workspaceList"
          :filter="filter"
          v-on:onnumrows="onNumRows($event)"
        />

        <router-view v-on:update-workspace="updateList($event)" v-on:remove-workspace="removeWorkspace($event)"></router-view>
      </div>
      <c-loading :status="loadStatus" v-else></c-loading>
    </b-container>
</template>

<script>
//usado apenas para testes. comente esta linha caso não esteja testando
//import dataTeste from "./testes/data.json";

import workspaceTableList from "./workspaceTableList";

export default {
  data() {
    return {
      workspaceList: [],
      loadStatus: String,
      perPage: 10,
      currentPage: 1,
      filter: null,
      numRows: 1
    };
  },
  props: {
    editable: { type: Boolean, default: true }
  },
  beforeMount() {
    this.loadStatus = "loading";
    this.$http.get("account/workspace").then(
      data => {
        this.workspaceList = data;
        this.loadStatus = "loaded";
        this.numRows = data.length;
      },
      error => {
        console.error("load workspace error",error)
        this.loadStatus = "error";
      }
    );
  },
  components: {
    workspaceTableList,
  },

  methods: {
    onNumRows(numrows) {
      this.numRows = numrows;
    },

    //atualiza a lista de usuários
    updateList(newWorkspace) {
      const index = this.workspaceList.findIndex(e => e.id === newWorkspace.id);
      if (index === -1) {
        this.workspaceList.push(newWorkspace);
      } else {
        let dataObj = this.workspaceList[index];
        Object.entries(newWorkspace).forEach(([key, value]) => {
          dataObj[key] = value;
        });
      }
    },

    removeWorkspace(id) {
      this.$bvModal
        .msgBoxConfirm(
          "Deseja realmente apagar este grupo permanentemente?",
          {
            title: "Por favor confirme",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "Sim",
            cancelTitle: "Não",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true
          }
        )
        .then(value => {
          if (value === true) {
            this.$http.delete("account/workspace/" + id).then(
              () => {
                let index = this.workspaceList.findIndex(e => e.id === id);
                this.workspaceList.splice(index, 1);
                this.$router.push({name:'workspaceList'})
              },
              error => {
                console.error("delete workspace error",error)
                //API error
              }
            );
          }
        })
    },

    newWorkspace() {
      this.$router.push({ name: "workspaceNew" });
    }
  }
};
</script>

<style lang="scss" scoped>
.workspace-page {
  padding-top: 30px;

  .header {
    margin: 0 0 20px;
    small {
      color: #666;
    }

    h2 {
      font-family: "Open Sans", Helvetica, Arial, sans-serif;
      color: #444;
      font-size: 27px;
      font-weight: 700;
      line-height: 27px;
      margin: 0;
    }
  }

  .workspace-page-header {
    padding-bottom: 10px;
  }

}
</style>