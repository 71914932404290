<template>
  <!-- <div> -->
    <component v-if="sensor!=undefined" :is="'pageDetails-' + sensorType" :sensor="sensor" />

    <!-- <b-card title="sensor debug">
      <vue-json-pretty
      :path="'res'"
      :data="sensor"
      :showDoubleQuotes="false"
      :deep="2">
      </vue-json-pretty>
  </b-card> -->
  <!-- </div> -->
</template>

<script>
import sensor from "@sensorcomponents/sensor.js";
import pivotPageDetais from "@sensorcomponents/sensPivot/pivotPageDetails";
import stationPageDetails from "@sensorcomponents/sensStation/stationPageDetails";
import soilPageDetails from "@sensorcomponents/sensSoil/soilPageDetails";
import pluvioPageDetails from "@sensorcomponents/sensPluvio/pluvioPageDetails";
import waterPageDetails from "@sensorcomponents/sensWater/waterPageDetails";
import wellPageDetails from "@sensorcomponents/sensWell/wellPageDetails";
import hydrometerPageDetails from "@sensorcomponents/sensHydro/hydrometerPageDetails";

export default {
  extends: sensor,
  components: {
    "pageDetails-pivot": pivotPageDetais,
    "pageDetails-station": stationPageDetails,
    "pageDetails-soil": soilPageDetails,
    "pageDetails-pluviometer": pluvioPageDetails,
    "pageDetails-waterLevel": waterPageDetails,
    "pageDetails-well": wellPageDetails,
    "pageDetails-hydrometer": hydrometerPageDetails,
  },
};
</script>

