<template>

  <b-table  
     small
     stacked="md"
    :items="items" 
    :fields="fields" 
    :filter="filter"
    :current-page="currentPage"
    :per-page="perPage"
    @filtered="onFiltered"
   >

  <template v-slot:cell(name)="row">
      <b-link :to="{name:'stationDetails',params:{id:row.item.id}}">
          {{row.item.name}}
      </b-link>
  </template>

  <template v-slot:cell(lastDate)="row">
          {{$filters.ptbrdate(row.item.lastDate)}}
  </template>

  <template v-slot:cell(type)="row">

    <font-awesome-icon class="text-primary" v-if="row.item.type == 'automatic'" icon="robot" title="Automática"/>
          <!-- {{$filters.ptbrdate(row.item.lastDate)}} -->
  </template>

<!-- 
    <template v-slot:cell(action)="row" >
        <b-button-group size="sm" v-if="$can('edit',row.item)">
            <b-button v-b-tooltip.hover title="Editar"  variant="primary"  @click="$emit('editstation',row.item.id)"><font-awesome-icon icon="edit" /></b-button>
            <b-button v-b-tooltip.hover title="Remover" variant="danger"   @click="$emit('removestation',row.item.id)"> <font-awesome-icon icon="trash" /></b-button>
        </b-button-group>
    </template> -->
  </b-table>

</template>

<script>

// var elevationFormater = function(value,col,row){
//     return row.location.ele;
// }

import { mapGetters } from "vuex";

export default {

    data(){
        return{
            fields: [
                { key: 'name',     label: 'Nome',     sortable: true },
                // { key: 'elevation',label: 'Altitude (m)', sortable: true, formatter:elevationFormater },
                { key: 'client',label: 'Cliente', sortable: true, formatter:this.getClient},
                { key: 'location.ele',label: 'Altitude (m)', sortable: true},
                { key: 'lastDate',label: 'Última Atualização', sortable: true},
                { key: 'type',label: 'Tipo', sortable: true},

                
                // { key: 'action',   label: '',         sortable: false }
            ],
        }
    },
    props:{
        stationList:Array,
        currentPage:Number,
        perPage:Number,
        filter:[String,RegExp,Object]
    },
    computed:{
        items(){
            let list =[]
            this.stationList.forEach((v)=>{
                list.push({
                    __type:v.__type,
                    id:v.id,
                    name:v.name,
                    client:v.client,
                    workspace:v.workspace,
                    location:v.location,
                    description:v.description,
                    lastDate : v.lastDate,
                    type :v.dataOrigin=='manual'?'manual':'automatic'

                    })
            });
            return list;     
        },

    ...mapGetters({
      clients: "accounts/clients",
    }),

    },
    methods:{
        onFiltered(filteredItems) {
            this.$emit('onnumrows',filteredItems.length)
        },
        getClient(id){
            let client =  this.clients.find(e => e.id==id)

            if(client){
                return client.name
            }else{
                return id;
            }
        }
    },

}
</script> 
