<template>

<div>
  <div> Irrigação</div>
<div class="input-group">
    <div class="input-33">
      <ValidationProvider :rules="{ required: false, between: [0, 300] }" v-slot="v">
        <b-input-group  size="sm" append="mm">
          <!-- INPUT DE LAMINA -->
          <b-input @input="irrigUpdate"  :autofocus="autofocus" type="number" min="0" step="0.1" v-model="irrig" 
            :state="$validateState(v)" />
          </b-input-group>
      </ValidationProvider>
    </div>


    <!-- INPUT DE PERCENTIMETRO -->
    <div class="input-33">
      <ValidationProvider :rules="{ required: false, between: [0, 100] }" v-slot="v">
        
          <b-input-group  size="sm" append="%">
          <b-input @input="percentUpdate" :disabled="percentK == null" type="number" min="0" max="100" step="1"
            v-model="percent" size="sm" :state="$validateState(v)" />
          </b-input-group>

      </ValidationProvider>
    </div>

    <div class="input-33">
      <b-input-group  size="sm" append="h:m">
        <!-- INPUT DE TEMPO -->
        <b-input @input="timeUpdate" :disabled="timeK == null" masked="true" v-mask="'##:##'" v-model="time" size="sm" />
      </b-input-group>
    </div>

  </div>
  </div>
</template>

<script>
var hourToTime = function (v) {
  if (isNaN(v)) return null;

  const zeroPad = (num, places) => String(num).padStart(places, "0");
  let hour = Math.trunc(v);
  let min = Math.round(0.6 * ((v * 100) % 100));
  return zeroPad(hour, 2) + ":" + zeroPad(min, 2);
};

var timeToHour = function (s) {
  let v = s.split(":");
  let resp = Number(v[0]);
  if (v.length > 1) resp += v[1] * (1 / 60);
  return resp;
};

export default {
  data() {
    return {
      irrig: undefined,
      percent: 0,
      time: 0,
      hours: 0,
      percentK: null,
      timeK: null
    };
  },

  props: {
    value: [Number, String],
    autofocus:Boolean,
    field: Object
  },

  mounted() {

    if (this.field.volumeToTimeConstant != undefined) {
      this.timeK = Number(this.field.volumeToTimeConstant);
    }

    if (this.field.volumeToPercentConstant != undefined) {
      this.percentK = Number(this.field.volumeToPercentConstant);
    }

    if (this.value !== undefined) {
      this.irrig = this.value;
      this.updateDataIrrig();
    }
  },


  watch: {
    irrig(v) {
      this.$emit("input", v);
      this.$emit("irrig-hours", this.hours);
    },

  },
  methods: {
    /** atualiza os valores de percentímetros e de tempo a partir
     * do volume de irrigação
     */
    updateDataIrrig(filter) {
      let irrig = Number(this.irrig);

      if (this.timeK != null && filter !== "time") {
        let hour = irrig * this.timeK;
        if (hour < 99){
           this.time = hourToTime(hour);
           this.hours = hour
        }
      }
      if (this.percentK != null && filter !== "percent") {
        if (irrig > 0) {
          this.percent = (this.percentK / irrig).toFixed(0);
        } else {
          this.percent = 0;
        }
      }
    },

    /**
     * Sinaliza que o valor de irrigação foi modificado
     */
    irrigUpdate() {
      this.updateDataIrrig();
    },

    /**
     * Sinaliza que o valor de tempo foi modificado.
     * Se a constante de conversão de tempo for null,
     * não faz nada
     */
    timeUpdate() {
      //console.log("timeUpdate");
      if (this.timeK == null) return;

      if (this.time.length > 4) {
        let hour = timeToHour(this.time);
        this.hours = hour
        this.irrig = (hour / this.timeK).toFixed(2);
        this.updateDataIrrig("time");
      }
    },

    /**
     * Sinaliza que o valor do percentímetro foi modificado. Se a constante de
     * conversão de percentimetro for null, não faz nada
     */
    percentUpdate() {
      //console.log("percentUpdate");
      if (this.percentK == null) return;
      let percent = Number(this.percent);

      if (percent >= 0) {
        if (percent == 0) {
          this.irrig = 0;
        } else
          this.irrig = (this.percentK / percent).toFixed(2);

        this.updateDataIrrig("percent");
      }
    },

  }
};
</script>

<style lang="scss" scoped>
.input-group {
  display: flex !important;
  margin-bottom: 1rem;
}

.input-33{
  flex: 0 0 33.33333333%;
  padding-right: 2px;
  padding-left: 2px;
  margin:0;
}
</style>