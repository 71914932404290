<template>
    <div v-bind:class="{ 'non-print': !print }">


        <div class="non-print">
            <b-form-checkbox v-model="print" :value="true" :unchecked-value="false">
            imprimir
            </b-form-checkbox>
        </div>

        <b-table-simple class="table-irrig-day table-responsive-sm shadow" striped hover small>
            <b-thead>
                <b-th colspan="7">
                        {{ $filters.localeDate(day.date) }} - {{ $filters.weekFormat(day.date, 'long') }}
                </b-th>
            </b-thead>

            <b-thead>
                <b-th style="width: 200px">Equipamento - Parcela</b-th>
                <b-th style="width: 100px">Lâmina (mm)</b-th>
                <b-th style="width: 70px">%</b-th>
                <b-th style="width: auto">obs</b-th>
                <b-th style="width: 80px">Início</b-th>
                <b-th style="width: 80px">Término</b-th>
                <b-th style="width: 80px">Duraçao</b-th>
            </b-thead>

            <b-tr v-for="(field, index) in day.fields" :key="index">
                <b-td class="td-equipment">
                    <div>
                        <b-link :to="{ name: 'equipmentDetails', params: { id: field.equipmentId } }">{{
                            field.equipmentName
                        }}</b-link> -
                        <b-link :to="{ name: 'fieldDetails', params: { id: field.fieldId } }">{{
                            field.fieldName
                        }}</b-link>
                    </div>
                    <!-- <div>
                            <img v-b-tooltip.hover :title="element.cultureName" class="culture-img"
                                :src="require('@irrigcomponents/culture/assets/48/' + element.cultureIcon + '.png')" />
                            {{ element.cultureName }}
                        </div> -->
                </b-td>
                <b-td> {{ $filters.round(field.water, 1) }} </b-td>
                <b-td> {{ $filters.round(field.percent, 0) }} </b-td>
                <b-td> {{ field.comment }} </b-td>
                <b-td> {{ $filters.localeTime(field.startDateTime) }} </b-td>
                <b-td> {{ $filters.localeTime(field.endDateTime) }} </b-td>
                <b-td> {{ $filters.decimalhourToTime(field.hour) }} </b-td>
            </b-tr>
        </b-table-simple>
    </div>
</template>
<script>
export default {

    data() {
        return { print: true }
    },

    props: {
        day: { tyepe: Object }
    },


}
</script>
<style lang="scss" scoped>
.table-irrig-day {
    margin-bottom: 30px;
    width: 100%;


    td,
    th {
        text-align: center;
        border: 1px solid #9b9b9b;
    }
}
</style>