import sensor from '../sensor.js'

export default {
  extends:sensor,
  computed:{
    level(){
      return this.sensor.level
    },
    unit(){
      return this.sensor.unit
    },
    emptyLevel(){
      return this.sensor.emptyLevel
    },
    fullLevel(){
      return this.sensor.fullLevel
    },
    warningEmpty(){
      return this.sensor.warningEmpty
    },
    warningFull(){
      return this.sensor.warningFull
    }
  },
  filters:{
  }
}
