<template>
  <div>
    <b-form-group label="Data e hora">
      <b-input-group class="mt-3">
        <b-form-input
          id="input-1"
          v-model="dateTime"
          type="datetime-local"
          trim
        ></b-form-input>
        <b-input-group-append>
          <b-button variant="outline-success" @click="updateTime">
            now</b-button
          >
        </b-input-group-append>
      </b-input-group>
    </b-form-group>

    <dataStreamInsert
      v-for="dataStream in feed.dataStreams"
      :key="dataStream.id"
      :dataStream="dataStream"
      :at="at"
      :feedSerialNumber="feed.serialNumber"
    />

    <dataStreamInsert :at="at" :feedSerialNumber="feed.serialNumber" />

    <hr />
    <h5>Inserir dados através de arquivo</h5>
    <b-form-file
      v-model="file"
      :state="Boolean(file)"
      placeholder="Escolha um arquivo e solte aqui..."
      drop-placeholder="Solte um arquivo json aqui..."
    ></b-form-file>
    <div class="mt-2">
      <b-button
        variant="success"
        v-if="sendFileStatus == 'ready'"
        @click="sendJson"
      >
        enviar
      </b-button>
      <b-button v-else variant="secondary">
        <span v-if="sendFileStatus == 'reading'">lendo arquivo...</span> 
        <span v-else>enviando...</span> 
        <font-awesome-icon icon="spinner" spin />
      </b-button>

    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon';
import dataStreamInsert from "./dataStreamInsert";

export default {
  props: {
    feed: { type: Object },
  },
  data() {
    return {
      dateTime: undefined,
      file: null,
      sendFileStatus: "ready",
    };
  },
  mounted() {
    this.updateTime();
  },
  computed: {
    at() {
      return Math.round(DateTime.fromISO(this.dateTime).toSeconds());
    },
  },

  methods: {
    updateTime() {
      this.dateTime = DateTime.local().toISO({ includeOffset: false})
    },

    sendJson() {
      var self = this;
      self.sendFileStatus = "reading";
      if (this.file) {
        const reader = new FileReader();

        reader.onload = function(event) {
          var contents = event.target.result;
          let json = JSON.parse(contents);

          self.sendFileStatus = "sending";
          self.$http
            .post("/sens/feed/" + self.feed.id + "/uploadData", json)
            .then(
              () => {
                //console.log(self.file);
                self.sendFileStatus = "ready";
              },
              (error) => {
                console.error("send data error", error);
                self.sendStatus = "error";
                self.$bvModal.msgBoxOk("os dados não foram enviados", {
                  title: "Erro!",
                });
              }
            );
        };
        reader.readAsText(this.file);
      }
    },
  },
  components: {
    dataStreamInsert,
  },
};
</script>

<style></style>
