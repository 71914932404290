<template>
  <div>
    <table-export
      :items="serie"
      :fields="fields"
      :tableName="''"
      :perPage="50"
      :fileName="filename"
    >
  </table-export>

  </div>
</template>

<script>
import { DateTime } from "luxon";

const dateTimeFormat = function(value) {
  return DateTime.fromISO(value).toFormat('ddMMyyyy;HHmm');
};


const formatLevel = function(obj){

  if (obj != undefined){
    return dateTimeFormat(obj.dateTime)+";"+
    obj.nivel.toLocaleString('pt-br',{minimumFractionDigits:1,maximumFractionDigits:1})

  } else{
    return ""
  }



}

export default {
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      loadingXLS:false,
      fields: [
        { key: "flow", label: "Dados de Vazão"},
        { key: "static", label: "Nível Estático"},
        { key: "dinamic", label: "Nível Dinâmico"},
      ],
    };
  },
  props: {
    data: Array,
    filename:String
  },
  computed: {
    numRows() {
      if (this.serie !== undefined) return this.serie.length;
      else return 0;
    },

    serie(){
      let serie = []

      this.data.forEach((e) => {

        serie.push({
          flow:dateTimeFormat(e.end)+";"+this.$filters.localeNumber(e.horimetro)+";"+this.$filters.localeNumber(e.volume,2),
          static:formatLevel(e.estatico),
          dinamic:formatLevel(e.dinamico),
        })
      })


      return serie;
    }
  }
};
</script>

