<template>
  <b-modal ref="edit-modal" hide-footer scrollable @hidden="close" size="lg">
    <template v-slot:modal-header>
      <span>Editar</span>
    </template>
      <workspaceEdit :workspace="workspace" v-on:update-workspace="updateWorkspace($event)" v-on:editcancel="$router.push({name:'workspaceList'})"/>
  </b-modal>
</template>

<script>
import workspaceEdit from "./workspaceEdit";

export default {
  data() {
    return {
      workspace: {},
      loadStatus: "loading"
    };
  },

  mounted() {
    this.$refs["edit-modal"].show();
  },

  components: {
    workspaceEdit
  },

  methods: {
    close() {
      this.$router.push({ name: "workspaceList" });
    },

    updateWorkspace(workspace){
      this.$emit("update-workspace",workspace);
      this.$router.push({name:'workspaceDetails',params:{id:workspace.id}})
    },
  }
};
</script>

<style>
</style>