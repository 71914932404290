<template>

  <div>

    <b-row class="client-page-header">
      <b-col  lg="6">
        <div class="float-right">
          <b-pagination v-model="currentPage" :total-rows="numRows" :per-page="perPage"
            aria-controls="my-table"></b-pagination>
        </div>
      </b-col>

      <b-col lg="6">
        <b-form-input size="sm" v-model="filter" type="search" id="filterInput" autocomplete="off"
          placeholder="Pesquisar"></b-form-input>
      </b-col>
    </b-row>

    <b-table show-empty  small responsive :items="clientList" :fields="fields" :filter="filter"
      :current-page="currentPage" :per-page="perPage" @filtered="onFiltered">
      <template v-slot:cell(name)="row">
        <b-link :to="{ name: 'clientDetails', params: { id: row.item.id } }">{{ row.item.name }}</b-link>
      </template>

      <template v-slot:cell(consultant)="row">
        <userSymbol :user="row.item.consultant.user" />
      </template>

      <template v-slot:cell(contact)="row">
        <div>{{ row.item.phone }}</div>
        <div>{{ row.item.email }}</div>
      </template>


      <template #emptyfiltered>
        <p>Não há registros correspondentes à sua pesquisa</p>
      </template>
    </b-table>

   
  </div>
</template>

<script>
import userSymbol from "../avatar/userSymbol";

export default {
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      filter: null,
      numRows:1,
      fields: [
        { key: "name", label: "Fazenda", sortable: true },
        { key: "consultant", label: "Consultor", sortable: true, sortByFormatted: true },
        { key: "state", label: "Estado", sortable: true },
        { key: "city", label: "Cidade", sortable: true },
        { key: "contact", label: "Contato", sortable: false },
      ]
    };
  },

  methods:{

    onFiltered(filteredItems) {
      // this.$emit("onnumrows", filteredItems.length);
      this.numRows = filteredItems.length
    },
  },
  mounted(){
    this.numRows = this.clientList.length
  },

  watch:{
    clientList(v){
      this.numRows = v.length
    }
  },

  props: {
    clientList: Array,
  },

  components: {
    userSymbol
  }
};
</script>
