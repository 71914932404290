<template>
  <div id="app">
    <VueDragResize  v-for="(p,i) in positions" v-bind:key="i"
      :isActive="true"
      :isResizable="true"
      :w="p.w" :h="p.h"
      :x="p.x" :y="p.y"
      v-on:resizing="resize($event,p)"
      v-on:dragging="resize($event,p)"
    >
      <!-- <h3>Hello World!</h3>
      <p>{{ top }} х {{ left }}</p>
      <p>{{ width }} х {{ height }}</p> -->

      <div class="p-2" style="pointer-events: none;">
        <pluvioSensWidget v-bind:sensor="sensor1" :disableclick="true" />
      </div>
    </VueDragResize>


    <p> {{positions}}</p>
  </div>
</template>

<script>
// import VueDragResize from "vue-drag-resize";
import VueDragResize from "@kl-framework/components/drag-resize.vue"
import pluvioSensWidget from "@sensorcomponents/sensPluvio/pluvioSensWidget.vue";
// import { /* mapState,  */ mapGetters } from "vuex";

export default {
  name: "app",

  components: {
    VueDragResize,
    pluvioSensWidget,
  },

  data() {
    return {
      width: 0,
      height: 0,
      top: 0,
      left: 0,
      positions:[
        {x:100,y:0,w:300,h:100},
        {x:400,y:0,w:300,h:100},
        {x:700,y:0,w:300,h:100},
      ]
    };
  },

  methods: {
    resize(newRect,p) {
      p.w = newRect.width;
      p.h = newRect.height;
      p.y = newRect.top;
      p.x = newRect.left;
    },
  },

  computed: {
    // ...mapGetters({
    //   pluvioList: "sensors/pluvioList",
    // }),

    sensor1() {
      return {
        
    "type": "pluviometer",
    "id": "a95a8d0c-e3b5-49a3-b1f7-b89890df2a42",
    "name": "pluvio_101",
    "description": "Sensor de hidrômetro\n @group=06\"Pivôs pluviômetros\"",
    "feedId": "19f6cee2-6c90-4513-ac84-f1c4ea8fa9d7",
    "defaultPeriod": 168,
    "hourlyRain": [
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null
    ],
    "lastTimeSync": 1655583707000,
    "offlineTime": 1800,
    "location": {
        "ele": 750,
        "lat": -14.6478,
        "lon": -45.2422
    }
}

    },
  },
};
</script>

<style></style>
