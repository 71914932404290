<template lang="html">
  <div class="wellInfo">

    <div class="widget-box-header">
      <span class="name">{{sensor.name}}</span>
      <span class="time">
        <font-awesome-icon icon="clock"/> {{$filters.secondsToStr(elapsedTime)}}
      </span>
    </div>


    <div class="widget-box-value">
      <wellSvg :width="200" :height="100" :sensor="sensor"/>
    </div>

  </div>
</template>

<script>
import sensWell from './sensWell.js'
import wellSvg from './wellSvg'
export default {
  extends:sensWell,
  components:{
    wellSvg
  }
}
</script>

<style lang="scss" scoped>

</style>
