<template>
  <div class="cd-horizontal-timeline">
    <div class="timeline">
      <div class="events-wrapper">
        <div class="events">
            <div class="event" v-for="(e,index) in events" :key="index" :style="{width:e.width,left:e.start}">
                <div class="filling-line"></div>
                <div class="date start" :title="$filters.localeDateTime(e.startTime)"></div>
                <div class="date end"   :title="$filters.localeDateTime(e.endTime)"></div>
               
            </div>
        </div>
      </div>
    </div>
    <!-- {{ events }} -->
  </div>
</template>

<script>

import { DateTime } from 'luxon';

export default {
  props: {
    value: { type: Array, required: true },
    startTime: { type: String, required: true },
    endTime: { type: String, required: true },
  },


  computed:{
    events(){
        let events = [];

        let startSec = DateTime.fromISO(this.startTime).toSeconds();
        let endSec = DateTime.fromISO(this.endTime).toSeconds();
        let total = endSec - startSec

        this.value.forEach((e)=>{

            let ss = DateTime.fromISO(e.startTime).toSeconds()
            let es = DateTime.fromISO(e.endTime).toSeconds()

            let sp = ((ss-startSec)/total)*100
            let ep = ((es-ss)/total)*100


            events.push({startTime:e.startTime,endTime:e.endTime,start:sp+"%", width:ep+"%"});
        })

        return events;
    }
  }
};
</script>

<style lang="scss" scoped>

// $line-color: #c6538c;
$line-color: $success;
// $circle-color: $success;
$circle-color: #f8f8f8;
$line-height:10px;
$offline-line:#dfdfdf;
// $circle-color: #fd8204;
// $pin-color: $primary;
$pin-color: #fd8204;

.cd-horizontal-timeline {
  /* opacity: 0; */
  /* margin: 1em auto; */
  transition: opacity 0.2s;
  width: 100%;
//   font-size: 32.5%;

  a {
    text-decoration: none;
  }

  .timeline {
    position: relative;
    height: 50px;
    width: 100%;
    /* max-width: 800px; */
    margin: 0 auto;
  }

  .events-wrapper {
    position: relative;
    height: 100%;
    margin: 0 0px;
    overflow: hidden;
  }

  .events {
    /* this is the grey line/timeline */
    position: absolute;
    z-index: 1;
    left: 0;
    top: 20px;
    height: $line-height;
    /* width will be set using JavaScript */
    background: $offline-line;
    transition: transform 0.4s;
    margin-left: 6px;
    width: calc(100% - 12px);

    .event{
        position: absolute;
        height: 100%;
    }

    .date {
      position: absolute;
      bottom: 0;
      z-index: 2;
      text-align: center;
      font-size: 1.3rem;
      padding-bottom: 15px;
      color: #383838;
      /* fix bug on Safari - text flickering while timeline translates */
      transform: translateZ(0);
      cursor: pointer;

      &::after {
        /* this is used to create the event spot */
        content: "";
        position: absolute;
        transform: translateX(-50%);

        // left: 50%;
        // right: auto;
        // transform: translateX(-50%);
        // bottom: -5px;
        // height: 12px;
        // width: 12px;
        // border-radius: 50%;
        // border: 2px solid $line-color;
        // background-color: $circle-color;
        // transition: background-color 0.3s, border-color 0.3s;

        width: 0; 
        height: 0; 
        bottom:12px;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 10px solid $pin-color;
        
      }

      &.start {
        left: 0%;
      }

      &.end {
        left: 100%;
      }

      &.selected::after {
        background-color: #085796;
        border-color: #085796;
      }
    }
  }

  .filling-line {
    /* this is used to create the green line filling the timeline */
    position: absolute;
    z-index: 2;
    left: 0;
    top: -$line-height*0.1;
    height: 120%;
    width: 100%;
    background-color: $line-color;
    // background-color: #fd8204;
    // transform: scaleX(1);
    // transform-origin: left center;
    // transition: transform 0.3s;
  }
}

// .no-touch .cd-horizontal-timeline .events a:hover::after {
//   background-color: #085796;
//   border-color: #085796;
// }
// .cd-horizontal-timeline .events a.selected {
//   pointer-events: none;
// }
// .cd-horizontal-timeline .events a.selected::after {
//   background-color: #085796;
//   border-color: #085796;
// }
</style>
