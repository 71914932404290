<template lang="html">
<tbody v-if="dataIsValid">

  <vl-feature  :id="'station-'+feed.id" :properties="{name:feed.name, feed: feed, onClick:onClick}">
    <vl-geom-point :coordinates="[feed.location.lon,feed.location.lat]"></vl-geom-point>
    <vl-style-box :z-index="5">
       <vl-style-icon 
            :scale="1" 
            :anchor="[0.5, 1]" 
            :size="[40, 40]"
            :src="require('./assets/feedPoint.png')"
            :opacity="feed.online?1:0.3"
        ></vl-style-icon>
      </vl-style-box>
      
  </vl-feature>
</tbody>
</template>

<script>

export default {
  props: {
    feed: {type:Object, required:true}
  },
  computed: {
    dataIsValid(){
      if(this.feed.location == undefined){
          return false
      }else{
        return true
      }
    }
  },
  methods :{
    onClick(){
      this.$emit("click");
    }
  },
};
</script>

<style lang="scss" scoped>

  .popup-soil{
    width:300px;
    padding: 5px;
    cursor: pointer;
  }
  </style>
