<template>
    <simple-map mapHeight="100%" objectsVectorIdent="areaEdit">
      <template v-slot:layer>
        <selectArea v-model="editableField.geometryArea" objectsVectorIdent="areaEdit" />
      </template>


      <tbody v-for="f in fields" :key="f.id">
        <field-map :field="f"  v-if="field.id !== f.id">
        </field-map>
      </tbody>


      <tbody v-for="equipment in equipments" :key="equipment.id">
          <component :is="'map-' + equipment.type" :equipment="equipment" />
      </tbody>


      <template v-slot:no-fit-vectors>
        <clientMap :client="client" />
      </template>

    </simple-map>
</template>

<script>
import { mapGetters } from "vuex";
import selectArea from "@components/map/selectArea";
// import dripMap from "../equipment/dripMap";
import field from "./field";
import clientMap from "@accountcomponents/client/clientMap";
import fieldMap from "@irrigcomponents/field/fieldMap";
import mapPivot from "@irrigcomponents/equipment/pivotMap";
import mapDrip from "@irrigcomponents/equipment/dripMap";
import mapSprinkler from "@irrigcomponents/equipment/sprinklerMap";

export default {
  mixins: [field],

  data() {
    return {
      editableField: undefined,
    };
  },

  beforeMount() {
    this.editableField = JSON.parse(JSON.stringify(this.field));
  },

  watch: {
    editableField: {
      deep: true,
      handler(v) {
        this.$emit("update:field", v);
      },
    },
  },

  components: {
    selectArea,
    clientMap,
    "field-map": fieldMap,
    "map-Pivot": mapPivot,
    "map-Drip": mapDrip,
    "map-Sprinkler":mapSprinkler,
  },

  computed: {
    ...mapGetters({
      client: "accounts/client",
      fields: "equipments/fields",
      equipments: "equipments/all"
    }),
  },

};
</script>

<style></style>
