<template>
        <b-dropdown size="sm" split-variant="light"  variant="light">
            <template #button-content>
                <palette :colorPalette="palettes[selected]" />
            </template>
            <b-dropdown-item v-for="(p, index) in palettes" :key="index" :value="index"
            @click="selected=index">
                <palette :colorPalette="p" />
            </b-dropdown-item>
        </b-dropdown>
</template>
<script>

import palette from "./palette.vue";

export default {

    data() {
        return {
            selected: 0
        }
    },

    props: {
        value: Number,
        palettes: Array

    },

    components: {
        palette
    },

    watch: {

        selected(v) {
            this.$emit("input", v)
        },
        value(v) {
            this.selected = v
        }
    }

}
</script>
<style lang="scss" scoped>
</style>