<template>
  <b-modal ref="edit-modal" hide-footer scrollable @hidden="close" size="lg">
    <template v-slot:modal-header>
      <span>Editar</span>
    </template>
      <clientEdit :client="client" v-on:updateclient="updateClient($event)" v-on:editcancel="$router.push({name:'clientList'})"/>
  </b-modal>
</template>

<script>
import clientEdit from "./clientEdit";

export default {
  data() {
    return {
      client: { location:{ele:0,lat:0,lon:0}, membersId:[]},
      loadStatus: "loading"
    };
  },

  mounted() {
    this.$refs["edit-modal"].show();
  },

  components: {
    clientEdit
  },

  methods: {
    close() {
      this.$router.push({ name: "clientList" });
    },

    updateClient(client){
      this.$emit("updateclient",client);
      this.$router.push({name:'clientDetails',params:{id:client.id}})
    },
  }
};
</script>

<style>
</style>