<template>
  <div v-bind:class="{'sidebar-wrapper':sideBar,'top-nav-bar':!sideBar}">
    <template v-if="accountStatus == 'loaded' && pageStatus == 'loaded'" >
      <navbar v-if="!sideBar"/>
      <navsidebar v-else/>
      <navbarmobile />
      <sideBarMobile></sideBarMobile>
      <div class="body-page">
        <c-loading v-if="irrigModule == 'loading' || sensorModule == 'loading'"></c-loading>
        <router-view v-else></router-view>
      </div>

    <kl-footer></kl-footer>


    </template>
    <c-loading v-else @retry="loadAccount()" :status="accountStatus" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import navsidebar from "@components/navbar/navSideBar";
import navbar from "@components/navbar/navbar";
import navbarmobile from "@components/navbar/navbarmobile";
import sideBarMobile from "@components/navbar/sideBarMobile";
import footer from "./footer"
/***********************************************************
 *  APIs
 */
// import SensorSocket from "@components/sensors/sensorSocket";
// var sensorSocket = new SensorSocket();
import irrigation from "@components/irrigation";
import sensors from "@sensorModule";

import { defineRulesFor } from "@/config/ability";

export default {
  data() {
    return {
      pageStatus: "loading",
      irrigModule: "loading",
      sensorModule: "loading",
    };
  },
  computed: {
    ...mapGetters({
      accountStatus: "accounts/status",
      client: "accounts/client",
      workspace: "accounts/workspace",
    }),

    sideBar(){
      let options = localStorage.getItem('side-bar');

      if (options!= undefined) 
        return options 
      else 
      return false
    }

  },

  methods: {
    reconfig() {
      // console.log(">> reconfig")
      let role = this.$store.getters["accounts/role"];
      let clientId = this.$store.getters["accounts/clientId"];
      let userId = this.$store.getters["accounts/userId"];
      let workspaceId = this.$store.getters["accounts/workspaceId"];
      let rules = defineRulesFor({ role, clientId, userId, workspaceId });
      this.$ability.update(rules);

      this.irrigModule = "loading";
      irrigation().then(
        () => {
          this.irrigModule = "loaded";
        },
        (error) => {
          this.irrigModule = "error";
          console.error("config irrig module", error);
        }
      );

      this.sensorModule = "loading";
      sensors().then(
        () => {
          this.sensorModule = "loaded";
        },
        (error) => {
          this.sensorModule = "error";
          console.error("config sensor module", error);
        }
      );
    },
    loadAccount() {
      this.pageStatus = "loading";
      //  console.log(">> loading")
      this.$store.dispatch("accounts/loadAccount").then(
        () => {
          // console.log(">> loaded")
          // apiSocket.start('',store);
          this.pageStatus = "loaded";
          //this.reconfig();
        },
        (error) => {
          this.pageStatus = "error";
          if ([422, 403].includes(error.response.status)) {
            this.$router.push({
              name: "login",
              params: { nextpath: this.$route.path },
            });
          }
        }
      );
    },
  },

  watch: {
    // client(oldValue,newValue){
    //   this.reconfig();
    // },
    workspace() {
      // console.log(">> workspace change", oldValue, newValue)
      this.reconfig();
    },
  },

  beforeMount: function () {
    this.loadAccount();
  },
  components: {
    navsidebar,
    navbar,
    navbarmobile,
    "kl-footer":footer,
    sideBarMobile
  },
};
</script>

<style lang="scss">
.top-nav-bar {
  .body-page {
    padding-top: $navbar-height;
  }
}



.loading-page {
  text-align: center;
}

.body-page {
  $footer-height: 37px;

  min-height: calc(100vh - $footer-height); 
  @media screen and (max-width: $breakpoint-sm) {
    //  height: calc(100vh - var(--navbar-height) + var(--mobilebar-height));
    padding-bottom: $mobilebar-height;
  }
}
</style>
