import clientPageList from './clientPageList'
import clientPageDetails from './clientPageDetails'
import clientPageNew from './clientPageNew'

export default {
  path: 'client',
  component: clientPageList,
  name: 'clientList',
  meta: {authorize:{action:'read',resource:'Client'}},
  children: [
    {
      path: ':id', name: 'clientDetails', component: clientPageDetails,
      props: (route) => ({ clientId: route.params.id }),
    },
    {
      path: 'new', name: 'clientNew', component: clientPageNew,
      meta: {authorize:{action:'create',resource:'Client'}},
    }
  ]
}