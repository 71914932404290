<template>
    <table-export
      class="mt-4"
      :items="dataPoints"
      :fields="fields"
      :tableName="'Umidades'"
      :perPage="50"
      :fileName="name"
    >
    </table-export>
</template>

<script>
import { DateTime } from "luxon";


const dateTimeFormat = function(value) {
  return DateTime.fromMillis(value).toLocaleString({ month: '2-digit', day: '2-digit', year:'2-digit', hour: '2-digit', minute: '2-digit', hourCycle: 'h23' });
};

const exlDateTimeFormat = function(value) {
  return DateTime.fromMillis(value).toJSDate()
};

var roundFormatter = function(value) {
  if (typeof value !== 'number') return value;
  return value.toLocaleString('pt-br',{minimumFractionDigits:2,maximumFractionDigits:2})
};

export default {
  data() {
    return {

    };
  },
  props: {
    data: Array,
    sensors:Array,
    name:String
  },
  computed: {

    fields(){
      let fields = [
        {
          key: "time",
          label: "Hora",
          sortable: true,
          class: "text-nowrap",
          formatter: dateTimeFormat,
          exlformatter:exlDateTimeFormat
        }]

        this.data.forEach((e1,index) => {
          fields.push({ key: "v"+index, label: e1.depth+"cm ("+e1.unit+")",  sortable: true,formatter: roundFormatter,exlformatter:function(e){return e}})
        });

        return fields;
    },

    dataPoints(){

      let dict = {}

      this.data.forEach(e =>{
        e.dataPoints.forEach(e2 => {
          let v = dict[e2.ux];
          if(v==undefined)
            dict[e2.ux] = [e2.v1];
          else
            dict[e2.ux].push(e2.v1);
        })
      })
      
      let output = [];

      for (const [key, value] of Object.entries(dict)) {
        let obj = {time:Number(key)*1000};
        value.forEach((e,i) => {
          obj["v"+i]= e;
        })
        output.push(obj)
      }
    return output
    }

  }
};
</script>
