<template>
  <div style="width: 100%">
    <v-chart :option="serie" autoresize />
  </div>
</template>

<script>
import { DateTime } from "luxon";
import {flagFormat} from "./utils.js"

var dateFormat = function (value) {
  return DateTime.fromISO(value).toLocaleString();
};

export default {
  props: {
    data: Array,
    projection: Array,
    field: { type: Object, required: true },
  },
  computed: {
    serie: function () {
      let list = this.data;
      let numDays = this.data.length

      let numPredictionDays = 0
      if (this.projection != undefined) {
        numPredictionDays = this.projection.length
        list = list.concat(this.projection);
      }


      let series = [];
      let humanSensorialMarks = [];
      let cultureStages = [];
      let flagMarks =[]

      //define quantos dias passou desde a data de plantio
      let a = DateTime.fromISO(this.field.startDate);
      let b = DateTime.fromISO(this.field.datePlanting);
      let day = b.diff(a, "days").toObject().days;

      this.field.culture.stages.forEach((e) => {
        let stage = {
          xAxis: day,
          label: { formatter: e.name, show: true, position: "insideEndBottom" },
        };
        cultureStages.push(stage);
        day += e.durationDays;
      });

      list.forEach(function (v, index) {
        if (v.humanSensorial != null) {
          humanSensorialMarks.push({
            value: "M",
            xAxis: index,
            yAxis: v.laa == null ? 0 : v.laa,
          });
        }

        if (v.humanSensorialFinal != null) {
          humanSensorialMarks.push({
            value: "MF",
            xAxis: index,
            yAxis: v.laa == null ? 0 : v.laa,
          });
        }
      });

      list.forEach(function (v, index) {
        if (v.irrigFlag != null) {
          flagMarks.push({
            // value: v.irrigFlag,
            xAxis: index,
            yAxis: 0
          });
        }
      });

      series.push({
        name: "Lâmina Disponível",
        data: list.map((item) => item.laa),
        // tooltip:{formatter: '{b}: {c}<br />{b}: {c}'},
        type: "line",
        symbol: "none",
        color: "#007399",
        smooth: true
      });

      series.push({
        name: "Irrig",
        data: list.map((item) => item.irrig),
        type: "bar",
        symbol: "none",
        stack: "rainirrig",
        color: "#53C815",
      });

      series.push({
        name: "Chuva",
        data: list.map((item) => item.rain),
        type: "bar",
        symbol: "none",
        stack: "rainirrig",
        color: "#00ace6",
      });

      series.push({
        name: "Excesso",
        data: list.map((item) => item.excess),
        type: "bar",
        symbol: "none",
        color: "#ff6600",
        // barWidth: '20%',
        barGap: '-30%',
      });

      series.push({
        name: "CTA",
        data: list.map((item) => item.cta),
        type: "line",
        symbol: "none",
        color: "#c5eea5",
        z: -2,
        smooth: true,
        areaStyle: { opacity: 0.5, color: "#c5eea5" },
        lineStyle: { normal: { opacity: 1 } },
      });

      series.push({
        name: "estresse",
        data: list.map(function (item) {
          return item.cta && item.cra ? item.cta - item.cra : undefined;
        }),
        type: "line",
        symbol: "none",
        color: "#FFAF8C",
        z: -1,
        smooth: true,
        areaStyle: { opacity: 0.8, color: "#ffd699" },
        lineStyle: { type: "doted" },
        // lineStyle: { normal: { opacity: 0 } }
      });

      series.push({
        name: "ET0",
        data: list.map((item) => item.et0),
        type: "line",
        symbol: "none",
      });

      series.push({
        name: "ETC",
        data: list.map((item) => item.etc),
        type: "line",
        symbol: "none",
      });

      series.push({
        name: "ETPC",
        data: list.map((item) => item.etpc),
        type: "line",
        symbol: "none",
      });

      series.push({
        name: "IRN",
        data: list.map((item) => item.irn),
        type: "line",
        symbol: "none",
        smooth: true,
        color: "#F00",

      });

      series.push({
        name: "Evento",
        // data: list.map(item => item.humanSensorial),
        type: "line",
        symbol: "none",
        color: "#800",
        markPoint: {
          symbol: "pin",
          itemStyle:{
           shadowColor: 'rgba(0, 0, 0, 0.6)', shadowBlur: 5,shadowOffsetX:2
          },
          symbolSize: 35,
          data: humanSensorialMarks,
        },
      });


      series.push({
        name: "Flags",
        data: list.map(item => flagFormat(item.irrigFlag)),
        type: "line",
        symbol: "none",
        color: "#fa0",
        markPoint: {
          itemStyle:{
           color:'#e20',
           borderColor:'#fff', borderWidth:0.6,
           shadowColor: 'rgba(0, 0, 0, 0.6)', shadowBlur: 5,shadowOffsetX:2
          },
          symbolOffset:[0,'-52%'],
          symbol: "path://M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z",
          symbolSize: 20,
          data: flagMarks,
        },
      });



      series.push({
        name: "Estágio",
        type: "line",
        color: "#009900",
        z: -2,
        markLine: {
          symbol: ["none", "pin"],
          symbolSize: 20,
          data: cultureStages,
        },
      });


      if (numPredictionDays > 0) {
        series.push({
          type: "line",
          color: "#800",
          z: -3,
          markArea: {
            itemStyle: {
              borderType: 'dotted',
              color: 'rgba(250, 250, 250,0.6)',
              borderColor: "#ea4",
              borderWidth: 2,
            },
            data: [
              [
                {
                  name: 'Próximos ' + numPredictionDays + ' dias',
                  xAxis: numDays
                },
                {
                  xAxis: list.length
                }
              ],

            ]
          }
        });
      }

      // series.push({
      //   name: "LaminaTotal",
      //   data: list.map(item => item.laminaTotal),
      //   type: "line",
      //   symbol: "none",
      //   smooth: true
      // });

      // series.push({
      //   name: "rootDepth",
      //   data: list.map(item => item.rootDepth),
      //   type: "line",
      //   symbol: "none"
      // });

      return {
        // title: {
        //   text: "Histórico"
        // },
        // grid: { left: 50, right: 5, bottom: "100" },

        // aria:{
        //   decal:{
        //     show:true,
        //   }
        // },
        grid: [
          {
            top: 20,
            left: 0,
            right: 10,
            bottom: 100,
            containLabel: true,
            // height: "50%",
            // show: true,
            // backgroundColor: "#fff",
            // z: -3,
            // containLabel: true
          },
          //{
          //   left: 60,
          //   right: 0,
          //   bottom: "25%",
          //   height: "10%",
          //   containLabel: true,
          // },
        ],

        dataZoom: [
          // {
          //   xAxisIndex: [0, 1],
          //   type: "inside",
          //   show: true,
          //   realtime: true,
          //   start: 0,
          //   end: 100,
          // },
          {
            show: true,
            xAxisIndex: [0, 1],
            type: "slider",
            // top: "83%",
            height: 20,
            bottom: "15%",
          },
        ],
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(245, 245, 245, 0.8)",
          borderWidth: 1,
          borderColor: "#ccc",
          padding: 10,
          textStyle: { color: "#000" },
          valueFormatter: (v) => this.$filters.localeNumber(v, 2),
          axisPointer: {
            type: "cross",
            label: { show: true, precision: 2 }
          },
        },
        axisPointer: {
          link: { xAxisIndex: "all" },
          label: {
            backgroundColor: "#777",
          },
        },
        legend: {
          bottom: 0,
          selected: {
            IRN: false,
            ETPC: false,
            ETC: false,
            ET0: false,
            Flags: true,
            Excesso:false

            // CRA: true,
            // CTA: true,
            // Et0: false,
            // etc: false,
            // etpc: false,
            // irn: false,
            // irrig: true,
            // rain: true,
            // kc: false,
            // // rootDepth: false,
            // laa: true,
            // // laminaTotal: false
          },
        },
        xAxis: [
          {
            type: "category",
            data: list.map((item) => dateFormat(item.date)),
            alignTicks: true,
          },
          // {
          //   alignTicks: true,
          //   type: "category",
          //   data: list.map((item) => dateFormat(item.date)),
          //   gridIndex: 1,
          //   scale: true,
          //   // boundaryGap: false,
          //   axisLine: { onZero: false },
          //   axisTick: { show: false },
          //   splitLine: { show: false },
          //   axisLabel: { show: false },
          //   splitNumber: 20,
          // },
        ],
        yAxis: [
          {
            type: "value",
            // max: 50,
            // splitArea: { show: false },
            axisLabel: {
              formatter: "{value} mm",
            },
          },
          // {
          //   type: "value",
          //   scale: true,
          //   gridIndex: 1,
          //   splitNumber: 2,
          //   axisLabel: { show: false },
          //   axisLine: { show: false },
          //   axisTick: { show: false },
          //   splitLine: { show: false },
          // },
        ],
        series: series,


        // visualMap:{ 
        //   show:true,
        //   dimension: 0,

        //   pieces:[
        //     {min: 0, 
        //       max: numDays,
        //       colorSaturation:1,
        //       // colorLightness:0.5
        //       // opacity:1
        //       colorAlpha:1

        //     },
        //     {
        //       min: numDays,
        //       colorSaturation:0,
        //       // colorLightness:1
        //       // opacity:0.5
        //       colorAlpha:0.5
        //     }
        //     ]
        // }

        // visualMap: {
        //   type: 'piecewise',
        //   show: false,
        //   dimension: 0,
        //   seriesIndex: 1,
        //   pieces: [
        //     {
        //       lt: numDays,
        //       color: 'rgba(0, 0, 180, 0.4)'
        //     },
        //     {
        //       gt: numDays,
        //       color: 'rgba(0, 0, 180, 0.4)'
        //     }
        //   ]
        // },


      };
    },
  },
};
</script>

<style lang="scss" scoped>
.echarts {
  width: 100%;
  height: 420px;
}
</style>
