<template lang="">
  <pageTemplate :sensor="sensor">
    <template v-slot:sensor>
      <div class="svg-image water-sensor-Color">
        <hydroFlow width="100%" :sensor="sensor" />
      </div>
    </template>

    <template v-slot:info>
      <div class="details-box">
        <div class="mb-2">
          Acumulado
        </div>
        <div class="widget-box-value mx-2">
          <span class="label">Diário </span>
          <span class="value">
            {{ $filters.localeNumber(totalDay,0) }}<small> {{ sensor.unit }} </small>
          </span>
        </div>
        <div class="widget-box-value mx-2">
          <span class="label">Mensal</span>
          <span class="value">
            {{ $filters.localeNumber(totalMonth,0) }}<small> {{ sensor.unit }} </small>
          </span>
          <value-bar v-if="maxMonth" :value="totalMonth" :max="maxMonth" />
        </div>
      </div>
    </template>

    <b-card no-body>
    <b-tabs card small>
      <!-- ABA DE VAZÂO -->
      <b-tab title="Vazão" active>
        <div class="d-flex flex-wrap mb-2">
          <div>
            <h5>Histórico de Vazão</h5>
          </div>
          <div class="ml-auto">
            <data-range v-model="dateRange" />
          </div>
        </div>

        <div v-if="loadStatus == 'loaded'">
          <div class="flow-chart">
            <flowChart :data="data.hydrometers" :unit="unit + '/h'" />
            <div v-for="(h,i) in data.hydrometers" :key="i">
              <hr>
              <flowTable :sensorName="sensor.name"  :hidrometer="h" :unit="unit + '/h'"/>
            </div>
          </div>
        </div>
        <c-loading v-else :status="loadStatus"></c-loading>
      </b-tab>

      <!-- ABA DE VOLUME diário -->
      <b-tab title="Diário" :lazy="dailyLazy" @click="dailyLazy=false">
        <hydroPageDaily :sensor="sensor" />
      </b-tab>

      <!-- ABA DE VOLUME Mensal-->
      <b-tab title="Mensal" :lazy="mensalLazy" @click="mensalLazy=false">
        <hydroPageMonthy :sensor="sensor" />
      </b-tab>

      <!-- ABA DE dados do INEMA-->
      <b-tab title="Relatório" :lazy="inemaLazy" @click="inemaLazy=false">
        <hydroPageInema :sensor="sensor" />
      </b-tab>
      
    </b-tabs>
  </b-card>
  </pageTemplate>
</template>

<script>
import { DateTime } from "luxon";

import flowTable from "./flowTable.vue";
import sensHydro from "./sensHydro";
import pageTemplate from "../sensorDetailsTemplate";
import hydroFlow from "./hydroFlow";
import flowChart from "./flowChart";
import hydroPageDaily from "./hydroPageDaily";
import hydroPageMonthy from "./hydroPageMonthy";
import hydroPageInema from "./hydroPageInema";

export default {
  extends: sensHydro,

  data() {
    return {
      loadStatus: "loading",
      data: [],
      dateRange: null,
      mensalLazy : true,
      dailyLazy  : true,
      inemaLazy  : true
    };
  },
  computed: {
    chartData() {
      return this.data;
    },
  },
  mounted() {
    let end = new Date(Date.now());
    end.setHours(0, 0, 0, 0);
    let start = new Date(Date.now());
    start.setHours(start.getHours() - this.sensor.defaultPeriod);
    start.setHours(0, 0, 0, 0);
    this.dateRange = [start, end];
  },

  methods: {
    /**
     * função paraload de série de vazão
     */
    loadData() {
      this.loadStatus = "loading";
      let start = Math.round(
        DateTime.fromJSDate(this.dateRange[0]).toSeconds()
      );
      let end = Math.round(
        DateTime.fromJSDate(this.dateRange[1])
          .plus({ days: 1 })
          .toSeconds()
      );
      this.$http
        .get(
          "sens/sensor/" +
            this.sensorId +
            "/serie?start=" +
            start +
            "&end=" +
            end
        )
        .then(
          (data) => {
            this.data = data;
            this.loadStatus = "loaded";
          },
          (error) => {
            console.error("load hydrometer sensor error:", error);
            this.loadStatus = "error";
          }
        );
    },
  },

  watch: {
    dateRange() {
      this.loadData();
    },
  },

  components: {
    pageTemplate,
    hydroFlow,
    flowChart,
    hydroPageDaily,
    hydroPageMonthy,
    flowTable,
    hydroPageInema
  },
};
</script>

<style lang="scss" scoped>
.flow-chart {
  margin: 1rem 0px;
}

.details-box {
  .progress {
    background: rgba(0, 0, 0, 0.2);
    margin: 2px -10px 5px -10px;
    height: 2px;
  }
}
</style>
