import sensor from '../sensor.js'

export default {
  extends:sensor,

  data(){
    return {
      hourly : [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
    }
  },

  mounted(){
    if(this.sensor.hourlyRain != undefined){
      this.hourly = this.sensor.hourlyRain;
    }
  },

  watch:{
    sensor:{
      deep: true,
      handler() {
        if(this.sensor.hourlyRain != undefined){
          this.hourly = this.sensor.hourlyRain;
        }
      }
    }
  },

  computed:{
    precipitation(){
      let total = 0;
      if(this.hourly != undefined){
          this.hourly.forEach(function(e){
            total += e
          })
        }
      return total;
    }
  }
}
