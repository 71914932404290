<template>
    <table-export
      small
      :items="dataPoints"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      :fileName="sensorName+'_'+hidrometer.name+'_vazão'"
      :tableName="hidrometer.name"
    >
    </table-export>
</template>

<script>
import { DateTime } from "luxon";


const dateTimeFormat = function(value) {
  return DateTime.fromISO(value).toLocaleString({ month: '2-digit', day: '2-digit', year:'2-digit', hour: '2-digit', minute: '2-digit', hourCycle: 'h23' });
};

var roundFormatter = function(value) {
  if (typeof value !== 'number') return value;
  return value.toLocaleString('pt-br',{minimumFractionDigits:2,maximumFractionDigits:2})
};

export default {
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      loadingXLS:false,
      fields: [
        {
          key: "time",
          label: "Hora",
          sortable: true,
          class: "text-nowrap",
          formatter: dateTimeFormat,
        },
        { key: "value", label: "vazão ("+this.unit+")",  sortable: true,formatter: roundFormatter},
        { key: "value2", label: "volume (m³)",  sortable: true,formatter: roundFormatter},
        // { key: "volume2", label: "Volume2 ("+this.unit+")",  sortable: true,formatter: roundFormatter},
        // { key: "goal",  label: "meta ("+this.unit+")", sortable: false, formatter: roundFormatter},
      ],
    };
  },
  props: {
    hidrometer: Object,
    unit:String,
    sensorName:String
  },
  computed: {
    dataPoints(){
      return this.hidrometer.dataPoints
    },
    numRows() {
      if (this.dataPoints !== undefined) return this.dataPoints.length;
      else return 0;
    },
  }
};
</script>
