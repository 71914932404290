import pageSettings from './pageSettings'
import userAccount  from './account/routes'
import clients      from './client/routes'
import workspace    from './workspace/routes'
import user         from './user/routes'
import {member,membernew, memberInvite} from './member/routes'


export default {path:'/settings', component:pageSettings,
      children:[
        userAccount,
        clients,
        workspace,
        user,
        member,
        membernew,
        memberInvite
      ]
    }