<template>
  <b-container class="client-page">
    <div class="header">
      <h2>Clientes</h2>
      <small>
        <font-awesome-icon class="mr-1" icon="info-circle" />Lista de todos os
        clientes cadastrados no sistema
      </small>
    </div>

    <!-- LISTA -->
    <div v-if="loadStatus == 'loaded'">
      <div class="mb-2">
        <simple-map>
          <clientMap
                v-for="client in clientList"
                :key="client.id"
                :client="client"
                @click="$router.push({name:'clientDetails',params: { id: client.id }})"
              />
        </simple-map>
      </div>

      <!-- MENU de ferramentas  -->
      <b-row class="client-page-header">
        <b-col sm="6" v-if="$can('create', 'Client')">
          <b-button size="sm" @click="newClient" variant="success">Novo Cliente</b-button>
        </b-col>
      </b-row>


      <b-card class="shadow" no-body>
        <b-tabs card content-class="mt-3">
          <b-tab :title="`Ativos (${activeClients.length})`" active>
            <clientTableList
              :clientList="activeClients"
            />
         </b-tab>

         <b-tab :title="`Inativos (${disabledClients.length})`" >
            <clientTableList
              :clientList="disabledClients"
            />
         </b-tab>
        </b-tabs>
      </b-card>


      <router-view
        v-on:updateclient="updateList($event)"
        v-on:removeclient="removeClient($event)"
      ></router-view>
    </div>
    <c-loading @retry="loadData()" :status="loadStatus" v-else></c-loading>
  </b-container>
</template>

<script>
//usado apenas para testes. comente esta linha caso não esteja testando
//import dataTeste from "./testes/data.json";

import clientTableList from "./clientTableList";
import clientMap from "./clientMap";


export default {
  data() {
    return {
      clientList: [],
      loadStatus: String,
    };
  },
  props: {
    editable: { type: Boolean, default: true },
  },
  beforeMount() {
    this.loadData();
  },
  components: {
    clientTableList,
    clientMap,
  },

  computed:{
    // activeList(){
    //   return this.clientList.map(function(it){
    //       return {
    //         id:it.id,
    //         name:it.name,
    //         consultant:it.consultant,
    //         state:it.state,
    //         city:it.city,
    //         email:it.email,
    //         phone:it.phone,
    //       }
    //   });
    // }
    activeClients() {
      return this.clientList.filter(e => e.active);
    },
    disabledClients() {
      return this.clientList.filter(e => !e.active);
    }
  },

  methods: {
    loadData() {
      this.loadStatus = "loading";
      this.$http.get("account/client").then(
        (data) => {
          this.clientList = data;
          this.loadStatus = "loaded";
        },
        (error) => {
          console.error("client error:", error);
          this.loadStatus = "error";
        }
      );
    },

    onMapSingleClick() {},


    //atualiza a lista de usuários
    updateList(newClient) {
      const index = this.clientList.findIndex((e) => e.id === newClient.id);
      if (index === -1) {
        this.clientList.push(newClient);
      } else {
        let dataObj = this.clientList[index];
        Object.entries(newClient).forEach(([key, value]) => {
          dataObj[key] = value;
        });
      }
    },

    removeClient(id) {
      this.$deleteConfirm
        .show(
         "excluir-cliente",
        {excludeObjects:["Cliente","Equipamentos","Parcelas","Históricos"]}
        )
        .then((value) => {
          if (value === true) {
            this.$http.delete("account/client/" + id).then(
              () => {
                let index = this.clientList.findIndex((e) => e.id === id);
                this.clientList.splice(index, 1);
                this.$router.push({ name: "clientList" });
              },
              (error) => {
                console.error("remove Client error:", error);
                //API error
              }
            );
          }
        })
        .catch((err) => {
          console.error("remove Client error:", err);
          // An error occurred
        });
    },

    newClient() {
      this.$router.push({ name: "clientNew" });
    },
  },
};
</script>

<style lang="scss" scoped>
.client-page {
  padding-top: 30px;

  .header {
    margin: 0 0 20px;
    small {
      color: #666;
    }

    h2 {
      font-family: "Open Sans", Helvetica, Arial, sans-serif;
      color: #444;
      font-size: 27px;
      font-weight: 700;
      line-height: 27px;
      margin: 0;
    }
  }

  .client-page-header {
    padding-bottom: 10px;
  }
}
</style>
