export default {
  props:{
    sensor:{ type: Object, required: true}
  },

  data(){
    return {
      receiving:false
    }
  },

  watch:{
    sensor:{
      deep: true,
      handler() {
        this.receiving = true;
        setTimeout(() => {
          this.receiving = false;
        }, 5000);
      }
    }
  },
  
  computed:{
    sensorType(){
      return this.sensor.type
    },

    sensorId(){
      return this.sensor.id
    },
    //indica se o sensor está online
    isOnline(){
      if(!this.sensor.lastTimeSync)
        return false;

      return this.elapsedTime < this.sensor.offlineTime
    },
    //indica quantos segundos se passaram desde a ultima comunicação do sensor
    elapsedTime(){
      let time = (this.$store.state.sensors.serverTime -  this.sensor.lastTimeSync)/1000;
      if(time > 0)
        return time;
      else
        return 0;
    }

  },
}
