<template>
  <pageTemplate :sensor="sensor">

    <template v-slot:sensor>
      <div class="svg-image pivot-sensor-Color">
        <pivot-svg
          v-if="pivot != undefined"
          :width="'100%'"
          :height="180"
          :angle="angle"
          :percent="percent"
          :areaStart="areaStart"
          :areaEnd="areaEnd"
          :irrigStart="irrigStart"
          :irrigDist="irrigDist"
          :startAngle="startAngle"
          :status="status"
        />
      </div>
    </template>

    <template v-slot:info>
      <div>
        <ul class="sensor-info-group">
          <li>
            <strong>Status</strong>
            <span>{{ status }}</span>
          </li>
          <li>
            <strong>Posição</strong>
            <span>{{ $filters.round(angle,1) }} °</span>
          </li>
          <li>
            <strong>Percentímetro</strong>
            <span>{{ $filters.round(percent,0) }} %</span>
          </li>
          <li>
            <strong>Início</strong>
            <span>{{ $filters.localeDateTime(irrigStartTime) }}</span>
          </li>
          <li>
            <strong>Pressão</strong>
            <span>{{ $filters.round(pressure,1) }} {{ pressureUnit }}</span>
          </li>
        </ul>
        <!-- <pivotController /> -->
      </div>
    </template>

    <template v-slot:tools>
      <div>
        <b-button
          v-if="$can('edit', 'sensors')"
          v-b-tooltip.hover
          title="Reprocessar todas as irrigações a partir dos dados do sensor"
          variant="warning"
          @click="irrigationsReprocess"
        >
        Reprocessar irrigações
          <!-- <font-awesome-icon icon="exclamation-triangle" /> -->
        </b-button>
      </div>
    </template>

    <b-card no-body>
    <b-tabs card content-class="mt-3">

      <b-tab title="histórico" active>
        <div class="d-flex flex-wrap">
          <div>
            <h5>Histórico de irrigações</h5>
          </div>
          <div class="ml-auto">
            <b-button size="sm" variant="outline-primary" @click="$printDiv('printDiv')" title="imprimir">
              <font-awesome-icon icon="print" />
            </b-button>
          </div>

          <div class="ml-2">
            <data-range v-model="dateRange" />
          </div>
        </div>

        <hr/>
        <div id="printDiv" v-if="loadStatus == 'loaded'">
          <!-- {{data}} -->
          <report-header
            :dateInterval="dateInterval"
            :label="'Relatório de irrigação'"
            :title="'Pivô'"
          />

          <div v-if="irrigCount > 0">
            <b-row>
              <b-col md="6" lg="5" class="mb-3">
                <h6>Total de irrigação</h6>
                <irrigChart
                  :startAngle="startAngle"
                  :data="data.totalIrrigByAngle"
                />
              </b-col>
              <b-col md="6" lg="3" class="mb-3">
                <h6>Pressão na ponta</h6>
                <pressureChart
                  :unit="'mca'"
                  :startAngle="startAngle"
                  :data="data.pressureAvgByAngle"
                />
              </b-col>
              <b-col md="6" lg="4" class="mb-3">
                <h6>Horários</h6>
                <timeChart :data="data.totalTimeGroup" />
              </b-col>
            </b-row>
            <hr />

            <irrigation-time-line
              :startAngle="startAngle"
              :irrigations="data.irrigations"
            />
          </div>
          <b-alert v-else show variant="info" class="pr-5">
            <div class="d-flex">
              <div class="mr-2" style="font-size:35px;">
                <font-awesome-icon icon="info-circle" />
              </div>
              <div>
                <p>
                  Sem irrigações no período selecionado.
                </p>
                <p>
                  Para visualizar as informações de irrigação deste sensor,
                  selecione um intervalo de data que exista irrigações.
                </p>
              </div>
            </div>
          </b-alert>
        </div>
        <c-loading v-else :status="loadStatus"></c-loading>
      </b-tab>

      <b-tab  title="Dados" >
        <dataAnalysis :pivot="pivot" />
      </b-tab>


    </b-tabs>
    </b-card>
  </pageTemplate>
</template>

<script>
import { DateTime } from "luxon";

import pivotSvg from "./pivotSvg";
//import waterChart from "./waterChart";
import pivot from "./pivot";
// import pivotController from "./pivotController";

import timeChart from "./charts/timeChart";
import pressureChart from "./charts/pressureChart";
import irrigChart from "./charts/irrigationChart";
// import irrigTable from "./irrigTable";
import irrigationTimeLine from "./irrigationTimeLine";

import dataAnalysis from "./dataAnalysis.vue"

import pageTemplate from "../sensorDetailsTemplate";

import reportHeader from "@reportcomponents/comum/reportHeader.vue";

export default {
  extends: pivot,

  data() {
    return {
      loadStatus: "loading",
      data: [],
      dateRange: null,
    };
  },
  computed: {
    chartData() {
      return this.data;
    },

    irrigCount() {
      if (!this.data || !this.data.irrigations) return 0;
      else return this.data.irrigations.length;
    },
    dateInterval(){
      return {
        start:DateTime.fromJSDate(this.dateRange[0]).toISO({
        includeOffset: false,
        }),
        end:DateTime.fromJSDate(this.dateRange[1]).toISO({
        includeOffset: false,
        }),
      }
     }

  },
  mounted() {
    let end = DateTime.local()
      .endOf("day")
      .toJSDate();
    let start = DateTime.local()
      .plus({ hours: -this.pivot.defaultPeriod })
      .toJSDate();
    this.dateRange = [start, end];
  },

  methods: {
    loadData() {
      this.loadStatus = "loading";
      let start = DateTime.fromJSDate(this.dateRange[0]).toISO({
        includeOffset: false,
      });
      let end = DateTime.fromJSDate(this.dateRange[1])
        .plus({ days: 1 })
        .toISO({ includeOffset: false });

      this.$http
        .get(
          "sens/pivot/" +
            this.sensorId +
            "/complete?startTime=" +
            start +
            "&endTime=" +
            end
        )
        .then(
          (data) => {
            this.data = data;
            this.loadStatus = "loaded";
          },
          (error) => {
            console.error("load pivot sensor error:", error);
            this.loadStatus = "error";
          }
        );
    },

   

    irrigationsReprocess() {
      this.loadStatus = "loading";
      this.$http.get("sens/pivot/" + this.sensorId + "/irrigreprocess").then(
        () => {
          this.loadData();
        },
        (error) => {
          console.error("load pivot sensor error:", error);
          this.summaryStatus = "error";
        }
      );
    },
  },

  watch: {
    dateRange() {
      this.loadData();
    },
  },

  components: {
    pivotSvg,
    // waterChart,
    timeChart,
    pressureChart,
    irrigChart,
    irrigationTimeLine,
    // pivotController,
    pageTemplate,
    dataAnalysis,
    reportHeader
  },
};
</script>

