
import { DateTime } from 'luxon';


function currency (value,digits=2) {
  if (typeof value !== "number") {
      return value;
  }
  var formatter = new Intl.NumberFormat('pt-br', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: digits
  });
  return formatter.format(value);
}


function localeNumber(value,digits=1){
  if (typeof value !== 'number') return value;
  return value.toLocaleString('pt-br',{minimumFractionDigits:digits,maximumFractionDigits:digits})
}


function round(value, accuracy, keep){
  if (typeof value !== 'number') return value;
  let fixed = value.toFixed(accuracy);
  return keep ? fixed : +fixed;
}


// formata segundos em minutos ou horas
function secondsToStr(value){
  if (typeof value !== 'number') return value;
  if(value < 60){
      return  Math.trunc(value)+"s";
  }else
  if(value >= 60 && value <3600){
      return Math.trunc(value/60)+"m"
  }else
  if(value >=3600 && value<(3600*24)){
      return Math.trunc(value/3600)+"hs"
  }else{
      let v = Math.trunc(value/(3600*24));
      return v+ " dia"+(v>1?'s':'');
  }
}





/**
 * converte horas de decimal para tempo
 * @param s separarador de horas
 */
function decimalhourToTime(v,sep=':',sep2=''){
  if (v==undefined)
    return null;
    
  if (isNaN(v)) return v;
  const zeroPad = (num, places) => String(num).padStart(places, "0");
  let hour = Math.trunc(v);
  let min = Math.round(0.6 * ((v * 100) % 100));
  return zeroPad(hour, 2) + sep + zeroPad(min, 2)+sep2;
}


function ptbrdate(value){
  if(value != null){
    return DateTime.fromISO(value).toFormat('dd/MM/yy'); 
  }else{
    return '-'
  }
}

var longMonth = function(value) {
  let _value = (typeof value === 'object')?value.value:value
  return DateTime.fromFormat(_value, "yyyy-MM").toLocaleString(
    {month: "long",year: "numeric"}
  );
};

function localeDate(value){
  if(value != null){
    return DateTime.fromISO(value).toLocaleString()
  }else{
    return '-'
  }
}

function longDateFormat(value){
  if(value != null){
    return DateTime.fromISO(value).toLocaleString(DateTime.DATE_FULL)
  }else{
    return '-'
  }
}

function weekFormat(date,format="short") {
  return DateTime.fromISO(date).toLocaleString({ weekday: format})
}

function dayFormat(date) {
  return DateTime.fromISO(date).toLocaleString({ day: '2-digit' })
}

function localeDateTime(value){
  if(value != null){
    return DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)
  }else{
    return '-'
  }
}

function localeTime(value){
  if(value != null){
    return DateTime.fromISO(value).toLocaleString(DateTime.TIME_24_SIMPLE)
  }else{
    return '-'
  }
}

function ptbrdatehour(value){
  if(value != null){
    return DateTime.fromISO(value).toFormat('dd/MM/yy HH:mm:ss'); 
  }else{
    return '-'
  }
}

// function formatFromServe(value){
//   if(value[0] == '@'){
// 					var token = value.split('|');
// 					if(token[0] === "@unixtotime"){
// 							return moment.unix(token[2]/1000).format(token[1]);
// 					}else
//           if(token[0] === "@badge"){
//               return "<span class='badge badge-"+token[1]+"'>"+ token[2] +"</span>"
//           }
// 		}else{
//       return value;
//     }
// }


function capitalize(value) {
    if (!value) return ''
    value = value.toString()
    return value.charAt(0).toUpperCase() + value.slice(1)
  }


function dateNow(){
  return DateTime.now().toISO({
    includeOffset: false,
  });
}


export{
  round,
  secondsToStr,
  ptbrdate,
  /* formatFromServe, */
  capitalize,
  decimalhourToTime,
  ptbrdatehour,
  localeDate,
  localeDateTime,
  currency,
  localeNumber,
  longMonth,
  localeTime,
  weekFormat,
  dayFormat,
  longDateFormat,
  dateNow
}
