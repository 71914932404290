import Vue from "vue";

/***********************************************************
 *  Map
 */
// import 'vuelayers/lib/style.css' // needs css-loader
// import VueLayers from 'vuelayers'
// Vue.use(VueLayers, {dataProjection: 'EPSG:4326'})

import { kebabCase } from "lodash";

import {
  Map,
  TileLayer,
  VectorSource,
  LineStringGeom,
  Overlay,
  Feature,
  StyleBox,
  CircleStyle,
  StrokeStyle,
  FillStyle,
  IconStyle,
  OsmSource,
  PolygonGeom,
  MultiLineStringGeom,
  MultiPolygonGeom,
  PointGeom,
  BingmapsSource,
  SelectInteraction,
  VectorLayer,
  DrawInteraction,
  XyzSource,
  ImageLayer,
  TextStyle,
  ModifyInteraction,
  SnapInteraction,
  WmsSource,
  ImageStaticSource
} from "vuelayers";
import "vuelayers/lib/style.css"; // needs css-loader
Vue.use(Map, { dataProjection: "EPSG:4326" });
Vue.use(TileLayer);
Vue.use(OsmSource);
Vue.use(BingmapsSource);
Vue.use(XyzSource);
Vue.use(PolygonGeom);
Vue.use(MultiPolygonGeom);
Vue.use(MultiLineStringGeom);
Vue.use(VectorLayer);
Vue.use(VectorSource);
Vue.use(ImageLayer);
Vue.use(Feature);
Vue.use(StyleBox);
Vue.use(CircleStyle);
Vue.use(StrokeStyle);
Vue.use(FillStyle);
Vue.use(PointGeom);
Vue.use(IconStyle);
Vue.use(SelectInteraction);
Vue.use(LineStringGeom);
Vue.use(Overlay);
Vue.use(DrawInteraction);
Vue.use(ModifyInteraction);
Vue.use(SnapInteraction);
Vue.use(TextStyle);
Vue.use(WmsSource);
Vue.use(ImageStaticSource);



import "./customControls.less"; // needs css-loader

export default {
  data() {
    return {
      started: false,
      zoom: 2,
      center: [0, 0],
      rotation: 0,
      geolocPosition: undefined,
      featurePointer:undefined,
      baseLayers: [
        { source: "osm", title: "Mapa", icon: "map", visible: false },
        // {
        //   source: "bingmaps",
        //   title: "Bing",
        //   // icon: "satellite",
        //   text:"B",
        //   apiKey:
        //     "Am6S4E1CnAABn9rKMtprpwtI-DQZH3a5vERaNEOwL3MSzTlU4QYblJySdHbX6ph2",
        //   imagerySet: "AerialWithLabels",
        //   visible: false,
        // },



        {
          source: "xyz",
          title: "MapTiler",
          text: "M",
          // icon: "google",
          crossOrigin: "Anonymous",
          url: "https://api.maptiler.com/tiles/satellite-v2/{z}/{x}/{y}.jpg?key=XTpWIKHbnjvk6Hg0gfqk",
          visible: false,
        },


        
        


        // {
        //   source: "xyz",
        //   title: "MapBox",
        //   text: "M",
        //   crossOrigin: "Anonymous",
        //   url: "https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/512/{z}/{x}/{y}?access_token=pk.eyJ1IjoicGF1bDEyMSIsImEiOiJja3d5MHE2eDMwM2FqMnpueXM5c240NjZrIn0.Ba134-cD3q1AotmpdElQog",
        //   // apiKey:"pk.eyJ1IjoicGF1bDEyMSIsImEiOiJja3d5MHE2eDMwM2FqMnpueXM5c240NjZrIn0.Ba134-cD3q1AotmpdElQog",
        //   // url:"https://api.maptiler.com/tiles/satellite/{z}/{x}/{y}.jpg?key=get_your_own_D6rA4zTHduk6KOKTXzGB",
        //   visible: false,
        // },

        {
          source: "xyz",
          title: "Google",
          text: "G",
          // icon: "google",
          crossOrigin: "Anonymous",
          url: "https://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}",
          // url:"https://api.maptiler.com/tiles/satellite/{z}/{x}/{y}.jpg?key=get_your_own_D6rA4zTHduk6KOKTXzGB",
          visible: false,
        },
        {
          source: "wms",
          title: "sentinel",
          text: "S",
          crossOrigin: "Anonymous",
          url:"https://services.sentinel-hub.com/ogc/wms/8b97d6d7-0a56-43e0-a45e-c9212d99ae8f",
          visible: false,
          extParams: {
            width: 256,
            height: 256,
            srs: "EPSG:4326",
          },
          version: "1.1.1",
          layers: "TRUE_COLOR",
          format: "image/jpeg",
        }
      ],
    };
  },

  props: {
    autofit: { type: Boolean, required: false, default: true },
    fitPadding:{type:Array, default:function(){return [50, 50, 10, 50]}}
  },

  beforeMount() {
    let mapLayer = localStorage.getItem("map-tile-layer");

    if (!mapLayer) {
      mapLayer = "Google";
      localStorage.setItem("map-tile-layer", mapLayer);
    }

    if (mapLayer == "sentinel" ) {
      localStorage.setItem("map-tile-layer", 'Google');
    }

    this.showBaseLayer(mapLayer);
  },

  methods: {
    fit(duration, padding=this.fitPadding) {
      this.$refs.view.$mountPromise.then(() => {
        let objectSource = this.$refs.objSource;

        if (!objectSource || !objectSource.$source) return;

        let area = objectSource.$source.getExtent();
        if (area[0] !== Infinity) {
          this.$refs.view.fit(area, {
            padding:padding ,
            duration: duration,
            constrainResolution: false,
            maxZoom: 16,
          });
        }
      });
    },

    // eslint-disable-next-line no-unused-vars
    onMapPostCompose({ vectorContext, frameState }) {},

    // eslint-disable-next-line no-unused-vars
    onMapPointermove(evt) {
      let features = this.$refs.map.$map.getFeaturesAtPixel(evt.pixel);
      if (features) {
        this.$refs.map.$map.getTargetElement().style.cursor = "pointer";
        let namesList = []
        for (var i = 0; i < features.length; i++) {
            if (features[i].values_ != undefined && features[i].values_ .name!= undefined) {
              namesList.push(features[i].values_ .name)
            }
        }

        if (namesList.length>5){
          let count = namesList.length-5
          namesList = namesList.slice(0, 5)
          namesList.push(" +"+count)
        }

        if (namesList.length>0){
          this.featurePointer = {
                titles:namesList,
                coordinates:evt.coordinate
                }
        }

       
      } else {
        this.$refs.map.$map.getTargetElement().style.cursor = "";
        this.featurePointer = undefined
      }
    },

    geometryTypeToCmpName(type) {
      return "vl-geom-" + kebabCase(type);
    },

    mapConfig() {},

    onMapMounted() {
      this.mapConfig();
      if (this.started === false) {
        this.started = true;

        if (this.autofit) {
          setTimeout(() => {
            this.fit(0);
          }, 200);
        }
      }
    },
    // base layers
    showBaseLayer(title) {
      let layer = this.baseLayers.find((layer) => layer.visible);
      if (layer != null) {
        layer.visible = false;
      }
      layer = this.baseLayers.find((layer) => layer.title === title);
      if (layer != null) {
        layer.visible = true;
        if(layer.title != "sentinel"){
          localStorage.setItem("map-tile-layer", layer.title);
        }
      }else{
          localStorage.setItem("map-tile-layer", "Bing");
      }
    },
  },

  computed: {
    mapSensors: function() {
      if (this.sensors != null) {
        return this.sensors.filter(
          (ele) =>
            ["waterLevel", "pivot", "station", "soil", "pluviometer","well","hydrometer"].indexOf(
              ele.type
            ) != -1
        );
      }

      return [];
    },
  },
};
