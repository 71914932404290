<template>
  <div :style="{ width: width }">
    <monthChart
      title="Consumo (R$)"
      :series="[comsumoSerie]"
      unit="R$"
      unit-left
      :height="chartHeight"
      :lastMonth="month"
      :numMonth="numMonth"
      :width="width"
      @click="$emit('select-month', $event)"
    />
    <hr>
    <monthChart
      title="R$/kWh"
      :series="[valorKw]"
      unit="R$"
      unit-left
      :height="chartHeight"
      :lastMonth="month"
      :numMonth="numMonth"
      :width="width"
      @click="$emit('select-month', $event)"
    />
    <hr>
    <monthChart
      title="Horário Reservado"
      :series="[reservadoSerie]"
      unit="%"
      :height="chartHeight"
      :lastMonth="month"
      :numMonth="numMonth"
      :width="width"
      @click="$emit('select-month', $event)"
    />
  </div>
</template>

<script>
import monthChart from "@kl-framework/charts/monthChart";

export default {
  props: {
    history: Array,
    month: { type: String, required: true },
    numMonth:{type:Number, default:13},
    width: { type: [String, Number], default: "100%" },
    chartHeight: { type: [String, Number], default: "150px" },
  },

  computed: {
    comsumoSerie() {
      return {
        type: "bar",
        color: "#dc3545",
        values: this.history
          ? this.history.map((e) => {
              return { month: e.referenceMonth, value: e.totalCost };
            })
          : [],
      };
    },
    reservadoSerie() {
      return {
        type: "bar",
        color: "#28a745",
        values: this.history
          ? this.history.map((e) => {
              return {
                month: e.referenceMonth,
                value: e.reservedPowerRatio * 100,
              };
            })
          : [],
      };
    },
    valorKw() {
      return {
        type: "bar",
        color: "#08f",
        values: this.history
          ? this.history.map((e) => {
              return { month: e.referenceMonth, value: e.costPerKWh };
            })
          : [],
      };
    },
  },

  components: {
    monthChart,
  },
};
</script>
