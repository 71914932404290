// import sensorPageDetails from "./sensorPageDetails";
import modalSensorDetails from "./modalSensorDetails";
// import { Role } from "@/helpers/role";

import store from '@/store'

export default {
  path: "sensor/:id",
  // meta: { authorize: [Role.Admin] },
  component: modalSensorDetails,
  name: "sensorDetails",
  props: (route) => ({ sensor: store.getters["sensors/getById"](route.params.id) })
};
