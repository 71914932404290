<template>
  <b-container class="m-5">


    <div v-if="loadStatus == 'loaded'">

      <b-card
      title="Convite para juntar-se ao Grupo"
      class="shadow text-center"
      v-if="invite!= null"
      >
      
      <b-card-text>
        <b-alert show variant="info" class="text-left">
          <font-awesome-icon icon="info-circle" />
          Este é um convite para se juntar ao grupo de trabalho
          <b>{{ invite.workspace.name }}</b>
        </b-alert>
        
        <p> Para aceitar o convite, clique no botão abaixo.
          Este convite é válido apenas para um único usuário e será descartado em seguida
        </p>
        
      </b-card-text>
      
      <b-button class="mr-2" :to="{name:'home'}"> Cancelar </b-button>
      <b-button variant="success" @click="loadAceptJoin()"> Jutar-se ao grupo {{  invite.workspace.name }}</b-button>
      
      
      <!-- {{ inviteId }} -->
    </b-card>
    
    <b-card
    title="Inválido"
    class="shadow text-center"
    v-else
    >
    <b-card-text>
      
      <b-alert show variant="danger" class="text-left">
        <!-- <font-awesome-icon icon="alert" /> -->
        Este convite não existe ou foi removido.
      </b-alert>
      
      
    </b-card-text>
    
    <b-button :to="{name:'home'}"> Cancelar </b-button>  
  </b-card>

  </div>
  <c-loading @retry="loadData" :status="loadStatus" :error="error" v-else></c-loading> 

  
  
</b-container>
</template>

<script>
export default {
  data() {
    return {
      loadStatus: String,
      error:undefined,
      invite: null,
    };
  },
  props: {
    inviteId: String,
  },
  mounted() {
    this.loadInviteData();
  },

  methods: {
    loadInviteData() {
      this.loadStatus = "loading";
      this.$http.get("account/workspace/invite/" + this.inviteId).then(
        (data) => {
          this.invite = data;
          this.loadStatus = "loaded";
        },
        (error) => {
          this.invite = null
          if ([422, 403].includes(error.response.status)) {
            this.$router.push({
              name: "login",
              params: { nextpath: this.$route.path },
            });
          }

          this.loadStatus = "error";
          this.error = error
        }
      );
    },

    loadAceptJoin() {
      this.$http.get("account/workspaceJoin/" + this.inviteId).then(
        (data) => {
         console.log(data);
         this.$store.dispatch("accounts/changeWorkspace",data.workspace.id);
         this.$router.push({name:'home'})
        },
        (error) => {
          console.error("workspaceJoin error:", error); 
        }
      );
    }
  },
};
</script>

<style></style>
