<template>
  <v-chart
  :style="{ width: width, height: height }"
    v-if="drawChart"
    class="chart"
    ref="chart"
    :option="chartOptions"
    :theme="dark ? darkTheme : null"
    autoresize
    @click="onclick"
    :init-options="{renderer: 'svg'}"
  />
</template>

<script>
import { DateTime } from "luxon";
import darkTheme from "@kl-framework/charts/themes/dark.js";

var monthFormatter = function(value) {
  let _value = (typeof value === 'object')?value.value:value
  return DateTime.fromFormat(_value, "yyyy-MM").toLocaleString(
    {month: "2-digit",year: "2-digit"}
  );
};

export default {
  props: {
    series: Array,
    unit: { type: String, default: "" },
    unitLeft:{type:Boolean, default:false},
    ymaxValue: Number,
    yminValue: Number,
    title: String,
    color: String,
    width: { type: [String, Number], default: "100%" },
    height: { type: [String, Number], default: "180px" },
    lastMonth: { type: String },
    numMonth: { type: Number },
    dark: { type: Boolean, default: false },
    vertical:{type:Boolean, default:false},
    axisValue:{type:Array},
    valuesAxis:{type:Boolean, default:false}
  },

  data() {
    return {
      darkTheme: darkTheme,
      drawChart:false
    };
  },

  mounted(){
    this.drawChart = true
  },

  methods:{
    onclick(v){
      this.$emit("click",v.name)
    }
  },

  computed: {
    //cria uma série com os meses/ano
    xSerie() {
      let xSerie = [];

      let startDate = DateTime.fromFormat(this.lastMonth, "yyyy-MM");
      startDate = startDate.plus({ month: -this.numMonth });
      for (let i = 0; i < this.numMonth; i++) {
        startDate = startDate.plus({ month: 1 });
        xSerie.push(
          startDate.toFormat("yyyy-MM")
          //  startDate.toLocaleString({ month: "2-digit", year: "2-digit" })
        );
      }
      return xSerie;
    },

    chartOptions: function() {
      let self = this;

      let xSerie = this.xSerie;

      let dataSeries = this.series.map((item) => {
        let data = [];
        xSerie.forEach((xmonth) => {
          let monthValue =  item.values.find((e) =>e.month === xmonth);
          data.push(monthValue ? monthValue.value : null);
        });
        // item.data = data;
        // item.data = [10,20,30,40,50];

        return { 
          name:item.name,
          type: item.type,
          data: data,
          stack: item.stack,
          color: item.color,
          barGap:item.barGap,
          label: item.label? item.label: {
              show: true,
              position: this.vertical ?'top':'right',
              formatter: function(obj) {
                let value = obj.value.toLocaleString({minimumFractionDigits:2,maximumFractionDigits:2})
                return self.unitLeft?self.unit+value:value+self.unit
              },
            }
          };
      });


      let monthAxis = {
            type: "category",
            data: xSerie,
            axisLabel: {
              formatter: monthFormatter
            },
            axisPointer: {
              label: {
                formatter: monthFormatter
              },
            },
          }
      
      let valuesAxis ={
            type: "value",
            // name: self.unit,
            max: self.ymaxValue,
            min: self.yminValue,
            boundaryGap: [0, 0.3],
            axisLabel: {
              show:this.valuesAxis,
              formatter: (val) => {
                return val+this.unit;
              },
            },
            splitArea: { show: false },
            ticks: {
              beginAtZero: false,
            },
          }

          let  yAxis = undefined
          let  xAxis = undefined

          if(this.vertical){
            yAxis = valuesAxis;
            xAxis = monthAxis
          }else{
            yAxis = monthAxis
            xAxis = valuesAxis
          }

      return {
        // animation: false,
        title: self.title?{
          left: "center",
          textAlign: "auto",
          text: self.title,
        }:undefined,

        grid: [{  bottom: 5, top: 25, left:0, containLabel: true }],
        legend: {
          selectedMode: true,
          top: 0,
        },
        
        tooltip: {
          trigger: "axis",
          axisPointer: { type: "cross", crossStyle: { color: "#999" } },
          valueFormatter: (v) => this.$filters.localeNumber(v, 2),
        },
        // dataZoom: [
        //   {
        //     type: "inside",
        //     show: true,
        //     realtime: true,
        //     start: 0,
        //     end: 100,
        //   },
        // ],
        yAxis: [ yAxis],
        xAxis: [ xAxis],
        series: dataSeries,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.chart{
  width: 100%;
  height: 260px;
}
</style>
