<template>
  <div class="map-layers">
    <button class="map-button" @click="layersvisible = !layersvisible">
      <font-awesome-icon icon="layer-group" />
    </button>
    <div v-if="layersvisible">
      <div class="panel">
      <button
        v-for="layer in layers"
        :key="layer.title"
        :class="{ 'is-info': layer.visible }"
        @click="showBaseLayer(layer.title)"
        :title="layer.title"
      >
        <font-awesome-icon v-if="layer.icon" :icon="layer.icon" />
        <span v-if="layer.text"> {{ layer.text }} </span>
      </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      layers :[],
      layersvisible: false,
    };
  },

  props: {
    baseLayers: Array,
  },
  mounted(){
      this.layers = JSON.parse(JSON.stringify(this.baseLayers))
  },
  methods: {

    //TODO: essa função está repetida no component map.js
    showBaseLayer(title) {
      let layer = this.baseLayers.find((layer) => layer.visible);
      if (layer != null) {
        layer.visible = false;
      }
      layer = this.baseLayers.find((layer) => layer.title === title);
      if (layer != null) {
        layer.visible = true;
         if(layer.title !== "sentinel"){
          localStorage.setItem("map-tile-layer", layer.title);
         }
      }

      this.layersvisible = false;
    },
  },
};
</script>

