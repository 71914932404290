<template>
  <b-modal ref="soilEdit-modal" scrollable @hidden="close" size="max-md">
    <template v-slot:modal-header>
      <h6>Editar Solo</h6>
      <popover-select :options="selectList" v-on:select-item="importData($event)" />
      <!-- <div class="d-flex">
        Importar:
        <b-form-select size="sm" :options="selectList"></b-form-select>
      </div>-->
    </template>

    <validation-observer ref="formObserver">
      <soilEdit v-model="soil" />
    </validation-observer>

    <template v-slot:modal-footer>
      <b-button @click="submit" variant="primary">Salvar</b-button>
      <b-button @click="cancel">Cancelar</b-button>
    </template>
  </b-modal>
  <!-- <b-button type="submit">Salvar</b-button>
  <b-button type="reset">Cancelar</b-button>-->
</template>

<script>
import soilEdit from "./soilEdit";

export default {

  data() {
    return {
      soil: { name: "", description: "", layers: [] }
    };
  },

  props: {
    soilId: { type: String, required: false },
    soilList: Array
  },

  mounted() {
    if (this.soilId !== undefined) {
      let soil = this.soilList.find(ele => this.soilId === ele.id);
      if (soil != undefined) this.soil = JSON.parse(JSON.stringify(soil));
    }

    this.$refs["soilEdit-modal"].show();
  },

  computed: {
    selectList() {
      let list = [];
      this.soilList.forEach(function(v) {
        list.push({ value: v, text: v.name });
      });
      return list;
    }
  },

  methods: {


    close() {
      this.$router.push({ name: "soilList" });
    },

    importData(v) {
      this.soil.layers = JSON.parse(JSON.stringify(v.layers));
      this.soil.classification = JSON.parse(JSON.stringify(v.classification));
    },

    submit() {

       this.$refs.formObserver.validate().then(result =>{
         if (!result) {
           return;
         }
      
        let soilData = JSON.parse(JSON.stringify(this.soil));
        this.$http.post("irrig/soil/", soilData).then(
          data => {
            this.$emit("update:soil", data);
            this.$refs["soilEdit-modal"].hide();
          },
          error => {
            this.$refs.formObserver.setErrors(this.$validServer(error))
            // this.$bvModal.msgBoxOk("Verifique se todos os dados estão corretos",
            //   {
            //     title: "Erro!"
            //   }
            // );
          }
        );

      });


    },

    cancel() {
      this.$refs["soilEdit-modal"].hide();
    }

    // onSubmit(evt) {
    //   evt.preventDefault();
    //   console.log("submit")
    //   alert(JSON.stringify(this.form));
    // },
    // onReset(evt) {
    //   evt.preventDefault();
    // }
  },
  components: {
    soilEdit,
  }
};
</script>
