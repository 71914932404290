
import sensor from '@sensorcomponents/sensor.js'

export default {

    extends:sensor,

    filters:{
      percent: function(value){
        if (typeof value !== 'number') return value;
        value = Math.abs(value*100)
        return value.toFixed(1)
      }
    },

    computed:{
      pivot(){
        return this.sensor
      },
      status(){
        return this.pivot.status
      },
      radius(){
        return this.pivot.radius
      },
      pressure(){
        return this.pivot.pressure
      },
      pressureUnit(){
        return this.pivot.pressureUnit
      },
      angle(){
        return this.pivot.angle
      },
      percent(){
        if(Math.abs(this.pivot.percent) < 5){
          return 0;
        }
        else {
          return this.pivot.percent
        }
      },
      areaStart(){
        return this.pivot.areaStart
      },
      areaEnd(){
        return this.pivot.areaEnd
      },
      startAngle(){
        return this.pivot.pivot.displayOffsetPos || 0;
      },

      offsetPos(){
        return this.pivot.pivot.offsetPos || 0;
      },

      center(){
        return this.pivot.pivot.centerLocation
      },

      irrigStartTime(){
        if(this.pivot.currentIrrigation != undefined && this.pivot.currentIrrigation.startTime!= undefined){
          return this.pivot.currentIrrigation.startTime
        }else{
          return undefined;
        }
      },
      irrigStart(){
        if(this.pivot.currentIrrigation != undefined && this.pivot.currentIrrigation.start!= undefined){
          return this.pivot.currentIrrigation.start
        }else{
          return undefined;
        }
      },
      irrigDist(){
        if(this.pivot.currentIrrigation != null && this.pivot.currentIrrigation.distance!= null){
          return this.pivot.currentIrrigation.distance
        }else{
          return undefined;
        }
      },
    },
  }
