<template>
  <div>
    <div class="list-page" v-if="loadStatus=='loaded'">
      <b-table
        class=""
        stacked="sm"
        small
        :items="fieldsHistorical"
        :fields="fields"
        :filter="filter"
        :current-page="currentPage"
        :per-page="perPage"
        v-on:onnumrows="onNumRows($event)"
      >
        <template v-slot:cell(name)="row">
          <b-link :to="{name:'fieldHistory',params:{id:row.item.id}}">{{row.item.name}}</b-link>
        </template>

         <template v-slot:cell(culture)="{value}">
          <img class="culture-img" :src="require('@irrigcomponents/culture/assets/48/'+value.icon+'.png')"/>
          {{value.name}}
        </template>

        <template v-slot:cell(production)="row">
         {{row.item.endCycle.production}} {{row.item.endCycle.unit}}
        </template> 


        <template v-slot:cell(action)="row">
          <b-button block size="sm" v-if="$can('create', 'Field')" variant="secondary"
                    :to="{ name: 'fieldNew', params: {equipmentId:equipment.id, cloneId: row.item.id} }">
                    <font-awesome-icon icon="copy" /> Copiar
                  </b-button>
        </template> 

      </b-table>

      <div class="list-page-pagination">
        <b-pagination
          v-model="currentPage"
          :total-rows="numRows"
          :per-page="perPage"
          aria-controls="my-table"
        ></b-pagination>
      </div>
    </div>
    <c-loading :status="loadStatus" v-else></c-loading>


  </div>
</template>

<script>

import { DateTime } from 'luxon';

var dateFormatter = function(value){
  //  return DateTime.fromISO(value).toFormat('dd/MM/yy'); 
   return DateTime.fromISO(value).toLocaleString(); 
}

export default {
  data() {
    return {
      fields: [
        { key: "name", label: "Nome", sortable: true },
        { key: "culture", label: "Cultura", sortable: true },
        { key: "datePlanting", label: "Plantio", sortable: true, formatter:dateFormatter },
        { key: "endCycle.harvestDate", label: "Colheita", sortable: true , formatter:dateFormatter},
        { key: "production", label: "Produtividade", sortable: true },
        { key: "action", label: "Ação", sortable: false },
      ],

      fieldsHistorical: [],
      loadStatus: "loading",
      perPage: 10,
      currentPage: 1,
      filter: null,
      numRows:1,
    };
  },
  props: {
    equipment: { type: Object, required: true }
  },

  mounted() {
    this.loadHistorical();
  },

  methods: {
    loadHistorical() {
      this.loadStatus = "loading";
      this.$http
        .get("irrig/equipment/" + this.equipment.id + "/field-historical")
        .then(
          data => {
            this.fieldsHistorical = data;
            this.loadStatus = "loaded";
          },
          error => {
            console.error("load field historical error",error)
            this.loadStatus = "error";
          }
        );
    },



    onNumRows(numrows){
      this.numRows = numrows;
    },
  }
};
</script>

<style lang="scss" scoped>

.culture-img{
  height: 32px;
}
</style>