<template>
  <b-tbody>
    <insertDataRow v-for="item in editable" :key="item.postoTarifario"
      :name= "`${name}  ${item.timeOfUse}`"
      :unit="unit"
      v-model="item.consumption"
    />
  </b-tbody>
</template>

<script>
import insertDataRow from "./insertDataRow";

export default {
  data() {
    return {
      editable: undefined,
    };
  },

  props: {
    value: Array,
    name: String,
    unit:String
  },

  components: {
    insertDataRow,
  },

  watch: {
    editable: {
      deep: true,
      handler(v) {
        this.$emit("input", JSON.parse(JSON.stringify(v)));
      },
    },
  },

  mounted() {
    this.editable = Object.assign([
        {timeOfUse:'PONTA',consumption:{}},
        {timeOfUse:'FORAPONTA',consumption:{}},
        {timeOfUse:'RESERVADO',consumption:{}}
        ], this.value);
  },
};
</script>
