<template>
  <v-chart class="echarts" style="width:100%;height:300px;" :option="serie" :theme="theme" autoresize />
</template>

<script>
import { DateTime } from 'luxon';

const colors = ['#007bff','#dc3545','#28a745','#fd7e14','#6f42c1']

export default {
  data: function() {
    return {
      theme: undefined, // chartTheme,
    };
  },
  props: {
    data: Array,
    unit:String,
  },
  computed: {
    serie: function() {
      let data = this.data;
      if (!data) return{};

      let series = [];

      data.forEach((element,index) => {
        series.push({
          color:colors[index],
          yAxisIndex: 0,
          name: element.name,
          type: "line",
          data: element.dataPoints.map(e=>{return [e.time,e.value]}),
          showSymbol: false
        });

        // series.push({
        //   color:colors[index],
        //   yAxisIndex: 1,
        //   name: element.name+"(m³)",
        //   type: "bar",
        //   data: element.dataPoints.map(e=>{return [e.time,e.value2]}),
        //   showSymbol: false
        // });


      });

      return {
        title: { left: "center", padding: 1, textStyle: { color: "#fff" } },
        dataZoom: [
          {
            type: "inside",
            show: true,
            realtime: true,
            start: 0,
            end: 100,
          },
        ],
        legend: {
          show: true,
          bottom: 0,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: { type: "cross", crossStyle: { color: "#999" } },
          valueFormatter: (v) => this.$filters.localeNumber(v)+" "+this.unit,
        },
        grid: { left:0, bottom: 40, right: 0, top:30, containLabel: true },
        yAxis: [
          {
            name:"m³/h",
            scale: false,
            // min: this.emptyLevel,
            // max: this.fullLevel,
            // axisLabel: { formatter:  "{value} "+this.unit },
            axisLabel: { formatter: (v)=> this.$filters.localeNumber(v)},
          }
        ],

        xAxis: {
          type: "time",
          axisPointer:{
            label:{
              formatter: function(value) {
                return DateTime.fromMillis(value.value).toLocaleString(DateTime.DATETIME_SHORT);
              },
            }
          },
        },
        series: series,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
