<template>
  <div>
    <validation-observer v-if="workspaceEdit!= undefined" ref="formObserver">
      <b-row>
        <ValidationProvider
          slim
          vid="name"
          name="Nome"
          :rules="{ required: true,min:3 }"
          v-slot="v"
        >
          <b-form-group label="Nome" class="col-sm-12">
            <b-form-input
              v-model="workspaceEdit.name"
              type="text"
              maxlength="30"
              :state="$validateState(v)"
            ></b-form-input>
            <span class="invalid-input">{{ v.errors[0] }}</span>
          </b-form-group>
        </ValidationProvider>


        <ValidationProvider
          slim
          vid="subtitle"
          name="Subtítulo"
          :rules="{ required: false,min:3 }"
          v-slot="v"
        >
          <b-form-group label="Subtítulo" class="col-sm-12">
            <b-form-input
              v-model="workspaceEdit.subtitle"
              type="text"
              maxlength="30"
              :state="$validateState(v)"
            ></b-form-input>
            <span class="invalid-input">{{ v.errors[0] }}</span>
          </b-form-group>
        </ValidationProvider>

        <!-- PHONE -->
        <!-- <ValidationProvider
          slim
          vid="phone"
          name="telefone"
          :rules="{ required: false,min:3 }"
          v-slot="v"
        >
          <b-form-group label="Telefone" class="col-sm-6">
            <b-form-input
              name="workspace.phone"
              v-model="workspace.phone"
              type="text"
              maxlength="30"
              :state="$validateState(v)"
            ></b-form-input>
            <span class="invalid-input">{{ v.errors[0] }}</span>
          </b-form-group>
        </ValidationProvider> -->

        <!-- email -->
        <!-- <ValidationProvider
          slim
          vid="email"
          name="email"
          :rules="{ required: false, min:3 }"
          v-slot="v"
        >
          <b-form-group label="Email" class="col-sm-6">
            <b-form-input
              name="workspace.email"
              v-model="workspace.email"
              type="text"
              maxlength="30"
              :state="$validateState(v)"
            ></b-form-input>
            <span class="invalid-input">{{ v.errors[0] }}</span>
          </b-form-group>
        </ValidationProvider> -->

        <!-- Zone ID -->
        <!-- <ValidationProvider slim vid="zone" name="Zona" :rules="{ required: true}" v-slot="v">
          <b-form-group label="Zona" class="col-sm-6">
            <b-form-select
              v-model="workspace.zoneIdStr"
              :options="zoneIdList"
              name="zoneIdStr"
              :state="$validateState(v)"
            ></b-form-select>
            <span class="invalid-input">{{ v.errors[0] }}</span>
          </b-form-group>
        </ValidationProvider> -->

        <!-- Estado -->
        <!-- <ValidationProvider slim vid="state" name="Estado" :rules="{ required: true}" v-slot="v">
          <b-form-group label="Estado" class="col-sm-6">
            <b-form-input
              v-model="workspace.state"
              type="text"
              maxlength="50"
              name="workspace.state"
              :state="$validateState(v)"
            ></b-form-input>
            <span class="invalid-input">{{ v.errors[0] }}</span>
          </b-form-group>
        </ValidationProvider> -->

        <!-- Cidade -->
        <!-- <ValidationProvider
          slim
          vid="city"
          name="Cidade"
          :rules="{ required: true, min:3}"
          v-slot="v"
        >
          <b-form-group label="Cidade" class="col-sm-6">
            <b-form-input
              v-model="workspace.city"
              max="2"
              type="text"
              name="workspace.city"
              :state="$validateState(v)"
            ></b-form-input>
            <span class="invalid-input">{{ v.errors[0] }}</span>
          </b-form-group>
        </ValidationProvider> -->

      </b-row>
    </validation-observer>

    <div class="d-flex mt-5">
      <div class="ml-auto">
        <b-button variant="primary" @click="submit()">Salvar</b-button>
        <b-button variant="secondary" @click="$emit('editcancel')">cancelar</b-button>
      </div>
    </div>
  </div>
</template>

<script>

export default {

  data() {
    return {
      zoneIdList: ["UTC", "UTC-3"],
      workspaceEdit:undefined
    };
  },
  props: {
    workspace: { type: Object, require: true }
  },

  computed: {
    consultantList() {
      return this.memberList;
    }
  },

  mounted() {
    this.workspaceEdit = JSON.parse(JSON.stringify(this.workspace));
  },

  methods: {
    submit() {
      this.$refs.formObserver.validate().then(result => {
        if (!result) {
          return;
        }

        let workspaceData = JSON.parse(JSON.stringify(this.workspaceEdit));

        this.$http.save("account/workspace", workspaceData, workspaceData.id).then(
          data => {
            this.$emit("update-workspace", data);
            this.$emit("editcancel");
          },
          error => {
            console.error("save workspace error",error)
            // this.$refs.formObserver.setErrors(this.$validServer(error));
            this.$bvModal.msgBoxOk(
              "Verifique se todos os dados estão corretos",
              {
                title: "Erro!"
              }
            );
          }
        );
      });
    },
  },

};
</script>
