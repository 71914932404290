export const f_color_b = ["#4da6ff"];
export const f_color_g = ["#28a761", "#72b12b", "#a3c72f", "#c7bf2f"];
export const f_color_o = ["#FFA62D", "#FF832D", "#FF752D", "#FF632D"];
export const f_color_r = "#ff471a";

//paleta 2
// export const f_color_b = ['#3288bd'];
// export const f_color_g = ['#66c2a5', '#abdda4', '#e6f598', '#ffffbf'];
// export const f_color_o = ['#fee08b', '#fdae61', '##fd9d61', '#f46d43'];
// export const f_color_r = "#d53e4f";

//nova paleta
// rgb(50, 136, 189); #3288bd
// rgb(102, 194, 165); #66c2a5
// rgb(171, 221, 164);#abdda4
// rgb(230, 245, 152); #e6f598
// rgb(255, 255, 191); #ffffbf
// rgb(254, 224, 139); #fee08b
// rgb(253, 174, 97); #fdae61
// rgb(244, 109, 67); #f46d43
// rgb(213, 62, 79); #d53e4f

/**
 * Mixin usado para colorir o feed.
 * Necessita que o objeto tenha o atributo colorCode
 */
export default {
  computed: {
    color: function() {
      let colorCode = this.colorCode;
      return this.calcFiledColor(colorCode)
    },
  },

  methods: {
    calcFiledColor: function(colorCode) {
      if (colorCode != undefined) {
        switch (colorCode) {
          case "b-1": return f_color_b[0];

          case "g-10": return f_color_g[0];
          case "g-9": return f_color_g[0];
          case "g-8": return f_color_g[1];
          case "g-7": return f_color_g[1];
          case "g-6": return f_color_g[1];
          case "g-5": return f_color_g[2];
          case "g-4": return f_color_g[2];
          case "g-3": return f_color_g[2];
          case "g-2": return f_color_g[3];
          case "g-1": return f_color_g[3];
          case "g-0": return f_color_g[3];

          case "o-10": return f_color_o[0];
          case "o-9": return f_color_o[0];
          case "o-8": return f_color_o[1];
          case "o-7": return f_color_o[1];
          case "o-6": return f_color_o[1];
          case "o-5": return f_color_o[2];
          case "o-4": return f_color_o[2];
          case "o-3": return f_color_o[2];
          case "o-2": return f_color_o[3];
          case "o-1": return f_color_o[3];
          case "o-0": return f_color_o[3];

          case "r": return f_color_r;
        }
      }

      return null;
    },
  }
};
