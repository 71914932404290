<template>
  <b-sidebar id="sidebar-tool" bg-variant="light" text-variant="dark" backdrop-variant="dark" backdrop shadow
    width="100%" >

    <nav class="px-3 h-100">
      <!-- <b-nav vertical class=""> -->
        <sideBarContent></sideBarContent>
      <!-- </b-nav> -->
    </nav>

    <template #footer>
      <div class="p-1">{{ $appInfo.appversion }}</div>
    </template>
  </b-sidebar>
</template>


<script>
import sideBarContent from "./sideBarContent.vue"
export default {
  components: {
    sideBarContent
  },
}
</script>
