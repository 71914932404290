import pluvioPageList from './pluvioPageList'


export default {
      path:'/pluvio', component:pluvioPageList, name:'pluvioList',
      // children:[
      //   {
      //     path:':id/edit',
      //     name:'stationEdit',
      //     component:stationPageEdit,
      //     props: (route) => ({stationId:route.params.id}),
      //     meta: {authorize:{action:'edit',resource:'WeatherStation'}},
      //   },
      //   {
      //     path:':id',
      //     name:'stationDetails',
      //     component:stationPage,
      //     props: (route) => ({stationId:route.params.id})
      //   },
      //   {
      //     path:'new',
      //     name:'stationNew',
      //     component:stationPageEdit,
      //     meta: {authorize:{action:'create',resource:'WeatherStation'}},
      //   }
      // ]
    }