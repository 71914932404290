<template lang="html">


  <sensorWidget :sensor="sensor" :color="'#b7dad7'">
    <template v-slot:icon>
    <waterSvg :width="80" :height="80" :sensor="sensor"/>
    </template>

    <template v-slot:content>
      <div>
      <div class="widget-box-value">
        <span class="label"> nível </span>
        <span class="value">
          {{ $filters.round(level,2)}}<small> {{unit}} </small>
        </span>
      </div>
      <div class="widget-box-value">
        <span class="label"> máximo </span>
        <span class="value">
          {{ $filters.round(warningFull,2)}}<small> {{unit}} </small>
        </span>
      </div>
      <div class="widget-box-value">
        <span class="label"> mínimo </span>
        <span class="value">
          {{ $filters.round(warningEmpty,2)}}<small> {{unit}} </small>
        </span>
      </div>
      </div>
    </template>
  </sensorWidget>


</template>

<script>
import sensWater from './sensWater.js'
import waterSvg from './waterSvg'
import sensorWidget from '../sensorWidget.vue'
export default {
    extends:sensWater,
    methods: {
      showModal() {
        //this.$emit('set-pivot-current',this.pivot)
        this.$store.dispatch('select/setSensor',this.sensor)
      }
    },

    components:{
      waterSvg,
      sensorWidget
    }
}
</script>

