<template>
  <b-card class="mb-2 shadow">
    <!-- <hr> -->

    <div class="d-flex">
      <div>
        <h5>
            {{ $filters.localeDate(bill.dateInterval.start) }} ~
            {{ $filters.localeDate(bill.dateInterval.end) }}
        </h5>
      </div>
      <div v-if="editable" class="ml-auto buttons-container">
        <b-button
          variant="outline-secondary"
          v-b-tooltip.hover
          title="Editar Conta"
          size="sm"
          @click="$emit('set-editable-bill', bill)"
        >
          <font-awesome-icon icon="edit" />
        </b-button>
        <b-button
          v-b-tooltip.hover
          title="Apagar Conta"
          variant="outline-danger"
          size="sm"
          @click="$emit('delete-unit', bill.id)"
        >
          <font-awesome-icon icon="trash" />
        </b-button>
      </div>
    </div>

    <div class="d-flex justify-content-center flex-wrap mt-2">

      <div class="bill-kpi">
        <div class="title">Valor da conta</div>
        <div class="value text-success">
          {{ $filters.currency(bill.totalCost ) }}
        </div>
      </div>

      <div class="bill-kpi">
        <div class="title">Horário reservado</div>
        <div class="value">
          {{  $filters.round((bill.reservedPowerRatio * 100),0) }} %
        </div>
      </div>

      <div class="bill-kpi">
        <div class="title">Energia Reativa</div>
        <div class="value">{{  $filters.currency(bill.reactiveEnergyCost) }}</div>
      </div>

      <div class="bill-kpi">
        <div class="title">R$/kW</div>
        <div class="value">{{ $filters.currency(bill.costPerKWh) }}</div>
      </div>

      <div class="bill-kpi ml-auto" :set="flag=getFlag(bill.seasonalFlag)">
        <div class="title">Bandeira Tarifária</div>
        <div :style="{backgroundColor:flag.color, color:'white'}"> 
          <font-awesome-icon icon="flag"></font-awesome-icon>
          {{flag.text}}
        </div>
      </div>

    </div>

    <hr />
    <h6>Lançamentos</h6>
    <bill-composition :billComposition="bill.composition" />

    <pre>
   {{ bill.comments }}
   </pre
    >
  </b-card>
</template>

<script>

import billComposition from "./billComposition.vue";
import { flagColor } from "./flagColorMixin";

export default {
  mixins:[flagColor],
  components: { billComposition },
  props: {
    bill: Object,
    editable: { type: Boolean, default: false },
  },

 
};
</script>

<style lang="scss" scoped>
.bill-kpi {
  // height: 100%;
  border: 1px solid rgba(0,0,0,0.1);
  text-align: center;
  // padding: 0.25rem 0.5rem;
  margin: 0.25rem;
  padding: 0.25rem;
  border-radius: 5px;
  .value {
    font-size: 20px;
  }
  .title {
    font-size: 12px;
    font-weight: 600;
  }
}
</style>
