<template>
        <canvas @click="$emit('click')" :width="width" :height="height" ref="canvas"></canvas>
</template>
<script>

import { colorToPalett, clipMask, drawHistogram, createHistogram } from "./imageUtils"


export default {

    data() {

        return {
            img: undefined,
            canva: undefined,
            ctx: undefined
        }
    },

    props: {
        width: { type: Number, default: 240 },
        height: { type: Number, default: 240 },
        src: { type: String },
        maxValue: { type: Number, default: 1 },
        minValue: { type: Number, default: 0 },
        mask: { type: Array },
        colorPalette: { type: Array, requerid: true },
        drawHistogram: { type: Boolean, default: false },
        histogramHeight: { type: Number, default: 25 },
        smoothing:{type:Boolean,default:true}
    },


    watch: {

        smoothing(){
            this.drawImage()
        },
        maxValue() {
            this.drawImage()
        },
        minValue() {
            this.drawImage()
        },
        mask() {
            this.drawImage()
        },
        colorPalette() {
            this.drawImage()
        }
    },

    computed: {
        imageHeight() {

            return this.drawHistogram ? this.canvas.height - this.histogramHeight : this.canvas.height
        }

    },

    methods: {

        loadImage(src) {
            this.img = new Image;
            this.img.crossOrigin = "anonymous"
            this.img.onload = () => {
                // this.canvas.width = this.img.width;
                // this.canvas.height = this.img.height;
                this.drawImage()
            };

            this.img.src = src;
        },

        drawImage() {
            const colorstops = this.colorPalette

            this.ctx.save();

            this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)
            // this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);

            if (this.mask != undefined) {
                clipMask(this.ctx, this.mask, this.canvas.width, this.imageHeight)
            }


            this.ctx.imageSmoothingEnabled = this.smoothing;
            this.ctx.drawImage(this.img, 0, 0, this.canvas.width, this.imageHeight);

            this.ctx.restore()



            const imageData = this.ctx.getImageData(0, 0, this.canvas.width, this.imageHeight);
            //const imageData = this.ctx.createImageData(this.canvas.width, this.canvas.height);
            let histogram = createHistogram(imageData)

            colorToPalett(imageData, this.minValue, this.maxValue, colorstops)
            this.ctx.putImageData(imageData, 0, 0);
           



            if (this.drawHistogram) {
                
                drawHistogram(
                    this.colorPalette,
                    this.ctx,
                    this.canvas.width,
                    this.histogramHeight,
                    this.minValue,
                    this.maxValue,
                    histogram,
                    // this.canvas.height- this.histogramHeight-50
                    this.canvas.height/2
                )
                
            }


        }
    },

    mounted() {
        this.canvas = this.$refs.canvas
        this.canvas.willReadFrequently = true;
        this.ctx = this.canvas.getContext('2d');
        this.loadImage(this.src)
    }

}
</script>
