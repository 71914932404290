<template lang="html">


  <sensorWidget :sensor="sensor" :color="'#b7dad7'">
    <template v-slot:icon>
      <soilSvg/>
    </template>

    <template v-slot:content>
      <div>
        <div class="widget-box-value" v-for="(layer,index) in layers" :key="index">
          <span class="label">{{layer.depth}} cm</span>
          <span class="value">
            {{ $filters.round(layer.value ,2)}}<small> {{sensor.unit}} </small>
          </span>
          <div class="progress">
            <div class="progress-bar" :style="{width: $options.filters.barValue(layer.value)}"></div>
          </div>
        </div>
      </div>
    </template>
  </sensorWidget>



</template>

<script>

import sensSoil from './sensSoil.js'
import soilSvg from './soilSvg'
import sensorWidget from '../sensorWidget.vue'


export default {
  extends:sensSoil,
  components:{
    soilSvg,
    sensorWidget
  }
}
</script>

<style lang="scss" scoped>
</style>
