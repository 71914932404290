export const flagColor = {

    methods:{
        getFlag(value){
            if (value === "VERDE") return {text:"VERDE",color:"#28a745"}
            if (value === "AMARELA") return {text:"AMARELA",color:"#ffc107"}
            if (value === "VERMELHA") return {text:"VERMELHA",color:"#dc3545"}
            if (value === "VERMELHA2") return {text:"VERMELHA II",color:"#900"}
            return {text:undefined,color:undefined}
        }
  }
  
  }