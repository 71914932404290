/**
 * Mixin para ser usado em conjunt de um formulário para salvar ou atualizar objetos
 * Faz o controle de abertura e fechamento do modal
 */

export const formModalObject = {
  data() {
    return {
      editableObject: {},
      savingObject: false,
      objectUrl:"",
      idField:"id"
    };
  },

  props: {
    value: {type:Object, required:false }
    
  },

  watch: {
    value() {
      if (this.value !== undefined) {
        this.editableObject = JSON.parse(JSON.stringify(this.value));
        this.$refs["object-edit-modal"].show();
        this.savingObject = false;
      } else {
        this.$refs["object-edit-modal"].hide();
        this.editableObject={}
      }
    },
  },

  methods: {
    close() {
      this.$refs["object-edit-modal"].hide();
      this.$emit("input", undefined);
    },

    submit() {
      this.savingObject = true;
      this.$refs.formObserver.validate().then((result) => {
        if (!result) {
          this.savingObject = false;
          return;
        }

        this.$http
          .save(
            this.objectUrl,
            this.editableObject,
            this.editableObject[this.idField]
          )
          .then(
            (data) => {
              this.$emit("update-object", data);
              this.$emit("input", undefined);
              this.$refs["object-edit-modal"].hide();
              this.savingObject = false;
            },
            () => {
              this.savingObject = false;
              const h = this.$createElement
              const message = h('div', [
                h('p', { class: ['text-center'] }, ['Verifique se todos os dados estão corretos ']),
                // h('p', { class: ['code'] }, [error.error])
              ])
              //this.$refs.formObserver.setErrors(this.$validServer(error));
              this.$bvModal.msgBoxOk(
                [message],
                {
                  title: "Erro!",
                }
              );
            }
          );
      });
    },
  },
};
