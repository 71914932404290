<template>
  <li class="irrig-details">
    <div class="timeline-body">
      <b-row>
        <b-col lg="4" sm="6" class="pt-2">
          <pivot-svg
            width="100%"
            height="100px"
            :angle="irrig.end"
            :percent="irrig.distance > 0 ? 1 : -1"
            :areaStart="areaStart"
            :areaEnd="areaEnd"
            :irrigStart="irrig.start"
            :irrigDist="irrig.distance"
            :startAngle="startAngle"
            :status="'irrig'"
          />
          <!-- <span>{{ [irrig.startDateTime, irrig.endDateTime] | irrigTimeFormat  }}</span> -->
          <span> {{$filters.round(irrig.start ,0)}}º ~  {{ $filters.round(irrig.end ,0)}}º</span>
        </b-col>

        <b-col>
          <ul class="irrig-info-group">
            <li>
              <b>Percentimetro</b>
              <span>{{ percentimeterFormatter(irrig.percent) }} %</span>
            </li>
            <li>
              <b>Lâmina</b>
              <span>{{ $filters.round(irrig.irrig_mm,2) }} mm</span>
            </li>
            <li>
              <b>Energia</b>
              <span>{{ $filters.round(irrig.powerKwh,2) }} kWh</span>
            </li>
            <li>
              <b>Água</b>
              <span>{{ $filters.round(irrig.waterM3 ,2) }} m³</span>
            </li>
            <li v-if="showComplete">
              <b>Pressão média</b>
              <span>{{ $filters.round(irrig.pressureAvg ,1) }} mca </span>
            </li>
          </ul>
        </b-col>
      </b-row>

      <b-collapse :visible="showComplete" id="collapse-3">
        <hr />
        <b-row>
          <b-col>
            <pressureChart
              :width="'100%'"
              :height="'150px'"
              :startAngle="startAngle"
              :data="
                irrig.pressures.map((e) => {
                  return [e.pressure, e.angle];
                })
              "
            />
          </b-col>
          <b-col>
            <timeChart :data="irrig.info" />
          </b-col>
        </b-row>
        <i> {{ irrig.note }} </i>
      </b-collapse>

      <div class="colapse-button">
        <button @click="showComplete = !showComplete">
          <font-awesome-icon v-if="showComplete" icon="chevron-up" />
          <font-awesome-icon v-else icon="chevron-down" />
        </button>
      </div>
    </div>
  </li>
</template>

<script>

import pivotSvg from "../pivotSvg";
import pressureChart from "../charts/pressureChart";
import timeChart from "../charts/timeChart";

export default {
  data() {
    return {
      showComplete: false,
    };
  },
  props: {
    irrig: Object,
    startAngle: { type: Number, default: 0 },
    areaStart: { type: Number, default: 0 },
    areaEnd: { type: Number, default: 360 },
  },

  methods: {
     percentimeterFormatter(value) {
      return Math.round(value * 20) * 5;
    }
  },

  components: {
    pivotSvg,
    pressureChart,
    timeChart,
  },
};
</script>

<style lang="scss" scoped>

.irrig-details {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: $card-height;
  word-wrap: break-word;
  background-color: $card-bg;
  background-clip: border-box;
  border: $card-border-width solid $card-border-color;
  @include border-radius($card-border-radius);

  margin-bottom: 10px;
  padding: 5px;

  .colapse-button {
    text-align: center;
    font-size: 8px;
    margin-top: 10px;
    margin-bottom: -15px;
    button {
      width: 100px;
      border-radius: 0.25rem;
      border: 1px solid transparent;
    }
  }
}

.irrig-info-group {
  padding-left: 0;
  border-left: 0;
  border-right: 0;
  border-radius: 0;
  padding-left: 0;
  padding-right: 0;
  text-align: left;
  li {
    position: relative;
    display: block;
    padding: 5px 10px 0px 10px;
    // margin-bottom: -1px;
    border-bottom: 1px solid #aaa;
    // line-height: 16px;
    span {
      float: right !important;
      color: #3c8dbc;
    }
  }
}
</style>
