<template>
  <div class="profile-container">
    <div class="avatar">
      <Avatar :username="user.name" :src="$avatarUrl+user.imageSrc" :size="50" />
    </div>

    <div class="info-container">
      <div class="name">{{user.name}}</div>
      <div class="username">@{{user.username}}</div>
      <div class="email">
        <a :href="'mailto:'+user.email">{{user.email}}</a>
      </div>
      <div class="about">{{user.about}}</div>
    </div>

    <div class="role-dropdown-container">
      <div v-if="roleprocessing ==false">
        <b-form-select 
        @change="changeRole" 
        v-model="memberCopy.role" 
        :options="roleList"
        :disabled="!$can('edit',$subject('Member',member))"
        ></b-form-select>
      </div>
      <div v-else>
        <font-awesome-icon icon="spinner" class="fa-spin" />
      </div>
    </div>

    <div class="actions">
      <div v-if="activeprocessing==false">
        <b-button
          variant="outline-danger"
          @click="setActive(false)"
          v-if="active"
          title="Desabilitar"
           :disabled="!$can('edit',$subject('Member',member))"
        >
          <font-awesome-icon icon="ban" />
        </b-button>
        <b-button variant="outline-success" @click="setActive(true)" v-else title="reativar">
          <font-awesome-icon icon="recycle" />
        </b-button>
        <b-button v-if="hasRemoverListener" variant="danger" @click="$emit('remove-member', member.id)" title="remover">
          <font-awesome-icon icon="trash" />
        </b-button>
      </div>
      <div v-else>
        <font-awesome-icon icon="spinner" class="fa-spin" />
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from "../avatar/Avatar";
import { roleList } from "./utils";


export default {
  data() {
    return {
      roleList,
      roleprocessing: false,
      activeprocessing: false,
      memberCopy: undefined
    };
  },
  beforeMount() {
    this.memberCopy = JSON.parse(JSON.stringify(this.member));
    // this.memberCopy = this.member;
  },
  props: {
    member: { type: Object, required: true }
  },

  components: {
    Avatar
  },

  computed: {
    user() {
      return this.memberCopy.user;
    },
    active() {
      return this.memberCopy.active;
    },

    hasRemoverListener(){
      return this.$listeners && this.$listeners['remove-member']
    }
  },

  methods: {
    setActive(v) {
      this.changeStatus({ active: v });
    },

    changeRole(role) {
      this.changeStatus({ role });
    },

    changeStatus(status) {
      this.roleprocessing = true;
      this.$http
        .put("account/member/" + this.member.id + "/status", status)
        .then(
          data => {
            this.memberCopy = data;
            this.roleprocessing = false;
            this.$emit("update-member", this.memberCopy);
          },
          error => {
            console.error(error);
            this.memberCopy = JSON.parse(JSON.stringify(this.member));
            this.roleprocessing = false;

            this.$bvToast.toast("Não foi possíve realizar a operação", {
              title: "error",
              autoHideDelay: 3000,
              variant: "danger",
              solid: true
            });
          }
        );
    }
  }
};
</script>

