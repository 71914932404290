<template lang="html">

  <svg  :width="width" :height="height" viewBox="0 0 220 220">
    <!-- <g :style="{transform: 'translate(${width/2}px, ${height/2}px)' }"> -->
   <!-- <g :style="{transform: `translate(${width/2}px, ${height/2}px)`}"> -->
  <g transform="translate(100, 100)">
     <defs>
       <filter id="f3" x="0" y="0" width="150%" height="150%">
        <feOffset result="offOut" in="SourceAlpha" dx="2" dy="2" />
        <feGaussianBlur result="blurOut" in="offOut" stdDeviation="2" />
        <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
      </filter>
   </defs>
      <path class="areaPath" :d="areaPath"  :style="{fill:areaColor}"/>
      <path class="irrigPath" :d="irrigPath" filter="url(#f3)"/>
        <g :transform="rotatePipe()" filter="url(#f3)">
          <g class="pipe">
            <rect x="-5" width="10" height="100" />
            <circle cx="0" cy="0" r="10" />
          </g>
          <g class="direction" transform="translate(0, 70)">
            <path :d="pipeDirection"/>
          </g>
      </g>
    </g>
  </svg>

</template>

<script>





//import * as d3 from 'd3';
//import {arc} from 'd3';
import { arc } from "d3-shape";
export default {
  props:{
    width :{ type: [Number,String], required: false, default: 100},
    height:{ type: [Number,String], required: false, default: 100},
    outline:{type:Boolean, required: false, default: false},
    status:{ type: String, required: false},
    areaStart:{ type: Number, required: false,default:0},
    areaEnd:{ type: Number, required: false,default:360},
    irrigStart:{ type: Number, required: false,default:0},
    irrigDist:{ type: Number, required: false,default:0},
    angle:{ type: Number, required: false,default:0},
    percent:{ type: Number, required: false,default:0},
    startAngle:{ type: Number, required: false,default:0}
 },

 // data() {
 //    return {
 //      data: [99, 71, 78, 25, 36, 92],
 //      areaPath: '',
 //    };
 //  },

  mounted() {
  //  this.calculatePath();
  },

  computed:{

    areaColor:function(){
      if(this.status === 'irrig'){
        return '#76BF8A'
      }else {
        return '#ccc'
        }
    },

    pipeDirection:function(){
      if(this.percent < 0){
        return "m10 10 L 30 20 L10 30"
      }else if(this.percent > 0){
        return "m-10 10 L-30 20 L-10 30"
      }else {
        return "";
      }
    },


    areaPath:function(){
      const path = arc()
       .innerRadius(0)
       .outerRadius(100)
       .startAngle((this.areaStart+this.startAngle)*(Math.PI/180))
       .endAngle((this.areaEnd+this.startAngle)*(Math.PI/180));
       return path();
    },
    irrigPath:function(){
      if(this.irrigDist === 0)
        return '';

      const path = arc()
       .innerRadius(20)
       .outerRadius(100)
       .startAngle((this.irrigStart+this.startAngle)*(Math.PI/180))
       .endAngle((this.irrigStart+this.irrigDist+this.startAngle)*(Math.PI/180));
       return path();
    }
  },

   methods: {
     rotatePipe(){
              return "rotate(" + (this.angle+this.startAngle-180) + " 0 0)"
            },
   }


};
</script>


<style lang="scss" scoped>
// svg{
//   margin: 25px;
// }
.areaPath{
//  fill: #76BF8A;
}
.irrigPath{
  fill: #768ABF;
}

.pipe{
  fill: #eee;
}

.direction{
  fill: #fa0;
}

</style>
