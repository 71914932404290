<template>
  <!-- https://www.bootdey.com/snippets/view/bs4-timeline -->

  <ul class="timeline">
    <li v-for="(item, index) in itens" :key="index">
      <!-- begin timeline-time -->
      <div class="timeline-time">
        <span class="date">{{ $filters.localeDate( item.from) }}</span>
        <span class="time">{{ $filters.localeDate( item.from) }}</span>
      </div>


      <!-- end timeline-time -->
      <!-- begin timeline-icon -->
      <div class="timeline-icon">
        <a href="javascript:;">&nbsp;</a>
      </div>
      <!-- end timeline-icon -->
      <!-- begin timeline-body -->
      <div class="timeline-body">
        <slot name="body" v-bind="item"></slot>
        <!-- <div class="timeline-header">
               <span class="userimage"><img src="https://bootdey.com/img/Content/avatar/avatar1.png" alt=""></span>
               <span class="username"><a href="javascript:;">John Smith</a> <small></small></span>
               <span class="pull-right text-muted">18 Views</span>
            </div>
            <div class="timeline-content">
               <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc faucibus turpis quis tincidunt luctus.
                  Nam sagittis dui in nunc consequat, in imperdiet nunc sagittis.
               </p>
            </div>
            <div class="timeline-likes">
               <div class="stats-right">
                  <span class="stats-text">259 Shares</span>
                  <span class="stats-text">21 Comments</span>
               </div>
               <div class="stats">
                  <span class="fa-stack fa-fw stats-icon">
                  <i class="fa fa-circle fa-stack-2x text-danger"></i>
                  <i class="fa fa-heart fa-stack-1x fa-inverse t-plus-1"></i>
                  </span>
                  <span class="fa-stack fa-fw stats-icon">
                  <i class="fa fa-circle fa-stack-2x text-primary"></i>
                  <i class="fa fa-thumbs-up fa-stack-1x fa-inverse"></i>
                  </span>
                  <span class="stats-total">4.3k</span>
               </div>
            </div>
            <div class="timeline-footer">
               <a href="javascript:;" class="m-r-15 text-inverse-lighter"><i class="fa fa-thumbs-up fa-fw fa-lg m-r-3"></i> Like</a>
               <a href="javascript:;" class="m-r-15 text-inverse-lighter"><i class="fa fa-comments fa-fw fa-lg m-r-3"></i> Comment</a> 
               <a href="javascript:;" class="m-r-15 text-inverse-lighter"><i class="fa fa-share fa-fw fa-lg m-r-3"></i> Share</a>
            </div>
            <div class="timeline-comment-box">
               <div class="user"><img src="https://bootdey.com/img/Content/avatar/avatar6.png"></div>
               <div class="input">
                  <form action="">
                     <div class="input-group">
                        <input type="text" class="form-control rounded-corner" placeholder="Write a comment...">
                        <span class="input-group-btn p-l-10">
                        <button class="btn btn-primary f-s-12 rounded-corner" type="button">Comment</button>
                        </span>
                     </div>
                  </form>
               </div>
            </div> -->
      </div>
      <!-- end timeline-body -->
    </li>

    <li>
      <!-- begin timeline-icon -->
      <div class="timeline-icon">
        <a href="javascript:;">&nbsp;</a>
      </div>
      <!-- end timeline-icon -->
      <!-- begin timeline-body -->
      <div class="timeline-body">
        Loading...
      </div>
      <!-- begin timeline-body -->
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    itens: Array,
  },
};
</script>

<style lang="scss" scoped>

$lineColor: #c4c1c1;

.timeline {
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 5px;
    bottom: 5px;
    width: 5px;
    background:$lineColor;
    left: 15%;
    margin-left: -2.5px;
  }

  > li {
    position: relative;
    min-height: 50px;
    padding: 20px 0;
  }

  .timeline-time {
    position: absolute;
    left: 0;
    width: 13%;
    text-align: right;
    top: 30px;

    .date,
    .time {
      display: block;
      font-weight: 600;
    }

    .date {
      line-height: 16px;
      font-size: 12px;
    }

    .time {
      line-height: 24px;
      font-size: 20px;
      color: #242a30;
    }
  }

  .timeline-icon {
    left: 10%;
    position: absolute;
    width: 10%;
    text-align: center;
    top: 40px;

    a {
      text-decoration: none;
      width: 20px;
      height: 20px;
      display: inline-block;
      border-radius: 20px;
      //   background: #d9e0e7;
      background: #ffffff;
      line-height: 10px;
      //   color: #fff;
      font-size: 14px;
      border: 5px solid $lineColor;
    //   transition: border-color 0.2s linear;

    }
  }
  .timeline-body {
    margin-left: 18%;
    margin-right: 0;
    background: #fff;
    position: relative;
    padding: 5px 5px;
    border-radius: 6px;
    box-shadow: 2px 2px 6px rgba($color: #000000, $alpha: 0.5);

    &:before {
      content: "";
      display: block;
      position: absolute;
      border: 10px solid transparent;
      border-right-color: #fff;
      left: -20px;
      top: 20px;
    }
  }
}

// .timeline .timeline-body>div+div {
//     margin-top: 15px
// }

// .timeline .timeline-body>div+div:last-child {
//     margin-bottom: -20px;
//     padding-bottom: 20px;
//     border-radius: 0 0 6px 6px
// }

// .timeline-header {
//     padding-bottom: 10px;
//     border-bottom: 1px solid #e2e7eb;
//     line-height: 30px
// }

// .timeline-header .userimage {
//     float: left;
//     width: 34px;
//     height: 34px;
//     border-radius: 40px;
//     overflow: hidden;
//     margin: -2px 10px -2px 0
// }

// .timeline-header .username {
//     font-size: 16px;
//     font-weight: 600
// }

// .timeline-header .username,
// .timeline-header .username a {
//     color: #2d353c
// }

// .timeline img {
//     max-width: 100%;
//     display: block
// }

// .timeline-content {
//     letter-spacing: .25px;
//     line-height: 18px;
//     font-size: 13px
// }

// .timeline-content:after,
// .timeline-content:before {
//     content: '';
//     display: table;
//     clear: both
// }

// .timeline-title {
//     margin-top: 0
// }

// .timeline-footer {
//     background: #fff;
//     border-top: 1px solid #e2e7ec;
//     padding-top: 15px
// }

// .timeline-footer a:not(.btn) {
//     color: #575d63
// }

// .timeline-footer a:not(.btn):focus,
// .timeline-footer a:not(.btn):hover {
//     color: #2d353c
// }

// .timeline-likes {
//     color: #6d767f;
//     font-weight: 600;
//     font-size: 12px
// }

// .timeline-likes .stats-right {
//     float: right
// }

// .timeline-likes .stats-total {
//     display: inline-block;
//     line-height: 20px
// }

// .timeline-likes .stats-icon {
//     float: left;
//     margin-right: 5px;
//     font-size: 9px
// }

// .timeline-likes .stats-icon+.stats-icon {
//     margin-left: -2px
// }

// .timeline-likes .stats-text {
//     line-height: 20px
// }

// .timeline-likes .stats-text+.stats-text {
//     margin-left: 15px
// }

// .timeline-comment-box {
//     background: #f2f3f4;
//     margin-left: -25px;
//     margin-right: -25px;
//     padding: 20px 25px
// }

// .timeline-comment-box .user {
//     float: left;
//     width: 34px;
//     height: 34px;
//     overflow: hidden;
//     border-radius: 30px
// }

// .timeline-comment-box .user img {
//     max-width: 100%;
//     max-height: 100%
// }

// .timeline-comment-box .user+.input {
//     margin-left: 44px
// }

// .lead {
//     margin-bottom: 20px;
//     font-size: 21px;
//     font-weight: 300;
//     line-height: 1.4;
// }

// .text-danger, .text-red {
//     color: #ff5b57!important;
// }
</style>
