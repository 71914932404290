<template>
  <b-modal
    no-fade
    hide-footer
    scrollable
    ref="details-modal"
    @hidden="close"
    size="full"
  >
    <template v-slot:modal-title>
      <span>
        <b v-if="feed">{{ feed.name }}</b>
      </span>
    </template>

    <div v-if="loadStatus == 'loaded'">
      <!-- ------------- MODO DE DETALHES ------------------------------- -->
      <b-row v-if="ScreenMode == 'details'" class="feed-details">
        <b-col lg="4" class="feed-info">
          <b-card no-body>
            <b-row no-gutters>
              <b-col sm="6" lg="12">
                <div class="map-div">
                  <simple-map :mapHeight="'250px'"> <feedMap :feed="feed" /> </simple-map>
                </div>
              </b-col>
              <b-col sm="6" lg="12" class="p-2">
                <b-button-toolbar class="mb-2">
                  <b-button-group size="sm" v-if="$can('edit', $subject('Feed',feed) )">
                    <b-button
                      v-b-tooltip.hover
                      title="Editar Feed"
                      variant="primary"
                      @click="ScreenMode = 'edit'"
                    >
                      <font-awesome-icon icon="edit" />
                    </b-button>

                    <b-button
                      v-b-tooltip.hover
                      title="Remover Feed"
                      variant="danger"
                      @click="removeFeed()"
                    >
                      <font-awesome-icon icon="trash" />
                    </b-button>
                  </b-button-group>

                  <b-button
                    class="ml-auto"
                    size="sm"
                    v-b-tooltip.hover
                    title="Configuração remota"
                    variant="warning"
                    @click="ScreenMode = 'remoteEdit'"
                  >
                    <font-awesome-icon icon="rss-square" />
                  </b-button>
                </b-button-toolbar>

                <ul class="feed-info-group">
                  <li>
                    <b>Status</b>
                    <b-badge v-if="feed.online" variant="success">
                      online
                    </b-badge>
                    <b-badge v-else variant="danger"> offline </b-badge>
                  </li>
                  <li>
                    <b>Bateria</b>
                    <span style="min-width:80px">
                      <battery v-if="feed.battery!=null" :data="feed.battery"/>
                    </span>
                  </li>
                  <li>
                    <b>Ultima atualização</b>
                    <span>{{ $filters.secondsToStr(feed.lastSyncSeconds) }}</span>
                  </li>
                  <li>
                    <b>Serial Number</b>
                    <span
                      ><code>{{ feed.serialNumber }}</code></span
                    >
                  </li>
                  <li>
                    <b>Adicionado</b>
                    <span>{{ $filters.localeDateTime(feed.created) }}</span>
                  </li>
                </ul>

                <div>
                  <i>{{ feed.description }}</i>
                </div>
              </b-col>
            </b-row>
          </b-card>

          <!-- CARD DE SENSORES -->
          <b-card v-for="sensor in feed.sensors" :key="sensor.id">
            <div>{{ sensor.type }}</div>
            <div class="d-flex">
              <div>{{ sensor.name }}</div>
              <div class="ml-auto">
                <b-button-group size="sm" v-if="$can('edit', $subject('Feed',feed))">
                  <b-button
                    v-b-tooltip.hover
                    title="Editar Especialização do feed"
                    variant="primary"
                    :to="{
                      name: 'sensorEdit',
                      params: { feedId: feed.id, sensorId: sensor.id },
                    }"
                  >
                    <font-awesome-icon icon="edit" />
                  </b-button>

                  <b-button
                    v-b-tooltip.hover
                    title="Remover Especialização do feed"
                    variant="danger"
                    @click="removeSensor(sensor.id)"
                  >
                    <font-awesome-icon icon="trash" />
                  </b-button>
                </b-button-group>
              </div>
            </div>
          </b-card>

          <b-button
            variant="success"
            size="sm"
            :to="{ name: 'sensorNew', params: { feedId: feed.id } }"
          >
            novo sensor
          </b-button>
        </b-col>

        <b-col class="feed-datastream" lg="8">
          <b-tabs pills content-class="mt-3">
            <!-- ABA de DATASTREAMS -->
            <b-tab title="Data Stream" active>
              <div v-if="feed.dataStreams && feed.dataStreams.length > 0">
                <dataStreamCard
                  v-for="dataStream in feed.dataStreams"
                  :dataStream="dataStream"
                  :feed="feed"
                  :key="dataStream.id"
                  @updatedatastream="updateDataStream($event)"
                  @removedatastream="removeDataStream($event)"
                />
              </div>
              <b-alert v-else show variant="warning">
                <div class="d-flex">
                  <div class="mr-2" style="font-size: 35px">
                    <font-awesome-icon icon="exclamation-triangle" />
                  </div>
                  <div>Este feed não possui dataStreams</div>
                </div>
              </b-alert>
            </b-tab>

            <!-- ABA de Inserção de dados -->
            <b-tab title="inserir Dados">
              <feedInsertData :feed="feed" />
            </b-tab>

            <!-- ABA de LOGS -->
            <b-tab title="Logs" lazy>
              <feedLogs :feed="feed" />
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>

      <!-- ------------------MODO DE EDIÇÂO------------- -->
      <div v-else-if="ScreenMode == 'edit'">
        <validation-observer ref="formObserver">
          <b-row>
            <b-col lg="6">
              <b-row>
                <!-- ---NOME DO Feed --- -->
                <ValidationProvider
                  slim
                  vid="name"
                  name="Nome"
                  :rules="{ required: true, min: 5 }"
                  v-slot="v"
                >
                  <b-form-group label="Nome" class="col-sm-6 col-lg-12">
                    <b-form-input
                      autofocus
                      name="feed.name"
                      v-model="form.name"
                      type="text"
                      maxlength="30"
                      placeholder="nome"
                      :state="$validateState(v)"
                    ></b-form-input>
                    <span class="invalid-input">{{ v.errors[0] }}</span>
                  </b-form-group>
                </ValidationProvider>

                <!-- ---Serial Number  --- -->
                <ValidationProvider
                  slim
                  vid="serialNumber"
                  name="serialNumber"
                  :rules="{ required: true, min: 5 }"
                  v-slot="v"
                >
                  <b-form-group
                    label="Serial Number"
                    class="col-sm-6 col-lg-12"
                  >
                    <b-form-input
                      name="feed.serialNumber"
                      v-model="form.serialNumber"
                      type="text"
                      maxlength="32"
                      placeholder="serialNumber"
                      :state="$validateState(v)"
                    ></b-form-input>
                    <span class="invalid-input">{{ v.errors[0] }}</span>
                  </b-form-group>
                </ValidationProvider>

                <!-- DESCRIÇÃO DO Feed-->
                <ValidationProvider
                  slim
                  vid="description"
                  name="Descrição"
                  :rules="{ max: 250, min: 5 }"
                  v-slot="v"
                >
                  <b-form-group
                    class="col-12"
                    id="input-group-description"
                    label="Descrição"
                  >
                    <b-form-textarea
                      name="feed.description"
                      v-model="form.description"
                      placeholder="Descrição"
                      :state="$validateState(v)"
                    ></b-form-textarea>
                    <span class="invalid-input">{{ v.errors[0] }}</span>
                  </b-form-group>
                </ValidationProvider>

                <!-- OfflineTime DO Feed-->
                <ValidationProvider
                  slim
                  vid="offlineTime"
                  name="offline Time"
                  :rules="{ required: true, minValue: 5 }"
                  v-slot="v"
                >
                  <b-form-group label="Offline Time" class="col-sm-6 col-lg-12">
                    <b-input-group append="s" :state="$validateState(v)">
                      <b-form-input
                        name="feed.OfflineTime"
                        v-model="form.offlineTime"
                        type="number"
                        min="0"
                        :state="$validateState(v)"
                      ></b-form-input>
                    </b-input-group>
                    <span class="invalid-input">{{ v.errors[0] }}</span>
                  </b-form-group>
                </ValidationProvider>

                <b-form-group class="col-sm-6 col-lg-12">
                  <b-form-checkbox
                    v-model="form.autoUpdateLocation"
                    value="true"
                    unchecked-value="false"
                    switches
                    >auto Update Location
                  </b-form-checkbox>
                </b-form-group>
              </b-row>
            </b-col>

            <b-col>
              <div>Selecione posição do feed no mapa</div>
              <select-location
                v-model="form.location"
                :imgPoint="require('./assets/feedPoint.png')"
              >
                <gatewayMap :gateway="channel" />
              </select-location>
            </b-col>
          </b-row>
        </validation-observer>

        <div class="modal-footer">
          <b-button @click="ScreenMode = 'details'" variant="secondary"
            >Cancelar</b-button
          >
          <b-button
            v-if="saveStatus == 'saved'"
            @click="submit()"
            variant="success"
            >Salvar</b-button
          >
          <b-button v-else-if="saveStatus == 'saving'" variant="secondary">
            <font-awesome-icon icon="spinner" spin />
          </b-button>
        </div>

        <!-- {{form}} -->
      </div>

      <!-- ------------------MODO DE EDIÇÂO------------- -->
      <div v-else>
        <feedRemoteEdit :feed="feed" @cancel="ScreenMode = 'details'" />
      </div>
    </div>
    <c-loading :status="loadStatus" v-else></c-loading>
  </b-modal>
</template>

<script>
import dataStreamCard from "./dataStreamCard";
import feedInsertData from "./feedInsertData";
import feedMap from "./feedMap";
import feedLogs from "./feedLogs";
import feedRemoteEdit from "./feedRemoteEdit";
import gatewayMap from "./gatewayMap";

var msgBoxConfirmOptions = {
  title: "Por favor confirme",
  size: "sm",
  buttonSize: "sm",
  okVariant: "danger",
  okTitle: "Sim",
  cancelTitle: "Não",
  footerClass: "p-2",
  hideHeaderClose: false,
  centered: true,
};

var msgBoxConfirOptions = {
  title: "Por favor confirme",
  size: "sm",
  buttonSize: "sm",
  okVariant: "danger",
  okTitle: "Sim",
  cancelTitle: "Não",
  footerClass: "p-2",
  hideHeaderClose: false,
  centered: true,
};

export default {
  data() {
    return {
      feed: undefined,
      form: undefined,
      loadStatus: "loading",
      ScreenMode: "details",
      saveStatus: "saved",
    };
  },
  components: {
    dataStreamCard,
    feedInsertData,
    feedLogs,
    feedMap,
    feedRemoteEdit,
    gatewayMap,
  },
  props: {
    feedId: { type: String, required: true },
    channel: { type: Object, required: true },
  },

  mounted() {
    this.$refs["details-modal"].show();
    this.loadStatus = "loading";
    this.$http.get("/sens/feed/" + this.feedId).then(
      (data) => {
        this.feed = data;
        this.form = {
          name: data.name,
          serialNumber: data.serialNumber,
          description: data.description,
          offlineTime: data.offlineTime,
          autoUpdateLocation: data.autoUpdateLocation,
          location: data.location ? data.location : { lat: 0, lon: 0 },
        };
        this.loadStatus = "loaded";
      },
      (error) => {
        console.log("load feed error:", error);
        this.loadStatus = "error";
      }
    );
  },
  methods: {
    close() {
      this.$router.push({
        name: "channelDetails",
        params: { id: this.channel.id },
      });
      // :to="{ name: 'channelDetails', params: { id: channel.id } }"
    },

    onMapSingleClick(evt) {
      if (evt.coordinate != undefined) {
        this.form.location.lon = evt.coordinate[0];
        this.form.location.lat = evt.coordinate[1];
      }
    },

    submit() {
      this.saveStatus = "saving";
      this.$http.save("/sens/feed", this.form, this.feedId).then(
        (data) => {
          Object.entries(data).forEach(([key, value]) => {
            this.feed[key] = value;
          });
          this.saveStatus = "saved";
          this.ScreenMode = "details";
        },
        (error) => {
          console.log("load feed error:", error);
          this.saveStatus = "error";
        }
      );
    },

    removeSensor(SensorId) {
      this.$bvModal
        .msgBoxConfirm(
          "Deseja realmente apagar este sensor?",
          msgBoxConfirOptions
        )
        .then((value) => {
          if (value === true) {
            this.$http.delete("/sens/sensor/" + SensorId).then(
              () => {
                //var data
                let index = this.feed.sensors.findIndex(
                  (e) => e.id === SensorId
                );
                this.feed.sensors.splice(index, 1);
              },
              (error) => {
                console.error("delete sensor error", error);
              }
            );
          }
        });
    },

    updateDataStream(dataStream) {
      const index = this.feed.dataStreams.findIndex(
        (e) => e.id === dataStream.id
      );
      if (index === -1) {
        this.feed.dataStreams.push(dataStream);
      } else {
        let dataObj = this.feed.dataStreams[index];
        Object.entries(dataStream).forEach(([key, value]) => {
          dataObj[key] = value;
        });
      }
    },

    removeDataStream(dataStreamId) {
      this.$bvModal
        .msgBoxConfirm(
          "Deseja realmente apagar este DataStream?",
          msgBoxConfirmOptions
        )
        .then((value) => {
          if (value === true) {
            this.$http.delete("/sens/dataStream/" + dataStreamId).then(
              () => {
                let index = this.feed.dataStreams.findIndex(
                  (e) => e.id === dataStreamId
                );
                this.feed.dataStreams.splice(index, 1);
              },
              (error) => {
                console.error("delete dataStream error", error);
              }
            );
          }
        });
    },
    removeFeed() {
      this.$bvModal
        .msgBoxConfirm(
          "Deseja realmente apagar este feed permanentemente?",
          msgBoxConfirmOptions
        )
        .then((value) => {
          if (value === true) {
            this.$http.delete("/sens/feed/" + this.feedId).then(
              () => {
                this.$emit("delete-feed", this.feedId)
                this.$router.push({
                  name: "channelDetails",
                  params: { id: this.channel.id },
                });
              },
              (error) => {
                console.error("delete sensor error", error);
              }
            );
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.feed-table {
  margin: 1rem 0px;
}

.feed-datastream{
  @media (min-width: $breakpoint-lg){
    max-height: calc(100vh - 9rem);
    overflow-y: auto;
  }
}

.feed-info {
  //background-color: #eee;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: -1rem;
  // margin-bottom: -1rem;
  //border-right: 1px dotted #aaa;

  @media (min-width: $breakpoint-lg){
    max-height: calc(100vh - 9rem);
    overflow-y: auto;
  }

  .feed-info-group {
    padding-left: 0;
    border-left: 0;
    border-right: 0;
    border-radius: 0;
    padding-left: 0;
    padding-right: 0;
    li {
      position: relative;
      display: block;
      padding: 5px 10px;
      margin-bottom: -1px;
      border-bottom: 1px solid #ddd;

      span {
        float: right !important;
        // color: #3c8dbc;
      }
    }
  }
}
</style>
