<template>
  <v-chart :style="{ width:width, height:height}" :option="serie" :theme="theme" autoresize />
</template>

<script>


export default {
  data: function() {
    return {
      theme: undefined, // chartTheme,
    };
  },
  props: {
    data: Array,
    unit: String,
    startAngle:{type:Number, default:0},
    width:{type:[Number,String], default:'100%'},
    height:{type:[Number,String], default:'200px'}
  },

  computed: {
    serie: function() {
      if (!this.data) return;

      let data = [];
      let lastAngle = undefined;
      let ANGLE_TOLERANCE = 10; /** angulo mínimo para criar continuidade no gráfico */

      this.data.forEach(e=>{
        let angle = e[1];
        if(lastAngle && angle && (Math.abs(lastAngle-angle)>ANGLE_TOLERANCE))
          data.push([null,lastAngle+1])
        data.push(e)
        lastAngle = angle;
      })

      // data.push([null,360])
      // data.push([10,60])
      // data.push([10,90])
      // data.push([10,120])
      // data.push([10,150])
      
      let self = this

      return {
        legend: { data: ["line"] },
        animation:false,
        tooltip: {
          trigger: "axis",
          axisPointer: { type: "cross" },
           formatter: function(val) {
                let value = (val[0].data[0])
                if(value)
                  return value.toFixed(2) +" "+self.unit;
                else 
                  return "-"
              },
            // formatter: "{a} {c0} "+this.unit,
            // formatter: '{b0}: {c0}<br />{b1}: {c1}',
        },
        angleAxis: { type: "value", min:0, max:360, startAngle:  90 - this.startAngle },
        radiusAxis: { min: 0 },
        polar: { center: ["50%", "50%"] },
        series: [
          {
            coordinateSystem: "polar",
            name: "pressure",
            type: "line",
            showSymbol: false,
            // areaStyle: {
            //     normal: {
            //           color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
            //               offset: 0,
            //               color: '#8ec6ad'
            //           }, {
            //               offset: 1,
            //               color: '#ffe'
            //           }])
            //       }
            // },
            data:data
          },
        ],
 
      };
    },
  },
};
</script>

