<template>
  <svg
    aria-hidden="true"
    focusable="false"
    data-prefix="fad"
    data-icon="farm"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 576 512"
    class="kl-icon-svg"
  >
    <g>
      <path
        fill="currentColor"
        d="M190.24 120.94l-55.48 111a64.35 64.35 0 0 0-6.76 28.6V512H0V112a111.93 111.93 0 0 1 221.87-21.09l-.37.17a64.12 64.12 0 0 0-31.26 29.86z"
        class="kli-secondary"
      />
      <path
        fill="currentColor"
        d="M572.62 246.22l-55.49-111a32 32 0 0 0-15.62-14.93L381 66.76a32 32 0 0 0-26 0l-120.51 53.56a32 32 0 0 0-15.62 14.93l-55.49 111a32.08 32.08 0 0 0-3.38 14.29V512h128v-96h160v96h128V260.54a32.08 32.08 0 0 0-3.38-14.32zM416 320h-96v-96h96z"
        class="kli-primary"
      />
    </g>
  </svg>
</template>

<script>
export default {};
</script>

