<template>
  <page-float-card
    title="Energia Elétrica"
    subTitle="Gerenciamento de Unidades Consumidoras e contas"
    no-body
  >
    <div v-if="loadListStatus == 'loaded'">
      <b-tabs v-if="consumerStations.length > 0" card v-model="tabIndex">
        <!-- Add your b-tab components here -->
        <b-tab
          v-for="(consumerStation, index) in consumerStations"
          :title="consumerStation.title"
          :key="consumerStation.id"
        >
          <consumerStationDetails
            :active="tabIndex === index"
            :loadMonth="month"
            :consumerStation="consumerStation"
            @set-editable-unit="editableConsumerUnit = $event"
            @delete-unit="deleteObjectById($event,'excluir-UC')"
          />
        </b-tab>

        <template #tabs-end>
          <b-nav-item
            v-if="$can('create', 'ConsumerStation')"
            role="presentation"
            @click="editableConsumerUnit = { clientName: client.name }"
            href="#"
          >
            <strong> + nova </strong>
          </b-nav-item>
        </template>

      </b-tabs>
      <div v-else class="text-center m-5">
        <div>
          <img style="width: 140px;" src="@/assets/energy.svg" alt="" />
        </div>
        <div class="mt-4 mx-auto" style="max-width: 460px;">
          <h5>Unidades consumidoras</h5>
          <p>
            Antes de cadastrar as contas de energia elétrica, primeiro faça o cadastro da
            unidade consumidora correspondente.
          </p>
          <b-button
            v-if="$can('create', 'ConsumerStation')"
            variant="success"
            @click="editableConsumerUnit = { clientName: client.name }"
          >
            Adicionar Unidade Consumidora</b-button
          >
        </div>
      </div>

      <consumerStationEdit
          v-model="editableConsumerUnit"
          @update-object="updateList($event)"
        />

    </div>
    <c-loading @retry="loadList()" :status="loadListStatus" v-else>
      <!-- <b-skeleton-table
        :rows="10"
        :columns="4"
        :table-props="{ bordered: true, striped: false }"
      ></b-skeleton-table> -->
    </c-loading>
  </page-float-card>
</template>

<script>
import { mapGetters } from "vuex";
import consumerStationEdit from "./consumerStationEdit";
import { editableList } from "@components/mixins/EditableList";
import consumerStationDetails from "./consumerStationDetails";

export default {
  mixins: [editableList],
  data() {
    return {
      tabIndex: undefined,
      editableConsumerUnit: undefined,
    };
  },
  props: {
    unitId: { type: String, required: false },
    month: { type: String, required: false },
  },
  computed: {
    ...mapGetters({
      client: "accounts/client",
    }),

    consumerStations() {
      return this.objectsList;
    },
  },

  methods:{
    listLoaded(){
      this.consumerStations.forEach((uc,index) => {
        if(uc.id === this.unitId)
        this.tabIndex = index
    });
    }
  },

  mounted() {
    this.objectsListUrl = "report/consumer-station";
    this.objectListDeleteMessage =
      "Deseja realmente apagar esta unidade consumidora e todas\
              as contas de energia contidas nela?";
    this.loadList();
  },

  components: {
    consumerStationEdit,
    consumerStationDetails,
  },
};
</script>

