//require('module-alias/register');

import Vue from 'vue'
import appConfig from './appConfig.js'
import APP from './APP.vue'

// import './registerServiceWorker'

Vue.config.productionTip = false
Vue.prototype.$window = window;



import packageJson from '../package'
const version = packageJson.version


Vue.prototype.$appInfo ={
  vueVersion:Vue.version,
  appversion: version,
  mode: process.env.NODE_ENV
} 


Vue.prototype.$timeZoneList = Intl.supportedValuesOf("timeZone").filter(e=>e.includes("America"))
Vue.prototype.$timeZoneList .push("UTC")


/***********************************************************
*  Atualizar navegador
*/
// import browserUpdate from 'browser-update';

// browserUpdate({ 
//   required: {
//       e:-2,
//       i:11,
//       f:-3,
//       o:-3,
//       s:10.1,
//       c:"84.0.4147.106",
//       samsung: 7.0,
//       vivaldi: 1.2
//   },
//   insecure:true
// } )



/***********************************************************
*  internationalization
*/
import {i18n}  from '@/plugins/i18n';




/***********************************************************
 * custom framework
 */

import '@/framework'





/***********************************************************
*  chart
*/
import '@kl-framework/charts/charts'



/***********************************************************
*  Store
*/
import store from '@store'



/******************************************************
 * global mixin
 */

import {mixinDetictingMobile} from '@/utils/detectMobile.js'
Vue.mixin(mixinDetictingMobile)





/***********************************************************
*  Routers
*/
import router from './router'


/***********************************************************
*  Api
*/
import apiHttp from '@api/apiHttp'
Vue.prototype.$http = apiHttp;






/***********************************************************
*  Validador de Formulário
*/
import '@components/validate/valid.js'

Vue.prototype.$validateState = function({ dirty, validated, valid = null }) {
  return dirty || validated ? valid : null;
}


/**********************************************************
 * Máscara de inputs
 */
import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)




/**********************************************************
 * url do avatar
 */
const AVATAR_URL = process.env.NODE_ENV === 'production' ? '' : appConfig.URL_TESTE;
Vue.prototype.$avatarUrl = AVATAR_URL+'/account/user/avatar/';






/**********************************************************
 * permissões de acesso
 */

import { subject } from '@casl/ability';
import { abilitiesPlugin } from '@casl/vue'
import { Can } from '@casl/vue';
import {createAbility} from '@/config/ability'
let ability = createAbility()
Vue.use(abilitiesPlugin, ability)
Vue.component('Can', Can);
Vue.prototype.$subject = subject;

var vueApp = new Vue({
  i18n,
  store,
  router,
  render: h => h(APP,{props:{appName:'app'}})
})


vueApp.$mount('#app')
