<template>
  <v-chart class="echarts" :option="serie" :theme="theme" autoresize />
</template>

<script>
import { DateTime } from "luxon";

//import chartTheme from "@kl-framework/charts/themes/dark.js";
import { LinearGradient } from "echarts/lib/util/graphic";

const areaStyle = {
  normal: {
    color: new LinearGradient(0, 0, 0, 1, [
      {
        offset: 0,
        color: "rgba(77, 210, 255, 0.7)",
      },
      {
        offset: 1,
        color: "rgba(200, 210, 255, 0.1)",
      },
    ]),
  },
};

const predictAreaStyle = {
  normal: {
    color: new LinearGradient(0, 0, 0, 1, [
      {
        offset: 0,
        color: "rgba(200, 200, 200, 0.5)",
      },
      {
        offset: 1,
        color: "rgba(150, 150, 150, 0.0)",
      },
    ]),
  },
};

export default {
  data: function() {
    return {
      theme: undefined, // chartTheme,
    };
  },
  props: {
    data: Object,
    emptyLevel: { type: Number },
    fullLevel: { type: Number },
    warningFull: { type: Number },
    warningEmpty: { type: Number },
    unit:{type:String, default:''}
  },
  computed: {
    serie: function() {
      let data = this.data;
      let unit = this.unit

      if (!data) return;

      let predictionsTime = [];

      if (data.timeToWarningEmpty) {
        predictionsTime.push({
          value: DateTime.fromMillis(data.timeToWarningEmpty).toLocaleString(DateTime.TIME_SIMPLE),
          coord: [data.timeToWarningEmpty, this.warningEmpty],
        });
      }
      if (data.timeToWarningFull) {
        predictionsTime.push({
          value:  DateTime.fromMillis(data.timeToWarningFull).toLocaleString(DateTime.TIME_SIMPLE),
          coord: [data.timeToWarningFull, this.warningFull],
        });
      }

      let series = [];

      if (data.levelSerie) {
        series.push({
          yAxisIndex: 0,
          name: "Nível",
          type: "line",
          data: data.levelSerie,
          areaStyle: areaStyle,
          color: "#3c8dbc",
           showSymbol: false,
            symbolSize: 10,
        });
      }

      if (data.prediction) {
        series.push({
          yAxisIndex: 0,
          name: "tendência",
          lineStyle: { type: "dashed" },
          type: "line",
          areaStyle: predictAreaStyle,
          data: data.prediction,
          markPoint: {
            symbol: "pin",
            symbolSize: 60,
            data: predictionsTime,
            label: { color: "#000" },
            itemStyle: {
              color: "#fa0",
            },
          },
          color: "#aaa",
          showSymbol: false,
          
        });
      }

      if (this.warningFull && this.warningEmpty) {
        series.push({
          name: "Alertas",
          type: "line",
          showSymbol: false,
          markLine: {
            // silent: false,
            data: [
              { name: "Alerta mínimo", yAxis: this.warningEmpty },
              { name: "Alerta máximo", yAxis: this.warningFull },
            ],
            label: {
              color: "#f00",
              formatter: "{b}: {c} "+unit,
              show: true,
              position: "middle",
            },
          },
          color: "#f70",
        });
      }

      return {
        title: { left: "center", padding: 1, textStyle: { color: "#fff" } },
        dataZoom: [
          {
            type: "inside",
            show: true,
            realtime: true,
            start: 0,
            end: 100,
          },
        ],
        legend: {
          show: true,
          bottom: 0,
          //  data:['Nível','tendência','Alertas']
        },
        tooltip: {
          trigger: "axis",
          axisPointer: { type: "cross", crossStyle: { color: "#999" } },
           formatter: function (params) {
             params = params[0];
             var date = new Date(params.axisValue);
             return DateTime.fromJSDate(date).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)+' <br> ' + params.value[1]+' '+unit;
         }
        },
        grid: { left:0, right:10, bottom: 40, top:40, containLabel: true },
        yAxis: [
          {
            scale: false,
            min: this.emptyLevel,
            max: this.fullLevel,
            axisLabel: { formatter: "{value} "+unit },
          },
        ],

        xAxis: {
          type: "time",
        },
        series: series,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.echarts {
  width: 100%;
  height: 350px;
  background-color: #fff;
}
</style>
