<template lang="">
        <b-modal size="md"  ref="delete-confirm" title="Você tem certeza?">

            <b-alert show variant="danger">
                <div>Você está prestes a excluir este registro <b>permanentemente</b></div>
                <slot></slot>

                <ul>
                    <li v-for="obj,index in excludeObjects" :key='index'> {{ obj}} </li>
                </ul>

            </b-alert>
            
            <div>
                Digite o seguinte para confirmar: <code> {{currentPassphrase}}</code>
            </div>
            <div>
                <b-input autofocus autocomplete="off" v-model="inputPassphrase"></b-input>
            </div>
            
            <template #modal-footer>
                <b-button size="sm" @click="_cancel"> Cancelar, manter o registro </b-button>
                <b-button size="sm" @click="_confirm" variant="danger" :disabled="!confirmButtonEnable"> Sim, apagar </b-button>
            </template>

        </b-modal>
</template>
<script>

export default {

    data: () => ({
        resolvePromise: undefined,
        rejectPromise: undefined,
        inputPassphrase:"",
        currentPassphrase:"",
        excludeObjects:[]
       
    }),

    computed:{
        confirmButtonEnable(){
            return this.inputPassphrase == this.currentPassphrase
        }
    },

    props:{
        passphrase:{type:String,default:'excluir-registro'}
    },
    methods: {
        show(passPhrase,options={}) {
            
            this.inputPassphrase = ""
            if(passPhrase){
                this.currentPassphrase = passPhrase
            }else{
                this.currentPassphrase = this.passphrase
            }

            this.excludeObjects = options.excludeObjects

            this.$refs['delete-confirm'].show()
            return new Promise((resolve, reject) => {
                this.resolvePromise = resolve
                this.rejectPromise = reject
            })
        },

        _confirm() {
            this.$refs['delete-confirm'].hide()
            this.resolvePromise(true)
        },

        _cancel() {
            this.$refs['delete-confirm'].hide()
            this.resolvePromise(false)
        },
    },
}
</script>
<style lang="">
    
</style>