<template>
  <div>
    <b-alert show class="pr-5">
      <div class="d-flex">
        <div class="mr-2" style="font-size:35px;">
          <font-awesome-icon icon="info-circle" />
        </div>
        <div>
          Seu nome completo, nome de usuário, email e telefone
          são compartilhados em todas as áreas de trabalho que você faz parte.
        </div>
      </div>
    </b-alert>

    <validation-observer v-if="user!= undefined" ref="formObserver">
      <ValidationProvider slim vid="name" name="Nome" :rules="{ required: true,min:5 }" v-slot="v">
        <b-form-group label="Nome Completo" class="col-md-6">
          <b-form-input
            name="name"
            v-model="userEdit.name"
            type="text"
            maxlength="50"
            :state="$validateState(v)"
          ></b-form-input>
          <span class="invalid-input">{{ v.errors[0] }}</span>
        </b-form-group>
      </ValidationProvider>

      <!-- USUARIO -->
      <ValidationProvider
        slim
        vid="username"
        name="Username"
        rules="required|verify_username"
        v-slot="v"
      >
        <b-form-group
          label="Username"
          description="Usado para efetuar login e para menções."
          class="col-md-6"
        >
          <b-form-input
            name="username"
            v-model="userEdit.username"
            type="text"
            maxlength="30"
            placeholder="****.****"
            :state="$validateState(v)"
          ></b-form-input>
          <span class="invalid-input">{{ v.errors[0] }}</span>
        </b-form-group>
      </ValidationProvider>

      <!-- Sobre -->
      <ValidationProvider
        slim
        vid="about"
        name="Sobre"
        :rules="{ required: false, min:4 }"
        v-slot="v"
      >
        <b-form-group label="Sobre" class="col-md-6">
          <b-form-input
            name="about"
            v-model="userEdit.about"
            type="text"
            maxlength="30"
            :state="$validateState(v)"
          ></b-form-input>
          <span class="invalid-input">{{ v.errors[0] }}</span>
        </b-form-group>
      </ValidationProvider>

      <!-- PHONE -->
      <ValidationProvider
        slim
        vid="phone"
        name="telefone"
        :rules="{ required: false,min:3 }"
        v-slot="v"
      >
        <b-form-group label="Telefone" class="col-md-6">
          <b-form-input
            name="phone"
            v-model="userEdit.phone"
            type="text"
            maxlength="30"
            :state="$validateState(v)"
          ></b-form-input>
          <span class="invalid-input">{{ v.errors[0] }}</span>
        </b-form-group>
      </ValidationProvider>

      <!-- email -->
      <ValidationProvider slim vid="email" name="email" rules="email" v-slot="v">
        <b-form-group label="Email" class="col-md-6">
          <b-form-input
            name="email"
            v-model="userEdit.email"
            type="text"
            maxlength="30"
            :state="$validateState(v)"
          ></b-form-input>
          <span class="invalid-input">{{ v.errors[0] }}</span>
        </b-form-group>
      </ValidationProvider>

      <b-col sm="6" class="d-flex">
        <div class="ml-auto">
          <b-button v-if="!updating" variant="success" @click="submit()">Salvar Mudanças</b-button>
          <b-button v-else>
            <font-awesome-icon icon="spinner" class="fa-spin" />salvando...
          </b-button>
        </div>
      </b-col>
    </validation-observer>

    <h4>Sua Imagem</h4>
    <p>
      Personalize como as pessoas vêem você.
      Você pode arrastar e soltar um arquivo nessa caixa de diálogo ou clicar
      no botão abaixo para fazer o upload.
    </p>

    <b-col lg="4">
      <div v-if="imageEdit">
        <div v-if="updating_img">
          <font-awesome-icon icon="spinner" class="fa-spin" />processando...
        </div>
        <imageInput v-else v-on:finished="setImage($event)" />
      </div>
      <div v-else>
        <b-img v-if="user.imageSrc" :src="$avatarUrl+user.imageSrc"></b-img>
        <div class="p-1">
          <b-button size="sm" @click="imageEdit=true" variant="primary">editar</b-button>
          <b-button size="sm" @click="deleteImage" variant="danger">apagar imagem</b-button>
        </div>
      </div>
    </b-col>
  </div>
</template>

<script>
import imageInput from "../user/imageInput";

export default {
  data() {
    return {
      updating: false,
      updating_img: false,
      imageEdit: false,
      userEdit:undefined
    };
  },
  props: {
    user: { type: Object, required: true }
  },

  beforeMount(){
    this.userEdit = JSON.parse(JSON.stringify(this.user))
  },

  methods: {
    submit() {
      this.$refs.formObserver.validate().then(result => {
        if (!result) {
          return;
        }

        this.updating = true;

        this.$http.put("account/user/self/", this.userEdit).then(
          data => {
            this.$emit("update", data);
            this.updating = false;
          },
          error => {
            console.error(error)
            this.updating = false;
            this.$bvToast.toast("Não foi possíve realizar a operação", {
              title: "error",
              autoHideDelay: 3000,
              variant: "danger",
              solid: true
            });
          }
        );
      });
    },

    setImage(img) {
      var img_data = img.toDataURL();
      this.updating_img = true;
      this.$http
        .put("account/user/self/image", { avatarbase64: img_data })
        .then(
          data => {
            this.$emit("update", data);
            this.updating_img = false;
            this.imageEdit = false;
          },
          error => {
            console.erro(error)
            this.updating_img = false;
            this.$bvToast.toast("Não foi possíve realizar a operação", {
              title: "error",
              autoHideDelay: 3000,
              variant: "danger",
              solid: true
            });
          }
        );
    },

    deleteImage() {
      this.updating_img = true;
      this.$http.delete("account/user/self/image").then(
        data => {
          this.$emit("update", data);
          this.updating_img = false;
          this.imageEdit = false;
        },
        error => {
          console.error(error)
          this.updating_img = false;
          this.$bvToast.toast("Não foi possíve realizar a operação", {
            title: "error",
            autoHideDelay: 3000,
            variant: "danger",
            solid: true
          });
        }
      );
    }
  },
  components: {
    imageInput
  }
};
</script>

<style>
</style>