const equator_circumference = 6371000 * 2 * Math.PI;
const polar_circumference = 6356800 * 2 * Math.PI;
const m_per_deg_long = 360.0 / polar_circumference;

export const pivotGeometry = {
  methods: {
    getGeometry: function (start, end, radius, center, zeroPos) {
      //center = proj.fromLonLat(center);
      let pointsArea = [];
      let pointsZero = [];

      // const meterConverter = 1 / 105000;
      // const radiusM = radius * meterConverter;
      //const radiusM10 = (radius+30)*meterConverter;
      //  //Marcação do ponto zero
      //  pointsArea.push([center[0]+ Math.sin(zeroPos*(Math.PI/180))*radiusM,
      //               center[1]+ Math.cos(zeroPos*(Math.PI/180))*radiusM]);
      //  pointsArea.push([center[0]+ Math.sin(zeroPos*(Math.PI/180))*radiusM10,
      //               center[1]+ Math.cos(zeroPos*(Math.PI/180))*radiusM10]);

      if ((end - start) < 360) {
        pointsArea.push([center[0], center[1]]);
      }

      let lon = center[0];
      let lat = center[1];

      pointsZero.push([lon,lat]);

      let rad_lat = (lat * (Math.PI) / 180);
      let m_per_deg_lat = 360.0 / (Math.cos(rad_lat) * equator_circumference);
      let radiusLon = radius * m_per_deg_lat;
      let radiusLat = radius * m_per_deg_long;
      

      pointsZero.push(
        [ lon + Math.sin(zeroPos * (Math.PI / 180)) * radiusLon ,
          lat + Math.cos(zeroPos * (Math.PI / 180)) * radiusLat
        ]);


      for (let i = start + zeroPos; i <= end + zeroPos; i++) {
        let point = [0, 0];
        point[0] = lon + Math.sin(i * (Math.PI / 180)) * radiusLon;
        point[1] = lat + Math.cos(i * (Math.PI / 180)) * radiusLat;
        pointsArea.push(point);
      }
      if ((end - start) < 360) {
        pointsArea.push([lon, lat]);
      }
      return {area:pointsArea, zeroLine:pointsZero};
    },

  },


}