import workspacePageList from './workspacePageList'
import workspaceDetails from './workspacePageDetails'
import workspacePageNew from './workspacePageNew'

export default {
  path: 'workspace',
  component: workspacePageList,
  name: 'workspaceList',
  meta: {authorize:{action:'read',resource:'Workspace'}},
  children: [
    {
      path: ':id', name: 'workspaceDetails', component: workspaceDetails,
      props: (route) => ({ workspaceId: route.params.id }),
      meta: {authorize:{action:'read',resource:'Workspace'}},
    },
    {
      path: 'new', name: 'workspaceNew', component: workspacePageNew,
      meta: {authorize:{action:'create',resource:'Workspace'}},
    }
  ]
}