const generateMatrix = function(points, width, height ,pixelSize, power = 2, xoffset = 0,yoffset = 0){

    let sizex = ((width / pixelSize) >> 0) + 4;
    let sizey = ((height / pixelSize) >> 0) + 4;

    let matrix = new Array(sizex);

    for (var x = 0; x < sizex; x++) {
      matrix[x] = new Array(sizey);
      for (var y = 0; y < sizey; y++) {
        //  iii++;
        var dists = [];
        var sum_dist = 0;

        // calculate, record and sum the (decayed) distances
        points.forEach((dest) => {
          let eucdist =
            Math.pow(x * pixelSize - dest.destpos[0] + xoffset, 2) +
            Math.pow(y * pixelSize - dest.destpos[1] + yoffset, 2);
          let dist_decayed = Math.pow(eucdist, -power);
          sum_dist += dist_decayed;
          dists.push({ dist: dist_decayed, val: dest.val });
        });

        // calculate the inverse distance weight
        matrix[x][y] = 0;
        for (let i = 0, len = dists.length; i < len; i++) {
          matrix[x][y] += (dists[i].val * dists[i].dist) / sum_dist;
        }
      }
    }

    return matrix;
  }



  const generateImage = function(canvas,width,height,pixelSize,matrix, maxValue, minValue, cols,alphaColor=undefined, contourLine= undefined){
   
    width = width/pixelSize >> 0
    height = height/pixelSize >>0 
    pixelSize = 1

    canvas.width = width
    canvas.height = height
    
    let ctx = canvas.getContext("2d");
    var dat = ctx.getImageData(0, 0, width, height);
    var pix = dat.data;
    var minx, dx, miny, dy; //bilinear

    let val_range = maxValue - minValue; //rescale to 0.0 - 1.0
    

    for (let x = 0; x < width; x++) {
      minx = (x / pixelSize) >> 0; //bilinear
      
      dx = x / pixelSize - minx; //bilinear
      for (let y = 0; y < height; y++) {
        var pixel_val = undefined;
        if (pixelSize > 1) {
          //If pixelSize > 1 we need to resample our IDX grid to match the canvas size
          //NEAREST NEIGHBOUR (blocky and awful)
          //var pixel_val = matrix[Math.round(x / pixelSize)][Math.round(y / pixelSize)]
          //BILINEAR (moderately blockly but tolerable)
          miny = (y / pixelSize) >> 0;
          dy = y / pixelSize - miny;

          try{
              pixel_val =
                (matrix[minx][miny] * (2 - dx - dy) +
                  matrix[minx][miny + 1] * (1 - dx + dy) +
                  matrix[minx + 1][miny] * (dx + 1 - dy) +
                  matrix[minx + 1][miny + 1] * (dx + dy)) /
                4;
          }
          catch(e){
              console.error("error");
              return 
          }

        } else {
          pixel_val = matrix[x][y];
        }


         if(contourLine){
          let v = pixel_val
          contourLine.forEach(e=>{
           if(pixel_val>e)
            v = e
          })
          pixel_val = v
         }
         if(pixel_val > maxValue ) 
          pixel_val = maxValue-0.1



        pixel_val = (pixel_val - minValue) / val_range;

       
        // console.log(pixel_val, Math.round(pixel_val*10)/10.0)
        //pixel_val = Math.round(pixel_val*100)/100.0

        let idx = (width * y + x) * 4;
        // Rescale to fit into colour ramp
        let colRamp = (cols.length - 1) * pixel_val;
        let colFloor = colRamp >> 0;
        let minCol = cols[colFloor];
        let maxCol = cols[colFloor + 1];
        pixel_val = colRamp - colFloor;
        let inv_val = 1 - pixel_val;


        
          
        let r = pix[idx] = minCol[0] * inv_val + maxCol[0] * pixel_val; //red
        let g = pix[idx + 1] = minCol[1] * inv_val + maxCol[1] * pixel_val; //green
        let b = pix[idx + 2] = minCol[2] * inv_val + maxCol[2] * pixel_val; //blue


        if(!alphaColor){
          pix[idx + 3] = minCol[3] * inv_val + maxCol[3] * pixel_val; //alpha
        }else{
          pix[idx + 3] = (255*alphaColor)>>0
        }

        pix[idx] =   r;
        pix[idx+1] = g;
        pix[idx+2] = b;
      }
    }

    ctx.putImageData(dat, 0, 0);
  }



  function getExtentArea(geometricArea) {
    let maxX = -Infinity;
    let maxY = -Infinity;
    let minX = Infinity;
    let minY = Infinity;
    geometricArea.forEach((e) => {
      e.forEach((e2) => {
        let pos = e2;
        if (maxX < pos[0]) maxX = pos[0];
        if (maxY < pos[1]) maxY = pos[1];
        if (minX > pos[0]) minX = pos[0];
        if (minY > pos[1]) minY = pos[1];
      });
    });
    return [minX, minY, maxX, maxY];
  }



function posTransform(extent, size, pos) {
    let pp = [0, 0];
    pp[0] = ((pos[0] - extent[0]) / (extent[2] - extent[0])) * size[0];
    pp[1] = ((pos[1] - extent[3]) / (extent[1] - extent[3])) * size[1];
    return pp;
  }

  export { generateMatrix,generateImage,getExtentArea,posTransform};