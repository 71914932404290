<template>
  <div>

  
  <list-page
    :loadStatus="loadStatus"
    :numRows="numRows"
    title="Estações"
    subTitle="Lista de Estações para manejo de irrigação."
    @load-retry="loadData()"
  >
    <template v-slot:button>
      <b-button
        v-if="$can('create', 'WeatherStation')"
        class="shadow-sm"
        pill
        @click="newStation"
        variant="success"
        >+ nova</b-button
      >
    </template>

    <template v-slot:head>
      <simple-map :mapHeight="'300px'">
            <stationMap
              v-for="station in stationList"
              :key="station.id"
              :station="station"
              @click="$router.push({name:'stationDetails',params:{id:station.id}})"
            />
      </simple-map>
    </template>

    <!-- LISTA -->
    <template v-slot:list="props">
      <stationTableList
        class="mb-2"
        :per-page="props.perPage"
        :current-page="props.currentPage"
        :stationList="stationList"
        :filter="props.filter"
        v-on:removestation="removeStation($event)"
        v-on:editstation="editStation($event)"
        v-on:onnumrows="onNumRows($event)"
      />
      <router-view
        :stationList="stationList"
        v-on:removestation="removeStation($event)"
        v-on:update:station="updateList($event)"
      ></router-view>
    </template>

  
  </list-page>

</div>
</template> 

<script>
import stationTableList from "./stationTableList";
import stationMap from "./stationMap";

export default {
  data() {
    return {
      stationList: [],
      loadStatus: String,
      numRows: 1,
    };
  },
  props: {
    editable: { type: Boolean, default: true },
  },
  beforeMount() {
    this.loadData();
  },
  components: {
    stationTableList,
    stationMap
  },
  methods: {
    loadData() {
      this.loadStatus = "loading";
      this.$http.get("irrig/station").then(
        (data) => {
          this.stationList = data;
          this.loadStatus = "loaded";
          this.numRows = this.stationList.length;
        },
        (error) => {
          console.error("load station error:", error);
          this.loadStatus = "error";
        }
      );
    },

    onNumRows(numrows) {
      this.numRows = numrows;
    },

    //atualiza os valores do solo da lista a partir de um novo solo
    updateList(newStation) {
      const index = this.stationList.findIndex((e) => e.id === newStation.id);
      if (index === -1) {
        this.stationList.push(newStation);
        this.numRows = this.stationList.length;
      } else {
        let dataObj = this.stationList[index];
        Object.entries(newStation).forEach(([key, value]) => {
          dataObj[key] = value;
        });
      }
    },

    editStation(id) {
      this.$router.push("/station/" + id + "/edit");
    },

    removeStation(id) {

      this.$deleteConfirm.show(
        "excluir-estacao",
        {excludeObjects:["Estação","Climas"]}
      ).then((value) => {
        if (value === true) {
          this.$http.delete("irrig/station/" + id).then(
              () => {
                let index = this.stationList.findIndex((e) => e.id === id);
                this.stationList.splice(index, 1);
                this.numRows = this.stationList.length;
                this.$router.push({ name: "stationList" });
              },
              (error) => {
                console.error("delete station error:", error);
                this.$bvModal.msgBoxOk(
                  "Não foi possível remover a estação.\
                  É possível que ela esteja sendo usada em alguma parcela.",
                  {
                    title: "Erro!",
                  }
                );
              }
            );
        }
      })
    },

    newStation() {
      this.$router.push({ name: "stationNew" });
    },
  },
};
</script>

<style></style>
