import userPageList from './userPageList'
import userPageNew from './userPageNew'
import userPageDetails from './userPageDetails'


export default {
  path: 'user', component: userPageList, name: 'userList',
  meta: {authorize:{action:'read',resource:'User'}},
  children: [
    {
      path: ':id', name: 'userDetails', component: userPageDetails,
      props: (route) => ({ userId: route.params.id })
    },
    {
      path: 'new', name: 'userNew', component: userPageNew,
      meta: {authorize:{action:'create',resource:'User'}},
    }
  ]
}

