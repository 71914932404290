
import billCompositionItens from '../electricity/billCompositionItens.js'

export function createFieldCells(data) {

    let rows = []


    let headerColor = "#dde8cb"

    let formatHeaders = { fontSize: 10, borderStyle: "thin", align: 'center', height: 24, backgroundColor: headerColor,alignVertical:'top' }
    let formatNumbers = { align: 'center', type: Number }


    rows.push([
        null,
        { value: 'Parcela', ...formatHeaders, type: String, rightBorderStyle: "medium", span: 6 ,alignVertical:'center'}, null, null, null,null,null,
        { value: 'Acumulado', ...formatHeaders, type: String, rightBorderStyle: "medium", span: 5,alignVertical:'center' }, null, null, null, null,
        { value: 'Mês', ...formatHeaders, type: String, rightBorderStyle: "medium", span: 7 ,alignVertical:'center'}, null, null, null, null, null, null,
    ])

    rows.push([
        null,
        { value: 'Equip.', ...formatHeaders, type: String },
        { value: 'Parcela', ...formatHeaders, type: String ,span:2},null,
        { value: 'Cultura', ...formatHeaders, type: String ,span:2},null,
        { value: 'Área', ...formatHeaders, type: String, rightBorderStyle: "medium" },

        { value: 'Dias', ...formatHeaders, type: String },
        { value: 'Irrigação\n (mm)', ...formatHeaders, type: String },
        { value: 'Chuva\n (mm)', ...formatHeaders, type: String },
        { value: 'ETc\n (mm)', ...formatHeaders, type: String },
        { value: 'Red. ETpc\n (%)', ...formatHeaders, type: String, rightBorderStyle: "medium" },

        { value: 'Irrigação\n (mm)', ...formatHeaders, type: String },
        { value: 'Chuva\n (mm)', ...formatHeaders, type: String },
        { value: 'Red. ETpc\n (%)', ...formatHeaders, type: String },
        { value: 'Energia\n (R$/mm/ha)', ...formatHeaders, type: String },
        { value: 'Energia\n (R$/ha)', ...formatHeaders, type: String },
        { value: 'Energia\n (kWh)', ...formatHeaders, type: String },
        { value: 'Custo Total\n (R$)', ...formatHeaders, type: String, rightBorderStyle: "medium" },
    ])



    data.fields.forEach(field => {


        let formatCels = { fontSize: 10, borderStyle: "thin" }



        let cols = [
            null,
            { value: field.equipmentName, ...formatCels, type: String, },
            { value: field.fieldName, ...formatCels, type: String ,span:2},null,
            { value: field.culture.name, ...formatCels, type: String,span:2 },null,
            { value: (field.areaM2 / 10000), ...formatCels, ...formatNumbers, format: '##0.0', rightBorderStyle: "medium" },

            { value: field.accumulatedData.numDays, ...formatCels, ...formatNumbers },
            { value: field.accumulatedData.irrigation, ...formatCels, ...formatNumbers, format: '##0.0' },
            { value: field.accumulatedData.rain, ...formatCels, ...formatNumbers, format: '##0.0' },
            { value: field.accumulatedData.etc, ...formatCels, ...formatNumbers, format: '##0.00' },
            { value: field.accumulatedData.etcpReduction * 100, ...formatCels, ...formatNumbers, format: '##0.0', rightBorderStyle: "medium" },

            { value: field.intervalData.irrigation, ...formatCels, ...formatNumbers, format: '##0.0' },
            { value: field.intervalData.rain, ...formatCels, ...formatNumbers, format: '##0.0' },
            { value: field.intervalData.etcpReduction * 100, ...formatCels, ...formatNumbers, format: '##0.0' },
            { value: field.intervalData.costPerMmPerHa, ...formatCels, ...formatNumbers, format: '##0.00' },
            { value: field.intervalData.costPerHa, ...formatCels, ...formatNumbers, format: '##0.00' },
            { value: field.intervalData.powerKWh, ...formatCels, ...formatNumbers, format: '##0.0' },
            { value: field.intervalData.totalCost, ...formatCels, ...formatNumbers, format: '#,##0.00', rightBorderStyle: "medium" }
        ]

        rows.push(cols)

    });

    {
        let formatCels = { fontSize: 10, borderStyle: "thin", backgroundColor: headerColor, height: 24 ,fontWeight: 'bold',alignVertical:'center'}

        let totalCols = [
            null,
            { value: 'Total / Média\n(considera áreas das parcelas)', ...formatCels, type: String, span: 5 }, null, null,null,null,

            { value: data.totalAccumulated.areaHa, ...formatCels, ...formatNumbers ,rightBorderStyle: "medium",format: '##0.0'},
            {  ...formatCels },

            { value: data.totalAccumulated.irrigation, ...formatCels, ...formatNumbers, format: '##0.0' },
            { value: data.totalAccumulated.rain, ...formatCels, ...formatNumbers, format: '##0.0' },
            { value: data.totalAccumulated.etc, ...formatCels, ...formatNumbers, format: '##0.00' },
            { value: (data.totalAccumulated.etpcReduction*100), ...formatCels, ...formatNumbers, format: '##0.0', rightBorderStyle: "medium" },

            { value: data.totalMonth.irrigation, ...formatCels, ...formatNumbers, format: '##0.0' },
            { value: data.totalMonth.rain, ...formatCels, ...formatNumbers, format: '##0.0' },
            { value: (data.totalMonth.etpcReduction*100), ...formatCels, ...formatNumbers, format: '##0.0' },
            { value: data.totalMonth.costPerMmPerHa, ...formatCels, ...formatNumbers, format: '##0.00' },
            { value: data.totalMonth.costPerHa, ...formatCels, ...formatNumbers, format: '##0.00' },
            { value: data.totalMonth.powerKWh, ...formatCels, ...formatNumbers, format: '##0.0' },
            { value: data.totalMonth.totalCost, ...formatCels, ...formatNumbers, format: '#,##0.00', rightBorderStyle: "medium" }
        ]

        rows.push(totalCols)
    }






    return rows;

}






export function createBillCells(data,energiComments) {

    let rows = []
    let headerColor = "#dde8cb"
    let formatHeaders = { fontSize: 10, borderStyle: "thin", align: 'center', height: 24, backgroundColor: headerColor, alignVertical:'center' }

    let arrayItens = billCompositionItens(data.composition)

    let commentsRowSpan = arrayItens.length +2


    rows.push([
        null,
        { value: 'Descrição', ...formatHeaders, type: String, span: 4 }, null,null,null,
        { value: 'Quantidade', ...formatHeaders, type: String ,span: 2},null,
        { value: 'Valor (R$)', ...formatHeaders, type: String ,span: 2},null,
        null,
        {value:energiComments,span: 9,rowSpan:commentsRowSpan, borderStyle: "thin",alignVertical:'top',fontSize:12,wrap: true}
    ])



   

    let formatNumbers = { align: 'center', type: Number, fontSize: 10 }

    let colorList = {}
    colorList['danger'] = "#ed969e"
    colorList['success'] = "#8fd19e"
    colorList['warning'] = "#ffdf7e"


    {
        let formatCels = { fontSize: 10, borderStyle: "thin" }
        arrayItens.forEach(item => {
            let color = colorList[item.color]

            rows.push([
                null,
                { value: item.name + (item.unit ? " (" + item.unit + ")" : ""), ...formatCels, type: String, span: 4, backgroundColor: color }, null,null,null,
                { value: item.power, ...formatCels, ...formatNumbers, format: '##0.00', backgroundColor: color,span:2 },null,
                { value: item.price, ...formatCels, ...formatNumbers, format: '#,##0.00', backgroundColor: color, span:2 },null,
            ])

        })
    }

    {
        let formatCels = { fontSize: 10, borderStyle: "thin", height: 24, backgroundColor: headerColor,fontWeight: 'bold',alignVertical:'center' }
        rows.push([
            null,
            { value: 'Total', ...formatCels, span: 4 },null,null,
            null, { value: '', ...formatCels ,span:2},null,
            { value: data.totalCost, ...formatCels, ...formatNumbers, format: '[$R$-416] #,##0.00', span:2 }
        ])
    }




    return rows;
}