<template>
  <v-chart style="width:100%;height:300px;" :option="serie" autoresize />
</template>

<script>
import { DateTime } from "luxon";

var monthFormatter = function(value) {
  let _value = typeof value === "object" ? value.value : value;
  return DateTime.fromFormat(_value, "yyyy-MM").toLocaleString({
    month: "2-digit",
    year: "2-digit",
  });
};

export default {
  props: {
    data: Array,
    unit: String,
  },

  computed: {

    serie: function() {
      let xSerie = this.data.map((item) => 
        item.month
      );

      let volumeSerie = this.data.map((item) => 
        item.volume,
      );

      let grantSerie = this.data.map((item) => 
        item.grant,
      );

      return {
        grid: [{ right: 0, bottom: 40, top: 25, left: 0, containLabel: true }],
        tooltip: {
          trigger: "axis",
          axisPointer: { type: "cross", crossStyle: { color: "#999" } },
          valueFormatter: (v) => this.$filters.localeNumber(v,0)+" "+this.unit,
        }, 

        legend:{
            data:['Volume','Outorga'],
            bottom:0
        },

        xAxis: [
          {
            type: "category",
            data: xSerie,
            axisLabel: {
              formatter: monthFormatter,
            },
            axisPointer: {
              label: {
                formatter: monthFormatter,
              },
            },
          },
        ],

        yAxis: [{ 
          name: this.unit,
          type: "value",
          axisLabel: { formatter: (v)=> this.$filters.localeNumber(v,0)}
          }],
        series: [
          {
            name: "Volume",
            data: volumeSerie,
            type: "bar",
            
          },

          {
            name: "Outorga",
            data: grantSerie,
            type: "bar",
            color: "#af4534",
          },
        ],
      };
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
