<template>
  <b-modal
    no-close-on-backdrop
    no-fade
    hide-footer
    @hidden="close"
    scrollable
    ref="equipmentDetails-modal"
    size="max-xl"
  >
    <template v-slot:modal-title>
    
    <span v-if="$route.name === 'equipmentEdit'"><b-link :to="{name:'equipmentDetails',params:{id:equipment.id} }">{{equipment.name }}</b-link> / Editar </span>
    <span v-else> {{equipment.name || 'Novo Equipamento'}}</span>
      
      <!-- 
      <div class="d-flex"> 
        <b-button size="sm" @click="before()"> < </b-button>
        <b-button size="sm" @click="next()">  > </b-button>
      </div>
      -->
    </template>

    <router-view :equipment="equipment" v-on:close="close"></router-view>
  </b-modal>
</template>

<script>

import "./equipment.less";

export default {
  props: {
    equipment: {
      type: Object,
      default() {
        return {};
      }
    }
  },

  mounted() {
    this.$refs["equipmentDetails-modal"].show();
  },

  methods: {
    close() {
      this.$router.push({ name: "home" });
    },

    before() {},

    next() {}
  }
};
</script>

<style>
</style>