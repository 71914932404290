import socketStomp from "@kl-framework/socketStomp.js"; 

import appConfig from "@/appConfig.js";

const SITE_ADDR =
  process.env.NODE_ENV === "production" ? "" : appConfig.URL_TESTE;

export default function(store) {

  var skStomp = new socketStomp(SITE_ADDR+ "/irrigWS");
  var clientId_ = undefined;



  /******
   * Se inscreve em tópico
   */
  this.listen = function(clientId) {
    clientId_ = clientId;
    skStomp.unsubscribeAll();
  
    skStomp.subscribe("/topic/field/" + clientId_, function(
      messageOutput
    ) {
      let data = JSON.parse(messageOutput.body);
      // console.log(messageOutput.body);
      if (typeof data === "object") {
        store.commit("equipments/saveField", data);
      }
    });
  };

  
}
