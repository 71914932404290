<template>
  <v-chart style="width:100%;height:300px;" :option="serie" autoresize  :theme="darkTheme" />
</template>

<script>
import { DateTime } from "luxon";
import darkTheme from "@kl-framework/charts/themes/dark.js";


export default {
  props: {
    rains: Array,
  },

  data(){
    return {
      darkTheme: darkTheme,
    }
  },

  computed: {
    serie: function() {
      let rains = this.rains.map((item) => [
        DateTime.fromISO(item.date).toJSDate(),
        item.precipitation,
      ]);

      return {
        // title: {
        //   text: "Histórico de climas"
        // },
        grid: [{ bottom: 20, top: 40, right: 5, left: 5,containLabel: true }],
        tooltip: {
          trigger: "axis",
          axisPointer: { type: "cross", crossStyle: { color: "#999" } },
        },
        xAxis: {
          type: "time",
          axisPointer:{
            label:{
              formatter: function(value) {
                return DateTime.fromMillis(value.value).toLocaleString();
              },
            }
          },
        },
        yAxis: [{ name: "mm", type: "value" }],
        series: [
          {
            name: "chuva",
            data: rains,
            type: "bar",
            color: "#00ace6",
          },
        ],
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.echarts {
  width: 100%;
  height: 200px;
}
</style>
