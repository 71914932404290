<template>
  <ValidationProvider slim :name="label" :rules="rules" v-slot="v">
    <b-form-group :label="label">
      <b-form-input
        :value="value"
        @input="$emit('input', $event)"
        type="text"
        :maxlength="maxlength"
        :placeholder="label"
        :state="$validateState(v)"
      ></b-form-input>
      <span class="invalid-input">{{ v.errors[0] }}</span>
    </b-form-group>
  </ValidationProvider>
</template>

<script>
export default {
  name: "TextInput",
  props: {
    rules: Object,
    label: { type: [String,Number], required: true },
    value: String,
    maxlength:Number
  }
};
</script>

<style></style>
