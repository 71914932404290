<template>
  <div>
    <hr>

    <b-row>
      <b-col lg="6">
        <b-row>
          <!-- RAIO DO PIVÔ-->
          <ValidationProvider
            slim
            vid="radius"
            name="Raio"
            :rules="{required: true, minValue:1 }"
            v-slot="v"
          >
            <b-form-group label="Raio" class="col-sm-4">
              <b-input-group append="m">
                <b-form-input
                  name="radius"
                  v-model="equipment.radius"
                  type="number"
                  min="1"
                  placeholder="Raio"
                  :state="$validateState(v)"
                ></b-form-input>
              </b-input-group>
              <!-- <b-form-invalid-feedback>{{ v.errors[0] }}</b-form-invalid-feedback> -->
              <span class="invalid-input">{{ v.errors[0] }}</span>
            </b-form-group>
          </ValidationProvider>

          <!-- VAO EM BALANÇO-->
          <ValidationProvider
            slim
            vid="overhang"
            name="Balanço"
            :rules="{required: true, minValue:0 }"
            v-slot="v"
          >
            <b-form-group label="Balanço" class="col-sm-4">
              <b-input-group append="m">
                <b-form-input
                  name="overhang"
                  v-model="equipment.overhang"
                  type="number"
                  min="1"
                  placeholder="Balanço"
                  :state="$validateState(v)"
                ></b-form-input>
              </b-input-group>
              <!-- <b-form-invalid-feedback>{{ v.errors[0] }}</b-form-invalid-feedback> -->
              <span class="invalid-input">{{ v.errors[0] }}</span>
            </b-form-group>
          </ValidationProvider>

          <!-- VELOCIDADE MÁXIMA-->
          <ValidationProvider
            slim
            vid="velocityMax"
            name="Velocidade"
            :rules="{required: true, minValue:50 }"
            v-slot="v"
          >
            <b-form-group label="Velocidade" class="col-sm-4">
              <b-input-group append="m/h">
                <b-form-input
                  name="velocityMax"
                  v-model="equipment.velocityMax"
                  type="number"
                  min="0"
                  placeholder="Velocidade"
                  :state="$validateState(v)"
                ></b-form-input>
              </b-input-group>
              <!-- <b-form-invalid-feedback>{{ v.errors[0] }}</b-form-invalid-feedback> -->
              <span class="invalid-input">{{ v.errors[0] }}</span>
            </b-form-group>
          </ValidationProvider>

          <!-- OFSSET DE POSICAO -->
          <ValidationProvider
            slim
            vid="offsetPos"
            name="Posição Zero"
            :rules="{required: true, between:[-180,180]}"
            v-slot="v"
          >
            <b-form-group label="Posição Zero" class="col-12">
              <b-input-group>
                <b-input-group-prepend is-text class="input-slice-number" style="width:70%">
                  <b-form-input
                    name="offsetPos"
                    v-model="equipment.offsetPos"
                    type="range"
                    min="-180"
                    max="180"
                    :state="$validateState(v)"
                  ></b-form-input>
                </b-input-group-prepend>
                <b-form-input
                  name="offsetPos"
                  v-model="equipment.offsetPos"
                  type="number"
                  min="-180"
                  max="180"
                  :state="$validateState(v)"
                ></b-form-input>
                <b-input-group-append is-text>⁰</b-input-group-append>
              </b-input-group>
              <span class="invalid-input">{{ v.errors[0] }}</span>
            </b-form-group>
          </ValidationProvider>

          <!-- POSICAO INICIAL-->
          <ValidationProvider
            slim
            vid="areaStartPos"
            name="Posição inicial"
            :rules="{required: true, between:[0,360] }"
            v-slot="v"
          >
            <b-form-group label="Posição inicial" class="col-12">
              <b-input-group>
                <b-input-group-prepend is-text class="input-slice-number" style="width:70%">
                  <b-form-input
                    name="areaStartPos"
                    v-model="equipment.areaStartPos"
                    type="range"
                    min="0"
                    max="360"
                    :state="$validateState(v)"
                  ></b-form-input>
                </b-input-group-prepend>
                <b-form-input
                  name="areaStartPos"
                  v-model="equipment.areaStartPos"
                  type="number"
                  min="0"
                  max="360"
                  :state="$validateState(v)"
                ></b-form-input>
                <b-input-group-append is-text>⁰</b-input-group-append>
              </b-input-group>
              <span class="invalid-input">{{ v.errors[0] }}</span>
            </b-form-group>
          </ValidationProvider>

          <!-- POSICAO FINAL-->
          <ValidationProvider
            slim
            vid="areaEndPos"
            name="Posição final"
            :rules="{required: true,between:[0,360] }"
            v-slot="v"
          >
            <b-form-group label="Posição final" class="col-12">
              <b-input-group>
                <b-input-group-prepend is-text class="input-slice-number" style="width:70%">
                  <b-form-input
                    name="areaEndPos"
                    v-model="equipment.areaEndPos"
                    type="range"
                    min="0"
                    max="360"
                    placeholder="inicio"
                    :state="$validateState(v)"
                  ></b-form-input>
                </b-input-group-prepend>
                <b-form-input
                  name="areaEndPos"
                  v-model="equipment.areaEndPos"
                  type="text"
                  min="0"
                  max="360"
                  placeholder="inicio"
                  :state="$validateState(v)"
                ></b-form-input>
                <b-input-group-append is-text>⁰</b-input-group-append>
              </b-input-group>
              <!-- <b-form-invalid-feedback>{{ v.errors[0] }}</b-form-invalid-feedback> -->
              <span class="invalid-input">{{ v.errors[0] }}</span>
            </b-form-group>
          </ValidationProvider>
        </b-row>
      </b-col>

      <b-col lg="6">
        <div>Selecione posição do equipamento no mapa</div>
        <simple-map>
          <template v-slot:layer>
            <selectPosition v-model="equipment.location"/>
          </template>
            <pivotMap :equipment="equipment" />

          <template v-slot:no-fit-vectors>
             <clientMap :client="client" />
            <tbody v-for="pivot in pivots" :key="pivot.id" >
              <pivotMap  v-if="pivot.id!=value.id" :ghost="true" :equipment="pivot" />
            </tbody>
          </template>
        </simple-map>
      </b-col>



    </b-row>
    <!-- teste de lista de pivo
{{pivots}} -->
<!-- {{value}} -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import pivotMap from "./pivotMap";
import selectPosition from "@components/map/selectPosition";
import clientMap from "@accountcomponents/client/clientMap";

export default {
  data() {
    return {
      zoom: 2,
      center: [0, 0],
      features: [],
      equipment:undefined
    };
  },
  props: {
    value: { type: Object, required: true }
  },
  beforeMount(){
    // this.equipment = JSON.parse(JSON.stringify(this.value))
     this.equipment = {
      location    : this.value.location,
      radius      : this.value.radius,
      overhang    : this.value.overhang,
      velocityMax : this.value.velocityMax,
      offsetPos   : this.value.offsetPos,
      areaStartPos: this.value.areaStartPos,
      areaEndPos  : this.value.areaEndPos
     }
 
     if (this.value.location == undefined)
      this.equipment.location = this.client.location
  },
  components: {
    pivotMap,
    selectPosition,
    clientMap
  },

  watch:{
    equipment:{
      handler(val){
        // this.$emit("input",JSON.parse(JSON.stringify(val)));
        this.$emit('input', Object.assign({}, this.value,val))
     },
     deep: true
    }
  },

  computed: {
    ...mapGetters({
      client: "accounts/client",
      pivots: "equipments/pivots",
    })
  },
  mounted() {
    if (this.equipment.radius == undefined) {
      let location = { lon: 0, lat: 0 };

      if (this.client) {
        this.center = [this.client.location.lat, this.client.location.lon];
        location = this.client.location;
      }

      this.$set(this.equipment, "radius", 500);
      this.$set(this.equipment, "location", location);
      this.$set(this.equipment, "areaStartPos", 0);
      this.$set(this.equipment, "areaEndPos", 360);
      this.$set(this.equipment, "overhang", 10);
      this.$set(this.equipment, "offsetPos", 0);
    }
  }
};
</script>

<style>
</style>