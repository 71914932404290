<template>
<tbody v-if="dataIsValid">

  <vl-feature  :id="'pivotcenter-'+equipment.id" :properties="{compId:equipment.id,name: `${equipment.name}`, equipment: equipment}">
    <vl-geom-point :coordinates="[equipment.location.lon,equipment.location.lat]"></vl-geom-point>
    <vl-style-box :z-index="5">
        <vl-style-circle :radius="3">
          <vl-style-fill color="#fa0"></vl-style-fill>
        </vl-style-circle>
      </vl-style-box>
  </vl-feature>
</tbody>
</template>

<script>

export default {
  props: {
    equipment: {type:Object, required:true}
  },
  computed: {
    dataIsValid(){
      if(this.equipment.location == undefined){
          return false
      }else{
        return true
      }
    }
  },
  methods:{
    
  }
};
</script>

<style lang="css" scoped>
</style>
