import Vue from 'vue';
import VueI18n from 'vue-i18n';
Vue.use(VueI18n);



const messages = {
  'en': {
    layer:'Layer',
    unit:'Unit',
    date:'Date',
    edit:'Edit',
    new:'New',
    note:'Note',
    start:'Start',
    end:'End'
  },
  'pt-br': {
    layer:'Camada',
    unit:'Unidade',
    date:'Data',
    edit:'Editar',
    new:'Novo',
    note:'Anotação',
    start:'Início',
    end:'Fim'
  }
};



export const i18n = new VueI18n({
                             locale: 'pt-br', // set locale
                            //  locale: 'es', // set locale
                              fallbackLocale: 'en', // set fallback locale
                              messages, // set locale messages
                            });
