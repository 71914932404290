import soilPageList from './soilPageList'
import soilPageEdit from './soilPageEdit'

export default {
      path:'/soil',
      component:soilPageList,
      name:'soilList',
      children:[
        {
          path:':id/edit',
          name:'soilEdit',
          component:soilPageEdit,
          props: (route) => ({soilId:route.params.id}),
          meta: {authorize:{action:'edit',resource:'Soil',redirect:'soilList'}},
        },
        {
          path:'new',
          name:'soilNew',
          component:soilPageEdit,
          meta: {authorize:{action:'create',resource:'Soil',redirect:'soilList'}},
        }
      ]
    }