<script>
import IdwSource from "./IDWSource";
import { source } from "vuelayers/lib/mixin";
/**
 * Um source para layers com mapa de calor
 *
 */
export default {
  name: "vl-source-ndvi",
  mixins: [source],
  data() {
    return {
      source: undefined,
    };
  },
  props: {
    area: { type: Array, required: false },
    extent: { type: Array, required: true },
    src:{type:String,required:true},
    minValue:   { type: Number },
    maxValue:   { type: Number },
    gradient:   { type: Array, required: false },
    alphaColor: { type: Number, required: false },
    colorPalette: { type: Array, required: true },
    smoothing: { type: Boolean, required: false,default:false },
  },
  methods: {
    createSource() {
      this.source = new IdwSource({
        area: this.area,
        extent:this.extent,
        src:this.src,
        minValue: this.minValue,
        maxValue: this.maxValue,
        gradient: this.gradient,
        onStartRender: this.startRender,
        onEndRender: this.endRender,
        alphaColor: this.alphaColor,
        colorPalette:this.colorPalette,
        smoothing:this.smoothing
      });
      return this.source;
    },

  },

  watch: {

    smoothing(){
      this.scheduleRecreate();
    },
    area(){
      this.scheduleRecreate();
    },
    alphaColor(){
      this.scheduleRecreate();
    },
    colorPalette(){
      this.scheduleRecreate();
    },
    src() {
        this.scheduleRecreate();
    },
    minValue() {
        this.scheduleRecreate();
    },
    maxValue() {
        this.scheduleRecreate();
      
    },

  },
};
</script>
