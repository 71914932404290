<template>
  <div>
    <avatar :size="30" :id="randonId" :username="user.name" :src="$avatarUrl+user.imageSrc"></avatar>
    <b-tooltip :target="randonId">
      <div class="user-tooltip">
        <div class="user-tooltip-avatar">
          <avatar :id="'usersymbol'+user.username" :username="user.name" :src="$avatarUrl+user.imageSrc"></avatar>
        </div>
        <div>
          <div class="user-tooltip-name">{{user.name}}</div>
          <b-badge variant="success">@{{user.username}}</b-badge>
        </div>
      </div>
    </b-tooltip>
  </div>
</template>

<script>
import avatar from "./Avatar";
export default {
  name:'user-symbol',
  data(){
      return{
          randonId : undefined
      }
  },
  props: {
    user: { type: Object, required: true }
  },
  components: {
    avatar,
  },
  beforeMount(){
      this.randonId = this.user.username+Math.floor((0 - 1000) * Math.random());
  }
};
</script>

<style lang="scss" scoped>

.user-tooltip{
    padding: 5px;
    display: flex;
    .user-tooltip-avatar{
        margin-right: 10px;

    }
    .user-tooltip-name{
        color: white;
        //white-space:nowrap;
    }

}

</style>