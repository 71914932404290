/* eslint-disable no-unused-vars */
import ImageCanvas from "ol/source/ImageCanvas";
import { assign } from "ol/obj.js";
import Color from 'color'
import {colorToPalett} from "../imageUtils"


function posTransform(extent, size, pos) {
  let pp = [0, 0];
  pp[0] = ((pos[0] - extent[0]) / (extent[2] - extent[0])) * size[0];
  pp[1] = ((pos[1] - extent[3]) / (extent[1] - extent[3])) * size[1];
  return pp;
}

var Property = {
  GRADIENT: "gradient",
};

var DEFAULT_GRADIENT = [
    "#afe0",
    "#0ff6",
    "#0bf8",
    "#00fa",
    "#008c",
    "#103f",
];




var IdwSource = /** @class */ (function (ImageCanvas) {
  function IdwSource(opt_options) {

    var options = opt_options ? opt_options : /** @type {Options} */ ({});
    var baseOptions = assign({}, options);

    ImageCanvas.call(this, baseOptions) || this;

    this.canvas = document.createElement("canvas");
    this.canvas.willReadFrequently = true;
    this.imageCanvas_ = document.createElement("canvas");

    this.gradient    = baseOptions.gradient?  baseOptions.gradient  : DEFAULT_GRADIENT;
    this.alphaColor  = baseOptions.alphaColor?baseOptions.alphaColor: undefined;
    this.smoothing   = baseOptions.smoothing?baseOptions.smoothing: false;


    
    this.recalcule = true

    if(baseOptions.minValue!== undefined){ this.minValue=baseOptions.minValue  }
    if(baseOptions.maxValue!== undefined){ this.maxValue=baseOptions.maxValue  }
     
  
    if (baseOptions.area) {
      this.geometricArea_ = baseOptions.area;
      //this.extent = getExtentArea(this.geometricArea_);
    }


    this.extent = baseOptions.extent 

    this.colorPalette = baseOptions.colorPalette


    this.img = new Image;
    this.img.crossOrigin = "anonymous"
    this.img.onload = () => {
        //this.drawImage()
    };
  
    this.img.src = baseOptions.src;



    // cria array de cores RGBA
    this.cols = [];
    for (let i = 0; i < this.gradient.length; i++) {
      this.cols[i] = Color(this.gradient[i]).array()
      if(this.cols[i][3]===undefined){
        this.cols[i][3] = 1
      }
      this.cols[i][3] = (this.cols[i][3]*255)>>0
    }


    /**
     *
     * @param {*} extent
     * @param {*} resolution
     * @param {*} pixelRatio
     * @param {*} size
     * @param {*} projection
     */
    this.canvasFunction_ = function (
      extent,
      resolution,
      pixelRatio,
      size,
      projection
    ) {
      
      this.canvas.width = size[0];
      this.canvas.height = size[1];

      let width = size[0] >> 0;
      let height = size[1] >> 0;

      let pp = posTransform(extent, size, [this.extent[0],this.extent[1]]);
      let pp2 = posTransform(extent, size, [this.extent[2],this.extent[3]]);
      let xoffset =  pp[0]
      let yoffset =  pp[1]
      let img_sizex = pp2[0]-pp[0]
      let img_sizey = pp2[1]-pp[1]


      this.canvas.width = width;
      this.canvas.height = height;
      let ctx = this.canvas.getContext("2d");
      ctx.save();
      ctx.fillStyle = "transparent";
      ctx.fillRect(0, 0, width, height);



      // cria o contorno da área e define os pontos máximos e mínimos  --
      let geometricArea = [];
      if (this.geometricArea_) {
        this.geometricArea_.forEach((e) => {
          let v = [];
          e.forEach((e2) => {
            v.push(posTransform(extent, size, e2.slice(0, 2)));
          });
          geometricArea.push(v);
        });
      
        // cria a máscara de contorno na área ---------------------------
        ctx.beginPath();
        geometricArea.forEach((e) => {
          let lastPos = undefined;
          e.forEach((pos) => {
            if (lastPos) {
              // ctx.moveTo(lastPos[0],lastPos[1]);
              ctx.lineTo(pos[0], pos[1]);
            } else {
              ctx.moveTo(pos[0], pos[1]);
            }
            lastPos = pos;
          });
        });
        ctx.closePath();
        ctx.clip();
      }
 
      //--------------------------------------------------------------
      ctx.globalAlpha = this.alphaColor;
      
      ctx.imageSmoothingEnabled = this.smoothing;  
      ctx.drawImage(this.img , xoffset, yoffset,img_sizex,img_sizey);

      const imageData = ctx.getImageData(0, 0,  this.canvas.width, this.canvas.height);
      colorToPalett(imageData,this.minValue,this.maxValue,this.colorPalette)
      
      ctx.putImageData(imageData,0,0);
      ctx.restore();
      
      return this.canvas;
    };
  }

  if (ImageCanvas) IdwSource.__proto__ = ImageCanvas;
  IdwSource.prototype = Object.create(ImageCanvas && ImageCanvas.prototype);
  IdwSource.prototype.constructor = IdwSource;

  return IdwSource;
})(ImageCanvas);

export default IdwSource;
