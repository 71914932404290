<template>
  <div>

    <div class="widget-box-header">
      <span class="name">{{ sensor.name }}</span>
      <span class="time">
        <font-awesome-icon icon="clock" /> {{ $filters.secondsToStr(elapsedTime) }}
      </span>
    </div>

  
    <div class="widget-box-value" style="min-width: 200px;">
      <div>
        <span class="label">pressão:</span>
        <span class="value">{{ $filters.round(pressure, 1) }} <small>{{ pressureUnit }}</small></span>
      </div>
      <div class="clearfix"></div>
      <div>
        <span class="label">posição:</span>
        <span class="value">{{ $filters.round(angle, 1) }} <small>°</small></span>
      </div>
      <div class="clearfix"></div>
      <div>
        <span class="label">percent:</span>
        <span class="value">{{ $filters.round(percent, 0) }} <small>%</small></span>
      </div>

      <div v-if="isOnline === false" class="pivotInfo-overlay">
        <font-awesome-icon icon="clock" />
        {{ $filters.secondsToStr(elapsedTime) }} Offline!
      </div>
    </div>
  </div>
</template>

<script>

import pivot from "./pivot";

export default {
  extends: pivot,

};
</script>

<style lang="scss" scoped>
</style>
