<template>
  <div class="irrig-schedule">
    <div v-for="(s, index) in schedule" v-bind:key="s.date" class="irrig-div"
      v-bind:class="{ 'firstDayBox': index == 0 }">
      <div v-if="s.excess > 1" class="warning" :title="'excesso de ' + $filters.round(s.excess, 1) + 'mm'">
        <font-awesome-icon icon="exclamation-triangle" />
      </div>

      <div v-bind:class="{ 'irrigation': s.irrigSchedule != undefined, 'edit-irrigation': $can('edit', 'Irrigation') }"
        @click="showEdit(s)">
        <div v-if="index == 0" class="badge badge-info">{{ $t("today") }}</div>
        <div v-else>{{ $filters.weekFormat(s.date) }}</div>

        <div class="day">{{ $filters.dayFormat(s.date) }}</div>
        <dayBox :day="s" />
        <!-- <div title="Irrigação total necessária" class="deficit">
              <div class="deficit-itn"> ITN: </div>
              <div class="deficit-itn-value"> {{ $filters.round(s.irrig.itn, 1) }} mm </div>
            </div> -->
        

        <div class="irrig-info" v-if="s.irrigSchedule != undefined">
          <div class="irrig-info-equipment">
            <div class="label">{{ $filters.round(s.irrigSchedule.water, 1) }}<small>mm</small></div>
            <div class="label" v-if="s.irrigSchedule.percent != undefined">{{ $filters.round(s.irrigSchedule.percent,
              0) }}<small>%</small></div>
          </div>

          <div v-if="s.irrigSchedule.realized == undefined">
            <div class="hour">{{ $filters.localeTime(s.irrigSchedule.startDateTime) }} ~
              {{ $filters.localeTime(s.irrigSchedule.endDateTime) }}
            </div>
            <div class="irrigation-mark" :title="s.irrigSchedule.comment">
              <span> Irrigar </span>
              <font-awesome-icon v-if="!!s.irrigSchedule.comment" icon="clipboard" />
            </div>
          </div>
          <div v-else>
            <div class="label-realized">
              <!-- <font-awesome-icon icon="check-circle" /> -->
              <font-awesome-icon icon="arrow-right" />
              {{ $filters.round(s.irrigSchedule.realized, 1) }}<small>mm</small>
            </div>
            <div class="irrigation-mark realized" :title="s.irrigSchedule.comment">
              <font-awesome-icon icon="check-circle" />
              <span> Irrigado!</span>
              <!-- <font-awesome-icon v-if="!!s.irrigSchedule.comment" icon="clipboard" /> -->
            </div>
          </div>
        </div>

        <div v-else-if="s.realized" >
          <div>
            <font-awesome-icon icon="check-circle" />
            irrigado 
          </div>
          <div>
            {{s.realized}} 
            <small>mm</small>
          </div>
        </div>



      </div>
    </div>
    <b-modal ref="edit-modal" title="Editar Irrigação">

      <validation-observer ref="formObserver">

        <laminaInput v-model="irrigationForm.water" :autofocus="true" :field="field" @irrig-hours="irrigHours = $event" />

        <b-row>
          <b-form-group label="Início" class="col-6">
            <b-input-group size="sm" :prepend="$filters.localeDate(irrigationForm.date)">
              <b-input type="time" v-model="irrigationForm.startTime"></b-input>
            </b-input-group>
          </b-form-group>

          <b-form-group label="Fim" class="col-6">
            <b-input-group size="sm" :prepend="$filters.localeDate(endTime)">
              <b-input type="time" disabled :value="$filters.localeTime(endTime)"></b-input>
            </b-input-group>
          </b-form-group>
          <!-- <input-time class="col-6" label="Início" v-model="irrigationForm.startTime" /> -->
          <!-- <input-text class="col-6" label="Fim" v-model="endTime" disabled /> -->
        </b-row>

        <input-area label="Comentário" v-model="irrigationForm.comment" />
        <!-- <input-text label="direção" v-model="irrigationForm.direction" /> -->


      </validation-observer>

      <template #modal-footer="{ cancel }">
        <b-button v-if="removeButton" @click="removeIrrig()" variant="danger">remover</b-button>
        <b-button @click="cancel()">Cancelar</b-button>
        <b-button @click="saveIrrig()" variant="primary">Salvar</b-button>
      </template>

    </b-modal>
  </div>



  <!-- 
    <div>
      <span class="badge m-2" :style="calcLegendFill(1)">Umidade ok</span>
      <span class="badge m-2" :style="calcLegendFill(0.80)">Irrigação necessária</span>
      <span class="badge m-2" :style="calcLegendFill(0)">Extresse hídirco</span>
    </div>-->
</template>

<script>
import { DateTime } from 'luxon';
import dayBox from "./scheduleDay";
import laminaInput from "./laminaInput.vue"
export default {


  data() {
    return {
      irrigHours: 0,
      irrigationForm: {},
      removeButton: false
    }
  },



  props: {
    field: { type: Object },
  },

  computed: {
    schedule() {
      return this.field.status.schedule
    },

    endTime() {
      return DateTime.fromISO(this.irrigationForm.date + "T" + this.irrigationForm.startTime).plus({ hours: this.irrigHours });
    }
  },

  methods: {

    saveIrrig() {

      this.$refs.formObserver.validate().then(result => {
        if (!result) {
          return;
        }

        this.$http.post("irrig/equipment/field/" + this.field.id + "/irrigation-schedule", this.irrigationForm).then(
          () => {
            this.$refs['edit-modal'].hide();
          },
          error => {
            this.$refs.formObserver.setErrors(this.$validServer(error))
            this.$bvModal.msgBoxOk(
              "Verifique se todos os dados estão corretos",
              {
                title: "Erro!"
              }
            );
          }
        );

      });
    },
    removeIrrig() {
      this.$http.delete("irrig/equipment/field/" + this.field.id + "/irrigation-schedule/" + this.irrigationForm.date).then(
       () => {
          this.$refs['edit-modal'].hide();
        },
        error => {
          this.$refs.formObserver.setErrors(this.$validServer(error))
          this.$bvModal.msgBoxOk(
            "Verifique se todos os dados estão corretos",
            {
              title: "Erro!"
            }
          );
        }
      );
    },

    showEdit(day) {

      if (this.$can('edit', 'Irrigation') == false) {
        return
      }

      let values = day.irrigSchedule

      if (values == undefined) {
        this.irrigationForm = {
          date: day.date,
          water: this.$filters.round(day.irrig.itn, 1),
          startTime: "21:00",
          direction: "horário",
          comment: ""
        }
        this.removeButton = false
      } else {
        this.irrigationForm = {
          date: day.date,
          water: values.water,
          startTime: values.startTime,
          direction: values.direction,
          comment: values.comment
        }
        this.removeButton = true
      }



      this.$refs['edit-modal'].show()
    },



    // calcLegendFill(c) {
    //   return {
    //     //backgroundColor: calcFiledColor(c),
    //     color: "white"
    //   };
    // }
  },
  components: {
    dayBox,
    laminaInput
  },
  i18n: {
    messages: {
      en: { today: "Today" },
      "pt-br": { today: "Hoje" }
    }
  }
};
</script>



<style lang="scss" scoped>
.irrig-schedule {

  user-select: none;
  display: flex;
  margin-right: 0px;
  margin-left: 0px;
  max-width: 675px;
  width: 100%;
  overflow-x: auto;

  .firstDayBox {
    padding-right: 20px;
    border-right: 4px solid #7695ab;
    margin-right: 15px !important;

    &:after {
      content: "";
      position: absolute;
      left: 100%;
      top: 50px;
      border-bottom: 25px solid transparent;
      border-top: 25px solid transparent;
      border-left: 15px solid #7695ab;
    }
  }


  .irrig-div {

    position: relative;
    padding: 0 0.1em;
    color: #323e4e;
    text-align: center;
    margin: 0 2px 5px;
    min-width: 90px;

    .edit-irrigation {
      cursor: pointer;
    }

    .warning {
      position: absolute;
      top: -5px;
      left: 0px;
      font-size: 20px;
      color: orange;
      z-index: 2;
    }

    .day {
      font-size: 24px;
      line-height: 24px;
    }

    .irrigation {
      // border: 1px solid #3c4d5a;
      background-color: #e7edf1;
      border-radius: 10px;
      position: relative;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);

      .irrig-info {


        .irrig-info-equipment {
          padding: 5px 5px 2px;
          display: flex;
          justify-content: space-between;
          font-size: 16px;
          text-align: center;


          .label {
            font-size: 14px;
            line-height: 14px;
            font-weight: bold;
          }

        }

        .hour {
          line-height: 12px;
          font-size: 12px;
          text-align: center;
        }

        .label-realized {
          color:var(--success);;
          font-size: 14px;
          line-height: 14px;
          font-weight: bold;
        }

        small {
          font-size: 10px;
        }

        .irrigation-mark {
          font-size: 12px;
          color: #fff;
          background-color: #3c4d5a;
          margin-top: 5px;
          border-radius: 0 0 10px 10px;
        }

        .realized {
          background-color: var(--success);
        }
      }
    }
  }

}
</style>