<template>
  <b-modal no-fade hide-footer scrollable ref="details-modal" @hidden="close" size="max-xl">
    <template v-slot:modal-title>
      <span>
        <b>{{ station.name }}</b>
      </span>
    </template>
    <b-row v-if="station != undefined">
      <b-col lg="4" class="station-info">
        <b-card no-body class="sticky-top">
          <b-row no-gutters>
            <b-col sm="6" lg="12">
              <div class="map-div">
                <simple-map>
                  <stationMap :station="station" />
                </simple-map>
              </div>
            </b-col>
            <b-col sm="6" lg="12" class="p-2">

              <ul class="station-info-group">
                <li>
                  <b>Elevação</b>
                  <span>{{ $filters.round(station.location.ele, 2) }} m</span>
                </li>
                <li>
                  <b>Coordenadas:</b>
                  <span>{{ station.location.lat }} , {{ station.location.lon }}</span>
                </li>
                <li>
                  <b>Origem de dados</b>
                  <span>{{ station.dataOrigin }}</span>
                </li>
                <li>
                  <b>Código</b>
                  <span> <small>{{ station.code }}</small></span>
                </li>
                <li>
                  <b>Ultima Sincronização</b>
                  <span>{{ $filters.localeDate(station.lastDate) }}</span>
                </li>
              </ul>


              <div>
                <i>{{ station.description }}</i>
              </div>

              <div class="mt-5">

                <b-button block size="sm" v-if="$can('edit', $subject('WeatherStation',station))" 
                  variant="primary" :to="{ name: 'stationEdit', params: { id: station.id } }">
                  <font-awesome-icon icon="edit" /> Editar
                </b-button>

                <b-button block size="sm" v-if="$can('remove', $subject('WeatherStation',station))" 
                  variant="danger" @click="$emit('removestation', station.id)">
                  <font-awesome-icon icon="trash" /> Excluir
                </b-button>
              </div>

            </b-col>
          </b-row>
        </b-card>
      </b-col>

      <b-col lg="8">
          <b-tabs class="tab-card">
            <b-tab title="Histórico" active>
              <div class="d-flex flex-wrap mt-2">
                <div>
                  <h5>Histórico de climas</h5>
                </div>
                <div class="ml-auto">
                  <data-range v-model="dateRange" />
                </div>
              </div>

             <stationData ref="stationData" :station="station" :dateRange="dateRange"></stationData>
            </b-tab>

            <b-tab title="24h" v-if="station.dataOrigin=='kalliandra'" :lazy="summaryLazy" @click="summaryLazy=false">
                <stationSummary :stationId="station.code"/>
            </b-tab>

            <b-tab title="Importar" v-if="$can('edit', $subject('WeatherStation',station))">
              <station-send-file :stationId="stationId" />
            </b-tab>

            <b-tab title="Funções" v-if="$can('edit', $subject('WeatherStation',station))">
              <b-card header="Reprocessar">
                <p> Reprocessar os climas a partir dos sensores de origem. </p>
                <div class="bg-light p-2 mb-4">
                  <ul>
                    <li> Consulta a base de dados de sensores;</li>
                    <li> Calcula novos climas;</li>
                    <li> Atualiza os climas da estação a partir dos novos dados;</li>
                  </ul>
                </div>
                <b-button variant="warning" size="sm" :disabled="buttonReloadDisabled"
                  @click="reloadWethersOrigin">
                  <font-awesome-icon icon="exclamation-triangle" />
                  Reprocessar
                </b-button>
              </b-card>
            </b-tab>

          </b-tabs>
        
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import stationMap from "./stationMap";
import stationSendFile from "./stationSendFile";
import stationData from "./stationData.vue";
import stationSummary from "@sensorcomponents/sensStation/stationSummary"

export default {
  data() {
    return {
      summaryLazy : true,
      station: undefined,
      dateRange: null,
      buttonReloadDisabled: false,
    };
  },
  components: {
    stationMap,
    stationSendFile,
    stationData,
    stationSummary
  },
  props: {
    stationId: { type: String, required: false },
    stationList: Array,
  },

  mounted() {
    if (this.stationId !== undefined) {
      this.station = this.stationList.find((ele) => this.stationId === ele.id);
    }
    this.$refs["details-modal"].show();
    let today = new Date(Date.now());
    let lastMounth = new Date();
    lastMounth.setMonth(today.getMonth() - 1);
    this.dateRange = [lastMounth, today];
  },


  methods: {
    /**
     * recarrega os dados de clima
     */


    /**
     *Força o carregamento dos dados do clima no sensor gerador de origem
     */
    reloadWethersOrigin() {
      
      this.$bvModal
        .msgBoxConfirm("Deseja realmente refazer os dados da estação", {
          title: "Por favor confirme",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Sim",
          cancelTitle: "Não",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value === true) {
            this.buttonReloadDisabled = true;
            this.$refs["stationData"].setLoading()
            this.$http
              .get("irrig/station/" + this.stationId + "/weather/reloadOptions")
              .then(
                () => {
                  this.$refs["stationData"].loadWeathers();
                  this.buttonReloadDisabled = false;
                },
                () => {
                  this.$bvModal.msgBoxOk(
                    "Não foi possível executar essa operação.",
                    {
                      title: "Erro!",
                    }
                  );
                  this.buttonReloadDisabled = false;
                }
              );
          }
        });
    },
   

    close() {
      this.$router.push({ name: "stationList" });
    },
  },
};
</script>

<style lang="scss" scoped>
.weather-chart {
  margin: 1rem 0px;
}

.weather-table {
  margin: 1rem 0px;
}

.station-info {
  .station-info-group {
    padding-left: 0;
    border-left: 0;
    border-right: 0;
    border-radius: 0;
    padding-left: 0;
    padding-right: 0;

    li {
      position: relative;
      display: block;
      padding: 5px 10px;
      margin-bottom: -1px;
      border-bottom: 1px solid #ddd;

      span {
        float: right !important;
        color: #3c8dbc;
      }
    }
  }
}
</style>
