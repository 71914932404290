<template>
  <div class="sensor-container">
    <div class="d-flex pt-3 px-sm-2 border-bottom">
      <slot></slot>
    </div>
    <!-- <div class="ml-auto"> -->
    <!-- <b-button-group size="sm">  -->
    <!-- <b-button @click="page='decision'" variant="info">Decisão</b-button>
            <b-button @click="page='schedule'" variant="primary">Previsão</b-button> -->
    <!-- </b-button-group>
      </div>
    </div> -->

    <b-container fluid class="pt-3 pb-2">
      <!-- <b-row> -->
      <!-- <div
          class="col-12"
          v-bind:key="sensor.id"
          v-for="sensor in stationsList"
        >
          <stationWidget v-bind:sensor="sensor" />
        </div>

        <h5 v-if="waterList.length > 0" class="sensor-list-label">Níveis</h5>
        <div
          class="col-sm-6 col-md-6 col-lg-4 col-xl-3"
          v-bind:key="sensor.id"
          v-for="sensor in waterList"
        >
          <waterSensWidget v-bind:sensor="sensor" />
        </div>

        <h5 v-if="wellList.length > 0" class="sensor-list-label">Poços</h5>
        <div
          class="col-sm-6 col-md-6 col-lg-4 col-xl-3"
          v-bind:key="sensor.id"
          v-for="sensor in wellList"
        >
          <wellSensWidget v-bind:sensor="sensor" />
        </div>

        <h5 v-if="hydrometerList.length > 0" class="sensor-list-label">
          Hidrometros
        </h5>
        <div
          class="col-sm-6 col-md-6 col-lg-4 col-xl-3"
          v-bind:key="sensor.id"
          v-for="sensor in hydrometerList"
        >
          <hydroSensWidget v-bind:sensor="sensor" />
        </div>

        <h5 v-if="pluvioList.length > 0" class="sensor-list-label">
          Pluviômetros
        </h5>
        <div
          class="col-sm-6 col-md-6 col-lg-4 col-xl-3"
          v-bind:key="sensor.id"
          v-for="sensor in pluvioList"
        >
          <pluvioSensWidget v-bind:sensor="sensor" />
        </div>

        <h5 v-if="pivotsList.length > 0" class="sensor-list-label">Pivôs</h5>
        <div
          class="col-sm-6 col-md-6  col-lg-4 col-xl-3"
          v-bind:key="sensor.id"
          v-for="sensor in pivotsList"
        >
          <pivotSensWidget v-bind:sensor="sensor" />
        </div>

        <h5 v-if="soilsList.length > 0" class="sensor-list-label">Solos</h5>
        <div
          class="col-sm-6 col-md-6  col-lg-4 col-xl-3"
          v-bind:key="sensor.id"
          v-for="sensor in soilsList"
        >
          <soilSensWidget v-bind:sensor="sensor" />
        </div>
      </b-row>
       -->

      <!-- <div v-for="(value,name) in groups" :key="name">
        {{ name }}
      </div> -->

      <fullscreen v-model="fullscreen" :teleport="false" :page-only="false" fullscreen-class="fullscreen-sensors">

        <div class="float-right">
          <button
            class="map-fullscreen map-button"
            type="button"
            @click="fullscreen = !fullscreen"
            title="fullScreen"
          >
            <font-awesome-icon v-if="!fullscreen" icon="expand" />
            <font-awesome-icon v-else icon="compress" />
          </button>
        </div>

        <div v-for="group in groups" :key="group.name">
          <h5 v-if="group.label" class="sensor-list-label">
            {{ group.label }}
          </h5>
          <b-row>
            <div
              class="col-sm-6 col-md-6 col-lg-4 col-xl-3 col-xxl-2"
              v-for="sensor in group.sensors"
              v-bind:key="sensor.id"
            >
              <component :is="'sensor-' + sensor.type" v-bind:sensor="sensor" />
            </div>
          </b-row>
          <hr />
        </div>
      </fullscreen>
    </b-container>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

import pivotSensWidget from "@sensorcomponents/sensPivot/pivotSensWidget.vue";
import soilSensWidget from "@sensorcomponents/sensSoil/soilSensWidget.vue";
import stationWidget from "@sensorcomponents/sensStation/stationWidget.vue";
import pluvioSensWidget from "@sensorcomponents/sensPluvio/pluvioSensWidget.vue";
import waterSensWidget from "@sensorcomponents/sensWater/waterSensWidget.vue";
import wellSensWidget from "@sensorcomponents/sensWell/wellSensWidget.vue";
import hydroSensWidget from "@sensorcomponents/sensHydro/hydroSensWidget.vue";

const getParameterByName = function(parameter, str) {
  // parameter = parameter.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[@]" + parameter + '=(([0-9]*)"([^"]*))"');
  let results = regex.exec(str);

  let order = 100;
  let name = "sensores";
  let label = "";

  // console.log(results);

  if (results) {
    if (results[1]) name = results[1];
    if (results[2]) order = Number(results[2]);
    if (results[3]) label = results[3];
  }

  return { name: name, label: label, order: order };
};

export default {
  data() {
    return {
      fullscreen: false,
    };
  },
  computed: {
    groups() {
      const groups = this.sensors.reduce((groups, item) => {
        const groupParameters = getParameterByName("group", item.description);
        let groupName = groupParameters.name;
        let label = groupParameters.label;
        let order = groupParameters.order;

        const group = groups[groupName] || {
          sensors: [],
          label: label,
          order: order,
        };
        group.sensors.push(item);
        groups[groupName] = group;
        return groups;
      }, {});

      return Object.values(groups).sort(function(a, b) {
        return a.order - b.order;
      });
    },

    ...mapState({
      sensors: (state) => state.sensors.sensors,
    }),

    ...mapGetters({
      stationsList: "sensors/stationsList",
      pivotsList: "sensors/pivotsList",
      soilsList: "sensors/soilsList",
      pluvioList: "sensors/pluvioList",
      waterList: "sensors/waterList",
      wellList: "sensors/wellList",
      hydrometerList: "sensors/hydrometerList",
    }),
    ...mapState({
      allSensors: (state) => state.sensors.sensors,
    }),
  },

  components: {
    pivotSensWidget,
    soilSensWidget,
    waterSensWidget,
    stationWidget,
    pluvioSensWidget,
    wellSensWidget,
    hydroSensWidget,
    "sensor-pivot": pivotSensWidget,
    "sensor-station": stationWidget,
    "sensor-soil": soilSensWidget,
    "sensor-pluviometer": pluvioSensWidget,
    "sensor-waterLevel": waterSensWidget,
    "sensor-well": wellSensWidget,
    "sensor-hydrometer": hydroSensWidget,
  },
};
</script>

<style lang="scss" scoped>
.sensor-container {
  border-top: solid 1px #000;
  //background-color: #f1f7f6;
  // background-image: linear-gradient(150deg, #f1f7f6, #cad4db);

  width: 100%;
}

.sensor-list-label {
  // border-bottom: solid 1px #aaa;
  width: 100%;
  // margin-top: 2rem;
}

.fullscreen-sensors {
  background-color: rgb(236, 236, 236);
  padding: 2em;
  overflow-y: scroll;
}
</style>
