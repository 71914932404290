<template>
  <div class="d-flex">
    <ValidationProvider
      slim
      vid="startDate"
      name="Leitura anterior"
      :rules="{required: true,date_before:endDate}"
      v-slot="v"
    >
      <b-form-group label="Leitura Anterior" class="w-50">
        <b-form-input
          name="startDate"
          v-model="startDate"
          type="date"
          :state="$validateState(v)"
        ></b-form-input>
        <span class="invalid-input">{{ v.errors[0] }}</span>
      </b-form-group>
    </ValidationProvider>

    <ValidationProvider
      slim
      vid="startDate"
      name="Ultima leitura"
     :rules="{required: true,date_between:[startDate,Date.now()]}"
      v-slot="v"
    >
      <b-form-group label="Ultima leitura" class="pl-1 w-50">
        <b-form-input
          name="startDate"
          v-model="endDate"
          type="date"
          :state="$validateState(v)"
        ></b-form-input>
        <span class="invalid-input">{{ v.errors[0] }}</span>
      </b-form-group>
    </ValidationProvider>
  </div>
</template>

<script>
export default {
  data() {
    return {
      startDate: "",
      endDate: "",
    };
  },
  props: {
    value: Object,
  },

  mounted() {
    if (this.value) {
      this.startDate = this.value.start;
      this.endDate = this.value.end;
    }
  },
  
  methods:{
      updateDate(){
           this.$emit("input",{start:this.startDate, end:this.endDate})
      }

  },

  watch:{
    startDate(){
        this.updateDate()
    },
    endDate(){
        this.updateDate()
    }

  }
};
</script>

<style></style>
