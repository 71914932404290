<template>
  <list-page
    :loadStatus="loadStatus"
    :numRows="numRows"
    title="Solos"
    subTitle="Lista de tipos Solos para manejo de irrigação."
  
    @load-retry="loadData()"
  >
  <!-- :backgroundImage="require('./assets/background.jpg')" -->
    <template v-slot:button>
      <b-button
        v-if="$can('create', 'Soil')"
        class="shadow-sm"
        pill
        @click="newSoil"
        variant="success"
        >+ novo</b-button
      >
    </template>

    <template v-slot:list="props">
      <soilTableList
        :per-page="props.perPage"
        :current-page="props.currentPage"
        :filter="props.filter"
        :soilList="soilList"
        v-on:removesoil="removeSoil($event)"
        v-on:editsoil="editSoil($event)"
        v-on:onnumrows="onNumRows($event)"
      />

      <router-view
        :soilList="soilList"
        v-on:update:soil="updateList($event)"
      ></router-view>
    </template>
  </list-page>
</template>

<script>
//usado apenas para testes. comente esta linha caso não esteja testando
//import dataTeste from "./testes/soils.json";

import soilTableList from "./soilTableList";

export default {
  data() {
    return {
      soilList: [],
      loadStatus: "loading",
      numRows: 1,
    };
  },
  props: {
    editable: { type: Boolean, default: true },
  },
  beforeMount() {
    this.loadData();
  },
  components: {
    soilTableList,
  },

  methods: {
    onNumRows(numrows) {
      this.numRows = numrows;
    },

    loadData() {
      this.loadStatus = "loading";
      this.$http.get("irrig/soil").then(
        (data) => {
          this.soilList = data;
          this.loadStatus = "loaded";
          this.numRows = this.soilList.length;
        },
        (error) => {
          console.error("soil loading error:", error);
          this.loadStatus = "error";
        }
      );
    },

    //atualiza os valores do solo da lista a partir de um novo solo
    updateList(newSoil) {
      const index = this.soilList.findIndex((e) => e.id === newSoil.id);
      if (index === -1) {
        this.soilList.push(newSoil);
        this.numRows = this.soilList.length;
      } else {
        let dataObj = this.soilList[index];
        Object.entries(newSoil).forEach(([key, value]) => {
          dataObj[key] = value;
        });
      }
    },

    editSoil(id) {
      this.$router.push({ name: "soilEdit", params: { id: id } });
    },

    removeSoil(id) {
      this.$bvModal
        .msgBoxConfirm(
          "Deseja realmente apagar este registro permanentemente?",
          {
            title: "Por favor confirme",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "Sim",
            cancelTitle: "Não",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value === true) {
            this.$http.delete("irrig/soil/" + id).then(
              () => {
                let index = this.soilList.findIndex((e) => e.id === id);
                this.soilList.splice(index, 1);
                this.numRows = this.soilList.length;
              },
              () => {
                //API error
              }
            );
          }
        })
        .catch(() => {
          // An error occurred
        });
    },

    newSoil() {
      this.$router.push({ name: "soilNew" });
    },
  },
};
</script>

