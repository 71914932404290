<template>
<b-table-simple responsive striped small class="report-table">

    <b-thead class="text-center">
      <b-tr>
        <b-th class="line-right" colspan="5"></b-th>
        <b-th v-if="showAccumulated" class="td-header-group line-right" colspan="5">Acumulado</b-th>
        <b-th v-if="showMonth" class="td-header-group" colspan="7">Mês</b-th>
      </b-tr>

     <b-tr>
        <b-th>Equip.</b-th>
        <b-th>Parcela</b-th>
        <b-th colspan="2">Cultura</b-th>
        <b-th class="line-right"> Área<br> <small>(ha)</small> </b-th>


        <template v-if="showAccumulated">
          <b-th>Dias</b-th>
          <b-th>Irrigação<br> <small>(mm)</small></b-th>
          <b-th>Chuva<br> <small>(mm)</small></b-th>
          <b-th>ETc<br> <small>(mm)</small></b-th>
          <b-th class="line-right">Red. ETpc<br> <small>(%)</small></b-th>
        </template>

        <template v-if="showMonth">
          <b-th>Irrigação<br> <small>(mm)</small></b-th>
          <b-th>Chuva<br> <small>(mm)</small></b-th>
          <!-- <b-th>ETC<br> <small>(mm)</small></b-th> -->
          <b-th>Red. ETpc<br> <small>(%)</small></b-th>
          <b-th>Energia <br> <small>(R$/mm/ha)</small></b-th>
          <b-th>Energia <br> <small>(R$/ha)</small></b-th>
          <b-th>Energia <br> <small>(kWh)</small></b-th>
          <b-th>Custo Total <br> <small>(R$)</small></b-th>
        </template>
       </b-tr>
    </b-thead>

     <b-tbody >
       
        <tr class="text-center" v-for="(field,index) in fields" :key="index">
            <td> {{field.equipmentName}}</td>
            <td> {{field.fieldName}}</td>
            <td>
                <img v-b-tooltip.hover :title="field.culture.name" class="culture-img" :src="require('@irrigcomponents/culture/assets/48/'+field.culture.icon+'.png')" />
            </td>
            <td> {{field.culture.name}}</td>
            <td class="line-right"> {{ $filters.localeNumber((field.areaM2/10000),2)}}</td>

            <template v-if="showAccumulated">
              <td> {{field.accumulatedData.numDays}}</td>
              <td> {{$filters.localeNumber(field.accumulatedData.irrigation,2)}}</td>
              <td> {{$filters.localeNumber(field.accumulatedData.rain,2)}}</td>
              <td> {{$filters.localeNumber(field.accumulatedData.etc,2)}}</td>
              <td class="line-right"> {{$filters.localeNumber(field.accumulatedData.etcpReduction*100,2)}}</td>
            </template>

            <template v-if="showMonth">
              <td> {{$filters.localeNumber(field.intervalData.irrigation,2)}}</td>
              <td> {{$filters.localeNumber(field.intervalData.rain,2)}}</td>
              <td> {{$filters.localeNumber(field.intervalData.etcpReduction*100,2)}}</td>
              <td> {{$filters.localeNumber(field.intervalData.costPerMmPerHa,2)}}</td>
              <td> {{$filters.localeNumber(field.intervalData.costPerHa,2)}}</td>
              <td> {{$filters.localeNumber(field.intervalData.powerKWh,2)}}</td>
              <td> {{$filters.localeNumber(field.intervalData.totalCost,2)}}</td>
              </template>
        </tr>  

    </b-tbody>

    <b-tfoot class="text-center">
        <tr>
        <td class="text-left" colspan="4"> Total / Média<small>(Considerando as áreas das parcelas)</small></td>
        <td> {{$filters.localeNumber(value.totalAccumulated.areaHa,2)}} </td>

        <template v-if="showAccumulated">
          <td></td>
          <td> {{$filters.localeNumber(value.totalAccumulated.irrigation,2)}} </td>
          <td> {{$filters.localeNumber(value.totalAccumulated.rain,2)}} </td>
          <td> {{$filters.localeNumber(value.totalAccumulated.etc,2)}} </td>
          <td> {{$filters.localeNumber(value.totalAccumulated.etpcReduction*100,2)}} </td>
        </template>
        <template v-if="showMonth">
          <td> {{$filters.localeNumber(value.totalMonth.irrigation,2)}} </td>
          <td> {{$filters.localeNumber(value.totalMonth.rain,2)}} </td>
          <td> {{$filters.localeNumber(value.totalMonth.etpcReduction*100,2)}} </td>
          <td> {{$filters.localeNumber(value.totalMonth.costPerMmPerHa,2)}}</td>
          <td> {{$filters.localeNumber(value.totalMonth.costPerHa,2)}}</td>
          <td> {{$filters.localeNumber(value.totalMonth.powerKWh,2)}}</td>
          <td> {{$filters.localeNumber(value.totalMonth.totalCost,2)}}</td>
          </template>
        </tr>

    </b-tfoot>


    

</b-table-simple>
  
</template>

<script>
export default {
    props:{
        value:{type:Object, required:true},
        showAccumulated:{type:Boolean, default:true},
        showMonth:{type:Boolean, default:true}
    },

    computed:{
        fields(){
            return this.value.fields
        }
    }
}
</script>



<style lang="scss" scoped>
.report-table {
  .line-right {
    border-right: 2px solid #dee2e6;
  }

  .culture-img {
    height: 25px;
  }

  tr {
    font-size: 0.85em;
  }
  thead {
    th {
      vertical-align: top;
    }
    .td-header-group {
    //   border-bottom: 2px #005f8d solid;
      padding-bottom: 2px;
    }
  }

  tfoot {
    font-weight: bold;
  }
}
</style>