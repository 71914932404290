<template>
  <tr v-if="rain!== undefined">
    <td class="id-r-line field-label">
      <span> {{name}} </span>
    </td>
    <td class="td-input id-r-line ">
      <ValidationProvider :rules="{required: false,between:[0,300]}" v-slot="v">
        <b-input @update="weatherUpdate" v-model="rain.value" size="sm" type='number' min=0 max=300 :state="$validateState(v)" />
      </ValidationProvider>
    </td>
    <td></td>
  </tr>
</template>

<script>
export default {
  data() {
    return {
      rain: undefined,
    };
  },
  methods: {
    weatherUpdate() {
      this.$emit("input",JSON.parse(JSON.stringify(this.rain)));
      this.$emit("onchange");
    }
  },
  props: {
    value: Object,
    name:  String
  },
  mounted() {
    this.rain = Object.assign({}, this.value);  
  }
};
</script>

<style lang="scss" scoped>
.field-label {
  padding-left: 20px;
}

.td-input {
  width: 150px;
}
</style>