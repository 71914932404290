<template>
  <b-row>

     <!-- datastreamId-->
    <ValidationProvider
      slim
      vid="fullLevel"
      name="Sensor Id"
      :rules="{ required: true, between:[0,8]}"
      v-slot="v"
    >
      <b-form-group label="Sensor Id" class="col-sm-6">
        <b-input-group :state="$validateState(v)">
          <b-form-input
            name="sensor.datastreamId"
            v-model="editable.datastreamId"
            type="number"
            min="0"
            :state="$validateState(v)"
          ></b-form-input>
        </b-input-group>
        <span class="invalid-input">{{ v.errors[0] }}</span>
      </b-form-group>
    </ValidationProvider>


    <!-- fullLevel-->
    <ValidationProvider
      slim
      vid="fullLevel"
      name="Nível cheio"
      :rules="{ required: true, minValue: 0 }"
      v-slot="v"
    >
      <b-form-group label="Nível cheio" class="col-sm-6">
        <b-input-group :append=editable.unit :state="$validateState(v)">
          <b-form-input
            name="sensor.fullLevel"
            v-model="editable.fullLevel"
            type="number"
            min="0"
            :state="$validateState(v)"
          ></b-form-input>
        </b-input-group>
        <span class="invalid-input">{{ v.errors[0] }}</span>
      </b-form-group>
    </ValidationProvider>

    <!-- emptyLevel-->
    <ValidationProvider
      slim
      vid="emptyLevel"
      name="Nível vazio"
      :rules="{ required: true, between:[0,editable.fullLevel] }"
      v-slot="v"
    >
      <b-form-group label="Nível vazio" class="col-sm-6">
        <b-input-group :append=editable.unit :state="$validateState(v)">
          <b-form-input
            name="sensor.emptyLevel"
            v-model="editable.emptyLevel"
            type="number"
            min="0"
            :state="$validateState(v)"
          ></b-form-input>
        </b-input-group>
        <span class="invalid-input">{{ v.errors[0] }}</span>
      </b-form-group>
    </ValidationProvider>


    <!-- warningFull-->
    <ValidationProvider
      slim
      vid="warningFull"
      name="Alerta de nível cheio"
      :rules="{ required: true, between:[editable.emptyLevel,editable.fullLevel] }"
      v-slot="v"
    >
      <b-form-group label="Alerta de nível cheio" class="col-sm-6">
        <b-input-group :append=editable.unit :state="$validateState(v)">
          <b-form-input
            name="sensor.warningFull"
            v-model="editable.warningFull"
            type="number"
            min="0"
            :state="$validateState(v)"
          ></b-form-input>
        </b-input-group>
        <span class="invalid-input">{{ v.errors[0] }}</span>
      </b-form-group>
    </ValidationProvider>


     <!-- warningEmpty-->
    <ValidationProvider
      slim
      vid="warningEmpty"
      name="Alerta de nível vazio"
      :rules="{ required: true, between:[editable.emptyLevel,editable.warningFull] }"
      v-slot="v"
    >
      <b-form-group label="Alerta de nível vazio" class="col-sm-6">
        <b-input-group :append=editable.unit :state="$validateState(v)">
          <b-form-input
            name="sensor.warningEmpty"
            v-model="editable.warningEmpty"
            type="number"
            min="0"
            :state="$validateState(v)"
          ></b-form-input>
        </b-input-group>
        <span class="invalid-input">{{ v.errors[0] }}</span>
      </b-form-group>
    </ValidationProvider>



     <!-- predictionTimeInterval-->
    <ValidationProvider
      slim
      vid="predictionTimeInterval"
      name="predictionTimeInterval"
      :rules="{ required: true, between:[0,7200] }"
      v-slot="v"
    >
      <b-form-group label="predictionTimeInterval" class="col-sm-6">
        <b-input-group append="s" :state="$validateState(v)">
          <b-form-input
            name="sensor.predictionTimeInterval"
            v-model="editable.predictionTimeInterval"
            type="number"
            min="0"
            :state="$validateState(v)"
          ></b-form-input>
        </b-input-group>
        <span class="invalid-input">{{ v.errors[0] }}</span>
      </b-form-group>
    </ValidationProvider>

     <!-- formula -->
     <ValidationProvider
        slim
        vid="formula"
        name="formula"
        :rules="{ required: false, min: 2 }"
        v-slot="v"
      >
        <b-form-group label="Fórmula" class="col-sm-6">
          <b-form-input
            name="sensor.formula"
            v-model="editable.formula"
            type="text"
            placeholder="fn de x (Ex: x+100)"
            :state="$validateState(v)"
          ></b-form-input>
          <span class="invalid-input">{{ v.errors[0] }}</span>
        </b-form-group>
      </ValidationProvider>


      <!-- unidade  -->
    <ValidationProvider
      slim
      vid="unit"
      name="Unidade"
      :rules="{ required: true, min:1, max:10 }"
      v-slot="v"
    >
      <b-form-group label="Unidade" class="col-sm-6">
        <b-form-input
          name="sensor.unit"
          v-model="editable.unit"
          type="text"
          maxlength="10"
          placeholder="unidade"
          :state="$validateState(v)"
        ></b-form-input>
        <span class="invalid-input">{{ v.errors[0] }}</span>
      </b-form-group>
    </ValidationProvider>


  </b-row>
</template>

<script>
export default {
  data(){
    return{
      editable:undefined
    }
  },
  beforeMount(){
    this.editable = JSON.parse(JSON.stringify(this.value))
  },
  props: {
    value: { type: Object, required: true },
  },
  watch:{
    editable: {
      deep: true,
      handler(v) {
       this.$emit("input",v)
      },
    },
  },
};
</script>

<style></style>
