<template>
  <page-float-card title="Relatórios de Irrigação" subTitle="Relatórios Mensais">
    <div v-if="loadListStatus == 'loaded'">
      <div class="non-print" v-if="reports.length > 0">
        <div class="d-flex">
          <div>
            <b-button
              v-if="$can('create', 'Report')"
              pill
              variant="success"
              @click="editReport = {}"
              title="cadastrar relatório"
            >
              +novo
            </b-button>
          </div>
          <b-pagination
            class="ml-auto"
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="my-table"
          ></b-pagination>
        </div>
        <irrigationListTable :reports="reports" />
      </div>
      <div v-else class="text-center m-5">
        <div>
          <img
            style="width: 140px;"
            src="@/assets/business-report.svg"
            alt=""
          />
        </div>
        <div class="mt-4 mx-auto" style="max-width: 460px;">
          <h5>Gerar Relatórios de Irrigação</h5>
          <p>
            O relatório de irrigação é um compilado dos dados de todas as
            parcelas em um período e considera as contas de energia elétrica.
            Por isso, certifique-se que existam 
            <b-link :to="{ name: 'electricityPage' }">
              contas de energia elétrica
            </b-link>
            cadastradas para os meses que pretende gerar o relatório.
          </p>
          <b-button
            v-if="$can('create', 'Report')"
            pill
            variant="success"
            @click="editReport = {}"
          >
            Gerar Relatório
          </b-button>
        </div>
      </div>
    </div>
    <c-loading @retry="loadList()" :status="loadListStatus" v-else>
      <b-skeleton type="button"></b-skeleton>
      <b-skeleton-table
        :rows="5"
        :columns="9"
        :table-props="{ bordered: true, striped: false }"
      ></b-skeleton-table>
    </c-loading>
    <router-view @report-deleted="loadList()" @update-object="loadList()"></router-view>

    <irrigationReportCreate
      v-model="editReport"
      @update-object="objectCreated($event)"
    />
  </page-float-card>
</template>

<script>

import irrigationListTable from "./report/irrigationListTable";
import irrigationReportCreate from "./irrigationReportCreate";

export default {
  data() {
    return {
      currentPage: 1,
      rows: 0,
      perPage: 12,
      loadListStatus: "",
      reports: [],
      editReport: undefined,
    };
  },

  mounted() {
    this.loadList(1);
  },
  components: {
    irrigationListTable,
    irrigationReportCreate,
  },
  watch: {
    currentPage(v) {
      this.loadList(v);
    },
  },
  methods: {
    loadList(page = this.currentPage) {
      this.loadListStatus = "loading";
      let objectsListUrl =
        "/report/irrigation-report?page=" +
        (page - 1) +
        "&size=" +
        this.perPage;
      this.$http.get(objectsListUrl).then(
        (data) => {
          this.reports = data.content;
          this.rows = data.totalElements;
          this.loadListStatus = "loaded";
        },
        (error) => {
          console.error("loading List error", error);
          this.loadListStatus = "error";
        }
      );
    },

    objectCreated(report) {
      this.$router.push({
        name: "irrigationReportDetails",
        params: { month: report.referenceMonth },
      });
      this.loadList();
    },
  },
};
</script>
