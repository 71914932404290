<template>
  <b-row>
    <b-col lg="4" class="field-info">

      <b-card no-body class="sticky-top">



        <b-row no-gutters>
          <b-col class="mb-2" cols="12" sm="5" md="6" lg="12">
            <div v-if="field.cultureParameters">
              <cultureSVG width="100%" :height="180" :layers="field.soil.layers" :drawPlant="field.status.numDays>=0"
                :cultureParameters="field.cultureParameters" />
            </div>
          </b-col>
          <b-col cols="12" sm="7" md="6" lg="12">
            <img class="mr-2 ml-2" :src="require('@irrigcomponents/culture/assets/48/' +
              field.culture.icon +
              '.png')
              " />

            <span class="culture-name">{{ field.culture.name }}</span>

            <ul class="field-info-group">
              <li> {{ field.description }}</li>
              <li>
                <b>Início do manejo</b>
                <span>{{ $filters.localeDate(field.startDate) }}</span>
              </li>
              <li>
                <b>Área plantada</b>
                <span>{{ $filters.round((field.area / 10000), 2) }} ha</span>
              </li>
              <li>
                <b>Data do plantio</b>
                <span>{{ $filters.localeDate(field.datePlanting) }}</span>
              </li>
              <li v-if="field.cultureParameters">
                <b> Fase</b>
                <span>{{ field.cultureParameters.stageName }} ({{
                  field.status.numDays
                }}
                  dias)</span>
              </li>

              <template v-if="dataOk">
                <li>
                  <b>Lâmina disponível</b>
                  <span>{{ $filters.round(field.status.laa, 1) }} mm</span>
                </li>
                <li>
                  <b>Etc </b>
                  <span>{{ $filters.round(field.status.etc, 1) }} mm</span>
                </li>
                <li>
                  <b>ITN </b>
                  <span>{{ $filters.round(field.status.irrig.itn, 2) }} mm</span>
                </li>
                <li v-if="field.status.irrig.hour">
                  <b>Tempo recomendado</b>
                  <span>{{ $filters.decimalhourToTime(field.status.irrig.hour, "h ") }}</span>
                </li>
                <li v-if="field.status.irrig.percent">
                  <b>Percentimetro</b>
                  <span>{{ $filters.round(field.status.irrig.percent, 0) }} %</span>
                </li>
              </template>
            </ul>

            <div class="mt-3 p-2">

              <!--
              <b-button block size="sm" v-if="$can('edit', 'Field')" variant="primary"
                 @click="recalculeField" :disabled="recalculing">
                <font-awesome-icon icon="recycle" /> Recalcular
              </b-button>
              -->

              <b-button block size="sm" v-if="$can('edit', 'Field')" variant="primary"
                :to="{ name: 'fieldEdit', params: { id: field.id } }">
                <font-awesome-icon icon="edit" /> Editar
              </b-button>

              <b-button block size="sm" v-if="$can('create', 'Field')" variant="secondary"
              :to="{ name: 'fieldNew', params: {equipmentId:field.equipmentId, cloneId: field.id} }">
              <font-awesome-icon icon="copy" /> Copiar
              </b-button>

              <b-button block size="sm" v-if="$can('edit', 'Field')" variant="warning" v-b-modal.finalize-modal>
                <font-awesome-icon icon="leaf" /> Finalizar
              </b-button>

              <b-button block size="sm" v-if="$can('remove', 'Field')" v-b-tooltip.hover title="Apagar parcela"
                variant="danger" @click="removeField">
                <font-awesome-icon icon="trash" /> Excluir
              </b-button>


            </div>

          </b-col>
        </b-row>

      </b-card>
    </b-col>

    <b-col class="field-content" lg="8">
      <div>
        <h5 class="card-title">Recomendação de Irrigação</h5>

        <div v-if="dataOk" class="irrig-schedule-box">
          <p>
            <font-awesome-icon class="text-danger" icon="exclamation-triangle" />
            ATENCÂO: Caso ocorra chuva haverá alteração no planejamento de irrigação.

          </p>
          <irrigationSchedule :field="field" />
        </div>
        <div v-else>
          <b-alert show variant="warning" class="pr-5 shadow">
            <div class="d-flex">
              <div class="mr-2" style="font-size:35px;">
                <font-awesome-icon icon="exclamation-triangle" />
              </div>
              <div>
                <p>
                  Não foi possível mostrar os dados de irrigação porque existem
                  lançamentos pendentes para esta parcela.
                  <span v-if="$can('edit', 'InsertData')">
                    Você pode clicar
                    <b-link :to="{
                      name: 'insertData',
                      params: { date: field.status.dateToInsert },
                    }">aqui</b-link>
                    para lançar os dados pendentes.
                  </span>
                </p>
              </div>
            </div>
          </b-alert>
        </div>
      </div>

      <hr />
      <fieldMetrics :field="field" />
    </b-col>

    <b-modal id="finalize-modal" title="Finalizar Parcela" hide-footer lazy>

      <div v-if="finishingField">  
        <div class="d-flex align-items-center">
          <strong>Finalizando...</strong>
          <b-spinner small class="ml-2"></b-spinner>
        </div>
      </div> 

      <fieldEndEdit v-else :field="field"
       v-on:cancel="$bvModal.hide('finalize-modal')" 
       v-on:finalized="finalize($event)" 
       />
    </b-modal>


  </b-row>
</template>

<script>
import field from "./field";
//import sensorStatus from "@sensorcomponents/sensorStatus.vue";
import irrigationSchedule from "./schedule";
import fieldMetrics from "./fieldMetrics";
import fieldEndEdit from "./fieldEndEdit";
import cultureSVG from "@irrigcomponents/culture/cultureSvg/cultureSvg";

export default {
  extends: field,
  data() {
    return {
      irrigationsLoad: false,
      fieldDays: {},
      recalculing:false,
      finishingField:false
    };
  },
  props: {
    //fieldId:{ type: String, required: true},
    //field:{type:Object}
  },
  components: {
    //sensorStatus,
    irrigationSchedule,
    fieldMetrics,
    fieldEndEdit,
    cultureSVG,
  },

  methods: {
    /**
     * Remover parcela
     */
    removeField() {
      this.$deleteConfirm.show("excluir-parcela").then((value) => {
        if (value === true) {
          this.$store
            .dispatch("equipments/removeField", this.field.id)
            .then(() => {
              this.$router.go(-1);
            });
        }
      })
    },

    recalculeField(){
      this.recalculing = true
      this.$http.get("/irrig/equipment/field/"+this.field.id+"/recalcule").then(
      () => {this.recalculing = false }
      );
    },

    finalize(endCycle){
      this.finishingField = true
      this.$store
          .dispatch("equipments/finalizeField", {
            id: this.field.id,
            endCycle: endCycle
          }).then(() => {
            this.$router.push({name:'fieldHistory',params:{id:this.field.id}})
          },error=>{
            console.error("finalize Field error",error)
            this.finishingField = false
            this.$bvModal.msgBoxOk(
                "Verifique se todos os dados estão corretos",
                {
                  title: "Erro!",
                }
              );
          });
    }
  },
};
</script>

<style lang="scss" scoped>
.irrig-schedule-box {
  border: 1px dashed $border-color;
  color: #838383;
  border-radius: 8px;
  padding: 0.5em;
  // background-color: var(--modalLight);
  background-color: var(--white);
}


.field-content {
  @media (min-width: $breakpoint-lg) {
    //max-height: calc(100vh - 9rem);
    //overflow-y: auto;
  }
}

.field-info {
  //margin-bottom: 1rem;
  //border-right: 1px solid $border-color;
  // @media (min-width: $breakpoint-lg) {
  //   max-height: calc(100vh - 9rem);
  //   overflow-y: auto;
  // }

  .culture-icon {
    display: block;
    margin: 0 auto;
    width: 100px;
    padding: 3px;
    //border: 3px solid #d2d6de;
    border-radius: 30%;
    background-color: white;
    box-shadow: 2px 3px 5px #a0a0a0;
  }

  .culture-name {
    font-size: 21px;
    margin-top: 5px;
    text-align: center;
  }

  .field-info-group {
    padding-left: 0;
    border-left: 0;
    border-right: 0;
    border-radius: 0;
    padding-left: 0;
    padding-right: 0;

    li {
      position: relative;
      display: block;
      padding: 5px 10px 0px 10px;
      margin-bottom: -1px;
      border-bottom: 1px solid #aaa;
      line-height: 16px;

      span {
        float: right !important;
        color: #3c8dbc;
      }
    }
  }
}
</style>
