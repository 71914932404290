<template>
    <b-modal ref="object-edit-modal" title="Editar API" scrollable @hidden="close" size="lg">

      
            <validation-observer  ref="formObserver">
                <b-row>

                    <input-select class="col-sm-4" label="Tipo" v-model="editableObject.type"
                        :options="['weatherlink', 'underground','fieldClimate']" :rules="{ required: true }"></input-select>

                    <input-text class="col-sm-4" label="Nome" v-model="editableObject.name"
                        :rules="{ required: true, min: 5 }"></input-text>

                    <input-text class="col-sm-4" label="Serial Number" v-model="editableObject.feedSerialNumber"
                        :rules="{ required: true }"></input-text>


                    <input-area class="col-sm-12" label="Descrição" v-model="editableObject.description" />

                    <input-number class="col-sm-6" label="Intervalo (min)" v-model="editableObject.interval"
                        :rules="{ required: true, minValue: 1 }"></input-number>

                    <input-select class="col-sm-6" label="Zona" v-model="editableObject.timeZone" :options="$timeZoneList"
                        :rules="{ required: true }"></input-select>

                </b-row>

                <h6> Unidades </h6>
                <b-row>

                    <input-select class="col-sm-3" label="Temperatura" v-model="editableObject.tempUnit"
                        :options="['celsius', 'fahrenheit', 'kelvin']" :rules="{ required: true }"></input-select>

                    <input-select class="col-sm-3" label="Chuva" v-model="editableObject.rainUnit" :options="['mm', 'in']"
                        :rules="{ required: true }"></input-select>

                    <input-select class="col-sm-3" label="vento" v-model="editableObject.windUnit"
                        :options="['m/s', 'km/h', 'mi/h', 'ft/s', 'no']" :rules="{ required: true }"></input-select>


                    <input-select class="col-sm-3" label="radiação" v-model="editableObject.lightUnit" :options="['MJ', 'wm2']"
                        :rules="{ required: true }"></input-select>

                </b-row>


                <h6> API </h6>
                <b-row>
                    <input-text class="col-sm-12" label="Sensor ID" v-model="editableObject.apiSensorId" :rules="{}"></input-text>

                    <input-text class="col-sm-12" label="chave 1" v-model="editableObject.apiKey1" :rules="{}"></input-text>

                    <input-text class="col-sm-12" label="chave 2" v-model="editableObject.apiKey2" :rules="{}"></input-text>
                    <hr>
                </b-row>

            </validation-observer>


        <template v-slot:modal-footer>
            <b-button :disabled="savingObject" @click="submit" variant="primary">
                Salvar
                <font-awesome-icon v-if="savingObject" icon="spinner" spin />
            </b-button>
            <b-button @click="close">Cancelar</b-button>
        </template>


    </b-modal>
</template>


<script>
import { formModalObject } from "@components/mixins/FormModalObject";

export default {
  mixins: [formModalObject],
  mounted() {
    this.objectUrl = "sensorapi/sensors";
  },
};
</script>

<!-- <script>
export default {

    data() {

        return {
            apiForm: undefined,
            loadStatus: "",
        }
    },

    props: {
        id: String
    },

    mounted() {

        if (this.id != undefined)
            this.loadData()
        else {
            this.loadStatus = "loaded"
            this.apiForm = {}
        }
    },

    methods: {
        loadData() {
            this.loadStatus = "loading";
            this.$http.get("sensorapi/sensors/" + this.id).then(
                (data) => {
                    this.apiForm = {
                        id: data.id,
                        name: data.name,
                        apiKey1: data.apiKey1,
                        apiKey2: data.apiKey2,
                        apiSensorId: data.apiSensorId,
                        description: data.description,
                        feedSerialNumber: data.feedSerialNumber,
                        humidityUnit: data.humidityUnit,
                        interval: data.interval,
                        lastTimeSync: data.lastTimeSync,
                        lightUnit: data.lightUnit,
                        rainUnit: data.rainUnit,
                        tempUnit: data.tempUnit,
                        timeZone: data.timeZone,
                        type: data.type,
                        windUnit: data.windUnit,
                    }

                    this.loadStatus = "loaded";
                },
                (error) => {
                    console.error("user error:", error);
                    this.loadStatus = "error";
                }
            );
        },

        onSubmit() {
            this.$refs.formObserver.validate().then((result) => {
                if (!result) {
                    return;
                }

                let apiForm = JSON.parse(JSON.stringify(this.apiForm));

                this.$http.save("sensorapi/sensors", apiForm, apiForm.id).then(
                    (data) => {
                        this.emit("update-data", data)
                    },
                    (error) => {
                        // this.$refs.formObserver.setErrors(this.$validServer(error));
                        console.error(error);
                        this.$bvModal.msgBoxOk(
                            "Verifique se todos os dados estão corretos",
                            {
                                title: "Erro!",
                            }
                        );
                    }
                );
            });
        },



    }

}
</script> -->
<style lang="">
    
</style>