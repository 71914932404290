<template>
  <div>
    <div v-if="loadStatus == 'loaded'">
      <router-view
        :channelList="channelList"
        @removechannel="removeChannel($event)"
        @update="updateList($event)"
      ></router-view>
    </div>
    <c-loading
      @retry="loadData()"
      :status="loadStatus"
      v-else
    ></c-loading>
  </div>
</template>
<script>
export default {
  data() {
    return {
      numRows:1,
      loadStatus: "",
      channelList: [],
    };
  },

  mounted() {
    this.loadData();
  },

  methods: {
    loadData() {
      this.loadStatus = "loading";
      this.$http.get("/sens/channel").then(
        (data) => {
          this.channelList = data;
          this.loadStatus = "loaded";
          this.numRows = this.channelList.length;
        },
        (error) => {
          console.log("load channels error:", error);
          this.loadStatus = "error";
        }
      );
    },
    updateList(newChannel) {
      const index = this.channelList.findIndex((e) => e.id === newChannel.id);
      if (index === -1) {
        this.channelList.push(newChannel);
        this.numRows = this.channelList.length;
      } else {
        let dataObj = this.channelList[index];
        Object.entries(newChannel).forEach(([key, value]) => {
          dataObj[key] = value;
        });
      }
    },

    removeChannel(id) {
      this.$bvModal
        .msgBoxConfirm("Deseja realmente apagar este canal permanentemente?", {
          title: "Por favor confirme",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Sim",
          cancelTitle: "Não",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value === true) {
            this.$http.delete("sens/channel/" + id).then(
              () => {
                //var data
                let index = this.channelList.findIndex((e) => e.id === id);
                this.channelList.splice(index, 1);
                this.numRows = this.channelList.length;
                this.$router.push({ name: "channelList" });
              },
              (error) => {
                console.error("delete channel error", error);
              }
            );
          }
        });
    },

    editChannel(id) {
      this.$router.push({ name: "channelEdit", params: { id: id } });
    },
  },
};
</script>


