<template>
  <div>
    <b-breadcrumb>
      <b-breadcrumb-item :to="{ name: 'channelList' }">
        <font-awesome-icon icon="rss-square" class="item-icon" />
        Lista de Canais
      </b-breadcrumb-item>
      <b-breadcrumb-item v-if="loadStatus == 'loaded'" active>{{
        channel.name
      }}</b-breadcrumb-item>
    </b-breadcrumb>

    <div>
      <b-container fluid v-if="loadStatus == 'loaded'" class="channel-details">
        <b-row>
          <b-col lg="4" class="channel-info">
            <b-card no-body class="shadow sticky-top">
              <b-row no-gutters>
                <b-col sm="6" lg="12">
                  <div class="map-div">
                    <simple-map mapHeight="320px">
                      <gatewayMap :gateway="channelDetails" />
                      <feedMap v-for="feed in feeds" :key="feed.id" :feed="feed"
                        @click="$router.push({ name: 'feedDetails', params: { feedId: feed.id } })" />
                    </simple-map>
                  </div>
                </b-col>
                <b-col sm="6" lg="12" class="p-2">
                  <b-button-toolbar class="mb-2">
                    <b-button-group size="sm">
                      <b-button v-b-tooltip.hover title="Editar Channel" variant="primary" @click="mode = 'edit'">
                        <font-awesome-icon icon="edit" />
                      </b-button>

                      <b-button v-b-tooltip.hover title="Remover Channel" variant="danger"
                        @click="$emit('removechannel', channel.id)">
                        <font-awesome-icon icon="trash" />
                      </b-button>
                    </b-button-group>
                  </b-button-toolbar>

                  <ul class="channel-info-group">
                    <li>
                      <b>{{ channel.name }}</b>
                    </li>
                    <li>
                      <code>{{ channel.id }}</code>
                    </li>
                    <li>
                      <b>Elevação</b>
                      <span>{{ $filters.round(channelDetails.location.ele, 2) }} m</span>
                    </li>
                    <li>
                      <b>Status</b>
                      <b-badge v-if="channel.online" variant="success">
                        online
                      </b-badge>
                      <b-badge v-else variant="danger"> offline </b-badge>
                    </li>
                    <li>
                      <b>Ultima Sincronização</b>
                      <span>{{ $filters.localeDateTime(channelDetails.lastTimeSync) }}</span>
                    </li>

                  </ul>


                  <ul class="channel-info-group remote">
                    <li v-for="(value,key) in heartBeatInfo " :key="key">
                      <b>{{key}}</b>
                      <span>{{ value}}</span>
                    </li>
                  </ul>

                  <div>
                    <markdown :source="channelDetails.description"></markdown>
                  </div>
                </b-col>
              </b-row>
            </b-card>
          </b-col>

          <b-col lg="8">
            <!-- <b-button-toolbar key-nav> -->

            <div class="mt-2">
              <h5>Lista de Feeds</h5>
            </div>

            <div class="d-flex justify-content-between flex-wrap">
              <div>
                <b-button class="mr-2" title="Recarregar" size="sm" variant="outline-secondary"
                  @click="loadCompleteChannel()">
                  <font-awesome-icon icon="sync-alt" />
                </b-button>
              </div>

              <b-form-group>
                <b-form-input v-model="filter" type="search" id="filterInput" autocomplete="off"
                  placeholder="Pesquisar"></b-form-input>
              </b-form-group>
            </div>

            <b-table class="shadow border" striped small responsive :items="feeds" :fields="tableFields" :filter="filter"
              :current-page="currentPage" :per-page="perPage" @filtered="onFiltered">
              <template v-slot:cell(name)="row">
                <b-link :to="{ name: 'feedDetails', params: { feedId: row.item.id } }">{{ row.item.name }}</b-link>
              </template>

              <template v-slot:cell(serialNumber)="row">
                <code> {{ row.item.serialNumber }}</code>
              </template>

              <template v-slot:cell(status)="row">
                <b-badge v-if="row.item.online" variant="success">
                  online
                </b-badge>
                <b-badge v-else variant="danger"> offline </b-badge>
              </template>

              <template v-slot:cell(battery)="row">
                <battery class="mx-2" v-if="row.item.battery != null" :data="row.item.battery" />
              </template>

            </b-table>

            <div class="d-flex flex-wrap mb-5">
              <div class="p-2">
                Linhas por página
              </div>
              <div class="pr-4">
                <b-form-select v-model="perPage">
                  <b-form-select-option :value="10">10</b-form-select-option>
                  <b-form-select-option :value="25">25</b-form-select-option>
                  <b-form-select-option :value="50">50</b-form-select-option>
                  <b-form-select-option :value="100">100</b-form-select-option>
                  <b-form-select-option :value="9999">máximo</b-form-select-option>
                </b-form-select>
              </div>

              <b-pagination v-model="currentPage" :total-rows="numRows" :per-page="perPage"
                aria-controls="my-table"></b-pagination>

              <div class="ml-auto">

                <b-button class="mr-1" size="sm" variant="primary" @click="exportKml()">
                  <font-awesome-icon icon="file-arrow-down" />
                  KML
                </b-button>

                <b-button size="sm" variant="success" @click="addFeed(undefined)">
                  <font-awesome-icon icon="plus" />
                  Novo feed
                </b-button>
              </div>
            </div>

          </b-col>
        </b-row>

        <!-- -----------  MODO DE EDIÇÂO -------------------- -->
        <channelEdit v-if="mode == 'edit'" @update="updateChannel($event)" :channel="channelDetails"
          @cancel="mode = 'view'" />

        <router-view v-if="channel" :channel="channel" @delete-feed="deleteFeed($event)"></router-view>
      </b-container>
      <c-loading :status="loadStatus" v-else></c-loading>

      <!-- {{channel}} -->
    </div>
  </div>
</template>

<script>
import feedMap from "./feedMap";
import gatewayMap from "./gatewayMap";
import channelEdit from "./channelEdit";
import makeId from "@/utils/makeId";

export default {
  data() {
    return {
      channel: undefined,
      mode: "view",
      newFeedId: undefined,
      channelDetails: undefined,
      channelform: undefined,
      loadStatus: "loading",
      perPage: 10,
      currentPage: 1,
      filter: null,
      numRows: 1,
      tableFields: [
        { key: "name", label: "Nome", sortable: true },
        { key: "serialNumber", label: "Serial Number", sortable: false },
        { key: "status", label: "Status", sortable: false },
        { key: "lastSyncSeconds", label: "Ultima", class: 'text-center', sortable: true, formatter: this.$filters.secondsToStr },
        { key: "battery", label: "Bateria", sortable: false, class: 'text-center' },
      ],
    };
  },
  props: {
    // channel: { type: Object, required: true },
    channelId: { type: String, required: true },
    channelList: { type: Array, required: true },
  },
  components: {
    feedMap,
    gatewayMap,
    channelEdit,
  },
  mounted() {
    // this.$refs["details-modal"].show();
    if (this.channelId !== undefined) {
      this.channel = this.channelList.find((ele) => this.channelId === ele.id);
    }
    if (!this.channel) {
      this.$router.push({ name: "channelList" });
    }

    this.loadCompleteChannel();
  },

  computed: {
    feeds() {
      return this.channelDetails && this.channelDetails.feeds
        ? this.channelDetails.feeds
        : [];
    },

    heartBeatInfo(){
      try {
        let obj = JSON.parse(this.channelDetails.heartBeatInfo)
        let orderedObj = Object.keys(obj)
        .sort()  // Ordenar as chaves
        .reduce((acc, key) => {
          acc[key] = obj[key];  // Reconstruir o objeto na ordem
          return acc;
        }, {});
        return orderedObj
      } catch (error) {
        return {}
      }
     
    },


    kml() {
     let xml = '<?xml version="1.0" encoding="utf-8"?> <kml xmlns="http://www.opengis.net/kml/2.2"> <Document>'
      xml += '<name> pontos de '+ this.channel.name +'</name>'
      xml += '<open>1</open>'
      //xml += '<description>Unleash your creativity with the help of these examples!</description>'
      // xml += '<Folder>'

      this.feeds.forEach(e => {

        if (e.lastSyncSeconds < 60*60*24*50){
            xml += '<Placemark>'
            xml += '<name>' + e.name + '</name><Point><coordinates>' + e.location.lon + ',' + e.location.lat + ',' + e.location.ele + '</coordinates></Point>'
            xml += '</Placemark>'
        }
      })

      // xml += '</Folder>'
      xml += '</Document></kml>'

      return xml
    }
  },


  methods: {


    exportKml() {

      const blob = new Blob([this.kml], { type: "xml" });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = this.channel.name+".kml";
      link.click();
      URL.revokeObjectURL(link.href);

    },


    deleteFeed(feedId) {
      let index = this.channelDetails.feeds.findIndex((e) => e.id === feedId);
      this.channelDetails.feeds.splice(index, 1);

    },
    updateChannel(data) {
      this.$emit("update", data);
      this.loadCompleteChannel();
      this.mode = "view";
    },

    close() {
      this.$router.push({ name: "channelList" });
    },

    onFiltered(filteredItems) {
      this.numRows = filteredItems.length;
    },

    loadCompleteChannel() {
      this.loadStatus = "loading";
      this.$http.get("/sens/channel/" + this.channelId).then(
        (data) => {
          this.channelDetails = data;
          // this.channelform = {
          //   // name: data.name,
          //   // serialNumber: data.serialNumber,
          //   // description: data.description,
          //   // OfflineTime: data.OfflineTime,
          //   // autoUpdateLocation: data.autoUpdateLocation,
          //   // location: data.location ? data.location : { lat: 0, lon: 0 },
          // };
          this.loadStatus = "loaded";
          this.numRows = this.feeds.length;
        },
        (error) => {
          console.log("load feed error:", error);
          this.loadStatus = "error";
        }
      );
    },

    addFeed(feedId) {
      let feedKey = makeId(5);

      let feedData = {
        id: feedId ? feedId : undefined,
        name: "feed@" + feedKey,
        serialNumber: feedKey,
        description: "",
        OfflineTime: 300,
        autoUpdateLocation: false,
        location: { lat: 0, lon: 0, ele: 0 },
        dataStreams: [
          {
            name: "bateria",
            units: "milivolt",
            unitSymbol: "mV",
            maxValue: 4200,
            minValue: 3300,
            sensorId: 0,
          },
        ],
      };

      this.$http
        .post("/sens/channel/" + this.channel.id + "/feed", feedData)
        .then(
          (data) => {
            this.channelDetails.feeds.push(data);
            this.$router.push({ name: 'feedDetails', params: { feedId: data.id } })
          },
          (error) => {
            console.error("create feed error", error);
          }
        );
    },
  },
};
</script>

<style lang="scss" scoped>
.feed-table {
  margin: 1rem 0px;
}

.channel-details {
  height: 100%;
}


.remote{
  background-color: rgb(255, 255, 170);
}

.channel-info {
  //background-color: #eee;
  //padding-top: 1rem;
  // margin-top: 1rem;
  // margin-bottom: -1rem;
  //border-right: 1px dotted #aaa;
  //margin-top: -1rem;
  //position: sticky;
  //position: sticky;
  //top: 4rem;

  .channel-info-group {
    padding-left: 0;
    border-left: 0;
    border-right: 0;
    border-radius: 0;
    padding-left: 0;
    padding-right: 0;

    li {
      position: relative;
      display: block;
      padding: 5px 10px;
      margin-bottom: -1px;
      border-bottom: 1px solid #ddd;

      span {
        float: right !important;
      }
    }
  }
}
</style>
