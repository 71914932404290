<template>
  <div ref="mapdiv" class="map-div" :style="{ height: mapHeight}">
    <vl-map :load-tiles-while-animating="true" :load-tiles-while-interacting="true" data-projection="EPSG:4326" ref="map"
      @singleclick="onMapSingleClick" @pointermove="onMapPointermove" @dblclick="onMapSingleClick"
      @postcompose="onMapPostCompose">
      <vl-view ref="view" :minZoom="5" :maxZoom="19" projection="EPSG:4326"></vl-view>

      <vl-layer-tile :z-index="1" v-for="layer in baseLayers" :key="layer.title" :id="layer.title"
        :visible="layer.visible">
        <component :is="'vl-source-' + layer.source" v-bind="layer"></component>
      </vl-layer-tile>

      <slot name="layer"></slot>

      <vl-layer-vector :z-index="2">
        <vl-source-vector>
          <slot name="no-fit-vectors"></slot>
        </vl-source-vector>
      </vl-layer-vector>

      <vl-layer-vector :z-index="2">
        <vl-source-vector @mounted="onMapMounted" ref="objSource" :ident="objectsVectorIdent">
          <slot></slot>
        </vl-source-vector>

        <!-- overlay com o nome da feature -->
        <vl-overlay v-if="featurePointer != undefined" :position="featurePointer.coordinates" :offset="[-48, -20]"
          positioning="bottom-left">
          <div class="map-popup">
            <div v-for="(value, index) in featurePointer.titles" :key="value + index">
              {{ value }} <br />
            </div>
          </div>
        </vl-overlay>
      </vl-layer-vector>

      <!-- <div class="base-layers-panel ol-unselectable ol-control">
        <button v-for="layer in baseLayers"
                :key="layer.source" :class="{ 'is-info': layer.visible }"
                @click="showBaseLayer(layer.source)">
            {{layer.title}}
        </button>
    </div> -->
    </vl-map>
    <div class="ol-touch ol-unselectable map-control">
      <button class="map-fit map-button" type="button" @click="fit(300)" title="ajustar mapa">
        <font-awesome-icon icon="search-location" />
      </button>
      <selectLayer :baseLayers="baseLayers" />
      <slot name="map-control" />
    </div>
  </div>
</template>

<script>
import map from "./map";
import selectLayer from "./selectLayer";
export default {
  extends: map,

  data() {
    return {
      ro: null,
    }
  },

  props: {
    mapHeight: { type: String, default: '400px' },
    objectsVectorIdent:String
  },

  mounted() {
    this.ro = new ResizeObserver(this.updateSize)
    this.ro.observe(this.$refs.mapdiv)
  },

  beforeDestroy() {
    this.ro.unobserve(this.$refs.mapdiv)
  },

  methods: {
    onMapSingleClick(evt) {
      this.mousePosClick = evt.coordinate;
      let features = this.$refs.map.$map.getFeaturesAtPixel(evt.pixel);
      if (!features) {
        return;
      }

      for (var i = 0; i < features.length; i++) {
        let onclick = features[i].values_.onClick;
        if (onclick !== undefined) {
          onclick();
          break;
        }
      }
    },

    updateSize() {
      this.$refs.map.updateSize();
    },
  },
  components: {
    selectLayer,
  },
};
</script>

<style lang="scss" scoped>
.map-div {
  position: relative;
  width: 100%;

  .map {
    height: 100%;
    width: 100%;
  }
}
</style>
