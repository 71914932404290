<template>
  <tr v-if="value!== undefined">
    <td class="id-r-line field-label">
      <font-awesome-icon
        v-if="value.dataChecked!=true"
        class="text-warning"
        icon="exclamation-triangle"
      />
      <font-awesome-icon v-else class="text-success" icon="check-circle" />
      {{name}}
    </td>
    <td class="td-input">
      <b-select
        @change="umidityChange"
        :options="humanSensorialOptions"
        v-model="humanSensorial"
        size="sm"
      />
    </td>
    <td class="td-input id-r-line">
      <b-select
        @change="umidityChange"
        :options="humanSensorialOptions"
        v-model="humanSensorialFinal"
        size="sm"
      />
      <!-- <div  :style="{backgroundColor:endColor}"/> -->
    </td>
    <td></td>
  </tr>
</template>

<script>
export default {
  data() {
    return {
      humanSensorial: undefined,
      humanSensorialFinal: undefined,
      humanSensorialOptions: [
        { value: null, text: "" },
        { value: 1, text: "1 (C. campo)" },
        { value: 2, text: "2" },
        { value: 3, text: "3" },
        { value: 4, text: "4" },
        { value: 5, text: "5" },
        { value: 6, text: "6" },
        { value: 7, text: "7" },
        { value: 8, text: "8" },
        { value: 9, text: "9" },
        { value: 10, text: "10 (P. Murcha)" }
      ]
    };
  },
  methods: {
    umidityChange() {
      let value = JSON.parse(JSON.stringify(this.value))
     
      if (this.humanSensorial == null) {
        value.humanSensorial = null;
      } else {
        value.humanSensorial = [this.humanSensorial];
      }

      if (this.humanSensorialFinal == null) {
        value.humanSensorialFinal = null;
      } else {
        value.humanSensorialFinal = [this.humanSensorialFinal];
      }

      // console.log(value)
      this.$emit("onchange");
      this.$emit("input",value); 
    }
  },
  props: {
    value: Object,
    name:  String
  },
  computed: {
    endColor() {
      return "#fa0";
    },
    startColor() {
      return "#af0";
    }
  },
  mounted() {
    if (this.value !== undefined) {
      if (this.value.humanSensorial != null)
        this.humanSensorial = this.value.humanSensorial[0];

      if (this.value.humanSensorialFinal != null)
        this.humanSensorialFinal = this.value.humanSensorialFinal[0];
    }
  }
};
</script>

<style lang="scss" scoped>

.td-input {
  width: 150px;
}
</style>