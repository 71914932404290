
import ndviPage from './ndviPage'
import fieldNDVI from "./ndviArea";
import ndviAreaList from "./ndviAreaList"

export default {
    path: 'ndvi',
    component: ndviPage,
    children: [
        {
            name:'ndviPageList',
            path:'',
            component: ndviAreaList,
        },
        {
            path: ':id',
            component: fieldNDVI,
            name: 'ndviArea',
            props: function (route) {
                return { areaId: route.params.id }
            }

        }
    ]
}

