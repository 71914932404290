<template>

    <div class="nav-menu-content">

        <b-navbar-nav>
            <router-link :to="{ name: 'home' }" class="brand-link">

                <img src="@/assets/logo.png" alt="Kalliandra Logo" class="brand-image " > 
                <!-- <img v-if="user.imageSrc" :src="$avatarUrl + user.imageSrc" class="brand-image " />
                <img v-else src="@/assets/logo.png" alt="Kalliandra Logo" class="brand-image" >  -->
             
                <span class="brand-text font-weight-light"> {{ workspace.name }}</span>
                <!-- {{ workspace.subTitle }} -->
            </router-link>


            

        </b-navbar-nav>


        <div class="nav-menu-content-list" :style="{ transform: submenu ? 'translate(-100%)' : 'translate(0)' }">
        

            <div class="nav-menu-content-itens"  >
                <!-- :style="{ width: submenu ? '0' : '100%', visibility:submenu?'hidden':'visible' }" -->
                <!-- :style="{ transform: submenu ? 'translate(-13rem)' : 'translate(0)' }" -->


                <!-- <div class="d-flex flex-column"> -->
                <b-navbar-nav>
                    <b-nav-item :to="{ name: 'home' }">
                        <!-- <font-awesome-icon icon="home" class="nav-icon" /> -->
                        <font-awesome-icon :icon="['fal', 'house']" class="nav-icon" />
                        <!-- <b-icon icon="house" class="nav-icon" /> -->
                        <span class="label"> Home </span>
                    </b-nav-item>


                    <b-nav-item v-if="$can('read', 'Soil')" :to="{ name: 'soilList' }">
                        <!-- <font-awesome-icon icon="layer-group" class="nav-icon" /> -->
                        <font-awesome-icon :icon="['fal', 'layer-group']" class="nav-icon" />
                        <!-- <b-icon icon="layers" class="nav-icon" /> -->
                        <span class="label"> Solo </span>
                    </b-nav-item>

                    <b-nav-item v-if="$can('read', 'Culture')" :to="{ name: 'cultureList' }">
                        <!-- <font-awesome-icon icon="seedling" class="nav-icon" /> -->
                        <font-awesome-icon :icon="['fal', 'seedling']" class="nav-icon" />
                        <!-- <b-icon icon="tree" class="nav-icon" /> -->
                        <span class="label"> culturas </span>
                    </b-nav-item>


                    <b-nav-item v-if="$can('edit', 'InsertData')" :to="{ name: 'insertData' }" title="Lançar dados">
                        <!-- <font-awesome-icon icon="clipboard" class="nav-icon" /> -->
                        <font-awesome-icon :icon="['fal', 'clipboard']" class="nav-icon" />
                        <!-- <kli-calendar-edit class="nav-icon" />  -->
                        <!-- <b-icon icon="clipboard-check" class="nav-icon" /> -->
                        <span class="label"> Lançar dados </span>
                    </b-nav-item>



                    <b-nav-item v-b-toggle.collapse-more>
                        <font-awesome-icon :icon="['fal', 'grip-dots']" class="nav-icon" />
                        <!-- <b-icon icon="circle" class="nav-icon" /> -->
                        <span class="label"> Mais </span>
                        <font-awesome-icon icon="chevron-right" class="right" />
                    </b-nav-item>
                    <b-collapse id="collapse-more" accordion="sub-menu-accordion">
                        <b-nav-item :to="{ name: 'stationList' }">
                            <font-awesome-icon :icon="['fal', 'weather-station']" class="nav-icon" />
                            <span class="label">Estações</span>
                        </b-nav-item>

                        <b-nav-item :to="{ name: 'pluvioList' }">
                            <font-awesome-icon :icon="['fal', 'pluviometer']" class="nav-icon" />
                            <span class="label">Pluviômetros</span>
                        </b-nav-item>

                        <b-nav-item v-if="$can('create', 'Equipment')"
                            :to="{ name: 'equipmentNew', params: { id: undefined } }">
                            <font-awesome-icon :icon="['fal', 'circle-small']" class="nav-icon" />
                            <span class="label">Novo Equipamento</span>
                        </b-nav-item>
                    </b-collapse>


                    <!-- RELATORIOS -->
                    <b-nav-item v-b-toggle.collapse-report>
                        <font-awesome-icon :icon="['fal', 'file-chart-pie']" class="nav-icon" />
                        <!-- <b-icon icon="circle" class="nav-icon" /> -->
                        <span class="label"> Relatórios </span>
                        <font-awesome-icon icon="chevron-right" class="right" />
                    </b-nav-item>
                    <b-collapse id="collapse-report" accordion="sub-menu-accordion">
                        <b-nav-item v-if="$can('read', 'ConsumerStation')" :to="{ name: 'electricityPage' }">
                            <font-awesome-icon :icon="['fal', 'transformer-bolt']" class="nav-icon" />
                            <span class="label">Energia</span>
                        </b-nav-item>

                        <b-nav-item v-if="$can('read', 'Report')" :to="{ name: 'irrigationReportList' }">
                            <font-awesome-icon :icon="['fal', 'circle-small']" class="nav-icon" />
                            <span class="label">Irrigação</span>
                        </b-nav-item>

                        <b-nav-item v-if="$can('read', 'Report')" :to="{ name: 'fieldsReportMetrics' }">
                            <font-awesome-icon :icon="['fal', 'circle-small']" class="nav-icon" />
                            <span class="label">Resumo de Parcelas</span>
                        </b-nav-item>
                    </b-collapse>


                    <b-nav-item :to="{ name: 'rainHeatMap' }" title="Mapa de chuva">
                        <!-- <font-awesome-icon icon="cloud-rain" class="nav-icon" />  -->
                        <font-awesome-icon :icon="['fal', 'cloud-rain']" class="nav-icon" />
                        <!-- <b-icon icon="cloud-rain" class="nav-icon" /> -->
                        <span class="label"> Mapa de chuva </span>
                    </b-nav-item>

                    <b-nav-item :to="{ name: 'ndviPageList' }" title="NDVI">
                        <!-- <font-awesome-icon icon="satellite" class="nav-icon" />  -->
                        <font-awesome-icon :icon="['fal', 'satellite']" class="nav-icon" />
                        <span class="label"> NDVI </span>
                    </b-nav-item>

                    <b-nav-item v-if="$can('read', 'Channel')" :to="{ name: 'channelList' }"
                        title="Canais de comunicação">
                        <!-- <font-awesome-icon icon="rss-square" class="nav-icon" />  -->
                        <font-awesome-icon :icon="['fal', 'router']" class="nav-icon" />
                        <!-- <b-icon icon=" diagram3" class="nav-icon" /> -->
                        <span class="label"> Sensores </span>
                    </b-nav-item>

                    <b-nav-item v-if="$can('read', 'Channel')" :to="{ name: 'sensorUptime' }">
                        <font-awesome-icon :icon="['fal', 'stopwatch']" class="nav-icon" />
                        <span class="label"> Funcionamento </span>
                    </b-nav-item>

                  

                </b-navbar-nav>




                <b-navbar-nav class="mt-auto">


                    <b-nav-item :to="{ name: 'user-account' }" title="Configurações">
                        <font-awesome-icon :icon="['fal', 'gear']" class="nav-icon" />
                        <span class="label"> Configurações </span>
                    </b-nav-item>

                    <!-- Clientes -->
                    <b-navbar-nav>
                    <b-nav-item v-if="client" :text="client.name" @click="showAllClients()" :title="client.name">
                        <kli-farm class="nav-icon" />
                        <span class="label text-truncate" style="max-width: 150px">
                            {{ client.name }}
                        </span>
                        <font-awesome-icon icon="chevron-right" class="right" />
                    </b-nav-item>
                </b-navbar-nav>


                    <b-nav-item @click="logout" title="Sair">
                        <Avatar class="nav-icon avatar" :username="user.name" :src="$avatarUrl + user.imageSrc"
                            :size="28" />
                        <span class="label"> {{ user.name }} </span>
                        <font-awesome-icon icon="right-from-bracket" class="right" />
                    </b-nav-item>

                </b-navbar-nav>
            </div>
        </div>



        <clientSelect ref="client-select" />



    </div>
</template>

<script>
import { mapGetters } from "vuex";
import Avatar from "@components/account/avatar/Avatar";
import clientSelect from "./clientSelect.vue";
export default {


    data() {
        return {
            submenu: false
        }
    },

    computed: {
        ...mapGetters({
            user: "accounts/user",
            workspace: "accounts/workspace",
            workspaces: "accounts/workspaces",
            client: "accounts/client",
            clients: "accounts/clients",
        }),

    },

    components: {
        Avatar,
        clientSelect
    },

    methods: {

        logout() {
            this.$bvModal .msgBoxConfirm( "Você quer se desconectar deste dispositivo?",
                {
                    title: "Sair",
                    size: "sm",
                    buttonSize: "sm",
                    okVariant: "danger",
                    okTitle: "Sair",
                    cancelTitle: "Cancelar",
                    footerClass: "p-2",
                    hideHeaderClose: false,
                    centered: true,
                }
                )
                .then((value) => {
                    if (value === true) {
                        this.$http.clearToken();
                        this.$router.push({ name: "login" });
                    }
                })
            
        },
        changeWorkspace(ws) {
            this.$store.dispatch("accounts/changeWorkspace", ws.id);
        },
        changeClient(cl) {
            this.$store.dispatch("accounts/changeClient", cl.id);
        },
        showAllClients() {
            this.$refs["client-select"].show();
        },
    },

}
</script>
<style lang="scss" scoped>
.nav-menu-content {
    padding: 0.4rem;
    //border-bottom: $border-width solid $border-color;

    display: flex;
    flex-direction: column;
    color: rgba(20, 29, 20, 0.74);
    height: 100%;

    .brand-link {
        display: block;
        font-size: 1.25rem;
        line-height: 1.5;
        padding: 0.5rem 0.25rem;
        transition: width .3s ease-in-out;
        white-space: nowrap;
        margin-bottom: 1rem;
        text-decoration: none;

        .brand-image {
            position: absolute;
            float: left;
            line-height: .8;
            margin-left: 0rem;
            margin-right: 0.5rem;
            margin-top: -3px;
            max-height: 40px;
            width: auto;
            border-radius: 5px;
            opacity: .8;
            transition: opacity .6s ease, visibility .6s ease, transform .6s ease,
        }


        .brand-text {
            padding-left: 50px;
            color: rgba(0, 0, 0, 0.7);
            transition: opacity .3s ease, visibility .3s ease
        }
    }


    .nav-menu-content-list {

        transition: transform .3s ease-in-out;
        
        display: flex;
        height: 100%;

        .nav-menu-content-itens {
            display: flex;
            flex-direction: column;
            width: 100%;
            transition: width .3s ease-in-out;
            height: 100%;

            .nav-item {
                margin-bottom: 0;
                // padding-bottom: 0.1rem;
                //padding-right: 0.1rem;
               


                &.not-collapsed .nav-link .right {
                    transform: rotate(90deg);
                }

                .router-link-active {
                    color: $primary !important;
                    // border-right: 2px solid $primary;
                    font-weight: bold;
                }


                .nav-link {
                    color: inherit;
                    display: flex;
                    justify-content: flex-start;
                    //padding: 0.5rem 1rem;
                    padding-left: 0.8rem;
                    border-radius: 0.3rem;
                    position: relative;

                    &:hover {
                        background-color: rgba(0, 0, 0, 0.100);
                    }


                    .label {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }

                    .right {
                        transition: transform ease-in-out .3s, -webkit-transform ease-in-out .3s;
                        position: absolute;
                        right: 1rem;
                        top: 0.7rem;
                    }


                    .nav-icon {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        min-width: 1.5rem;
                        max-width: 1.5rem;
                        //margin-left: 0.05rem;
                        font-size: 1.2rem;
                        margin-right: 0.5rem;
                        text-align: center;
                        // width: 1.6rem;
                    }
                }

            }
        }
    }


}
</style>