<template>
  <b-container fluid="lg" class="insert-page">
    <div class="header">
      <h5>Lançamento e Confirmação de dados</h5>
      <div class="d-flex flex-wrap">
        <div class="mb-2">
          <small>
            <font-awesome-icon icon="info-circle" />
            Formulário de confirmação e inserção simplificado de dados
          </small>
        </div>
        <div class="ml-auto d-flex">
         
            <!-- <b-button @click="moveToDateInsert" variant="warning" title="ir para o pŕoximo dia com pendência">
              <font-awesome-icon icon="calendar-times" />
            </b-button> -->
        
            <div class="mr-2">
              <b-button @click="moveToDateInsert" variant="warning" title="ir para o pŕoximo dia com pendência">
                <kli-calendar-exclamation v-if="loadingDataInsert == 'normal'" />
                <font-awesome-icon v-else icon="spinner" spin />
              </b-button>
            </div>
      
            <!-- <b-form-input type="date" v-model="currentDate" lazy :max="today" v-on:keyup.enter="loadData(currentDate);"></b-form-input> -->
           
            <b-form-datepicker  boundary="viewport" :max="today" :date-format-options="{ 'year': 'numeric', 'month': 'numeric', 'day': 'numeric' }" v-model="currentDate" class="mb-2"></b-form-datepicker>
           
           
            <!-- <b-input-group-append>
              <b-button variant="primary" @click="loadData(currentDate);">
                <font-awesome-icon icon="check" />
              </b-button>
            </b-input-group-append> -->
         

          <div class="ml-4">
            <b-button :disabled="saveButtonDisabled" @click="submit" :variant="saveButtonVariant">{{ saveButtonText }}
              <font-awesome-icon v-if="dataStatus == 'saving'" icon="spinner" spin />
            </b-button>
          </div>
        </div>
      </div>
    </div>

    <div v-if="pageStatus === 'loaded'">      
      <div>
        <h6><b-badge variant="info"><font-awesome-icon icon="calendar" /> {{ $filters.localeDate(data.date) }}</b-badge></h6>
      </div>

      <b-tabs content-class="p-1 shadow border border-top-0" v-model="tabIndex">
        <validation-observer ref="formObserver">
          <!-- ABA DE IRRIGACAO -->
          <validation-observer v-slot="{ invalid }">
            <b-tab>
              <template #title>
                <font-awesome-icon class="text-danger" icon="exclamation-triangle" v-if="invalid" />
                Irrigação
              </template>
              <b-table-simple class="insert-table table table-responsive-sm table-hover table-sm ">
                <thead class="text-center">
                  <th>Parcela</th>
                  <th colspan="3">Irrigação</th>
                  <th></th>
                </thead>
                <thead class="text-center">
                  <th></th>
                  <th class="insert-col">Volume (mm)</th>
                  <th class="insert-col">Percent. (%)</th>
                  <th class="insert-col">Tempo (h)</th>
                  <th class="insert-col">Obs.</th>
                </thead>
                <tbody v-for="equipment in data.equipments" :key="equipment.id">
                  <tr>
                    <td class="id-r-line">{{ equipment.name }}</td>
                    <td class="id-r-line" colspan="3"></td>
                    <td></td>
                  </tr>
                  <insertIrrigRow v-for="field in equipment.fields" :key="field.id" :name="field.name" :field="field"
                    v-model="field.day" @onchange="setDataChanged" />
                </tbody>
              </b-table-simple>
            </b-tab>
          </validation-observer>

          <!-- ABA DE Pluviômetros -->
          <validation-observer v-slot="{ invalid }">
            <b-tab>
              <template #title>
                <font-awesome-icon class="text-danger" icon="exclamation-triangle" v-if="invalid" />
                Chuva
              </template>
              <table class="insert-table table table-hover table-sm">
                <thead class="text-center">
                  <th>Pluviômetros</th>
                  <th>Chuva (mm)</th>
                  <th></th>
                </thead>
                <insertPluvioRow v-for="pluvio in data.pluviometers" :key="pluvio.id" :name="pluvio.name"
                  v-model="pluvio.rain" @onchange="setDataChanged" />
              </table>
            </b-tab>
          </validation-observer>

          <!-- ABA DE Climas -->
          <validation-observer v-slot="{ invalid }">
            <b-tab>
              <template #title>
                <font-awesome-icon class="text-danger" icon="exclamation-triangle" v-if="invalid" />
                Clima
              </template>
              <b-table-simple stacked="md" class="insert-table table table-responsive-md table-hover table-sm">
                <b-thead class="text-center">
                  <th style="width: 30%">Estação</th>
                  <th>Temp. max <br><small>( ºC )</small></th>
                  <th>Temp. min <br><small>( ºC )</small></th>
                  <th>Temp. média <br><small>( ºC )</small></th>
                  <th>Umid. relativa <br><small>( % )</small></th>
                  <th>Vento 2m <br><small>( m/s )</small></th>
                  <th>Radiação <br><small>( MJ/dia )</small></th>
                  <th>chuva <br><small>( mm )</small></th>
                  <th></th>
                </b-thead>
                <b-tbody>
                  <insertWeatherRow v-for="station in data.wheaterStations" :key="station.id" :name="station.name"
                    v-model="station.weather" @onchange="setDataChanged" />
                </b-tbody>
              </b-table-simple>
            </b-tab>
          </validation-observer>

          <!-- ABA DE UMIDADE -->
          <b-tab title="Umidade">
            <table class="insert-table table table-hover table-sm">
              <thead class="text-center">
                <th>Parcelas</th>
                <th colspan="2">Sensorial</th>
                <th></th>
              </thead>
              <thead class="text-center">
                <th></th>
                <th>Inicio</th>
                <th>Fim</th>
                <th></th>
              </thead>
              <tbody v-for="equipment in data.equipments" :key="equipment.id">
                <tr>
                  <td class="id-r-line">{{ equipment.name }}</td>
                  <td class="id-r-line" colspan="2"></td>
                  <td></td>
                </tr>
                <insertHumidityRow v-for="field in equipment.fields" :key="field.id" :name="field.name"
                  v-model="field.day" @onchange="setDataChanged" />
              </tbody>
            </table>
          </b-tab>
        </validation-observer>
      </b-tabs>

      <!-- <div class="mt-4 d-flex">
        <div class="ml-auto">
          <b-button
            :disabled="saveButtonDisabled"
            @click="submit"
            :variant="saveButtonVariant"
            >{{ saveButtonText }}
            <font-awesome-icon
              v-if="dataStatus == 'saving'"
              icon="spinner"
              spin
            />
          </b-button>
        </div>
      </div> -->


    </div>
    <c-loading v-else @retry="startLoad()" :status="pageStatus">
      <template v-slot:before-spiner>
            <div class="mb-2">
              <img src="@/assets/calendar.png" width="64" alt="" />
            </div>
      </template>
    
    </c-loading>
    <!-- {{dataToSend}}  -->
    <!-- <vue-json-pretty :path="'res'" :data="dataToSend" :deep="3" showLength></vue-json-pretty> -->
    <!-- <vue-json-pretty :path="'res'" :data="data"></vue-json-pretty> -->
  </b-container>
</template>

<script>
import { DateTime } from "luxon";
import insertIrrigRow from "./insertIrrigRow";
import insertHumidityRow from "./insertHumidityRow";
// import insertRainRow from "./insertRainRow";
import insertWeatherRow from "./insertWeatherRow";
import insertPluvioRow from "./insertPluvioRow";

//import dataTeste from './teste/data.json'

export default {
  data() {
    return {
      tabIndex: 0,
      data: [],
      pageStatus: "loading",
      dataStatus: "normal",
      currentDate: undefined,
      loadingDataInsert: "normal",
      today: "",
    };
  },
  props: {
    date: { type: String, required: false, default: undefined },
  },
  watch: {
    currentDate(val, old) {
      if (val !== old) {
        this.loadData(this.currentDate);
      }
    },
  },
  computed: {
    saveButtonVariant() {
      switch (this.dataStatus) {
        case "saving":
          return "secondary";
        case "saved":
          return "success";
        default:
          return "primary";
      }
    },
    saveButtonText() {
      switch (this.dataStatus) {
        case "saving":
          return "";
        case "saved":
          return "salvo";
        default:
          return "salvar";
      }
    },
    saveButtonDisabled() {
      if (this.dataStatus === "changed") {
        return false;
      }
      return true;
    },
  },

  mounted() {
    let today = new Date();
    this.today = today.toISOString().substring(0, 10);
    this.startLoad();
  },

  methods: {
    startLoad() {
      if (this.date !== undefined) {
        this.currentDate = this.date;
      } else {
        this.moveToDateInsert();
      }
    },

    setDataChanged() {
      this.dataStatus = "changed";
    },

    moveToDateInsert() {
      this.loadingDataInsert = "loading";
      this.$http.get("irrig/days/insertDate").then(
        (data) => {
          this.currentDate = data;
          this.loadingDataInsert = "normal";
        },
        (error) => {
          console.error("load insert data error", error);
          this.loadingDataInsert = "normal";
          this.pageStatus = "error";
        }
      );
    },

    getDataToSend() {
      /** prepara dados de envio para parcelas
       * irrigações, ajuste manual e flags
       */
      let days = [];

      this.data.equipments.forEach((equipment) => {
        equipment.fields.forEach((field) => {
          if (field.day != undefined) {
            days.push(field.day);
          }
        });
      });

      let stations = this.data.wheaterStations.map((e) => {
        if (e.weather == undefined) {
          e.weather = {};
        }
        return { id: e.id, weather: e.weather, name: e.name };
      });

      let pluviometers = this.data.pluviometers.map((e) => {
        if (e.rain == undefined) {
          e.rain = {};
        }
        return { id: e.id, rain: e.rain, name: e.name };
      });

      return {
        date: this.data.date,
        days: days,
        pluviometers: pluviometers,
        wheaterStations: stations,
      };
    },

    loadData(date) {
      this.pageStatus = "loading";
      this.$http.get("irrig/days/" + date).then(
        (data) => {
          this.dataStatus = "normal";
          this.data = data;
          this.pageStatus = "loaded";
        },
        (error) => {
          console.error("load days error:", error);
          this.pageStatus = "error";
        }
      );
    },

    submit() {
      this.$refs.formObserver.validate().then((result) => {
        if (!result) {
          this.$bvModal.msgBoxOk("Verifique se todos os dados estão corretos", {
            title: "Erro!",
          });
          return;
        }

        this.dataStatus = "saving";
        this.$http.post("irrig/days/", this.getDataToSend()).then(
          () => {
            this.dataStatus = "saved";
            this.currentDate = DateTime.fromISO(this.currentDate)
              .plus({ days: 1 })
              .toISODate();
          },
          (error) => {
            console.error("save days error", error);
            this.dataStatus = "normal";
            this.$bvModal.msgBoxOk("Não foi possível salvar os dados. Verifique se todos os dados estão corretos", {
              title: "Erro!",
            });
          }
        );
      });
    },
  },
  components: {
    insertIrrigRow,
    insertHumidityRow,
    // insertRainRow,
    insertWeatherRow,
    insertPluvioRow,
  },
};
</script>

<style lang="scss" scoped>
$th-color: rgb(128, 136, 138);

:deep(.insert-table) {
  font-size: 13px;

  .insert-equip {
    padding-top: 15px;
  }

  th {
    border-bottom: 0;
    border-top: 0;
    color: rgb(255, 255, 255);
    background-color: $th-color;
  }

  .id-r-line {
    border-right: 3px solid $th-color;
  }

  .field-label {
    padding-left: 20px;
    // min-width: 30%;
  }
}

.id-color-b1 {
  background-color: rgb(137, 163, 175);
}

.id-color-b2 {
  background-color: rgb(182, 197, 204);
}

.insert-page {
  padding-top: 30px;
  padding-bottom: 10px;

  .header {
    margin: 0 0 20px;

    small {
      color: #666;
    }
  }
}

.fit-tabs {
  padding: 1px 1px;
}



.insert-col{
  min-width: 80px;
}

</style>
