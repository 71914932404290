<template lang="html">
  <!-- <svg  :width="width" :height="height" viewBox="0 0 100 100" preserveAspectRatio="none"> -->
  <div class="svg">
    <svg width="100%" height="100%">
      <g v-for="(item, index) in normalizedData" :key="index">
        <rect
          :x="index * xScale"
          :y="0"
          :width="xScale - 1"
          :height="height - 10"
          style="fill:rgb(230,230,230);"
        >
          <title>{{ index }}h - {{ item.value }}mm</title>
        </rect>

        <rect
          :x="index * xScale"
          :y="height - 10 - item.height"
          :width="xScale - 1"
          :height="item.height"
          style="fill:rgb(110,150,212);"
        >
          <title>{{ index }}h - {{ item.value }}mm</title>
        </rect>

        <text
          v-if="index % 6 === 0"
          :x="index * xScale"
          :y="height"
          font-family="Verdana"
          font-size="12"
          style="fill:#000;"
        >
          {{ index }}h
        </text>
      </g>
    </svg>
  </div>
</template>

<script>
import { addListener, removeListener } from "resize-detector";

export default {
  data() {
    return {
      width: 100,
      height: 100,
    };
  },
  props: {
    data: { type: Array, required: true },
  },
  methods: {
    resizeCallback() {
      // console.log("resize");
      //let width = element.offsetWidth;
      //let height = element.offsetHeight;
      //console.log("Size: " + width + "x" + height);
    },
    setSize() {
      if (this.$el.clientWidth > 0) {
        this.width = this.$el.clientWidth;
        this.height = this.$el.clientHeight;
      }
    },
  },
  computed: {
    normalizedData() {
      let max = Math.max.apply(null, this.data);
      if (max === 0) max = 1;
      let scalar = (this.height - 10) / max;
      return this.data.map(function(x) {
        return { height: x * scalar, value: x };
      });
    },
    xScale() {
      return this.width / 24;
    },
  },
  mounted() {
    this.setSize();
    addListener(this.$el, () => {
     this.setSize();
    });
  },

  beforeUnmount() {
    removeListener(this.$el, () => {});
  },
};
</script>

<style lang="scss" scoped>
.svg {
  width: 100%;
  height: 100%;
}
</style>
