/* eslint-disable no-unused-vars */
import ImageCanvas from "ol/source/ImageCanvas";
import { assign } from "ol/obj.js";
import Color from 'color'
import { generateMatrix, generateImage, getExtentArea, posTransform } from "./IDWMatrix"
import Conrec from "./conrec.js"
import * as d3 from 'd3';

var Property = {
  PIXEL_SIZE: 4,
  maxNeighbours: 12,
  POWER: 2,
  GRADIENT: "gradient",
  RADIUS: "radius",
  LEGENDS: false,
  NAMES: false,
  CONTOURLINE: false
};

var DEFAULT_GRADIENT = [
  "#afe0",
  "#0ff6",
  "#0bf8",
  "#00fa",
  "#008c",
  "#103f",
];


function inside(point, vs) {
  // ray-casting algorithm based on
  // https://wrf.ecse.rpi.edu/Research/Short_Notes/pnpoly.html
  
  var x = point[0], y = point[1];
  
  var inside = false;
  for (var i = 0, j = vs.length - 1; i < vs.length; j = i++) {
      var xi = vs[i][0], yi = vs[i][1];
      var xj = vs[j][0], yj = vs[j][1];
      
      var intersect = ((yi > y) != (yj > y))
          && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
      if (intersect) inside = !inside;
  }
  
  return inside;
}


function getMaxMinValue(points_, minMax = [Infinity, -Infinity]) {
  if (points_) {
    points_.forEach((source) => {
      if (source.val < minMax[0]) minMax[0] = source.val;
      if (source.val > minMax[1]) minMax[1] = source.val;
    });
  }
  return minMax
}

var IdwSource = /** @class */ (function (ImageCanvas) {
  function IdwSource(opt_options) {

    var options = opt_options ? opt_options : /** @type {Options} */ ({});
    var baseOptions = assign({}, options);
    // delete baseOptions.style;
    // delete baseOptions.renderBuffer;
    // delete baseOptions.updateWhileAnimating;
    // delete baseOptions.updateWhileInteracting;
    ImageCanvas.call(this, baseOptions) || this;

    this.canvas = document.createElement("canvas");
    this.imageCanvas_ = document.createElement("canvas");
    this.matrix = undefined;
    this.imageProperties = undefined


    this.minMaxValueConf = [Infinity, -Infinity]

    this.gradient = baseOptions.gradient ? baseOptions.gradient : DEFAULT_GRADIENT;
    this.power = baseOptions.power ? baseOptions.power : Property.POWER;
    this.pixelSize = baseOptions.pixelSize ? baseOptions.pixelSize : Property.PIXEL_SIZE;
    this.showLegends = baseOptions.legends ? baseOptions.legends : Property.LEGENDS;
    this.showNames = baseOptions.names ? baseOptions.names : Property.NAMES;
    this.contourLine = baseOptions.contourLine ? baseOptions.contourLine : Property.CONTOURLINE;
    this.alphaColor = baseOptions.alphaColor ? baseOptions.alphaColor : undefined;
    this.onStartRender = baseOptions.onStartRender ? baseOptions.onStartRender : function () { };
    this.onEndRender = baseOptions.onEndRender ? baseOptions.onEndRender : function () { };

    this.recalcule = true

    if (baseOptions.minValue !== undefined) { this.minMaxValueConf[0] = baseOptions.minValue }
    if (baseOptions.maxValue !== undefined) { this.minMaxValueConf[1] = baseOptions.maxValue }


    if (baseOptions.area) {
      this.geometricArea_ = baseOptions.area;
      this.extent = getExtentArea(this.geometricArea_);
    }


    if (baseOptions.points) {
      this.points = baseOptions.points
      this.minMaxValue = getMaxMinValue(this.points, this.minMaxValueConf)
    }


    // cria array de cores RGBA
    this.cols = [];
    for (let i = 0; i < this.gradient.length; i++) {
      this.cols[i] = Color(this.gradient[i]).array()
      if (this.cols[i][3] === undefined) {
        this.cols[i][3] = 1
      }
      this.cols[i][3] = (this.cols[i][3] * 255) >> 0
    }


    /**
     *
     * @param {*} extent
     * @param {*} resolution
     * @param {*} pixelRatio
     * @param {*} size
     * @param {*} projection
     */
    this.canvasFunction_ = function (
      extent,
      resolution,
      pixelRatio,
      size,
      projection
    ) {


      this.onStartRender();
      this.canvas.width = size[0];
      this.canvas.height = size[1];
      let pixelSize = (this.pixelSize * pixelRatio) >> 0;


      let width = size[0] >> 0;
      let height = size[1] >> 0;

      // console.log("width",width)

      let maxX = -Infinity;
      let maxY = -Infinity;
      let minX = Infinity;
      let minY = Infinity;


      // cria o contorno da área e define os pontos máximos e mínimos  --
      let geometricArea = [];
      if (this.geometricArea_) {
        this.geometricArea_.forEach((e) => {
          let v = [];
          e.forEach((e2) => {
            let pos = posTransform(extent, size, e2.slice(0, 2));
            if (maxX < pos[0]) maxX = pos[0];
            if (maxY < pos[1]) maxY = pos[1];
            if (minX > pos[0]) minX = pos[0];
            if (minY > pos[1]) minY = pos[1];
            v.push(pos);
          });
          geometricArea.push(v);
        });
      }

      // let extentArea =  getExtentArea(geometricArea)

      //---------------------------------------------------------------


      // if (maxX > width) maxX = width;
      // if (maxY > height) maxY = height;
      // if (minX < 0) minX = 0;
      // if (minY < 0) minY = 0;

      let xoffset = minX;
      let yoffset = minY;
      let img_sizex = (maxX - minX) >> 0;
      let img_sizey = (maxY - minY) >> 0;

     


      pixelSize = Math.round(pixelSize * img_sizex / 500.0)
      if (pixelSize < this.pixelSize) pixelSize = this.pixelSize;

      this.imageProperties = {xoffset,yoffset,img_sizex,img_sizey,extent,size,pixelSize}

      // this.imageCanvas_.width = width;
      // this.imageCanvas_.height = height;

      this.canvas.width = width;
      this.canvas.height = height;
      let ctx = this.canvas.getContext("2d");
      ctx.save();
      ctx.fillStyle = "transparent";
      ctx.fillRect(0, 0, width, height);


      if (img_sizex > pixelSize && img_sizey > pixelSize && this.points.length > 1) {

        let points = [];
        this.points.forEach((e) => {
          points.push({
            destpos: posTransform(extent, size, e.pos.slice(0, 2)),
            val: e.val,
            name: e.name
          });
        });



        let numColors = 20
        let zs = []
        for (let i = 0; i < numColors; i++) {
          let normalized = i / (numColors - 1);
          let value = (this.minMaxValue[1] * normalized + this.minMaxValue[0] * (1 - normalized))
          if (value >= 100) value = value.toFixed(0);
          else if (value >= 10) value = value.toFixed(1);
          else value = value.toFixed(2);
          zs.push(value);
        }


        ///-------------------------------------------

        //if(this.recalcule){
        // console.log("calculate")
        // this.recalcule = false;
        let matrix = generateMatrix(points, img_sizex, img_sizey, pixelSize, this.power, xoffset, yoffset)
        this.matrix = matrix

        generateImage(this.imageCanvas_, img_sizex, img_sizey, pixelSize, matrix, this.minMaxValue[1], this.minMaxValue[0], this.cols, this.alphaColor)
        //}
        ////////////////////////

       




        // cria a máscara de contorno na área ---------------------------

        ctx.beginPath();
        geometricArea.forEach((e) => {
          let lastPos = undefined;
          e.forEach((pos) => {
            if (lastPos) {
              // ctx.moveTo(lastPos[0],lastPos[1]);
              ctx.lineTo(pos[0], pos[1]);
            } else {
              ctx.moveTo(pos[0], pos[1]);
            }
            lastPos = pos;
          });
        });
        ctx.closePath();
        ctx.clip();
        //--------------------------------------------------------------

        ctx.drawImage(this.imageCanvas_, xoffset, yoffset, img_sizex, img_sizey);


        if (this.contourLine) {
          //curvas de nível
          var c = new Conrec();
          let wx = matrix.length - 1
          let wy = matrix[0].length - 1
          let xs = d3.range(0, wx)
          let ys = d3.range(0, wy)

          c.contour(matrix, 0, wx, 0, wy, xs, ys, zs.length, zs);
          let contourList = c.contourList()
          ctx.strokeStyle = "#1118"
          ctx.fillStyle = "#000"
          ctx.lineWidth = 1
          let fontsize = 12 * pixelRatio;
          ctx.font = (fontsize | 0) + "px Arial";


          contourList.forEach((line) => {
            ctx.beginPath();
            let lastPos = undefined;
            let dist = 0;
            let l = line.length

            line.forEach((pos) => {

              let px = pos.x * pixelSize
              let py = pos.y * pixelSize

              if (dist == 0) {
                ctx.fillText(line.level, px + xoffset, py + yoffset);
              }

              if (lastPos) {

                let lpx = lastPos.x * pixelSize
                let lpy = lastPos.y * pixelSize
                ctx.lineTo(px + xoffset, py + yoffset);
                dist += Math.sqrt((lpx - px) * (lpx - px) + (lpy - py) * (lpy - py))

              } else {
                ctx.moveTo(px + xoffset, py + yoffset);
              }


              if (dist > 500)
                dist = 0


              lastPos = pos;
            });
            ctx.stroke();
          });
        }

        ctx.restore();



        if (this.showLegends) {
          points.forEach((dest) => {
            let value = isNaN(dest.val) ? 0 : dest.val
            let fixed = 2;
            if (value >= 10) fixed = 1;
            if (value >= 100) fixed = 0;
            value = value.toFixed(fixed);

            let fontsize = 12 * pixelRatio;
            ctx.font = (fontsize | 0) + "px Arial";

            if (this.showNames) {
              ctx.fillStyle = "#000"
              ctx.fillText(dest.name, dest.destpos[0] + 1, dest.destpos[1] - 14);
              ctx.fillStyle = "#f85"
              ctx.fillText(dest.name, dest.destpos[0], dest.destpos[1] - 15);
            }

            ctx.fillStyle = "#000"
            ctx.fillText(value + " mm", dest.destpos[0] + 1, dest.destpos[1] + 1);
            ctx.fillStyle = "#fa3"
            ctx.fillText(value + " mm", dest.destpos[0], dest.destpos[1]);
          });
        }
      }



      this.onEndRender();
      return this.canvas;
    };


    this.getValue = function(coordinate){



      let isInside = false
      if (this.geometricArea_) {
        this.geometricArea_.forEach((e) => {
          if(inside(coordinate,e)){
            isInside = true
          }
        })
      }

      if (!isInside)
        return undefined

      let pos = posTransform(this.imageProperties.extent, this.imageProperties.size, coordinate);
      let x = pos[0] - this.imageProperties.xoffset
      let y = pos[1] - this.imageProperties.yoffset
      let pixelSize = this.imageProperties.pixelSize

      if((x> 0 && x<   this.imageProperties.img_sizex)&&
      (y> 0 && y<   this.imageProperties.img_sizey)){

        let xindex = (x/this.imageProperties.pixelSize)>>0 
        let yindex = (y/this.imageProperties.pixelSize)>>0 
        return {coordinate:coordinate, value:this.matrix[xindex][yindex]}
      }
       
       return undefined
    };

  }

  if (ImageCanvas) IdwSource.__proto__ = ImageCanvas;
  IdwSource.prototype = Object.create(ImageCanvas && ImageCanvas.prototype);
  IdwSource.prototype.constructor = IdwSource;

  return IdwSource;
})(ImageCanvas);

export default IdwSource;
