<template lang="html">

    <sensorWidget :sensor="sensor" :color="'#b7dad7'">
      <template v-slot:icon>
          <img src="./assets/station.png" height="80px" alt=""/>
      </template>   

      <template v-slot:content>
          <station-info :sensor="sensor"/>
      </template>
    </sensorWidget>

</template>

<script>
    import stationInfo from './stationInfo'
    import station from './station'
    import sensorWidget from '../sensorWidget.vue'


    export default {
      extends:station,
      components:{
        'station-info':stationInfo,
        sensorWidget
      }
    }
</script>

