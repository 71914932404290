import SockJS from "sockjs-client";
import Stomp from "webstomp-client";



export default function(addr, connectCallBack=function(){}, disconnectCallback=function(){}) {
  //console.log("create sensor socket");
  var recInterval = null;
  var socket = null;
  var stompClient = null;
  // var clientId_ = undefined;
  var connected = false;

  var subscribeList =new Map();

  // var self = this;

  /**cria uma nova conexão */
  const newConection = function(){


    if(socket){
      socket.close();
    }

    // console.log("createConnection");
    connected = false;
    socket = new SockJS(addr);
    stompClient = Stomp.over(socket);
   
    // stompClient.reconnect_delay = 5000;
    stompClient.debug = () => {};


    // eslint-disable-next-line no-unused-vars
    for (const [key,value] of subscribeList.entries()) {
      // console.log("disconect",key)
      if(value.subs){
        // console.log("@diconect")
        value.subs.unsubscribe();
        value.subs = undefined;
      }
    }

    /**
     * Callback de Stomp Conectado
     * @param {*} frame 
     */
    // eslint-disable-next-line no-unused-vars
    let stompConnectCallback = function(frame) {
      // console.log("stomp connected")
      connected = true;
      clearInterval(recInterval);

      for (const [key, value] of subscribeList.entries()) {
        // console.log("connect",key)
        if(!value.subs){
          // console.log("@subscribe")
          value.subs = stompClient.subscribe(key, value.function);
        }
      }

      connectCallBack();
      

    };

    /******
     * callback de Stomp disconectado
     */
    let stompDisconnectCallback = function() {
      
      if(connected)
        disconnectCallback();
        
      connected = false;
      
      // console.log("stomp Disconnect");
      
      
      recInterval = setTimeout(function () {
        console.log("retry connection")
        newConection();
      }, 10000);

     
    };



    let header = {
      origin: true,
      credentials: false,
    };
    
    /**
    * conecta o stomp
    */
    stompClient.connect(header, stompConnectCallback, stompDisconnectCallback);

     

    // /**
    //  * Callback de socket conectado 
    //  */
    // socket.onopen = function () {
    //   console.log("socket open")
    //   // clearInterval(recInterval);
    // };  

    //  /**
    //  * Callback de socket desconectado 
    //  */
    // socket.onclose = function () {    
    //   console.log("socket close")
    //     // recInterval = setInterval(function () {
    //     //   newConection();
    //     // }, 2000);
    // };

    // /**
    //  * Callback de socket error 
    //  */
    //  socket.onerror = function () {
    //   console.log("socket error")
    //   // clearInterval(recInterval);
    // }; 

  };

  


  /**
   * Conecta todos os topicos da lista
   * @param {*} topic 
   * @param {*} f 
   */
  this.subscribe = function(topic, f){
    // console.log("subscribe")
    let s = undefined;
    if(connected){
      // console.log("@subscribe")
      s = stompClient.subscribe(topic, f);
    }
    subscribeList.set(topic,{function:f, subs:s})
  }


  /**
   * desconecta de todos os topicos
   */
  this.unsubscribeAll = function(){
    // eslint-disable-next-line no-unused-vars
    for (const [key, value] of subscribeList.entries()) {
      // console.log("disconect",key)
      if(value.subs){
        // console.log("@diconect")
        value.subs.unsubscribe();
      }
    }
    subscribeList.clear();
  }

  this.connected = connected;

  newConection();


}
