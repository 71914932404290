<template>
  <b-modal ref="edit-modal" hide-footer scrollable @hidden="close" size="lg">
    <template v-slot:modal-header>
      <span>Editar</span>
    </template>
      <userEdit :user="user" v-on:update-user="updateUser($event)" v-on:editcancel="$router.push({name:'userList'})"/>
  </b-modal>
</template>

<script>
import userEdit from "./userEdit";

export default {
  data() {
    return {
      user: {},
      loadStatus: "loading"
    };
  },

  mounted() {
    this.$refs["edit-modal"].show();
  },

  components: {
    userEdit
  },

  methods: {
    close() {
      this.$router.push({ name: "userList" });
    },

    updateUser(user){
      this.$emit("update-user",user);
      this.$router({name:'userDetails',params:{id:user.id}})
    },
  }
};
</script>

<style>
</style>