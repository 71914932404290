<template>
  <div class="grant-body">
    <h5>Outorga</h5>
    <hr />
    <div v-for="(month, index) in months" :key="month.name">
      <b-row>
        <b-col>
          {{ month.name }}
        </b-col>
        <b-col>
          <b-input
            type="number"
            min="0"
            step="1"
            v-model="editable[index]"
            size="sm"
          />
        </b-col>
      </b-row>
    </div>

    <b-input-group class="mt-2">
      <b-input-group-prepend is-text> m³/dia </b-input-group-prepend>
      <!-- <b-form-input v-model="client.client"></b-form-input> -->
      <b-form-input
        v-model="diario"
        type="number"
        min="0"
        step="1"
      ></b-form-input>
      <b-input-group-append>
        <b-button @click="autoComplete()"
          >auto preencher
          </b-button>
      </b-input-group-append>
    </b-input-group>
  </div>
</template>

<script>
export default {
  data() {
    return {
      editable: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      months: [
        { name: "Janeiro", days: 31 },
        { name: "Fevereiro", days: 28 },
        { name: "Março", days: 31 },
        { name: "Abril", days: 30 },
        { name: "Maio", days: 31 },
        { name: "Junho", days: 30 },
        { name: "Julho", days: 31 },
        { name: "Agosto", days: 31 },
        { name: "Setembro", days: 30 },
        { name: "outubro", days: 31 },
        { name: "Novembro", days: 30 },
        { name: "Dezembro", days: 31 },
      ],
      diario:0
    };
  },
  props: {
    value: Array,
  },
  methods:{
    autoComplete(){
      let v = []
      for (let i =0; i<12;i++){
        v.push( this.months[i].days*this.diario)
      }

      this.editable = v

    }
  },
  beforeMount() {
    if (this.value) {
      this.value.forEach((e, index) => {
        this.editable[index] = e;
      });
    }
  },
  watch: {
    editable: {
      deep: true,
      handler(v) {
        this.$emit("input", v);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.grant-body {
  padding: 2px;
  // background-color: $indigo;
}
</style>
