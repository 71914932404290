<template>
  <tr  class="text-center" :style="{backgroundColor:fieldColor}">
    
    <!-- PARCELAS -->
    <td class="td-equipment" v-if="showEquipment===true" rowspan="10">
      <b-link  :to="{name:'equipmentDetails',params:{id:equipment.id}}">{{equipment.name}}</b-link>
    </td>
    <td>
      <img v-b-tooltip.hover :title="field.culture.name" class="culture-img" :src="require('@irrigcomponents/culture/assets/48/'+field.culture.icon+'.png')" />
      <!-- <small>{{field.version}}</small> -->
    </td>
    <td>
      <b-link :to="{name:'fieldDetails',params:{id:field.id}}">{{field.name}}</b-link> 
    </td>
    <td>{{$filters.round((field.area/10000),2)}}</td>
    <td>{{$filters.localeDate(field.datePlanting)}}</td>
    <td>{{field.status.numDays}}</td>


    <template v-if="!dataOk">
      <td colspan="10">
        <font-awesome-icon class="text-warning" icon="exclamation-triangle"/> 
        <span> Lançamentos Pendentes do dia {{$filters.localeDate(field.status.dateToInsert)}}. </span> 
        <b-link v-if="$can('edit','InsertData')" :to="{name:'insertData',params:{date:field.status.dateToInsert}}"> Lançar Dados </b-link>
      </td>
    </template>
    <template v-else>

     
      <td>{{$filters.round(field.status.etc,1)}}</td>
      <td>{{$filters.round(field.status.laa,1)}}</td>
      <td>{{$filters.round(field.status.las,1)}}</td>
      <td>{{$filters.round(field.status.moisture,1)}}</td>

      <td>{{$filters.round(field.status.irrig.itn,2)}}</td>
      <td>{{$filters.round(field.status.irrig.percent,0)}}</td>
      <td>{{$filters.decimalhourToTime(field.status.irrig.hour,'h ')}}</td>

      <td v-if="field.status.daysToStress===0">
         <font-awesome-icon class="text-danger" icon="exclamation-triangle"/> stress!
      </td>
      <td v-else-if="field.status.daysToStress === undefined">mais que 7</td>
      <td v-else>{{field.status.daysToStress}}</td>


      <td>{{$filters.round(field.status.itnNextDays,2)}}</td>

    </template>
  </tr>
</template>

<script>
import field from "../field/field";
export default {
  extends: field,

  props:{
    showEquipment:{type:Boolean, default:true}
  },

  computed: {
    fieldColor() {
      let color = "#fff";
      if (this.color != null) {
        color = this.color;
      }
      return color;
    }
  }
};
</script>

<style lang="scss" scoped>

.culture-img{
  height: 25px;
  background-color: rgba(255,255,255,0.5);
  border-radius: 30%;
}

  

</style>