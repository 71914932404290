<template>
  <div>
    <div class="d-flex flex-wrap mb-2">
       <h5 v-if="tableName!= undefined"> {{tableName}}</h5>
       <b-button
        v-if="!hideSaveButton"
        :disabled= "savingFile"
        class="ml-auto"
        title="baixar dados .xlsx"
        size="sm"
        variant="success"
        @click="download()"
        ><font-awesome-icon icon="file-excel" /> baixar
      </b-button>
    </div>

    <b-table
      :headVariant="headVariant"
      :small="small"
      responsive
      :stickyHeader="stickyHeader"
      :items="items"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
    >
    </b-table>
    

    <hr>
    <div  class="d-flex">
      <b-pagination
        class="ml-auto"
        responsive
        v-model="currentPage"
        :total-rows="numRows"
        :per-page="perPage"
        aria-controls="my-table"
      ></b-pagination>
     
    </div>
  </div>
</template>

<script>
import {tableExport} from "../mixins/exportTable.js" 

export default {

  mixins:[tableExport],
  data() {
    return {
      currentPage: 1,
    };
  },
  props: {
    headVariant:String,
    hideSaveButton:{type:Boolean,default:false},
    tableName:String,
    small:Boolean,
    stickyHeader:String,
    items:Array,
    fields:Array,
    name:String,
    perPage:{type:Number, default:10}
  },
  computed: {
    numRows() {
      if (this.items !== undefined) return this.items.length;
      else return 0;
    },
  }
};
</script>
