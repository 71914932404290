import channelsList from "./channelsList";
import channelsPageList from "./channelsPageList";
import channelPageDetails from "./channelPageDetails";
import feedPageDetails from "./feedPageDetails";
import sensorEdit from "../sensors/sensorEdit";
import { Role } from "@/helpers/role";

export default {
  path: "/channel",
  meta: { authorize: [Role.Admin] },
  component: channelsList,
  children: [
    {
      path: "",
      component: channelsPageList,
      name: "channelList",
    },
    {
      path: ":id",
      props: (route) => ({ channelId: route.params.id }),
      component: channelPageDetails,
      name: "channelDetails",
      children: [
        {
          path: "feed/:feedId",
          name: "feedDetails",
          props: (route) => ({ feedId: route.params.feedId }),
          component: feedPageDetails,
        },
        {
          path: "feed/:feedId/sensor/new",
          name: "sensorNew",
          component: sensorEdit,
          props: (route) => ({
            feedId: route.params.feedId,
          }),
        },
        {
          path: "feed/:feedId/sensor/:sensorId",
          name: "sensorEdit",
          component: sensorEdit,
          props: (route) => ({
            sensorId: route.params.sensorId,
            feedId: route.params.feedId,
          }),
        },
      ],
    }
  ]
};
