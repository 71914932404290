<template>
  <div class="legend">
    <div class="legend-color">
      <div
        v-for="(color, index) in fieldColors.blue"
        :key="index"
        class="color size-100"
        :style="{ backgroundColor: color }"
      ></div>
      <div class="text">CC</div>
    </div>
    <div class="legend-color">
      <div
        v-for="(color, index) in fieldColors.green"
        :key="index"
        class="color size-25"
        :style="{ backgroundColor: color }"
      ></div>
      <div class="text">umidade</div>
    </div>
    <div class="legend-color">
      <div
        v-for="(color, index) in fieldColors.orange"
        :key="index"
        class="color size-25"
        :style="{ backgroundColor: color }"
      ></div>
      <div class="text">estresse</div>
    </div>
  </div>
</template>

<script>
import {
  f_color_b,
  f_color_g,
  f_color_o,
} from "@irrigcomponents/field/fieldColors.js";

export default {
  data() {
    return {
      fieldColors: {
        blue: f_color_b,
        green: f_color_g,
        orange: f_color_o,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.legend {
  height: 1em;

  line-height: 0;
  .size-25 {
    width: 25%;
  }

  // border-bottom: 1px solid black;

  .legend-color {
    display: inline-block;
    text-align: center;
    width: 33.33%;
    border-right: solid 1px black;
    position: relative;
    .size-100 {
      width: 100%;
    }
    .text {
      user-select: none;
      cursor: pointer;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      line-height: 11px;
      font-size: 12px;
      &:hover{
        color: transparent;
      }
    }
    .color {
      line-height: 0;
      display: inline-block;
      height: 10px;
      border-right: solid 1px rgba(0, 0, 0, 0.2);
    }
  }
}
</style>
