<template>
  <list-page
    :loadStatus="loadStatus"
    :numRows="numRows"
    title="Pluviômetros"
    subTitle="Lista de pluviômetros usados no manejo de irrigação."
    @load-retry="loadData()"
  >

  <!-- :backgroundImage="require('./assets/background.jpg')" -->
    <template v-slot:button>
      <b-button
        v-if="$can('create', 'Pluvio')"
        class="shadow-sm"
        pill
        @click="newPluvio"
        variant="success"
        >+ novo</b-button
      >
    </template>

    <template v-slot:list="props">
      <b-table
        small
        :items="items"
        :fields="fields"
        :filter="props.filter"
        :current-page="props.currentPage"
        :per-page="props.perPage"
        @filtered="onFiltered($event)"
      >
        <template v-slot:cell(details)="row">
          <b-link @click="row.toggleDetails">
            <font-awesome-icon v-if="row.detailsShowing" icon="chevron-up" />
            <font-awesome-icon v-else icon="chevron-down" />
          </b-link>
        </template>

        <template v-if="$can('edit', 'Pluvio')" v-slot:cell(action)="row">
          <b-button-group size="sm">
            <b-button variant="primary" @click="editPluvio(row.item.id)"
              ><font-awesome-icon icon="edit"
            /></b-button>
            <b-button variant="danger" @click="removePluvio(row.item.id)">
              <font-awesome-icon icon="trash"
            /></b-button>
          </b-button-group>
        </template>
      </b-table>

      <!-- EDITAR -->
      <b-modal ref="edit-modal" scrollable @hidden="close" size="lg">
        <template v-slot:modal-header>
          <span>
            Editar
            <strong>{{ form.name }}</strong>
          </span>
        </template>

        <validation-observer ref="formObserver">
          <b-row>
            <!-- nome dO pluviômero-->
            <ValidationProvider
              slim
              vid="name"
              name="Nome"
              :rules="{ required: true, min: 3 }"
              v-slot="v"
            >
              <b-form-group label="Nome" class="col-12">
                <b-form-input
                  name="name"
                  v-model="form.name"
                  type="text"
                  maxlength="30"
                  placeholder="nome"
                  :state="$validateState(v)"
                ></b-form-input>
                <span class="invalid-input">{{ v.errors[0] }}</span>
              </b-form-group>
            </ValidationProvider>
          </b-row>
        </validation-observer>

        <template v-slot:modal-footer>
          <b-button @click="submit" variant="primary">Salvar</b-button>
          <b-button @click="cancel">Cancelar</b-button>
        </template>
      </b-modal>
    </template>
  </list-page>
</template>

<script>


export default {
  data() {
    return {
      form: undefined,
      pluvioList: [],
      loadStatus: "loading",
      numRows: 1,
      fields: [
        { key: "details", label: "", sortable: false },
        { key: "name", label: "Nome", sortable: true },
        // { key: 'classification',label: 'Classificação', sortable: true ,class:"d-none d-sm-table-cell"},
        // { key: 'layers',        label: 'Camadas', formatter:layersFormat, sortable: true},
        // { key: 'description',   label: 'Descrição',     sortable: false, class:"d-none d-sm-table-cell" },
        { key: "action", label: "", sortable: false },
      ],
    };
  },
  props: {
    editable: { type: Boolean, default: true },
  },
  beforeMount() {
    this.loadData();
  },
  computed: {
    items() {
      let list = [];
      this.pluvioList.forEach((v) => {
        list.push({
          id: v.id,
          name: v.name,
          // description:v.description,
          // classification:v.classification,
          // layers:v.layers
        });
      });
      return list;
    },
  },
  methods: {
    onFiltered(list) {
      this.numRows = list.length;
    },
    loadData() {
      this.loadStatus = "loading";
      this.$http.get("irrig/pluviometer").then(
        (data) => {
          this.pluvioList = data;
          this.loadStatus = "loaded";
          this.numRows = data.length;
        },
        (error) => {
          console.error("soil loading error:", error);
          this.loadStatus = "error";
        }
      );
    },
    //atualiza os valores do solo da lista a partir de um novo solo
    updateList(newPluvio) {
      const index = this.pluvioList.findIndex((e) => e.id === newPluvio.id);
      if (index === -1) {
        this.pluvioList.push(newPluvio);
        this.numRows = this.pluvioList.length;
      } else {
        let dataObj = this.pluvioList[index];
        Object.entries(newPluvio).forEach(([key, value]) => {
          dataObj[key] = value;
        });
      }
    },

    removePluvio(id) {
      this.$bvModal
        .msgBoxConfirm(
          "Deseja realmente apagar este registro permanentemente?",
          {
            title: "Por favor confirme",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "Sim",
            cancelTitle: "Não",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value === true) {
            this.$http.delete("irrig/pluviometer/" + id).then(
              () => {
                let index = this.pluvioList.findIndex((e) => e.id === id);
                this.pluvioList.splice(index, 1);
                this.numRows = this.pluvioList.length;
              },
              () => {
                //API error
              }
            );
          }
        })
        .catch(() => {
          // An error occurred
        });
    },

    cancel() {
      this.$refs["edit-modal"].hide();
    },

    close() {
      this.$refs["edit-modal"].hide();
    },

    editPluvio(id) {
      let pluvio = this.pluvioList.find((ele) => id === ele.id);

      if (pluvio) {
        this.form = {
          id: pluvio.id,
          name: pluvio.name,
        };
        this.$refs["edit-modal"].show();
      }
    },

    newPluvio() {
      this.form = {
        id: undefined,
        name: "novo pluviômetro",
      };
      this.$refs["edit-modal"].show();
    },

    submit() {
      this.$refs.formObserver.validate().then((result) => {
        if (!result) {
          return;
        }
        this.$http.save("irrig/pluviometer", this.form, this.form.id).then(
          (data) => {
            this.updateList(data);
            this.$refs["edit-modal"].hide();
          },
          (error) => {
            this.$refs.formObserver.setErrors(this.$validServer(error));
            this.$bvModal.msgBoxOk(
              "Verifique se todos os dados estão corretos",
              {
                title: "Erro!",
              }
            );
          }
        );
      });
    },
  },
};
</script>

