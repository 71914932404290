<template lang="html">

  <div class="soilInfo">

    <div class="widget-box-header">
      <span class="name">{{sensor.name}}</span>
      <span class="time">
        <font-awesome-icon icon="clock"/> {{$filters.secondsToStr(elapsedTime)}}
      </span>
    </div>


    <div class="widget-box-value" v-for="(layer, index) in layers" :key="index">
      <span class="label">{{layer.depth}} cm</span>
      <span class="value">
        {{ $filters.round(layer.value,2)}}<small> {{sensor.unit}} </small>
      </span>

      <div class="progress">
        <div class="progress-bar" :style="{width: $options.filters.barValue(layer.value)}"></div>
      </div>
    </div>

  </div>

</template>

<script>

import sensSoil from './sensSoil.js'

export default {
  extends:sensSoil,
}
</script>

<style lang="scss" scoped>

.soilInfo{
  min-width: 200px;

  .progress {
      background: rgba(0,0,0,0.2);
      margin: 2px -2px 5px -2px;
      height: 4px;

      .progress-bar {
          background: #00a65a;
          float: left;
      }
  }
}
</style>
