import { render, staticRenderFns } from "./channelPageDetails.vue?vue&type=template&id=42fb2a95&scoped=true"
import script from "./channelPageDetails.vue?vue&type=script&lang=js"
export * from "./channelPageDetails.vue?vue&type=script&lang=js"
import style0 from "./channelPageDetails.vue?vue&type=style&index=0&id=42fb2a95&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42fb2a95",
  null
  
)

export default component.exports