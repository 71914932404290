<template>
  <list-page
    :loadStatus="'loaded'"
    :numRows="numRows"
    title="Canais"
    subTitle="Lista de canais de comunicação" 
  >
  <!-- :backgroundImage="require('./assets/background.jpg')" -->
    <template v-slot:button>
      <b-button
        v-if="$can('create', 'Channel')"
        class="shadow-sm"
        pill
        @click="newChannel"
        variant="success"
        >+novo</b-button
      >
    </template>

    <template v-slot:list="props">
      <!-- LISTA -->
      <b-table
        small
        :items="channelList"
        :fields="fields"
        :filter="props.filter"
        :current-page="props.currentPage"
        :per-page="props.perPage"
        @filtered="filtered"
      >
        <template v-slot:cell(id)="row">
          <code> {{ row.item.id }}</code>
        </template>

        <template v-slot:cell(name)="row">
          <b-link
            :to="{ name: 'channelDetails', params: { id: row.item.id } }"
            >{{ row.item.name }}</b-link
          >
        </template>

        <template v-slot:cell(status)="row">
          <b-badge v-if="row.item.online" variant="success">
            online
          </b-badge>
          <b-badge v-else variant="danger"> offline </b-badge>
        </template>

        <!-- <template v-if="$can('edit', 'Channel')" v-slot:cell(action)="row">
            <b-button-group size="sm">
              <b-button
                v-b-tooltip.hover
                title="Editar"
                variant="primary"
                @click="$emit('edit:channel', row.item.id)"
                ><font-awesome-icon icon="edit"
              /></b-button>
              <b-button
                v-b-tooltip.hover
                title="Remover"
                variant="danger"
                @click="$emit('remove:channel', row.item.id)"
              >
                <font-awesome-icon icon="trash"
              /></b-button>
            </b-button-group>
          </template> -->
      </b-table>
    </template>
  </list-page>
</template>

<script>
export default {
  data() {
    return {
      numRows: 1,
      fields: [
        { key: "name", label: "Nome", sortable: true },
        { key: "id", label: "key", sortable: false },
        { key: "status", label: "status", sortable: false },
        { key: "lastSyncSeconds", label: "Last update", sortable: true, formatter:this.$filters.secondsToStr },
        // { key: "action", label: "", sortable: false },
      ],
    };
  },
  props: {
    channelList: Array,
  },

  mounted() {
    this.numRows = this.channelList.length;
  },

  methods: {
    filtered(filteredItems) {
      this.numRows = filteredItems.length;
    },

    newChannel() {
      this.$http
        .post("/sens/channel", {
          name: "novo canal " + this.channelList.length,
          zoneIdStr: "UTC",
        })
        .then(
          (data) => {
            this.$emit("update", data);
            this.$router.push({
              name: "channelDetails",
              params: { id: data.id },
            });
          },
          (error) => {
            console.error("create channel error", error);
          }
        );
    },
  },
};
</script>

<style></style>
