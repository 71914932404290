<template>
  <div>
    <ul class="irrig-timeline" >
      <li v-for="(item, index) in timeLineItens" :key="index" v-bind:class="item.continue?'continue':''">

        <div class="timeline-irrig" ><span>{{ $filters.decimalhourToTime((item.object.totalSeconds / 3600),"h") }}</span></div>

         <div v-bind:class="item.isSequence?'timeline-sequence':'timeline-end'">
          <span class="time">{{ $filters.localeTime(item.to) }}</span>
          <span class="date">{{ $filters.localeDate(item.to) }}</span>
          <span class="icon" v-if="item.isSequence">  <font-awesome-icon  icon="angle-double-up"/> </span>
          <span class="icon" v-else>  <font-awesome-icon  icon="stop-circle"/> </span>
        </div>

        <div  v-if="!item.continue" class="timeline-start">
          <span class="time">{{ $filters.localeTime(item.from) }}</span>
          <span class="date">{{ $filters.localeDate(item.from) }}</span>
          <span class="icon"> <font-awesome-icon icon="chevron-circle-up"/> </span>
        </div>

       


        <div class="timeline-body">
          <irrigListDetails
            :irrig="item.object"
            :startAngle="startAngle"
            :areaStart="areaStart"
            :areaEnd="areaEnd"
          />
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import irrigListDetails from "./irriListDetails.vue";
import { DateTime } from "luxon";

export default {
  data() {
    return {
      perPage: 10,
      currentPage: 1,
    };
  },

  props: {
    irrigations: Array,
    startAngle: { type: Number, default: 0 },
    areaStart: { type: Number, default: 0 },
    areaEnd: { type: Number, default: 360 },
  },

  computed: {
    timeLineItens() {
      let last = null
      return this.irrigations.map(function(e) {
       
        let isSequence = false
        if(last!= null && e.endDateTime){
          const date1 = DateTime.fromISO(last.from)
          const date2 = DateTime.fromISO(e.endDateTime)
          const diff = date1.diff(date2, ["minutes"])
          if(diff.minutes < 5){
            last.continue = true
            isSequence = true;
          }
        }

        last = {
          continue:false,
          isSequence:isSequence,
          from: e.startDateTime,
          to: e.endDateTime,
          object: e,
        };

        return last;

      
      });
    },

    numRows() {
      if (this.irrigations !== undefined) return this.irrigations.length;
      else return 0;
    },
  },
  components: {
    irrigListDetails,
  },
};
</script>

<style lang="scss" scoped>
$lineColor: #c4c1c1;
$irrigColor: #5ba9d6;

$left-margin : 100px;

.irrig-timeline {
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 5px;
    bottom: 5px;
    width: 6px;
    background: $lineColor;
    left: ($left-margin - 16px);
    margin-left: -2.5px;
  }

  > li {
    position: relative;
    min-height: 50px;
    padding: 20px 0;
  }

  .timeline-start,
  .timeline-end,
  .timeline-sequence {
    position: absolute;
    left: 0;
    width: ($left-margin - 30px);
    text-align: right;

    .date,
    .time {
      display: block;
      font-weight: 600;
    }

    .date {
      line-height: 16px;
      font-size: 12px;
    }

    .time {
      line-height: 24px;
      font-size: 20px;
      color: #242a30;
    }

    .icon {
      content: "";
      color:white;
      position: absolute;
      display: block;
      right: -24px;
      top: 5px;
      width: 20px;
      height: 20px;
      background: $irrigColor;
      border-radius: 20px;
      // border: 5px solid $irrigColor;
      text-align: center;

        line-height: 22px;
        font-size: 12px;
    }
  }

  .timeline-start {
    bottom: 20px;
  }

  .timeline-end {
    top: 20px;
  }
  .timeline-sequence {
    top: 0px;
  }


  .timeline-irrig {
    position: absolute;
    top: 45px;
    bottom: 50px;
    left: ($left-margin - 16px);
    color: black;
    text-align: center;
    content: "";

    width: 6px;
    background: $irrigColor;
    margin-left: -2.5px;

    span{
      color:#555;
      position:absolute;
      top:40%;
      right: 10px;
    }


  }

  .timeline-body {
    margin-left: $left-margin;
    margin-right: 0;
    background: #fff;
    position: relative;
    padding: 5px 5px;
    border-radius: 6px;
    box-shadow: 2px 2px 6px rgba($color: #000000, $alpha: 0.5);

    // &:before {
    //   content: "";
    //   display: block;
    //   position: absolute;
    //   border: 10px solid transparent;
    //   border-right-color: #fff;
    //   left: -20px;
    //   top: 20px;
    // }
  }

  .continue{
    padding-bottom:0!important;
    
    &>.timeline-irrig{
        bottom: -45px!important;
    }

    
    // .timeline-start{
    //      bottom: 0px;
    // }

  }
}
</style>
