<template>
  <div class="deltaT">

    <div class="d-flex justify-content-center mb-2">
      <div class="value-container shadow-sm" :class=" deltaTColor ">
          <b>DeltaT: </b> 
          <span>{{ $filters.localeNumber(deltaT,2) }}</span>

      </div>
    </div>
      

    <div class="chart">

      <svg class="point" viewBox="0 0 110 100">

        <!-- <g>
                  <rect
                    style="opacity:1;fill:#e66100;fill-opacity:1;stroke:#000000;stroke-width:0.33637;stroke-linecap:round;stroke-linejoin:round;stroke-dasharray:none"
                   
                    width="110"
                    height="100"
                    x="0"
                    y="0"
                    ry="0" />
                </g> -->

        <g>
          <circle class="point-symbol" id="position" :cx="px" :cy="py" r="2" />
        </g>
      </svg>

      <img style="width:100%" src="@/assets/deltaT.svg" alt="">

    </div>

    

    <div class="legend-container mt-4">
      <div class="legend-item">
        <div class="color-box cr1"></div>
        <span>Condições mais adequadas</span>
      </div>
      <div class="legend-item">
        <div class="color-box cr2"></div>
        <span>Condições marginais/limite</span>
      </div>
      <div class="legend-item">
        <div class="color-box cr3"></div>
        <span>Condições inadequadas para gotas médias, finas e muito finas</span>
      </div>
      <div class="legend-item">
        <div class="color-box cr4"></div>
        <span>Condições não recomendadas</span>
      </div>
    </div>


    <!-- <b-form-input type="range" v-model="temperature" min="0" max="40"></b-form-input> 
    <b-form-input type="range" v-model="humidity" min="10" max="100"></b-form-input> 

    {{ temperature }}
    {{ humidity }} -->


  </div>
</template>
<script>


// import {DeltaTChart} from "./chart.js";

const map = function (x, in_min, in_max, out_min, out_max) {
  return (x - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
}


function calcDeltaT(tempAr, umidadeRelativa) {
    // Cálculo da temperatura do bulbo úmido (Tbu) usando a fórmula de Stull
    let tempBulboUmido = tempAr * Math.atan(0.151977 * Math.sqrt(umidadeRelativa + 8.313659)) +
                         Math.atan(tempAr + umidadeRelativa) -
                         Math.atan(umidadeRelativa - 1.676331) +
                         0.00391838 * Math.pow(umidadeRelativa, 1.5) * Math.atan(0.023101 * umidadeRelativa) -
                         4.686035;

    // Cálculo do deltaT (diferença entre temperatura do ar e bulbo úmido)
    let deltaT = tempAr - tempBulboUmido;

    return deltaT;
}

export default {
  data() {
    return {
      chart: undefined,
      // humidity:40,
      // temperature:25
    };
  },

  props: {
    humidity: Number,
    temperature: Number,
    width: { type: [Number, String], default: '500px' },
    height: { type: [Number, String], default: '500px' }
  },


  mounted() {
    // this.chart = new DeltaTChart(this.$refs.canvas);
    // this.chart.startAngle = this.startAngle;
    // this.chart.data = this.data;
    // window.addEventListener('resize', this.handleResize)
  },

  computed: {
    px() {
      return map(this.temperature, 0, 40, 0, 110)
    },
    py() {
      return map(this.humidity, 10, 100, 100, 0)
    },
    deltaT(){
      return calcDeltaT(Number(this.temperature),Number(this.humidity))
    },
    deltaTColor(){
      if (this.deltaT < 1) return "cr3"
      else  if (this.deltaT >= 1 && this.deltaT < 2)  return "cr2"
      else  if (this.deltaT >= 2 && this.deltaT < 8)  return "cr1"
      else  if (this.deltaT >= 8 && this.deltaT < 10)  return "cr2"
      else  if (this.deltaT >= 10 && this.deltaT < 12)  return "cr3"
      else return "cr4"
        
    }
  }

}
</script>

<style lang="scss" scoped>
.deltaT {

  max-width: 500px;
  margin-left: 10px;


  .value-container{
    padding: 5px 10px;
    border: 1px solid black;
    font-size: larger;
    border-radius: 5px;
  }

  .chart {
    position: relative;
    width: 100%;


    .point {
      position: absolute;
      // border: 1px solid red;
      padding: 1% 20% 9% 12%;

      width: 100%;
      height: 100%;

      .point-symbol {
        opacity: 1;
        fill: #ffffff;
        fill-opacity: 1;
        stroke: #c01c28;
        stroke-width: 0.79375;
        stroke-linecap: round;
        stroke-linejoin: round;

        animation: blink 0.5s linear infinite;

      }
    }
  }

  .legend-container {
    display: flex;
    flex-direction: column;
    // max-width: 200px;
    border: 1px solid #000;
    padding: 10px;

    .legend-item {
      display: flex;
      align-items: center;
      margin-bottom: 5px;

      .color-box {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
    }
  }

  .cr1 {
      background-color: #26A269;
    }

    .cr2 {
      background-color: #E5A50A;
    }

    .cr3 {
      background-color: #E66100;
    }

    .cr4 {
      background-color: #C64600;
    }

}





@keyframes blink {
  0% {
    opacity: 0.3;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.3;
  }
}
</style>