var createItens = function(array, name, object, unit, color) {
    let price = object;
    let power = undefined;
    let rate = undefined;
    if (typeof object == "object") {
      price = object.price;
      power = object.power;
      rate = object.price / object.power;
    }
  
    if (price && price > 0) {
      array.push({
        name: name,
        unit: unit,
        color: color,
        price: price,
        rate: rate,
        power: power,
      });
    }
  };


export default function(composition){


    var array = [];

    createItens(
      array,
      "Demanda Ativa",
      composition.activeDemand,
      "kW"
    );
    createItens(
      array,
      "Ultrapassagem de Demanda",
      composition.exceedingDemand,
      "kW",
      "danger"
    );
    createItens(
      array,
      "Demanda Complementar",
      composition.complementaryDemand,
      "kW"
    );
    createItens(
      array,
      "Demanda Reativa Excedente",
      composition.surplusReactiveDemand,
      "kVARh"
    );

    composition.activeConsumption.forEach((element) => {
      createItens(
        array,
        "Consumo Ativo " + element.timeOfUse,
        element.consumption,
        "kWh",
        element.timeOfUse == "PONTA" ? "warning" : undefined
      );
    });

    composition.reactiveConsumption.forEach((element) => {
      createItens(
        array,
        "Consumo Reativo " + element.timeOfUse,
        element.consumption,
        "kVARh",
        element.timeOfUse == "PONTA" ? "warning" : undefined
      );
    });


    composition.injectedEnergy.forEach((element) => {
      createItens(
        array,
        "Energia Injetada " + element.timeOfUse,
        element.consumption,
        "kWh",
        "success",
      );
    });


    createItens(
      array,
      "Multas",
      composition.penalties,
      undefined,
      "danger"
    );

    createItens(
      array,
      "Acréscimos",
      composition.additions,
      undefined
    );

    createItens(array, "Impostos", composition.tax, undefined);

    createItens(
      array,
      "Descontos",
      composition.discounts,
      undefined,
      "success"
    );

    return array;

}