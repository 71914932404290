<template lang="">
     <footer class="footer d-flex py-2">
        <div class="pl-2 text-muted">
          Versão: {{ $appInfo.appversion }}
        </div>
        <div class="footer-copyright ml-auto  px-2">© 2024 Kalliandra:
        <a href="https://kalliandra.com.br" target="_blank"> kalliandra.com.br</a>
       
      </div>
    </footer>
</template>

<script>
export default {

}
</script>

<style lang="">

</style>