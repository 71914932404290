<template>
  <div>
    <div v-if="pageStatus == 'ready'">
      <p>
        Use o campo abaixo para modificar os dados do intervalo:
        <b>{{ $filters.localeDateTime(rangeselect.start) }}</b> até
        <b> {{ $filters.localeDateTime(rangeselect.end) }} </b>
      </p>

      <ValidationProvider
        slim
        vid="datastream.name"
        name="Nome"
        :rules="{ required: true}"
        v-slot="v"
        >

        <b-form-group label="Nome">
          <b-form-input
            name="formula"
            v-model="formula"
            type="text"
            maxlength="30"
            placeholder="EX: v1*2 + v2"
            :state="$validateState(v)"
          ></b-form-input>
          <span class="invalid-input">{{ v.errors[0] }}</span>
        </b-form-group>
      </ValidationProvider>

      <hr />
      <div class="d-flex">
        <b-button-toolbar class="mr-auto">
          <b-button @click="$emit('chartmode')" variant="secondary"
            >Cancelar</b-button
          >
          <b-button @click="submit()" variant="success">Salvar</b-button>
        </b-button-toolbar>
      </div>
    </div>

    <div v-else-if="pageStatus == 'saving'">
      salvando ...
    </div>

    <c-loading :status="pageStatus" v-else></c-loading>
  </div>
</template>

<script>
import { DateTime } from "luxon";

var msgBoxConfirmOptions = {
  title: "Por favor confirme",
  size: "sm",
  buttonSize: "sm",
  okVariant: "danger",
  okTitle: "Sim",
  cancelTitle: "Não",
  footerClass: "p-2",
  hideHeaderClose: false,
  centered: true,
};

export default {
  data() {
    return {
      pageStatus: "ready",
      formula: "",
    };
  },
  props: {
    dataStreamId: { type: Number, required: true },
    rangeselect: Object,
  },

  computed: {
    dateInterval() {
      return (
        DateTime.fromISO(this.rangeselect.start).toLocaleString(
          DateTime.DATETIME_SHORT_WITH_SECONDS
        ) +
        " até " +
        DateTime.fromISO(this.rangeselect.end).toLocaleString(
          DateTime.DATETIME_SHORT_WITH_SECONDS
        )
      );
    },
  },

  methods: {
    submit() {
      this.dataModifier(this.rangeselect, this.formula);
    },

    dataModifier(range, formula) {
      this.$bvModal
        .msgBoxConfirm(
          "Deseja realmente modificar os dados de " +
            DateTime.fromISO(range.start).toLocaleString(
              DateTime.DATETIME_SHORT_WITH_SECONDS
            ) +
            " até " +
            DateTime.fromISO(range.end).toLocaleString(
              DateTime.DATETIME_SHORT_WITH_SECONDS
            ) +
            " deste dataStream?",
          msgBoxConfirmOptions
        )
        .then((value) => {
          if (value === true) {
            this.$http
              .put(
                "/sens/dataStream/" +
                  this.dataStreamId +
                  "/dataPoints/operation" +
                  "?startTime=" +
                  range.start +
                  "&endTime=" +
                  range.end +
                  "&formula=" +
                  formula
              )
              .then(
                () => {
                  this.$emit("chartmode");
                },
                (error) => {
                  console.error("clear dataStream error", error);
                }
              );
          }
        });
    },
  },
};
</script>

<style></style>
