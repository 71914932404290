<template>
  <div class="ndvi-color-legend">
    <!-- <div class="color-item color-0"> Água </div>
    <div class="color-item"> Rochas, areia </div>
    <div class="color-item"> Baixo índice vegetativo </div>
    <div class="color-item"> Alto índice vegetativo </div> -->

     <div class="color-item">Escala NDVI </div>
    <div class="color-item color-10"> 1.0 </div>
    <div class="color-item color-09"> 0.9 </div>
    <div class="color-item color-08"> 0.8 </div>
    <div class="color-item color-07"> 0.7 </div>
    <div class="color-item color-06"> 0.6 </div>
    <div class="color-item color-05"> 0.5 </div>
    <div class="color-item color-04"> 0.4 </div>
    <div class="color-item color-03"> 0.3 </div>
    <div class="color-item color-02"> 0.2 </div>
    <div class="color-item color-01"> 0.1 </div>
    <div class="color-item color-0"> 0 </div>
  </div>
</template>

<script>

// import Color from 'color'

export default {
};
</script>

<style lang="scss" scoped>
.ndvi-color-legend {
  border-radius: 2px;
  text-align: center;
  font-size: 0.8em;
  background-color: var(--black);
  padding: 5px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  .color-item {
    padding: 1px 5px;
    color:white
  }

     .color-0{
       background-color: #a50026;
       color:white;
       }
     .color-01{
       background-color: #d73027;
       color:black;
       }
     .color-02{
       background-color: #f46d43;
       color:black;
       }
     .color-03{
       background-color: #fdae61;
       color:black;
       }
     .color-04{
       background-color: #fee08b;
       color:black;
       }
     .color-05{
       background-color: #ffffbf;
       color:black;
       }
     .color-06{
       background-color: #d9ef8b;
       color:black;
       }
     .color-07{
       background-color: #a6d96a;
       color:black;
       }
     .color-08{
       background-color: #66bd63;
       color:white;
       }
     .color-09{
       background-color: #1a9850;
       color:white;
       }
     .color-10{
       background-color: #006837;
       color:white;
       }


}
</style>
