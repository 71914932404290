<template lang="html">
  <sensorWidget :sensor="sensor" :color="'#f3db97'">
    <template v-slot:icon>
      <pivot-svg
        :width="'100%'"
        :height="90"
        :angle="angle"
        :percent="percent"
        :areaStart="areaStart"
        :areaEnd="areaEnd"
        :irrigStart="irrigStart"
        :irrigDist="irrigDist"
        :status="status"
        :startAngle="startAngle"
      />
    </template>

    <template v-slot:content>
      <div>
        <div class="widget-box-value">
          <span class="label">pressão:</span>
          <span class="value"
            >{{ $filters.round(pressure,1) }} <small>{{ pressureUnit }}</small>
          </span>
        </div>
        <div class="widget-box-value">
          <span class="label">posição:</span>
          <span class="value">{{ $filters.round(angle,1) }} <small>°</small> </span>
        </div>
        <div class="widget-box-value">
          <span class="label">percentimetro:</span>
          <span class="value">{{ $filters.round(percent,0) }} <small>%</small> </span>
        </div>
      </div>
    </template>
  </sensorWidget>
</template>

<script>
import pivot from "./pivot.js";
import pivotSvg from "./pivotSvg";
import sensorWidget from "../sensorWidget.vue";

// import BCard from 'bootstrap-vue/es/components/card/card'
// import BCardText from 'bootstrap-vue/es/components/card/card-text'
// import BButton from 'bootstrap-vue/es/components/button/button'
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'

export default {
  extends: pivot,

  methods: {
    showDetail() {
      //this.$emit('set-pivot-current',this.pivot)
      this.$store.dispatch("select/setSensor", this.sensor);
    },
  },

  components: {
    pivotSvg,
    sensorWidget,
  },
};
</script>

