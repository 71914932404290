<template>
  <vl-feature :id="'field-'+field.id" :properties="{compId:field.id,name: `${equipmentName} : ${field.name}`}">
       <vl-geom-multi-polygon v-if="geometry!=null" :coordinates="[geometry]"></vl-geom-multi-polygon>

      <vl-style-box :z-index="0">
        <vl-style-stroke color="rgba(255,220,0,1)" :width="1.25" :lineDash="[1,2]" ></vl-style-stroke>
         <vl-style-fill :color="fieldMapColor"  ></vl-style-fill>
         <vl-style-text :text="field.name">
          <vl-style-fill color="rgba(255,255,255,1)" > </vl-style-fill>
         </vl-style-text>
      </vl-style-box> 

       <!-- <vl-style-box :z-index="0">
          <vl-style-stroke color="rgba(50,50,50,0.5)" :width="2"></vl-style-stroke>
         <vl-style-fill color="rgba(100,100,100,0.1)" ></vl-style-fill>
       </vl-style-box> -->

       <vl-style-box v-if="showDetail===true" :z-index="5">
          <vl-style-stroke color="rgba(200,200,255,1)" :width="3"></vl-style-stroke>
       </vl-style-box>

  
    <vl-overlay v-if="showDetail===true"
       :position="overlayPosition" :offset="[-48, -15]"
       positioning="bottom-left"
       :auto-pan="true" :auto-pan-animation="{ duration: 300 }">
            <div class="map-popup popup-field p-1">
              <div class="d-flex">
              <h5>
                <b-link :to="{name:'equipmentDetails', params:{id:field.equipmentId} }">{{equipmentName}}</b-link>
                <span class="mx-1"> - </span>
              </h5>
              <h5 class="mr-2"> {{field.name}} </h5>
              <div class="ml-auto">
                  <b-button variant="success" size="sm" title="mais detalhes"
                  :to="{name:'fieldDetails',params:{id:field.id}}">
                     <font-awesome-icon icon="chart-line"/>
                  </b-button>
              </div>
              </div>
              <p>
               <img v-b-tooltip.hover :title="field.culture.name" class="culture-img" :src="require('@irrigcomponents/culture/assets/48/'+field.culture.icon+'.png')" />  
               {{field.culture.name}}</p>
                <ul class="field-info-group">
            <li>
              <b>Área plantada</b>
              <span>{{$filters.round((field.area/10000),2)}} ha</span>
            </li>
            <li>
              <b>Data do plantio</b>
              <span>{{$filters.localeDate(field.datePlanting)}}</span>
            </li>
            <li>
              <b> Fase</b>
              <span>{{field.cultureParameters.stageName}} ({{field.status.numDays}}  dias)</span>
            </li>
  

            <template v-if="dataOk">
            <li>
              <b>Lâmina disponível</b>
              <span>{{$filters.round(field.status.laa,1)}} mm</span>
            </li>
            <li>
              <b>ITN </b>
              <span>{{$filters.round(field.status.irrig.itn,2)}} mm</span>
            </li>
               <li>
              <b>Tempo recomendado</b>
              <span>{{$filters.decimalhourToTime(field.status.irrig.hour,'h ')}}</span>
            </li>
            <li v-if="field.status.irrig.percent">
              <b>Percentimetro</b>
              <span>{{$filters.round(field.status.irrig.percent,0)}} %</span>
            </li>

            </template>
          </ul>
          </div>
       </vl-overlay>


       <!-- <vl-overlay v-if="feature.geometry && showDetail===true"
       :position="overlayPosition" :offset="[-48, -15]"
       positioning="bottom-left"
       :auto-pan="true" :auto-pan-animation="{ duration: 300 }">
            <div class="map-popup popup-field" @click="goToFieldDetail">
              <div style="width:310px" v-if="planted">
                <field-info :field="field" />
              </div>
            </div>
       </vl-overlay> -->
  </vl-feature>
</template>

<script>
import { findPointOnSurface } from "vuelayers/lib/ol-ext";
import field from "./field";
import { HEX2RGB } from "@/utils/colorsUtils.js";


export default {
  extends: field,

  props: {
    showDetail: { type: Boolean, default: false },
    showWaterColor: { type: Boolean, default: true },
    datailsPos: { type: Array, default: null }
  },

  computed: {
    /**
     * Retorna a cor que a parcela será representada no mapa
     */
    fieldMapColor() {
      let color = "#444";
      if (this.color != null) {
        color = this.color;
      }
      color = HEX2RGB(color);
      color[3] = 0.5;
      return color;
    },

    overlayPosition() {
      if (this.datailsPos == null) {
        // console.log("detail is null");
        return findPointOnSurface(this.feature.geometry);
      } else {
        // console.log("detail is not null");
        return this.datailsPos;
      }
    }
  },

  mounted() {
    //   this.feature = this.$refs.fieldFeature;
  }
};
</script>

<style lang="scss" scoped>
.popup-field {
  // cursor: pointer;
  border-radius: 10px;
  min-width: 300px;

  .culture-img {
    height: 25px;
  }

  h5{
    font-size: 18px;
    color:#888;
  }

  .field-info-group {
  font-size: 13px;
  border-left: 0;
  border-right: 0;
  border-radius: 0;
  padding:0;
  margin-bottom:0;
  line-height: 14px;  
  li {
    position: relative;
    display: block;
    border-bottom: 1px solid #ddd;
    span {
      float: right !important;
      color: #3c8dbc;
    }
  }
}
}


</style>
