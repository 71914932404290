<template>
  <ValidationProvider slim :name="label" :rules="rules" v-slot="v">
    <b-form-group :label="label" :description="description">
      <b-form-select
        :value="value"
        @input="$emit('input', $event)"
        :options="options"
        :state="$validateState(v)"
      ></b-form-select>
      <span class="invalid-input">{{ v.errors[0] }}</span>
    </b-form-group>
  </ValidationProvider>
</template>

<script>
export default {
  name: "SelectInput",
  props: {
    options:Array,
    rules: Object,
    label: { type: String, required: true },
    description: String,
    value: String,
  },
};
</script>

<style></style>
