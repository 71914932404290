<template>
  <div>
    <div class="d-flex mb-2">
      <div class="ml-auto">
        <b-button-toolbar class="ml-auto">
          <b-button-group size="sm" class="mx-1">
            <b-dropdown variant="outline-secondary" right title="colunas">
              <b-dropdown-form class="dropdownform">
                <b-form-checkbox
                  v-for="field in fields"
                  :key="field.key"
                  v-model="field.show"
                  >{{ field.label }}</b-form-checkbox
                >
              </b-dropdown-form>
              <template #button-content>
                <font-awesome-icon icon="list" />
              </template>
            </b-dropdown>

            <b-dropdown variant="outline-secondary" right title="Exportar">
              <b-dropdown-item @click="download"> MS Excel </b-dropdown-item>
              <template #button-content>
                <font-awesome-icon icon="file-export" />
              </template>
            </b-dropdown>
          </b-button-group>
        </b-button-toolbar>
      </div>
    </div>

    <table-export
      :fileName="'historico_'+fieldName"
      ref="table"
      hide-save-button
      class="text-center"
      responsive
      head-variant="light"
      small
      sticky-header="400px"
      :items="data"
      :fields="filterFields"
      :perPage="1000"
    />
  </div>
</template>

<script>
import { DateTime, Duration } from "luxon";
import {flagFormat} from "./utils.js"

const sensorialFormat = function(value){
  
  if (Array.isArray(value)) {
    let s = ""

    value.forEach(e => {
      s += "["+e+"] "
    });

    return s
  }
  
  return null;
}


const exlDateFormat = function(value) {
  return DateTime.fromISO(value).toJSDate()
};

const dateFormat = function(value) {
  return DateTime.fromISO(value).toLocaleString();
};

const round3 = function(value) {
  if (typeof value !== "number") return value;
  return value.toFixed(3);
};
const round2 = function(value) {
  if (typeof value !== "number") return value;
  return value.toFixed(2);
};

const exFormat = function(value){
  return value
}
// const round0 = function(value) {
//   if (typeof value !== "number") return value;
//   return value.toFixed(0);
// };

const timeDuration = function(value) {
  return Duration.fromObject({ hour: value }).toFormat("hh:mm:ss");
};


const xlsDateType = {type:Date, format:'dd/mm/yy',formatter:exlDateFormat}
const xlsNumbeType2 = {type:Number, format: "0.00", formatter:exFormat, align:'center'}
const xlsNumbeType3 = {type:Number, format: "0.000", formatter:exFormat,align:'center'}

export default {
  data() {
    return {
      fields: [
        { show: true,  key: "date", label: "Data", formatter: dateFormat, _width:10,xlsType:xlsDateType},
        { show: true,  key: "et0", label: "Et0", formatter: round3, xlsType:xlsNumbeType3 },
        { show: false, key: "kc", label: "Kc", formatter: round3, xlsType:xlsNumbeType3 },
        { show: false, key: "etpc", label: "Etpc", formatter: round3, xlsType:xlsNumbeType3 },
        { show: false, key: "ks", label: "Ks", formatter: round3, xlsType:xlsNumbeType3 },
        { show: true,  key: "etc", label: "Etc", formatter: round3, xlsType:xlsNumbeType3 },
        { show: false, key: "cta", label: "Cta", formatter: round3, xlsType:xlsNumbeType3 },
        { show: false, key: "cra", label: "Cra", formatter: round3, xlsType:xlsNumbeType3 },
        
        
        {
          show: true,
          key: "laa",
          label: "Lâmina Disponível (mm)",
          _height:50,
          _width:10,
          formatter: round3, xlsType:xlsNumbeType3
        },
        {
          show: true,
          key: "irrig",
          label: "Irrigação (mm)",
          _width:10,
          formatter: round2, xlsType:xlsNumbeType2
        },
        { show: true, key: "rain", label: "Chuva (mm)", formatter: round2,  _width:6,xlsType:xlsNumbeType2 },
        {
          show: false,
          key: "excess",
          label: "Excesso (mm)",
          _width:8,
          formatter: round2, xlsType:xlsNumbeType2
        },
        {
          show: false,
          key: "rootDepth",
          label: "Raiz (cm)",
          _width:5,
          formatter: round2, xlsType:xlsNumbeType2
        },
        {
          show: false,
          key: "powerKWh",
          label: "Energia (kWh)",
          _width:8,
          formatter: round3, xlsType:xlsNumbeType3
        },
        {
          show: false,
          key: "totalWaterM3",
          label: "Volume de Água (m³)",
          _width:10,
          formatter: round3, xlsType:xlsNumbeType3
        },
        {
          show: false,
          key: "irrigationTime",
          label: "Tempo de irrigação (HH:MM:SS)",
          formatter: timeDuration,
          _width:10
        },
        {
          show: false,
          key: "humanSensorial",
          label: "sensorial I",
          formatter:sensorialFormat
        },
        {
          show: false,
          key: "humanSensorialFinal",
          label: "sensorial F",
          formatter:sensorialFormat
        },

        { key: "irrigFlag", label: "Flag" , formatter:flagFormat},
      ],
    };
  },

  props: {
    fieldName:String,
    data: Array,
  },

  computed: {
    filterFields() {
      return this.fields.filter(function(e) {
        return e.show === true;
      });
    },
  },

  methods: {

    download(){
      this.$refs.table.download()
    }
  },
};
</script>

<style lang="scss" scoped>
.echarts {
  width: 100%;
  height: 420px;
}

.dropdownform {
  width: 230px;
  height: 250px;
  overflow: auto;
  font-size: 12px;
}
</style>
