import stationPageList from './stationPageList'
import stationPageEdit from './stationPageEdit'
import stationPage from './stationPage'

export default {path:'/station', component:stationPageList, name:'stationList',
      children:[
        {
          path:':id/edit',
          name:'stationEdit',
          component:stationPageEdit,
          props: (route) => ({stationId:route.params.id}),
          meta: {authorize:{action:'edit',resource:'WeatherStation'}},
        },
        {
          path:':id',
          name:'stationDetails',
          component:stationPage,
          props: (route) => ({stationId:route.params.id})
        },
        {
          path:'new',
          name:'stationNew',
          component:stationPageEdit,
          meta: {authorize:{action:'create',resource:'WeatherStation'}},
        }
      ]
    }