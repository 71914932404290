<template lang="">
    <b-card no-body>
        
        <b-tabs card small content-class="mt-1">
            
        <b-tab title="Tree" active>
            <vue-json-pretty  v-if="!error" :data="jsonObject"  @input="$emit('input', $event)" editable showIcon virtual showSelectController/>
        </b-tab>
        <b-tab title="Texto">
            <b-form-textarea
            @input="$emit('input', $event)"
            :value="jsonText"
            rows="10"
            > </b-form-textarea>
        </b-tab>
        </b-tabs>

    <div class="p-3">

        <b-alert variant="danger" :show="error"> Erro </b-alert>
    </div>

        <!-- <b-button size="sm" variant="primary" pill @click="copy()">  <font-awesome-icon icon="copy" /> </b-button>
        <b-button size="sm" variant="primary" pill @click="paste()"> <font-awesome-icon icon="paste"/> </b-button> -->

        </b-card>


 

        <!-- <vue-json-editor
        v-model="editable.pivot"
        :show-btns="true"
        :expandedOnStart="true"
      ></vue-json-editor> -->
</template>
<script>
// import JsonEditorVue from 'json-editor-vue'
export default {

    data() {

        return {
            jsonText: "",
            jsonObject: "",
            error: false
        }
    },

    props: {
        value: { type: [Object, String,Array] },
    },

    methods: {

        // copy() {
        //     navigator.clipboard.writeText(this.jsonText)
        // },
        // paste() {
        //     navigator.clipboard.readText().then(clipText =>    this.jsonText =  clipText);
           
        // },

        convert(v) {
            try {
                if (typeof this.value == 'object') {
                    this.jsonText = JSON.stringify(v, undefined, 2)
                    this.jsonObject = v
                }

                if (typeof v == 'string') {
                    this.jsonObject = JSON.parse(v, undefined, 2)
                    this.jsonText = v
                }

                this.error = false
            } catch (e) {
                this.error = true
            }
        }
    },

    mounted() {
        this.convert(this.value)
    },

    watch: {
        value(v) {
            this.convert(v)
        },

        jsonText(v) {
            try {
                if (typeof v == 'object') {
                    this.$emit("input", v);
                }

                if (typeof v == 'string') {
                    this.$emit("input", JSON.parse(v));
                }
                this.error = false
            } catch (e) {
                this.error = true
            }
        },

        jsonObject(v) {
            this.$emit("input", v);
            this.jsonText = JSON.stringify(v, undefined, 2)

        }
    },

    computed: {





    }



}
</script>
<style lang="scss">

    
</style>