<template>
    <page-float-card fluid="lg" title="API Externas" subTitle="Controle de vinculo de sensores com APIs externas">


      

        <template v-slot:head-tools>
            <b-button size="sm" variant="success" title="inserir nova Api" @click="editableApi = {}"> nova </b-button>
        </template>



        <div v-if="loadListStatus === 'loaded'">
             <sensorApi v-for="api in objectsList" :key="api.id" :api="api" 
            @click-edit="editableApi = $event"
            @click-delete ="deleteObjectById(api.id)"
            @update-object="updateList($event)"
            >
            </sensorApi> -
            <sensorApiEditor v-model="editableApi"  @update-object="updateList($event)"/>
            
        </div>
        <c-loading @retry="loadList()" :status="loadListStatus" v-else></c-loading>

    </page-float-card>
</template>
<script>
import sensorApi from './sensorApi.vue';
import sensorApiEditor from './sensorApiEditor.vue';
import { editableList } from "@components/mixins/EditableList";
export default {
    mixins: [editableList],
    data() {
        return {
            editableApi: undefined,
        }
    },


    mounted() {
        this.objectsListUrl = "sensorapi/sensors";
        this.loadList();
    },



    // methods: {
    //     loadData() {
    //         this.loadStatus = "loading";
    //         this.$http.get("sensorapi/sensors").then(
    //             (data) => {
    //                 this.sensors = data;
    //                 this.loadStatus = "loaded";
    //             },
    //             (error) => {
    //                 console.error("sensors load error:", error);
    //                 this.loadStatus = "error";
    //             }
    //         );
    //     },

    //     updateList(sensor) {
    //         const index = this.channelList.findIndex((e) => e.id === sensor.id);
    //         if (index === -1) {
    //             this.sensors.push(sensor);
    //             this.numRows = this.sensors.length;
    //         } else {
    //             let dataObj = this.sensors[index];
    //             Object.entries(sensor).forEach(([key, value]) => {
    //                 dataObj[key] = value;
    //             });
    //         }
    //     },
    // },

    // mounted() {
    //     this.loadData()
    // },

    components: {
        sensorApi,
        sensorApiEditor
    }

}
</script>
<style lang="">
    
</style>