<template>
  <div>
    <hr>
    <h5> Demanda </h5>
    <monthChart
      :series="contractedDemand"
      unit="kW"
      vertical
      height="200px"
      :lastMonth="month"
      :numMonth="numMonth"
      :width="width"
      @click="$emit('select-month', $event)"
      valuesAxis
    />
  </div>
</template>
<script>
import monthChart from "@kl-framework/charts/monthChart";
export default {

  props:{
    history:Array,
    month: { type: String, required: true },
    numMonth:{type:Number, default:13},
    width: { type: [String, Number], default: "100%" },
    chartHeight: { type: [String, Number], default: "150px" },
  },

  components:{
    monthChart
  },

  computed: {
    contractedDemand() {
      return [{
        name: "Contratada",
        type: "line",
        color: "#f80",
        label: { show: false },
        values: this.history
          ? this.history.map((e) => {
            return { month: e.referenceMonth, value: e.contractedDemand };
          })
          : [],
      },
      {
        type: "bar",
        color: '#aaa',
        stack: "demand",
        label:{show: false},
        values: this.history
          ? this.history.map((e) => {
            return { month: e.referenceMonth, value: (e.activeDemand - e.exceedingDemand) };
          })
          : [],

      },
      {
        name: "Ativa",
        type: "bar",
        color: "#28a745",
        barGap:'-100%',
        label: { show: false },
        values: this.history
          ? this.history.map((e) => {
            return { month: e.referenceMonth, value: e.activeDemand };
          })
          : [],

      },
      {
        name: "Ultrapassagem",
        type: "bar",
        color: "#f40",
        stack: "demand",
        label: { show: false },
        values: this.history
          ? this.history.map((e) => {
            return { month: e.referenceMonth, value: e.exceedingDemand };
          })
          : [],

      }

      ];
    },
  }
}
</script>
<style lang="">

</style>