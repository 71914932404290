<template>
  <b-table
    class="shadow border"
    striped
    small
    responsive
    :items="workspaceList"
    :fields="fields"
    :filter="filter"
    :current-page="currentPage"
    :per-page="perPage"
    @filtered="onFiltered"
  >
    <template v-slot:cell(name)="row">
      <b-link :to="{name:'workspaceDetails',params:{id:row.item.id}}">{{row.item.name}}</b-link>
    </template>

  </b-table>
</template>

<script>
import userSymbol from "../avatar/userSymbol";

export default {
  data() {
    return {
      fields: [
        { key: "name", label: "Nome", sortable: true },
        { key: "subtitle", label: "Subtítulo", sortable: true },
        // { key: "consultant", label: "Consultor", sortable: true },
        // { key: "state", label: "Estado", sortable: true },
        // { key: "city", label: "Cidade", sortable: true },
        // { key: "contact", label: "Contato", sortable: false },
      ]
    };
  },
  props: {
    workspaceList: Array,
    currentPage: Number,
    perPage: Number,
    filter: [String, RegExp, Object]
  },
  methods: {
    onFiltered(filteredItems) {
      this.$emit("onnumrows", filteredItems.length);
    }
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    userSymbol
  }
};
</script> 
