<template>
  <div>
    <b-row>
      <b-col cols="3" md="2">
        <b-button-group size="sm">
          <b-button
            v-b-tooltip.hover
            title="recarregar"
            variant="outline-secondary"
            @click="loadLog()"
          >
            <font-awesome-icon icon="sync-alt" />
          </b-button>

          <b-button
            v-b-tooltip.hover
            title="Apagar log"
            variant="danger"
            @click="clearLog()"
          >
            <font-awesome-icon icon="trash" />
          </b-button>
        </b-button-group>
      </b-col>

      <b-col cols="9" md="5">
        <b-pagination
          v-model="currentPage"
          :total-rows="numRows"
          :per-page="perPage"
          aria-controls="my-table"
        ></b-pagination>
      </b-col>

      <b-col md="5">
        <b-input-group size="sm">
          <b-form-input
            v-model="filter"
            type="search"
            placeholder="Digite o que procurar"
          ></b-form-input>
          <b-input-group-append>
            <b-button :disabled="!filter" @click="filter = ''">Limpar</b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col> 
    </b-row>
    <b-table
      v-if="loadStatus == 'loaded'"
      small
      striped
      :items="logs"
      :fields="tableFields"
      :filter="filter"
      :current-page="currentPage"
      :per-page="perPage"
      @filtered="onNumRows"
      sortBy="at"
      :sort-desc="true"
      sort-direction="desc"
    >
    </b-table>
    <c-loading :status="loadStatus" v-else></c-loading>
  </div>
</template>

<script>
import { DateTime } from 'luxon';
var dateFormat = function(value) {
  return DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)
};

var msgBoxConfirmOptions = {
  title: "Por favor confirme",
  size: "sm",
  buttonSize: "sm",
  okVariant: "danger",
  okTitle: "Sim",
  cancelTitle: "Não",
  footerClass: "p-2",
  hideHeaderClose: false,
  centered: true,
};

export default {
  data() {
    return {
      currentPage: 1,
      filter: null,
      numRows: 1,
      perPage: 10,
      logs: [],
      loadStatus: "loading",
      tableFields: [
        {
          key: "at",
          label: "Data/Hora",
          sortable: true,
          sortDirection: 'desc',
          formatter: dateFormat,
        },
        { key: "author", label: "Autor", sortable: false },
        { key: "type", label: "Tipo", sortable: true },
        { key: "value", label: "Mensagem", sortable: false },
      ],
    };
  },
  props: {
    feed: { type: Object, required: true },
  },
  mounted() {
    this.loadLog();
  },
  methods: {
    loadLog() {
    this.loadStatus = "loading";
      this.$http.get("/sens/feed/" + this.feed.id + "/logger").then(
        (data) => {
          this.logs = data;
          this.numRows = data.length;
          this.loadStatus = "loaded";
        },
        (error) => {
          console.log("load feed log error:", error);
          this.loadStatus = "error";
        }
      );
    },
    onNumRows(filteredItems) {
      this.numRows = filteredItems.length;
    },

    clearLog() {
       this.$bvModal
        .msgBoxConfirm(
          "Deseja realmente apagar os logs deste feed?",
          msgBoxConfirmOptions
        )
        .then((value) => {
          if (value === true) {
            this.$http.delete("/sens/feed/" + this.feed.id + "/logger").then(
              () => {
                this.loadLog();
              },
              (error) => {
                console.error("delete logs error", error);
              }
            );
          }
        });
    },
  },
};
</script>

<style></style>
