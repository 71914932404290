<template>
  <b-table-simple hover small caption-top responsive class="report-list-table ">
      <b-thead>
        <b-tr>
          <b-th>Mês/Ano</b-th>
          <b-th>Parcelas</b-th>
          <b-th>Área<br> <small> (ha)</small></b-th>
          <b-th>Irrigação<br> <small> (mm)</small></b-th>
          <b-th>Excesso irrig.<br> <small> (mm)</small></b-th>
          <b-th>Chuva<br> <small> (mm)</small></b-th>
          <b-th>Consumo<br> <small> (kWh)</small></b-th>
          <b-th><br> <small> (R$/ha)</small></b-th>
          <b-th>Custo<br> <small> (R$/mm/ha)</small></b-th>
          <b-th><br> <small> (R$/kWh)</small></b-th>
          <b-th>Total<br> <small> (R$)</small></b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-for="report in reports" :key="report.referenceMonth">
          <b-td class="text-left"><b-link :to="{name:'irrigationReportDetails',params:{month: report.referenceMonth }}">
            {{ $filters.longMonth(report.referenceMonth) }}
          </b-link></b-td>
          <b-td>{{report.numFields}}</b-td>
          <b-td>{{$filters.localeNumber(report.areaHa,2)}}</b-td>
          <b-td>{{$filters.localeNumber(report.irrigation,2)}}</b-td>
          <b-td>{{$filters.localeNumber(report.irrigationExcess,2)}}</b-td>
          <b-td>{{$filters.localeNumber(report.rain,2)}}</b-td>
          <b-td>{{$filters.localeNumber(report.powerKWh,2)}}</b-td>
          <b-td>{{$filters.localeNumber(report.costPerHa,2)}}</b-td>
          <b-td>{{$filters.localeNumber(report.costPerMmPerHa,2)}}</b-td>
          <b-td>{{$filters.localeNumber(report.costPerKWh,2)}}</b-td>
          <b-td><strong>{{$filters.localeNumber(report.totalCost,2)}}</strong></b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
</template>

<script>
export default {

    props:{
        reports:Array
    }

}
</script>

<style lang="scss" scoped>
.report-list-table {
  text-align: center;

  .line-right {
    border-right: 2px solid #dee2e6;
  }

  .culture-img {
    height: 25px;
  }

  tr {
    font-size: 0.85em;
  }
  thead {
    th {
      vertical-align: top;
    }
    .td-header-group {
    //   border-bottom: 2px #005f8d solid;
      padding-bottom: 2px;
    }
  }

  tfoot {
    font-weight: bold;
  }
}
</style>