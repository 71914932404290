<template>
  <v-chart
    ref="chart"
    style="width:100%;height:300px;"
    @brushselected="brushselected"
    :option="serie"
    autoresize
  />
</template>

<script>
import { DateTime } from "luxon";

export default {
  data() {
    return {
      startZom: 0,
      chart: undefined,
    };
  },
  props: {
    datapoints: Array,
    dataStream: Object,
    rangeselect:Object
  },
  methods: {
    brushselected(params) {
      let area = params.batch[0].areas[0];
      if(area){
        let coord =area.coordRange;
        this.$emit("update:rangeselect", {
          start: DateTime.fromSeconds(coord[0] / 1000,).toISO(),
          end:   DateTime.fromSeconds(coord[1] / 1000,).toISO(),
        });
      }else{
        this.$emit("update:rangeselect", undefined);
      }
    },

    // dataZoomUpdate() {
    //   console.log(this.chart.getOption().xAxis[0]);
    //   var axis = this.chart.getOption().xAxis[0];
    //   this.$emit("datazoom", {
    //     start: axis.rangeStart / 1000,
    //     end: axis.rangeEnd / 1000,
    //   });

    //   //var option = this.chart.chart.getOption()
    //   //this.$emit('datazoom',{start:option.dataZoom[0].startValue,end:option.dataZoom[0].endValued})
    // },
  },
  mounted() {
    this.chart = this.$refs.chart;
    // this.dataZoomUpdate();
  },
  computed: {
    serie: function() {
      let self = this;
      let datapoints = this.datapoints;
      //   console.log(datapoints);

      if (!datapoints) return;


      let maxValue = undefined
      if (this.dataStream.maxValue != undefined){
        let min = 0
        if (this.dataStream.minValue != undefined)
          min = this.dataStream.minValue
        maxValue = this.dataStream.maxValue + (this.dataStream.maxValue-min)*0.1
      }

      return {
        // title: {
        //   text: "Histórico de climas"
        // },
        grid: [{ right: 1, bottom: 20, top: 50 }],
        legend: {
          top: 0,
          // data: ["serie 1", "serie 2"],
        },
        brush: {
          toolbox: ["lineX", "clear"],
          xAxisIndex: 0,
        },
        toolbox: {},
        tooltip: {
          trigger: "axis",
          axisPointer: { type: "cross", crossStyle: { color: "#999" } },
        },
        dataZoom: [
          {
            //xAxisIndex: [0, 1],
            type: "inside",
            show: true,
            realtime: true,
            start: 0,
            end: 100,
          },
        ],
        xAxis: [
          {
            type: "time",
            time: {
              // displayFormats: {
              //   day: "DD MMM - HH:mm:ss",
              // },
            },
            axisPointer: {
              label: {
                formatter: function(value) {
                  return DateTime.fromMillis(value.value).toLocaleString(
                    DateTime.DATETIME_SHORT_WITH_SECONDS
                  );
                },
              },
            },
            display: true,
            scaleLabel: {
              display: false,
              labelString: "Data/hora",
            },
          },
        ],

        yAxis: [
          {
            name: self.dataStream.units,
            type: "value",
            // boundaryGap: ['10%', '10%'],
            max: maxValue,
            min: self.dataStream.minValue,
            axisLabel: {
              formatter: function(val) {
                return val + self.dataStream.unitSymbol;
              },
            },
            splitArea: { show: false },
            // display: true,
            ticks: {
              beginAtZero: false,
            },
            // scaleLabel: {
            //   display: true,
            //   labelString: this.dataStream.unitys,
            // },
          },
        ],

        series: [
          {
            yAxisIndex: 0,
            name: "serie 1",
            data: datapoints.map((item) => [item.ux * 1000, item.v1]),
            type: this.dataStream.dataType ? this.dataStream.dataType : "line",
            symbol: "none",
          },
          {
            yAxisIndex: 0,
            name: "serie 2",
            data: datapoints.map((item) => [item.ux * 1000, item.v2]),
            type: this.dataStream.dataType ? this.dataStream.dataType : "line",
            symbol: "none",
          },
        ],
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.echarts {
  width: 100%;
  height: 200px;
}
</style>
