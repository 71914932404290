<template>
  <div class="p-2">
    <h6>1 - Primeiro selecione um arquivo CSV com os dados de clima</h6>
    <b-form-file
      v-model="file"
      :state="Boolean(file)"
      placeholder="Escolha um arquivo e solte aqui..."
    ></b-form-file>

    <hr />
    <h6>2 - Selecione os campos do CSV correspondente</h6>

    <b-row class="my-2">
      <b-form-group class="col-md-3 col-sm-6" label="Data">
        <b-form-select v-model="fields.date" :options="fieldOptions">
        </b-form-select>
      </b-form-group>
      <b-form-group class="col-md-3 col-sm-6" label="T. máxima">
        <b-form-select v-model="fields.tempMax" :options="fieldOptions">
        </b-form-select>
      </b-form-group>
      <b-form-group class="col-md-3 col-sm-6" label="T. minima">
        <b-form-select v-model="fields.tempMin" :options="fieldOptions">
        </b-form-select>
      </b-form-group>
      <b-form-group class="col-md-3 col-sm-6" label="T. media">
        <b-form-select v-model="fields.tempAvg" :options="fieldOptions">
        </b-form-select>
      </b-form-group>
      <b-form-group class="col-md-3 col-sm-6" label="Umidade">
        <b-form-select v-model="fields.humiRel" :options="fieldOptions">
        </b-form-select>
      </b-form-group>
      <b-form-group class="col-md-3 col-sm-6" label="Vento">
        <b-form-select v-model="fields.velWind2m" :options="fieldOptions">
        </b-form-select>
      </b-form-group>
      <b-form-group class="col-md-3 col-sm-6" label="Radiação">
        <b-form-select v-model="fields.radi" :options="fieldOptions">
        </b-form-select>
      </b-form-group>
      <b-form-group class="col-md-3 col-sm-6" label="Chuva">
        <b-form-select v-model="fields.rain" :options="fieldOptions">
        </b-form-select>
      </b-form-group>
    </b-row>

    <hr />
    <h6>3 - Configure as unidades e formato de data se necessário</h6>
    <b-row class="my-2">
      <b-form-group class="col-lg-4" label="Unidade de Radiação" size="sm">
        <b-form-select v-model="convertToMJ">
          <b-form-select-option :value="false"> MJ/dia</b-form-select-option>
          <b-form-select-option :value="true"> W/M² </b-form-select-option>
        </b-form-select>
      </b-form-group>

      <b-form-group class="col-lg-4" label="Formato de data" size="sm">
        <b-form-input v-model="dateFormat" />
      </b-form-group>
    </b-row>

    <hr />
    <h6>4 - Verifique se os dados estão corretos</h6>
    <weathersTable
      title="Dados para envio"
      :station="{ id: stationId }"
      :weathers="weathers"
    />

    <hr />
    <h6>5 - Enviar</h6>
    <div class="d-flex">
      <div class="ml-auto">
        <b-button
          variant="success"
          v-if="sendFileStatus == 'ready'"
          @click="sendFile"
        >
          Enviar
        </b-button>

        <b-button variant="secoundary" v-else>
          enviando...
        </b-button>
      </div>
    </div>
    <!-- {{ weathers }} -->
  </div>
</template>

<script>
import { DateTime } from "luxon";
import weathersTable from "./weathersTable";
import { csvJSON } from "@kl-framework/utils/csvToJson.js";

// eslint-disable-next-line no-unused-vars
function extractNumber(val, separator = ".") {
  if (val) {
    return Number(val.replace(separator, "."));
  }
  return "";
}

function extractDate(val, dateFormat) {
  if (val) {
    return DateTime.fromString(val, dateFormat).toISODate();
  }
  return "";
}

export default {
  data() {
    return {
      sendFileStatus: "ready",
      file: null,
      json: [],
      convertToMJ: false,
      fieldOptions: [],
      dateFormat: "dd/MM/yyyy",
      fields: {},
    };
  },

  props: {
    stationId: { type: String, required: false },
  },

  mounted() {
    this.fieds = {
      date: "Data",
      tempMax: "Temp. Max",
      tempMin: "Temp. Min",
      tempAvg: "Temp. Med",
      humiRel: "Umidade Relativa",
      velWind2m: "Vel. Vento",
      radi: "Radiação",
      rain: "Chuva",
    };
  },

  computed: {
    weathers() {
      let result = [];
      let numericSeparator = ",";
      let radiConverter = this.convertToMJ ? 0.0864 : 1;

      this.json.forEach((element) => {
        if (Object.keys(element).length >= 8) {
          result.push({
            date: extractDate(element[this.fields.date], this.dateFormat),
            tempMax: extractNumber(
              element[this.fields.tempMax],
              numericSeparator
            ),
            tempMin: extractNumber(
              element[this.fields.tempMin],
              numericSeparator
            ),
            tempAvg: extractNumber(
              element[this.fields.tempAvg],
              numericSeparator
            ),
            humiRel: extractNumber(
              element[this.fields.humiRel],
              numericSeparator
            ),
            velWind2m: extractNumber(
              element[this.fields.velWind2m],
              numericSeparator
            ),
            radi:
              extractNumber(element[this.fields.radi], numericSeparator) *
              radiConverter,
            rain: extractNumber(element[this.fields.rain], numericSeparator),
          });
        }
      });

      return result;
    },
  },

  components: {
    weathersTable,
  },

  watch: {
    file() {
      if (this.file) {
        const reader = new FileReader();
        reader.onload = (event) => {
          let csv = event.target.result;
          this.json = JSON.parse(csvJSON(csv));
          this.fieldOptions = Object.keys(this.json[0]);
        };
        reader.readAsText(this.file);
      } else {
        this.json = [];
      }
    },
  },
  methods: {
    sendFile() {
      this.sendFileStatus = "sending";
      this.$http
        .post(
          "irrig/station/" + this.stationId + "/weather/list",
          this.weathers
        )
        .then(
          () => {
            this.sendFileStatus = "ready";
            this.file = null;
            this.$bvModal.msgBoxOk("Dados salvos", {
              title: "Successo!",
            });
          },
          (error) => {
            console.error("send weathers error", error);
            this.$bvModal.msgBoxOk("Não foi possível salvar os dados", {
              title: "Erro!",
            });
            this.sendFileStatus = "error";
          }
        );
    },
  },
};
</script>

<style></style>
