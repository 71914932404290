import sensor from '../sensor.js'

export default {
  extends:sensor,
  computed:{
    level(){
      return this.sensor.level
    },
    maxLevel(){
      return this.sensor.maxLevel
    },
    sensorLevel(){
      return this.sensor.sensorLevel
    },
    maxDayLevel(){
      return this.sensor.maxDayLevel
    },
    minDayLevel(){
      return this.sensor.minDayLevel
    },
    maxDayTime(){
      return this.sensor.maxDayTime
    },
    minDayTime(){
      return this.sensor.minDayTime
    },
  },
  filters:{
  }
}
