import store from '@/store'

import equipmentPage from './equipmentPage'
import equipmentPageDetails from './equipmentPageDetails'
import equipmentPageEdit from './equipmentPageEdit'


export default { 
    path:'equipment/:id',
    component:equipmentPage,
    props: (route) => ({ equipment: store.getters["equipments/getById"](route.params.id)}),
    children:[
        {
            path:'',
            name:'equipmentDetails',
            component:equipmentPageDetails,
        },
        {
            path:'edit',
            name:'equipmentEdit',
            component:equipmentPageEdit,
            meta: {authorize:{action:'edit',resource:'Equipment'}},
        },
        {
            path:'/equipment/new',
            name:'equipmentNew',
            component:equipmentPageEdit,
            meta: {authorize:{action:'create',resource:'Equipment'}},
        }
    ]
}