<template>
  <b-modal
    ref="object-edit-modal"
    title="Editar conta de energia elétrica"
    scrollable
    @hidden="close"
    size="lg"
  >
    <validation-observer ref="formObserver">
      <b-row>
        <ValidationProvider
          slim
          vid="month"
          name="Mes Referência"
          :rules="{ required: true }"
          v-slot="v"
        >
          <b-form-group label="Mes Referência" class="col-lg-4 col-6">
            <b-form-input
              disabled
              name="month"
              v-model="editableObject.referenceMonth"
              type="month"
              :state="$validateState(v)"
            ></b-form-input>
            <span class="invalid-input">{{ v.errors[0] }}</span>
          </b-form-group>
        </ValidationProvider>


        <b-form-group  label="Bandeira Tarifária" class="col-lg-3 col-6">
          <b-form-select v-model="editableObject.seasonalFlag" :style="{backgroundColor: getFlag(editableObject.seasonalFlag).color}">
            <b-form-select-option :style="{backgroundColor: getFlag('VERDE').color}" value="VERDE">VERDE</b-form-select-option>
            <b-form-select-option :style="{backgroundColor: getFlag('AMARELA').color}" value="AMARELA">AMARELA</b-form-select-option>
            <b-form-select-option :style="{backgroundColor: getFlag('VERMELHA').color}" value="VERMELHA">VERMELHA</b-form-select-option>
            <!-- <b-form-select-option value="VERMELHA2">VERMELHA 2</b-form-select-option> -->
          </b-form-select>
        </b-form-group>


        <dateInterval
          class="col-sm-12 col-lg-5"
          v-model="editableObject.dateInterval"
        />

        <b-col lg="6" sm="1"></b-col>
        <b-col lg="6" sm="11">
          <ValidationProvider :rules="{ required: false, minValue: 0 }" v-slot="v">
            <b-form-group label-cols-lg="6" label="Demanda Contratada">
              <b-input-group size="sm"  append="kW">
                <b-input
                v-model="editableObject.contractedDemand"
                type="number"
                min="0"
                step="any"
                :state="$validateState(v)"
                />
            </b-input-group>
            </b-form-group>
          </ValidationProvider>
        </b-col>


      </b-row>

      <billCompositionEdit v-model="editableObject.composition" :editInjectedEnergy="editInjectedEnergy" />


      <b-form-group label="comentários">
        <b-form-textarea
          name="bill_comments"
          v-model="editableObject.comments"
          rows="2"
        ></b-form-textarea>
      </b-form-group>
    </validation-observer>

    <!-- <vue-json-pretty :path="'res'" :data="editableObject"></vue-json-pretty> -->

    <template v-slot:modal-footer>
      <b-button :disabled="savingObject" @click="submit" variant="primary">
        Salvar
        <font-awesome-icon v-if="savingObject" icon="spinner" spin />
      </b-button>
      <b-button @click="close">Cancelar</b-button>
    </template>
  </b-modal>
</template>

<script>
import { formModalObject } from "@components/mixins/FormModalObject";
import billCompositionEdit from "./billCompositionEdit";
import dateInterval from "./dateInterval";
import { flagColor } from "../flagColorMixin";

export default {
  mixins: [formModalObject,flagColor],
  props: {
    consumerStationId: { type: String, required: true },
    editInjectedEnergy:{typeo:Boolean, default:false}
  },
  mounted() {
    this.objectUrl =
      "report/consumer-station/" + this.consumerStationId + "/bill";
  },
  components: {
    dateInterval,
    billCompositionEdit,
  },
  computed: {
    // consumoAtivoPonta(){
    //   var obj = this.editableObject.consumoAtivo.find(e=>e.postoTarifario=='PONTA')
    //   return obj?obj:{postoTarifario:'PONTA',consumo:{}}
    // }
  },
};
</script>
