var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-row',[_c('b-col',{staticClass:"mb-3",attrs:{"lg":"4"}},[_c('b-card',{staticClass:"sticky-top",attrs:{"no-body":""}},[_c('b-row',{attrs:{"no-gutters":""}},[_c('b-col',{attrs:{"sm":"6","lg":"12"}},[_c('div',{staticClass:"map-div"},[(_vm.equipment.type !== undefined)?_c('simple-map',{attrs:{"id":"detail-eqipment-map","mapHeight":'220px'}},[_c('map-' + _vm.equipment.type,{tag:"component",attrs:{"equipment":_vm.equipment}}),(_vm.equipment.fields !== undefined)?_c('tbody',_vm._l((_vm.equipment.fields),function(field){return _c('fieldMap',{key:field.id,attrs:{"field":field}})}),1):_vm._e()],1):_vm._e()],1)]),_c('b-col',{staticClass:"p-2",attrs:{"sm":"6","lg":"12"}},[_c('div',[_c('i',[_vm._v(_vm._s(_vm.equipment.description))])]),_c('ul',{staticClass:"equipment-info-group"},[_c('li',[_c('b',[_vm._v("Tipo")]),_c('span',[_vm._v(_vm._s(_vm.equipment.type))])]),_c('li',[_c('b',[_vm._v("Vazão")]),_c('span',[_vm._v(_vm._s(_vm.$filters.round(_vm.equipment.flowRate, 2))+" m³/h")])]),_c('li',[_c('b',[_vm._v("Potência")]),_c('span',[_vm._v(_vm._s(_vm.$filters.round(_vm.equipment.powerKw, 2))+" kW")])]),_c('li',[_c('b',[_vm._v("Área Irrigada")]),_c('span',[_vm._v(_vm._s(_vm.$filters.round(_vm.equipment.irrigatedArea / 10000, 2))+" ha")])]),(_vm.equipment.completTurn100Percent != undefined)?_c('li',[_c('b',[_vm._v("Lâmina a 100%")]),_c('span',[_vm._v(_vm._s(_vm.$filters.round(_vm.equipment.water100, 2))+" mm")])]):_vm._e(),(_vm.equipment.completTurn100Percent != undefined)?_c('li',[_c('b',[_vm._v("Volta a 100%:")]),_c('span',[_vm._v(_vm._s(_vm.$filters.decimalhourToTime(_vm.equipment.completTurn100Percent, "h ", "m")))])]):_vm._e()]),_c('div',{staticClass:"mt-5"},[(_vm.$can('edit', 'Equipment'))?_c('b-button',{attrs:{"block":"","variant":"primary","size":"sm","to":{
                name: 'equipmentEdit',
                params: { id: _vm.equipment.id },
              }}},[_c('font-awesome-icon',{attrs:{"icon":"edit"}}),_vm._v(" Editar ")],1):_vm._e(),(_vm.$can('remove', 'Equipment'))?_c('b-button',{staticClass:"ml-auto",attrs:{"block":"","size":"sm","variant":"danger"},on:{"click":_vm.removeEquipment}},[_c('font-awesome-icon',{attrs:{"icon":"trash"}}),_vm._v(" Excluir ")],1):_vm._e()],1)])],1)],1)],1),_c('b-col',[_c('b-tabs',{staticClass:"tab-card"},[_c('b-tab',{attrs:{"title":"Parcelas","active":""}},[(_vm.numFiels == 0)?_c('div',{staticClass:"text-center m-5"},[_c('div',[_c('img',{staticStyle:{"width":"140px"},attrs:{"src":require("@/assets/field.png"),"alt":""}})]),_c('div',{staticClass:"mt-4"},[_c('h5',[_vm._v("Sem parcelas ativas")]),_c('p',[_vm._v(" No momento não existe nenhum parcela ativa neste equipamento. ")]),(_vm.$can('create', 'Field'))?_c('b-button',{attrs:{"to":{
                name: 'fieldNew',
                params: { equipmentId: _vm.equipment.id },
              },"variant":"primary"}},[_vm._v("Nova parcela ")]):_vm._e()],1)]):_c('div',[_vm._l((_vm.equipment.fields),function(field){return _c('field-card',{key:field.id,attrs:{"field":field}})}),_c('div',{staticClass:"d-flex justify-content-center"},[(_vm.$can('create', 'Field'))?_c('b-button',{attrs:{"size":"sm","to":{
                name: 'fieldNew',
                params: { equipmentId: _vm.equipment.id },
              },"variant":"primary"}},[_vm._v("Nova parcela ")]):_vm._e()],1)],2)]),_c('b-tab',{attrs:{"title":"Histórico"}},[_c('fieldHistorical',{attrs:{"equipment":_vm.equipment}})],1),_c('b-tab',{attrs:{"title":"Ficha técnica"}},[(_vm.equipment.type != undefined)?_c('datasheet-' + _vm.equipment.type,{tag:"component",attrs:{"equipment":_vm.equipment}}):_vm._e()],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }