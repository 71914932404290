<template>
  <b-row>

     <!-- datastreamId-->
    <ValidationProvider
      slim
      vid="sensorId"
      name="Sensor Id"
      :rules="{ required: true, between:[0,16]}"
      v-slot="v"
    >
      <b-form-group label="Sensor Id" class="col-sm-6">
        <b-input-group :state="$validateState(v)">
          <b-form-input
            name="sensor.dataStreamId"
            v-model="editable.dataStreamId"
            type="number"
            min="0"
            :state="$validateState(v)"
          ></b-form-input>
        </b-input-group>
        <span class="invalid-input">{{ v.errors[0] }}</span>
      </b-form-group>
    </ValidationProvider>


    <!-- sensor Level-->
    <ValidationProvider
      slim
      vid="sensorLevel"
      name="Nível vazio"
      :rules="{ required: true, minValue:editable.maxLevel }"
      v-slot="v"
    >
      <b-form-group label="Profundidade do sensor" class="col-sm-6">
        <b-input-group append="m" :state="$validateState(v)">
          <b-form-input
            name="sensor.sensorLevel"
            v-model="editable.sensorLevel"
            type="number"
            min="0"
            :state="$validateState(v)"
          ></b-form-input>
        </b-input-group>
        <span class="invalid-input">{{ v.errors[0] }}</span>
      </b-form-group>
    </ValidationProvider>


    <!-- max Level-->
    <ValidationProvider
      slim
      vid="fullLevel"
      name="Nível máximo"
      :rules="{ required: true,  between:[0,editable.sensorLevel] }"
      v-slot="v"
    >
      <b-form-group label="Nível Máximo" class="col-sm-6">
        <b-input-group append="m" :state="$validateState(v)">
          <b-form-input
            name="sensor.maxLevel"
            v-model="editable.maxLevel"
            type="number"
            min="0"
            :state="$validateState(v)"
          ></b-form-input>
        </b-input-group>
        <span class="invalid-input">{{ v.errors[0] }}</span>
      </b-form-group>
    </ValidationProvider>

    



  </b-row>
</template>

<script>
export default {
  data(){
    return{
      editable:undefined
    }
  },
  beforeMount(){
    this.editable = JSON.parse(JSON.stringify(this.value))
  },
  props: {
    value: { type: Object, required: true },
  },
  watch:{
    editable: {
      deep: true,
      handler(v) {
       this.$emit("input",v)
      },
    },
  },
};
</script>

<style></style>
