<template lang="html">
  <pageTemplate :sensor="sensor">
    <template v-slot:sensor>
      <div class="svg-image">
        <!-- <img class="pluvio-img" src="../../assets/pluviometer.svg" alt=""/> -->
        <!-- <waterSvg :width="'100%'" :height="180" :sensor="sensor" /> -->
        <pluvioInfo :sensor="sensor" />
      </div>
    </template>

    <template v-slot:info>
    
    </template>

    <pluvioRainHistoric :pluvio="sensor" />

  </pageTemplate>
</template>

<script>

import pluvio from "./sensPluvio";
import pluvioRainHistoric from "./pluvioRainHistoric";
import pluvioInfo from "./pluvioInfo";
import pageTemplate from "../sensorDetailsTemplate";

export default {
  extends: pluvio,

  components: {
    pluvioRainHistoric,
    pluvioInfo,
    pageTemplate,
  },
};
</script>

