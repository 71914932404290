<template>
  <b-container>
    <div class="config-page">
      <div class="header">
        <h2>Seu Perfil</h2>
        <small>
          <font-awesome-icon icon="info-circle" />
          Seus dados
        </small>
      </div>

      <div v-if="loadStatus == 'loaded'">
        <router-view :user="user" v-on:update="update($event)"></router-view>
      </div>
      <c-loading @retry="loadData" :status="loadStatus" v-else></c-loading>
    </div>
  </b-container>
</template>

<script>
export default {
  data() {
    return {
      user: undefined,
      loadStatus: String,
    };
  },
  beforeMount() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.loadStatus = "loading";
      this.$http.get("account/user/self").then(
        (data) => {
          this.user = data;
          this.loadStatus = "loaded";
        },
        (error) => {
          console.error("user error:", error);
          this.loadStatus = "error";
        }
      );
    },
    update(v) {
      this.user = v;
    },
  },
};
</script>

<style></style>
