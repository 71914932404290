<template>
  <div class="progress">
    <div class="progress-bar" :style="{width: normalBar}"></div>
    <div class="progress-bar bg-danger" :style="{width: dangerBar}"></div>
  </div>
</template>

<script>
export default {
  props: {
    value: { type: Number, default: 0 },
    max: { type: Number, default: 100 },
  },

  computed: {
    normalBar() {
      let diference = this.value - this.max
      if (diference < 0 ) diference = 0
      let val = ((this.value-diference)/(this.max+diference)) * 100;
      if (val > 100) val = 100;
      return Math.round(val) + "%";
    },

    dangerBar(){
       let diference = this.value - this.max
      if (diference < 0 ) diference = 0
      let val = (diference / (this.max+diference)) * 100;
      if (val > 100) val = 100;
      return Math.round(val) + "%";
    }

  },
};
</script>

<style></style>
