import culturePageList from './culturePageList'
import culturePageEdit from './culturePageEdit'
// import { Role } from "@/helpers/role";

export default {
      path:'/culture',
      component:culturePageList,
      name:'cultureList',
      children:[
        { path:':id/edit',  name:'cultureEdit', component:culturePageEdit,
          props: (route) => ({cultureId:route.params.id}),
          // meta: {authorize:[Role.Admin]},
          meta: {authorize:{action:'edit',resource:'Culture'}},
        },
        { 
          path:'new', name:'cultureNew', component:culturePageEdit,
          // meta: {authorize:[Role.Admin]},
           meta: {authorize:{action:'create',resource:'Culture'}},
        }
      ]
    }