<template>
        <canvas :style="{width: width, height: height}" ref="canvas"></canvas>
</template>
<script>

import {colorToPalett} from "./imageUtils"


export default {

    data(){
        return {
            ctx: undefined,
            canvas:undefined,
        }
    },
    

    props: {
        width: { type: String, default: "80px" },
        height: { type: String, default: "14px" },
        colorPalette:{type:Array,requerid:true},
    },


    mounted(){
        this.canvas = this.$refs.canvas
        this.ctx = this.canvas.getContext('2d');
        this.draw();
    }, 

    watch:{
        colorPalette(){
            this.draw()
        }
    },
    
    methods:{
        draw() {
            const colorstops = this.colorPalette
            let ctx = this.ctx
            let width = this.canvas.width
            let height = this.canvas.height
            // console.log(height,width)
            ctx.save();
            ctx.fillStyle = "transparent";
            ctx.clearRect(0, 0,  this.canvas.width, this.canvas.height);

            const grd = ctx.createLinearGradient(0, 0, width, 0);
            grd.addColorStop(0, "black");
            grd.addColorStop(1, "white");
            ctx.fillStyle = grd;
            ctx.fillRect(0, 0, width, height);

            const imageData = this.ctx.getImageData(0, 0, width, height);
            colorToPalett(imageData, 0, 1, colorstops)
            ctx.putImageData(imageData, 0, 0);
            ctx.restore()
        }
    }



}
</script>
<style lang="">
    
</style>