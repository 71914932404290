import irrigationReportPage from './irrigationReportPage'
import irrigationReportList from './irrigationReportList'

export default {
      path:'irrigation',
      // component:energyReport,
      name:'irrigationReportList',
      meta: {authorize:{action:'read',resource:'Report'}},
      component:irrigationReportList,
      children:[
        {
          path:':month',
          name:'irrigationReportDetails',
          component:irrigationReportPage,
          props: (route) => ({month:route.params.month}),
        }
      ]
    }