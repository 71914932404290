import store from "@store";
import SensorsUpdate from "./sensorsUpdate.js";

import "./sensor.scss";

var sensorsUpdate = new SensorsUpdate(store);

export default function() {
  return new Promise((resolve, reject) => {
    store.dispatch("sensors/loadSensors").then(
      () => {
        var clientId = store.getters["accounts/clientId"];
        sensorsUpdate.listen(clientId);
        resolve();
      },
      (error) => {
        reject(error);
      }
    );
  });
}
