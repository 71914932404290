<template>
  <div>


    <h6>{{ soil.name }} </h6>
    <p>Classe: <b>{{ soil.classification }}</b></p>

    <p><i>{{ soil.description }}</i></p>


    <div class="excessBox-container">
      <div class="layers-item">
        Caixa de excesso : <b>{{ soil.excessBox }}</b> <small>mm</small>
      </div>
      <div class="layers-item">
        Percolação da caixa: <b>{{ soil.excessPercolationRate }}</b> <small>mm/dia</small>
      </div>
    </div>
    <div class="layers-container" v-for="(layers, index) in soil.layers" :key="index">
      <div class="layers-item">
        Espessura: <b>{{ layers.depth }}</b> <small>cm</small>
      </div>
      <div class="layers-item">
        Capacidade de campo: <b>{{ layers.fieldCapacity }}</b> <small>%</small>
      </div>
      <div class="layers-item">
        Ponto de murcha: <b>{{ layers.wiltingPoint }}</b> <small>%</small>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  props: {
    soil: Object,
  },
};
</script>

<style lang="scss" scoped>
.layers-container,
.excessBox-container {
  display: flex;
  border-radius: 5px;

  padding: 5px;
  border: 1px solid rgb(179, 179, 179);
  margin-bottom: 5px;
  box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.2);

  .layers-item {
    flex: 1;
    /*grow*/
    //margin-left: 15px;
  }
}

.excessBox-container {
  background-color: rgb(203, 243, 255);
}

.layers-container {
  margin-left: 1rem;
  background-color: rgb(255 228 194);
}
</style>
