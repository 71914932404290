<template>
  <div class="loading-page">
    <div v-if="status === 'error'">
      <div class="image-container">
        <img src="@/assets/errorLoading.png" alt="" />
      </div>

      <div v-if="error!==undefined && [[502, 503].includes(error.response.status)]" class="message-container"> 
        <h1> {{error.response.status}} </h1>
        <h4>Serviço temporariamente Indispoível</h4>
        <p>
          Este serviço está temporariamente indispoível. Você pode tentar novamente mais tarde.
        </p>
      </div>

      <div v-else class="message-container">
        <h4>Não foi possível carregar os dados</h4>
        <p>
          Você pode estar sem conexão com a internet ou este serviço pode estar
          temporariamente indisponível.
          <span v-if="retrybutton">Tente clicar no botão abaixo para recarregar esta página.</span>
        </p>
        <div v-if="retrybutton" class="mt-5">
          <b-button variant="primary" @click="$emit('retry')">
            Tentar Novamente
          </b-button>
        </div>
      </div>
    </div>
    <div v-else>
      <slot>

        <div class="m-5">
          <slot name="before-spiner"></slot>
          <!-- <b-skeleton-img></b-skeleton-img> -->
          <b-spinner  variant="primary" label="Spinning"></b-spinner>
          <!-- <img src="@/assets/loading.gif" alt="" /> -->
          <!-- <div>Carregando...</div> -->
          <slot name="after-spiner"></slot>
        </div>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    status: [String],
    error: Object
  },
  computed: {
    retrybutton() {
      return this.$listeners && this.$listeners.retry;
      // return true;
    },
  },
};
</script>

<style lang="scss" scoped>
.loading-page {
  text-align: center;
  padding: 15px 16px 10px;

  .image-container {
    img {
      width: 120px;
    }

    padding-top: 40px;
    margin-right: 30px;
    display: inline-block;
  }

  .message-container {
    display: inline-block;
    vertical-align: top;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>
