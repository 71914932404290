<template>
  <div>

    <b-card>
    <div class="d-flex flex-wrap">
      <div>
        <h5>Histórico de chuvas</h5>
      </div>
      <div class="ml-auto">
        <data-range v-model="dateRange"/>
      </div>
    </div>

    <div class="mt-1" v-if="loadStatus == 'loaded'">
      <div v-if="data.length > 0">
        <div class="day-chart">
        <pluvioDayChart :rains="data" />
        </div>
        <hr/>
        <b-row>
          <b-col sm="12" md="6" lg="4">
            <h5>Distribuição diária</h5>
          </b-col>
          <b-col>
            <b-pagination
              class="ml-auto"
              v-model="currentPage"
              :total-rows="numRows"
              :per-page="perPage"
              aria-controls="my-table"
            ></b-pagination>
          </b-col>
          <b-col>
            <b-button
              :disabled= "savingFile"
              class="ml-auto"
              title="baixar dados .xlsx"
              size="sm"
              variant="success"
              @click="download()"
              ><font-awesome-icon icon="file-excel" /> baixar
            </b-button>
          </b-col>
        </b-row>
        <b-table
          class="text-center"
          responsive
          small
          sortBy="date"
          :sort-desc="true"
          :items="data"
          :fields="fields"
          :current-page="currentPage"
          :per-page="perPage"
        >
          <template v-slot:cell(hourlyData)="row">
            <div class="hourlyData-chart">
              <pluvioChart :data="row.item.hourlyData" />
            </div>
          </template>

          <template v-slot:cell(action)="row">
            <div v-if="$can('edit','sensorData')">
              <b-button size="sm" variant="primary" @click="editByDate(row.item.date)">
                <font-awesome-icon icon="edit"> </font-awesome-icon>
              </b-button>
            </div>
          </template>
        </b-table>
      </div>
      <div v-else class="text-center m-5 pt-5">
          <div>
            <img src="@/assets/rainSearch.svg" alt="" />
          </div>
          <div class="mt-3">
            <h5>Sem Chuvas</h5>
            <p>Sem dados de chuva no intervalo selecionado. Você pode tentar um intervalo diferente.</p>

              <!-- <b-button  v-if="$can('edit','sensorData')" size="sm" variant="primary" @click="editByDate(dateRange[1])">
                <font-awesome-icon icon="edit"> </font-awesome-icon> Inserir Manualmente
              </b-button> -->

              <b-button  v-if="$can('edit','sensorData')" size="sm" variant="primary" @click="editByDate()">
                <font-awesome-icon icon="edit"> </font-awesome-icon> Inserir Manualmente
              </b-button>
          </div>
    </div>
    </div>
    <c-loading v-else :status="loadStatus"></c-loading>
  </b-card>


  <pluvioRainEdit ref="edit-rain" :pluvioId="pluvio.id" @saved="loadInterval()"/>
 

    

  </div>
</template>

<script>
import { DateTime } from "luxon";

import pluvioChart from "./pluvioMiniChart";
import pluvioDayChart from "./pluvioDayChart";

import writeXlsxFile from 'denver-write-excel-file'
import pluvioRainEdit from './pluvioRainEdit'

var dateFormat = function(value) {
  return DateTime.fromISO(value).toLocaleString();
};

var rainFormatter = function(value) {
  return value + " mm";
};

export default {
  props: {
    pluvio: { type: Object, required: true },
  },
  components: {
    pluvioChart,
    pluvioDayChart,
    pluvioRainEdit
  },
  data() {
    return {
      loadStatus: "loading",
      data: [],
      dateRange: null,
      perPage: 10,
      currentPage: 1,
      savingFile:false,

      fields: [
        { key: "date", label: "Data", formatter: dateFormat, sortable: true },
        {
          key: "precipitation",
          label: "Total",
          formatter: rainFormatter,
          sortable: true,
        },
        { key: "hourlyData", label: "Por Hora", sortable: false },
        { key: "action", label: "", sortable: false },
      ],
    };
  },
  mounted() {
    let end = DateTime.local()
      .endOf("day")
      .toJSDate();
    let start = DateTime.local()
      .plus({ hours: -this.pluvio.defaultPeriod })
      .toJSDate();
    this.dateRange = [start, end];
  },

  watch: {
    dateRange() {
      this.loadInterval();
    },
  },
  methods: {

    loadInterval() {
      this.loadStatus = "loading";
      let start = DateTime.fromJSDate(this.dateRange[0]).toISO({includeOffset: false});
      let end = DateTime.fromJSDate(this.dateRange[1])
        .plus({ days: 1 })
        .toISO({ includeOffset: false });

      this.$http
        .get(
          "/sens/pluviometer/" +
            this.pluvio.id +
            "/rain?start=" +
            start +
            "&end=" +
            end
        )
        .then(
          (data) => {
            this.data = data;
            this.loadStatus = "loaded";
          },
          (error) => {
            console.error("load pluviometer rain error:", error);
            this.loadStatus = "error";
          }
        );
    },

    
    
    editByDate(date){ 
      // if ( Object.prototype.toString.call(date) === "[object Date]") {
      //   this.$refs["edit-rain"].loadEdit(DateTime.fromJSDate(date).toISO({includeOffset: false}));
      // }else{
        this.$refs["edit-rain"].show(date);
      // }
    },


    async download(){

      this.savingFile = true;
          let rows = []
          let strStartDate = DateTime.fromJSDate(this.dateRange[0]).toLocaleString()
          let strEndDate = DateTime.fromJSDate(this.dateRange[1]).toLocaleString()

          let headerColor = "#dde8cb"

          rows.push([{
            value: 'Histórico de chuvas',
            span:26,alignVertical:'center',fontSize:12,fontWeight: 'bold',
            height:30,
            align:'center',
            backgroundColor:headerColor
          }]);

          rows.push([
            {value: "Pluviômetro", backgroundColor:headerColor},
            {value: this.pluvio.name, backgroundColor:headerColor},
            {span:24,backgroundColor:headerColor,rightBorderStyle:"thin"}
          ]);

          rows.push([
            {value: "Período", backgroundColor:headerColor},
            {value: strStartDate +" ~ "+strEndDate,backgroundColor:headerColor},
            {span:24,backgroundColor:headerColor,rightBorderStyle:"thin"}
          ]);

          rows.push([{span:26,backgroundColor:headerColor,rightBorderStyle:"thin"}])

          let headers = [
            { value:'Data', align:'center', borderStyle:"thin", backgroundColor:headerColor},
            { value:'Total Dia (mm)', align:'center', borderStyle:"thin", backgroundColor:headerColor},
          ]
          
          for (let i= 0; i < 24; i++){
            headers.push( { value:(i+'h'), align:'center', borderStyle:"thin", backgroundColor:headerColor},)
          }

        
          rows.push(headers);

          // row.item.hourlyData
      

          this.data.forEach((e,index) => {
            
            let rowNumber = index+6

            let col = [
              {value:DateTime.fromISO(e.date).toJSDate(),borderStyle:"thin", type: Date,format: 'dd/mm/yyyy'},
              // {value:e.precipitation,type:Number, borderStyle:"thin"}
              {value:"=sum(c"+rowNumber+":z"+rowNumber+")",type:"Formula", borderStyle:"thin"}
            ]
        
            for (let i= 0; i < 24; i++){
              col.push(  {value:e.hourlyData[i],type:Number, borderStyle:"thin"},)
            }
           
            rows.push(col);
          });


          rows.push([
            { value:'Total', align:'center', borderStyle:"thin", backgroundColor:headerColor},
            { value:"=sum(b6:b"+(this.data.length+5)+")",type:"Formula", borderStyle:"thin",backgroundColor:headerColor},
            { span:24,borderStyle:"thin",backgroundColor:headerColor}
          ]);


          let columns = [
          {width: 10},
          {width: 15}
          ]

          for (let i= 0; i < 24; i++){
            columns.push(  {width: 6},)
          }


          await writeXlsxFile(rows, {
            columns,
            fileName: this.pluvio.name+"("+strStartDate +" ~ "+strEndDate+").xlsx",
            stickyRowsCount: 5
          })
          this.savingFile = false;
    }


  },
  computed: {
    numRows() {
      if (this.data !== undefined) return this.data.length;
      else return 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.hourlyData-chart {
  height: 50px;
  padding: 5px;
}

.day-chart{
  background-color: $gray-25;
}
</style>
