<template>
  <v-chart :style="{ width:width, height:height}" :option="serie" autoresize />
</template>

<script>


export default {
  props: {
    data: Array,
    width:{type:[Number,String], default:'100%'},
    height:{type:[Number,String], default:'200px'}
  },

  computed: {

    dataColor(){

      return this.data.map((element)=>{
        
        let color = 'red'
        if (element.name == 'reservado') color = '#91cc75'
        if (element.name == 'ponta') color = '#ee6666'
        if (element.name == 'fora de ponta') color = '#fac858'


        return {...element,
          
          itemStyle: {
            color: color}
          }

      })

    },


    serie: function() {
      let data = this.data;
      if (!data) return;

      return {
        tooltip: {
          trigger: "item",
          //formatter: "{a} <br/>{b}: {c} ({d}%)"
          formatter: "{b} {d}%",
        },
        legend: { orient: 'vertical', x: 'left'},
        series: [
          {
            name: "uso",
            type: "pie",
            // radius: ['10%', '70%'],
            radius: "55%",
            center: ["40%", "50%"],
            // avoidLabelOverlap: false,
            //  label: { normal: { show: false, position: 'center'},
            //  	emphasis: { show: true, textStyle: { fontSize: '30', fontWeight: 'bold' }}
            //  },
            //labelLine: {normal: {show: false}},
            data: this.dataColor,
          },
        ],
      };
    },
  },
};
</script>

