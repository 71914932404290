<template>
  <div>
    <div id="printDiv" class="print-only">
      <reportobj printMode v-if="loadStatus == 'loaded'" v-model="reportData" />
    </div>

    <b-modal ref="report-irrigation-modal" no-fade hide-footer title="Relatório de irrigação" @hidden="close"
      size="max-xl" body-bg-variant="light">
      <div v-if="loadStatus == 'loaded'">
        <div class="d-flex p-1 print-display-none">
          <div>
            <h4 class="mb-0">{{ $filters.longMonth(reportData.referenceMonth) }}</h4>
            <span>{{ $filters.ptbrdate(reportData.dateInterval.start) }} -
              {{ $filters.ptbrdate(reportData.dateInterval.end) }}
            </span>
          </div>
          <div class="ml-auto buttons-container">

            <b-button class="mr-3" v-if="$can('delete', 'Report')" size="sm" variant="outline-danger"
              @click="deleteReport" title="apagar">
              <font-awesome-icon icon="trash" />
            </b-button>


            <b-button v-if="$can('delete', 'Report')" size="sm" variant="outline-warning" @click="recreateReport"
              title="reprocessar">
              <font-awesome-icon icon="recycle" />
            </b-button>


            <b-button size="sm" variant="outline-primary" @click="$printDiv('printDiv')" title="imprimir">
              <font-awesome-icon icon="print" />
            </b-button>

            <b-button size="sm" variant="outline-success" @click="exportExcel()" title="excel">
              <font-awesome-icon icon="file-excel" />
            </b-button>

          </div>
        </div>
        <hr />
        <reportobj v-model="reportData" :editable="$can('edit', 'Report')" @report-saved="updateReport($event)" />
      </div>
      <c-loading :status="loadStatus" v-else> </c-loading>
    </b-modal>
  </div>
</template>

<script>
import reportobj from "./report/report";
import { mapGetters } from "vuex";
// import writeXlsxFile from 'write-excel-file'
import writeXlsxFile from 'denver-write-excel-file'

import { createFieldCells, createBillCells } from './irrigReportExcel'


export default {
  data() {
    return {
      reportData: undefined,
      loadStatus: "",
    };
  },

  props: {
    month: String,
  },

  components: {
    reportobj,
  },

  watch: {
    month() {
      this.loadReport();
    },
  },

  computed: {
    ...mapGetters({
      user: "accounts/user",
      client: "accounts/client",
      workspace: "accounts/workspace",
      consultant: "accounts/consultant"
    }),
  },


  methods: {

    async exportExcel() {

      const columns = [
        { width: 3 },
        { width: 10 }, //equip
        { width: 10 }, //parcela
        { width: 9 }, //cultura
        { width: 8 }, //area
        { width: 8 }, //dias
        { width: 10 },
        { width: 10 },
        { width: 10 },
        { width: 10 },
        { width: 10 },
        { width: 10 },
        { width: 10 },
        { width: 10 },
        { width: 10 },
        { width: 10 },
        { width: 10 },
        { width: 10 },
        { width: 12 },
      ]
      let rows = []

      let value = this.reportData
      // console.log(this.reportData)

      let headerColor = "#dde8cb"


      let formatkpi = { fontSize: 10, borderStyle: "thin", align: 'center', height: 24, backgroundColor: headerColor, alignVertical: 'top', span: 2 }
      // let formatNumbers = { align: 'center', type: Number }
      let formatLegend = { fontWeight: 'bold', type: String, height: 32 }
      let formatTitle = { fontWeight: 'bold', type: String, height: 64, align: 'center', alignVertical: 'center', fontSize: 14 ,backgroundColor:'#f4f4f4'}


      rows.push([null, { value: 'Relatório de Gerenciamento de Irrigação (' + this.$filters.longMonth(value.referenceMonth) + ')', ...formatTitle, span: 18 }])


      let subTitleFormat = { height: 24, alignVertical: 'top', fontSize: 12,backgroundColor:'#f4f4f4'}

      rows.push([
        null,
        { value: (this.$filters.ptbrdate(value.dateInterval.start) + " ~ " + this.$filters.ptbrdate(value.dateInterval.end)), ...subTitleFormat, span: 5 }, null, null, null, null,
        { value: this.client.name, span: 8, ...subTitleFormat, align: 'center', }, null, null, null, null, null, null, null,
        { value: 'Consultor: ' + this.consultant.name, span: 5, ...subTitleFormat, align: 'right' }, null, null,
      ])



      rows.push([null, { height: 32, topBorderStyle: "thin", span: 18 }])

      rows.push([
        null,
        { value: 'Custo Parcelas', ...formatkpi, type: String }, null,
        { value: 'Área Gerenciada\n(ha)', ...formatkpi, type: String }, null,
        { value: 'Consumo de água\n(mm)', ...formatkpi, type: String }, null,
        { value: 'Consumo de água\n(m³)', ...formatkpi, type: String }, null,
        { value: 'Energia Reativa', ...formatkpi, type: String }, null,
        { value: 'Horário Reservado\n(%)', ...formatkpi, type: String }, null,
        { value: 'R$/ha', ...formatkpi, type: String }, null,
        { value: 'R$/mm/ha', ...formatkpi, type: String }, null,
        { value: 'R$/kWh', ...formatkpi, type: String }, null,
      ])


      let formatkpiNumbers = { align: 'center', type: Number, height: 24, alignVertical: 'center', span: 2, fontSize: 12 }
      rows.push([
        null,
        { value: value.totalCost, ...formatkpiNumbers, borderStyle: "thin", format: '[$R$-416] #,##0.00', fontWeight: 'bold',color:'#f00000' }, null,
        { value: value.totalAccumulated.areaHa, ...formatkpiNumbers, borderStyle: "thin", format: '##0.00' }, null,
        { value: value.totalMonth.irrigation, ...formatkpiNumbers, borderStyle: "thin", format: '##0.00' }, null,
        { value: value.totalMonth.waterM3, ...formatkpiNumbers, borderStyle: "thin", format: '##0.00' }, null,
        { value: value.reactiveEnergyCost, ...formatkpiNumbers, borderStyle: "thin", format: '[$R$-416] #,##0.00' }, null,
        { value: value.reservedPowerRatio * 100, ...formatkpiNumbers, borderStyle: "thin", format: '##0.0' }, null,
        { value: value.costPerHa, ...formatkpiNumbers, borderStyle: "thin", format: '[$R$-416] #,##0.00' }, null,
        { value: value.costPerMmPerHa, ...formatkpiNumbers, borderStyle: "thin", format: '[$R$-416] #,##0.00' }, null,
        { value: value.costPerKWh, ...formatkpiNumbers, borderStyle: "thin", format: '[$R$-416] #,##0.00' }, null,
      ]
      )

      rows.push([])

      rows.push([null,
        { value: 'Composição do consumo', ...formatLegend, span: 8 }, null, null, null, null, null, null, null, 
        null,
        { value: 'Consumo de energia - Considerações', ...formatLegend, span: 9 },
      ])

      let billRows = createBillCells(this.reportData.bill, this.reportData.energyComments)
      rows = rows.concat(billRows)


      rows.push([])

      // rows.push([
      //   null,
      //   { value: "Consumo de energia - Considerações", span: 8, height: 24, fontSize: 12 }
      // ])
      // rows.push([
      //   null,
      //   { value: this.reportData.energyComments, span: 8, height: 64, alignVertical: 'top', wrap: true, borderStyle: "thin" }
      // ])




      rows.push([])
      rows.push([null, { height: 32, bottomBorderStyle: "thin", span: 18 }])

      // console.log(this.reportData)

      rows.push([null, { value: 'Gestão de Irrigação (considera o período de leitura das contas)', ...formatLegend, span: 16 }])
      let irrifRows = createFieldCells(this.reportData)
      rows = rows.concat(irrifRows)


      rows.push([])
      rows.push([
        null,
        { value: "Manejo de irrigação - Considerações", ...formatLegend, span: 11, height: 24 }
      ])
      rows.push([
        null,
        { value: this.reportData.irrigationComments, span: 11, height: 128, alignVertical: 'top', wrap: true, borderStyle: "thin", fontSize:12}
      ])


      await writeXlsxFile(rows, {
        columns,
        showGridLines: false,
        orientation: 'landscape',
        sheet: 'Completo',
        fileName: "Relatorio de Irrigacao " + this.$filters.longMonth(value.referenceMonth) + " (" + this.client.name + ").xlsx"
      })

    },


    loadReport() {
      this.loadStatus = "loading";
      this.$http.get("/report/irrigation-report/" + this.month).then((data) => {
        this.reportData = data;
        this.loadStatus = "loaded";
      },
        (error) => {
          console.error("loading report error", error);
          this.loadStatus = "error";
        });
    },

    updateReport(report) {
      let sendReport = {
        energyComments: report.energyComments,
        irrigationComments: report.irrigationComments,
        author: this.user.name
      };
      this.$http
        .save("/report/irrigation-report", sendReport, this.month)
        .then((data) => {
          this.reportData = data;
        },
          (error) => {
            console.error("save report error", error);
            this.$bvToast.toast('Não foi possível salvar', {
              title: "Erro",
              variant: 'danger',
              solid: true
            })
          });

      //console.log("report update", sendReport);
      // console.print(report);
    },

    deleteReport() {
      this.$bvModal
        .msgBoxConfirm('Deseja realmente apagar este relatório', {
          title: "Confirmar Exclusão!",
          size: "md",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Sim",
          cancelTitle: "Não",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value === true) {
            this.$http
              .delete("/report/irrigation-report/" + this.month)
              .then(() => {
                this.$emit("report-deleted");
                this.$router.replace({ name: "irrigationReportList" });
              });
          }
        });
    },


    recreateReport() {
      this.loadStatus = "loading";
      this.$http
        .put("/report/irrigation-report/" + this.month + "/recreate")
        .then((data) => {
          this.$emit("update-object", data);
          this.reportData = data;
          this.loadStatus = "loaded";
        });
    },

    close() {
      this.$router.push({ name: "irrigationReportList" });
    },
  },
  mounted() {
    this.$refs["report-irrigation-modal"].show();
    this.loadReport();
  },
};
</script>

<style></style>
