<template>
    <div>
        <div v-if="loadStatus == 'loaded'">
            <div class="weather-chart">
                <weathersChart :weathers="weathersRains" />
            </div>
            

            <div class="weather-table mt-3">
                <weathersTable :stationId="station.id" :weathers="weathersRains" :editable="editable && $can('edit', $subject('WeatherStation',station) )"
                    @update-weather="updateWeather($event)" @remove-weather="removeWeather($event)" />
            </div>
        </div>
        <c-loading v-else :status="loadStatus"></c-loading>
    </div>
</template>
<script>

import { DateTime } from "luxon";
import weathersTable from "./weathersTable";
import weathersChart from "./weathersChart";
export default {

    data() {
        return {
            loadStatus: "loading",
            weathers: [],
            numRows: 1,
        }
    },

    computed:{
        weathersRains(){
            if(this.rains){
                let rains = this.rains
               return this.weathers.map(function(weather){

                    let weatherCopy = JSON.parse(JSON.stringify(weather))

                    var newRain  = rains.find( function(rain){
                        return rain.date === weatherCopy.date
                    })
                    
                    if (newRain)
                        weatherCopy.rain = newRain.rain
                    else
                        weatherCopy.rain = 0

                    return weatherCopy 
                })
            }else{
                return this.weathers
            }
        }
    },

    props: {
        rains:Array,
        station: { type: Object, requerid: true },
        dateRange: { type: Array },
        editable: { type: Boolean, default: true }
    },
    mounted() {
        this.loadWeathers();
    },


    watch: {
        dateRange() {
            //console.log("dataRangeMudou")
            this.loadWeathers();
        },
    },
    components: {
        weathersTable,
        weathersChart,
    },

    methods: {

        setLoading() {
            this.loadStatus = "loading";
        },

        updateWeather(weather) {
            const index = this.weathers.findIndex((e) => e.id === weather.id);
            if (index === -1) {
                this.weathers.push(weather);
            } else {
                let dataObj = this.weathers[index];
                Object.entries(weather).forEach(([key, value]) => {
                    dataObj[key] = value;
                });
            }
        },

        loadWeathers() {
            
            this.loadStatus = "loading";

            let start = typeof this.dateRange[0] === 'string'?this.dateRange[0]:DateTime.fromJSDate(this.dateRange[0]).toISODate();
            let end = typeof this.dateRange[1] === 'string'?this.dateRange[1]:DateTime.fromJSDate(this.dateRange[1]).toISODate();

            this.$http
                .get(
                    "irrig/station/" + this.station.id + "/weather/" + start + "/" + end
                )
                .then(
                    (data) => {
                        this.weathers = data;
                        this.loadStatus = "loaded";
                        this.numRows = this.weathers.length;
                    },
                    (error) => {
                        this.loadStatus = "error";
                        console.error(error);
                    }
                );
        },

        removeWeather(id) {
            this.$bvModal
                .msgBoxConfirm("Deseja realmente apagar este clima??" + id, {
                    title: "Por favor confirme",
                    size: "sm",
                    buttonSize: "sm",
                    okVariant: "danger",
                    okTitle: "Sim",
                    cancelTitle: "Não",
                    footerClass: "p-2",
                    hideHeaderClose: false,
                    centered: true,
                })
                .then((value) => {
                    if (value === true) {
                        this.buttonReloadDisabled = true;
                        this.loadStatus = "loading";
                        this.$http
                            .delete("irrig/station/" + this.station.id + "/weather/" + id)
                            .then(
                                () => {
                                    this.loadWeathers();
                                },
                                () => {
                                    this.$bvModal.msgBoxOk(
                                        "Não foi possível executar essa operação.",
                                        {
                                            title: "Erro!",
                                        }
                                    );
                                }
                            );
                    }
                });
        },

    }

}
</script>
<style lang="">
    
</style>