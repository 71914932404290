<template>
    <div class="culture-fases mt-2">

        <div v-for="stage, index in stages" class="fase" :key="index"
            :style="{ width: stage.width + '%', backgroundColor: stage.color }">
            {{ stage.name }}
            <span class="date"> {{ stage.date }} </span>
            <span class="day"> {{ stage.day }} </span>
        </div>

        <div v-if="currentDay" class="current" :style="{left:+ currentPos+'%'}"> </div>

    </div>
</template>
<script>

import { DateTime } from 'luxon';

export default {

    props: {
        culture: { type: Object },
        datePlanting: { type: String },
        currentDay:{type:Number}
    },

    computed: {

        currentPos(){
            let totalDay = 0
            this.culture.stages.forEach(e => { totalDay += Number(e.durationDays) })
            return (this.currentDay / totalDay) * 100
        },

        stages() {

            let day = 0
            let totalDay = 0
            this.culture.stages.forEach(e => { totalDay += Number(e.durationDays) })
            let colors = ['#D68910', '#597E52', '#5F0F40', '#6D2932', '#706233']
            let ret = []
            this.culture.stages.forEach((e, i) => {

                let durationDays = Number(e.durationDays)
                let date = ''


                if (this.datePlanting)
                    date = DateTime.fromISO(this.datePlanting).plus({ days: day }).toLocaleString()


                ret.push(
                    {
                        day: day,
                        date: date,
                        name: e.name,
                        width: (durationDays / totalDay) * 100,
                        color: colors[i % 5]
                    },
                )
                day += durationDays
            });


            // let ret = [
            //     { day: 0, date: '01/02/2024', name: 'fase1', width: 10 },
            //     { day: 10, date: '10/02/2024', name: 'fase2', width: 20 },
            //     { day: 20, date: '30/02/2024', name: 'fase3', width: 25 },
            //     { day: 30, date: '01/03/2024', name: 'fase4', width: 30 }
            // ]


            return ret;
        }
    }

}
</script>
<style lang="scss" scoped>
$lineColor: #c4c1c1;
$irrigColor: #5ba9d6;

$left-margin : 100px;


.culture-fases {
    display: flex;
    overflow: hidden;
    height: 5rem;
    line-height: 0;
    font-size: .75rem;
    // background-color: #e9ecef;
    // border-radius: 0.25rem;
    position: relative;
    

    .current {
        position: absolute;
        top:1.5rem;

        &::before {
            content: "";
            translate: -4px 0;
            display:inline-block;
            border: 7px solid transparent;
            border-top:8px solid rgb(56, 56, 56);
            border-bottom:0 none;
        }

    }


    .fase {
        line-height: 0.95rem;
        flex-direction: column;
        -ms-flex-pack: center;
        justify-content: center;
        color: #ffffff;
        text-align: center;
        white-space: nowrap;
        background-color: #007bff;
        height: 0.85rem;
        position: relative;
        margin-top: 2rem;
        // border-radius: 2px;
        transition: width .6s ease;
        box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.5);


        &::before {
            content: "";
            position: absolute;
            left: 0px;

            top: -0.3rem;
            width: 0.25rem;
            height: 1.5rem;
            background: inherit;
            border-radius: 2px;

            // top: -0.3rem;
            // width: 1.5rem;
            // height: 1.5rem;
            // background: inherit;
            // border-radius: 50%;
        }


        .day {
            color: #6e6e6e;
            position: absolute;
            left: 0px;
            top: -1.75rem;
            font-size: 1.25rem;
        }

        .date {
            color: #6e6e6e;
            position: absolute;
            left: 0;
            bottom: -1.5rem;
        }

    }


}
</style>