<template>
  <div @click="userDetails(user.id)" class="card mb-3 user-card shadow">
    <div class="row no-gutters">
      <div class="col-md-4">
        <!-- <img :src="require('../assets/user.png')" alt="User profile picture" /> -->

         <!-- <img :src="$avatarUrl+user.id+'.jpg'" /> -->
          <!-- <b-img thumbnail center rounded="circle" fluid :src="$avatarUrl+user.imageSrc"></b-img> -->
          <Avatar :username="user.name" :src="$avatarUrl+user.imageSrc" :size="100"/>
          <!-- <b-img v-if="user.imageSrc==null" thumbnail center rounded="circle" fluid :src="require('../assets/user.png')" alt="User profile picture" />
          <b-img v-else thumbnail center rounded="circle" fluid :src="$avatarUrl+user.imageSrc"></b-img> -->


      </div>

      <div class="col-md-8">
        <div class="card-body">
          <h5 class="card-title">{{user.name}}</h5>
          <p class="card-text">@{{user.username}}</p>
          <p class="card-text">
            <small class="text-muted">{{user.about}}</small>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Avatar from "../avatar/Avatar";

export default {
  props: {
    user: { type: Object, required: true }
  },
  methods:{
      userDetails(id){
      this.$router.push({name:'userDetails',params:{id:id}})
    },
  },
  components:{
    Avatar
  }
};
</script>

<style lang="scss" scoped>
.user-card {
  padding: 10px;
  min-height: 200px;
  cursor: pointer;
}
</style>