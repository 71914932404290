<template lang="html">

  <sensorWidget :sensor="sensor" :color="'#b7dad7'">
    <template v-slot:icon>
      <img class="pluvio-img" src="../../assets/pluviometer.svg" alt=""/>
    </template>

    <template v-slot:content>
      <div>
      <div class="widget-box-value">
        <span class="label"> lâmina hoje</span>
        <span class="value">
          {{$filters.round(precipitation,2)}}<small> mm </small>
        </span>
      </div>

      <div class="chart">
        <pluvioChart :data="hourly"/>
      </div>
      </div>
    </template>
  </sensorWidget>

</template>

<script>
import pluvioChart from './pluvioMiniChart.vue'
import sensPluvio from './sensPluvio.js'
import sensorWidget from '../sensorWidget.vue'
export default {
  extends:sensPluvio,
  components:{
    pluvioChart,
    sensorWidget
  }
}
</script>

<style lang="scss" scoped>
  .chart{
    width: 100%;
    height: 50px;
  }
.pluvio-img{
  width: 80px;
  height: 80px;
}
</style>
