<template>
  <div>
    <b-navbar type="dark" fixed="top" class="nav-shadow custom-navbar">
      <!-- <b-button size="sm" v-b-toggle.sidebar-tool class="mr-3">...</b-button> -->
      <!-- <b-navbar-toggle target="sidebar-tool"></b-navbar-toggle> -->

      <!-- LOGO E TROCA DE GRUPO -->
      <b-navbar-nav>
        <b-nav-item-dropdown class="dropdown-styled" no-caret>
          <template v-slot:button-content>
            <div class="kalliandra-brand">
              {{ workspace.name }}
              <div class="brand-small">
                <span
                  class="d-inline-block text-truncate"
                  style="max-width: 100px"
                >
                  {{ workspace.subtitle }}
                </span>
              </div>
            </div>
          </template>

          <div class="dropdown-head">
            <div class="fa">
              <font-awesome-icon icon="building" class="item-icon" />
            </div>
            <div class="title">
              {{ workspace.name }}
            </div>
            <div class="subtitle">{{workspace.subtitle}}</div>
          </div>
          <b-dropdown-divider></b-dropdown-divider>
          <h3>Mudar para...</h3>

          <b-dropdown-item class="dropdown-head"
            v-for="ws in workspaces"
            :key="ws.id"
            @click="changeWorkspace(ws)"
            >
            <div class="fa">
              <font-awesome-icon icon="building" />
            </div>
            <div class="title">{{ ws.name }}</div>
            <div class="subtitle">{{ ws.subtitle }}</div>
          </b-dropdown-item
          >
        </b-nav-item-dropdown>
      </b-navbar-nav>



      <!-- <b-navbar-nav class="d-none d-sm-flex">

        <template  v-for="(item,index) in navBarItens" >
          
        <component :is="!item.child?'b-nav-item':'b-nav-item-dropdown'"
          v-if="!item.permission || $can(item.permission.operation, item.permission.object)"
          :key="index"
          :to="item.name?{name:item.name}:undefined"
          :title="item.title" 
          :active="$route.name === item.name"
        >

        
        <template  v-slot:button-content>
            <font-awesome-icon :icon="['fal', item.icon]" class="item-icon" />
        </template>
        <font-awesome-icon  v-if="!item.child" :icon="['fal', item.icon]" class="item-icon" />




        <b-dropdown-item 
          v-for="(item,index) in item.child" 
          :key="'sub-'+index"
          :to="{name:item.name}"
        >
        <font-awesome-icon  v-if="!item.child" :icon="['fal', item.icon]" class="item-icon" />
        {{ item.title }}
      </b-dropdown-item>
        
      </component>

        </template>

      </b-navbar-nav> -->
      



      <!-- Ítens à esquerda -->
      <b-navbar-nav class="d-none d-sm-flex">
        <b-nav-item
          :to="{ name: 'home' }"
          title="home"
          :active="$route.name === 'home'"
        >
          <font-awesome-icon icon="home" class="item-icon" />
        </b-nav-item>

        <b-nav-item
          v-if="$can('read', 'Soil')"
          :to="{ name: 'soilList' }"
          title="Solos"
          :active="$route.name === 'soilList'"
        >
          <font-awesome-icon icon="layer-group" class="item-icon" />
        </b-nav-item>

        <b-nav-item
          v-if="$can('read', 'Culture')"
          :to="{ name: 'cultureList' }"
          title="Culturas"
          :active="$route.name === 'cultureList'"
        >
          <font-awesome-icon icon="seedling" class="item-icon" />
        </b-nav-item>

        <b-nav-item
          v-if="$can('edit', 'InsertData')"
          :to="{ name: 'insertData' }"
          title="Lançar dados"
          :active="$route.name === 'insertData'"
        >
          <!-- <font-awesome-icon icon="clipboard" class="item-icon" /> -->
          <kli-calendar-edit />
        </b-nav-item>

        <b-nav-item-dropdown title="mais">
          <template v-slot:button-content>
            <font-awesome-icon icon="table" class="item-icon" />
          </template>

          <b-dropdown-item :to="{ name: 'stationList' }">
            <font-awesome-icon :icon="['fal', 'weather-station']" class="nav-icon" />
            Estações
          </b-dropdown-item>

          <b-dropdown-item :to="{ name: 'pluvioList' }">
            <font-awesome-icon :icon="['fal', 'pluviometer']" class="nav-icon" />
            Pluviômetros
          </b-dropdown-item>

          <b-dropdown-item
            v-if="$can('create', 'Equipment')"
            :to="{ name: 'equipmentNew', params: { id: undefined } }"
            >
            <font-awesome-icon :icon="['fal', 'circle-small']" class="nav-icon" />
            Cadastrar Equipamento
          </b-dropdown-item >
        </b-nav-item-dropdown>

        <!-- RELATORIOS -->
        <b-nav-item-dropdown title="relatórios">
          <template v-slot:button-content>
            <kli-file-chart />
          </template>

          <b-dropdown-item
            v-if="$can('read', 'ConsumerStation')"
            :to="{ name: 'electricityPage' }"
          >
            <font-awesome-icon icon="plug" class="item-icon" /> Energia Elétrica
          </b-dropdown-item>

          <b-dropdown-item
            v-if="$can('read', 'Report')"
            :to="{ name: 'irrigationReportList' }"
          >
            <kli-hand-holding-seedling /> Relatório de irrigação
          </b-dropdown-item>

          <b-dropdown-item
            v-if="$can('read', 'Report')"
            :to="{ name: 'fieldsReportMetrics' }"
          >
            <kli-hand-holding-seedling /> Resumo de Parcelas
          </b-dropdown-item>

        </b-nav-item-dropdown>

        <b-nav-item
          :to="{ name: 'rainHeatMap' }"
          title="Mapa de chuva"
          :active="$route.name === 'rainHeatMap'"
        >
          <font-awesome-icon icon="cloud-rain" class="item-icon" />
        </b-nav-item>

        <b-nav-item
          :to="{ name: 'ndviPageList' }"
          title="NDVI"
          :active="$route.name === 'ndviPageList'"
        >
          <font-awesome-icon icon="satellite" class="item-icon" />
        </b-nav-item>

        <b-nav-item
          v-if="$can('read', 'Channel')"
          :to="{ name: 'channelList' }"
          title="Canais de comunicação"
          :active="$route.name === 'channelList'"
        >
          <font-awesome-icon icon="rss-square" class="item-icon" />
        </b-nav-item>

        <b-nav-item
          v-if="$can('read', 'Channel')"
          :to="{ name: 'sensorUptime' }"
          title="Funcionamento dos Sensores"
          :active="$route.name === 'sensorUptime'"
        >
          <font-awesome-icon icon="stopwatch" class="item-icon" />
          </b-nav-item
        >

        <b-nav-item
          v-if="$can('read', 'Channel')"
          :to="{ name: 'apiService' }"
          title="Serviços de API"
          :active="$route.name === 'apiService'"
        >
          <font-awesome-icon icon="gear" class="item-icon" />
          </b-nav-item
        >

        

      </b-navbar-nav>

      <!-- Ítens à direita -->
      <b-navbar-nav class="ml-auto">
        <b-nav-item-dropdown
          class="dropdown-styled"
          right
          v-if="client"
          :text="client.name"
        >
          <template v-slot:button-content>
            <span class="d-inline-block text-truncate" style="max-width: 150px">
              {{ client.name }}
            </span>
          </template>
          <div class="dropdown-head">
            <div class="fa">
              <!-- <font-awesome-icon icon="home" class="item-icon" /> -->
              <kli-farm class="item-icon"/>
            </div>
            <div class="title">{{ client.name }}</div>
            <div class="subtitle">Fazenda atual</div>
          </div>
          <b-dropdown-divider></b-dropdown-divider>
          <h3>Mudar para...</h3>

          <b-dropdown-item
            v-for="cl in clients.slice(0, 8)"
            :key="cl.id"
            @click="changeClient(cl)"
            > <span class="d-inline-block text-truncate" style="max-width: 250px" >{{ cl.name }} </span></b-dropdown-item
          >

          <b-dropdown-item v-if="clients.length > 8" @click="showAllClients()">
            <span class="font-italic font-weight-bold"> Mais ...</span>
          </b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>

      <b-navbar-nav class="user-itens">
        <b-nav-item-dropdown right>
          <template v-slot:button-content>
            <Avatar
              class="avatar"
              :username="user.name"
              :src="$avatarUrl + user.imageSrc"
              :size="28"
            />
          </template>

          <div class="quick-switcher">
            <b-dropdown-item :to="{ name: 'user-account' }">
              <font-awesome-icon icon="cogs" class="item-icon" />Configurações
              <div class="item-note">
                Edite suas configurações, clientes e grupo
              </div>
            </b-dropdown-item>

            <b-dropdown-divider></b-dropdown-divider>

            <b-dropdown-item @click="logout">
              <font-awesome-icon icon="lock" class="item-icon" />Sair
              <div class="item-note">Fazer logout do sistema</div>
            </b-dropdown-item>
          </div>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-navbar>


    

    <clientSelect ref="client-select"/>
   
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Avatar from "@components/account/avatar/Avatar";
import clientSelect from "./clientSelect.vue";
import navBarItens from "./navaBarItens.json"



export default {

  methods: {
    logout() {
      this.$http.clearToken();
      this.$router.push({ name: "login" });
    },
    changeWorkspace(ws) {
      this.$store.dispatch("accounts/changeWorkspace", ws.id);
    },
    changeClient(cl) {
      this.$store.dispatch("accounts/changeClient", cl.id);
    },
    showAllClients() {
            this.$refs["client-select"].show();
    },
    
  },
  computed: {

    navBarItens(){
      return navBarItens
    },


    ...mapGetters({
      user: "accounts/user",
      workspace: "accounts/workspace",
      workspaces: "accounts/workspaces",
      client: "accounts/client",
      clients: "accounts/clients",
    }),
  },
  components: {
    Avatar,
    clientSelect
  },
};
</script>

<style lang="scss" scoped>
.nav-shadow {
  box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.25),
    inset 0 -1px 5px rgba(0, 0, 0, 0.25);
}

.user-itens {
  .item-icon {
    margin-right: 5px;
    width: 20px;
  }
  .item-note {
    font-family: "Open Sans", Helvetica, Arial, sans-serif;
    font-size: 11px;
    font-weight: 400;
    line-height: 15px;
    color: var(--fadedTextColor);
    margin: 0;
    padding: 0;
    padding: 0 0 0 30px;
    color: #666;
  }

  .avatar {
    display: inline-block !important;
    margin: -10px 0px;
  }
}

.custom-navbar {
  // background-color: #3d6367;
  // border-bottom: 3px solid #b30000;
  background-color: #333;
  padding: 5px;
  height: $navbar-height;
  //border-bottom: 2px solid #222;

  // .nav-icon {
  //   width: 28px;
  //   color: white;
  // }

  .kalliandra-brand {
    font-size: 20px;
    color: white;
    font-weight: 600;
    margin-right: 10px;

    .brand-small {
      text-align: right;
      font-size: 10px;
      color: #ccc;
      margin-top: -9px;
      font-weight: 400;
    }
  }
}


  .dropdown-head {
    min-height: 32px;
    margin: 5px 0 0;
    padding: 0px 5px 0 40px;
    position: relative;
    .title {
      font-family: "Open Sans", Helvetica, Arial, sans-serif;
      font-size: 16px;
      font-weight: 700;
      line-height: 16px;
    }

    .item-icon{
      color: #588fed;
     
      font-size: 30px;
    }

    .fa {
      width: 38px;
      left: 0;
      position: absolute;
      top: 0;
      line-height: 32px;
      text-align: center;
     
    }

    .subtitle {
      font-family: "Open Sans", Helvetica, Arial, sans-serif;
      font-size: 13px;
      font-weight: 400;
      line-height: 16px;
      color: grey;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  h3 {
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    font-style: italic;
    margin: 0;
    padding: 15px 6px 6px;
  }

</style>
