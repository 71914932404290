<template>
  <b-modal no-fade hide-footer scrollable ref="details-modal" @hidden="close" size="lg">
    <template v-slot:modal-title>
      <span>Detalhes do usuário</span>
    </template>

    <div v-if="loadStatus=='loaded'">
      <div class="row user-profile" v-if="!edit">
        <div class="col-md-4">
          <div>
            <b-button-group size="sm">
              <b-button
                v-b-tooltip.hover
                title="Editar Usuário"
                variant="primary"
                @click="edit=true"
              >
                <font-awesome-icon icon="edit" />
              </b-button>
              <b-button
                v-b-tooltip.hover
                title="Remover Usuário"
                variant="danger"
                @click="$emit('remove-user',user.id)"
              >
                <font-awesome-icon icon="trash" />
              </b-button>
            </b-button-group>
          </div>

          <!-- <b-img v-if="user.imageSrc==null" thumbnail center rounded="circle" fluid :src="require('../assets/user.png')" alt="User profile picture" />
          <b-img v-else thumbnail center rounded="circle" fluid :src="$avatarUrl+user.imageSrc"></b-img>
          -->

          <Avatar :username="user.name" :src="$avatarUrl+user.imageSrc" :size="100" />

          <p class="mt-3">
            <i>{{user.about}}</i>
          </p>
        </div>

        <div class="col-md-8">
          <ul class="list-group list-group-unbordered">
            <li class="list-group-item">
              <b>Nome</b>
              <a class="pull-right">{{user.name}}</a>
            </li>
            <li class="list-group-item">
              <b>Usuário</b>
              <a class="pull-right">{{user.username}}</a>
            </li>
            <li class="list-group-item">
              <b>Telefone</b>
              <a class="pull-right">{{user.phone}}</a>
            </li>
            <li class="list-group-item">
              <b>email</b>
              <a class="pull-right">{{user.email}}</a>
            </li>
          </ul>

          <i>{{user.roles}}</i>
        </div>
      </div>

      <div v-else>
        <userEdit :user="user" v-on:update-user="updateUser($event)" v-on:editcancel="edit=false" />
      </div>
    </div>

    <c-loading v-else :status="loadStatus"></c-loading>
  </b-modal>
</template>

<script>
import userEdit from "./userEdit";
import Avatar from "../avatar/Avatar";


export default {
  data() {
    return {
      edit: false,
      user: undefined,
      loadStatus: "loading"
    };
  },
  props: {
    userId: { type: String, required: true }
  },
  components: {
    userEdit,
    Avatar
  },

  mounted() {
    this.$refs["details-modal"].show();
  },

  beforeMount() {
    if (this.userId !== undefined) {
      this.loadUser();
    }
  },
  methods: {
    loadUser() {
      this.loadStatus = "loading";
      this.$http.get("account/user/" + this.userId).then(
        data => {
          this.user = data;
          this.loadStatus = "loaded";
        },
        error => {
          console.error("load user error",error)
          this.loadStatus = "error";
        }
      );
    },

    updateUser(user) {
      this.user = user;
      this.$emit("update-user", user);
    },

    close() {
      this.$router.push({ name: "userList" });
    }
  }
};
</script>


<style lang="scss" scoped>
.user-profile {
  .profile-username {
    font-size: 21px;
    margin-top: 5px;
  }

  .list-group {
    padding-left: 0;
    margin-bottom: 20px;

    .list-group-item {
      border-left: 0;
      border-right: 0;
      border-radius: 0;
      padding-left: 0;
      padding-right: 0;

      .pull-right {
        color: #3c8dbc;
        float: right;
      }
    }
  }
}
</style>