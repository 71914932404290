<template>
  <div>
    <div :style="{ width: width, height: height }">
      <div class="display">
        <div class="display-title">Hidrômetros</div>

        <div class="display-body">
          <div class="display-pos-midle">
            <div
              class="display-hydro mode-on"
              :class="hydrometer.currentFlow > 0 ? 'mode-on' : ''"
              v-for="(hydrometer, index) in hydrometers"
              :key="index"
            >
              <!-- <span class="sinal"> on </span> -->
              <div class="hydro-name">{{ hydrometer.name }}</div>
              <span class="value"> {{ $filters.round(hydrometer.currentFlow ,0)}} </span>
              <span class="unit"> {{ unit }}/h </span>
              <value-bar
                class="mx-1"
                :value="hydrometer.currentFlow"
                :max="hydrometer.maxFlow"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import sensHydro from "./sensHydro.js";
export default {
  extends:sensHydro,
  props: {
    width: { type: [Number, String], required: false, default: "100px" },
    height: { type: [Number, String], required: false, default: "110px" },
    // hydrometers: Array,
    // unit: { type: String, default: "m³" },
  }
};
</script>

<style lang="scss" scoped>
.display {
  font-family: "Share Tech Mono", monospace;
  background-color: #333;
//   border: 2px #666 solid;
  margin: 4px;
  padding: 2px 5px;
  border-radius: 8px;
  line-height: 18px;
  font-size: 12px;
  text-align: right;
  color: #94a1a5;

  height: 100%;
  position: relative;

  .display-body {
    height: 100%;
    position: relative;
  }

  .mode-on {
    color: #daf6ff;
    text-shadow: 0 0 10px rgba(10, 175, 230, 1), 0 0 10px rgba(10, 175, 230, 0);
  }

  .display-pos-midle {
    // padding: 5px;
    width: 100%;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .display-title {
    letter-spacing: 0.1em;
    font-size: 10px;
    color: #b38b6a;
    text-align: center;
  }

  .display-hydro {
    margin-bottom: 10px;

    .sinal {
      color: #b0dd8b;
    }
    .hydro-name {
      font-size: 9px;
      color: #b0dd8b;
      text-align: left;
      line-height: 8px;
    }
    .value {
      font-size: 18px;
      line-height: 18px;
    }

    .unit {
      font-size: 10px;
      //   line-height: 18px;
      letter-spacing: 0em;
    }
  }

  .progress {
    background: rgba(0, 0, 0, 0.2);
    margin: 2px -10px 5px -10px;
    height: 2px;

    .progress-bar {
      background: #00a65a;
      float: left;
    }
  }
}
</style>
