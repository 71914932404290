<template>
  <div class="report-header">
    <b-row>
      <b-col sm="2">
        <div class="header-logo">
          <img src="@/assets/logo.svg" alt="" />
        </div>
      </b-col>


      <b-col sm="5">
        <div class="relatorio-identficacao">
          <h1>{{ client.name }}</h1>

          <b-row>
            <b-col sm="6">
              <label>Consultor:</label>
              <span>{{ consultant.name }}</span>
            </b-col>


          </b-row>
        </div>
      </b-col>

      <b-col sm="5">
        <div class="report-perid">
          <h2>{{ label }}</h2>
          <span v-if="dateInterval != undefined">{{ $filters.ptbrdate(dateInterval.start) }} -
            {{ $filters.ptbrdate(dateInterval.end) }}</span>
        </div>

        <div class="ml-auto text-right title2">
          <div>
            Relatório gerado em <b>{{ $filters.localeDate(dateNow) }}</b>
          </div>
          <div>por {{ user.name }}</div>
        </div>
        <!-- <div class="mt-4 text-right">
              Página <span class="print-page-number"></span>
            </div> -->

      </b-col>
    </b-row>
    <div class="mt-2">
      <h5 class="text-center">{{ title }}</h5>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { DateTime } from "luxon";
export default {
  props: {
    dateInterval: Object,
    label: String,
    title: String,
  },

  computed: {
    dateNow() {
      return DateTime.now().toFormat('yyyy-MM-dd')
    },

    ...mapGetters({
      user: "accounts/user",
      client: "accounts/client",
      workspace: "accounts/workspace",
      consultant: "accounts/consultant"
    }),
  }
};
</script>

<style lang="scss" scoped>
.report-header {
  //   font-family: "Roboto Condensed";
  //   background: #b9e4ef !important;
  //   -webkit-border-radius: 60px 0 0 0;
  //   border-radius: 60px 0 0 0;
  margin: -15px -15px 25px -15px;
  padding: 15px 15px 0;
  border-bottom: 1px #aaa solid;

  .header-logo {
    padding: 5px 5px;
    // border-right: 2px #1782b6 solid;

    img {
      display: block;
      width: 8rem;
    }
  }

  .report-perid {
    text-align: right;

    h2 {
      font-size: 20px;
    }
  }

  .relatorio-identficacao {
    h1 {
      font-size: 1.7em;
    }

    label {
      display: block;
      margin: 0px;
      line-height: 100%;
      font-size: 0.9em;
      font-weight: normal;
    }

    span {
      font-weight: bold;
    }
  }
}
</style>
