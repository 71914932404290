<template lang="html">

  <vl-feature  :id="'map-water-'+sensor.id" :properties="{ name:sensor.name, compId: sensor.id }">
  
   
      <vl-geom-point :coordinates="coordinates"></vl-geom-point>
      <vl-style-box v-if="isOnline" :z-index="7">
        <vl-style-icon  :scale="1" :anchor="[0.5, 1]" :size="[40, 40]"
          :src="require('../../assets/mapPin/waterLevel.png')"
        ></vl-style-icon>
      </vl-style-box>
      <vl-style-box v-else :z-index="4">
        <vl-style-icon :scale="1" :anchor="[0.5, 1]" :size="[40, 40]"
            :src="require('../../assets/mapPin/waterLevel_off.png')"
        ></vl-style-icon>
      </vl-style-box>

      <receivingIcon v-if="receiving" />

<!--
      uma forma de pegar a posição do feature no mapa
      pointOnSurface(feature.geometry)
-->

      <vl-overlay v-if="showDetail===true"
      :position="coordinates" :offset="[-48, -54]"
      positioning="bottom-left"
      :auto-pan="true" :auto-pan-animation="{ duration: 300 }">
         <div class="map-popup popup-water"  @click="showInfo">
           <waterInfo :sensor="sensor"/>
        </div>
      </vl-overlay>


  </vl-feature>

</template>

<script>
import {pointSensor} from '@components/map/mapCompMixin.js'
import sensWater from './sensWater.js'
import waterInfo from './waterInfo'
import receivingIcon from "../receivingIconMap.vue"

export default {
  extends:sensWater,
  mixins: [pointSensor],
  components:{
    waterInfo,
    receivingIcon
  }
}
</script>

<style lang="scss" scoped>
.popup-water{
  width:200px;
  cursor: pointer;
  padding: 5px;
}
</style>
