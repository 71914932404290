<template>
  <pageFloatingCard :backgroundImage="backgroundImage" :title="title" :subTitle="subTitle" :fluid="fluid" no-body>
    <slot name="head"> </slot>

    <template v-slot:head-tools>
      <slot name="head-tools"></slot>
    </template>


    <b-card-body>

      <tableNavigation :numRows="numRows" :noSearch="noSearch" :page="page">
        <template v-slot:button>
          <slot name="button"> </slot>
        </template>

        <template v-slot:list="props">
          <div v-if="loadStatus == 'loaded'">
            <slot name="list" v-bind="props"> </slot>
          </div>
          <c-loading @retry="$emit('load-retry')" :status="loadStatus" :error="error" v-else>
            <b-skeleton-table :rows="8" :columns="4"
              :table-props="{ bordered: true, striped: false }"></b-skeleton-table>
          </c-loading>
        </template>

      </tableNavigation>



    </b-card-body>
  </pageFloatingCard>
</template>

<script>
import pageFloatingCard from "./PageFloatingCard";
import tableNavigation from "./tableNavigation.vue";

export default {

  props: {
    numRows: Number,
    loadStatus: String,
    error: Object,
    backgroundImage: String,
    title: String,
    subTitle: String,
    fluid: { type: String, require: false, default: 'lg' },
    page: Number,
    noSearch: { type: Boolean, default: false },
  },
  beforeMount() {
    this.currentPage = this.value;
  },
  watch: {
    currentPage(v) {
      if (v) this.$emit("page-update", v);
    },
  },
  components: {
    pageFloatingCard,
    tableNavigation
  },
};
</script>
