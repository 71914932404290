<template>
  <div>
    <b-button :id="id" :variant="variant" :size="size" ref="button">{{label}}</b-button>
   
    <b-popover
      :target="id"
      triggers="click"
      :show.sync="popoverShow"
      placement="bottom-Right"
      boundary-padding="10"
      boundary="scrollParent"
      ref="popover"
      @show="onShow"
      @shown="onShown"
      @hidden="onHidden"
    >
      <template v-slot:title>
        {{sublabel}}
        <b-button @click="onClose" class="close" aria-label="Close">
          <span class="d-inline-block" aria-hidden="true">&times;</span>
        </b-button>
      </template>

      <div>
        <b-form-group label="Pesquisar" description="Pesquise pelo nome">
          <b-form-input ref="input1" v-model="filter" type="text" size="sm"></b-form-input>
        </b-form-group>
      </div>
      <div v-if="options===undefined">...</div>
      <div v-else id="scrollspy-nested" style="position:relative; height:200px; overflow-y:auto">
        <div v-for="(v,i) in filtered" :key="i">
          <b-button
            block
            size="sm"
            @click="onSelectIntem(v.value)"
            class="my-1"
            variant="info"
          >{{v.text}}</b-button>
        </div>
      </div>
      <slot> </slot>
    </b-popover>
  </div>
</template>

<script>
export default {
  data() {
    return {
      popoverShow: false,
      id: "button",
      filter: ""
    };
  },
  props: {
    options: Array,
    label: { type: String, default: "selecionar" },
    sublabel: { type: String, default: "selecione na lista" },
    size: { type: String, default: "sm" },
    variant: String
  },

  computed: {
    filtered() {
      //procura por texto inciado por
      // let regexStr = "^" + this.filter+""; 
      let regexStr = "(w*" + this.filter+"w*)";
      try {
        let regex = new RegExp(regexStr,"i");
        return this.options.filter(function(word) {
          return regex.test(word.text);
        });
      } catch (e) {
        return [];
      }
    }
  },

  mounted() {
    this.id = "pop-" + this._uid;
  },
  methods: {
    onClose() {
      this.popoverShow = false;
    },

    onSelectIntem(v) {
      this.onClose();
      this.$emit("select-item", v);
    },

    onOk() {},
    onShow() {
      //limpa os dados de pesquisa
      this.filter = ''
    },
    onShown() {
      //tras o foco para o campo de pesquisar
      this.focusRef(this.$refs.input1)
    },
    onHidden() {
      this.focusRef(this.$refs.button)
    },

    focusRef(ref) {
      // Some references may be a component, functional component, or plain element
      // This handles that check before focusing, assuming a `focus()` method exists
      // We do this in a double `$nextTick()` to ensure components have
      // updated & popover positioned first
      this.$nextTick(() => {
        this.$nextTick(() => {
          (ref.$el || ref).focus()
        })
      })
    }
  }
};
</script>


<style lang="scss" scoped>
// .pop-hover-top {
//   z-index: 10;
//   box-shadow: 10px 10px 10px gray;
// }
</style>