<template>
  <b-container>
    <div class="config-page">
      <div class="header">
        <h2>Convite</h2>
        <small>
          <font-awesome-icon icon="info-circle" /> Gerenciar convites para usuários
        </small>
      </div>
      
      <hr>
      <p>
        Cria um convite que pode ser usado por um usuário
        já cadastrado no sistema para se juntar ao seu grupo
      </p>
      <div class="mb-5">
        <validation-observer ref="inviteObserver">
          <ValidationProvider slim vid="email" name="email" :rules="'required|email'" v-slot="v">
            <b-form-group label="Email" class="col-md-6">
              <b-form-input
                name="email"
                v-model="invite.email"
                type="text"
                maxlength="30"
                :state="$validateState(v)"
              ></b-form-input>
              <span class="invalid-input">{{ v.errors[0] }}</span>
            </b-form-group>
          </ValidationProvider>

          <b-form-group label="Permissão" class="col-md-6">
            <b-form-select v-model="invite.role" :options="roleList"></b-form-select>
          </b-form-group>

          <b-form-group class="col-md-6">
            <b-button size="sm" @click="createInvite" variant="success">criar convite</b-button>
          </b-form-group>
        </validation-observer>
      </div>

      <h4>Convites Pendentes</h4>
      <div v-if="loadStatus=='loaded'">
        <template v-if="inviteList.length>0">
          <div v-for="invite in inviteList" :key="invite.id">
            <inviteCard :invite="invite" v-on:remove-invite="removeInvite($event)"></inviteCard>
          </div>
        </template>
        <div class="font-italic" v-else>sem convites ativos.</div>
      </div>
    </div>
  </b-container>
</template>

<script>
import inviteCard from "./inviteCard";
import { roleList } from "./utils";

export default {
  data() {
    return {
      inviteList: [],
      loadStatus: String,
      perPage: 10,
      currentPage: 1,
      filter: null,
      roleList,
      invite: { role: "member", email: "" }
    };
  },

  beforeMount() {
    this.loadStatus = "loading";
    this.$http.get("account/workspace/invite").then(
      data => {
        this.inviteList = data;
        this.loadStatus = "loaded";
      },
      error => {
         console.log("load invite error:", error);
        this.loadStatus = "error";
      }
    );
  },
  components: {
    inviteCard
  },

  methods: {
    removeInvite(id) {
      this.$http.delete("account/workspace/invite/" + id).then(
        data => {
          this.inviteList = data;
        },
        error => {
          console.log("remove invite error:", error);
        }
      );
    },
    createInvite() {
      this.$refs.inviteObserver.validate().then(result => {
        if (!result) {
          return;
        }

        this.$http.post("account/workspace/invite/", this.invite).then(
          data => {
            this.inviteList = data;
          },
          error => {
             console.log("create invite error:", error);
          }
        );
      });
    }
  }
};
</script>

<style>
</style>