<template>
  <div class="h-100">
    <b-table-simple class="h-100" bordered sticky-header hover small responsive
    select-mode="single"
      >
      <b-thead head-variant="light">
        <b-tr>
          <b-th stickyColumn> Data </b-th>
          <b-th> Média </b-th>
          <b-th class="text-nowrap" v-for="(name, index) in tableData.headers" :key="index">{{
            name
          }}</b-th>
        </b-tr>
      </b-thead>

      <b-tbody>
        <b-tr v-for="[date, row] in tableData.rows" :key="date">
          <b-th stickyColumn variant="primary">
            <b-link @click="selectDate(date)">{{$filters.localeDate(date)}}</b-link> 
          </b-th>
          <b-th variant="primary">
            {{$filters.localeNumber(row.avg, 2)}}
          </b-th>
          <b-td v-for="(rain, index) in row.list" :key="index">
          
            <b-link v-if="editable" @click="editByDate(date,tableData.ids[index])">
              <font-awesome-icon class="mr-1" icon="edit" />
              {{ $filters.localeNumber(rain, 2) }}
            </b-link>
            <span v-else>
              {{ $filters.localeNumber(rain, 2) }}
            </span>
          
          </b-td>
        </b-tr>
      </b-tbody>

      <b-tfoot>
        <b-tr>
          <b-th stickyColumn variant="success"><b-link @click="selectDate(undefined)">Total</b-link> </b-th>
          <b-th variant="success">{{$filters.localeNumber(tableData.totalavg,2)}} </b-th>
          <b-th
            variant="success"
            v-for="(value, index) in tableData.total"
            :key="index"
            >{{ $filters.localeNumber(value, 2) }}</b-th
          >
        </b-tr>
      </b-tfoot>
    </b-table-simple>

    <div class="d-flex">

      <div>
        <p> <font-awesome-icon icon="info-circle"/> 
          Clique em uma data da coluna <b>Data</b> para filtrar os dias no mapa.
        </p>
      </div>

      

      <div class="ml-auto">  
        <b-button
        v-if="$can('edit','sensorData') && !editable"
        class="mr-1"
        size="sm"
        variant="primary"
        @click="editMode()"
        ><font-awesome-icon icon="edit" /> Editar
      </b-button>     
      <b-button
        v-else
        class="mr-1"
        size="sm"
        variant="secondary"
        @click="editable=false"
        ><font-awesome-icon icon="edit" /> Finalizar
      </b-button>


        <b-button
        :disabled="savingFile"
        title="baixar dados .xlsx"
        size="sm"
        variant="success"
        @click="download()"
        ><font-awesome-icon icon="file-excel" /> Baixar
      </b-button>
    </div>
  </div>

  <pluvioRainEdit ref="edit-rain" @saved="$emit('update:rain',$event)" />

  </div>
</template>

<script>
import writeXlsxFile from "denver-write-excel-file";
import { DateTime } from "luxon";
import pluvioRainEdit from '@sensorcomponents/sensPluvio/pluvioRainEdit'

export default {
  data() {
    return {
      savingFile: false,
      editable:false
    };
  },
  props: {
    data: Array,
  },
  components:{
    pluvioRainEdit
  },

  computed: {
    tableData() {
      let tableData = new Map();
      let pluvioIndex = -1;
      let numPluvios = this.data.length;
      let names = this.data.map((e) => e.pluviometer.name);
      let ids = this.data.map((e) => e.pluviometer.id);

      let total = new Array(numPluvios);
   
      total.fill(0);

      this.data.forEach((e) => {
        let days = e.days;
        pluvioIndex += 1;

        if (days != undefined) {
          let p_total = 0;
          days.forEach(function (day) {
            let value = tableData.get(day.date);
            if (!value) {
              let list = new Array(numPluvios);
              list.fill(0);
              value = { list: list, sum:0, avg:0}
            }

            value.list[pluvioIndex] = day.precipitation;
            value.sum += day.precipitation;
            tableData.set(day.date, value);
            p_total += day.precipitation;
          });

          total[pluvioIndex] = p_total;
        }
      });


      let totalavg = 0;
      /** calcula a média de chuva por dia */
      tableData.forEach((value) => {
        let avg = Number((value.sum/value.list.length).toFixed(2))
        value.avg = avg
        totalavg+= avg
      });

      tableData = new Map([...tableData].sort((a,b)=>{
        return new Date(b[0]) - new Date(a[0]);
      }));

      

      return { headers: names, ids:ids,  rows: tableData, total: total,  totalavg:totalavg };
    },
  },

  methods: {

    editMode(){
      this.editable = true
      this.$emit('edit-mode')
    },

    editByDate(date,pluvio){ 
        this.$refs["edit-rain"].show(date,pluvio);
    },

    selectDate(date) {
        if (date!= undefined){
          this.$emit("update:rangeselect", {
            start: date+"T00:00:00",
            end:   date+"T23:59:59",
          });
        }else{
          this.$emit("update:rangeselect", undefined);
        }
      },

    async download() {
      let headerColor = "#dde8cb";
      this.savingFile = true;
      let rows = [];
      let tabledata = this.tableData;

      let columns = [{ width: 10 },{ width: 7 }];
      let head = [
        {
          value: "Data",
          height: 30,
          alignVertical: "center",
          align: "center",
          backgroundColor: headerColor,
        },
        {
          value: "Média",
          height: 30,
          alignVertical: "center",
          align: "center",
          backgroundColor: headerColor,
        },
      ];
      tabledata.headers.forEach((e) => {
        head.push({
          value: e,
          height: 30,
          alignVertical: "center",
          align: "center",
          borderStyle: "thin",
          backgroundColor: headerColor,
        });
        columns.push({ width: (e.length /2)+3 });
      });
      rows.push(head);



      tabledata.rows.forEach((value, key) => {
        let row = [
          {
            value: DateTime.fromISO(key).toJSDate(),
            borderStyle: "thin",
            backgroundColor: headerColor,
            type: Date,
            format: "dd/mm/yyyy",
          },
          { value: value.avg, type: Number, borderStyle: "thin", backgroundColor: headerColor }
        ];

        value.list.forEach((col) => {
          row.push({ value: col, type: Number, borderStyle: "thin" });
        });
        rows.push(row);
      });



      let total = [
        {
          value: "Total",
          height: 30,
          alignVertical: "center",
          borderStyle: "thin",
          align: "center",
          backgroundColor: headerColor,
        },
        {
          value: tabledata.totalavg,
          type: Number,
          height: 30,
          alignVertical: "center",
          borderStyle: "thin",
          align: "center",
          backgroundColor: headerColor,
        }
      ];
      tabledata.total.forEach((e) => {
        total.push({
          value: e,
          type: Number,
          height: 30,
          alignVertical: "center",
          borderStyle: "thin",
          align: "center",
          backgroundColor: headerColor,
        });
      });
      rows.push(total);


      await writeXlsxFile(rows, {
        columns,
        fileName: "mapa_de_chuva.xlsx",
        stickyRowsCount: 1,
        stickyColumnsCount: 2,
      });
      this.savingFile = false;
    },
  },
};
</script>

<style></style>
