<template>
  <div>

    <section>
      <b-row>
        <!-- Nome do solo -->
        <ValidationProvider slim vid="name" name="Nome" :rules="{ required: true, min: 3 }" v-slot="v">
          <b-form-group label="Nome" class="col-sm-7">
            <b-form-input name="soil-name" v-model="soilEdit.name" type="text" maxlength="20" placeholder="nome"
              :state="$validateState(v)"></b-form-input>
            <!-- <b-form-invalid-feedback>{{ v.errors[0] }}</b-form-invalid-feedback> -->
            <span class="invalid-input">{{ v.errors[0] }}</span>
          </b-form-group>
        </ValidationProvider>

        <!-- Classificação do solo -->
        <ValidationProvider slim vid="classification" name="classificação" :rules="{ required: true }" v-slot="v">
          <b-form-group label="Tipo de solo" class="col-sm-5">
            <b-form-select v-model="soilEdit.classification" :options="classification_options" name="soil-classification"
              :state="$validateState(v)"></b-form-select>
            <!-- <b-form-invalid-feedback>{{ v.errors[0] }}</b-form-invalid-feedback> -->
            <span class="invalid-input">{{ v.errors[0] }}</span>
          </b-form-group>
        </ValidationProvider>
      </b-row>

      <!-- Descrição do solo -->
      <ValidationProvider name="Descrição" :rules="{ min: 5, max: 250 }" v-slot="v">
        <b-form-group label="Descrição">
          <b-form-textarea name="soil-description" v-model="soilEdit.description" placeholder="Descrição"
            :state="$validateState(v)"></b-form-textarea>
          <!-- <b-form-invalid-feedback>{{ v.errors[0] }}</b-form-invalid-feedback> -->
          <span class="invalid-input">{{ v.errors[0] }}</span>
        </b-form-group>
      </ValidationProvider>

    </section>
    <section>
      <h6>Camadas</h6>

      <b-table-simple stacked="sm" small class="layer-table">
        <b-thead class="text-center">
          <b-tr>
            <b-th></b-th>
            <b-th>Altura</b-th>
            <b-th>C. Campo </b-th>
            <b-th>P. Murcha </b-th>
            <b-th></b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr v-for="(layer, index) in soilEdit.layers" :key="index">
            <b-td stacked-heading="Mover">
              <b-button size="sm" class="button-move" v-if="index != 0" title="mover para cima"
                @click="moveUpLayer(index)" variant="outline-secondary">▲
              </b-button>
            </b-td>

            <b-td stacked-heading="Altura do solo">
              <ValidationProvider slim vid="soil_depth" name="Altura" :rules="{ required: true, minValue: 1 }" v-slot="v">
                <b-form-input size="sm" v-model="layer.depth" type="number" min="1" step="0.1"
                  :state="$validateState(v)"></b-form-input>
              </ValidationProvider>
            </b-td>

            <b-td stacked-heading="Capacidade de campo">
              <ValidationProvider slim vid="fieldCapacity" name="capacidade de campo"
                :rules="{ required: true, minValue: 1 }" v-slot="v">
                <b-form-input size="sm" v-model="layer.fieldCapacity" type="number" min="1" step="0.1"
                  :state="$validateState(v)"></b-form-input>
              </ValidationProvider>
            </b-td>

            <b-td stacked-heading="Ponto de Murcha">
              <ValidationProvider slim name="wiltingPoint" :rules="{ required: true, minValue: 1 }" v-slot="v">
                <b-form-input size="sm" v-model="layer.wiltingPoint" type="number" min="1" step="0.1"
                  :state="$validateState(v)"></b-form-input>
              </ValidationProvider>
            </b-td>

            <b-td stacked-heading="Apagar estágio">
              <b-button size="sm" title="remover camada" variant="danger" @click="removeLayer(index)">x</b-button>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
      <b-form-group>
        <b-button size="sm" @click="newLayer" variant="primary">Nova</b-button>
      </b-form-group>


    </section>

    <section>
      <h6>Excesso</h6>

      <div class="excessCard">
        <b-row>
          <b-form-group label="Padrão" class="col-md-3 col-sm-4">
          </b-form-group>

          <!-- caixa de excesso de agua -->
          <ValidationProvider slim name="Caixa de excesso" :rules="{ required: true, minValue: 0 }" v-slot="v">
            <b-form-group label="Caixa de excesso" class="col-md-3 col-sm-4"
              description="Volume máxima de água da caixa de excesso">
              <b-input-group size="sm" append="mm">
                <b-form-input size="sm" v-model="soilEdit.excessBox" type="number" min="0" step="0.1"
                  :state="$validateState(v)"></b-form-input>
              </b-input-group>
              <!-- <b-form-invalid-feedback>{{ v.errors[0] }}</b-form-invalid-feedback> -->
              <span class="invalid-input">{{ v.errors[0] }}</span>
            </b-form-group>
          </ValidationProvider>

          <!-- Percolação da caixa de excesso de agua -->
          <ValidationProvider slim name="Percolação" :rules="{ required: true, minValue: 0 }" v-slot="v">
            <b-form-group label="Percolação da caixa" class="col-md-3 col-sm-4"
              description="Perda de água diária da caixa de excesso">
              <b-input-group size="sm" append="mm/dia">
                <b-form-input size="sm" v-model="soilEdit.excessPercolationRate" type="number" min="0" step="0.1"
                  :state="$validateState(v)"></b-form-input>
              </b-input-group>
              <span class="invalid-input">{{ v.errors[0] }}</span>
            </b-form-group>
          </ValidationProvider>
        </b-row>
      </div>



      <div class="excessCard" v-for=" ex, index in soilEdit.datedExcessConfig" :key="index">
        <b-row>
          <!-- caixa de excesso de agua -->
          <ValidationProvider slim :rules="{ required: true }" v-slot="v">
            <b-form-group label="Inicio" class="col-md-3 col-sm-4" description="Data incial para a nova regra de excesso">
              <b-form-input size="sm" v-model="ex.date" type="date" :state="$validateState(v)"></b-form-input>
              <span class="invalid-input">{{ v.errors[0] }}</span>
            </b-form-group>
          </ValidationProvider>


          <!-- caixa de excesso de agua -->
          <ValidationProvider slim :rules="{ required: true, minValue: 0 }" v-slot="v">
            <b-form-group label="Caixa de excesso" class="col-md-3 col-sm-4"
              description="Volume máxima de água da caixa de excesso">
              <b-input-group size="sm" append="mm">
                <b-form-input size="sm" v-model="ex.excessBox" type="number" min="0" step="0.1"
                  :state="$validateState(v)"></b-form-input>
              </b-input-group>
              <span class="invalid-input">{{ v.errors[0] }}</span>
            </b-form-group>
          </ValidationProvider>

          <!-- Percolação da caixa de excesso de agua -->
          <ValidationProvider slim :rules="{ required: true, minValue: 0 }" v-slot="v">
            <b-form-group label="Percolação da caixa" class="col-md-3 col-sm-4"
              description="Perda de água diária da caixa de excesso">
              <b-input-group size="sm" append="mm/dia">
                <b-form-input size="sm" v-model="ex.excessPercolationRate" type="number" min="0" step="0.1"
                  :state="$validateState(v)"></b-form-input>
              </b-input-group>
              <span class="invalid-input">{{ v.errors[0] }}</span>
            </b-form-group>
          </ValidationProvider>

          <div class="col-md-3 mt-4">
            <b-button variant="danger" size="sm" @click="removeExcess()"> Remover </b-button>
          </div>
        </b-row>
      </div>
      <b-button v-if="insertExcess" variant="primary" size="sm" @click="addExcess()"> Novo </b-button>


    </section>

  </div>
</template>

<script>
import { DateTime } from "luxon";
export default {
  // inject: ['$validator', '$validateState'],

  // created(){
  //   let self = this
  //   debugger
  // },

  data() {
    return {
      soilEdit: undefined,
      updateOrigin: true,
      classification_options: [
        { value: null, text: "Selecione" },
        { value: "arenoso", text: "arenoso" },
        { value: "argiloso", text: "argiloso" },
      ],
    };
  },

  props: {
    value: Object,
    insertExcess: { type: Boolean, default: false }
  },
  beforeMount() {
    this.soilEdit = JSON.parse(JSON.stringify(this.value));
  },
  watch: {
    value: {
      deep: true,
      handler(v) {
        this.updateOrigin = false;
        this.soilEdit = JSON.parse(JSON.stringify(v));
      },
    },

    soilEdit: {
      deep: true,
      handler(v) {
        if (this.updateOrigin) {
          this.$emit("input", JSON.parse(JSON.stringify(v)));
        } else {
          this.updateOrigin = true;
        }
      },
    },
  },
  methods: {

    removeExcess(index) {
      this.soilEdit.datedExcessConfig.splice(index, 1);
    },

    addExcess() {
      let ex = { date: DateTime.now().toISODate(), excessBox: 0, excessPercolationRate: 0 }
      if (this.soilEdit.datedExcessConfig != undefined) {
        this.soilEdit.datedExcessConfig.push(ex)
      } else {
        this.soilEdit.datedExcessConfig = [ex]
      }
    },

    removeLayer(index) {
      this.soilEdit.layers.splice(index, 1);
    },

    moveUpLayer(index) {
      let arr = this.soilEdit.layers;
      arr.splice(index - 1, 0, arr.splice(index, 1)[0]);
    },

    newLayer() {
      this.soilEdit.layers.push({
        depth: 10,
        fieldCapacity: 0,
        wiltingPoint: 0,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
section {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  margin-bottom: 2rem;
}


.layer-table {
  td {
    padding: 0px;
  }

  //    //input{
  //     //  padding: 0px;
  //     // border-radius: 0;
  //    //}
}

.excessCard {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  margin-bottom: 1rem;
  padding: 0.5rem;
  // box-shadow: 0 0 8px #a0a0a0;
  // background-color: rgb(203, 243, 255);
}


.button-move {
  width: 50px;
}
</style>
