<template >
    <b-container fluid>
        <div v-if="loadStatus == 'loaded'">

            <div class="non-print">
                <b-breadcrumb>
                    <b-breadcrumb-item :to="{ name: 'ndviPageList' }">
                        <font-awesome-icon icon="list" class="item-icon" />
                        NDVI Lista
                    </b-breadcrumb-item>
                    <b-breadcrumb-item active>
                        {{ ndviArea.name }}
                    </b-breadcrumb-item>
                </b-breadcrumb>

                <b-row>
                    <b-col md="7" lg="8">
                        <!-- <multipane layout="horizontal" @pane-resize-stop="panelResize"> -->
                        <!-- <h6> Controle de NDVI</h6> -->

                        <div class="map-container shadow">

                            <simple-map ref="map" :mapHeight="'100%'" :fitPadding="[40, 200, 10, 10]">

                                <template v-slot:layer>
                                    <vl-layer-image :z-index="5" v-if="currentImage != undefined">
                                        <ndviMap :area="mask ? ndviArea.geometryArea : undefined" :alphaColor="alpha"
                                            :minValue="minValue" :maxValue="maxValue" :src="imageSrc(currentImage.date)"
                                            :extent="imgExtent" :colorPalette="colorPalette" :smoothing="smoothing">
                                        </ndviMap>
                                    </vl-layer-image>
                                </template>


                                <vl-feature :id="'area-' + ndviArea.id"
                                    :properties="{ compId: ndviArea.id, name: `${ndviArea.name}` }">
                                    <vl-geom-multi-polygon v-if="ndviArea.geometryArea != null"
                                        :coordinates="[ndviArea.geometryArea]"></vl-geom-multi-polygon>
                                    <vl-style-box :z-index="0">
                                        <vl-style-stroke color="rgba(255,255,255,0.8)" :width="2"></vl-style-stroke>
                                    </vl-style-box>
                                </vl-feature>


                                <template v-slot:map-control v-if="currentImage">

                                    <div class="current-date" variant="primary">
                                        {{ $filters.longDateFormat(currentImage.date) }}
                                    </div>

                                    <div class="map-options ">

                                        <b-form-group label="Cores" label-cols="3">
                                            <paletteSelector :palettes="palettes" v-model="paletteSelect" />
                                        </b-form-group>

                                        <b-form-group :label="'Transparência (' + $filters.round(alpha * 100) + '%)'">
                                            <b-form-input class="max" type="range" number :debounce="500" v-model="alpha"
                                                min=0.1 max=1 step=0.01></b-form-input>
                                        </b-form-group>

                                        <div class="d-flex">
                                            <b-form-checkbox v-model="mask" :value="true" :unchecked-value="false">
                                                Contorno
                                            </b-form-checkbox>

                                            <b-form-checkbox class="ml-auto" v-model="smoothing" :value="true"
                                                :unchecked-value="false">
                                                Suavizar
                                            </b-form-checkbox>
                                        </div>



                                        <div class="map-histogram" v-if="currentImage != undefined">

                                            <histogram :minValue="minValue" :maxValue="maxValue"
                                                :colorPalette="colorPalette" :src="imageSrc(currentImage.date)"
                                                :mask="mask ? areaMask : undefined" height="80px" />
                                            <div class="range-multi">
                                                <b-form-input class="min" type="range" :debounce="200" number
                                                    v-model="minValue" min=0 max=1 step=0.01></b-form-input>
                                                <b-form-input class="max" type="range" :debounce="200" number
                                                    v-model="maxValue" min=0 max=1 step=0.01></b-form-input>
                                            </div>
                                        </div>
                                    </div>
                                </template>

                            </simple-map>

                            <div class="map-comment" v-if="currentImage">
                                <!-- <markdown :source="currentImage.comment"></markdown> -->
                                <editable-text textPlaceholder="Escreva um comentário" @text-saved="onTextSaved()"
                                    v-model="currentImage.comment"></editable-text>
                            </div>

                        </div>
                    </b-col>

                    <b-col md="5" lg="4">
                        <b-card class="shadow history-card" no-body>
                            <template #header>
                                <div class="d-flex">
                                    <div>
                                        Histórico
                                    </div>

                                    <div class="ml-auto">
                                        <b-input-group size="sm" append="%" title="Filtro de nuvens">
                                            <b-input-group-prepend is-text>
                                                <font-awesome-icon icon="cloud" />
                                            </b-input-group-prepend>
                                            <b-input type="number" style="width: 80px;" v-model="MAXCC" :max="100" :min="0">
                                            </b-input>
                                        </b-input-group>
                                    </div>
                                </div>
                            </template>




                            <b-card-body class="history-card-body">

                                <div v-if="statusInfo != 'ok'" class="new-images-alert">
                                    <font-awesome-icon icon="gear" spin size="lg"/>
                                    Buscando novas imagens...
                                </div>

                                <div v-if="loadImageStatus == 'loaded'">

                                    <div v-if="imagesToLoad.length > 0">
                                        <div class="time-line" v-for="image in imagesToLoad" :key="image.date">
                                            <div class="history-card-day" @click="currentImage = image"
                                                v-bind:class="{ 'selected': image.date == currentImage.date }">

                                                <!-- <b-skeleton-img no-aspect width="256px" height="256px"></b-skeleton-img> -->
                                                <!-- <b-img-lazy v-bind="imageProps" :src="imageSrc(image.date)" /> -->

                                                <ndviImage :src="imageSrc(image.date)" :minValue="minValue"
                                                    :maxValue="maxValue" :mask="mask ? areaMask : undefined" :width="80"
                                                    :height="80" :colorPalette="colorPalette" />

                                                <div class="history-card-info">
                                                    <div class="history-card-date">
                                                        {{ $filters.longDateFormat(image.date) }}
                                                    </div>

                                                    <div class="history-card-cloud">
                                                        <font-awesome-icon icon="cloud" />
                                                        Nuvens:
                                                        {{ image.cloudCoverPercentage }}<small>%</small>
                                                    </div>
                                                    <div v-if="!!image.comment" class="history-card-comment">
                                                        <font-awesome-icon icon="clipboard" />
                                                        Observações
                                                    </div>
                                                </div>
                                                <div class="ml-auto" title="Adicionar à Impressão">
                                                    <b-form-checkbox v-model="image.print" :value="true"
                                                        :unchecked-value="undefined">
                                                        <font-awesome-icon icon="print"/>
                                                    </b-form-checkbox>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div v-else class="no-images-alert">
                                        <font-awesome-icon icon="image" />
                                        Sem imagens
                                    </div>
                                </div>
                            </b-card-body>



                            <template #footer>
                                <b-button-toolbar aria-label="Opções">
                                    <b-button @click="$printDiv('printDiv')" class="mx-1" size="sm" variant="primary"
                                        title="Imprimir">
                                        <font-awesome-icon icon="print"/>
                                    </b-button>

                                    <b-form-checkbox title="Imprimir" class="mx-1" v-model="checkAllPrinters" :value="true"
                                        :unchecked-value="false">
                                        Todos
                                    </b-form-checkbox>

                                    <b-button v-if="$can('manage', 'NDVI')" @click="$emit('remove-area', areaId)"
                                        class="mx-1 ml-auto" size="sm" variant="danger" title="Apagar área"> 
                                        <font-awesome-icon icon="trash" />
                                    </b-button>

                                </b-button-toolbar>
                            </template>

                        </b-card>
                    </b-col>
                </b-row>
            </div>



            <div id="printDiv" class="ndvi-print print-only" v-if="imagesToPrint.length > 0">
                <report-header  label="Relatório de NDVI" :title="ndviArea.name" />
                <div class="report-container">
                    <div class="ndvi-image no-break-block shadow" v-for="image in imagesToPrint" :key="image.date">

                        <div>
                            <ndviImage :src="imageSrc(image.date)" :minValue="minValue" :maxValue="maxValue"
                                :mask="mask ? areaMask : undefined" :width="420" :height="420" :smoothing="smoothing"
                                :colorPalette="colorPalette" />
                        </div>

                        <div class="ml-5 flex-grow-1">
                            <h5>{{ $filters.longDateFormat(image.date) }} </h5>

                            <div class="d-flex ">
                                <div>
                                    <div> <font-awesome-icon icon="clock" />
                                        {{ $filters.localeTime(image.time) }}
                                    </div>
                                    <div>
                                        <font-awesome-icon icon="cloud" />
                                        Nuvens: {{ image.cloudCoverPercentage }}<small>%</small>
                                    </div>
                                </div>

                                <div class="ml-auto">
                                    <div class="report-image-histogram">
                                        <histogram class="histogram-chart" :minValue="minValue" :maxValue="maxValue"
                                            legendColor="black" :colorPalette="colorPalette" :src="imageSrc(image.date)"
                                            :mask="mask ? areaMask : undefined" />
                                    </div>
                                </div>

                            </div>

                            <div class="mt-1">
                                <h6>Observações:</h6>
                                <div class="report-comments">
                                    <markdown :source="image.comment"></markdown>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <c-loading :status="loadStatus" v-else></c-loading>

    </b-container>
</template>
<script>
import ndviMap from "./map/IdwVueSource.vue"
import ndviImage from "./ndviImage.vue";
import histogram from "./histogramChart.vue";
import paletteSelector from "./palettSelect.vue"
import reportHeader from "@reportcomponents/comum/reportHeader.vue";

export default {

    data() {
        return {
            ndviArea: undefined,
            images: [],
            MAXCC: 100,
            alpha: 0.75,
            smoothing: true,
            mask: true,
            statusInfo: "",
            loadStatus: "",
            loadImageStatus: "",
            currentImage: undefined,
            minValue: 0,
            maxValue: 1,
            paletteSelect: 0,
            statusPolling: null,
            checkAllPrinters: false,

            palettes: [
                [
                    [50, 0, 0],		 //  < -.2 = #000000 (black)
                    [165, 0, 38],    //  -> 0 = #a50026
                    [215, 48, 39],   //  -> .1 = #d73027
                    [244, 109, 67],  //  -> .2 = #f46d43
                    [253, 174, 97],  //  -> .3 = #fdae61
                    [254, 224, 139], //  -> .4 = #fee08b
                    [255, 255, 191], //  -> .5 = #ffffbf
                    [217, 239, 139], //  -> .6 = #d9ef8b
                    [166, 217, 106], //  -> .7 = #a6d96a
                    [102, 189, 99],  //  -> .8 = #66bd63
                    [26, 152, 80],   //  -> .9 = #1a9850
                    [0, 104, 55]
                ],
                [
                    [255, 255, 255],
                    [240, 241, 183],
                    [209, 232, 156],
                    [162, 218, 140],
                    [113, 193, 118],
                    [75, 163, 88],
                    [59, 128, 66],
                    [45, 103, 54],
                    [38, 88, 49],
                    [30, 71, 44]
                ],
                [
                    [129, 40, 0],
                    [201, 153, 92],
                    [199, 210, 112],
                    [138, 221, 96],
                    [9, 114, 16]
                ],
                [
                    [100, 0, 0],
                    [255, 0, 0],
                    [255, 255, 0],
                    [0, 200, 0],
                    [0, 100, 0]
                ],
            ]

        }
    },

    components: {
        ndviImage,
        ndviMap,
        histogram,
        reportHeader,
        paletteSelector
    },
    props: {
        areaId: { type: String }
    },

    computed: {

        imagesToPrint() {
            return this.images.filter(function (e) { return e.print === true })
        },


        colorPalette() {
            return this.palettes[this.paletteSelect]
        },

        areaMask() {
            let bbox = this.ndviArea.bbox

            let width = bbox[2] - bbox[0]
            let height = bbox[3] - bbox[1]

            let masks = []

            this.ndviArea.geometryArea.forEach(element => {
                let mask = element.map(e =>
                    [(e[0] - bbox[1]) / height, 1 - (e[1] - bbox[0]) / width]
                )
                masks.push(mask)
            });

            return masks
        },

        imgExtent() {
            return [
                this.ndviArea.bbox[1],
                this.ndviArea.bbox[0],
                this.ndviArea.bbox[3],
                this.ndviArea.bbox[2]
            ]
            //return [-45, -12, 0, 0]
        },

        imagesToLoad() {
            let MAXCC = this.MAXCC
            return this.images.filter(function (e) {
                return e.cloudCoverPercentage <= MAXCC
            }
            )
        }
    },

    mounted() {
        this.loadStatusInfo();
        this.loadArea();
        this.loadImages();
    },

    watch: {

        checkAllPrinters(v) {

            if (v == true) {
                this.imagesToLoad.forEach(e => {
                    this.$set(e, 'print', true)
                })
            } else {
                this.images.forEach(e => {
                    this.$set(e, 'print', false)
                })
            }
        },

        statusInfo(v, old) {
            if (v.startsWith('processing')) {
                this.pollStatus();
            }

            if (v == 'ok' && v != old) {
                clearInterval(this.statusPolling)
                this.statusPolling = undefined
                this.loadImages();
            }

        },

        areaId() {
            this.loadStatus = "loading";
            this.loadStatusInfo()
            this.loadArea()
        },

        minValue(v) {
            if (this.maxValue < v + 0.1)
                this.maxValue = v + 0.1
        },

        maxValue(v) {
            if (this.minValue > v - 0.1)
                this.minValue = v - 0.1
        }
    },

    beforeDestroy() {
        clearInterval(this.statusPolling)
    },

    methods: {

        pollStatus() {

            if (this.statusPolling == undefined) {
                this.statusPolling = setInterval(() => {
                    this.loadStatusInfo();
                    //console.log("process")
                }, 3000)
            }
        },


        loadStatusInfo() {
            this.$http.get("/irrig/ndvi/area/" + this.areaId + "/status").then(
                (data) => {
                    this.statusInfo = data
                },
                () => {
                    this.statusInfo = "error";
                }
            )
        },

        onTextSaved() {
            this.saveImageData()
        },

        saveImageData() {
            this.$http.put("/irrig/ndvi/area/" + this.areaId + "/image/" + this.currentImage.date, { comment: this.currentImage.comment }).then(
                () => {
                },
                () => {
                    this.statusInfo = "error";
                }
            )
        },

        loadImages() {

            this.loadImageStatus = "loading";
            this.$http.get("/irrig/ndvi/area/" + this.areaId + "/images").then(
                (data) => {
                    this.images = data
                    this.loadImageStatus = "loaded";

                    if (this.imagesToLoad.length > 0) {
                        this.currentImage = this.imagesToLoad[0]
                        this.$set(this.currentImage, 'print', true)
                    } else {
                        this.currentImage = undefined
                    }

                },
                () => {
                    this.loadImageStatus = "error";
                }
            )

        },

        loadArea() {

            this.loadStatus = "loading";
            this.$http.get("/irrig/ndvi/area/" + this.areaId).then(
                (data) => {
                    this.ndviArea = data
                    this.loadStatus = "loaded";

                    if (this.imagesToLoad.length > 0) {
                        this.currentImage = this.imagesToLoad[0]
                        this.$set(this.currentImage, 'print', true)
                    } else {
                        this.currentImage = undefined
                    }

                },
                () => {
                    this.loadStatus = "error";
                }
            )
        },

        imageSrc(date) {
            return '/app-files/ndvi/' + this.ndviArea.id + '/' + date + '.png'
        }
    }

}
</script>
<style lang="scss" scoped>
$left-margin : 30px;
$lineColor: #c4c1c1;
$mapHeight: calc(100vh - 100px);

.map-container {
    border-radius: 10px;
    position: relative;
    height: $mapHeight;
    margin-bottom: $card-group-margin;
}



.history-card {
    height: $mapHeight;
}


.history-card-body {
    position: relative;
    // top: 20px;
    // right: 10px;
    // background-color: white;
    padding: 5px;
    overflow-y: scroll;



    .no-images-alert {
        text-align: center;
        padding: 25px;
    }

    .new-images-alert {
        background-color: var(--warning);
        padding: 5px;
        margin-left: $left-margin;
        margin-bottom: 5px;
    }


    .time-line {
        position: relative;
        margin: 0;
        padding: 5px;
        margin-left: calc($left-margin/2);
        padding-left: calc($left-margin/2);
        border-left: 0.3rem solid rgb(225, 225, 225);

        &::after {
            content: "";
            position: absolute;
            top: 50%;
            transform: translate(-0.6rem, -0.6rem);
            left: 0;
            background-color: rgb(255, 255, 255);
            border: 0.15rem solid $primary;
            border-radius: 50%;
            width: 1rem;
            height: 1rem;
            box-shadow: 0px 0px 3px 1px rgb(135, 192, 224);
        }
    }


    .history-card-day {
        border-radius: 5px;
        display: flex;
        border: 1px solid rgba(117, 117, 117, 0.363);
        margin-bottom: 5px;
        padding: 5px;
        cursor: pointer;
        //box-shadow: 2px 2px 4px 1px rgba(0, 0, 0, 0.144);

        &.selected {
            box-shadow: 0px 0px 3px 1px rgb(135, 192, 224);
        }

        &:hover {
            background-color: #eeeeee;
        }


        .history-card-info {
            margin-left: 10px;

            .history-card-date {
                color: #000000a1;
                font-weight: bold;
            }

            .history-card-cloud,
            .history-card-comment {
                color: #00000091;
                font-size: small;
            }


        }

    }
}


.current-date {
    position: absolute;
    top: 5px;
    left: 25%;
    background-color: $primary;
    padding: 5px;
    color: white;
    font-weight: 600;
    border-radius: 5px;
}

.map-comment,
.map-options {
    position: absolute;
    border-radius: 5px;
    background-color: rgba(30, 30, 30, 0.5);
    color: white;
    right: 10px;
    z-index: 3;
    padding: 5px;
    backdrop-filter: blur(3px);
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);

}


.map-comment {
    top: 5px;
    width: 300px;
    max-height: 300px;
    overflow-y: scroll;

    :deep(.editable-text) {
        .edit-mode {
            color: white;
            background-color: rgb(0, 0, 0);
        }
    }
}



.map-options {
    bottom: 5px;

    .map-histogram {
        margin-top: 20px;
        width: 200px;
        height: 110px;
        left: 10px;
        // color: white;
    }

    .badge {
        position: absolute;
        top: -20px;
        right: 0;
    }
}





.report-container {

    //display: flex;
    //flex-wrap: wrap;
    // justify-content: center;
    justify-content: space-around;
    // page-break-after: always;
    // page-break-inside: avoid;


    .ndvi-image {
        display: flex;
        border: 1px rgb(143, 143, 143) solid;
        border-radius: 5px;
        margin: 5px;
        margin-bottom: 40px;
        padding: 15px;
        //max-width: 500px;




        .report-image-histogram {
            width: 310px;
            height: 120px;
            // background-color: gray;
            padding: 5px;
        }

        .report-comments {
            width: 100%;
            padding: 10px;
            border: 1px dashed rgb(143, 143, 143);
            height: 230px;
            margin-bottom: 10px;
        }


    }

}



.ndvi-print {
    position: relative;
    width: 100%;
}
</style>