<template>

  <b-table
     small
    :items="items" 
    :fields="fields" 
    :filter="filter"
    :current-page="currentPage"
    :per-page="perPage"
    @filtered="onFiltered"
   >

    <template v-slot:cell(details)="row">
        <b-link @click="row.toggleDetails">
          <font-awesome-icon v-if="row.detailsShowing" icon="chevron-up"/>
          <font-awesome-icon v-else icon="chevron-down"/> 
        </b-link>
      </template>
      
    <template v-slot:row-details="row">
        <div class="soil-details">
            

            <soilInfo :soil="row.item" />
            

            <div class="d-flex mt-2">
                <div class="ml-auto">
                    <b-button variant="danger" size="sm"   @click="$emit('removesoil',row.item.id)"> <font-awesome-icon icon="trash" /> Remover </b-button>
                </div>
            </div>
        </div>
    </template>

    <template v-if="$can('edit','Soil')" v-slot:cell(action)="row">
          <b-button variant="primary" size="sm" class="ml-auto" @click="$emit('editsoil',row.item.id)"><font-awesome-icon icon="edit" /> Editar </b-button>
    </template>
  </b-table>

</template>

<script>

import soilInfo from './soilInfo.vue';

//calcula o número de camadas
var layersFormat = function(value,col,row){
  return row.layers.length;
}

export default {

    data(){
        return{
            fields: [
                { key: 'details',       label: '',          sortable: false },
                { key: 'name',          label: 'Nome',          sortable: true },
                { key: 'classification',label: 'Classificação', sortable: true ,class:"d-none d-sm-table-cell"},
                { key: 'layers',        label: 'Camadas', formatter:layersFormat, sortable: true},
                // { key: 'excessBox',     label: 'Caixa de excesso', sortable: false},
                // { key: 'excessPercolationRate',label: 'Percolação da caixa', sortable: false},
                // { key: 'description',   label: 'Descrição',     sortable: false },
                { key: 'action',   label: '',     sortable: false, class:"text-center" }
                ],
        }
    },
    props:{
        soilList:Array,
        currentPage:Number,
        perPage:Number,
        filter:[String,RegExp,Object]
    },
    methods:{
        onFiltered(filteredItems) {
            this.$emit('onnumrows',filteredItems.length)
        }
    },
    components:{
        soilInfo  
    },
    computed:{
        items(){
            let list =[]
            this.soilList.forEach((v)=>{
                list.push({
                    id:v.id,
                    name:v.name,
                    description:v.description,
                    classification:v.classification,
                    layers:v.layers,
                    excessBox:v.excessBox,
                    excessPercolationRate:v.excessPercolationRate
                    })
            });
            return list;     
        }
    }
}
</script> 

<style lang="scss" scoped>

.soil-details{
    background-color: rgb(240, 240, 240);
    margin-bottom: 2rem;
    padding: 1rem;
    border: 1px dashed #aaa;
}



</style>