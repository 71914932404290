<template>
  <div>
    <validation-observer ref="finalizeformObserver">
      <b-row>
        <!-- Data da colheita-->
        <ValidationProvider slim vid="harvestDate" name="data da colheita" :rules="{ required: true, date_minValue:field.datePlanting }" v-slot="v">
          <b-form-group label="Data da colheita" class="col-sm-6">
            <b-form-input name="harvestDate" v-model="endCycle.harvestDate" type="date" :min="field.datePlanting"
              :state="$validateState(v)" autofocus></b-form-input>
            <span class="invalid-input">{{ v.errors[0] }}</span>
          </b-form-group>
        </ValidationProvider>

        <!-- Produção -->
        <ValidationProvider slim vid="field.production" name="Produtividade" :rules="{ required: true }" v-slot="v">
          <b-form-group label="Produção" class="col-sm-6">
            <b-input-group>

              <b-form-input name="field.production" v-model="endCycle.production" type="number" min="0"
                :state="$validateState(v)"></b-form-input>

                <b-form-select v-model="endCycle.unit" :options="unitList"></b-form-select>
             

            </b-input-group>
            <span class="invalid-input">{{ v.errors[0] }}</span>
          </b-form-group>
        </ValidationProvider>

        <!-- NOTAS -->
        <ValidationProvider slim vid="haverst.note" name="note" :rules="{ min: 5, max: 250 }" v-slot="v">
          <b-form-group class="col-12" id="input-group-description" label="Notas">
            <b-form-textarea name="haverst.note" v-model="endCycle.note" placeholder="Notas" maxlength="250"
              :state="$validateState(v)"></b-form-textarea>
            <span class="invalid-input">{{ v.errors[0] }}</span>
          </b-form-group>
        </ValidationProvider>

        <b-col cols="12">
          <!-- ALERTA DE ERROR -->
          <b-alert variant="danger" dismissible fade :show="showDismissibleAlert"
            @dismissed="showDismissibleAlert = false">Error! Verifique se os dados estão corretos</b-alert>

          <b-button-toolbar>
            <b-button variant="secondary" @click="$emit('cancel')">cancelar</b-button>
            <b-button class="ml-1" variant="primary" @click="finalizeField">confirmar</b-button>
          </b-button-toolbar>
        </b-col>
      </b-row>
    </validation-observer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      endCycle: {},
      showDismissibleAlert: false,

      unitList: ['sc/ha', 't/ha', '@/ha', 'cx/ha', 'lb/ha', 't/ac', 'bu/ac', 'cwt/ac', 'bales/ac']
    };
  },

  props: {
    field: { type: Object, required: true }
  },

  mounted() {

    if (this.field.endCycle)
      this.endCycle = JSON.parse(JSON.stringify(this.field.endCycle));
    else {
      this.endCycle = { unit: this.unitList[0], harvestDate:this.field.dateToInsertData }
    }
  },


  methods: {
    finalizeField() {
      this.showDismissibleAlert = false;
      this.$refs.finalizeformObserver.validate().then(result => {
        if (!result) {
          this.showDismissibleAlert = true;
          return;
        }
        this.$emit("finalized", this.endCycle);
      });
    }
  }
};
</script>

<style></style>