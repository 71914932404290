<template lang="html">
  <vl-feature
    :id="'map-pluvio-' + sensor.id"
    :properties="{ name:sensor.name, compId: sensor.id }"
  >
   
      <vl-geom-point :coordinates="coordinates"></vl-geom-point>
      <vl-style-box v-if="isOnline" :z-index="4">
        <vl-style-icon
          :scale="1"
          :alpha="0.5"
          :anchor="[0.5, 1]"
          :size="[40, 40]"
          :src="require('../../assets/mapPin/pluviometer.png')"
        ></vl-style-icon>
      </vl-style-box>
      <vl-style-box v-else :z-index="4">
        <vl-style-icon
          :scale="1"
          :anchor="[0.5, 1]"
          :size="[40, 40]"
          :src="require('../../assets/mapPin/pluviometer_off.png')"
        ></vl-style-icon>
      </vl-style-box>


      <receivingIcon v-if="receiving" />


      <vl-style-box v-if="precipitation>0 && isOnline" :z-index="5">
        <vl-style-text
          :text="precipitation.toFixed(1) + ' mm'"
          font="12px monospace;"
          :offsetY="0"
        >
          <vl-style-stroke color="black"></vl-style-stroke>
          <vl-style-fill color="orange"></vl-style-fill>
        </vl-style-text>
      </vl-style-box>

      <vl-overlay
        v-if="showDetail === true"
        :position="coordinates"
        :offset="[-48, -54]"
        positioning="bottom-left"
        :auto-pan="true"
        :auto-pan-animation="{ duration: 300 }"
      >
        <div class="map-popup popup-map" @click="showInfo">
          <pluvioInfo :sensor="sensor" />
        </div>
      </vl-overlay>
    
  </vl-feature>
</template>

<script>
import pluvioInfo from "./pluvioInfo";
import sensPluvio from "./sensPluvio.js";
import { pointSensor } from "@components/map/mapCompMixin.js";
import receivingIcon from "../receivingIconMap.vue"

export default {
  extends: sensPluvio,
  mixins: [pointSensor],
  components: {
    pluvioInfo,
    receivingIcon
  },
};
</script>

<style lang="scss" scoped>
.popup-map {
  width: 300px;
  cursor: pointer;
  padding: 5px;
}
</style>
