<template>
  <div :title="strValue" class="sensor-battery">
    <div
      v-if="barPercent != undefined"
      class="battery-bar"
      :style="{ width: barPercent + '%', backgroundColor: color }"
    >
      <span> {{ $filters.round(textPercent,0) }}% </span>
    </div>
    <span class="battery-no-bar" v-else> {{strValue}} </span>
  </div>
</template>

<script>
export default {
  props: {
    data: { type: Object, require: true },
    unit:{type:String, require:false, default:"mV"}
   
  },

  computed: {
    color() {
      if (this.barPercent <= 10) return "var(--danger)";
      else if (this.barPercent < 40) return "var(--orange)";
      else return "var(--success)";
    },
    strValue() {
      return this.data.milivolts +" "+ this.unit;
    },

    textPercent(){
      if (this.data.percent == null)
        return undefined
      let percent = this.data.percent*100
      if (percent < 0) percent = 0;
      if (percent > 100) percent = 100;
      return percent;
    },

    barPercent() {
      if (this.data.percent == null)
        return undefined

      let percent = this.data.percent*100
      if (percent < 10) percent = 10;
      if (percent > 100) percent = 100;

      return percent;
    },
  },
};
</script>

<style lang="scss" scoped>
$background: #bbb;
$border: #888;

.sensor-battery {
  display: flex;
  height: 1rem;
  font-size: 0.6rem;
  background-color: $background;
  border-radius: 0.2rem;
  padding: 1px;
  border: 1px solid $border;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    background: $border;
    border-radius: 0.25rem;
    width: 2px;
    height: 0.5rem;
    bottom: 0.18rem;
    right: -3px;
  }

  .battery-bar {
    flex-direction: column;
    justify-content: center;
    border-radius: 0.2rem;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    // background-color: #007bff;
    transition: width 0.6s ease;
  }

  .battery-no-bar{
    width: 100%;
  text-align: center;
  }
}
</style>
