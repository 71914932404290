<template>
  <b-row>
    <b-col>
      <validation-observer ref="formObserver">
        <b-row>
          <!-- tipo de equipamento de irrigação -->
          <ValidationProvider
            slim
            vid="type"
            name="Tipo"
            :rules="{ required: true }"
            v-slot="v"
          >
            <b-form-group label="Tipo" class="col-sm-4">
              <b-form-select
                :disabled="form.id !== undefined"
                v-model="form.type"
                :options="equipmentTypeList"
                name="type"
                :state="$validateState(v)"
              ></b-form-select>
              <!-- <b-form-invalid-feedback>{{ v.errors[0] }}</b-form-invalid-feedback> -->
              <span class="invalid-input">{{ v.errors[0] }}</span>
            </b-form-group>
          </ValidationProvider>

          <!-- NOME DO EQUIPAMENTO -->
          <ValidationProvider
            slim
            vid="name"
            name="Nome"
            :rules="{ required: true, min: 5 }"
            v-slot="v"
          >
            <b-form-group label="Nome" class="col-sm-8">
              <b-form-input
                name="equip-name"
                v-model="form.name"
                type="text"
                maxlength="20"
                placeholder="nome"
                :state="$validateState(v)"
              ></b-form-input>
              <!-- <b-form-invalid-feedback>{{ v.errors[0] }}</b-form-invalid-feedback> -->
              <span class="invalid-input">{{ v.errors[0] }}</span>
            </b-form-group>
          </ValidationProvider>

          <!-- DESCRIÇÃO DO EQUIPAMENTO-->
          <ValidationProvider
            slim
            vid="description"
            name="Descrição"
            :rules="{ max: 250, min: 5 }"
            v-slot="v"
          >
            <b-form-group
              class="col-12"
              id="input-group-description"
              label="Descrição"
            >
              <b-form-textarea
                name="equip-description"
                v-model="form.description"
                placeholder="Descrição"
                :state="$validateState(v)"
              ></b-form-textarea>
              <!-- <b-form-invalid-feedback>{{ v.errors[0] }}</b-form-invalid-feedback> -->
              <span class="invalid-input">{{ v.errors[0] }}</span>
            </b-form-group>
          </ValidationProvider>

          <!-- VAZÃO DO EQUIPAMENTO-->
          <ValidationProvider
            slim
            vid="flowRate"
            name="Vazão"
            :rules="{ required: true, minValue: 0.1 }"
            v-slot="v"
          >
            <b-form-group label="Vazão" class="col-sm-4">
              <b-input-group append="m³/h">
                <b-form-input
                  name="equip-flowRate"
                  v-model="form.flowRate"
                  type="number"
                  placeholder="Vazão"
                  min="0"
                  :state="$validateState(v)"
                ></b-form-input>
              </b-input-group>
              <!-- <b-form-invalid-feedback>{{ v.errors[0] }}</b-form-invalid-feedback> -->
              <span class="invalid-input">{{ v.errors[0] }}</span>
            </b-form-group>
          </ValidationProvider>

          <!-- EFICIENCIA-->
          <ValidationProvider
            slim
            vid="efficiency"
            name="Eficiência"
            :rules="{ required: true, between: [1, 100] }"
            v-slot="v"
          >
            <b-form-group label="Eficiência" class="col-sm-4">
              <b-input-group append="%">
                <b-form-input
                  name="equip-efficiency"
                  v-model="form.efficiency"
                  type="number"
                  min="0"
                  max="100"
                  placeholder="Eficiência"
                  :state="$validateState(v)"
                ></b-form-input>
              </b-input-group>
              <!-- <b-form-invalid-feedback>{{ v.errors[0] }}</b-form-invalid-feedback> -->
              <span class="invalid-input">{{ v.errors[0] }}</span>
            </b-form-group>
          </ValidationProvider>

          <!-- POTENCIA-->
          <ValidationProvider
            slim
            vid="powerKw"
            name="Potência"
            :rules="{ required: true, between: [1, 1000] }"
            v-slot="v"
          >
            <b-form-group label="Consumo" class="col-sm-4">
              <b-input-group append="kW/h">
                <b-form-input
                  name="equip-powerKw"
                  v-model="form.powerKw"
                  type="number"
                  min="0"
                  max="100"
                  placeholder="Potência"
                  :state="$validateState(v)"
                ></b-form-input>
              </b-input-group>
              <!-- <b-form-invalid-feedback>{{ v.errors[0] }}</b-form-invalid-feedback> -->
              <span class="invalid-input">{{ v.errors[0] }}</span>
            </b-form-group>
          </ValidationProvider>

          <!-- consumer Station-->
          <b-form-group label="Unidade Consumidora" class="col-sm-4">
            <b-form-select
              v-model="form.consumerStation"
              :options="selectConsumerStationList"
              name="consumerStation"
            ></b-form-select>
          </b-form-group>
        </b-row>
        <component
          v-if="form.type != undefined"
          :is="'edit-' + form.type"
          v-model="form"
        ></component>
      </validation-observer>
    </b-col>

    <b-col lg="12" class="buttons-container">
      <hr>
      <b-button @click="submit" variant="primary">Salvar</b-button>
      <b-button @click="cancel">Cancelar</b-button>
    </b-col>
    <!-- <b-card>{{form}}</b-card> -->
  </b-row>
</template>

<script>
import pivoEdit from "./pivot-edit";
import dripEdit from "./drip-edit";
export default {
  data() {
    return {
      form: {},
      consumerStationList: [],
      equipmentTypeList: [
        { value: null, text: "Selecione" },
        { value: "Pivot", text: "Pivô" },
        // { value: "Drip",  text: "Gotejo" },
        { value: "Sprinkler",  text: "Aspersão" },
      ],
    };
  },
  props: {
    equipment: Object,
  },
  methods: {
    cancel() {
      this.$router.go(-1);
    },
    submit() {
      this.$refs.formObserver.validate().then((result) => {
        if (!result) {
          this.$bvModal.msgBoxOk("Verifique se todos os dados estão corretos", {
            title: "Erro!",
          });
          return;
        }
        this.$store.dispatch("equipments/saveEquipment", this.form).then(
          (data) => {
            this.$router.push({
              name: "equipmentDetails",
              params: { id: data.id },
            });
          },
          (error) => {
            console.error("saveEquipment error", error);
            this.$bvModal.msgBoxOk(
              "Verifique se todos os dados estão corretos",
              { title: "Erro!" }
            );
          }
        );
      });
    },
  },
  computed: {
    selectConsumerStationList() {
      let list = [];
      this.consumerStationList.forEach(function(v) {
        list.push({ value: v[0], text: v[1] });
      });
      return list;
    },
  },
  mounted() {
    //copia os dados do equipamento para a área de dados do formulário
    if (this.equipment !== undefined) {
      let form = {};
      Object.entries(this.equipment).forEach(([key, value]) => {
        //não copia nenhum dos campos abaixo listados
        if (!["fields", "client", "irrigatedArea"].includes(key)) {
          form[key] = JSON.parse(JSON.stringify(value));
        }
      });
      this.form = form;
    }

    //carrega a lista de Unidade Consumidora
    this.$http.get("/report/consumer-station/maplist").then(
      (data) => {
        this.consumerStationList = data;
      },
      () => {
        console.error("load consumerStation list error:");
      }
    );
  },
  components: {
    "edit-Pivot": pivoEdit,
    "edit-Drip": dripEdit,
    "edit-Sprinkler": dripEdit,
  },
};
</script>

<style></style>
