<template>
  <div id="printDiv" class="equipment-datasheet">


    <report-header class="print-only"  label="Funcionamento do Pivô Central" :title="equipment.name" />

    <div class="mb-3 d-flex">
      <div>
        <h5>Ficha técnica</h5>
      </div>
      <div class="ml-auto non-print">
        <b-button size="sm" variant="outline-primary" title="imprimir" @click="$printDiv('printDiv')"> <font-awesome-icon
            icon="print" /></b-button> 
      </div>
    </div>

    <div>
      <ul class="equipment-datatsheet-list">
        <li>
          <b>Vazão</b>
          <span>{{ $filters.round(equipment.flowRate, 2) }} m³/h</span>
        </li>
        <li>
          <b>Potência</b>
          <span>{{ $filters.round(equipment.powerKw, 2) }} kW</span>
        </li>
        <li>
          <b>Área Irrigada</b>
          <span>{{ $filters.round((equipment.irrigatedArea / 10000), 2) }} ha</span>
        </li>
        <li>
          <b>Eficiência:</b>
          <span>{{ $filters.round(equipment.efficiency,1) }} %</span>
        </li>
        <li>
          <b>Raio da última torre:</b>
          <span>{{ $filters.round(equipment.radius,1) }} m</span>
        </li>
        <li>
          <b>Vão em balanço:</b>
          <span>{{ $filters.round(equipment.overhang,1) }} m</span>
        </li>
        <li>
          <b>Raio total:</b>
          <span>{{ $filters.round(equipment.overhang + equipment.radius,1) }} m</span>
        </li>
        <li>
          <b>Coordenadas:</b>
          <span>{{ equipment.location.lat }} , {{ equipment.location.lon }}</span>
        </li>
      </ul>

      <b-table class="text-center" id="datasheettable" responsive head-variant="dark" small :items="items" :fields="fields">

        <template #thead-top="">
          <b-tr>
            <b-th colspan="2"></b-th>
            <b-th :colspan="fields.length - 2">Tempos</b-th>
          </b-tr>
        </template>

        <template v-slot:cell(percent)="data"> {{ data.value }} % </template>
        <template v-slot:cell(depth)="data">
          {{ $filters.round(data.value, 2) }} mm
        </template>
      </b-table>
    </div>

    <hr />
  </div>
</template>

<script>

import { mapGetters } from "vuex";
import reportHeader from "@reportcomponents/comum/reportHeader.vue";


export default {
  props: {
    equipment: { type: Object, required: true },
  },
  methods: {

    convertDate(v) {
      return this.$filters.decimalhourToTime(v, "h ", "m");
    },

  },

  components:{
    reportHeader
  },

  computed: {
    fields() {
      let ret = [];
      let hasQuarter = false
      ret.push({ key: "percent", label: "Percentímetro" });
      ret.push({ key: "depth", label: "Lâmina Bruta" });
      ret.push({ key: "time", label: "Completo", formatter: this.convertDate });
      ret.push({ key: "timehalf", label: "Metade", formatter: this.convertDate });

      if (this.pivotSize === 360) {
        ret.push({ key: "timequarter", label: "Quadrante", formatter: this.convertDate });
        hasQuarter = true;
      }

      this.equipment.fields.forEach((element) => {
        if ((element.size != 180 && element.size != 90) || (element.size != 90 && !hasQuarter))
          ret.push({
            key: element.name,
            label: element.name,
            formatter: this.convertDate,
          });

      });
      return ret;
    },

    ...mapGetters({
      user: "accounts/user",
      client: "accounts/client",
      workspace: "accounts/workspace",
    }),

    pivotSize() {
      return (this.equipment.areaEndPos - this.equipment.areaStartPos);
    },

    items() {
      let ret = [];
      let sizefactor = this.pivotSize / 360;

      for (let i = 100; i >= 5; i -= 5) {
        let time = (100 * this.equipment.completTurn100Percent * sizefactor) / i;
        let depth = (100 * this.equipment.water100) / i;
        let obj = {
          percent: i,
          time: time,
          timehalf: time / 2,
          depth: depth,
        };

        if (this.pivotSize === 360) {
          obj['timequarter'] = time / 4;
        }

        this.equipment.fields.forEach((element) => {
          obj[element.name] = time * (element.size / 360);
        });

        ret.push(obj);
      }

      return ret;
    },
  },
};
</script>

