<template>
  <div>
    <b-alert show variant="warning" class="pr-5">
      <div class="d-flex">
        <div class="mr-2" style="font-size:35px;">
          <font-awesome-icon icon="exclamation-triangle" />
        </div>
        <div>
          Alterar sua senha aqui também se aplicará a todos os espaços
          de trabalho dos quais você é membro.
        </div>
      </div>
    </b-alert>

    <validation-observer ref="formObserver">
      <b-form-group label="Senha anterior" class="col-md-6">
        <b-form-input
          @input="clearMsg"
          name="current-password"
          v-model="password.old"
          type="password"
          maxlength="20"
          trim
        ></b-form-input>
      </b-form-group>

      <ValidationProvider rules="required|verify_password" name="confirm" v-slot="v">
        <b-form-group label="Nova senha" class="col-md-6">
          <b-form-input
            @input="clearMsg"
            name="new-password"
            v-model="password.new"
            type="password"
            maxlength="20"
            :state="$validateState(v)"
            trim
          ></b-form-input>
          <span class="invalid-input">{{v.errors[0]}}</span>
        </b-form-group>
      </ValidationProvider>

      <ValidationProvider rules="password_confirm:@confirm" v-slot="v">
        <b-form-group label="Confirmar senha" class="col-md-6">
          <b-form-input
            @input="clearMsg"
            name="check_password"
            v-model="passwordcheck"
            type="password"
            maxlength="20"
            :state="$validateState(v)"
            trim
          ></b-form-input>
          <span class="invalid-input">{{v.errors[0]}}</span>
        </b-form-group>
      </ValidationProvider>
    </validation-observer>

    <b-alert 
    :show="alertMsg!= null" 
    dismissible 
    :variant="msgVariant"
    @dismissed="alertMsg=null" 
    fade>{{alertMsg}}
    </b-alert>

    <div class="p-1">
      <b-button v-if="!updating" variant="success" @click="submit()">Salvar</b-button>
      <b-button v-else>
        <font-awesome-icon icon="spinner" class="fa-spin" />salvando...
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      passwordcheck: "",
      password: { old: "", new: "" },
      updating: false,
      alertMsg: null,
      msgVariant : 'danger'
    };
  },
  props: {
    user: { type: Object }
  },
  methods: {
    clearMsg() {
      this.alertMsg = null;
    },

    submit() {
      this.$refs.formObserver.validate().then(result => {
        if (!result) {
          this.alertMsg = "Confira os dados antes salvar";
          this.msgVariant = "danger"
          return;
        }

        this.updating = true;
        this.$http.put("account/user/password/", this.password).then(
          () => {
            this.updating = false;
            this.passwordcheck = "";
            this.password.old = "";
            this.password.new = "";
            this.msgVariant = "success"
            this.alertMsg = "Sua senha foi salva!";
          },
          (error) => {
            console.error(error);
            this.updating = false;
            this.msgVariant = "danger"
            this.alertMsg =
              "Não foi possíve realizar a operação. Verifique se a senha antiga está correta";
          }
        );
      });
    }
  }
};
</script>

<style>
</style>