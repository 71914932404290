<template>


  <div class="widget-box" @click="showModal" :style="{minHeight:height+'px'}">

<!--   -->


    <div class="widget-box-icon" :style="{backgroundColor:color,minHeight:height+'px',lineHeight:height+'px' }">
      <slot name ="icon"></slot>
    </div>

    <div class="widget-box-content">

      <div class="widget-box-header" v-if="showName">
        <span class="name">{{sensor.name}}</span>
        <span class="time">
          <font-awesome-icon icon="clock-rotate-left"/> {{$filters.secondsToStr(elapsedTime)}}
        </span>
      </div>
      <slot class="widget-box-content" name="content"></slot>
    </div>

     <div v-if="!isOnline" class="widget-box-overlay" >
      <div class="pt-4">{{sensor.name}}</div>
      <div>
        <font-awesome-icon icon="plug-circle-xmark"/> <i>{{$filters.secondsToStr(elapsedTime)}} Offline!</i>
      </div>
    </div>
  </div>

</template>

<script>
import sens from './sensor.js'
export default {
  extends:sens,
  props:{
    sensor:{ type: Object, required: true},
    color:{type:String, required:false, default:"#ccc"},
    height:{type:Number, required:false, default:120}
  },
  computed:{
    showName(){
      return this.height>80;
    }
  },
  methods: {
    showModal() {
      //this.$emit('set-pivot-current',this.pivot)
     // this.$store.dispatch('select/setSensor',this.sensor)
        this.$router.push('/sensor/'+this.sensor.id)
    }
  },
}
</script>

<style lang="scss">

// @import '~@/css/utils.less';

$icon-width :100px;
$box-radius :10px;


.widget-box {
    display: flex;
    background: #fff;
    border: 1px solid #bbb;

    box-shadow: 4px 4px 10px 0 rgba(0,0,0,0.2);
    border-radius: $box-radius;
    margin-bottom: 15px;
    cursor: pointer;
    position: relative;

    &:hover {
     transition: 0.2s ;
    // .transform(scale(1.02));
    transform:   translateY(-2px);

     box-shadow: 6px 6px 16px rgba(0,0,0,0.2);
     border-color: $primary;
   }

    &-icon {
        display: inline-block;
        border-top-left-radius: $box-radius;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: $box-radius;
      //  display: block;
      //  float: left;
      //  height: @box-height;
        height: 100%;
        min-width: $icon-width;
        text-align: center;
        font-size: 45px;
        background: rgba(0,0,0,0.2);

        .name{
          line-height:20px;
          font-size: 14px;
          font-weight: bold;
        }
        .time{
          line-height:20px;
          font-size: 14px;
        }

        svg, img{
          vertical-align: middle;
        }
    }


    &-content {

        display: inline-block;
        line-height:normal;
        padding: 5px 10px;
    //    margin-left: @icon-width;
        //height: @box-height;
        height: 100%;
        width: 100%;
    }


    &-header{
      margin-bottom: 0.75em;

      .name{
          text-transform: uppercase;
          font-size: 14px;
          white-space: nowrap;
          color:#36484a;
          font-weight: 500;
          margin:0;
      }
      .time{
        // background-color: #eeeeee;
        position: absolute;
        right: 0;
        top: 0;
        padding: 3px;
        color: #999;
        font-size: 13px;
        // border-radius: 0 $box-radius 0 $box-radius;
        // border-color: $primary;
        // box-shadow: 1px 1px 2px 0 rgba(0,0,0,0.2);
      }
    }

    &-text {
      text-transform: uppercase;
      display: block;
      font-size: 14px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-value {
        font-size: 14px;
        line-height: 11px;
        margin-bottom: 6px;

      .label{
        margin-bottom: 0.0rem;
      }
      .value{
        float: right;
        display: block;
        font-weight: bold;
        font-size: 14px;
      }
    }

    &-body{
        position: relative;
    }


    .progress {
        background: rgba(0,0,0,0.2);
        margin: 2px -10px 5px -10px;
        height: 2px;

        .progress-bar {
            background: #00a65a;
            float: left;
        }
    }

    &-overlay{
      border-radius: $box-radius;
      font-size: 18px;
        z-index: 50;
        color: #444;
        background: rgba(236,240,245,0.75);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        text-align: center;
        backdrop-filter: blur(2px);
    }
}


</style>
