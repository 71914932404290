<template>
  <b-navbar
    type="light" variant="light"
    fixed="bottom"
    class="d-sm-none button-nav-menu"
  >

    <b-navbar-nav class="navbar-nav-principal">
      <b-nav-item
          :to="{ name: 'home' }"
          title="home"
          :active="$route.name === 'home'"
        >
          <font-awesome-icon icon="home" class="item-icon" />
        </b-nav-item>

        <b-nav-item
          :to="{ name: 'rainHeatMap' }"
          title="Mapa de chuva"
          :active="$route.name === 'rainHeatMap'"
        >
          <font-awesome-icon icon="cloud-rain" class="item-icon" />
        </b-nav-item>

        <b-nav-item
          v-if="$can('edit', 'InsertData')"
          :to="{ name: 'insertData' }"
          title="Lançar dados"
          :active="$route.name === 'insertData'"
        >
          <!-- <font-awesome-icon icon="clipboard" class="item-icon" /> -->
          <kli-calendar-edit />
        </b-nav-item>

      <b-nav-item v-b-toggle.sidebar-tool><font-awesome-icon icon="bars" class="item-icon" /></b-nav-item>
    </b-navbar-nav>
  </b-navbar>
</template>

<script>
export default {

  // computed:{
  //   itens(){
  //     let list = [];
      

  //     list.push({ icon:'home', to:'home', title:'principal'})
  //     list.push({ icon:'cloud-rain', to:'rainHeatMap', title:'Mapa de chuva'})

  //     if(this.$can('edit', 'InsertData')){
  //       list.push({ icon:'home', to:'insertData', title:'Lançar dados'})
  //     }
  //     list.push({ icon:'home', to:'stationList', title:'Estações'})



  //     return list;
  //   }
  // }

};
</script>

<style lang="scss" scoped>
.button-nav-menu {
  box-shadow: 0 -0.05rem 0.2rem rgba(0, 0, 0, 0.25);
  display: block;
  padding: 0.0rem 1rem;

  // height: var(--mobilebar-height);
  .navbar-nav-principal {
    width: 100%;
    .nav-item {
      font-size: 1.5em;
      text-align: center;
      width: 25%;
      background-color: transparent !important;
      border-color: transparent !important;
    }
  }
}
</style>
