import password from './userPassword'
import profile from './userProfile'
import userPage from './userPage'


export default {
    path: 'account', component: userPage,
    children: [
        { path: '', name: 'user-account', component: profile },
        {
            path: 'password', name: 'user-password', component: password
        }
    ]
}