<template>
  <tbody>


      <!-- <vl-geom-line-string  :coordinates="areaCoordinates"></vl-geom-line-string> -->

     <vl-feature :id="'pivotArea-'+sensor.id"  >
      <vl-geom-polygon v-if="areaCoordinates!=null" :coordinates="[areaCoordinates.area]"></vl-geom-polygon>
      <vl-style-box :z-index="3">
         <vl-style-stroke :color="[0, 0, 0, 0.7]" :width="2" :lineDash="[5,10]"></vl-style-stroke>
          <!-- <vl-style-stroke v-if="isOnline && status==='irrig'" :color="[50, 100, 255, 0.5]" :width="4" ></vl-style-stroke>
          <vl-style-stroke v-else :color="[50, 50, 50, 0.8]" :width="2" :lineDash="[5,5]"></vl-style-stroke>
          -->
      </vl-style-box>
    </vl-feature> 



   <vl-feature  :id="'irrig-'+sensor.id">
     <vl-geom-polygon v-if="irrigCoordinates!=null" :coordinates="[irrigCoordinates.area]"></vl-geom-polygon>
     <vl-style-box :z-index="2"> 
       <!-- <vl-style-stroke color="#768ABF" :width="3"></vl-style-stroke> -->
      <vl-style-fill :color="[118, 138, 191, 0.7]"></vl-style-fill>
     </vl-style-box>
  </vl-feature> 

     <!-- <vl-style-box :z-index="2"> -->
       <!-- <vl-style-fill :color="[50, 50, 50, 0.9]"></vl-style-fill> -->
       <!-- <vl-style-circle :radius="5">
        <vl-style-stroke color="#d43f45" :width="2"></vl-style-stroke>
      </vl-style-circle>
     </vl-style-box> -->

     <vl-feature  :id="'center-'+sensor.id" :properties="{name:sensor.name, compId: sensor.id}">
       <vl-geom-point :coordinates="[center.lon,center.lat]"></vl-geom-point>
       <vl-style-box :z-index="5">
            <vl-style-circle :radius="10">
              <vl-style-stroke :color="[0, 0, 0, 0.3]" :width="5"></vl-style-stroke>
              <vl-style-fill color="#fff"></vl-style-fill>
            </vl-style-circle>
          </vl-style-box>
     </vl-feature>


   <vl-feature :id="'pipe-'+sensor.id" :properties="{compId: sensor.id}">
     <vl-geom-line-string  :coordinates="pipeCoord"></vl-geom-line-string>
     <vl-style-box v-if="isOnline" :z-index="5">
        <vl-style-stroke color="#fff" :width="4"></vl-style-stroke>
     </vl-style-box>
     <vl-style-box  v-if="isOnline && status==='irrig'" :z-index="4">
        <vl-style-stroke :color="[50, 100, 255, 0.5]" :width="12"></vl-style-stroke>
     </vl-style-box>
     <vl-style-box v-else :z-index="4">
       <vl-style-stroke :color="[0, 0, 0, 0.3]" :width="8"></vl-style-stroke>
     </vl-style-box>
   </vl-feature>


   <vl-feature  v-if="pipeDirCoord!=null" :id="'pipe-dir-'+sensor.id">
     <vl-geom-polygon  :coordinates="pipeDirCoord"></vl-geom-polygon>
     <vl-style-box :z-index="6">
         <vl-style-stroke color="#000" :width="1"></vl-style-stroke>
        <vl-style-fill color="#fa0"></vl-style-fill>
     </vl-style-box>
   </vl-feature>


   <vl-overlay
        v-if="showDetail === true"
        :position="[center.lon,center.lat]"
        :offset="[-50, -30]"
        positioning="bottom-left"
        :auto-pan="true"
        :auto-pan-animation="{ duration: 300 }"
      >
        <div class="map-popup popup-pivot" @click="showInfo">
          <pivotInfo :sensor="sensor" />
        </div>
      </vl-overlay>
   

   <!-- <vl-feature id="line-string2" :properties="{prop: 'value', prop2: 'value'}">
     <vl-geom-line-string :coordinates="coordinates2"></vl-geom-line-string>
  </vl-feature> -->
</tbody>
</template>

<script>


//import * as proj from 'ol/proj'

import { pivotGeometry } from "@components/mixins/PivotGeometry.js";

import pivot from './pivot'

import pivotInfo from "./pivotInfo.vue"
import { pointSensor } from "@components/map/mapCompMixin.js";

const meterConverter = 1/113000;

export default {
 mixins: [pivotGeometry,pointSensor],
 extends:pivot,

components:{
  pivotInfo
},




 computed:{
  

   colorStatus:function(){

      if(this.status === 'irrig')
        return "#76BF8A";

      if(this.status === 'walk')
          return "#ccc";
      // return "#FA0";

      return null;
   },

   irrigCoordinates:function(){
     if(this.sensor.currentIrrigation == null){
       return null;
     }

     let start = this.irrigStart
     let end = start + this.irrigDist

     //console.log("start>>",start,this.radius, this.center)

     let swap;
     if(end<start){
       swap = end;
       end = start;
       start = swap;
     }


      let center = [this.center.lon, this.center.lat]

     return this.getGeometry(start, end, this.radius,center , 0);
   },

   
   areaCoordinates:function(){
     let center = [this.center.lon, this.center.lat]
     return this.getGeometry(this.areaStart, this.areaEnd, this.radius, center, this.offsetPos);
   },

   pipeCoord:function(){
     const radius = this.radius*meterConverter;
     let center = [this.center.lon,this.center.lat];
     let point1 = [0,0];
     point1[0] = center[0]+ Math.sin((this.angle+this.offsetPos)*(Math.PI/180))*radius;
     point1[1] = center[1]+ Math.cos((this.angle+this.offsetPos)*(Math.PI/180))*radius;
     return [center,point1];
   },

   pipeDirCoord:function(){

     if(this.percent === 0){
       return null;
     }

    let center = [this.center.lon,this.center.lat];
    let points = [];
    const radius = this.radius*meterConverter;
    const size = (this.radius/10 + 25)*meterConverter;
    const dist = 20 *meterConverter
    let dir = 1;

     if(this.percent < 0)
        dir = -1;


     let px = Math.sin((this.angle)*(Math.PI/180));
     let py = Math.cos((this.angle)*(Math.PI/180));
     let px2 = Math.sin((this.angle+90)*(Math.PI/180))*dir;
     let py2 = Math.cos((this.angle+90)*(Math.PI/180))*dir;

     let point1 = [0,0];
     point1[0] = center[0]+ px*radius+px2*dist;
     point1[1] = center[1]+ py*radius+py2*dist;
     points.push(point1);

     let point2 = [0,0];
     point2[0] = center[0]+ px*(radius-size)+px2*dist;
     point2[1] = center[1]+ py*(radius-size)+py2*dist;
     points.push(point2);

     let point3 = [0,0];
     point3[0] = center[0]+ px*(radius-size/2) +px2*size;
     point3[1] = center[1]+ py*(radius-size/2) +py2*size;
     points.push(point3);

      points.push(point1);

     return [points];
   },

   //TEMPORÀRIO..  SEM USO
   pipeCoordinates:function(){
      let center = [this.center.lon,this.center.lat];
      let points = [];


      const radius = this.radius*meterConverter;

      const size = 2;

      let point1 = [0,0];
      point1[0] = center[0]+ Math.sin((size+this.angle)*(Math.PI/180))*radius;
      point1[1] = center[1]+ Math.cos((size+this.angle)*(Math.PI/180))*radius;
      points.push(point1);

      let point2 = [0,0];
      point2[0] = center[0]+ Math.sin((-size+this.angle)*(Math.PI/180))*radius;
      point2[1] = center[1]+ Math.cos((-size+this.angle)*(Math.PI/180))*radius;
      points.push(point2);

      const rr = 35*meterConverter;

      for(let i = 340; i>= 20; i-=10){
        let point =[0,0]
        point[0] = center[0]+ Math.sin((i+this.angle)*(Math.PI/180))*rr;
        point[1] = center[1]+ Math.cos((i+this.angle)*(Math.PI/180))*rr;
        points.push(point);
      }

      return [points];
      //return [[0,0],[10,10]];
   }
 },

 methods:{
  //  calcArea:function(start, end){
  //    let center = [this.center.lon,this.center.lat];
  //    //center = proj.fromLonLat(center);
  //    let points = [];

  //    const meterConverter = 1/109000;
  //    const radius = this.radius*meterConverter;

  //    if ((end - start) <360){
  //       points.push([center[0],center[1]]);
  //    }

  //    for(let i = start; i<= end; i++){
  //      let point = [0,0];
  //      point[0] = center[0]+ Math.sin(i*(Math.PI/180))*radius;
  //      point[1] = center[1]+ Math.cos(i*(Math.PI/180))*radius;
  //      points.push(point);
  //    }

  //    if ((end - start) <360){
  //       points.push([center[0],center[1]]);
  //    }

  //    return points;
  //  }
 }
}
</script>

<style lang="css" scoped>
.popup-pivot{
  cursor: pointer;
}
</style>
