<template>
  <b-modal no-fade hide-footer scrollable ref="details-modal" @hidden="close" size="max-lg">
    <template v-slot:modal-title>
      <span>Cliente</span>
    </template>

    <div v-if="loadStatus=='loaded'">
      <div v-if="!edit">
      <b-card no-body class="client-profile" >
        <b-row no-gutters>
           <b-col sm="12" lg="6">
            <div class="map-div">
              <simple-map>
                <clientMap :client="client" />
              </simple-map>
            </div>
          </b-col>

          <b-col sm="12" lg="6" class="p-2">
            <b-button-toolbar class="mb-2">
              <b-button-group size="sm">
                <b-button
                  v-if="$can('edit','Client')"
                  v-b-tooltip.hover
                  title="Editar Cliente"
                  variant="primary"
                  @click="edit=true"
                >
                  <font-awesome-icon icon="edit" />
                </b-button>
                <!-- <b-button
                  v-if="$can('remove','Client')"
                  v-b-tooltip.hover
                  title="Remover Cliente"
                  variant="danger"
                  @click="$emit('removeclient',client.id)"
                >
                  <font-awesome-icon icon="trash" />
                </b-button> -->
              </b-button-group>

              <div class="ml-auto"  v-if="$can('edit','Client')">
                  <b-button size="sm" variant="danger" v-if="client.active" @click="setStatus(false)"> Desativar </b-button>
                  <b-button size="sm" variant="success" v-else @click="setStatus(true)"> Ativar </b-button>
                </div>
            </b-button-toolbar>

            <ul class="list-group list-group-unbordered">
              <li class="list-group-item">
                <b>Nome</b>
                <span>{{client.name}}</span>
              </li>
              <li class="list-group-item">
                <b>Telefone</b>
                <span>{{client.phone}}</span>
              </li>
              <li class="list-group-item">
                <b>email</b>
                <span>{{client.email}}</span>
              </li>
              <li class="list-group-item">
                <b>Consultor</b>
                <span>
                  <userSymbol :user="client.consultant.user" />
                </span>
              </li>
              <li class="list-group-item">
                <b>Usuários</b>
               
                 <div class="d-flex flex-wrap flex-reverse justify-content-end">
                    <div class="mr-1" v-for="member in client.members" :key="member.id">
                      <userSymbol :user="member.user" />
                    </div>
                  </div>
               
              </li>
            </ul>
            <p class="mt-3">
              <i>{{client.description}}</i>
            </p>
          </b-col>
        </b-row>

      </b-card>
        <clientsEquipment :clientId="client.id"/>
      </div>
      <div v-else>
        <clientEdit
          :client="client"
          v-on:updateclient="updateClient($event)"
          v-on:editcancel="edit=false"
        />
      </div>



    </div>

    <c-loading @retry="loadClient()" v-else :status="loadStatus"></c-loading>
    
  </b-modal>
</template>

<script>
import clientEdit from "./clientEdit";
import clientMap from "./clientMap";

import userSymbol from "../avatar/userSymbol";
import clientsEquipment from "./clientsEquipment.vue";

export default {
  data() {
    return {
      edit: false,
      client: undefined,
      loadStatus: "loading"
    };
  },
  props: {
    clientId: { type: String, required: true }
  },
  components: {
    clientEdit,
    clientMap,
    userSymbol,
    clientsEquipment
  },

  mounted() {
    this.$refs["details-modal"].show();
  },

  beforeMount() {
    if (this.clientId !== undefined) {
      this.loadClient();
    }
  },
  methods: {
    loadClient() {
      this.loadStatus = "loading";
      this.$http.get("account/client/" + this.clientId).then(
        data => {
          this.client = data;
          this.loadStatus = "loaded";
        },
        error => {
          console.error(error)
          this.loadStatus = "error";
        }
      );
    },

    setStatus(active) {
      this.loadStatus = "loading";
      this.$http.put("account/client/" + this.clientId+"/status",{active:active}).then(
        data => {
          this.client = data;
          this.updateClient(data);
          this.loadStatus = "loaded";
        },
        error => {
          console.error(error)
          this.loadStatus = "error";
        }
      );
    },


    updateClient(client) {
      this.client = client;
      this.$emit("updateclient", client);
    },

    close() {
      this.$router.push({ name: "clientList" });
    }
  }
};
</script>


<style lang="scss" scoped>
.client-profile {
  .profile-clientname {
    font-size: 21px;
    margin-top: 5px;
  }

  .list-group {
    padding-left: 0;
    margin-bottom: 20px;

    .list-group-item {
      border-left: 0;
      border-right: 0;
      border-radius: 0;
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0px;

      span {
        color: #3c8dbc;
        float: right;
      }
    }
  }
}
</style>