<template>
  <div>
    <!-- <div class="float-right">
      <b-button @click="loadData" size="sm">recarregar</b-button>
    </div>-->
    <div v-if="loadStatus == 'loaded'">
  
        <b-tabs class="tab-card">
          <b-tab title="Gráfico">
            <field-day-charts :field="field" :data="fieldDays" :projection="fieldProjection" />
          </b-tab>

          <b-tab title="Acumulado">
            <div class="metrics" v-if="metrics != undefined">
              <b-row>

                <!-- <b-col md="4" sm="6">
                  <div class="metrics-block">
                    <div class="description-text">ITN</div>
                    <div class="value-text">
                      {{ $filters.round(metrics.itn, 2) }} mm
                    </div>
                  </div>
                </b-col> -->

                <b-col md="4" sm="6">
                  <div class="metrics-block">
                    <div class="description-text">Irrigação</div>
                    <div class="value-text">
                      {{ $filters.round(metrics.irrigation, 2) }} mm
                    </div>
                  </div>
                </b-col>

                <b-col md="4" sm="6">
                  <div class="metrics-block">
                    <div class="description-text">Precipitação</div>
                    <div class="value-text">{{ $filters.round(metrics.rain, 2) }} mm</div>
                  </div>
                </b-col>

                <b-col md="4" sm="6">
                  <div class="metrics-block">
                    <div class="description-text">Excesso de Irrigação</div>
                    <div class="value-text">
                      {{ $filters.round(metrics.irrigationExcess, 2) }} mm
                    </div>
                  </div>
                </b-col>

                <b-col md="4" sm="6">
                  <div class="metrics-block">
                    <div class="description-text">Excesso total</div>
                    <div class="value-text">
                      {{ $filters.round(metrics.waterExcess, 2) }} mm
                    </div>
                  </div>
                </b-col>

                <b-col md="4" sm="6">
                  <div class="metrics-block">
                    <div class="description-text">Etpc</div>
                    <div class="value-text">{{ $filters.round(metrics.etpc, 2) }} mm</div>
                  </div>
                </b-col>

                <b-col md="4" sm="6">
                  <div class="metrics-block">
                    <div class="description-text">Etc</div>
                    <div class="value-text">{{ $filters.round(metrics.etc, 2) }} mm</div>
                  </div>
                </b-col>

                <b-col md="4" sm="6">
                  <div class="metrics-block">
                    <div class="description-text">Redução etpc</div>
                    <div class="value-text">
                      {{ $filters.round((metrics.etcpReduction * 100), 2) }} %
                    </div>
                  </div>
                </b-col>

                <b-col md="4" sm="6">
                  <div class="metrics-block">
                    <div class="description-text">Excesso de Irrigação</div>
                    <div class="value-text">
                      {{ $filters.round(percentIrrgExcess, 2) }} %
                    </div>
                  </div>
                </b-col>

                <b-col md="4" sm="6">
                  <div class="metrics-block">
                    <div class="description-text">Dias em estresse</div>
                    <div class="value-text">{{ metrics.daysInStress }}</div>
                  </div>
                </b-col>

                <!-- <b-col  sm="3" cols="6">
                  <div class="metrics-block">
                  <div class="description-text">Água consumida</div>
                  <div class="value-text">{{ metrics.daysInStress }}</div>
                </div>
                </b-col> -->
              </b-row>
            </div>
          </b-tab>

          <b-tab title="Tabela">
            <fieldTable :data="fieldDays" :fieldName="field.name" />
          </b-tab>

          <b-tab title="Geral">
            <b-card header="Cultura" class="shadow" bg-variant="light">
              <cultureInfo :culture="field.culture" />
              <hr>
              <cultureFases :culture="field.culture" :datePlanting="field.datePlanting" :currentDay="field.status?field.status.numDays:undefined" />
            </b-card>

            <b-card header="Solo" class="shadow" bg-variant="light">
              <soilInfo :soil="field.soil" />
            </b-card>
          </b-tab>

          <b-tab title="Climas" :lazy="weatherLazy" @click="weatherLazy=false">
            
              <div class="d-flex">
                <div style="min-width: 15rem;">
                  <b-link :to="{name:'stationDetails',params:{id:field.stationId} }">
                  <font-awesome-icon icon="right-to-bracket" /> Estação </b-link>
                </div>
                <div class="ml-auto">

                  <b-form-checkbox
                  switch
                    v-model="weathersOriginalRains"
                    :value="true"
                    :unchecked-value="false"
                  >
                    Chuvas da estação
                  </b-form-checkbox>
                  
                </div>
              </div>
            <stationData  :station="{id:field.stationId}" :dateRange="dateRange" :editable="false" :rains="weathersOriginalRains?undefined:rains"></stationData>
          </b-tab>
        </b-tabs>

    </div>
    <c-loading v-else :status="loadStatus"></c-loading>
  </div>
</template>

<script>
import fieldDayChart from "./fieldDayChart";
import fieldTable from "./fieldTable";
import cultureInfo from "@irrigcomponents/culture/cultureInfo";
import soilInfo from "@irrigcomponents/soil/soilInfo";
import cultureFases from "@irrigcomponents/culture/cultureFaseDates";
import stationData from "@irrigcomponents/station/stationData";

export default {
  data() {
    return {
      loadStatus: "",
      fieldDays: [],
      fieldProjection: [],
      metrics: undefined,
      loadingXLS: false,
      weatherLazy:true,
      weathersOriginalRains:undefined,
    };
  },
  props: {
    field: { type: Object, required: true },
  },
  computed: {
    rains(){
      return this.fieldDays.map(function(e){ return {date:e.date, rain:e.rain}})
    },

    percentIrrgExcess() {
      if (this.metrics.irrigation > 0)
        return (this.metrics.irrigationExcess / this.metrics.irrigation) * 100;
      else return 0;
    },

    dateRange(){
      let endDate = this.field.endCycle? this.field.endCycle.harvestDate : new Date(Date.now());
      return [this.field.startDate, endDate]
    },


  },
  components: {
    "field-day-charts": fieldDayChart,
    cultureFases,
    fieldTable,
    cultureInfo,
    soilInfo,
    stationData
  },
  mounted() {
    this.loadData();
  },
  watch: {
    field: {
      deep: true,
      handler() {
        this.loadData(false)
      }
    }
  },
  methods: {
    loadData(reload = true) {
      if (reload)
        this.loadStatus = "loading";
      let loadUrl = "irrig/equipment/field/" + this.field.id + "/result";
      this.$http.get(loadUrl).then(
        (data) => {
          this.fieldDays = data.historic;
          this.fieldProjection = data.projection;
          this.metrics = data.metrics;
          this.loadStatus = "loaded";
        },
        (error) => {
          console.error("load equipment error:", error);
          this.loadStatus = "error";
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.metrics {
  margin: 1rem;

  .metrics-block {
    margin-bottom: 15px;
   
    text-align: center;
    //border-right: 1px solid rgba(0, 0, 0, 0.125);
    border: 1px solid rgba(0, 0, 0, 0.125);
    background-color: rgba(0, 0, 0, 0.05);
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;

    .description-text {
      padding: 0.1rem 0.2rem;
      text-align: center;
      background-color: #3c4d5a;
      color: white;
      border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0 ;
      //text-transform: uppercase;
    }

    .value-text {
      padding: 0.5rem;
      font-size: 16px;
      font-weight: 600;
      margin: 0;
    }
  }
}
</style>
