<template>
  <div class="dts-insert">
    <h5 v-if="dataStream">{{ dataStream.name }}</h5>

    <b-row>
      <b-form-group v-if="!dataStream" label="sensor Id" class="col-md-2">
        <b-form-input
          v-model="sensorId"
          type="number"
          trim
        ></b-form-input>
      </b-form-group>

      <b-form-group label="v1" class="col-md-2">
        <b-form-input
          v-model="value1"
          type="number"
          trim
        ></b-form-input>
      </b-form-group>

      <b-form-group label="v2" class="col-md-2">
        <b-form-input
          v-model="value2"
          type="number"
          trim
        ></b-form-input>
      </b-form-group>

      <b-form-group label="vStr" class="col-md-4">
        <b-form-input
          v-model="valueStr"
          type="number"
          trim
        ></b-form-input>
      </b-form-group>

      <b-form-group label="." class="col-md-2">
        <b-button v-if="sendStatus == 'sending'"  variant="secondary">
          <font-awesome-icon icon="spinner" spin />
        </b-button>
        <b-button v-else variant="primary" @click="send"> enviar</b-button>
      </b-form-group>
    </b-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      sensorId: undefined,
      value1: null,
      value2: null,
      valueStr: null,
      sendStatus: "ready",
    };
  },
  props: {
    dataStream: { type: Object, required: false },
    feedSerialNumber:{type:String, required:true},
    at:{type:Number}
  },
  mounted(){
    if(this.dataStream){
      this.sensorId = this.dataStream.sensorId;
    }
  },
  methods: {
    send() {
      this.sendStatus = "sending";

    let data = {
     
      feeds:[
        { 
          allowDeprecatedData:true,
          serialNumber:this.feedSerialNumber,
          dataStreams:[
            { sensorId:this.sensorId,
              datapoints:[
                { at:this.at*1000,
                  // v1:this.value1,
                  // v2:this.value2,
                  // vStr:this.valueStr,
                  value1:this.value1,
                  value2:this.value2,
                  valueStr:this.valueStr,
                }]
          }]
        }]
    }

      this.$http.post("sens/api/putDataPoints", data).then(
          () => {
            this.sendStatus="ready";
          },
          error => {
            console.error("send data error",error)
            this.sendStatus="error";
            this.$bvModal.msgBoxOk( "os dados não foram enviados",{ title: "Erro!" });
          }
        );
    },
  },
};
</script>

<style lang="scss" scoped>
.dts-insert {
  border-top: 1px solid $gray-300;
  margin-top: 2rem;
  padding-top: 0.5em;

  h5 {
    font-size: 15px;
  }
}
</style>
