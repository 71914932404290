<template>
  <b-modal
    no-fade
    scrollable
    ref="edit-modal"
    @hidden="$emit('cancel')"
    title="Editar Canal"
    size="lg"
  >
    <b-row>
      <b-col lg="12">
        <validation-observer ref="formObserver">
          <b-row>
            
            <input-text
              class="col-sm-6"
              label="Nome"
              v-model="channelform.name"
              :rules="{ required: true, min: 5 }"
            ></input-text>

            <input-select
              class="col-sm-6"
              label="Zona"
              v-model="channelform.zoneIdStr"
              :options="$timeZoneList"
              :rules="{ required: true }"
            ></input-select>

            <input-area
              class="col-12"
              label="Descrição"
              v-model="channelform.description"
              :rules="{ max: 250, min: 5 }"
            />

            <b-form-group label="Permitir reconfiguração" class="col-sm-4">
              <b-form-checkbox
                v-model="channelform.allowReconfiguration"
                value="true"
                unchecked-value="false"
                switches
              ></b-form-checkbox>
            </b-form-group>

            <b-form-group
              label="Adicionar feed automaticamente"
              class="col-sm-4"
            >
              <b-form-checkbox
                v-model="channelform.autoAddSensor"
                value="true"
                unchecked-value="false"
                switches
              ></b-form-checkbox>
            </b-form-group>
          </b-row>
        </validation-observer>
      </b-col>

      <b-col>
        <div>Selecione posição do Gateway no mapa</div>
        <select-location
          v-model="channelform.location"
          :imgPoint="require('./assets/gatewayPoint.png')"
        />

        <!-- <simpleMap> 
          <template v-slot:layer>
            <selectPosition v-model="channelform.location" :imgPoint="require('./assets/gatewayPoint.png')"/>
          </template>
           <clientMap v-if="editLocationType==='none'" :client="clientEdit" />
        </simpleMap> -->
      </b-col>
    </b-row>

    <template v-slot:modal-footer>
      <b-button variant="secondary" @click="$emit('cancel')">
        Cancelar
      </b-button>
      <b-button variant="success" @click="saveChannel()"> Salvar </b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  data() {
    return {
      channelform: undefined,
    };
  },

  props: {
    channel: { type: Object, required: true },
  },
  beforeMount() {
    this.channelform = {
      name: this.channel.name,
      zoneIdStr: this.channel.zoneIdStr,
      description: this.channel.description,
      location: this.channel.location,
      allowReconfiguration: this.channel.allowReconfiguration,
      autoAddSensor: this.channel.autoAddSensor,
    };
  },
  mounted() {
    this.$refs["edit-modal"].show();
  },

  methods: {
    saveChannel() {
      this.$http.put("/sens/channel/" + this.channel.id, this.channelform).then(
        (data) => {
          this.$emit("update", data);
        },
        (error) => {
          console.error("save channel error", error);
        }
      );
    },
  },
};
</script>

<style></style>
