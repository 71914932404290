import apiHttp from '@api/apiHttp'

const state = {
    account: undefined,
    status: "",
}

const getters = {

    isAuthenticated: state => {
        return state.account != undefined
    },
    user: state => {
        if (state.account == undefined)
            return null
        return state.account.user;
    },

    userId: state => {
        if (state.account == undefined || state.account.user == undefined)
            return null
        return state.account.user.id;
    },

    role: state => {
        if (state.account == undefined)
            return null
        return state.account.role;
    },

    status: state => {
        return state.status;
    },

    workspace: state => {
        if (state.account == undefined)
            return null

        return state.account.workspace;
    },
    workspaces: state => {
        if (state.account == undefined)
            return []

        return state.account.workspaces;
    },

    client: state => {
        if (state.account == undefined)
            return null

        return state.account.client;
    },

    consultant: state => {
        if (state.account == undefined)
            return null

        return state.account.consultant;
    },

    clientId: state => {
        if (state.account == undefined || state.account.client == null)
            return null
        return state.account.client.id;
    },

    workspaceId: state => {
        if (state.account == undefined || state.account.workspace == null)
            return null
        return state.account.workspace.id;
    },

    clients: state => {
        if (state.account == undefined)
            return []

        return state.account.clients;
    },

    loaded: state => {
        return state.account != undefined
    }
}

const mutations = {
    setAccount(state, account) {
        state.account = account
    },
    setStatus(state, status) {
        state.status = status
    },
}

const actions = {

    loadAccount(context) {
        context.commit('setStatus', 'loading');
        return new Promise((resolve, reject) => {
            apiHttp.get('account/info').then((data) => {
                context.commit('setAccount', data);
                context.commit('setStatus', 'loaded');
                apiHttp.setToken(data.token);
                resolve()
            }, (error) => {
                context.commit('setStatus', 'error');
                reject(error)
            });
        });
    },

    changeWorkspace(context, id) {
        context.commit('setStatus', 'loading');
        apiHttp.get('account/currentWorkspace/' + id).then((data) => {
            context.commit('setAccount', data);
            context.commit('setStatus', 'loaded');
            apiHttp.setToken(data.token);
        }, (error) => {
            console.error("change Workspace error",error)
            context.commit('setStatus', 'error');
        });
    },

    changeClient(context, id) {
        context.commit('setStatus', 'loading');
        apiHttp.get('account/currentClient/' + id).then((data) => {
            context.commit('setAccount', data);
            context.commit('setStatus', 'loaded');
            apiHttp.setToken(data.token);
        }, (error) => {
            console.error("change client error",error)
            context.commit('setStatus', 'error');
        });
    }
}




export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}