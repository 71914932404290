<template>
  <tr v-if="this.editable">
    <td class="field-label">
      <span> {{ name }} <strong>({{unit}})</strong></span> 
    </td>
    <td class="td-input">
      <ValidationProvider :rules="{ required: false, minValue: 0 }" v-slot="v">
        <b-input
          autocomplete="off"
          v-model="editable.power"
          size="sm"
          type="number"
          min="0"
          step="any"
          :state="$validateState(v)"
        />
      </ValidationProvider>
    </td>

    <td class="td-input">
      <ValidationProvider :rules="{ required: false, minValue: 0 }" v-slot="v">
        <b-input
          v-model="editable.price"
          autocomplete="off"
          size="sm"
          type="number"
          min="0"
          step="0.01"
          :state="$validateState(v)"
        />
      </ValidationProvider>
    </td>
    <td class="td-input">
      <b-input size="sm" disabled :value="$filters.localeNumber(rate,8)" />
    </td>

  </tr>
</template>

<script>
export default {
  data() {
    return {
      editable: undefined,
    };
  },
  props: {
    value: Object,
    name: String,
    unit: String
  },
  watch: {
    editable: {
      deep: true,
      handler(v) {
        this.$emit("input", JSON.parse(JSON.stringify(v)));
      },
    },
  },
  computed: {
    rate() {
      let price = Number(this.editable.price);
      let power = Number(this.editable.power);

      if (price && power && power > 0) {
        return price / power;
      } else {
        return 0;
      }
    },
  },

  beforeMount() {
    this.editable = Object.assign(
      { price: undefined, power: undefined },
      this.value
    );
  },
};
</script>
