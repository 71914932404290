<template>
  <div class="shadow border table-responsive">
    <table class="table table-striped table-list table-hover table-borderless table-sm">
      <thead class="text-center">
        <th class="line-right" colspan="7"></th>
        <th class="line-right" colspan="3">Zona Radicular</th>
        <th class="line-right" colspan="3">Irrigação</th>
        <th colspan="1"></th>
        <th colspan="3">Próximo 7 dias</th>
      </thead>

      <thead style="white-space: nowrap;" class="text-center">
        <th>Equip.</th>
        <th colspan="2" title="Nome da Parcela">Parcela</th>
        <th>
          Área<br>
          <small>(ha)</small>
        </th>
        <th title="Data do plantio">Plantio</th>

        <!-- STATUS -->
        <th title="Número de dias desde o plantio">Dias</th>
        <th title="Evapotranspiração" class="line-right">
          ETC <br>
          <small>(mm)</small>
        </th>
        <th title="Lâmina disponível">
          Disponível<br>
          <small>(mm)</small>
        </th>
        <th  title="Umidade até chegar ao stress">
          p/ Stress<br>
          <small>(mm)</small>
        </th>
        <th title="Umidade do solo" class="line-right">
          Umidade<br>
          <small>(%)</small>
        </th>

        <!-- IRIRGACAO -->
        <th title="Irrigação Total necessária">
          ITN<br>
          <small>(mm)</small>
        </th>
        <th title="Percentímetro do pivô">
          Velocidade<br>
          <small>(%)</small>
        </th>
        <th title="Tempo em horas necessária para a irrigação" class="line-right">
          Tempo<br>
          <small>(hh:mm)</small>
          </th>

        <!-- FUTURO -->
        <th title="Dias que ainda restam até chagar no stress hídrico." >Dias p/ <br>stress</th>
        <th title="Irrigação necessário ao final de 7 dias.">
          ITN<br>
          <small>(mm)</small>
        </th>
      </thead>

      <tbody v-for="equipment in equipments" :key="equipment.id">
        <tr v-if="equipment.fields === undefined || equipment.fields.length==0">
          <td class="td-equipment">
            <b-link :to="{name:'equipmentDetails',params:{id:equipment.id}}">{{equipment.name}}</b-link>
          </td>
          <td colspan="14"> Sem parcelas ativas </td>
        </tr>
        <fieldOverviewRow
          v-else
          v-for="(field, index) in equipment.fields"
          :showEquipment="index==0"
          :key="field.id"
          :field="field"
        />
      </tbody>
    </table>
  </div>
</template>

<script>
import fieldOverviewRow from "./fieldOverviewRow";
export default {
  props: {
    equipments: { type: Array, required: true }
  },
  components: {
    fieldOverviewRow
  }
};
</script>





<style lang="scss" scoped>
.line-right {
  border-right: 1px solid rgb(137, 163, 175);
}

:deep(.table-list) {
  font-size: 14px;
  th {
    color: rgb(63, 118, 126);
    background-color: rgb(223, 236, 238);
    vertical-align: top !important;
    line-height: 16px;
  }
  tr {
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  }
  small {
    font-size: 12px;
  }

  .td-equipment {
    text-align: center;
    background-color: rgb(223, 236, 238);
    vertical-align: middle !important;
    font-weight: 600;
  }

  a {
    color: rgb(0, 0, 0,0.8) !important;
  }
}
</style>