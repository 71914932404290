
import sensor from '../sensor.js'

export default {
  extends:sensor,

  computed:{
    station(){
      return this.sensor
    },
    temperature(){
        return this.sensor.temperature
    },
    temperature_max(){
      return this.sensor.maxTemp
    },
    temperature_min(){
      return this.sensor.minTemp
    },
    rain(){
        return this.sensor.rain
    },
    humidity(){
        return this.sensor.humidity
    },
    windKm(){
        return this.sensor.windKm
    },
    dewPoint(){
        return this.sensor.dewPoint
   }
  }

}
