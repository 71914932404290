<template>
  <div>
    <div class="d-flex flex-wrap mb-2">
      <div>
        <h5>Histórico de Climas por dia</h5>
      </div>
      <div class="ml-auto">
        <data-range v-model="dateRange" />
      </div>
    </div>

    <div v-if="serieStatus == 'loaded'">
      <div v-if="weatherCount > 0">
        <div class="weather-chart">
          <weathersChart :weathers="data" />
        </div>
        <hr />
        <div class="weather-table">
          <weathersTable :weathers="data" />
        </div>
        <hr />
      </div>
      <b-alert v-else show variant="info" class="pr-5">
        <div class="d-flex">
          <div class="mr-2" style="font-size:35px;">
            <font-awesome-icon icon="info-circle" />
          </div>
          <div>
            <p>
              Sem dados de clima no período selecionado.
            </p>
            <p>
              Para visualizar as informações de clima deste sensor, selecione
              outro intervalo de datas.
            </p>
          </div>
        </div>
      </b-alert>
    </div>
    <c-loading v-else :status="serieStatus"></c-loading>
  </div>
</template>

<script>
import { DateTime } from "luxon";
import weathersTable from "@irrigcomponents/station/weathersTable";
import weathersChart from "@irrigcomponents/station//weathersChart";

export default {
  data() {
    return {
      dateRange: null,
      serieStatus: "loading",
      data: [],
    };
  },

  components: {
    weathersTable,
    weathersChart,
  },

  props: {
    sensor: Object,
  },

  mounted() {
    let end = new Date(Date.now());
    end.setHours(0, 0, 0, 0);
    let start = new Date(Date.now());
    start.setHours(start.getHours() - this.sensor.defaultPeriod);
    start.setHours(0, 0, 0, 0);
    this.dateRange = [start, end];
  },

  computed: {
    weatherCount() {
      if (!this.data) return 0;
      else return this.data.length;
    },
  },

  methods: {
    loadSerie() {
      this.serieStatus = "loading";
      let start = DateTime.fromJSDate(this.dateRange[0]).toISODate();
      let end   = DateTime.fromJSDate(this.dateRange[1]).toISODate();

      this.$http
        .get(
          "/sens/weatherStation/" +
            this.sensor.id +
            "/weather/" +
            start +
            "/" +
            end
        )
        .then(
          (data) => {
            this.data = data;
            this.serieStatus = "loaded";
          },
          (error) => {
            console.error("load water sensor error:", error);
            this.serieStatus = "error";
          }
        );
    },
  },

  watch: {
    dateRange() {
      this.loadSerie();
    },
  },
};
</script>

<style></style>
