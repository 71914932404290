import store from "@store";
import irrigationSocket from "./webSocket/irrigationSocket.js";

var irrigSocket = new irrigationSocket(store);

export default function() {

  return new Promise((resolve, reject) => {
    store.dispatch("equipments/loadEquipments").then(() => {
      var clientId = store.getters["accounts/clientId"];
      irrigSocket.listen(clientId);
      resolve();
    },error=>{
      reject(error);
    });
  })

  // return new Promise((resolve, reject) => {
  //     apiSocket.start('',store)
  //     Vue.prototype.$http.get('irrig/equipment/').then((data) => {
  //         store.commit('equipments/setAll',data);

  //         resolve();
  //       },(error) =>{
  //         reject(error);
  //       });

  // })
}
