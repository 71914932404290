<template>
  <div class="field-card" :style="{ borderLeftColor: color }">
    <b-row>
      <b-col cols="4" sm="2" md="2">
        <b-img
          :src="
            require('@irrigcomponents/culture/assets/64/' +
              field.culture.icon +
              '.png')
          "
        />
      </b-col>

      <b-col cols="8" sm="4" md="4">
        <b-link
          class="field-card-name"
          :to="{ name: 'fieldDetails', params: { id: field.id } }"
        >
          {{ field.name }}
        </b-link>
        <div class="field-card-culure">{{ field.culture.name }}</div>


        <div>
          <i>{{ field.description }}</i>
        </div>

      </b-col>
      <b-col cols="12" sm="6" md="6">
        <!-- <div>Data do manejo: {{field.startDate|localeDate}}</div>
        <div>Data do plantio: {{field.datePlanting|localeDate }}</div>
        <div>Área plantada: {{(field.area/10000)|round(2)}} ha</div> -->
        <ul class="field-info-group">
          <li>
            <b>Área plantada</b>
            <span>{{ $filters.round(field.area / 10000, 2) }} ha</span>
          </li>
          <li>
            <b>Data do manejo</b>
            <span>{{ $filters.localeDate(field.startDate) }}</span>
          </li>
          <li>
            <b>Data do plantio</b>
            <span>{{ $filters.localeDate(field.datePlanting) }}</span>
          </li>
          <li>
            <b>Fase Atual</b>
            <span>{{ field.cultureParameters.stageName }}</span>
          </li>
        </ul> 
      </b-col>

      <b-col cols="12" sm="5" md="5">

        

        <!-- <b-link :to="{ name: 'fieldNew', params: {equipmentId:field.equipmentId, cloneId: field.id} }" > Copiar </b-link> -->

      </b-col>
    </b-row>

    <b-button
        class="copy-button"
          title="Clonar Parcela"
          variant="secondary"
          v-if="$can('create', 'Field')"
          :to="{ name: 'fieldNew', params: {equipmentId:field.equipmentId, cloneId: field.id} }"
        > <font-awesome-icon icon="copy" /> copiar </b-button>

    <!-- <div class="copy-button" >   <b-link :to="{ name: 'fieldNew', params: {equipmentId:field.equipmentId, cloneId: field.id} }" > Copiar </b-link> </div> -->


  </div>
</template>

<script>
import field from "./field";
export default {
  extends: field,
};
</script>

<style lang="scss">


$border-radius:0.5rem;

.field-card {
  //  background-color: #efefef;
  background-color: #fff;
  padding: 1em;
  margin-bottom: 20px;
  border-radius: $border-radius;
  border: 1px solid #ccc;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);
  position: relative;

  border-left-width: 1rem;
  border-left-style: solid;
  // border-left-color: var(--green);

  // border-right-width: 2.5rem;
  // border-right-style: solid;
  // border-right-color: #eee;

  .field-card-name {
    font-size: 18px;
    overflow-wrap: break-word;
  }

  .field-card-culure {
    font-weight: 600;
    color: gray;
  }

  .field-info-group {
    border-left: 0;
    border-right: 0;
    border-radius: 0;
    padding-left: 0;
    padding-right: 0;
    li {
      position: relative;
      display: block;
      border-bottom: 1px solid #ddd;
      span {
        float: right !important;
        color: #3c8dbc;
      }
    }
  }

  .copy-button{
    font-size: 0.7rem;
    min-width: 80px;
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: #3c4d5a;
    color: white;
    padding: 2px 5px;
    text-align: center;
    border-radius: $border-radius 0 $border-radius 0;

  }


}
</style>
