<template>
  <div>
    <validation-observer v-if="clientEdit != undefined" ref="formObserver">
      <b-row>
        <!-- <ValidationProvider
          slim
          vid="id"
          name="id"
          :rules="{ required: false,min:3 }"
          v-slot="v"
        >
          <b-form-group label="id" class="col-sm-12">
            <b-form-input
              name="client.id"
              v-model="client.id"
              type="text"
              :state="$validateState(v)"
            ></b-form-input>
            <span class="invalid-input">{{ v.errors[0] }}</span>
          </b-form-group>
        </ValidationProvider> -->

        <ValidationProvider
          slim
          vid="name"
          name="Nome"
          :rules="{ required: true, min: 3 }"
          v-slot="v"
        >
          <b-form-group label="Nome da Fazenda" class="col-sm-12">
            <b-form-input
              name="client.name"
              v-model="clientEdit.name"
              type="text"
              maxlength="50"
              :state="$validateState(v)"
            ></b-form-input>
            <span class="invalid-input">{{ v.errors[0] }}</span>
          </b-form-group>
        </ValidationProvider>

        <!-- PHONE -->
        <ValidationProvider
          slim
          vid="phone"
          name="telefone"
          :rules="{ required: false, min: 3 }"
          v-slot="v"
        >
          <b-form-group label="Telefone" class="col-sm-6">
            <b-form-input
              name="client.phone"
              v-model="clientEdit.phone"
              type="text"
              maxlength="30"
              :state="$validateState(v)"
            ></b-form-input>
            <span class="invalid-input">{{ v.errors[0] }}</span>
          </b-form-group>
        </ValidationProvider>

        <!-- email -->
        <ValidationProvider
          slim
          vid="email"
          name="email"
          rules="email"
          v-slot="v"
        >
          <b-form-group label="Email" class="col-sm-6">
            <b-form-input
              name="client.email"
              v-model="clientEdit.email"
              type="text"
              maxlength="30"
              :state="$validateState(v)"
            ></b-form-input>
            <span class="invalid-input">{{ v.errors[0] }}</span>
          </b-form-group>
        </ValidationProvider>

        <!-- Consultor -->

        <b-form-group label="Consultor" class="col-sm-6">
          <b-input-group>
            <b-input-group-prepend is-text>
                <font-awesome-icon icon="user-shield" />
              </b-input-group-prepend>
              <b-form-select
                v-model="clientEdit.consultantId"
                :options="consultantList"
                name="consultant"
              ></b-form-select>
          </b-input-group>
        </b-form-group>

        <!-- Zone ID -->
        <ValidationProvider
          slim
          vid="zone"
          name="Zona"
          :rules="{ required: true }"
          v-slot="v"
        >
          <b-form-group label="Zona" class="col-sm-6">
            <b-form-select
              v-model="clientEdit.zoneIdStr"
              :options="$timeZoneList"
              name="zoneIdStr"
              :state="$validateState(v)"
            ></b-form-select>
            <span class="invalid-input">{{ v.errors[0] }}</span>
          </b-form-group>
        </ValidationProvider>

         <!-- Estado -->
         <ValidationProvider
          slim
          vid="state"
          name="Estado"
          :rules="{ required: true }"
          v-slot="v"
        >
        <b-form-group label="Estado" class="col-sm-6">
            <b-form-select
              v-model="clientEdit.state"
              :options="estados"
              name="client.state"
              :state="$validateState(v)"
            ></b-form-select>
            <span class="invalid-input">{{ v.errors[0] }}</span>
          </b-form-group>
        </ValidationProvider>


        <!-- Cidade -->
        <ValidationProvider
          slim
          vid="city"
          name="Cidade"
          :rules="{ required: true, min: 3 }"
          v-slot="v"
        >
          <b-form-group label="Cidade" class="col-sm-6">
            <b-form-select
              v-model="clientEdit.city"
              :options="cidades(clientEdit.state)"
              name="client.city"
              :state="$validateState(v)"
            ></b-form-select>
            <span class="invalid-input">{{ v.errors[0] }}</span>
          </b-form-group>
        </ValidationProvider>

  
        <!-- <b-form-group label="Usuários" class="col-sm-12">
          <b-form-select
            multiple
            v-model="clientEdit.membersId"
            :options="memberList"
            name="members"
          ></b-form-select>
        </b-form-group> -->

        <!-- <b-form-group label="Usuários" class="col-sm-12">
          <b-form-checkbox-group
            v-model="clientEdit.membersId"
            :options="memberList"
            stacked
          ></b-form-checkbox-group>
        </b-form-group> -->

        <b-form-group label="Usuários" class="col-sm-12" >
          <div class="overflow-auto form-control" style="height: 200px;">

            <b-form-checkbox-group v-model="clientEdit.membersId">

              <b-form-checkbox
              style="min-width: 16rem"
             
              v-for="option in memberList"
              :key="option.value"
              :value="option.value"
              >
              <font-awesome-icon :icon="userIcon(option.type)" />
                {{ option.text }} 
            </b-form-checkbox>
          </b-form-checkbox-group>
        </div>
        </b-form-group>
      </b-row>
    </validation-observer>

    <div>
      <hr />

      <div>Selecione sede e a área da fazenda no mapa</div>
      <!-- <b-button size="sm" @click="editLocationType = 'none'">none</b-button> -->
       <div class="mb-1">
         <b-button class="mr-1" size="sm" @click="editLocationType = 'area'">Área</b-button>
         <b-button size="sm" @click="editLocationType = 'point'">Sede</b-button>
        </div>
      <!-- <selectLocation v-model="clientEdit.location" :imgPoint="require('./assets/clientPoint.png')"/> -->
      <simple-map :autofit="!newClient">
        <template v-slot:layer>
          <selectArea
            v-if="editLocationType === 'area'"
            v-model="clientEdit.geometryArea"
          />
          <selectPosition
            v-if="editLocationType === 'point'"
            v-model="clientEdit.location"
            :imgPoint="require('./assets/clientPoint.png')"
          />
        </template>
        <clientMap :client="clientEdit" />
      </simple-map>
    </div>

    <div class="d-flex mt-5">
      <div class="ml-auto">
        <b-button variant="primary" @click="submit()">Salvar</b-button>
        <b-button variant="secondary" @click="$emit('editcancel')"
          >cancelar</b-button
        >
      </div>
    </div>


   

  
    
  </div>
</template>

<script>
// import selectLocation from "@components/generic/selectLocation"
import selectArea from "@components/map/selectArea";
import selectPosition from "@components/map/selectPosition";
import clientMap from "./clientMap";
import estados from "./assets/cidades-estados";

export default {
  data() {
    return {
      memberList: [],
      clientEdit: undefined,
      editLocationType: "point",
    };
  },
  props: {
    client: { type: Object, require: true },
  },

  computed: {
    consultantList() {
      return this.memberList.filter(e=> ['MASTER','OWNER','ADMIN','MANAGER'].includes(e.type));
    },

    estados(){
      return estados.estados.map(function(e){ return {text:e.nome, value:e.sigla}})
    },

    newClient(){
      return this.client.id == undefined
    }

  },

  mounted() {
    //carrega a lista de usuários
    this.$http.get("/account/member/maplist").then(
      (data) => {
        let list = [];
        data.forEach(function (v) {
          list.push({ value: v[0], text: v[1],type:v[2] });
        });

        this.memberList = list;
      },
      (error) => {
        console.erro(error);
      }
    );
  },
  beforeMount() {
    this.clientEdit = JSON.parse(JSON.stringify(this.client));
  },

  methods: {

    userIcon(type){
      if (['MASTER','OWNER','ADMIN','MANAGER'].includes(type) ) 
        return 'user-shield' 
      else
        return 'user'
      
    },

    cidades(est){
      if (typeof est === 'string' || est instanceof String)
        return estados.estados.filter(e=> e.sigla == est)[0].cidades
      else
        return []
    },

    submit() {
      this.$refs.formObserver.validate().then((result) => {
        if (!result) {
          return;
        }

        let clientData = JSON.parse(JSON.stringify(this.clientEdit));

        this.$http.save("account/client", clientData, clientData.id).then(
          (data) => {
            this.$emit("updateclient", data);
            this.$emit("editcancel");
          },
          (error) => {
            // this.$refs.formObserver.setErrors(this.$validServer(error));
            console.error(error);
            this.$bvModal.msgBoxOk(
              "Verifique se todos os dados estão corretos",
              {
                title: "Erro!",
              }
            );
          }
        );
      });
    },
  },

  components: {
    // selectLocation,
    selectPosition,
    clientMap,
    selectArea,
  },
};
</script>

<style>
#fileInput {
  display: none;
}

.img-preview {
  width: 100%;
  padding: 3px;
  border: 2px solid #d2d6de;
  margin: 0 auto;
  border-radius: 50%;
}
</style>
>
