<template>
  <div class="editable-text">
    <div class="d-flex text-mode" v-if="!editing">
      <markdown :source="value"></markdown>

      <i v-if="!value && textPlaceholder">{{textPlaceholder}}</i>

      <div class="ml-auto">
        <b-button title="Editar Texto" variant="primary" @click="editing = true" size="sm" pill>
          <font-awesome-icon icon="pencil-alt" />
        </b-button>
      </div>
    </div>
    <div class="edit-mode" v-else>
      <b-tabs small content-class="mt-1">
        <b-tab title="Escrever" active>
          <b-form-textarea class="text-editor" v-model="textEdit" :placeholder="placeholder" :rows="rows"
            :max-rows="maxRows" no-auto-shrink></b-form-textarea>
        </b-tab>
        <b-tab title="Pré-visualizar">
          <markdown class="preview-text" :source="textEdit"></markdown>
        </b-tab>
      </b-tabs>

      <div class="comment-toolbar">
        <div>
          Notações em
          <a href="https://www.markdownguide.org/getting-started/" target="_blank">Markdown</a>
          são suportadas
        </div>
 

        <div class="ml-auto">
          <b-button-group size="sm">
            <b-button size="sm" @click="save()" variant="success">Salvar
            </b-button>
            <b-button size="sm" @click="editing = false">Cancelar </b-button>
          </b-button-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import markdown from "./markdown";
export default {
  data() {
    return {
      editing: false,
      textEdit: "",
    };
  },
  props: {
    value: String,
    rows: { type: Number, default: 4 },
    maxRows: { type: Number, default: 12 },
    placeholder: { type: String, default: "Escreva algo ..." },
    textPlaceholder: { type: String },
  },
  watch: {
    value() {
      if (!this.editing) {
        this.textEdit = this.value;
      }
    },
  },
  methods: {
    save() {
      this.$emit("input", this.textEdit);
      this.$emit("text-saved");
      this.editing = false;
    },
  },
  components: {
    markdown,
  },
};
</script>

<style lang="scss" scoped>
.text-editor {
  font-family: "Menlo", "DejaVu Sans Mono", "Liberation Mono", "Consolas",
    "Ubuntu Mono", "Courier New", "andale mono", "lucida console", monospace;
  font-size: 0.8125rem;
  border: 0;
  padding: 10px 0;
  background-color: inherit;
}

.comment-toolbar {
  display: flex;
  margin-top: 0.25rem;
  padding-top: 10px;
  color: #666;
  border-top: 1px solid #dbdbdb;
}

.preview-text {
  min-height: 167px;
  padding: 10px 0;
  overflow-x: auto;
}
</style>
