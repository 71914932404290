<template>
    <div class="sensor-api shadow">

        <div class="d-flex">
            <b-form-checkbox v-model="active" @change="enable($event)" name="check-button" switch>
            </b-form-checkbox>
            <div class="name"> {{ api.name }} </div>
            <div></div> 
        </div>

        <div class="type"> {{ api.type }} </div>

        <!-- <h5> <font-awesome-icon icon="info-circle" /> Detalhes</h5> -->
        <div class="p-2">

            <b-row>

            <b-col lg=6>
                <table>
                    <tr> <th class="col-sm-4" >Origem </th> <td class="col-sm-6"><code>{{ api.apiSensorId }} </code></td></tr>
                    <tr> <th class="col-sm-4">Destino </th> <td class="col-sm-6"><code>{{ api.feedSerialNumber }}</code></td></tr>
                    <tr> <th class="col-sm-4">Fuso </th> <td class="col-sm-6">{{ api.timeZone }}</td></tr>
                    <tr> <th class="col-sm-4">Último dado </th> <td class="col-sm-6">{{ api.lastTimeSync }}</td></tr>
                    <tr> <th class="col-sm-4">Última tentativa </th><td class="col-sm-6">{{ api.lastTimeAttempt }}</td></tr>
                </table>
            </b-col>
            <b-col lg=6 >
                <table>
                    <tr> <th class="col-sm-4">Intervalo </th> <td class="col-sm-6">{{ api.interval }} min </td></tr>
                    <tr> <th class="col-sm-4">Erros </th> <td class="col-sm-6">{{ api.errors }}</td></tr>
                    <tr> <th class="col-sm-4">Acertos </th> <td class="col-sm-6">{{ api.successes }}</td></tr>
                </table>
            </b-col>
            

            </b-row>
            
            <b-alert :show="api.lastError!=null && api.lastError.length !== 0" variant="warning">{{ api.lastError }}</b-alert>
        </div>
        <hr>
         
        <div class="d-flex">
            <div class="ml-auto">
                <!-- <b-button v-if="api.active == true" size="sm" variant="danger" @click="enable(false)"> desativar </b-button>
                <b-button v-else size="sm" variant="success" @click="enable(true)"> ativar </b-button> -->
                <b-button size="sm" class="mr-1" @click="tryNow()" >Agora</b-button>
                <b-button size="sm" class="mr-1" @click="editDateTime" >Data e Hora</b-button>
                <b-button size="sm" class="mr-1" variant="primary" @click="$emit('click-edit', toEditable(api))"> Editar </b-button>
                <b-button size="sm" variant="danger" @click="$emit('click-delete')"> Apagar </b-button>
                
            </div>
        </div>
            

       

        <b-modal :ref="'modal-time-'+api.id" title="Configurar Data e Hora" >
            <b-form-input type="datetime-local" v-model="dateTime" ></b-form-input>
            <template v-slot:modal-footer>
                <b-button  @click="saveDateTime" variant="primary">
                    Salvar
                </b-button>
                <b-button @click="$refs['modal-time-'+api.id].hide()">Cancelar</b-button>
            </template>
        </b-modal>

    </div>
</template>
<script>


import { DateTime } from 'luxon';

export default {

    data() {
        return {
            active: true,
            dateTime:undefined
        }
    },
    mounted() {
        this.active = this.api.active
    },

    props: {
        api: { type: Object, required: true }
    },

    watch: {
        api(v) {
            this.active = v.active
        }
    },

    methods: {

        editDateTime(){
         
            let dateTimeWithZone = DateTime.fromISO(this.api.lastTimeSync, { zone: this.api.timeZone });
            this.dateTime = dateTimeWithZone.toFormat('yyyy-MM-dd HH:mm:ss');
            this.$refs['modal-time-'+this.api.id].show()
        },

        saveDateTime(){
            let dateTime = this.dateTime.replace(" ", "T");
            this.$http.put("/sensorapi/sensors/" + this.api.id + "/startDateTime",
                { "startDateTime": dateTime }
            ).then(
                () => {
                    let newAPI = JSON.parse(JSON.stringify(this.api))
                    this.$emit("update-object", newAPI)
                    this.$refs['modal-time-'+this.api.id].hide()
                }
            )
        },

        tryNow(){
            this.$http.get("/sensorapi/sensors/" + this.api.id + "/try-now" 
            ).then(
                () => {
                    let newAPI = JSON.parse(JSON.stringify(this.api))
                    this.$emit("update-object", newAPI)
                }
            )
        },

        toEditable(data) {
            return {
                id: data.id,
                name: data.name,
                apiKey1: data.apiKey1,
                apiKey2: data.apiKey2,
                apiSensorId: data.apiSensorId,
                description: data.description,
                feedSerialNumber: data.feedSerialNumber,
                humidityUnit: data.humidityUnit,
                interval: data.interval,
                // lastTimeSync: data.lastTimeSync,
                lightUnit: data.lightUnit,
                rainUnit: data.rainUnit,
                tempUnit: data.tempUnit,
                timeZone: data.timeZone,
                type: data.type,
                windUnit: data.windUnit,
            }
        },

        enable(status) {
            //console.log(status)
            this.active = status

            this.$http.put("/sensorapi/sensors/" + this.api.id + "/active",
                { "active": status }
            ).then(
                () => {
                    let newAPI = JSON.parse(JSON.stringify(this.api))
                    newAPI.active = status
                    this.$emit("update-object", newAPI)
                }
            )
        },

    }
}
</script>

<style lang="scss" scoped>
.sensor-api {
    width: 100%;
    padding: 5px;
    border: $card-border-width solid $card-border-color;
    margin: 0 auto;
    border-radius: $card-border-radius;
    margin-bottom: 2rem;
    position: relative;
    

    .name{
        font-size: 20px;
    }

    .type{
        position: absolute;
        right: 0;
        top: 0;
        color: white;
        background-color: rgb(47, 66, 94);
        padding: 1px 10px 1px 10px;
        border-radius: 0 $card-border-width 0  10px;
    }
}
</style>