import sensor from '../sensor.js'

export default {
  extends:sensor,
  computed:{
    unit(){
      return this.sensor.unit
    },
    hydrometers(){
      return this.sensor.hydrometers
    },
    totalDay(){
      return this.sensor.totalDay
    },
    totalMonth(){
      return this.sensor.totalMonth
    },
    maxMonth(){
      return this.sensor.maxMonth
    },
  },
  filters:{
  }
}
