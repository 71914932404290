<template>
  <div v-if="weather">
    <ValidationProvider
      name="date"
      :rules="{ required: true }"
      v-slot="v"
    >
      <b-form-group 
      label="Data" 
      label-cols-sm="4"
      label-cols-lg="3">
       <b-input-group size="sm" append="ºC">
        <b-input
          @update="weatherUpdate"
          v-model="weather.date"
          type="date"
          :state="$validateState(v)"
          :enabled="!editDate"
        />
        </b-input-group>
      </b-form-group>
    </ValidationProvider>


    <ValidationProvider
      name="temp_max"
      :rules="{ required: false, between: [weather.tempMin, 50] }"
      v-slot="v"
    >
      <b-form-group 
      label="Temp. Max" 
      label-cols-sm="4"
      label-cols-lg="3">
       <b-input-group size="sm" append="ºC">
        <b-input
          @update="weatherUpdate"
          v-model="weather.tempMax"
          type="number"
          min="0"
          max="50"
          step="any"
          :state="$validateState(v)"
        />
        </b-input-group>
      </b-form-group>
    </ValidationProvider>

    <ValidationProvider
      name="temp_min"
      :rules="{ required: false, between: [0, weather.tempMax] }"
      v-slot="v"
    >
      <b-form-group 
      label="Temp. Min"
      label-cols-sm="4"
      label-cols-lg="3">
        <b-input-group size="sm" append="ºC">
        <b-input
          @update="weatherUpdate"
          v-model="weather.tempMin"
          type="number"
          min="0"
          max="60"
          step="any"
          :state="$validateState(v)"
        />
        </b-input-group>
      </b-form-group>
    </ValidationProvider>

    <ValidationProvider
      :rules="{ required: false, between: [weather.tempMin, weather.tempMax] }"
      v-slot="v"
    >
      <b-form-group 
      label="Temp. Media"
      label-cols-sm="4"
      label-cols-lg="3">
       <b-input-group size="sm" append="ºC">
        <b-input
          @update="weatherUpdate"
          v-model="weather.tempAvg"
          type="number"
          min="0"
          max="60"
          step="any"
          :state="$validateState(v)"
        />
        </b-input-group>
      </b-form-group>
    </ValidationProvider>

    <ValidationProvider
      :rules="{ required: false, between: [0, 100] }"
      v-slot="v"
    >
      <b-form-group 
      label="Umidade"
      label-cols-sm="4"
      label-cols-lg="3">
      <b-input-group size="sm" append="%">
        <b-input
          @update="weatherUpdate"
          v-model="weather.humiRel"
          type="number"
          min="0"
          max="100"
          step="any"
          :state="$validateState(v)"
        />
        </b-input-group>
      </b-form-group>
    </ValidationProvider>

    <ValidationProvider
      :rules="{ required: false, between: [0, 20] }"
      v-slot="v"
    >
      <b-form-group 
      label="Vento"
      label-cols-sm="4"
      label-cols-lg="3">
      <b-input-group size="sm" append="m/s">
        <b-input
          @update="weatherUpdate"
          v-model="weather.velWind2m"
          type="number"
          min="0"
          step="any"
          :state="$validateState(v)"
        />
        </b-input-group>
      </b-form-group>
    </ValidationProvider>

    <ValidationProvider
      :rules="{ required: false, between: [0, 35] }"
      v-slot="v"
    >
      <b-form-group 
      label="Radiação"
      label-cols-sm="4"
      label-cols-lg="3">
      <b-input-group size="sm" append="MJ/dia">
        <b-input
          @update="weatherUpdate"
          v-model="weather.radi"
          type="number"
          min="0"
          step="any"
          :state="$validateState(v)"
        />
        </b-input-group>
      </b-form-group>
    </ValidationProvider>

    <ValidationProvider
      :rules="{ required: false, between: [0, 40] }"
      v-slot="v"
    >
      <b-form-group 
      label="Chuva"
      label-cols-sm="4"
      label-cols-lg="3">
      <b-input-group size="sm" append="mm">
        <b-input
          @update="weatherUpdate"
          v-model="weather.rain"
          type="number"
          min="0"
          step="any"
          :state="$validateState(v)"
        />
        </b-input-group>
      </b-form-group>
    </ValidationProvider>
  </div>
</template>

<script>
export default {
  data() {
    return {
      weather: undefined,
      editDate:false
    };
  },
  props: {
    value: {
      type: Object,
      required: false,
      default: function() {
        return {};
      },
    },
  },

  mounted() {
    this.weather = Object.assign({}, this.value);
    if (this.weather.date == undefined)
      this.editDate = true;
  },

  methods: {
    weatherUpdate() {
      this.$emit("input", JSON.parse(JSON.stringify(this.weather)));
      this.$emit("onchange");
    },
  },
};
</script>

<style></style>
