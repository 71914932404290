<template>
  <div>
    <div class="d-flex">
      <div>
        <h5>{{ title }}</h5>
      </div>
    </div>
    <div>
      <!-- <div>
        <b-button  size="sm" variant="success" @click="newWeather()"> novo </b-button>
      </div> -->
      <b-table
        class="text-center"
        responsive
        small
        :items="weathers"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
        sortBy="date"
        :sort-desc="true"
        sort-direction="desc"
      >
        <template v-slot:cell(action)="row">
          <b-button-group size="sm" v-if="editable">
            <b-button
              v-b-tooltip.hover
              title="Editar"
              variant="primary"
              @click="showWeatherEdit(row.item)"
              ><font-awesome-icon icon="edit"
            /></b-button>
            <b-button
              v-b-tooltip.hover
              title="Remover"
              variant="danger"
              @click="$emit('remove-weather', row.item.id)"
            >
              <font-awesome-icon icon="trash"
            /></b-button>
          </b-button-group>
        </template>
      </b-table>

      <b-modal
        v-if="editable"
        ref="modal-edit-weather"
        title="Editar Climas"
        @ok="submitweather"
      >
        <validation-observer ref="formObserver">
          <div v-if="saveStatus == 'normal' || saveStatus == 'error'">
            <weatherEdit v-model="weatherEditData" />
          </div>
          <font-awesome-icon v-else icon="spinner" spin />
        </validation-observer>
      </b-modal>

      <div class="d-flex">
        <b-pagination
          v-model="currentPage"
          :total-rows="numRows"
          :per-page="perPage"
          aria-controls="my-table"
        ></b-pagination>

        <div class="ml-auto">
          <b-button
            :disabled="loadingXLS"
            title="baixar dados .xlsx"
            size="sm"
            variant="success"
            @click="download()"
            ><font-awesome-icon icon="file-excel" /> baixar
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DateTime } from "luxon";
import weatherEdit from "./weatherEdit";

import zipcelx from "zipcelx";

var dateFormat = function(value) {
  return DateTime.fromISO(value).toLocaleString();
};

var roundFormatter = function(value) {
  if (typeof value !== "number") return value;
  return value.toFixed(2);
};

// var elevationFormater = function(value,col,row){
//     return row.location.ele;
// }

export default {
  data() {
    return {
      saveStatus: "normal",
      perPage: 10,
      currentPage: 1,
      weatherEditData: undefined,
       loadingXLS:false,
      fields: [
        {
          key: "date",
          label: "Data",
          sortable: true,
          class: "text-nowrap",
          formatter: dateFormat,
        },
        {
          key: "tempMax",
          label: "T max (ºC)",
          sortable: true,
          formatter: roundFormatter,
        },
        {
          key: "tempMin",
          label: "T min (ºC)",
          sortable: true,
          formatter: roundFormatter,
        },
        {
          key: "tempAvg",
          label: "T media (ºC)",
          sortable: true,
          formatter: roundFormatter,
        },
        {
          key: "humiRel",
          label: "umidade (%)",
          sortable: true,
          formatter: roundFormatter,
        },
        {
          key: "velWind2m",
          label: "Vento (m/s)",
          sortable: true,
          formatter: roundFormatter,
        },
        {
          key: "radi",
          label: "radiação (MJ/dia)",
          sortable: true,
          formatter: roundFormatter,
        },
        {
          key: "rain",
          label: "Chuva (mm)",
          sortable: true,
          formatter: roundFormatter,
        },
        {
          key: "et0",
          label: "Et0 (mm)",
          sortable: false,
          formatter: roundFormatter,
        },
        { key: "action", label: "", sortable: false },
      ],
    };
  },
  props: {
    title: { type: String, default: "" },
    weathers: Array,
    stationId: { type: String, required: false },
    editable: { type: Boolean, default: false },
  },
  methods: {
    showWeatherEdit(weather) {
      this.weatherEditData = weather;
      this.saveStatus = "normal";
      this.$refs["modal-edit-weather"].show();
    },

    newWeather(){
      this.weatherEditData = 
        {
        "date":undefined,
        "tempMax":0,
        "tempMin":0,
        "tempAvg":0,
        "humiRel":0,
        "velWind2m":0,
        "radi":0,
        "rain":0,
        }
        this.saveStatus = "normal";
        this.$refs["modal-edit-weather"].show();
    },


    submitweather(bvModalEvt) {

  
      bvModalEvt.preventDefault();

      this.$refs.formObserver.validate().then((result) => {
        if (!result) {
          this.$bvModal.msgBoxOk("Verifique se todos os dados estão corretos", {
            title: "Erro!",
          });

          return;
        }

        this.saveStatus = "saving";
        this.$http
          .post(
            "irrig/station/" + this.stationId + "/weather",
            this.weatherEditData
          )
          .then(
            (data) => {
              this.saveStatus = "saved";
              this.$emit("update-weather", data);
              this.$refs["modal-edit-weather"].hide();
            },
            (error) => {
              console.error("save weather error", error);
              this.$bvModal.msgBoxOk("Não foi possível salvar os dados", {
                title: "Erro!",
              });
              this.saveStatus = "error";
            }
          );
      });
    },

    download() {
      this.loadingXLS = true;
      var data = [];
      data.push([
        { value: "Data", type: "string" },
        { value: "temperatura máxima (ºC)", type: "string" },
        { value: "temperatura mínima (ºC)", type: "string" },
        { value: "temperatura média (ºC)", type: "string" },
        { value: "umidade (%)", type: "string" },
        { value: "vento (m/s)", type: "string" },
        { value: "radiação (MJ/dia)", type: "string" },
        { value: "chuva (mm)", type: "string" },
        { value: "et0 (mm)", type: "string" },

      ]);

      this.weathers.forEach((element) => {
        var row = [];
        row.push({ value: dateFormat(element.date),  type: "date", });
        row.push({ value: element.tempMax, type: "number" });
        row.push({ value: element.tempMin, type: "number" });
        row.push({ value: element.tempAvg, type: "number" });
        row.push({ value: element.humiRel, type: "number" });
        row.push({ value: element.velWind2m,    type: "number" });
        row.push({ value: element.radi,    type: "number" });
        row.push({ value: element.rain,    type: "number" });
        row.push({ value: element.et0,     type: "number" });
        data.push(row);
      });

      const config = {
        filename: "historico_estação",
        sheet: {
          data: data,
        },
      };

      zipcelx(config);
      this.loadingXLS = false;
    },
  },
  computed: {
    numRows() {
      if (this.weathers !== undefined) return this.weathers.length;
      else return 0;
    },
  },
  components: {
    weatherEdit,
  },
};
</script>
