<template lang="html">

  <div class="pluvio-info">
    <div class="widget-box-header">
      <span class="name">{{sensor.name}}</span>
      <span class="time">
        <font-awesome-icon icon="clock"/> {{$filters.secondsToStr(elapsedTime)}}
      </span>
    </div>

    <div class="widget-box-value">
      <span class="label"> precipitação hoje</span>
      <span class="value">
        {{$filters.round(precipitation,2)}}<small> mm </small>
      </span>

      <div class="chart">
        <pluvioChart :data="hourly"/>
      </div>
    </div>

  </div>

</template>

<script>
import sensPluvio from './sensPluvio.js'
import pluvioChart from './pluvioMiniChart'
export default {
    extends:sensPluvio,
    components:{
      pluvioChart
    }
}
</script>

<style lang="scss" scoped>

.pluvio-info{
  min-width: 200px;

  .chart{
    margin-top: 15px;
    width: 100%;
    height: 50px;
  }
}
</style>
