<template>
  <div>
    <report-header
      v-if="printMode"
      :dateInterval="value.dateInterval"
      :label="$filters.longMonth(value.referenceMonth)"
      :title="title"
    />
      <!-- :author="value.author" -->

    <div class="d-flex justify-content-between flex-wrap">
      <div class="report-kpi">
        <label> Custo Parcelas</label>
        <span class="text-danger">
          {{ $filters.currency(value.totalCost ) }}
        </span>
      </div>

      <div class="report-kpi">
        <label> Área Gerenciada</label>
        <span>
          {{ $filters.localeNumber(value.totalAccumulated.areaHa, 2) }}
          <small> ha </small>
        </span>
      </div>

      <div class="report-kpi">
        <label> Consumo de água</label>
        <span class="value-small">
          {{ $filters.localeNumber(value.totalMonth.irrigation, 2) }}
          <small> mm </small>
        </span>
        <span class="value-small">
          {{ $filters.localeNumber(value.totalMonth.waterM3, 2) }} <small> m³ </small>
        </span>
      </div>

      <div class="report-kpi">
        <label> Energia Reativa</label>
        <span>
            {{ $filters.currency(value.reactiveEnergyCost) }}
        </span>
      </div>

      <div class="report-kpi">
        <label> Horário Reservado</label>
        <span>
            {{ $filters.localeNumber((value.reservedPowerRatio * 100),1) }} %
        </span>
      </div>

      <div class="d-flex report-kpi ml-auto">
        <div>
          <label> R$/ha</label>
          <span>
            {{ $filters.localeNumber(value.costPerHa, 2) }}
          </span>
        </div>
        <div class="mx-4">
          <label> R$/mm/ha</label>
          <span>
            {{ $filters.localeNumber(value.costPerMmPerHa, 2) }}
          </span>
        </div>
        <div>
          <label> R$/kWh</label>
          <span>
            {{ $filters.localeNumber(value.costPerKWh, 2) }}
          </span>
        </div>
      </div>
    </div>

    <hr />

    <b-row>
      <b-col md="7">


        <div v-if="value.bill" >

          <div class="d-flex">
            <h6>Composição do consumo</h6>
            
            <div class="ml-auto report-kpi" :set="flag=getFlag(value.bill.seasonalFlag)">
              <div class="title">
                <label>Valor Contas</label>
                <span>
                  {{ $filters.currency(value.bill.totalCost ) }}
                </span>
              </div>
            </div>
            
            <div class="ml-auto report-kpi" :set="flag=getFlag(value.bill.seasonalFlag)">
              <div class="title">Bandeira Tarifária:</div>
              <div class="px-2 text-center mb-2 ml-2" :style="{backgroundColor:flag.color, color:'white'}"> 
                <font-awesome-icon icon="flag"></font-awesome-icon>
                {{flag.text}} 
              </div>
            </div>
          </div>
          
          
          <billComposition
          v-if="value.bill"
          :billComposition="value.bill.composition"
          :showTarifa="false"
          ></billComposition>
        </div>
        
        <div class="no-report" v-else>
          <span>Sem contas de energia</span>
        </div>


      <hr class="mt-4" />
    <div class="no-break-block">
      <h5>Consumo de energia - Considerações</h5>
      <div class="report-comments">
        <editable-text
          v-if="editable && !printMode"
          v-model="energyComments"
        ></editable-text>
        <markdown v-else :source="value.energyComments"></markdown>
      </div>
    </div>

      </b-col>
      <b-col md="5">
        <bills-history-charts
          :width="printMode ? '380px' : '100%'"
          :numMonth="8"
          :month="value.referenceMonth"
          :history="value.history"
        />
      </b-col>
    </b-row>

    

    <hr class="mt-4" />
    <h5>
      Gestão de Irrigação
      <small> (considera o período de leitura das contas) </small>
    </h5>
    <reportTable :value="value" />

    <hr class="mt-4" />
    <div class="no-break-block">
      <h5>Manejo de irrigação - Considerações</h5>
      <div class="report-comments">
        <editable-text
          v-if="editable && !printMode"
          v-model="irrigationComments"
        ></editable-text>
        <markdown v-else :source="value.irrigationComments"></markdown>
      </div>
    </div>

    <hr class="mt-4" />
    <h5>Histórico</h5>
    <irrigationListTable :reports="value.history" />

    <hr class="mt-4" />
    <b-row class="no-break-block">
      <b-col md="4">
        <monthChart
          title="Custo (R$/mm/ha)"
          :series="[irrigationCostSerie]"
          unit="R$ "
          unit-left
          height="260px"
          :width="printMode ? '300px' : '100%'"
          :lastMonth="value.referenceMonth"
          :numMonth="13"
        />
      </b-col>
      <b-col md="4">
        <monthChart
          title="Irrigação (mm)"
          :series="[irrigationSerie]"
          unit=" mm"
          height="260px"
          :width="printMode ? '300px' : '100%'"
          :lastMonth="value.referenceMonth"
          :numMonth="13"
        />
      </b-col>
      <b-col md="4">
        <monthChart
          title="Chuva (mm)"
          :series="[rainSerie]"
          unit=" mm"
          height="260px"
          :width="printMode ? '300px' : '100%'"
          :lastMonth="value.referenceMonth"
          :numMonth="13"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import billComposition from "../../electricity/billComposition.vue";
import billsHistoryCharts from "../../electricity/billsHistoryCharts.vue";
import reportTable from "./irrigationReportTable";
import reportHeader from "../../comum/reportHeader.vue";
import irrigationListTable from "./irrigationListTable";
import monthChart from "@kl-framework/charts/monthChart";
import { flagColor } from "../../electricity/flagColorMixin";


export default {
  mixins:[flagColor],
  data() {
    return {
      title: "Relatório de Gerenciamento de Irrigação",
      energyComments: undefined,
      irrigationComments: undefined,
      editableReport: undefined,
    };
  },

  props: {
    value: { type: Object, requerid: true },
    printMode: { type: Boolean, default: false },
    editable: { type: Boolean, default: false },
  },

  components: {
    billComposition,
    reportTable,
    reportHeader,
    irrigationListTable,
    monthChart,
    billsHistoryCharts,
  },

 

  computed: {
    irrigationCostSerie() {
      return {
        type: "bar",
        color: "#08f",
        values: this.value.history
          ? this.value.history.map((e) => {
              return { month: e.referenceMonth, value: e.costPerMmPerHa };
            })
          : [],
      };
    },

    irrigationSerie() {
      return {
        type: "bar",
        color: "#08f",
        values: this.value.history
          ? this.value.history.map((e) => {
              return { month: e.referenceMonth, value: e.irrigation };
            })
          : [],
      };
    },
    rainSerie() {
      return {
        type: "bar",
        color: "#0a4",
        values: this.value.history
          ? this.value.history.map((e) => {
              return { month: e.referenceMonth, value: e.rain };
            })
          : [],
      };
    },
  },
  mounted() {
    this.editableReport = JSON.parse(JSON.stringify(this.value));
    this.energyComments = this.editableReport.energyComments;
    this.irrigationComments = this.editableReport.irrigationComments;
  },

  watch: {
    energyComments(v, old) {
      if (old != undefined) {
        this.editableReport.energyComments = v;
        this.$emit("input", this.editableReport);
        this.$emit("report-saved", this.editableReport);
      }
    },
    irrigationComments(v, old) {
      if (old != undefined) {
        this.editableReport.irrigationComments = v;
        this.$emit("input", this.editableReport);
        this.$emit("report-saved", this.editableReport);
      }
    },
  },
};
</script>

<style lang="scss" scoped>

.no-report{
  width: 100%;
  height: 400px;
  border: 1px dotted gray;
  text-align: center;
  padding: 50px 10px;
  background-color: #fcf8ee;
}


.report-comments {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  min-height: 1rem;
  background-color: white;
  padding: 5px;
  min-height: 5rem;
}

// .markdown-body {
//     line-height: 1.2 !important;
//     font-size: 12px !important;
//     color:green;
//   }

.report-kpi {
  border: 1px solid rgba(0, 0, 0, 0.1);
  display: inline-block;
  // height: 100%;
  text-align: center;
  padding: 0.0rem 0.5rem;
  margin: 0.20rem;
  border-radius: $border-radius;
  span {
    font-weight: 400;
    line-height: 30px;
    font-size: 18px;
    display: block; 
  }
  
  .value-small {
    font-size: 15px;
    line-height: 13px;
  }
  label {
    font-size: 12px;
    font-weight: 600;
    display: block;
    padding: 0;
    margin: 0;
  }
}
</style>
