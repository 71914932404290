<template>
  <div class="station-info">
    <div class="">


      <div>
        <div class="widget-box-value">
          <span class="label"><font-awesome-icon icon="temperature-half"/> Temperatura</span>
          <span class="value"
            >{{ $filters.round(temperature,1) }} <small>°C</small>
          </span>
        </div>

        <div class="widget-box-value">
          <span class="label"><font-awesome-icon icon="cloud-rain"/> Chuva</span>
          <span class="value"
            >{{ $filters.round(rain,1) }} <small>mm</small>
          </span>
        </div>

        <div class="widget-box-value">
          <span class="label"><font-awesome-icon icon="droplet"/> Umidade</span>
          <span class="value"
            >{{ $filters.round(humidity,1) }} <small>%</small>
          </span>
        </div>

        <div class="widget-box-value">
          <span class="label"><font-awesome-icon icon="wind"/> Vento</span>
          <span class="value"
            >{{ $filters.round(windKm,1) }} <small>km/h</small>
          </span>
        </div>
        
      </div>


      <!-- <div class="weather-data" title="temperatura" >
        <div class="max-min-temp">
          <div class="max-temp">{{ temperature_max }} <small>°C</small></div>
          <div class="min-temp">{{ temperature_min }} <small>°C</small></div>
        </div>
        <div class="img-station temperature"></div>
        <div>{{ temperature }} <small>°C</small></div>
      </div>

      <div class="weather-data" title="chuva">
        <div class="img-station rain"></div>
        <div>{{ rain }} <small>mm</small></div>
      </div>
      <div class="weather-data" title="umidade">
        <div class="img-station humidity"></div>
        <div>{{ humidity }} <small>%</small></div>
      </div>
      <div class="weather-data" title="vento">
        <div class="img-station wind"></div>
        <div>{{ windKm }} <small>km/h</small></div>
      </div> -->
      <!-- <div class="weather-data" title="ponto de orvalho">
        <div class="img-station dewPoint"></div>
        <div>{{ dewPoint }} <small>°C</small></div>
      </div> -->
    </div>
  </div>
</template>

<script>
import station from "./station.js";

export default {
  extends: station,
};
</script>

<style lang="scss" scoped>
.station-info {
  display: block;
  padding: 0;
}

// .weather-data {
//   font-size: 0.8rem;
//   text-align: center !important;
//   // margin-left: 1.5rem !important;
//   position: relative;
//   // width: 50px;

//   .max-min-temp {
//     font-size: 10px;
//     position: absolute;
//     right: -1rem;
//     line-height: 11px;
//     .max-temp {
//       color: red;
//     }

//     .min-temp {
//       color: blue;
//     }
//   }
// }

// .img-station {
//   width: 25px;
//   height: 25px;
//   background-size: 25px 25px;

//   &.temperature {
//     background-image: url("./assets/temperature.png");
//   }
//   &.wind {
//     background-image: url("./assets/wind.png");
//   }

//   &.rain {
//     background-image: url("./assets/rain.png");
//   }

//   &.humidity {
//     background-image: url("./assets/humidity.png");
//   }

//   &.dewPoint {
//     background-image: url("./assets/dewPoint.png");
//   }
// }
</style>
