<template>
  <b-modal ref="edit-modal" scrollable @hidden="close" size="lg">
    <template v-slot:modal-header>
      <span>
        Editar
        <b>{{form.name}}</b>
      </span>
      <popover-select :options="selectList" v-on:select-item="importData($event)" />
    </template>

    <validation-observer ref="formObserver">
      <cultureEdit v-model="form" />
    </validation-observer>

    <template v-slot:modal-footer>
      <b-button @click="submit" variant="primary">Salvar</b-button>
      <b-button @click="cancel">Cancelar</b-button>
    </template>
  </b-modal>
  <!-- <b-button type="submit">Salvar</b-button>
  <b-button type="reset">Cancelar</b-button>-->
</template>

<script>
import cultureEdit from "./cultureEdit";

export default {
  data() {
    return {
      form: { name: "", description: "", stages: [], icon:"default" }
    };
  },

  props: {
    cultureId: { type: String, required: false },
    cultureList: Array
  },

  mounted() {
    if (this.cultureId !== undefined) {
      let form = this.cultureList.find(ele => this.cultureId === ele.id);
      if (form != undefined) this.form = JSON.parse(JSON.stringify(form));
    }

    this.$refs["edit-modal"].show();
  },

  components: {
    cultureEdit
  },

  computed: {
    selectList() {
      let list = [];
      this.cultureList.forEach(function(v) {
        list.push({ value: v, text: v.name });
      });
      return list;
    }
  },

  methods: {
    close() {
      this.$router.push({ name: "cultureList" });
    },

    importData(v) {
      this.form.stages = JSON.parse(JSON.stringify(v.stages));
      this.form.icon = JSON.parse(JSON.stringify(v.icon));
    },

    submit() {
      this.$refs.formObserver.validate().then(result => {
        if (!result) {
          return;
        }

        let cultureData = JSON.parse(JSON.stringify(this.form));
        this.$http.post("irrig/culture/", cultureData).then(
          data => {
            this.$emit("update:culture", data);
            this.$refs["edit-modal"].hide();
          },
          error => {
            this.$refs.formObserver.setErrors(this.$validServer(error))
            this.$bvModal.msgBoxOk(
              "Verifique se todos os dados estão corretos",
              {
                title: "Erro!"
              }
            );
          }
        );
      });
    },
    cancel() {
      this.$refs["edit-modal"].hide();
    }
    // onSubmit(evt) {
    //   evt.preventDefault();
    //   console.log("submit")
    //   alert(JSON.stringify(this.form));
    // },
    // onReset(evt) {
    //   evt.preventDefault();
    // }
  }
};
</script>
