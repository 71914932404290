
//import {calcFieldLayers,calcFieldWater,calcFieldColor} from './soilWater.js'
import { pivotGeometry } from "@components/mixins/PivotGeometry.js";
import fieldColor from "./fieldColors";

  

export default {

  mixins:[pivotGeometry,fieldColor],

  props:{
    field:{ type: Object, required: true},
  },

  computed:{

    /**
     * Retorna o código da cor baseado no status atual do feed
     */
    colorCode(){
      if(this.field.status != null)
        return this.field.status.color;
      else
        return undefined;  
    },

    /**
     * Retorna o status da parcela para o dia atual
     */
    dataOk(){
      //debugger
      if(this.field.status != undefined && this.field.status.dataOK==true){
          return true;
      }else{
          return false;
      }
    },


    /**
     * Retorna o nome do equipamento e o nome da parcela
     */
    equipmentName:function(){
      return this.equipment.name;
    },
 
    layers:function(){
      // let sensors = this.soilSensorsOnline;
      // return calcFieldLayers(this.field.currentCycle.soil,sensors);
    },

    water : function(){
      //console.log("update water");
      // let rootDepth = this.field.cultureParameters.rootDepth
      // let safetyFactor = this.field.cultureParameters.safetyFactor
      // return calcFieldWater(this.layers,rootDepth,safetyFactor)
    },

   


    /**
     * Retorna o equipamento que a parcela está contida
     */
    equipment:function(){
      return this.$store.getters['equipments/getById'](this.field.equipmentId);
    },


    geometry:function(){
      //Retorna a geometia das parcelas de pivôs
      if(this.field.type === 'FieldPivot'){
        let zeroPos = Number(this.equipment.offsetPos);
        let start   = Number(this.field.start);
        let end     = start + Number(this.field.size);
        let radius  = Number(this.field.externalRadius);
        let center  = [this.equipment.location.lon, this.equipment.location.lat];
        return [this.getGeometry(start, end, radius, center, zeroPos).area];

      // retorna a geometria da parcela de área
      }else if(this.field.geometryArea!== undefined){
        return this.field.geometryArea;
      }else{
        return null;
      }
    },

    sensors:function(){
      // return this.$store.getters['sensors/getListSensByIds'](this.field.currentCycle.sensorsId);
    },

    soilSensors:function(){
      // return this.sensors.filter(sensor=> sensor.sensorType === 'SensorSoil')
    },

    soilSensorsOnline:function(){
      // let serverTime = this.$store.state.sensors.serverTime;
      // let sensors = this.soilSensors.filter(sensor=> (((serverTime - sensor.lastTimeSync)/1000) < sensor.offlineTime && sensor.unit==='%'));
      // return sensors;
    },

    hasSensorsOnline(){
      // return this.soilSensorsOnline.length > 0;
    },
  },

}
