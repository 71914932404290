<template>
    <table-export
      class="mt-3"
      small
      :items="dailySeries"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
    >
    </table-export>
</template>

<script>
import { DateTime } from "luxon";


const dateFormat = function(value) {
  return DateTime.fromISO(value).toLocaleString();
};

var roundFormatter = function(value) {
  if (typeof value !== 'number') return value;
  return value.toLocaleString('pt-br',{minimumFractionDigits:2,maximumFractionDigits:2})
};

export default {
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      loadingXLS:false,
      fields: [
        {
          key: "date",
          label: "Data",
          sortable: true,
          class: "text-nowrap",
          formatter: dateFormat,
        },
        { key: "volume", label: "consumo ("+this.unit+")",  sortable: true,formatter: roundFormatter},
        // { key: "volume2", label: "Volume2 ("+this.unit+")",  sortable: true,formatter: roundFormatter},
        { key: "goal",  label: "meta ("+this.unit+")", sortable: false, formatter: roundFormatter},
      ],
    };
  },
  props: {
    dailySeries: Array,
    unit:String
  }
};
</script>

