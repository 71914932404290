<template>
    <b-table-simple class="table-irrig table-responsive-sm shadow" bordered small>
        <b-thead class="text-center">
            <b-th style="width: 200px">Equipamento - Parcela</b-th>
            <b-th style="width: 100px" v-for="day in days" :key="day">
                <div>{{ $filters.weekFormat(day) }}</div>
                <div class="day-format">{{ $filters.dayFormat(day) }}</div>
            </b-th>
        </b-thead>


        <!-- <equipment v-for="eqp in equipments" :key="eqp.id" :equipment="eqp" /> -->

        <b-tbody>

            <b-tr v-for="(element, index) in irrigSchedule" :key="index">
                <b-td class="td-equipment">
                    <div>
                        <b-link :to="{ name: 'equipmentDetails', params: { id: element.equipmentId } }">{{
                            element.equipmentName
                        }}</b-link> -
                        <b-link :to="{ name: 'fieldDetails', params: { id: element.fieldId } }">{{
                            element.fieldName
                        }}</b-link>
                    </div>
                    <div>
                        <img v-b-tooltip.hover :title="element.cultureName" class="culture-img"
                            :src="require('@irrigcomponents/culture/assets/48/' + element.cultureIcon + '.png')" />
                        {{ element.cultureName }}
                    </div>
                </b-td>


                <b-td v-for="(value, key) in element.days" :key="key">
                    <div v-if="value">
                        <div class="irrig-info">

                            <div class="irrig-info-equipment">
                                <div class="water">{{ $filters.round(value.water, 1)
                                }}<small>mm</small>
                                </div>
                                <div class="percent" v-if="value.percent != undefined">{{
                                    $filters.round(value.percent,
                                        0) }}<small>%</small></div>
                            </div>

                            <div class="time" v-if="value">
                                <div>
                                    {{
                                        $filters.localeTime(value.startDateTime) }} ~ {{
        $filters.localeTime(value.endDateTime) }}
                                </div>
                                <div>
                                    {{ $filters.decimalhourToTime(value.hour) }}<small>h</small>
                                </div>
                            </div>
                        </div>

                        <div class="comment"> {{ value.comment }}</div>


                    </div>
                </b-td>
            </b-tr>
        </b-tbody>
    </b-table-simple>
</template>
<script>
export default {

    props: {
        irrigSchedule: { type: Array, required: true }
    },
    methods: {
        startDate() {
            return this.days[0]
        },
        dateInterval() {
            let startday = this.days[0];
            let endDay = this.days[this.days.length - 1];
            return { start: startday, end: endDay }
        }

    },
    computed: {
        days() {
            let days = undefined
            this.irrigSchedule.forEach(schedule => {
                if (schedule !== undefined) {
                    days = Object.keys(schedule.days)
                    return
                }

                if (days != undefined) {
                    return
                }
            });
            return days
        }
    }

}
</script>
<style lang="scss" scoped>
.table-irrig {
    font-size: 14px;

    //  td,th{
    //     border: 1px solid black;
    // }

    .day-format {
        font-size: 24px;

    }

    .irrig-info {
        margin: 1px;
        background-color: #eee;
        padding: 5px;
        border-radius: 10px;

        .irrig-info-equipment {
            display: flex;
            font-weight: bold;
            font-size: 16px;

            .water {}

            .percent {
                margin-left: auto;
            }

        }

        .time {
            text-align: center;
        }
    }

    .comment {
        font-style: italic;
        word-wrap: break-word;
        text-align: center;
    }

    .culture-img {
        height: 25px;
        background-color: rgba(255, 255, 255, 0.5);
        border-radius: 30%;
    }


    small {
        font-size: 12px;
    }

    .td-equipment {
        // background-color: rgb(223, 236, 238);
        vertical-align: middle !important;
        font-weight: 600;
    }


    td,
    th {
        text-align: center;
        border: 1px solid #9b9b9b;
    }
}
</style>