<template>
  <list-page
    :loadStatus="loadStatus"
    :numRows="numRows"
    title="Funcionamento Sensores"
    subTitle="Período de funcionamento dos sensors"
    fluid
    no-body
  >
    <template v-slot:head-tools>
      <data-range v-model="dateRange" />
    </template>

    <template v-slot:list="props">
      <b-table
        small
        responsive
        :items="data"
        :fields="fields"
        :filter="props.filter"
        :current-page="props.currentPage"
        :per-page="props.perPage"
        @filtered="onFiltered"
      >
        <template v-slot:cell(intervals)="row">
          <sensorUptimeChart
            :startTime="row.item.startTime"
            :endTime="row.item.endTime"
            :value="row.item.intervals"
          />
        </template>

        <template v-slot:cell(onlinePercents)="row">
          <b-progress :max="1">
            <b-progress-bar 
              :value="row.item.onlinePercents"
              :label="`${((row.item.onlinePercents) * 100).toFixed(0)}%`"
            ></b-progress-bar>
          </b-progress>
        </template>
      </b-table>
    </template>
  </list-page>
</template>

<script>
import { DateTime, Interval } from "luxon";
import _ from "lodash";
// import testData from "./testeData/data.json"

import sensorUptimeChart from "./sensorUptimeChart.vue";

export default {
  data() {
    return {
      data: [],
      loadStatus: "undefined",
      dateRange: null,
      selectDateRange: undefined,
      numRows: 1,

      fields: [
        { key: "sensorName", label: "Nome", sortable: true },
        {
          key: "intervals",
          label: "Intervalos",
          sortable: false,
          thStyle: "width:50%",
          thClass:"text-center"
        },
        {
          key: "size",
          label: "",
          sortable: false,
          class:"text-center",
          formatter:function(i,g,r){return r.intervals.length}
        },
        {
          key: "hours",
          label: "Horas",
          sortable: true,
          formatter: this.convertDate,
          class:"text-center"
        },
        {
          key: "onlinePercents",
          label: "upTime",
          sortable: true,
          thClass:"text-center"
        },
      ],
    };
  },

  watch: {
    dateRange() {
      this.loadData();
    },

    // o método debounce evita que esta função seja chamada repetidas vezes em
    // um intervalo de tempo muito pequeno
    selectDateRange: _.debounce(function (newVal) {
      if (newVal != undefined) {
        let interval = Interval.fromISO(newVal.start + "/" + newVal.end);
        this.processMapData(interval);
      } else {
        this.processMapData();
      }
    }, 1000),
  },

  mounted() {
    let end = DateTime.local().endOf("day").toJSDate();
    let start = DateTime.local().plus({ days: -30 }).toJSDate();
    this.dateRange = [start, end];
  },

  components: {
    sensorUptimeChart,
  },

  methods: {
    convertDate(v) {
      return this.$filters.decimalhourToTime(v, "h ", "m");
    },

    loadData() {
      // this.loadStatus = "loaded";
      // this.data = testData;

      if (!this.dateRange[0] || !this.dateRange[1]) return;

      this.rendering = true;
      this.processingData = true;
      this.selectDateRange = undefined;

      this.loadStatus = "loading";
      let start = DateTime.fromJSDate(this.dateRange[0]).toISO({
        includeOffset: false,
      });
      let end = DateTime.fromJSDate(this.dateRange[1])
        .plus({ days: 1 })
        .toISO({ includeOffset: false });

      this.loadStatus = "loading";
      this.$http.get(`sens/report/uptime?start=${start}&end=${end}`).then(
        (data) => {
          this.data = data;
          this.loadStatus = "loaded";
          this.numRows = this.data.length;
        },
        (error) => {
          console.error("culture error:", error);
          this.loadStatus = "error";
        }
      );
    },
    onFiltered(filteredItems) {
      this.numRows = filteredItems.length;
    },
  },
};
</script>

<style></style>
