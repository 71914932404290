<template lang="html">
  <svg :width="width" :height="height" viewBox="0 0 150 150">
    <g transform="translate(0,97.083333)" style="display:inline" id="layer2">
      <path
        id="terra"
        d="M 136.92068,-72.118924 V 31.725422 c 0,11.511834 -9.26765,20.779503 -20.77949,20.779503 H 36.159457 c -11.511831,0 -20.779479,-9.267669 -20.779479,-20.779503 V -72.118924 Z"
        style="opacity:0.98999999;fill:#d38d5f;stroke:#d38d5f;stroke-width:0.33134487;stroke-linecap:round;paint-order:markers stroke fill"
      />
      <path
        style="opacity:0.98999999;fill:#deaa87;stroke:none;stroke-width:0.77708453;stroke-linecap:round;stroke-miterlimit:4;stroke-dasharray:none;paint-order:markers stroke fill"
        d="m 51.086983,30.599937 c -3e-6,2.159108 -1.67706,3.909427 -3.745813,3.909427 -2.068755,0 -3.74581,-1.750319 -3.745812,-3.909427 2e-6,-2.159137 2.033617,-2.793054 3.389255,-1.995628 1.80223,1.060122 4.102367,-0.163481 4.10237,1.995628 z"
        id="path4640"
      />
      <!-- <g id="pedras">
        <path
          style="opacity:0.98999999;fill:#deaa87;stroke:none;stroke-width:0.77708453;stroke-linecap:round;stroke-miterlimit:4;stroke-dasharray:none;paint-order:markers stroke fill"
          d="m 129.10279,7.2697785 c 0,2.159109 -1.67706,3.9093975 -3.74581,3.9093975 -2.06875,0 -3.74581,-1.7502885 -3.74581,-3.9093975 0,-2.159166 2.03361,-2.793053 3.38926,-1.995656 1.80222,1.060121 4.10236,-0.163482 4.10236,1.995656 z"
          id="path4652"
        />
        <path
          id="path4650"
          d="m 114.73609,12.481486 c 0,3.040323 -2.46466,5.504972 -5.50497,5.504972 -3.04031,0 -5.50496,-2.464649 -5.50497,-5.504972 10e-6,-3.0403225 2.98867,-3.9329555 4.98097,-2.8100642 2.64861,1.4927552 6.02897,-0.2303743 6.02897,2.8100642 z"
          style="opacity:0.98999999;fill:#c87137;stroke:none;stroke-width:1.11787486;stroke-linecap:round;stroke-miterlimit:4;stroke-dasharray:none;paint-order:markers stroke fill"
        />
        <path
          id="path4654"
          d="m 117.41056,33.807299 c 1.40325,1.640927 1.26626,4.061116 -0.30597,5.405643 -1.57226,1.344527 -3.98439,1.104264 -5.38764,-0.536664 -1.40328,-1.640956 -0.26973,-3.444413 1.27881,-3.719448 2.05869,-0.365599 3.01153,-2.790487 4.41481,-1.149531 z"
          style="opacity:0.98999999;fill:#deaa87;stroke:none;stroke-width:0.77708453;stroke-linecap:round;stroke-miterlimit:4;stroke-dasharray:none;paint-order:markers stroke fill"
        />
        <path
          style="opacity:0.98999999;fill:#c87137;stroke:none;stroke-width:0.94210052;stroke-linecap:round;stroke-miterlimit:4;stroke-dasharray:none;paint-order:markers stroke fill"
          d="m 90.89977,23.339956 c 1.66227,2.036022 1.49999,5.038919 -0.36246,6.707179 -1.86246,1.668261 -4.719818,1.37016 -6.382093,-0.665833 -1.662296,-2.036079 -0.319514,-4.273786 1.514864,-4.615021 2.438682,-0.453625 3.567389,-3.462376 5.229699,-1.426325 z"
          id="path4656"
        />
        <path
          id="path4658"
          d="m 97.622878,-8.4428429 c 1.02913,1.4613276 0.92866,3.6166586 -0.2244,4.8140236 -1.15306,1.197365 -2.922073,0.983455 -3.951193,-0.477873 -1.02914,-1.461387 -0.1978,-3.067484 0.93786,-3.3123876 1.509803,-0.325609 2.208593,-2.485121 3.237733,-1.023763 z"
          style="opacity:0.98999999;fill:#deaa87;stroke:none;stroke-width:0.62800694;stroke-linecap:round;stroke-miterlimit:4;stroke-dasharray:none;paint-order:markers stroke fill"
        />
        <path
          style="opacity:0.98999999;fill:#deaa87;stroke:none;stroke-width:0.61150712;stroke-linecap:round;stroke-miterlimit:4;stroke-dasharray:none;paint-order:markers stroke fill"
          d="m 84.87325,13.698402 c -1.590143,0.709919 -3.635199,0.212785 -4.567795,-1.110261 -0.932574,-1.323075 -0.399601,-2.9712111 1.190484,-3.6811591 1.59018,-0.709948 2.973794,0.382236 2.99764,1.5114681 0.03171,1.501204 1.96979,2.569946 0.379671,3.279923 z"
          id="path4660"
        />
        <path
          style="opacity:0.98999999;fill:#deaa87;stroke:none;stroke-width:1.11787486;stroke-linecap:round;stroke-miterlimit:4;stroke-dasharray:none;paint-order:markers stroke fill"
          d="m 45.123535,7.6311486 c -4e-6,3.0402934 -2.46466,5.5049434 -5.504966,5.5049434 -3.040308,0 -5.504966,-2.46465 -5.504969,-5.5049434 3e-6,-3.040323 2.988667,-3.932983 4.980963,-2.810094 2.648611,1.492756 6.028968,-0.230374 6.028972,2.810094 z"
          id="path4637"
        />
        <path
          style="opacity:0.98999999;fill:#c87137;stroke:none;stroke-width:0.77708453;stroke-linecap:round;stroke-miterlimit:4;stroke-dasharray:none;paint-order:markers stroke fill"
          d="m 39.860506,36.604235 c 1.403257,1.640927 1.266264,4.061116 -0.305978,5.405671 -1.572251,1.344498 -3.984377,1.104236 -5.387637,-0.536663 -1.403279,-1.640956 -0.26973,-3.444442 1.278817,-3.719448 2.058688,-0.365627 3.011522,-2.790487 4.414808,-1.14956 z"
          id="path4642"
        />
        <path
          id="path4644"
          d="m 27.38136,30.279569 c 1.662271,2.03605 1.499992,5.038948 -0.362454,6.707209 -1.862461,1.668232 -4.71982,1.370159 -6.382097,-0.665862 -1.662292,-2.036051 -0.31951,-4.273757 1.514865,-4.614992 2.438681,-0.453655 3.567393,-3.462376 5.2297,-1.426355 z"
          style="opacity:0.98999999;fill:#deaa87;stroke:none;stroke-width:0.94210052;stroke-linecap:round;stroke-miterlimit:4;stroke-dasharray:none;paint-order:markers stroke fill"
        />
        <path
          style="opacity:0.98999999;fill:#deaa87;stroke:none;stroke-width:0.62800694;stroke-linecap:round;stroke-miterlimit:4;stroke-dasharray:none;paint-order:markers stroke fill"
          d="m 28.81607,-1.6113528 c 1.029127,1.46135696 0.928656,3.61666 -0.224404,4.814054 -1.153061,1.197365 -2.922074,0.983426 -3.951201,-0.477874 -1.029136,-1.461386 -0.197792,-3.06748304 0.937866,-3.31238804 1.509804,-0.325637 2.208597,-2.48511996 3.237739,-1.02379196 z"
          id="path4646"
        />
        <path
          id="path4648"
          d="m 31.613251,17.736038 c -1.590144,0.709949 -3.635202,0.213074 -4.567799,-1.110232 -0.932568,-1.323104 -0.399598,-2.97121 1.190485,-3.681187 1.59018,-0.709919 2.973794,0.382264 2.99764,1.511497 0.03171,1.501204 1.969792,2.569946 0.379674,3.279894 z"
          style="opacity:0.98999999;fill:#c87137;stroke:none;stroke-width:0.61150712;stroke-linecap:round;stroke-miterlimit:4;stroke-dasharray:none;paint-order:markers stroke fill"
        />
      </g> -->
      <g transform="translate(-20.10834)" id="cano">
        <rect
          ry="0"
          y="-52.518753"
          x="63.022961"
          height="96.821762"
          width="30.613605"
          id="rect4544"
          style="opacity:0.98999999;fill:#e3dedb;stroke:#d38d5f;stroke-width:0.39224172;stroke-linecap:round;paint-order:markers stroke fill"
        />
        <rect
          style="opacity:0.98999999;fill:#93aca7;stroke:#d38d5f;stroke-width:0.12273686;stroke-linecap:round;paint-order:markers stroke fill"
          id="rect4555"
          width="30.905745"
          height="9.3905382"
          x="62.876888"
          y="-58.962734"
          ry="0"
        />
        <g id="g5142">
          <rect
            ry="0"
            y="-86.709465"
            x="55.150345"
            height="28.445919"
            width="46.358845"
            id="rect4542"
            style="opacity:0.98999999;fill:#e3dedb;stroke:none;stroke-width:0.26162928;stroke-linecap:round;paint-order:markers stroke fill"
          />
          <rect
            style="opacity:0.98999999;fill:#b7c8b7;stroke:none;stroke-width:0.15425998;stroke-linecap:round;paint-order:markers stroke fill"
            id="rect4559"
            width="16.116339"
            height="28.445919"
            x="85.392853"
            y="-86.709465"
            ry="0"
          />
        </g>
      </g>
      <path
        id="grama"
        d="m 138.43716,-96.273564 c 0,0 -1.87603,7.331574 -4.01548,12.627508 -1.12218,-3.979144 -1.95335,-8.314041 -1.95335,-8.314041 -0.28842,0.03806 -1.07794,4.657785 -0.86418,9.176735 -1.68282,-3.918393 -3.1364,-8.433239 -3.1364,-8.433239 -0.23423,0.05536 -0.42056,3.250003 -0.0522,6.859829 -3.01274,-3.342143 -6.16402,-7.682293 -6.16401,-7.682293 -0.28,0.156562 1.2404,5.579323 3.78452,9.823382 -4.41401,-3.250109 -9.80103,-8.415359 -9.80103,-8.415359 v 0.0015 c -0.26174,0.207307 2.0866,5.042392 5.36539,9.175245 -5.13402,-2.138552 -11.57559,-5.78556 -11.57559,-5.78556 -0.17774,0.225532 2.50928,3.399884 6.0463,6.351748 h -76.7275 c 1.393149,-2.429654 2.215826,-4.304612 2.051691,-4.449055 0,0 -2.148383,2.03476 -4.933297,4.449055 h -2.138109 c 1.521939,-4.824068 2.140097,-9.338375 1.837135,-9.471748 0,0 -2.724716,4.946683 -5.72447,9.471748 h -1.799885 c 0.13417,-3.601924 0.405271,-6.743611 0.405271,-6.743611 -0.237978,-0.03979 -1.654733,3.006667 -2.769856,6.743611 h -0.983382 c -0.305974,-3.781988 -0.399312,-7.204012 -0.399312,-7.204012 -0.244772,-0.01145 -1.334643,3.283694 -2.015931,7.204012 h -0.05215 c -1.985473,-4.485458 -3.812838,-10.021546 -3.812838,-10.021546 -0.288645,0.07135 -0.364173,5.135441 0.552779,10.021548 h -0.759887 c -3.077809,-4.674432 -6.120803,-10.40149 -6.120803,-10.40149 -0.295054,0.138488 0.500262,5.296515 2.267736,10.40149 h -0.788191 c -4.149579,-3.812414 -8.5688251,-8.734211 -8.5688251,-8.734211 -0.2541524,0.195391 1.698288,4.912447 4.5891121,9.398739 -0.5688767,0.504091 -0.9357024,1.245725 -0.9357024,2.08745 v 9.739944 c 0,1.524869 1.1799624,2.751977 2.6461884,2.751977 H 139.98672 c 1.46624,0 2.64769,-1.227108 2.64769,-2.751977 v -9.739944 c 0,-1.275479 -0.8311,-2.332621 -1.95931,-2.64768 2.87992,-5.493368 4.30812,-12.426393 3.93202,-12.584299 0,0 -2.67943,4.221943 -5.65891,8.224643 0.59022,-5.481674 -0.1382,-11.092287 -0.51105,-11.130087 z"
        style="opacity:0.98999999;fill:#44aa00;stroke:none;stroke-width:0.3168779;stroke-linecap:round;paint-order:markers stroke fill"
      />
      <g
        transform="translate(-20.10834,-244.08334)"
        id="g5153"
        style="display:inline"
      >
        <path
          style="fill:#808080;stroke:#666666;stroke-width:1.11579728;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
          d="m 93.61512,208.0813 h 6.46065"
          id="path4569"
        />
        <path
          id="path4662"
          d="M 93.61512,208.0813 H 63.096187"
          style="fill:#999999;stroke:#cccccc;stroke-width:1.01715398;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
        />
      </g>
      <path
        style="display:inline;fill:none;stroke:#000000;stroke-width:0.83840609px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1"
        d="M 73.52822,288.38628 H 79.8801"
        id="path4575"
        transform="translate(0,-244.08334)"
      />
    </g>
    <g style="display:inline" transform="translate(0,-147.00001)" id="layer1">
      <g id="g5424">
        <rect
          style="opacity:0.98999999;fill:#2c89a0;stroke:none;stroke-width:0.27172196;stroke-linecap:round;paint-order:markers stroke fill"
          id="agua"
          width="29.855873"
          :height="waterY"
          x="43.293472"
          :y="288-waterY"
          ry="0"
        />
        <text
          xml:space="preserve"
          style="font-style:normal;font-weight:normal;font-size:16.33833504px;line-height:1.25;font-family:sans-serif;letter-spacing:0px;word-spacing:0px;fill:#4d4d4d;fill-opacity:1;stroke:none;stroke-width:0.40845832"
          id="agua_level"
          transform="scale(0.93545784,1.0689953)"
        >
          <tspan
            id="tspan4561"
            x="61.8936"
            :y="268-waterY"
            style="text-align:center;text-anchor:middle;fill:#4d4d4d;stroke-width:0.40845832"
          >
            {{level}}
          </tspan>
        </text>
      </g>
      <text
        xml:space="preserve"
        style="font-style:normal;font-weight:normal;font-size:31.00902367px;line-height:1.25;font-family:sans-serif;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;stroke-width:0.77522546"
        id="agua_max"
      >
        <tspan
          id="tspan4565"
          x="82.069"
          y="208.53416"
          style="font-size:14.40389633px;stroke-width:0.77522546"
        >
          <tspan
            id="tspan5157"
            style="font-size:20.16545677px;line-height:2.25;stroke-width:0.77522546"
          >
            {{maxLevel}}
          </tspan>
          m
        </tspan>
      </text>
      <text
        id="agua_min"
        style="font-style:normal;font-weight:normal;font-size:31.76894188px;line-height:1.25;font-family:sans-serif;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;stroke-width:0.79422343"
        xml:space="preserve"
      >
        <tspan
          style="font-size:14.40389633px;stroke-width:0.77522546"
          y="288.83542"
          x="82.069"
          id="tspan4571"
        >
          <tspan
            id="tspan5165"
            style="font-size:20.16545677px;line-height:2.25;stroke-width:0.77522546"
          >
            {{sensorLevel}}
          </tspan>
          m
        </tspan>
      </text>
    </g>
  </svg>
</template>

<script>
import sensWell from "./sensWell.js";
export default {
  extends: sensWell,
  props: {
    width: { type: [Number, String], required: false, default: 100 },
    height: { type: [Number, String], required: false, default: 100 },
    sensor: { type: Object, default: undefined },
  },

  methods: {
    map(x, in_min, in_max, out_min, out_max) {
      return ((x - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min;
    },
  },
  computed: {
    waterY() {
      let val = this.map(this.level,this.maxLevel,this.sensorLevel,80,0);
      if(val < 0) val = 0;
      if(val> 80) val =80;
      return val;
    },
  },
};
</script>

