/**********************************************************************
 * converte cor de hexadecimal para rgb
 */
function hexToRgb(hex) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function(m, r, g, b) {
      return r + r + g + g + b + b;
    });
  
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  }

/**********************************************************************
 * interpola 2 cores
 */
function perc2color(color1, color2, t) {
  let c1 = hexToRgb(color1);
  let c2 = hexToRgb(color2);
  let r = Math.round(c1.r + (c2.r - c1.r) * t);
  let g = Math.round(c1.g + (c2.g - c1.g) * t);
  let b = Math.round(c1.b + (c2.b - c1.b) * t);
  let h = r * 0x10000 + g * 0x100 + b * 0x1;
  return "#" + ("000000" + h.toString(16)).slice(-6);
}

/**********************************************************************
 * interpola as cores
 */
function interpoleColors(colorsArray, range, v) {
  let cor1 = colorsArray[0];
  let cor2 = colorsArray[0];
  let colorsSize = colorsArray.length;
  let n = 0;

  if (colorsSize > 1) {
    let interval = (range[1] - range[0]) / (colorsSize - 1);

    for (var i = 0; i < colorsSize - 1; i++) {
      cor1 = colorsArray[i];
      cor2 = colorsArray[i + 1];
      let max = range[0] + interval * (i + 1);
      let min = range[0] + interval * i;
      if (max - min == 0) break;

      n = (v - min) / (max - min);

      if (n < 0) {
        n = 0;
        break;
      } else if (n <= 1) {
        break;
      }
    }

    if (n > 1) n = 1;
  }

  return perc2color(cor1, cor2, n);
}



class IrrigChart {
  constructor(element) {
    this.element = element;
    this.margin = { top: 20, right: 90, bottom: 20, left: 10 };
    this.startAngle = 0;
    this.legendUnit = "mm";
    this.dataPoints = [];
    this.colors = [];
    this.domain = [0,100];
    this.mousePos = null;
    this.range = ["#e6f7ff", "#1ab2ff", "#132639"];
   

    this.element = element;
    this.ctx = element.getContext("2d");
    this.resize();


    // this.positionInfo = element.getBoundingClientRect();
    // this.height = this.positionInfo.height;
    // this.width = this.positionInfo.width;
    // this.ctx.canvas.width = this.width;
    // this.ctx.canvas.height = this.height;

    // console.log("constructor",this)

    function getMousePos(canvas, evt) {
        var rect = canvas.getBoundingClientRect();
        return {
          x: evt.clientX - rect.left,
          y: evt.clientY - rect.top,
        };
      }
     
    this.element.addEventListener(
        "mousemove",
        evt => {
          this.mousePos = getMousePos(element, evt);
          this.redraw();
        },
        false
      );

      this.element.addEventListener(
        "mouseout",
        function() {
          this.mousePos = null;
        },
        false
      );
  }

  /**********************************************************************
   * Desenha o gráfico
   */
  redraw() {
    // let self = this;

   // console.log("redraw")

    var textMargin = 10;

    let areaWidth = this.width - this.margin.right - this.margin.left;
    let areaHeight = this.height - this.margin.top - this.margin.bottom;
    let radius = Math.min(areaWidth, areaHeight) / 2 - textMargin;

    if (radius < 1) return;

  //  console.log(radius)

    let offsetX = areaWidth / 2 + this.margin.left;
    let offsetY = areaHeight / 2 + this.margin.top;

    let ctx = this.ctx;

    
    let domain = this.domain;
    let startAngle = -90 + this.startAngle;


    ctx.resetTransform();
    ctx.clearRect(0, 0, this.width, this.height);    
    ctx.save();
    ctx.translate(offsetX, offsetY);

    

    //console.log( this.width, this.height)

    //constantes
    var anglesValues = [0, 30, 60, 90, 120, 150, 180, 210, 240, 270, 300, 330];
    var degToRad = Math.PI / 180;
    var textRadius = radius + textMargin;

    //*************************************
    ctx.font = "10px Arial";
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    

    anglesValues.forEach(function(v) {
      ctx.strokeStyle = "#000";
      let px = Math.cos((v + startAngle) * degToRad);
      let py = Math.sin((v + startAngle) * degToRad);
      ctx.fillText(v, px * textRadius, py * textRadius);

      ctx.strokeStyle = "#ccc";
      ctx.beginPath();
      ctx.moveTo(0, 0);
      ctx.lineTo(px * radius, py * radius);
      ctx.stroke();

    //   ctx.fillRect(px, -10, 20, 20);
    });

    // preenchimento  com cores ************************
    let dataLength = this.dataPoints.length;
    let arcSize = (2 * Math.PI) / dataLength;
    let startRad = (startAngle * Math.PI) / 180;
    let minRad = Math.PI / 180;

    //startRad = Math.PI;

    this.colors.forEach(function(color, i) {
      if (color != 0) {
        ctx.fillStyle = color;
        ctx.beginPath();
        ctx.moveTo(0, 0);
        ctx.arc( 0, 0, radius, startRad + (i) * arcSize, minRad + startRad + (i + 1) * arcSize
        );
        //ctx.lineTo(0, 0);
        ctx.fill();
      }
    });

    // circulo externo   *********************************
    ctx.strokeStyle = "#000";
    ctx.beginPath();
    ctx.arc(0, 0, radius, 0, 2 * Math.PI);
    ctx.stroke();

    ctx.restore();



    // legenda   *********************************
    var self = this;
    function formatLegendText(v) {
       if (typeof v == 'number') return v.toFixed(2) + " " + self.legendUnit;
    }

    //if((domain[1]-domain[0])>0){
    ctx.save();
    let legendWidth = 15;
    let legendHeight = radius * 2; //3*areaHeight/4;
    let legendX = this.width - legendWidth - 65;
    let legendY = areaHeight / 2 + legendHeight / 2 + this.margin.top;
    let legendCount = Math.round(Math.min(legendHeight, dataLength + 1));

    let legendCF = (domain[1] - domain[0]) / legendCount;

    let lgdh = legendHeight / legendCount;
    // let legendCenter = Math.round(legendCount / 2);

    ctx.translate(legendX, legendY);

    for (let i = 0; i <= legendCount; i++) {
      //let px = legendX;
      //let py = i*lgdh+margin.top;
      let value = domain[0] + i * legendCF;

      if (i < legendCount) {
        let color = interpoleColors(this.range, domain, value);
        ctx.fillStyle = color;
        ctx.beginPath();
        ctx.rect(0, -i * lgdh - lgdh, legendWidth, lgdh + 1);
        ctx.fill();
      }
    }

    ctx.fillStyle = "#000";
    ctx.fillText(formatLegendText(domain[1]), legendWidth + 10, -legendHeight);
    ctx.fillText(
      formatLegendText((domain[1] + domain[0]) / 2),
      legendWidth + 10,
      -legendHeight / 2
    );
    ctx.fillText(formatLegendText(domain[0]), legendWidth + 10, 0);
    ctx.restore();



    // cursor   *********************************
    if (this.mousePos != null) {
      let deltaX = this.mousePos.x - offsetX;
      let deltaY = this.mousePos.y - offsetY;
      let distance = Math.sqrt(deltaX * deltaX + deltaY * deltaY);

      if (distance < radius) {
        ctx.save();
        ctx.font = "10px Arial";
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        let cursorAngle =
          (Math.atan2(this.mousePos.x - offsetX, this.mousePos.y - offsetY) *
            180) /
          Math.PI;
        let px = Math.sin(cursorAngle * degToRad);
        let py = Math.cos(cursorAngle * degToRad);

        //let px = mousePos.x;
        //let py = mousePos.y;

        //linha de angulo ----
        ctx.shadowColor = "black";
        ctx.shadowBlur = 5;
        ctx.strokeStyle = "#ffa";
        ctx.beginPath();
        ctx.moveTo(offsetX, offsetY);
        ctx.lineTo(offsetX + px * radius, offsetY + py * radius);
        ctx.stroke();

        
        ctx.fillStyle = "#ffa";
        ctx.beginPath();
        ctx.rect(
          offsetX + px * (radius + textMargin) - 12,
          offsetY + py * (radius + textMargin) - 7,
          24,
          14
        );
        ctx.fill();


        ctx.shadowBlur = 0;
        ctx.fillStyle = "#000";
        let realAngle = Math.round(-cursorAngle + 90 - startAngle);
        ctx.fillText(
          realAngle,
          offsetX + px * (radius + textMargin),
          offsetY + py * (radius + textMargin)
        );

        ctx.shadowColor = "black";
        ctx.shadowBlur = 5;
        ctx.fillStyle = "#ffa";
        ctx.beginPath();
        ctx.rect(this.mousePos.x - 30, this.mousePos.y - 20, 60, 15);
        ctx.fill();
        ctx.shadowBlur = 0;
        ctx.fillStyle = "#000";

        let pointIndex = Math.floor((realAngle / 360) * dataLength);
        ctx.fillText(
          formatLegendText(this.dataPoints[pointIndex]),
          this.mousePos.x + 5,
          this.mousePos.y - 12
        );

        ctx.restore();
      }
    }

    //}
  }


  set data(data) {
    let max = Number.MIN_VALUE;
    let min = Number.MAX_VALUE;

    var dataPoints = data.map(e=>{return e[0]})

    dataPoints.forEach(function(v) {
      if (v > 0 && v < min) min = v;
      if (v > max) max = v;
    });

    this.domain = [min==Number.MAX_VALUE?0:min, max==Number.MIN_VALUE?0:max];
    this.dataPoints = dataPoints;

    // console.log(dataPoints)

    var colors = [];
    this.dataPoints.forEach((v)=> {
        if (v != 0) {
          let color = interpoleColors(this.range, this.domain, v);
          colors.push(color)
        }else{
          colors.push(0)
        }
      });
    this.colors = colors

    this.redraw();
    
  }


  resize() {
    var positionInfo = this.element.getBoundingClientRect();
    this.width = positionInfo.width;
    this.height = positionInfo.height;
    this.ctx.canvas.width = this.width;
    this.ctx.canvas.height = this.height;
    this.redraw();
  }
  
}

export { IrrigChart };
