<template>

    <aside class="main-sidebar">
        <sideBarContent> </sideBarContent>
    </aside>
</template>
<script>



import sideBarContent from "./sideBarContent.vue"

export default {

    data() {
        return {
            is_expanded: false,
            clientfilter: "",
        }
    },


    components: {

        sideBarContent
    },

}
</script>
<style lang="scss">
.sidebar-wrapper {
    .main-sidebar {
        @media screen and (max-width: $breakpoint-sm) {
            //  height: calc(100vh - var(--navbar-height) + var(--mobilebar-height));
            display: none;
        }


        margin-left: 0;
        width: 4rem;
        bottom: 0;
        float: none;
        left: 0;
        position: fixed;
        top: 0;
        min-height: 100vh;
        background-color: $light;
        border-right: 1px solid rgb(172, 172, 172);
        z-index: 1030;
        transition: width .3s ease-in-out,
        box-shadow .6s ease-in-out;


        &:hover {
            width: 14rem;
            box-shadow: 0.3rem 0 0.6rem rgba(0, 0, 0, 0.267);

            .collapse {
                padding-left: 10px;
            }

        }

        &:not(:hover) {

            .nav-link .label,
            .nav-link .right,
            .brand-text  {
                visibility: hidden;
                opacity: 0;
            }

            // .collapse{
            //     display: none;
            // }

        }




        // .navbar-nav {
        //     position: relative;
        //     display: flex;
        //     -ms-flex-wrap: wrap;
        //     flex-wrap: wrap;
        //     padding-left: 0;
        //     margin-bottom: 0;
        //     list-style: none;
        // }


    }



    .body-page {
        @media screen and (min-width: $breakpoint-sm) {
            padding-left: 4rem;
        }

    }

    h3 {
        font-size: 14px;
        font-weight: 400;
        line-height: 14px;
        font-style: italic;
        margin: 0;
        padding: 15px 6px 6px;
    }

    // .user-itens {
    //     .item-icon {
    //         margin-right: 5px;
    //         width: 20px;
    //     }

    //     .item-note {
    //         font-family: "Open Sans", Helvetica, Arial, sans-serif;
    //         font-size: 11px;
    //         font-weight: 400;
    //         line-height: 15px;
    //         color: var(--fadedTextColor);
    //         margin: 0;
    //         padding: 0;
    //         padding: 0 0 0 30px;
    //         color: #666;
    //     }

    //     .avatar {
    //         display: inline-block !important;
    //         margin: -10px 0px;
    //     }
    // }
}
</style>