
const equator_circumference = 6371000*2*Math.PI;
const polar_circumference   = 6356800*2*Math.PI;
const m_per_deg_long =  360.0 / polar_circumference;
// const earthRadius = 6371; // Radius of the earth


function degreesToMeters(coordinate) {
    let lon = coordinate[0];
    let lat = coordinate[1];

    // acha a constante de conversão para a longitude
    let rad_lat = (lat * Math.PI) / 180.0;
    let m_per_deg_lat = 360.0 / (Math.cos(rad_lat) * equator_circumference);
    
    // converte as coordenadas de graus para metros
    lat = lat / m_per_deg_long;
    lon = lon / m_per_deg_lat;

  

    return [lon, lat];
}


export default function calcArea(polygon) {
    let a = [0, 0];
    let b = [0, 0];
    let c = [0, 0];
    
    let area = 0;
    let count = 0;

    for (let coordinate of polygon) {
        let result = degreesToMeters(coordinate);
        let lon = result[0];
        let lat = result[1];

        // Atualiza as coordenadas
        b[0] = a[0];
        b[1] = a[1];
        a[0] = lon;
        a[1] = lat;

        if (count > 0) {
            let d = ((a[0] * b[1] + a[1] * c[0] + b[0] * c[1]) -
                     (c[0] * b[1] + a[0] * c[1] + a[1] * b[0])) / 2;
            area += d;
        } else {
            c[0] = a[0];
            c[1] = a[1];
        }

        count++;
    }

    return Math.abs(area);
}
