


const flagFormat = function(value){
    let converter = {
          "germination"   : "Germinação" ,
          "chemigation"   : "Quimigação" ,
          "fertigation"   : "Fertirrigação" ,
          "problem_power" : "Falta de energia" ,
          "problem"       : "Problemas operacionais"
    }
    try{
      return converter[value]
    }catch{
      return value
    }
  }


  export{
    flagFormat
  }