<template>
  <b-row>
    <b-col lg="6">
      
     
      <!-- POSICAO INICIAL-->
      <b-form-group label="Posição Inicial">
        <b-input-group>
          <b-input-group-prepend is-text class="input-slice-number" style="width:75%">
            <b-form-input name="start" v-model="editableField.start" type="range" min="0" max="360"></b-form-input>
          </b-input-group-prepend>
          <b-form-input name="size" v-model="editableField.start" type="number" min="0" max="360"></b-form-input>
          <b-input-group-append is-text>⁰</b-input-group-append>
        </b-input-group>
      </b-form-group>

      
      <!-- TAMANHO DA PARCELA -->
      <b-form-group label="Tamanho">
        <b-input-group>
          <b-input-group-prepend is-text class="input-slice-number" style="width:75%">
            <b-form-input name="size" v-model="editableField.size" type="range" min="0" max="360"></b-form-input>
          </b-input-group-prepend>
          <b-form-input name="size" v-model="editableField.size" type="number" min="0" max="360"></b-form-input>
          <b-input-group-append is-text>⁰</b-input-group-append>
        </b-input-group>
      </b-form-group>



      <!-- RAIO EXTERNO-->
      <ValidationProvider
        slim
        vid="externalRadius"
        name="Raio Externo"
        :rules="{required: true, min:0,max:maxRadius}"
        v-slot="v"
      >
        <b-form-group label="Raio Externo">
          <b-input-group>

            <!-- <b-form-input
              name="internalRadius"
              v-model="editableField.internalRadius"
              type="number"
              min="0"
              :state="$validateState(v)"
            ></b-form-input> -->

            <b-form-input
              name="externalRadius"
              v-model="editableField.externalRadius"
              type="number"
              min="0"
              :state="$validateState(v)"
            ></b-form-input>


            <b-input-group-append is-text> m </b-input-group-append>
            <b-input-group-append> <b-button variant="primary" @click="setTotalExternalRadius()"> total </b-button></b-input-group-append>
           

          </b-input-group>
          <!-- <b-form-invalid-feedback>{{ v.errors[0] }}</b-form-invalid-feedback> -->
          <span class="invalid-input">{{ v.errors[0] }}</span>

         
        </b-form-group>
      </ValidationProvider>


    </b-col>
    <b-col>
      
      <simple-map>
        <pivotMap :equipment="equipment" />
        <fieldMap :field="field" />
      </simple-map>
       
    </b-col>
  </b-row>
</template>

<script>

import fieldMap from "./fieldMap";
import pivotMap from "../equipment/pivotMap";
import field from "./field";

export default {
  mixins: [field],
  data(){
    return{
      editableField:undefined
    }
  },
  
  components: {
    fieldMap,
    pivotMap,
  },

  beforeMount(){
    this.editableField = JSON.parse(JSON.stringify(this.field))
  },

  methods:{
    setTotalExternalRadius(){
      this.editableField.externalRadius = this.maxRadius
    }
  },

  computed: {
    maxRadius(){
      return this.equipment.radius + this.equipment.overhang;
    }
  },

  mounted() {
    if (this.editableField.externalRadius == undefined) {
      this.setTotalExternalRadius()
    }

    if (this.editableField.start == undefined) {
      this.$set(this.editableField, "start", 0);
      this.$set(this.editableField, "size", 360);
    }
  },
  watch:{
    editableField: {
      deep: true,
      handler(v) {
       this.$emit("update:field",v)
      },
    },
  },
};
</script>

<style>
</style>