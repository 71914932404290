<template>
  <div>
    <div v-if="consumerStation" :id="'printDiv-'+consumerStation.id">

      <report-header class="print-only"  label="Relatorio de Energia"/>


      <div class="d-flex">
        <h5>
          Unidade Consumidora: <b>{{ consumerStation.number }}</b>
        </h5>

        <div class="ml-auto buttons-container">
          <b-button v-if="$can('edit', 'ConsumerStation')" v-b-tooltip.hover title="editar Unidade Consumidora"
            variant="outline-secondary" size="sm" @click="$emit('set-editable-unit', consumerStation)">
            <font-awesome-icon icon="edit" />
          </b-button>
          <b-button v-if="$can('edit', 'ConsumerStation')" v-b-tooltip.hover title="apagar Unidade Consumidora"
            variant="outline-danger" size="sm" @click="$emit('delete-unit', consumerStation.id)">
            <font-awesome-icon icon="trash" />
          </b-button>
          <b-button size="sm" variant="outline-primary" @click="$printDiv('printDiv-'+consumerStation.id)" title="imprimir">
            <font-awesome-icon icon="print" />
          </b-button>
        </div>
      </div>

      <div class="d-flex">
        <div>{{ consumerStation.title }}</div>
        <div class="ml-4">
          {{ consumerStation.clientName }} -
          {{ consumerStation.concessionaire }}
        </div>
      </div>
     

      <div class="mt-4 ">
        <!-- <b-form-group class="non-print" label="Mês de referência:" label-size="lg" label-align-sm="right" label-cols-sm="5" label-cols-md="3" content-cols-sm content-cols-md="4">
          <b-input  type="month" id="start" name="start" v-model="month" />
        </b-form-group> -->

        <b-form-group class="non-print" label="Mês de referência:" 
          label-size="lg" 
          label-align-sm="right" 
          label-cols="6" content-cols="6"
          label-cols-md="3" content-cols-md="4">
          <monht-select  v-model="month" />
        </b-form-group>        

        <h5 class="print-only">
            <b>Mês de referência:</b> {{ $filters.longMonth(month) }}
        </h5>
      </div>
      <hr>
      <b-row>
        <b-col md="8">
          <!-- Lista de Contas de um mes -->
          <billsPage :consumerStation="consumerStation" :month="month" />
          
          <contractedDemandHistoryChart  
              class="mt-5"
              width="100%" 
              :numMonth="12" 
              :month="month"
              :history="bills"
              chartHeight="220px"
              @select-month="month = $event"/>
        </b-col>
        <b-col md="4">
          <!-- <billsHistory :unitId="consumerStation.id" :month="month" @select-month="month = $event" demand/> -->
          <bills-history-charts
            width="100%"
            :numMonth="12"
            :month="month"
            :history="bills"
            chartHeight="220px"
            @select-month="month = $event"
          />
        </b-col>
      </b-row>

    </div>
    <div v-else>Error!!</div>


  </div>
</template>

<script>
import billsPage from "./billsPage";

import { DateTime } from "luxon";
import reportHeader from "@reportcomponents/comum/reportHeader.vue";
import contractedDemandHistoryChart from "./contractedDemandHistoryChart";
import billsHistoryCharts from "./billsHistoryCharts";

export default {
  data() {
    return {
      loadListStatus: "",
      bills: undefined,
      month: undefined,
      consumerStationEdit: undefined,
    };
  },
  props: {
    loadMonth: String,
    consumerStation: { type: Object, required: true },
    active: Boolean,
  },
  components: {
    reportHeader,
    billsPage,
    contractedDemandHistoryChart,
    billsHistoryCharts
  },
  beforeMount() {
    if (this.loadMonth) {
      this.month = this.loadMonth;
    } else if (this.consumerStation.lastMonth) {
      this.month = this.consumerStation.lastMonth;
    } else {
      this.month = DateTime.now().toFormat('yyyy-MM');
    }
  },
  mounted(){
    this.loadBriefBills(this.month)
  },
  watch: {
    active(v) {
      if (v === true) {
        this.$router.replace({
          name: "electricityPage",
          params: { unitId: this.consumerStation.id, month: this.month },
        });
      }
    },

    month(val) {
      if (val !== this.month) {
        this.$router.replace({
          name: "electricityPage",
          params: { unitId: this.consumerStation.id, month: this.month },
        });

        if (this.month) {
           this.loadBriefBills(val);
        }

      }
    },
  },


  methods:{
    loadBriefBills(month) {
      this.loadListStatus = "loading";
      let objectsListUrl =
        "report/consumer-station/" + this.consumerStation.id + "/history/" + month + "/12";
      this.$http.get(objectsListUrl).then(
        (data) => {
          this.bills = data;
          this.loadListStatus = "loaded";
        },
        (error) => {
          console.error("loading Object List error", error);
          this.loadListStatus = "error";
        }
      );
    },
  }


};
</script>

<style></style>
