<template>
  <div>
    <colorsLegend />
    <div class="d-flex flex-wrap pt-1 px-sm-2 border-bottom">
      <slot></slot>




      <div class="ml-auto">

        <b-nav class="nav-border">
          <b-nav-item @click="tabIndex = 0" :active="tabIndex == 0">Decisão</b-nav-item>
          <b-nav-item @click="tabIndex = 1" :active="tabIndex == 1">Programação</b-nav-item>
          <b-nav-item @click="tabIndex = 2" :active="tabIndex == 2">Irrigação</b-nav-item>
        </b-nav>


        <!-- <b-button-group size="sm">
            <b-button @click="tabIndex=0" variant="info">Decisão</b-button>
            <b-button @click="tabIndex=1" variant="primary">Programação</b-button>
            <b-button @click="tabIndex=2" variant="success">Irrigação</b-button>
          </b-button-group> -->
      </div>
    </div>
    <div class="px-1 px-sm-2 pt-2">
      <decision v-if="tabIndex == 0" :equipments="equipments" />
      <schedule v-if="tabIndex == 1" :equipments="equipments" />
      <irrigation v-if="tabIndex == 2" />
    </div>
    <!-- <div class="tools">
      <slot></slot>
    </div>
    <b-tabs class="mt-2" content-class="mt-3" align="right">
      <b-tab title="Decisão" active> <decision :equipments="equipments"/></b-tab>
      <b-tab title="Programação"><schedule :equipments="equipments"/> </b-tab>
      <b-tab title="Irrigação" > <irrigation /> </b-tab>
    </b-tabs> -->


  </div>
</template>

<script>

import { mapState } from "vuex";
import overviewTable from './overviewTable'
import overviewSchedule from './overviewSchedule'
import IrrigationTable from './irrigationTable'
import colorsLegend from "@irrigcomponents/field/colorsLegend";

export default {
  data() {
    return {
      tabIndex: 0
    }
  },
  computed: {
    ...mapState({
      equipments: state => state.equipments.equipments
    })
  },

  components: {
    'decision': overviewTable,
    'schedule': overviewSchedule,
    'irrigation': IrrigationTable,
    colorsLegend
  }
};
</script>


<style lang="scss" scoped>
.tools {
  position: absolute;
  left: 0.5rem;
  top: 1rem;
}
</style>
