<template>
  <div class="d-flex">
    <div class="mr-2">
      <b-form-select v-model="selectedYear" @change="updateMonths">
        <b-form-select-option :value="undefined"> ano </b-form-select-option>
        <b-form-select-option v-for="year in years" :value="year" :key="year">{{ year }}</b-form-select-option>
      </b-form-select>
    </div>

    <div>
      <b-form-select v-model="selectedMonth" @change="updateModel">
        <b-form-select-option :value="undefined"> mês </b-form-select-option>
        <b-form-select-option v-for="(month, index) in months" :value="index + 1" :key="index">{{ month }}</b-form-select-option>
      </b-form-select>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      
      selectedYear: this.value?parseInt(this.value.split('-')[0]):undefined,
      selectedMonth: this.value?parseInt(this.value.split('-')[1]):undefined,


      months: [
        'Janeiro', 'Fevereiro', 'Março', 'Abril',
        'Maio', 'Junho', 'Julho', 'Agosto',
        'Setembro', 'Outubro', 'Novembro', 'Dezembro'
      ],
      years: []
    };
  },
  mounted() {
    this.generateYears();
  },
  watch: {
    value(newValue) {
      this.selectedYear = parseInt(newValue.split('-')[0]);
      this.selectedMonth = parseInt(newValue.split('-')[1]);
    },
    selectedYear(){
      this.selectedMonth = undefined
    } 
  },
  methods: {
    generateYears() {
      const currentYear = new Date().getFullYear();
      for (let year = currentYear - 10; year <= currentYear + 10; year++) {
        this.years.push(year);
      }
    },
    updateMonths() {
      this.updateModel();
    },
    updateModel() {
      const formattedMonth = this.selectedMonth < 10 ? "0" + this.selectedMonth : this.selectedMonth;
      const selectedDate = `${this.selectedYear}-${formattedMonth}`;

      if(this.selectedMonth && this.selectedYear)
      this.$emit('input', selectedDate);
    }
  }
};
</script>
