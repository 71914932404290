<template>
  <b-row no-gutters>
    <b-col lg="3" class="sensor-info">
      <b-card no-body>
        <b-overlay :show="!isOnline" rounded="sm">
          <b-row no-gutters>
            <b-col cols="12" sm="5" md="6" lg="12">
              <slot name="sensor"></slot>
            </b-col>

            <b-col cols="12" sm="7" md="6" lg="12" class="p-2">
              <span class="sensor-name">{{ sensor.name }}</span>
              <slot name="info"></slot>
            </b-col>

          </b-row>
          <template v-slot:overlay>
            <font-awesome-icon icon="plug-circle-xmark" />
            {{ $filters.secondsToStr(elapsedTime) }} Offline!
          </template>
        </b-overlay>
      </b-card>


      


      <div class="p-2">
         <slot name="tools"></slot>
      </div>

      <!-- link para o feed -->
      <Can I="read" a="Master">
        <div class="p-2">
          <b-button :to="{ name: 'feedDetails', params: { feedId: sensor.feedId  } }"> Feed </b-button>
        </div>
      </Can>

    </b-col>
    <b-col lg="9">
      <div class="mx-2">

        <div class="offline-alert" v-if="!isOnline">
            <font-awesome-icon icon="exclamation-triangle" />
            Offline. Os dados podem estar desatualizados.
        </div>

        <slot></slot>
      </div>
      <!-- <PAGINA PRINCIPAL> -->
    </b-col>
  </b-row>
</template>

<script>
import sensor from "./sensor";
export default {
  extends: sensor,
};
</script>

<style lang="scss" scoped>
.offline-alert {
  color: #721c24;
  background-color: #ffc107;
  border-color: #f5c6cb;
  position: relative;
  padding: 0.25rem 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

</style>
