<template>
  <tbody>
    <vl-layer-tile ref="ndviLayer" :z-index="2">
      <component :is="'vl-source-' + source.source" v-bind="source"></component>
    </vl-layer-tile>

     <div class="map-ndvi-legend">
      <ndviScale/>
     </div>
  </tbody>
</template>

<script>

import ndviScale from './ndviScale'

export default {
  data() {
    return {
      source: {
        // minZoom: 14,
        source: "wms",
        title: "NDVI",
        text: "N",
        visible: false,
        //  url:"https://services.sentinel-hub.com/ogc/wms/300a7354-89b9-4013-be6e-ed0d9d7904eb",
        url:
          "https://services.sentinel-hub.com/ogc/wms/8b97d6d7-0a56-43e0-a45e-c9212d99ae8f",

        // extParams:{'LAYERS':'NDVI'}
        // url: 'https://ahocevar.com/geoserver/wms',
        extParams: {
          width: 512,
          height: 512,
          srs: "EPSG:4326",
          // maxcc: 20,
          //  minZoom: 6,
          //  maxZoom: 16,
          // preset: "NDVI",
          // time: "2020-05-01/2020-11-04",
        },
        version: "1.1.1",
        layers: "NDVI,DATE",
        // layers:'MOISTURE_INDEX',
        // layers:'TRUE_COLOR',
        format: "image/jpeg",
      },
    };
  },

  mounted() {
    // console.log(this.$refs.ndviLayer.$layer);
  },

  methods: {},

  components:{
    ndviScale
  }
};
</script>

<style lang="scss" scoped>
.map-ndvi-legend {
  position: absolute;
  bottom: 3em;
  right: 0.5em;
  z-index: 100;
}
</style>
