<template >
<tbody v-if="dataIsValid">
  <!-- AREA DO PIVO -->
  <vl-feature :id="'pivotarea-'+equipment.id" >
    <vl-geom-polygon v-if="pivoGeometric!=null" :coordinates="[pivoGeometric.area]"></vl-geom-polygon>
    <vl-style-box :z-index="3">
      <vl-style-stroke v-if="ghost" color="#aaa" :width="3" :lineDash="[1,3]"></vl-style-stroke>
      <vl-style-stroke v-else color="#fa0" :width="2" :lineDash="[1,3]"></vl-style-stroke>
    </vl-style-box>
  </vl-feature>

  <!-- LINHA-ZERO -->
  <vl-feature v-if="!ghost" :id="'pivotzero-'+equipment.id" >
    <vl-geom-polygon v-if="pivoGeometric!=null" :coordinates="[pivoGeometric.zeroLine]"></vl-geom-polygon>
    <vl-style-box :z-index="4">
      <vl-style-stroke :color="[200, 180, 0, 0.3]" :width="2"></vl-style-stroke>
    </vl-style-box>
  </vl-feature> 

  <!-- CENTRO DO PIVO -->
  <vl-feature  v-if="!ghost" :id="'pivotcenter-'+equipment.id" :properties="{compId:equipment.id,name: `${equipment.name}`, equipment: equipment}">
    <vl-geom-point :coordinates="[location.lon,location.lat]"></vl-geom-point>
    <vl-style-box :z-index="5">
        <!-- <vl-style-circle :radius="10">
          <vl-style-stroke :color="[0, 0, 0, 0.3]" :width="5"></vl-style-stroke>
          <vl-style-fill color="#fff"></vl-style-fill>
        </vl-style-circle> -->
        <vl-style-circle :radius="3">
          <!-- <vl-style-stroke color="#fa0" :width="2"></vl-style-stroke> -->
          <vl-style-fill color="#fa0"></vl-style-fill>
        </vl-style-circle>
      </vl-style-box>
  </vl-feature>

</tbody>
</template>

<script>
import { pivotGeometry } from "@components/mixins/PivotGeometry.js";

export default {
  mixins: [pivotGeometry],

  props: {
    equipment: { type: Object, required: true },
    ghost :Boolean
  },

  computed: {
    pivoGeometric: function() {
      let zeroPos = Number(this.equipment.offsetPos);
      let start = Number(this.equipment.areaStartPos);
      let end = Number(this.equipment.areaEndPos);
      let radius =
        Number(this.equipment.radius) + Number(this.equipment.overhang);
      let center = [this.location.lon, this.location.lat];
      return this.getGeometry(start, end, radius, center, zeroPos);
    },

    location(){
      if (this.equipment.centerLocation)
        return this.equipment.centerLocation
      else
        return this.equipment.location
    },  

    dataIsValid() {
      if (
        this.location == undefined ||
        this.equipment.radius == undefined ||
        this.equipment.areaStartPos == undefined ||
        this.equipment.areaEndPos == undefined
      ) {
        return false;
      } else {
        return true;
      }
    }
  },
  methods: {}
};
</script>

<style lang="css" scoped>
</style>
