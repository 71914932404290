import sensor from '../sensor.js'

export default {
  extends:sensor,

  computed:{
    layers(){
      return this.sensor.currentLayers
    }
  },
  filters:{
    barValue : function(value){
      let val = value*3;
      if(val>100) val = 100

      return val+'%';
    }
  }
}
