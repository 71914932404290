<template>
  <simple-map>
    <template v-slot:layer>
      <selectPosition v-model="location" :imgPoint="imgPoint" />
      <slot></slot>
    </template>
  </simple-map>
</template>

<script>
import selectPosition from "@components/map/selectPosition";
export default {
  data() {
    return {
      location: {ele: 0, lat: 0, lon: 0},
    };
  },
  props: {
    value: {
      type: Object,
      default: function() {
        return { ele: 0, lat: 0, lon: 0 };
      },
    },
    imgPoint: { type: String, default: undefined },
  },
  beforeMount() {
    this.location = Object.assign({}, this.value);
  },
  watch: {
    location: {
      deep: true,
      handler(v) {
      this.$emit("input", JSON.parse(JSON.stringify(v)));
      },
    },

  },
  components: {
    selectPosition,
  },
};
</script>

<style></style>
