
export const editableList = {
  data() {
    return {
      objectsList: [],
      loadListStatus: "",
      editableConsumerUnit: undefined,
      objectsListUrl: "",
      objectsDeleteUrl: undefined,
      objectListDeleteMessage:"Deseja realmente apagar este registro?"
    };
  },

  methods: {
    loadList() {
      this.loadListStatus = "loading";
      this.$http.get(this.objectsListUrl).then(
        (data) => {
          this.objectsList = data;
          this.loadListStatus = "loaded";
          if(this.listLoaded)
            this.listLoaded()
        },
        (error) => {
          console.error("loading Object List error", error);
          this.loadListStatus = "error";
        }
      );
    },

    deleteObjectById(id,passphrase) {
      
      var deleteUrl = this.objectsDeleteUrl?this.objectsDeleteUrl:this.objectsListUrl

      var excludeModal = undefined

      if (passphrase)
        excludeModal =  this.$deleteConfirm.show(passphrase,{excludeObjects:['unidade consumidora','contas de energia']});
      else
        excludeModal =  this.$bvModal
                        .msgBoxConfirm(
                          this.objectListDeleteMessage,
                          {
                            title: "Confirmar Exclusão!",
                            size: "md",
                            buttonSize: "sm",
                            okVariant: "danger",
                            okTitle: "Sim",
                            cancelTitle: "Não",
                            footerClass: "p-2",
                            hideHeaderClose: false,
                            centered: false,
                          }
                        )


      excludeModal.then((value) => {
          if (value === true) {
            this.$http.delete(deleteUrl +'/'+ id).then(
              () => {
                //var data
                let index = this.objectsList.findIndex((e) => e.id === id);
                this.objectsList.splice(index, 1);
              },
              (error) => {
                console.error("delete error", error);
              }
            );
          }
        });
    },

    updateList(object) {
      this.editableConsumerUnit = undefined;
      const index = this.objectsList.findIndex((e) => e.id === object.id);
      if (index === -1) {
        this.objectsList.push(object);
      } else {
        let dataObj = this.objectsList[index];
        Object.entries(object).forEach(([key, value]) => {
          dataObj[key] = value;
        });
      }
    },
  }
};
