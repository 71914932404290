<template>
  <div>
    <div v-if="loadListStatus == 'loaded'">
      <div v-if="bills.length > 0">
        <billDetails
          v-for="bill in bills"
          :key="bill.id"
          :bill="bill"
          @set-editable-bill="editableBill = $event"
          @delete-unit="deleteObjectById($event)"
          :editable="$can('edit','EnergyBill')"
        ></billDetails>
      </div>
       <div v-else class="text-center m-5">
        <div>
          <img
            style="width: 140px;"
            src="@/assets/bill.svg"
            alt=""
          />
        </div>
        <div class="mt-4">
          <h5>Sem contas de energia</h5>
          <p>
            Este mês ainda não possui contas cadastradas.
          </p>
        </div>
      </div>

      <div v-if="$can('create','EnergyBill')" class="my-3 text-center non-print">
        <b-button
          variant="primary"
          title="inserir nova conta no mes de referência"
          @click="editableBill = { referenceMonth: month , contractedDemand:consumerStation.contractedDemand}"
        >
          + Inserir conta neste mês
        </b-button>
      </div>

      <billEdit
        v-model="editableBill"
        @update-object="updateList($event)"
        :consumerStationId="consumerStation.id"
        :editInjectedEnergy = "consumerStation.ownGeneration"
      />
    </div>
    <c-loading :status="loadListStatus" v-else> </c-loading>
  </div>
</template>

<script>
import { editableList } from "@components/mixins/EditableList";
import billDetails from "./billDetails";
import billEdit from "./billEdit/billEdit";

export default {
  mixins: [editableList],

  data() {
    return {
      editableBill: undefined,
    };
  },
  props: {
    consumerStation: { type: Object, required: true },
    month: { type: String, required: true },
  },
  components: {
    billDetails,
    billEdit,
  },
  watch: {
    month(v) {
      if (this.month) {
        this.loadBill(v);
      }
    },
  },
  beforeMount() {
    this.objectsDeleteUrl = "report/bill";
    if (this.month) {
      this.loadBill(this.month);
    }
  },
  methods: {
    loadBill(v) {
      this.objectsListUrl = "report/consumer-station/" + this.consumerStation.id + "/bill/" + v;
      this.loadList();
    },
  },
  computed: {
    bills() {
      return this.objectsList;
    },
  },
};
</script>

<style></style>
