<template lang="html">

  <vl-feature  :id="'map-hydro-'+sensor.id" :properties="{ name:sensor.name, compId: sensor.id }">
  
      <vl-geom-point :coordinates="coordinates"></vl-geom-point>
      <vl-style-box v-if="isOnline" :z-index="4">
        <vl-style-icon  :scale="1" :anchor="[0.5, 1]" :size="[40, 40]"
          :src="require('../../assets/mapPin/hydrometer.png')"
        ></vl-style-icon>
      </vl-style-box>
      <vl-style-box v-else :z-index="4">
        <vl-style-icon :scale="1" :anchor="[0.5, 1]" :size="[40, 40]"
            :src="require('../../assets/mapPin/hydrometer_off.png')"
        ></vl-style-icon>
      </vl-style-box>

      <receivingIcon v-if="receiving" />

<!--
      uma forma de pegar a posição do feature no mapa
      pointOnSurface(feature.geometry)
-->

      <vl-overlay v-if="showDetail===true"
      :position="coordinates" :offset="[-48, -54]"
      positioning="bottom-left"
      :auto-pan="true" :auto-pan-animation="{ duration: 300 }">
         <div class="map-popup popup-hydro"  @click="showInfo">
           <hydroInfo :sensor="sensor"/>
        </div>
      </vl-overlay>

  </vl-feature>

</template>

<script>
import {pointSensor} from '@components/map/mapCompMixin.js'
import sensHydro from './sensHydro.js'
import hydroInfo from './hydroInfo'
import receivingIcon from "../receivingIconMap.vue"

export default {
  extends:sensHydro,
  mixins: [pointSensor],
  components:{
    hydroInfo,
    receivingIcon
  }
}
</script>

<style lang="scss" scoped>
.popup-hydro{
  width:200px;
  cursor: pointer;
  padding: 5px;
}
</style>
