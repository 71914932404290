<template>
  <b-modal ref="edit-modal" no-fade scrollable @hidden="close" size="max-xl">

    <template v-slot:modal-title>
        <span v-if="stationId">   <b-link  :to="{name:'stationDetails', params:{id:form.id} }">{{form.name}} </b-link> / </span>
        <span v-if="stationId">Editar</span>
        <span v-else active>Nova</span>
    </template>


    <validation-observer ref="formObserver">
      <b-row>


      <!-- nome da estação-->
        <ValidationProvider
          slim
          vid="name"
          name="Nome"
          :rules="{ required: true,min:3 }"
          v-slot="v"
        >
          <b-form-group label="Nome" class="col-6">
            <b-form-input
              name="name"
              v-model="form.name"
              type="text"
              maxlength="30"
              placeholder="nome"
              :state="$validateState(v)"
            ></b-form-input>
            <span class="invalid-input">{{ v.errors[0] }}</span>
          </b-form-group>
        </ValidationProvider>


        <b-form-group label="Cliente" class="col-6">
          <b-form-select v-model="form.client" :options="clientList"></b-form-select>
        </b-form-group>


        <!-- tipo de estação-->
          <ValidationProvider slim vid="dataOrigin" name="Tipo" :rules="{required: true }" v-slot="v">
          <b-form-group label="Origem do dado" class="col-sm-6 col-lg-4">
            <b-form-select
              v-model="form.dataOrigin"
              :options="stationDataList"
              name="dataOrigin"
              :state="$validateState(v)"
            ></b-form-select>
             <!-- <b-form-invalid-feedback>{{ v.errors[0] }}</b-form-invalid-feedback> -->
             <span class="invalid-input">{{ v.errors[0] }}</span>
          </b-form-group>
          </ValidationProvider>


        <!-- código de estação-->
          <b-form-group label="Código" class="col-sm-6 col-lg-4">
            <b-form-input
              name="code"
              v-model="form.code"
              type="text"
              maxlength="50"
              placeholder="código"
            ></b-form-input>
          </b-form-group>

          <!-- DATA DO Início-->
            <ValidationProvider slim vid="startDate" name="Data" :rules="{required: true}" v-slot="v">
            <b-form-group label="Data de Início" class="col-sm-12 col-lg-4">
              <b-input-group>
                <template v-slot:append>
                  <b-input-group-text>
                    <font-awesome-icon icon="calendar" />
                  </b-input-group-text>
                </template>
                <b-form-input
                  name="startDate"
                  v-model="form.startDate"
                  type="date"
                  :state="$validateState(v)"
                ></b-form-input>
              </b-input-group>
              <span class="invalid-input">{{ v.errors[0] }}</span>
            </b-form-group>
            </ValidationProvider>



        <ValidationProvider
          slim
          vid="elevation"
          name="elevação"
          :rules="{ required: true, minValue:0 }"
          v-slot="v"
        >
          <b-form-group label="Elevação" class="col-sm-4">
            <b-form-input
              name="elevation"
              v-model="form.location.ele"
              type="number"
              min="0"
              :state="$validateState(v)"
            ></b-form-input>
            <span class="invalid-input">{{ v.errors[0] }}</span>
          </b-form-group>
        </ValidationProvider>

        <ValidationProvider
          slim
          vid="lat"
          name="Latitude"
          :rules="{ required: true, between:[-90,90] }"
          v-slot="v"
        >
          <b-form-group label="Latitude" class="col-sm-4">
            <b-form-input
              name="latitude"
              v-model="form.location.lat"
              type="number"
              min="-90"
              max="90"
              :state="$validateState(v)"
            ></b-form-input>
            <span class="invalid-input">{{ v.errors[0] }}</span>
          </b-form-group>
        </ValidationProvider>

        <ValidationProvider
          slim
          vid="long"
          name="Longitude"
          :rules="{ required: true, between:[-180,180] }"
          v-slot="v"
        >
          <b-form-group label="Longitude" class="col-sm-4">
            <b-form-input
              name="longitude"
              v-model="form.location.lon"
              type="number"
              min="-180"
              max="180"
              :state="$validateState(v)"
            ></b-form-input>
            <span class="invalid-input">{{ v.errors[0] }}</span>
          </b-form-group>
        </ValidationProvider>
      </b-row>
    </validation-observer>

    <div>
      <div>Selecione posição da estação no mapa</div>
      <!-- <simpleMap></simpleMap> -->
      <vl-map
        style="height: 400px"
        :load-tiles-while-animating="true"
        :load-tiles-while-interacting="true"
        data-projection="EPSG:4326"
        ref="map"
        @singleclick="onMapSingleClick"
        @dblclick="onMapSingleClick"
        @mounted="onMapMounted"
      >
        <vl-view
          ref="view"
          :maxZoom="19"
        ></vl-view>

        <vl-layer-vector :z-index="3">
          <vl-source-vector ref="objSource">
            <stationMap :station="form" />
          </vl-source-vector>
        </vl-layer-vector>
        <!-- <vl-layer-tile :z-index="0">
          <vl-source-osm></vl-source-osm>
        </vl-layer-tile>-->

        <vl-layer-tile
          :z-index="1"
          v-for="layer in baseLayers"
          :key="layer.source"
          :id="layer.source"
          :visible="layer.visible"
        >
          <component :is="'vl-source-' + layer.source" v-bind="layer"></component>
        </vl-layer-tile>
      </vl-map>
    </div>

    <template v-slot:modal-footer>
      <b-button @click="submit" variant="primary">Salvar</b-button>
      <b-button @click="cancel">Cancelar</b-button>
    </template>
  </b-modal>
  <!-- <b-button type="submit">Salvar</b-button>
  <b-button type="reset">Cancelar</b-button>-->
</template>

<script>
import stationMap from "./stationMap";
import map from "@components/map/map";

export default {
  mixins: [map],
  data() {
    return {
      clientList :[],
      form: { name: "", description: "", stages: [] },
       stationDataList: [
        { value: 'manual',     text: "Manual" },
        { value: "kalliandra", text: "Kalliandra Station" },
        { value: "inmet",      text: "Inmet Station" }
      ]
    };
  },

  props: {
    stationId: { type: String, required: false },
    stationList: Array
  },

  mounted() {
    if (this.stationId !== undefined) {
      let form = this.stationList.find(ele => this.stationId === ele.id);
      if (form != undefined) this.form = JSON.parse(JSON.stringify(form));
    }

    if (this.form.location == undefined) {
      this.$set(this.form, "location", { lon: 0, lat: 0, ele:0 });
    }

    if (this.form.client == undefined) {
      this.$set(this.form, "client",  this.$store.getters["accounts/clientId"]);
    }


    this.loadClientList();

    this.$refs["edit-modal"].show();
  },

  components: {
    stationMap
  },

  computed: {
    selectList() {
      let list = [];
      this.stationList.forEach(function(v) {
        list.push({ value: v, text: v.name });
      });
      return list;
    }
  },

  methods: {
    close() {
      this.$router.go(-1);
    },

    importData(v) {
      this.form.stages = JSON.parse(JSON.stringify(v.stages));
      this.form.icon = JSON.parse(JSON.stringify(v.icon));
    },

    loadClientList(){
      this.$http.get("/account/client/maplist").then(
        (data) => {
          this.clientList = data.map(function(v){
            return { value: v[0], text: v[1] }
          }
           );
         
        },
        (error) => {
          console.log("load client error:", error);
        }
      ); 
    },

    submit() {
      this.$refs.formObserver.validate().then(result => {
        if (!result) {
          return;
        }

        let stationData = JSON.parse(JSON.stringify(this.form));
        this.$http.save("irrig/station", stationData,this.stationId).then(
          data => {
            this.$emit("update:station", data);
            this.$refs["edit-modal"].hide();
            this.$router.push({ name: "stationDetails", params: { id: data.id } });
          },
          error => {
            this.$refs.formObserver.setErrors(this.$validServer(error));
            this.$bvModal.msgBoxOk(
              "Verifique se todos os dados estão corretos",
              {
                title: "Erro!"
              }
            );
          }
        );
      });
    },
    cancel() {
      this.$refs["edit-modal"].hide();
    },

    onMapSingleClick(evt) {
      if (evt.coordinate != undefined) {
        this.form.location.lon = evt.coordinate[0];
        this.form.location.lat = evt.coordinate[1];
      }
    }
    // onSubmit(evt) {
    //   evt.preventDefault();
    //   console.log("submit")
    //   alert(JSON.stringify(this.form));
    // },
    // onReset(evt) {
    //   evt.preventDefault();
    // }
  }
};
</script>
