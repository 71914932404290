<template>
  <div>
    <div v-if="loadStatus == 'loaded'">
      <genericChart
        title="Pressão na ponta"
        color="#800"
        :serie="summary.pressurePositionData"
        unit="mca"
        name="pressão"
        height="300px"
        :yminValue="3"
      />

      <genericChart
        v-if="$can('read', 'Master')"
        title="velocidade"
        color="#43A"
        :serie="summary.velocityPositionData"
        unit="º/h"
        name="Velocidade angular"
        height="300px"
      />
    </div>
    <c-loading v-else :status="loadStatus"></c-loading>
  </div>
</template>

<script>
import { DateTime } from "luxon";

// import pivotController from "./pivotController";
import genericChart from "@kl-framework/charts/genericChart";

export default {
  data() {
    return {
      summary: undefined,
      loadStatus: "loading",
      dateRange: null,
    };
  },

  props:{
      pivot:Object
  },

  mounted() {
    let end = DateTime.local()
      .endOf("day")
      .toJSDate();
    let start = DateTime.local()
      .plus({ hours: -this.pivot.defaultPeriod })
      .toJSDate();
    this.dateRange = [start, end];
    this.loadSummary();
  },

  methods: {
    loadSummary() {
      this.loadStatus = "loading";
      this.$http.get("sens/sensor/" + this.pivot.id + "/summary").then(
        (data) => {
          this.summary = data;
          this.loadStatus = "loaded";
        },
        (error) => {
          console.error("load pivot sensor error:", error);
          this.loadStatus = "error";
        }
      );
    },
  },

  components: {
    genericChart,
  },
};
</script>

<style></style>
