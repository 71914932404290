<template>
  <v-chart
    v-if="drawChart"
    ref="chart"
    :style="{ width: width, height: height }"
    :option="chartOptions"
    :theme="dark ? darkTheme : null"
    autoresize
  />
</template>

<script>
import { DateTime } from "luxon";
import darkTheme from "@kl-framework/charts/themes/dark.js";



var dateTimeShortFormatter = function(value) {
  return DateTime.fromMillis(value.value).toLocaleString(
       {day: '2-digit', month: '2-digit', year: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}
  );
};

export default {
  props: {
    series: Array,
    serie: Array,
    name: String,
    unit: { type: String, default: "" },
    ymaxValue: Number,
    yminValue: [Number,String],
    title: String,
    color: String,
    type: { type: String, default: "line" },
    width: { type: [String, Number], default: "100%" },
    height: { type: [String, Number], default: "180px" },
    minDate: { type: Date },
    maxDate: { type: Date },
    dark: { type: Boolean, default: false },
    dateFormatter: { type: Function, default: undefined },
    maxMinPoint:{type:Boolean, default: false},
    toFixed:{type:Number,default:2}
  },

  data() {
    return {
      darkTheme: darkTheme,
      drawChart:false
    };
  },
  mounted(){
    this.drawChart = true
  },
  computed: {
    chartOptions: function() {
      let self = this;

      let chartType = this.type;
      let areaStyle = null;

      if (chartType == "area") {
        chartType = "line";
        areaStyle = {};
      }

      let dataSeries = [];

      if (this.series) {
        dataSeries.concat(this.series);
      }

      if (this.serie != undefined) {

        let marpoint = undefined

        if(this.maxMinPoint){
          marpoint = {
              data: [
                { type: 'max', name: 'Max' },
                { type: 'min', name: 'Min' }
              ]
            }
        }

        dataSeries.push({
          yAxisIndex: 0,
          // name: this.name,
          data: this.serie.map((item) => [item.time, item.value.toFixed(this.toFixed)]),
          type: chartType,
          symbol: "none",
          color: this.color,
          areaStyle: areaStyle,
          markPoint: marpoint
        });
      }

      return {
        title: {
          left: "center",
          textAlign: "auto",
          text: self.title,
        },
        grid: [{ left:2, right: 2, bottom: 50, top: 50,containLabel: true }],
        legend: {
          bottom: 0,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: { type: "cross", crossStyle: { color: "#999" } },
        },
        dataZoom: [
          {
            type: "inside",
            show: true,
            realtime: true,
            start: 0,
            end: 100,
          },
        ],
        xAxis: [
          {
            min: this.minDate || null,
            max: this.maxDate || null,
            type: "time",
            axisPointer: {
              label: {
                formatter: (this.dateFormatter)?this.dateFormatter:dateTimeShortFormatter
              },
            },

            // time: {
            //   displayFormats: {
            //     day: "MMM DD",
            //   },
            // },
            // display: true,
            // scaleLabel: {
            //   display: false,
            //   labelString: "Data/hora",
            // },
          },
        ],
        yAxis: [
          {
            name: self.unit,
            type: "value",
            max: self.ymaxValue,
            min: self.yminValue,
            axisLabel: {
              formatter: function(val) {
                return val;
              },
            },
            splitArea: { show: false },
            ticks: {
              beginAtZero: false,
            },
          },
        ],
        series: dataSeries,
      };
    },
  },
};
</script>

<style></style>
