<template>
    <canvas :style="{ width: width, height: height }" :height="height"  ref="canvas"></canvas>
</template>
<script>

import {createHistogram, clipMask, drawHistogram } from "./imageUtils"


export default {

    data() {
        return {
            ctx: undefined,
            canvas: undefined,
            histogram: []
        }
    },


    props: {
        src: { type: String },
        width: { type: String, default: "100%" },
        height: { type: String, default: "100%" },
        maxValue: { type: Number, default: 1 },
        minValue: { type: Number, default: 0 },
        colorPalette: { type: Array, requerid: true },
        mask: { type: Array },
        legendColor:{type:String, default:"white"}
    },


    mounted() {
        this.canvas = this.$refs.canvas
        this.ctx = this.canvas.getContext('2d');
        this.loadImage()
    },

    watch: {
        colorPalette() {
            this.draw()
        },
        maxValue() {
            this.draw()
        },
        minValue() {
            this.draw()
        },
        src() {
            this.loadImage()
        },
        mask() {
            this.loadImage()
        }
    },

    methods: {
        loadImage() {

            let mask = this.mask
            // console.log("loading image")
            let img = new Image;
            img.crossOrigin = "anonymous"
            img.onload = () => {
                // console.log("image loaded")

                var canvas = document.createElement("canvas");
                canvas.width = img.width;
                canvas.height = img.height;
                var ctx = canvas.getContext("2d");
                if (mask != undefined) {
                    // console.log("create mask")
                    clipMask(ctx, mask, canvas.width, canvas.height)
                }
                ctx.drawImage(img, 0, 0);
                const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);

                this.histogram = createHistogram(imageData)
                this.draw()
            };
            img.src = this.src;


        },
        draw() {
            this.ctx.fillStyle = "transparent";
            this.ctx.clearRect(0, 0,  this.canvas.width, this.canvas.height);
            drawHistogram(
                this.colorPalette,
                this.ctx,
                this.canvas.width,
                this.canvas.height - 20,
                this.minValue,
                this.maxValue,
                this.histogram,
                0,
                this.legendColor
            )
        }
    }

}
</script>
<style lang="">
    
</style>