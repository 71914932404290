<template>
     <b-modal
      ref="client-select"
      scrollable
      size="lg"
      title="Selecionar cliente"
      hide-footer
    >
      <b-form-group label="Pesquisar">
        <b-form-input
          autofocus
          ref="client-filter-imput"
          autocomplete="off"
          name="client-filter"
          v-model="clientfilter"
          type="text"
          size="sm"
        ></b-form-input>
      </b-form-group>

      <div class="mb-2 text-right" v-if="stateList.length>1">
          <b-form-checkbox-group   size="sm" plain
            v-model="statesSelected"
            :options="stateList"
            ></b-form-checkbox-group>
        </div>
 

      <div
        id="scrollspy-nested"
        style="position: relative; overflow-y: auto"
      >
        <b-list-group v-if="client">
          <b-list-group-item 
            class="client-item"
            href="#"
            v-for="cl,index in clientsfiltered"
            :key="cl.id"
            @click="changeClient(cl)"
            :active="cl.id===client.id"
          > 
            <kli-farm /> 
            <span class="index"> {{ index+1 }} </span>
            <span class="name"> {{ cl.name }} </span>
            <span class="state"> {{ cl.state }} </span>
            
          </b-list-group-item>
        </b-list-group>
      </div>
    </b-modal>
</template>
<script>


import { mapGetters } from "vuex";

const sortClients = function(a, b){
  const nameA = a.name.toUpperCase(); // ignore upper and lowercase
  const nameB = b.name.toUpperCase(); // ignore upper and lowercase
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
}




export default {

    data() {
        return {
            clientfilter: "",
            statesSelected:[]
        }
    },


    methods: {
        changeClient(cl) {
            this.$store.dispatch("accounts/changeClient", cl.id);
        },
        show(){
            this.$refs["client-select"].show();
        }
    },

    mounted(){
        this.statesSelected = this.stateList
    },

    computed: {

        ...mapGetters({
            client: "accounts/client",
            clients: "accounts/clients",
        }),

        stateList(){
            let states = []
            this.clients.forEach(client => {
                let clientState = client.state.toUpperCase()
                let index = states.findIndex((item) => item === clientState);
                if (index === -1) {
                    states.push(clientState);
                } 
            });
            return states
        },


        clientStateFiltered(){
            let stateList = this.statesSelected
            return this.clients.filter(function (client) {
                return stateList.includes(client.state.toUpperCase())
            })
        },

        clientsfiltered() {
            //procura por texto inciado por
            // let regexStr = "^" + this.filter+"";
            let regexStr = "(w*" + this.clientfilter + "w*)";
            try {
                let regex = new RegExp(regexStr, "i");
                return this.clientStateFiltered.filter(function (client) {
                    return regex.test(client.name)
                }).sort(sortClients);
            } catch (e) {
                return [];
            }
        },
    }

}
</script>
<style lang="scss" scoped>

.client-item{

    display: flex;
    flex-wrap: wrap;
    .index{
        margin-right: 1rem;
        margin-left: 0.5rem;
    }
    .name{
        margin-right: 1rem;
    }

    .state{
        font-weight: bold;
        margin-left: auto;
    }

}
    
</style>