<template>
  <b-modal no-fade hide-footer scrollable ref="details-modal" @hidden="close" size="lg">
    <template v-slot:modal-title>
      <span>Detalhes do Grupo</span>
    </template>

    <div v-if="loadStatus=='loaded'">
      <div class="row workspace-profile" v-if="!edit">
        <div class="col-lg-6">
          <div>
            <b-button-group size="sm" >
              <b-button
                v-if="$can('edit','Workspace')"
                v-b-tooltip.hover
                title="Editar Grupo"
                variant="primary"
                @click="edit=true"
              >
                <font-awesome-icon icon="edit" />
              </b-button>
              <b-button
                v-if="$can('remove','Workspace')"
                v-b-tooltip.hover
                title="Remover Grupo"
                variant="danger"
                @click="$emit('remove-workspace',workspace.id)"
              >
                <font-awesome-icon icon="trash" />
              </b-button>
            </b-button-group>
          </div>

          <p class="mt-3">
            <i>{{workspace.description}}</i>
          </p>
        </div>

        <div class="col-lg-6">
          <ul class="list-group list-group-unbordered">
            <li class="list-group-item">
              <b>Nome</b>
              <span>{{workspace.name}}</span>
            </li>
            <li class="list-group-item">
              <b>Subtitulo</b>
              <span>{{workspace.subtitle}}</span>
            </li>
            <li class="list-group-item">
              <b>Telefone</b>
              <span>{{workspace.phone}}</span>
            </li>
            <li class="list-group-item">
              <b>email</b>
              <span>{{workspace.email}}</span>
            </li>

            <li class="list-group-item">
              <b>Membros</b>
                <div class="d-flex flex-wrap flex-reverse justify-content-end">
                  <div class="mr-1" v-for="member in workspace.members" :key="member.id">
                    <userSymbol :user="member.user" />
                  </div>
                </div>
       
            </li>
          </ul>
        </div>

        <div class="col-lg-12"></div>
      </div>

      <div v-else>
        <workspaceEdit
          :workspace="workspace"
          v-on:update-workspace="updateWorkspace($event)"
          v-on:editcancel="edit=false"
        />
      </div>
    </div>

    <c-loading v-else :status="loadStatus"></c-loading>
  </b-modal>
</template>

<script>
import workspaceEdit from "./workspaceEdit";
import userSymbol from "../avatar/userSymbol";

export default {
  data() {
    return {
      edit: false,
      workspace: undefined,
      loadStatus: "loading"
    };
  },
  props: {
    workspaceId: { type: String, required: true }
  },
  components: {
    workspaceEdit,
    userSymbol
  },

  mounted() {
    this.$refs["details-modal"].show();
  },

  beforeMount() {
    if (this.workspaceId !== undefined) {
      this.loadWorkspace();
    }
  },
  methods: {
    loadWorkspace() {
      this.loadStatus = "loading";
      this.$http.get("account/workspace/" + this.workspaceId).then(
        data => {
          this.workspace = data;
          this.loadStatus = "loaded";
        },
        error => {
          console.error("load workspace error",error)
          this.loadStatus = "error";
        }
      );
    },

    updateWorkspace(workspace) {
      this.workspace = workspace;
      this.$emit("update-workspace", workspace);
    },

    close() {
      this.$router.push({ name: "workspaceList" });
    }
  }
};
</script>


<style lang="scss" scoped>
.workspace-profile {
  .profile-workspacename {
    font-size: 21px;
    margin-top: 5px;
  }

  .list-group {
    // padding-left: 0;
    margin-bottom: 20px;

    .list-group-item {
      border-left: 0;
      border-right: 0;
      border-radius: 0;
      padding-left: 0;
      padding-right: 0;

      span{
        float: right;
        padding-right: 5px;
        color: #3c8dbc;
      }
    }
  }


}
</style>