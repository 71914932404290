import electricity from './electricityPage'


export default {
      path:'consumer-station/:unitId?/:month?',
      component:electricity,
      name:'electricityPage',
      meta: {authorize:{action:'read',resource:'ConsumerStation'}},
      props: (route) => ({unitId:route.params.unitId,month:route.params.month}),

    }