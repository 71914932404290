<template>
  <div>
    <b-row>
      <b-col lg="4">
        <img
          class="mr-2"
          :src="require('./assets/48/' + culture.icon + '.png')"
        />
        <span class="culture-name">{{ culture.name }}</span>
        <div class="py-2"> <i>{{ culture.description }}</i></div>
      </b-col>
      <b-col lg="8" >
        <div style="height: 150px;">
          <cultureStageChart :stages="culture.stages" />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import cultureStageChart from "./cultureStageChart";

export default {
  props: {
    culture: Object,
  },
  components: {
    cultureStageChart,
  },
};
</script>

<style lang="scss" scoped>
  .culture-name{
    font-size: 1.25rem;
  }

</style>

