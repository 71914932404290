<template>
  <div class="color-legend">
    <div
      class="color-item"
      v-for="(value, index) in values"
      :key="index"
      :style="{ backgroundColor: value.color }"
    >
      {{ value.value }} mm
    </div>
  </div>
</template>

<script>

import Color from 'color'

export default {
  props: {
    gradient: { type: Array, required: true },
    max: { type: Number, required: true },
    min: { type: Number, required: true },
    numColors: { type: Number, default: 6 },
  },
  computed: {
    values() {
      let resp = [];
      for (let i = this.numColors-1; i >=0 ; i--) {
        let normalized = i / (this.numColors - 1);
        let value = (this.max * normalized + this.min * (1 - normalized))

        if (value >= 100) value = value.toFixed(0);
        else if (value >= 10) value = value.toFixed(1);
        else value  = value.toFixed(2);

        resp.push({
          value: value,
          color: this.valueToColor(normalized),
        });
      }
      return resp;
    },
    arrayColors() {
      let cols = [];
      for (let i = 0; i < this.gradient.length; i++) {
        cols[i] = Color(this.gradient[i]).array();
      }

      return cols;
    },
  },
  methods: {
    valueToColor(value) {
      let cols = this.arrayColors
      let colRamp = (cols.length - 1) * value;
      let colFloor = colRamp >> 0;
      let minCol = cols[colFloor];
      let maxCol = minCol;
      if(colFloor < cols.length-1){
        maxCol = cols[colFloor + 1];
      }
      value = colRamp - colFloor;
      let inv_val = 1 - value;

      let r = minCol[0] * inv_val + maxCol[0] * value; //red
      let g = minCol[1] * inv_val + maxCol[1] * value; //green
      let b = minCol[2] * inv_val + maxCol[2] * value; //blue
    //   let a = minCol[3] * inv_val + maxCol[3] * value; //alpha
      return Color({r: r, g: g, b: b})
    },
  }
};
</script>

<style lang="scss" scoped>
.color-legend {
  border-radius: 2px;
  text-align: center;
  font-size: 0.8em;
  background-color: $black;
  padding: 5px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  .color-item {
    padding: 1px 5px;
    color: black;
  }
}
</style>
