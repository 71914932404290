<template>
  <v-chart style="width:100%;height:300px;" :option="serie" autoresize />
</template>

<script>
import { DateTime } from 'luxon';

export default {
  props: {
    weathers: Array,
    rains:Array
  },
  computed: {
    serie: function() {
      let weathers = this.weathers;
     
      return {
        // title: {
        //   text: "Histórico de climas"
        // },
        backgroundColor:"#fff",
        grid:[
            { left:30,  bottom: 50,containLabel: true},
        ] ,
        legend: {
          bottom: 0,
          data: [
            "Temp",
            "umidade",
            "radi",
            "vento",
            "chuva",
            "et0"
          ],
          selected: {
            umidade: false,
            radi: false,
            vento: false,
            chuva: true,
            et0: false
          }
        },
        tooltip: {
          trigger: "axis",
          axisPointer: { type: "cross", crossStyle: { color: "#999" } }
        },
        xAxis: {
          type: "time",
           axisPointer:{
            label:{
              formatter: function(value) {
                return DateTime.fromMillis(value.value).toLocaleString();
              },
            }
          },
        },
        yAxis: [
          { name: "ºC", type: "value", position: "left" },
          { name: "%", type: "value", position: "left", offset: 30 },
          { name: "MJ/dia", type: "value", position: "right", offset: 30 },
          { name: "m/s", type: "value", position: "right" },
          { name: "mm", type: "value", position: "right", offset: 60 }
        ],
        series: [

          {
            yAxisIndex: 0,
            name: "Temp",
            data: weathers.map(item => [item.date, item.tempMax]),
            type: "line",
            lineStyle: {
              color: '#f40',
              width: 1,
              type: 'dashed'
            },
            color:"#f40",
            //stack:'temp',
            // areaStyle: {
            //     color: new LinearGradient(0, 0, 0, 1, [
            //       {
            //         offset: 0,
            //         color: '#fa8'
            //       },
            //       {
            //         offset: 1,
            //         color: '#acf'
            //       }
            //     ])
            //   },
            symbol: "none",
          },

          {
            yAxisIndex: 0,
            name: "Temp",
            data: weathers.map(item => [item.date, item.tempAvg]),
            type: "line",
            symbol: "none",
            color:"#f40"
          },

          {
            yAxisIndex: 0,
            name: "Temp",
            data: weathers.map(item => [item.date, item.tempMin ]),
            type: "line",
            lineStyle: {
              color: '#f40',
              width: 1,
              type: 'dashed'
            },
            color:"#f40",
            //stack:'temp',
            symbol: "none",
          },
          
         

          {
            yAxisIndex: 1,
            name: "umidade",
            data: weathers.map(item => [item.date, item.humiRel]),
            type: "line",
            symbol: "none",
            color:"#08f"
          },
          {
            yAxisIndex: 2,
            name: "radi",
            data: weathers.map(item => [item.date, item.radi]),
            type: "line",
             symbol: "none",
            color:"#f91",
          },
          {
            yAxisIndex: 3,
            name: "vento",
            color:"#4a4",
            data: weathers.map(item => [item.date, item.velWind2m]),
            type: "line",
            symbol: "none",
          },
          {
            yAxisIndex: 4,
            name: "chuva",
            data: weathers.map(item => [item.date, item.rain]),
            type: "bar",
            areaStyle: {},
            color: "#00ace6",
          },
          {
            yAxisIndex: 4,
            name: "et0",
            data: weathers.map(item => [item.date, item.et0]),
            type: "line",
            color: "#ffaa00",
          }
        ]
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.echarts {
  width: 100%;
  height: 200px;
}
</style>