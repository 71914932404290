import store from '@/store'

import fieldPage from '@irrigcomponents/field/fieldPage'
import fieldPageDetails from '@irrigcomponents/field/fieldPageDetails'
import fieldPageEdit from '@irrigcomponents/field/fieldPageEdit'
import fieldHistoricalPage from '@irrigcomponents/field/fieldHistoricalPage'

 var field ={
    path: 'field/:id',
    component: fieldPage,
    // props: (route) => ({field: store.getters["equipments/getFieldById"](route.params.id)}),
    props: function(route){
        /** verifica se está passando pelo parâmetro o id do field. neste
         * caso pega o field na lista de fields.
         * caso esteja passando o id do equipamento vai criar um field novo
         * que aponta para o equipamento
         */
        let field = undefined;
        let equipment = undefined

        if(route.params.id != undefined){
            field = store.getters["equipments/getFieldById"](route.params.id);
        }else 
        
        if(route.params.equipmentId != undefined){
            equipment =  store.getters["equipments/getById"](route.params.equipmentId);
            if(equipment != undefined){
                if(equipment.type == 'Pivot'){
                    field = {equipmentId:route.params.equipmentId, type:"FieldPivot"}
                }else{
                    field = {equipmentId:route.params.equipmentId, type:"FieldArea"}
                }
            }
        }
        return  {field: field}
    },
    children: [
        {
            path: '',
            name: 'fieldDetails',
            component: fieldPageDetails,
        },
        {
            path: 'edit',
            name: 'fieldEdit',
            component: fieldPageEdit,
            meta: {authorize:{action:'edit',resource:'Field'}},
        },
        {
            path: '/equipment/:equipmentId/new-field/:cloneId?',
            name: 'fieldNew',
            component: fieldPageEdit,
            meta: {authorize:{action:'create',resource:'Field'}},
            props: (route) => ({cloneId:route.params.cloneId}),
            
        }
    ]
}


var fieldHistory = {
        path: '/fieldhistory/:id',
        name: 'fieldHistory',
        component: fieldHistoricalPage,
        props: (route) => ({fieldId:route.params.id})
}


export {field, fieldHistory}
