<template>
  <div class="markdown-text" v-html="rawHtml"></div>
</template>

<script>
import {marked} from "marked";
export default {
  props: {
    source: {type: String},
  },

  computed: {
    rawHtml() {
      if(this.source!= null){
        return marked(this.source, { breaks: true, smartLists: true });
      }else
        return ""
    },
  },
};
</script>

<style lang="scss">
.markdown-text {
  blockquote {
    border-left: 5px solid #ccc;
    margin: 0.5em 10px;
    padding: 0.25em 10px;
    color: #7f8fa4;
  }
  pre {
    display: block;
    padding: 9.5px;
    margin: 0 0 10px;
    font-size: 13px;
    line-height: 1.42857143;
    color: #333;
    word-break: break-all;
    word-wrap: break-word;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
    > thead > tr > th {
      vertical-align: bottom;
      border-bottom: 2px solid #ddd;
    }
  }
  p,ul{
    margin-bottom: 0.5rem;
  }

  img {
    max-width: 35%;
  }

  h1,h2{
    margin: 24px 0 16px;
    padding-bottom: 0.3em;
    border-bottom: 1px solid #eaeaea;
    color: #303030;
  }

  h1{
    font-size: 1.75em;
  }

  h2{
    font-size: 1.5em;
  }

  h3{
    margin: 24px 0 12px;
    font-size: 1.25em;
  }
}
</style>
