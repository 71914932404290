<template>
  <b-modal
    hide-footer
    @hidden="close"
    scrollable
    ref="sensor-modal"
     size="max-xl"
  >
    <template v-slot:modal-header>
      <div>
        <h5>{{ sensor.name }}</h5>
      </div>
      <button type="button" class="close" @click="close()">
        ×
      </button>
    </template>
    <sensorPageDetails v-if="sensor" :sensor="sensor" />
  </b-modal>
</template>

<script>
import sensorPageDetails from "./sensorPageDetails";
import sensor from "./sensor";
export default {
  extends: sensor,
  // props: {
  //   sensor: { type: Object, required: false },
  // },

  mounted() {
    this.$refs["sensor-modal"].show();
  },

  methods: {
    close() {
      this.$router.push({ name: "home" });
    },
  },
  components: {
    sensorPageDetails,
  },
};
</script>

