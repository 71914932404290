<template>
  <b-modal
    ref="object-edit-modal"
    title="Editar Unidade Consumidora"
    scrollable
    @hidden="close"
    size="lg"
  >
    <!-- <template v-slot:modal-header>
      <span>
        Editar
        <b>{{ consumerStationEdit.name }}</b>
      </span>
    </template> -->

    <validation-observer ref="formObserver">
      <b-row>
        <ValidationProvider
          slim
          vid="name"
          name="Nome"
          :rules="{ required: true, min: 3 }"
          v-slot="v"
        >
          <b-form-group label="Nome" class="col-md-6">
            <b-form-input
              name="Identificador"
              v-model="editableObject.title"
              type="text"
              maxlength="15"
              :state="$validateState(v)"
            ></b-form-input>
            <span class="invalid-input">{{ v.errors[0] }}</span>
          </b-form-group>
        </ValidationProvider>

        <ValidationProvider
          slim
          vid="concessionaire"
          name="Concessionária"
          :rules="{ required: true, min: 3 }"
          v-slot="v"
        >
          <b-form-group label="Concessionária" class="col-md-6">
            <b-form-input
              name="concessionaire"
              v-model="editableObject.concessionaire"
              type="text"
              maxlength="15"
              :state="$validateState(v)"
            ></b-form-input>
            <span class="invalid-input">{{ v.errors[0] }}</span>
          </b-form-group>
        </ValidationProvider>

        <ValidationProvider
          slim
          vid="number"
          name="Número de Registro"
          :rules="{ required: true, min: 3 }"
          v-slot="v"
        >
          <b-form-group label="Número de Registro" class="col-md-6">
            <b-form-input
              name="number"
              v-model="editableObject.number"
              type="text"
              maxlength="15"
              :state="$validateState(v)"
            ></b-form-input>
            <span class="invalid-input">{{ v.errors[0] }}</span>
          </b-form-group>
        </ValidationProvider>

        <ValidationProvider
          slim
          vid="clientName"
          name="Nome do Cliente"
          :rules="{ required: true, min: 3 }"
          v-slot="v"
        >
          <b-form-group label="Nome do Cliente" class="col-md-6">
            <b-form-input
              name="clientName"
              v-model="editableObject.clientName"
              type="text"
              maxlength="30"
              :state="$validateState(v)"
            ></b-form-input>
            <span class="invalid-input">{{ v.errors[0] }}</span>
          </b-form-group>
        </ValidationProvider>

        <ValidationProvider
          slim
          vid="reservado"
          name="Horário reservado"
          :rules="{ required: true, between: [0, 100] }"
          v-slot="v"
        >
          <b-form-group label="Horário Reservado" class="col-md-4">
            <b-input-group append="%">
              <b-input
                v-model="editableObject.reservado_goal"
                type="number"
                min="0"
                step="1"
                :state="$validateState(v)"
              />
            </b-input-group>
            <span class="invalid-input">{{ v.errors[0] }}</span>
          </b-form-group>
        </ValidationProvider>

        <ValidationProvider
          slim
          vid="ponta"
          name="Horário de Ponta"
          :rules="{ required: true, min: 0 }"
          v-slot="v"
        >
          <b-form-group label="Horário Ponta" class="col-md-4">
            <b-input-group prepend="R$">
              <b-input
                v-model="editableObject.ponta_goal"
                type="number"
                min="0"
                step="0.2"
                :state="$validateState(v)"
              />
            </b-input-group>
            <span class="invalid-input">{{ v.errors[0] }}</span>
          </b-form-group>
        </ValidationProvider>

        <ValidationProvider
          slim
          name="Energia Reativa"
          :rules="{ required: true, between: [0, 100] }"
          v-slot="v"
        >
          <b-form-group label="Energia Reativa" class="col-md-4">
            <b-input-group append="%">
              <b-input
                v-model="editableObject.reativa_goal"
                type="number"
                min="0"
                step="0.2"
                :state="$validateState(v)"
              />
            </b-input-group>
            <span class="invalid-input">{{ v.errors[0] }}</span>
          </b-form-group>
        </ValidationProvider>


        <ValidationProvider
          slim
          vid="contractedDemand"
          name="Demanda Contratada"
          :rules="{ required: true, min: 0 }"
          v-slot="v"
        >
          <b-form-group label="Demanda Contratada" class="col-md-4">
            <b-input-group append="kW">
              <b-input
                v-model="editableObject.contractedDemand"
                type="number"
                min="0"
                step="0.5"
                :state="$validateState(v)"
              />
            </b-input-group>
            <span class="invalid-input">{{ v.errors[0] }}</span>
          </b-form-group>
        </ValidationProvider>



        <input-select class="col-md-4"
          label="Mês aniversário"
    
       v-model="editableObject.birthday"
                        :options="[
                            {value:'JANUARY', text:'janeiro'},
                            {value:'FEBRUARY', text:'fevereiro'},
                            {value:'MARCH', text:'março'},
                            {value:'APRIL', text:'abril'},
                            {value:'MAY', text:'maio'},
                            {value:'JUNE', text:'junho'},
                            {value:'JULY', text:'julho'},
                            {value:'AUGUST', text:'agosto'},
                            {value:'SEPTEMBER', text:'setembro'},
                            {value:'OCTOBER', text:'outubro'},
                            {value:'NOVEMBER', text:'novembro'},
                            {value:'DECEMBER', text:'dezembro'}
                          ]" >
      </input-select>

      <b-form-group label="Gerção própria" class="col-md-4">
            <b-form-checkbox v-model="editableObject.ownGeneration" switch>
            Geração própria
          </b-form-checkbox>
        </b-form-group>

      </b-row>


    
      
      <input-select 
      label="Rateio de Custo"
      description="Forma de calcular o custo das parcelas.
       Proporcional: Divide o valor do custo da energia elétrica proporcionalmente entre os equipamentos de irrigação.
       Absoluto: Calcula o custo por equipamento a partir do cálculo de consumo"

       v-model="editableObject.calcCostType"
                        :options="[
                            {value:'ABSOLUTE', text:'Absoluto'},
                            {value:'PROPORTIONAL', text:'Proporcional'},
                          ]" >
      </input-select>
        



      




    </validation-observer>

    <template v-slot:modal-footer>
      <b-button :disabled="savingObject" @click="submit" variant="primary">
        Salvar
        <font-awesome-icon v-if="savingObject" icon="spinner" spin />
      </b-button>
      <b-button @click="close">Cancelar</b-button>
    </template>
  </b-modal>
</template>

<script>
import { formModalObject } from "@components/mixins/FormModalObject";

export default {
  mixins: [formModalObject],
  mounted() {
    this.objectUrl = "report/consumer-station";
  },
};
</script>

<style></style>
