<template>
  <v-chart class="echarts" :option="serie" :theme="theme" autoresize />
</template>

<script>
import { DateTime } from 'luxon';

export default {
  data: function() {
    return {
      theme: undefined, // chartTheme,
    };
  },
  props: {
    data: Array,
    unit:String,
  },
  computed: {
    serie: function() {
      let data = this.data;
      if (!data) return{};

      let series = [];

      data.forEach(element => {
        series.push({
          yAxisIndex: 0,
          name: element.depth + " cm",
          type: "line",
          data: element.dataPoints.map(e=>{return [e.ux*1000,e.v1]}),
          showSymbol: false
        });
      });

      return {
        title: { left: "center", padding: 1, textStyle: { color: "#fff" } },
        dataZoom: [
          {
            type: "inside",
            show: true,
            realtime: true,
            start: 0,
            end: 100,
          },
        ],
        legend: {
          show: true,
          bottom: 0,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: { type: "cross", crossStyle: { color: "#999" } },
          valueFormatter: (value) => (Number(value)?value.toFixed(2):value)+" "+this.unit,
        },
        grid: { left:0, bottom: 40, top:25, right: 0, containLabel: true },
        yAxis: [
          {
            scale: false,
            // min: this.emptyLevel,
            // max: this.fullLevel,
            axisLabel: { formatter: "{value} "+this.unit },
          },
        ],

        xAxis: {
          type: "time",
          axisPointer:{
            label:{
              formatter: function(value) {
                return DateTime.fromMillis(value.value).toLocaleString(DateTime.DATETIME_SHORT);
              },
            }
          },
        },
        series: series,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.echarts {
  width: 100%;
  height: 350px;
  background-color: #fff;
}
</style>
