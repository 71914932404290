import Vue from 'vue'

import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { required,
  min,
  max,
  between,
  max_value,
  min_value,
  email,
  integer,
  regex
 } from 'vee-validate/dist/rules';

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('validationObserver', ValidationObserver);
// extend('required', {
//   ...required,
//   message: 'This field is required'
// });
extend('required',required)
extend('min',min)
extend('max',max)
extend('between',between)
extend('maxValue',max_value)
extend('minValue',min_value)
extend('email',email)
extend('integer',integer)
extend('regex',regex)



extend('password_confirm', {
  params: ['target'],
  validate(value, { target }) {
    return value === target;
  },
  message: 'A senha de confirmação não confere.'
});


extend('verify_password', {
  validate: value => {
    var strongRegex = new RegExp("^(?=.*[A-Za-z])(?=.*\\d)[A-Za-z\\d]{8,}$");
    return strongRegex.test(value);
  },
  message: "A senha deve possuir ao menos 8 caracters com um número e uma letra",
});


extend('verify_username', {
  validate: value => {
    var strongRegex = new RegExp("(^[\\w]{2,})+\\.[\\w]{2,}$");
    return strongRegex.test(value);
  },
  message: "Nome de usuário inválido. Use o padrão : ****.***",
});



import {DateTime } from 'luxon';

extend('date_between', {
  params: ['target'],
  validate(value, { target }) {
    var valueDate = ((typeof value) == 'string' )    ?   DateTime.fromISO(value).toMillis() :value
    var minDate   = ((typeof target[0]) == 'string' )?   DateTime.fromISO(target[0]).toMillis() : target[0]
    var maxDate   = ((typeof target[1]) == 'string' )?   DateTime.fromISO(target[1]).toMillis() : target[1]
    return valueDate >= minDate && valueDate<=maxDate
  },
});


extend('date_before', {
  params: ['target'],
  validate(value, { target }) {
    if(!target) return true
    var valueDate = ((typeof value) == 'string' ) ? DateTime.fromISO(value).toMillis() :value
    var maxDate   = ((typeof target) == 'string' )? DateTime.fromISO(target).toMillis() : target
    return valueDate<maxDate
  },
});

extend('date_maxValue', {
  params: ['target'],
  validate(value, { target }) {
    if(!target) return true
    var valueDate = ((typeof value) == 'string' ) ? DateTime.fromISO(value).toMillis() :value
    var maxDate   = ((typeof target) == 'string' )? DateTime.fromISO(target).toMillis() : target
    return valueDate<=maxDate
  },
});

extend('date_minValue', {
  params: ['target'],
  validate(value, { target }) {
    if(!target) return true
    var valueDate = ((typeof value) == 'string' )  ? DateTime.fromISO(value).toMillis() :value
    var minDate   = ((typeof target) == 'string' ) ? DateTime.fromISO(target).toMillis() : target
    return valueDate>=minDate
  },
});


/***********************************************************
*  Validar resposta do servidor 
*/
Vue.prototype.$validServer = function( error ){
  let resp = {}
  error.response.data.forEach((e)=>{
     resp[e.field]=[e.defaultMessage]
   });
   return resp;
}