<template>
  <vl-style-box :z-index="zindex">
        <vl-style-icon
          :scale="1"
          :anchor="[0.5, 2.5]"
          :size="[24, 24]"
          :src="require('@/assets/sinal-wifi-green.png')"
        ></vl-style-icon>
      </vl-style-box>
</template>

<script>
export default {

    props:{
    zindex:{ type: Number, default:6}
  },

}
</script>

<style>

</style>