<template>
  <tr v-if="value!== undefined">
    <td class="id-r-line field-label">
      <!-- <b-link :to="{name:'fieldDetails',params:{id:field.id}}">{{field.name}}</b-link> -->
      <font-awesome-icon
        v-if="value.dataChecked!=true"
        class="text-warning"
        icon="exclamation-triangle"
      />
      <font-awesome-icon v-else class="text-success" icon="check-circle" />
      {{name}}
    </td>

    <!-- INPUT DE LAMINA -->
    <td class="td-input">
      <ValidationProvider :rules="{required: false,between:[0,300]}" v-slot="v">
        <b-input
          @input="irrigUpdate"
          type="number"
          min="0"
          step="0.1"
          v-model="irrig"
          size="sm"
          :state="$validateState(v)"
        />
      </ValidationProvider>
    </td>

    <!-- INPUT DE PERCENTIMETRO -->
    <td class="td-input">
      <ValidationProvider :rules="{required: false, between:[0,110]}" v-slot="v">
        <b-input
          @input="percentUpdate"
          :disabled="percentK==null"
          type="number"
          min="0"
          max="100"
          step="1"  
          v-model="percent"
          size="sm"
          :state="$validateState(v)"
        />
      </ValidationProvider>
    </td>

    <!-- INPUT DE TEMPO -->
    <td class="td-input id-r-line">
      <b-input
      v-if="!veryHighTime"
        @input="timeUpdate"
        :disabled="timeK==null"
        masked="true"
        v-mask="'##:##'"
        v-model="time"
        size="sm"
      />
      <b-input
        v-else
        disabled
        size="sm"
        value="> 100 horas"
      />

    </td>
    <td class="td-input">
      <b-select @change="flagUpdate" :options="flagOptions" v-model="flag" size="sm" />
    </td>
  </tr>
</template>

<script>
var hourToTime = function(v) {
  if (isNaN(v)) return null;

  const zeroPad = (num, places) => String(num).padStart(places, "0");
  let hour = Math.trunc(v);
  let min = Math.round(0.6 * ((v * 100) % 100));
  return zeroPad(hour, 2) + ":" + zeroPad(min, 2);
};

var timeToHour = function(s) {
  let v = s.split(":");
  let resp = Number(v[0]);
  if (v.length > 1) resp += v[1] * (1 / 60);
  return resp;
};

export default {
  data() {
    return {
      irrig: undefined,
      flag: undefined,
      percent: 0,
      time: 0,
      flagOptions: [
        { value: null, text: "" },
        { value: "germination",     text: "Germinação" },
        { value: "chemigation",     text: "Quimigação" },
        { value: "fertigation",     text: "Fertirrigação" },
        { value: "problem_power",   text: "Falta de energia" },
        { value: "problem",         text: "Problemas operacionais"}
      ],

      percentK: null,
      timeK: null,
      veryHighTime:false
    };
  },

  props: {
    value: Object,
    name:  String,
    field: Object
  },

  mounted() {
    
    if (this.field.volumeToTimeConstant != undefined) {
      this.timeK = Number(this.field.volumeToTimeConstant);
    }

    if (this.field.volumeToPercentConstant != undefined) {
      this.percentK = Number(this.field.volumeToPercentConstant);
    }

    if (this.value !== undefined) {
      if (this.value.dataChecked != true){
        this.$emit("onchange");
      }
      this.irrig = this.value.irrig;
      this.flag = this.value.irrigFlag;
      this.updateDataIrrig();
    }
  },


  watch: {
    irrig(v) {
      let value = JSON.parse(JSON.stringify(this.value))
      value.irrig = v;
      this.$emit("input",value); 
    },
    flag(v) {
      let value = JSON.parse(JSON.stringify(this.value))
      value.irrigFlag = v;
      this.$emit("input",value); 
    }
  },
  methods: {
    /** atualiza os valores de percentímetros e de tempo a partir
     * do volume de irrigação
     */
    updateDataIrrig(filter) {
      let irrig = Number(this.irrig);

      if (this.timeK != null && filter !== "time") {
        let hour = irrig * this.timeK;
        if (hour < 100){ 
          this.time = hourToTime(hour);
          this.veryHighTime = false;
        }else{
          this.veryHighTime = true;
        }
      }
      if (this.percentK != null && filter !== "percent") {
        if (irrig > 0) {
          this.percent = (this.percentK / irrig).toFixed(0);
        } else {
          this.percent = 0;
        }
      }
    },

    /**
     * Sinaliza que o valor de irrigação foram modificados
     */
    irrigUpdate() {
      //console.log("irrigUpdate");
      this.$emit("onchange");
      this.updateDataIrrig();
    },

    /**
     * Sinaliza que o valor de tempo foi modificado.
     * Se a constante de conversão de tempo for null,
     * não faz nada
     */
    timeUpdate() {
      //console.log("timeUpdate");
      if (this.timeK == null) return;

      if (this.time.length > 4) {
        let hour = timeToHour(this.time);
        this.irrig = (hour / this.timeK).toFixed(2);
        this.updateDataIrrig("time");
        this.$emit("onchange");
      }
    },

    /**
     * Sinaliza que o valor do percentímetro foi modificado. Se a constante de
     * conversão de percentimetro for null, não faz nada
     */
    percentUpdate() {
      //console.log("percentUpdate");
      if (this.percentK == null) return;
      let percent = Number(this.percent);

      if (percent >= 0) {
        this.$emit("onchange");

        if(percent==0){
          this.irrig = 0;
        }else
          this.irrig = (this.percentK / percent).toFixed(2);
          
        this.updateDataIrrig("percent");
      }
    },

    /**
     * Sinaliza que a Flag de irrigação foi modificada
     */
    flagUpdate() {
      this.$emit("onchange");
    }
  }
};
</script>

<style lang="scss" scoped>

.td-input {
  width: 150px;
}
</style>