<template>
  <div>

    <b-modal title="Removendo..." no-close-on-backdrop hide-header-close hide-footer v-model="removingArea">

      <div class="d-block text-center">
        <font-awesome-icon icon="gear" spin size="3x"/>
        <p>
          Removendo área e as imagens armazenadas..
        </p>
      </div>
    </b-modal>

    <router-view v-on:remove-area="removeArea($event)"> </router-view>

  </div>
</template>
  
<script>
export default {

  data(){
    return{
      removingArea:false
    }
  },  


  methods: {
    removeArea(id) {
      
      this.$deleteConfirm.show(
        "excluir-area",
        {excludeObjects:["Áreas NDVI","Imagens de NDVI"]}
      ).then((v) => {
        if (v == true) {
          this.removingArea = true
          this.$http.delete("/irrig/ndvi/area/" + id).then(
            () => {
              this.removingArea = false
              this.$router.push({ name: 'ndviPageList' })
            }
          )
        }
      })


    }
  }
}
</script>
  