

var contrastColor = '#bbb';
   var contrastColor2= 'rgba(255,255,255,0.1)';

   var axisCommon = function () {
       return {
           axisLine: {
               lineStyle: {
                   color: contrastColor
               }
           },
           axisTick: {
               lineStyle: {
                   color: contrastColor
               }
           },
           axisLabel: {
               textStyle: {
                   color: contrastColor
               }
           },
           splitLine: {
               lineStyle: {
                 //  type: 'dashed',
                   color: contrastColor2
               }
           },
           splitArea: {
               areaStyle: {
                   color: contrastColor
               }
           }
       };
   };


   var colorPalette = ['#3c8dbc', '#c23531','#2fc554','#d4a405', '#61a0a8','#749f83','#ca8622', '#bda29a','#6e7074', '#546570', '#c4ccd3'];
   var theme = {
       color: colorPalette,
     //  backgroundColor: '#333',
       tooltip: {
           axisPointer: {
               lineStyle: {
                   color: contrastColor
               },
               crossStyle: {
                   color: contrastColor
               }
           }
       },
       legend: {
           textStyle: {
               color: contrastColor
           }
       },
       textStyle: {
           color: contrastColor
       },
       title: {
           textStyle: {
               color: contrastColor
           }
       },
       toolbox: {
           iconStyle: {
               normal: {
                   borderColor: contrastColor
               }
           }
       },
       timeline: {
           lineStyle: {
               color: contrastColor
           },
           itemStyle: {
               normal: {
                   color: colorPalette[1]
               }
           },
           label: {
               normal: {
                   textStyle: {
                       color: contrastColor
                   }
               }
           },
           controlStyle: {
               normal: {
                   color: contrastColor,
                   borderColor: contrastColor
               }
           }
       },
       timeAxis: axisCommon(),
       logAxis: axisCommon(),
       valueAxis: axisCommon(),
       categoryAxis: axisCommon(),

       line: {
           symbol: 'circle'
       },
       graph: {
           color: colorPalette
       },
       gauge: {
           title: {
               textStyle: {
                   color: contrastColor
               }
           }
       }
   };
   theme.categoryAxis.splitLine.show = false;


   export default theme;
