<template>
  <b-container fluid>
    <b-row class="user-settings">
      <b-col sm="4" md="4" lg="3" xl="2" class="left-column">
        <div class="kl-sticky-top pt-1">
        <b-nav vertical >
          <h3>Sua conta</h3>
          <b-nav-item
            :active="$route.name === 'user-account'"
            :to="{ name: 'user-account' }"
            >Perfil</b-nav-item
          >
          <b-nav-item
            :active="$route.name === 'user-password'"
            :to="{ name: 'user-password' }"
            >Password</b-nav-item
          >

          <Can I="read" a="Client">
            <hr />
            <h3>Clientes</h3>
            <b-nav-item
              :active="$route.name === 'clientList'"
              :to="{ name: 'clientList' }"
              >Clientes</b-nav-item
            >
          </Can>

          <Can I="read" a="Member">
            <hr />
            <h3>Usuários</h3>
            <b-nav-item
              :active="$route.name === 'memberList'"
              :to="{ name: 'memberList' }"
              >Usuários</b-nav-item
            >
            <b-nav-item
              :active="$route.name === 'memberNew'"
              v-if="$can('create', 'Member')"
              :to="{ name: 'memberNew' }"
              >Novo Usuário</b-nav-item
            >
            <b-nav-item
              :active="$route.name === 'memberInvite'"
              v-if="$can('create', 'Invite')"
              :to="{ name: 'memberInvite' }"
              >Convites</b-nav-item
            >
          </Can>

          <Can I="read" a="Master">
            <hr />
            <h2>Master</h2>
            <h3>usuários</h3>
            <b-nav-item
              :active="$route.name === 'userList'"
              :to="{ name: 'userList' }"
              >Usuários</b-nav-item
            >

            <h3>Grupos</h3>
            <b-nav-item
              :active="$route.name === 'workspaceList'"
              :to="{ name: 'workspaceList' }"
              >Grupos</b-nav-item
            >
          </Can>
        </b-nav>
      </div>
      </b-col>

      <b-col sm="8" md="8" lg="9" xl="10">
        <router-view></router-view>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
$panel-bg-color: #f3f3f3;
$panel-text-color: #444;
$panel-activeBackgroundColor: #0069d9;
$panel-activeTextColor: #fff;

.user-settings {


  .left-column {
    background-color: $panel-bg-color;
    color: $panel-text-color;
    padding: 0 26px;
    // overflow-y: auto;

    hr {
      border-top: 1px solid darken($panel-bg-color, 10%);
      margin: 10px 0 10px 0;
    }

    h3,
    h2 {
      color: lighten($panel-text-color, 20%);
      font-family: "Open Sans", Helvetica, Arial, sans-serif;
      font-weight: 400;
      line-height: 13px;
      font-weight: 900;
      padding: 0 16px 3px 4px;
      margin-bottom: 8px;
      text-transform: uppercase;
      letter-spacing: 0.6px;
    }

    h3 {
      font-size: 11px;
    }

    h2 {
      font-size: 13px;
    }

    a {
      font-family: "Open Sans", Helvetica, Arial, sans-serif;
      font-size: 13px;
      font-weight: 700;
      line-height: 18px;
      display: block;
      cursor: pointer;
      margin-bottom: 2px;
      color: $panel-text-color;
      border-radius: 5px;

      &:hover {
        color: darken($panel-text-color, 20%);
        background-color: darken($panel-bg-color, 10%);
      }

      &.active {
        color: $panel-activeTextColor;
        background-color: $panel-activeBackgroundColor;
      }
    }
  }
}
</style>
