import Vue from "vue";

import { use } from "echarts/core";
import { CanvasRenderer,SVGRenderer } from "echarts/renderers";
import { BarChart, LineChart, LinesChart, CustomChart, PieChart } from "echarts/charts";
import {
  GridComponent,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  DataZoomComponent,
  ToolboxComponent,
  BrushComponent,
  PolarComponent,
  MarkPointComponent,
  MarkLineComponent,
  MarkAreaComponent,
  VisualMapComponent,
  AriaComponent
} from "echarts/components";
import { default as ECharts } from "vue-echarts";

use([
  GridComponent,
  SVGRenderer,
  CanvasRenderer,
  BarChart,
  LineChart,
  LinesChart,
  PieChart,
  CustomChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  DataZoomComponent,
  ToolboxComponent,
  BrushComponent,
  PolarComponent,
  MarkPointComponent,
  MarkLineComponent,
  MarkAreaComponent,
  VisualMapComponent,
  AriaComponent
]);

Vue.component("v-chart", ECharts);
