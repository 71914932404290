<template>
  <ValidationProvider slim :name="label" :rules="rules" v-slot="v">
    <b-form-group :label="label">

      <b-form-textarea
        :value="value"
        @input="$emit('input', $event)"
        :placeholder="label"
        :state="$validateState(v)"
      ></b-form-textarea>
      <span class="invalid-input">{{ v.errors[0] }}</span>
    </b-form-group>
  </ValidationProvider>
</template>

<script>
export default {
  name: "AreaInput",
  props: {
    rules: Object,
    label: { type: String, required: true },
    value: String,
  },
};
</script>

<style></style>
