<template>
  <div class="config-page">
    <div class="header">
      <h2>Novo usuário</h2>
      <small>
        <font-awesome-icon icon="info-circle"/>
        Criar um novo usuário no grupo
      </small>
    </div>

    <b-container>
      <userEdit url="/account/member" :user="user" v-on:update-user="updateUser($event)" v-on:editcancel="$router.push({name:'memberList'})"/>
    </b-container>
  </div>
</template>
<script>
import userEdit from "../user/userEdit";

export default {
  data() {
    return {
      user: {}
    };
  },
  components: {
    userEdit
  }
};
</script>

<style>
</style>