<template>
  <ValidationProvider slim :name="label" :rules="rules" v-slot="v">
    <b-form-group :label="label">

      <b-input
        type="number"
        :value="value"
        @input="$emit('input', $event)"
        :min="min"
        :max="max"
        :step="step"
        :state="$validateState(v)"
      ></b-input>
      <span class="invalid-input">{{ v.errors[0] }}</span>
    </b-form-group>
  </ValidationProvider>
</template>

<script>
export default {
  name: "TextInput",
  props: {
    rules: Object,
    label: { type: String, required: true },
    min:{type:Number, default:undefined},
    max:{type:Number, default:undefined},
    step:{type:Number, default:undefined},
    value:{type:[Number,String]}
  },
};
</script>

<style></style>
