import irrigation from './irrigation/routes'
import electrity from './electricity/routes'
import fieldsReport from './FieldsReport/routes'
import reportPage from './reportPage'


export default {
      path:'/report/',
      component:reportPage,
      // meta: {authorize:{action:'read',resource:'report'}},
      children:[
        irrigation,
        electrity,
        fieldsReport
      ]
    }