import socketStomp from "@kl-framework/socketStomp.js"; 

import appConfig from "@/appConfig.js";

const SITE_ADDR =
  process.env.NODE_ENV === "production" ? "" : appConfig.URL_TESTE;

export default function(store) {

 
  var DashboardServerTimeDiff = 0;
  var clientId_ = undefined;
  var reconnected = false;
  



  var updateAllSensor = function(){
    store.dispatch("sensors/updateSensors").then(
    // store.dispatch("sensors/loadSensors").then(
      () => {
        // console.log("** sensors update ***")
      },
      () => {
        // console.log("** sensors update error ***")
      }
    );
  }

  var skStomp = new socketStomp(SITE_ADDR+ "/sensorWS",
  function(){
    // console.log("***conectado***")

    if(reconnected){
      updateAllSensor();
    }

  },
  function(){
    reconnected = true;
    // console.log("***desconectado***")
  });

  





  /**
   * Calcula a diferenção entre a hora do cliente e a hora do servidor
   */
  var CalcDashboardServerTimeDiff = function(serverTime) {
    var d = new Date();
    var systemTime = d.getTime();
    DashboardServerTimeDiff = systemTime - serverTime;
  };

  /**
   * Atualiza ultimo horário de atualização
   */
  setInterval(function() {
    let d = new Date();
    let serverTime = d.getTime() - DashboardServerTimeDiff;

    if (store != null) {
      store.commit("sensors/setServerTime", serverTime);

      // store.commit('sensors/updateSensor',{angle:pos,id:"04fb4374-5e2a-4865-8023-54c9e1e81e7e"});
      // pos+=30;
    }
  }, 2000);

  /***********************************************************************
   * Recebe dados via socket
   */
  var receive = function(messageOutput) {
    // console.log(">>" + messageOutput.from + ": " + messageOutput.text);
    // console.log(">>",messageOutput.object);

    if (messageOutput.msgType === "sensors") {
      messageOutput.object.forEach(function(obj) {
        if (store != null) store.commit("sensors/updateSensor", obj);
        CalcDashboardServerTimeDiff(parseInt(messageOutput.serverTime));
      });
    }

    if (messageOutput.msgType === "sensor") {
      if (store != null)
        store.commit("sensors/updateSensor", messageOutput.object);
      // update(messageOutput.object);
      CalcDashboardServerTimeDiff(parseInt(messageOutput.serverTime));
    } else if (messageOutput.msgType === "serverTime") {
      CalcDashboardServerTimeDiff(parseInt(messageOutput.object));
    }
  };

  

  

  /******
   * Se inscreve em tópico
   */
  this.listen = function(clientId) {
    // console.log("listen")
    clientId_ = clientId;
    // console.log("subscribe",skStomp.connected)

    skStomp.unsubscribeAll();

    skStomp.subscribe("/topic/clientPanel/" + clientId_, function(
      messageOutput
    ) {
      // console.log(messageOutput.body);
      receive(JSON.parse(messageOutput.body));
    });


    //  skStomp.subscribe("/topic/Channel2", function(
    //   messageOutput
    // ) {
    //    console.log(messageOutput.body);
    // });

  };


}
