import Vue from 'vue'
import Router from 'vue-router'


import store from '@/store'

// import {defineRulesFor} from '@/config/ability'

/***********************************************************
*  routes
*/
//import overview from '@components/pages/overviewDesktop'

import loggedPage from '@components/pages/loggedPage'
import home from '@components/pages/home'
import info from '@components/pages/info'
import login from '@components/pages/login'
import joinGroup from '@components/pages/joinGroup'

// import teste1 from '@/testes/teste1'
// import teste2 from '@/testes/teste2'
 import teste2 from '@/testes/testeDrag'
//  import teste1 from '@/testes/testeLocation'




//fields
import {field,fieldHistory} from '@irrigcomponents/field/routes'

//ndvi
import ndvi from '@irrigcomponents/ndvi/routes'


//external API
import externalSensorApi from '@sensorModule/externalSensorApi/routes'

//sensor Info
import sensorInfo   from '@sensorModule/sensInfo/routes'

//sensor
 import sensorRoutes from '@sensorModule/sensors/routes'

 //Mapa de chuva
 import rainHeatMapRoutes from '@components/rainHeatMap/routes'

//feeds
import feedsRoutes from '@sensorModule/feedSens/routes'

//solos
import soilRoutes from '@irrigcomponents/soil/routes'

//equipmentos de irrigação
import equipmentRoutes from '@irrigcomponents/equipment/routes'

//culturas
import cultureRoutes from '@irrigcomponents/culture/routes'

//inserir dados
import insertRoutes from '@irrigcomponents/insertData/routes'

//Estações
import station from '@irrigcomponents/station/routes'

//Pluviometros
import pluvio from '@irrigcomponents/pluvio/routes'

//account
import account from '@accountcomponents/routes'

//Relatórios
import report from '@components/reportModule/routes'


Vue.use(Router)

const routes = [

  // {path:'/teste1',component:teste1, name:'teste1'},
  // {path:'/teste2',component:teste2, name:'teste2'},
  {path:'/testeDrag',component:teste2, name:'testeDrag'},

  { path: '/info', component: info, name: 'info',props: true },
  { path: '/signin', component: login, name: 'login',props: true },
  { path: '/join-group/:inviteid', component: joinGroup, name: 'joinGroup',
  props: (route) => ({inviteId:route.params.inviteid}),},

  {
    path: '', component: loggedPage,
    // meta: { requiresAuth: true },
    children: [
      {
        path: '/', component: home, name: 'home',
        children: [
          equipmentRoutes,
          field,
          fieldHistory,
          sensorRoutes
        ]
      },
      ndvi,
      soilRoutes,
      cultureRoutes,
      insertRoutes,
      station,
      pluvio,
      account,
      feedsRoutes,
      rainHeatMapRoutes,
      report,
      sensorInfo,
      externalSensorApi
      // user,
      // userSettings,
      // clientRountes,

    ]
  }
]

const router = new Router({
  routes // short for `routes: routes`
})


//verifica as autorizações e redireciona as rotas
router.beforeEach((to, from, next) => {

  const { authorize } = to.meta;
 
  if (authorize) {
    let ability = Vue.prototype.$ability;

      if(!ability.can(authorize.action||'read',authorize.resource)){
        if(authorize.redirect){
          return next({ name:authorize.redirect });
        }

        if(store.getters["accounts/status"]=='loaded'){
          return next(from.path)
        }
      }

      // if (!role) {
      //     // not logged in so redirect to login page with the return url
      //     // return next({ name: 'login', query: { returnUrl: to.path } });
      //     next({ name: 'login' });
      // }

      // // check if route is restricted by role
      // if (authorize.length && !authorize.includes(currentUser.role)) {
      //     // role not authorised so redirect to home page
      //     next({ name: 'home' });
      // }
  }

  next();
})


// router.afterEach((to, from) => {

// });


export default router;
