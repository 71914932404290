<template>
  <svg
    aria-hidden="true"
    focusable="false"
    data-prefix="fas"
    data-icon="hand-holding-seedling"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 576 512"
    class="kl-icon-svg"
  >
    <path
      fill="currentColor"
      d="M480 0h-64c-40.7 0-77.5 15.7-105.8 40.8 25.4 32 40.9 72.2 41.6 116C424.9 142 480 77.5 480 0zM256 160v112c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V160C320 71.6 248.4 0 160 0H96c0 88.4 71.6 160 160 160zm309.3 168.1c-11.8-10.7-30.2-10-42.6 0L430.3 402c-11.3 9.1-25.4 14-40 14H272c-8.8 0-16-7.2-16-16s7.2-16 16-16h78.3c15.9 0 30.7-10.9 33.3-26.6 3.3-20-12.1-37.4-31.6-37.4H192c-27 0-53.1 9.3-74.1 26.3L71.4 384H16c-8.8 0-16 7.2-16 16v96c0 8.8 7.2 16 16 16h356.8c14.5 0 28.6-4.9 40-14L564 377c15.2-12.1 16.4-35.3 1.3-48.9z"
      class=""
    ></path>
  </svg>
</template>

<script>
export default {};
</script>
