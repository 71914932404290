<template>
  <b-row>
    <b-col lg="12">
    <hr/>
      <div>Selecione posição do equipamento no mapa</div>
      <simple-map>
        <template v-slot:layer>
          <selectPosition v-model="equipment.location" />
        </template>
        <dripMap :equipment="equipment" />
        <template v-slot:no-fit-vectors>
          <clientMap :client="client" />
        </template>
      </simple-map>
    </b-col>
  </b-row>
</template>

<script>
import { mapGetters } from "vuex";
import map from "@components/map/map";
import dripMap from "./dripMap";
import selectPosition from "@components/map/selectPosition";
import clientMap from "@accountcomponents/client/clientMap";

export default {
  mixins: [map],
  data() {
    return {
      zoom: 2,
      center: [0, 0],
      features: [],
      equipment: undefined,
    };
  },
  props: {
    value: { type: Object, required: true },
  },
  watch: {
    equipment: {
      handler(val) {
        // this.$emit("input",JSON.parse(JSON.stringify(val)));
        this.$emit("input", Object.assign({}, this.value, val));
      },
      deep: true,
    },
  },
  beforeMount() {
    // this.equipment = JSON.parse(JSON.stringify(this.value))
    this.equipment = {
      location: this.value.location,
    };

    if (this.value.location == undefined)
      this.equipment.location = this.client.location
  },
  components: {
    dripMap,
    selectPosition,
    clientMap,
  },
  computed: {
    ...mapGetters({
      client: "accounts/client",
    }),
  },
  methods: {
    onMapSingleClick(evt) {
      if (evt.coordinate != undefined) {
        this.equipment.location.lon = evt.coordinate[0];
        this.equipment.location.lat = evt.coordinate[1];
      }
    },
    // eslint-disable-next-line no-unused-vars
    onMapPostCompose({ vectorContext, frameState }) {},
  },
  mounted() {
    if (this.equipment.location == undefined) {
      this.$set(this.equipment, "location", { lon: 0, lat: 0 });
    }
  },
};
</script>

<style></style>
