<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 110 130"
    role="presentation"
  >
    <g v-if="cultureParameters && layers" transform="matrix(1,0,0,1,0,20)">
      <!-- SOLO -->
      <path
        transform="matrix(1,0,0,1,0,-6.350)"
        sodipodi:nodetypes="ccccsc"
        style="fill:#916247;fill-opacity:1;stroke:#000000;stroke-width:1.41889;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
        d="M 0.70929689,1.3299116 V 99.289344 H 99.290542 V 1.3299116 c 0,0 -20.604725,-2.4604873 -34.057207,2.5328044 C 51.780838,8.8559948 18.087882,7.6574609 0.70929689,1.3299116 Z"
        id="soil"
      />

      <g transform="translate(-2.6124586,2.6450327)" inkscape:label="marcas" id="g1189">
        <ellipse
          style="fill:#6c4734;fill-opacity:1;stroke:none;stroke-width:0.864938;stroke-linecap:round;stroke-miterlimit:4;stroke-dasharray:none"
          id="path1159"
          cx="69.676697"
          cy="40.341621"
          rx="4.282258"
          ry="3.3498604"
        />
        <ellipse
          ry="3.3498604"
          rx="4.282258"
          cy="62.742336"
          cx="59.164604"
          id="ellipse1161"
          style="fill:#af795c;fill-opacity:1;stroke:none;stroke-width:0.864938;stroke-linecap:round;stroke-miterlimit:4;stroke-dasharray:none"
        />
        <ellipse
          style="fill:#ae7456;fill-opacity:1;stroke:none;stroke-width:0.864938;stroke-linecap:round;stroke-miterlimit:4;stroke-dasharray:none"
          id="ellipse1163"
          cx="27.926971"
          cy="30.604654"
          rx="4.282258"
          ry="3.3498604"
        />
        <ellipse
          ry="3.3498604"
          rx="4.282258"
          cy="61.435417"
          cx="13.074226"
          id="ellipse1165"
          style="fill:#b07b5d;fill-opacity:1;stroke:none;stroke-width:0.864938;stroke-linecap:round;stroke-miterlimit:4;stroke-dasharray:none"
        />
        <ellipse
          style="fill:#b37c61;fill-opacity:1;stroke:none;stroke-width:0.864938;stroke-linecap:round;stroke-miterlimit:4;stroke-dasharray:none"
          id="ellipse1167"
          cx="33.469288"
          cy="76.97435"
          rx="4.282258"
          ry="3.3498604"
        />
        <ellipse
          ry="2.1646628"
          rx="2.7039247"
          cy="77.179245"
          cx="75.200394"
          id="ellipse1169"
          style="fill:#ba8a70;fill-opacity:1;stroke:none;stroke-width:0.552493;stroke-linecap:round;stroke-miterlimit:4;stroke-dasharray:none"
        />
        <ellipse
          style="fill:#7c533c;fill-opacity:1;stroke:none;stroke-width:0.864938;stroke-linecap:round;stroke-miterlimit:4;stroke-dasharray:none"
          id="ellipse1171"
          cx="75.397743"
          cy="17.396881"
          rx="4.282258"
          ry="3.3498604"
        />
        <ellipse
          style="fill:#7c523c;fill-opacity:1;stroke:none;stroke-width:1.39461;stroke-linecap:round;stroke-miterlimit:4;stroke-dasharray:none"
          id="ellipse1173"
          cx="31.631969"
          cy="54.760029"
          rx="6.6188731"
          ry="5.6344838"
        />
        <ellipse
          ry="1.7701993"
          rx="3.6596844"
          cy="8.4180746"
          cx="20.770214"
          id="ellipse1175"
          style="fill:#5c3d2c;fill-opacity:1;stroke:none;stroke-width:0.581257;stroke-linecap:round;stroke-miterlimit:4;stroke-dasharray:none"
        />
        <ellipse
          ry="5.6344838"
          rx="6.6188731"
          cy="54.189396"
          cx="91.27034"
          id="ellipse1177"
          style="fill:#7c523c;fill-opacity:1;stroke:none;stroke-width:1.39461;stroke-linecap:round;stroke-miterlimit:4;stroke-dasharray:none"
        />
        <ellipse
          ry="3.3498604"
          rx="4.282258"
          cy="17.255169"
          cx="40.277348"
          id="ellipse1191"
          style="fill:#6c4734;fill-opacity:1;stroke:none;stroke-width:0.864938;stroke-linecap:round;stroke-miterlimit:4;stroke-dasharray:none"
        />
        <ellipse
          style="fill:#6c4734;fill-opacity:1;stroke:none;stroke-width:0.864938;stroke-linecap:round;stroke-miterlimit:4;stroke-dasharray:none"
          id="ellipse1193"
          cx="90.544678"
          cy="83.136841"
          rx="4.282258"
          ry="3.3498604"
        />
      </g>

      <g transform="matrix(1,0,0,1,50,0)" v-if="drawPlant">
        <!-- FOLHAGEM -->
        <g
          v-if="cultureParameters.shadedArea>30"
          transform="matrix(0.31486917,0,0,0.31486859,-34.283218,-27.636667)"
          id="folha1"
        >
          <path
            inkscape:label="folha"
            style="fill:#84cf53;fill-opacity:1;stroke:#000000;stroke-width:1.4;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
            d="m 121.07472,33.350396 c 34.00964,29.441493 50.74166,-40.7514252 42.80219,-50.192766 -7.93947,-9.441341 -76.811831,20.7512731 -42.80219,50.192766 z"
            id="folha_1"
            sodipodi:nodetypes="zsz"
          />
          <path
            inkscape:label="talo"
            style="fill:none;stroke:#000000;stroke-width:3.665;stroke-linecap:round;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
            d="M 151.96618,-7.0433937 C 120.85902,22.780159 109.51315,51.148038 110.81041,85.939565"
            id="talo_1"
            sodipodi:nodetypes="cc"
          />
        </g>

        <!-- FOLHAGEM2 -->
        <g
          v-if="cultureParameters.shadedArea>15"
          transform="matrix(-0.23871893,0,0,0.20583354,27.052628,-13.898685)"
          id="folha2"
        >
          <path
            inkscape:label="folha"
            sodipodi:nodetypes="zsz"
            id="folha_2"
            d="m 121.07472,33.350396 c 34.00964,29.441493 50.74166,-40.7514252 42.80219,-50.192766 -7.93947,-9.441341 -76.811831,20.7512731 -42.80219,50.192766 z"
            style="fill:#6aab3f;fill-opacity:1;stroke:#000000;stroke-width:1.4;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
          />
          <path
            inkscape:label="talo"
            sodipodi:nodetypes="cc"
            id="talo_2"
            d="M 151.96618,-7.0433937 C 120.85902,22.780159 114.80554,38.560359 112.26482,65.428208"
            style="fill:none;stroke:#000000;stroke-width:3.665;stroke-linecap:round;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
          />
        </g>
      </g>

      <!-- RAIZ -->
      <g transform="translate(50 0)" v-if="drawPlant">
        <g
          v-for="(root, index) in rootData.roots"
          :key="index"
          :transform="'translate(0 '+root.pos+') scale('+root.scale+' '+root.scale+')' "
        >
          <path
            d="m 0.27455148,0 a 0.89480388,0.86015999 0 0 0 -0.893992,0.8593802 v 3.6499114 a 0.89480388,0.86015999 0 0 0 0,0.382923 v 3.7847873 a 0.89480388,0.86015999 0 0 0 -0.301581,0.212907 L -4.1636945,12.394093 h -5.211822 a 0.55047772,0.52916502 0 0 0 -0.54833,0.529167 0.55047772,0.52916502 0 0 0 0.54833,0.529168 h 4.232354 l -4.630161,5.003835 a 0.89480388,0.86015999 0 0 0 0.07311,1.214915 0.89480388,0.86015999 0 0 0 1.263846,-0.07028 l 3.246435,-3.508318 v 4.449864 a 0.55047772,0.52916502 0 0 0 0.551019,0.529168 0.55047772,0.52916502 0 0 0 0.55048,-0.529168 v -5.640491 l 3.46899298,-3.748613 v 13.626061 a 0.89480388,0.86015999 0 0 0 0.893992,0.861447 0.89480388,0.86015999 0 0 0 0.89399302,-0.861447 V 6.9845962 l 2.9195879,3.1548508 v 5.64049 a 0.55047772,0.52916502 0 0 0 0.5510181,0.529168 0.55047772,0.52916502 0 0 0 0.5504802,-0.529168 v -4.45038 l 3.2469731,3.508834 a 0.89480388,0.86015999 0 0 0 1.263309,0.07028 0.89480388,0.86015999 0 0 0 0.073111,-1.214914 L 5.1428618,8.6899209 H 9.3752155 A 0.55047772,0.52916502 0 0 0 9.9235453,8.1607535 0.55047772,0.52916502 0 0 0 9.3752155,7.6315861 H 4.1633932 L 1.1685445,4.3950866 V 0.8593801 A 0.89480388,0.86015999 0 0 0 0.27455148,0 Z"
            style="color:#000000;font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:medium;line-height:normal;font-family:sans-serif;font-variant-ligatures:normal;font-variant-position:normal;font-variant-caps:normal;font-variant-numeric:normal;font-variant-alternates:normal;font-variant-east-asian:normal;font-feature-settings:normal;font-variation-settings:normal;text-indent:0;text-align:start;text-decoration:none;text-decoration-line:none;text-decoration-style:solid;text-decoration-color:#000000;letter-spacing:normal;word-spacing:normal;text-transform:none;writing-mode:lr-tb;direction:ltr;text-orientation:mixed;dominant-baseline:auto;baseline-shift:baseline;text-anchor:start;white-space:normal;shape-padding:0;shape-margin:0;inline-size:0;clip-rule:nonzero;display:inline;overflow:visible;visibility:visible;opacity:1;isolation:auto;mix-blend-mode:normal;color-interpolation:sRGB;color-interpolation-filters:linearRGB;solid-color:#000000;solid-opacity:1;vector-effect:none;fill:#502d16;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.75462;stroke-linecap:round;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1;color-rendering:auto;image-rendering:auto;shape-rendering:auto;text-rendering:auto;enable-background:accumulate;stop-color:#000000;stop-opacity:1"
            id="raiz"
          />
        </g>
      </g>

      <!-- DIVISA SOLO -->
      <g v-for="(pos, index) in layersPos" :key="index" :transform="'translate(0 '+pos+')'">
        <path
          inkscape:label="divisa-solo"
          style="display:inline;fill:none;stroke:#e3dbdb;stroke-width:1.7636;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:1.7636, 3.52721;stroke-dashoffset:0;stroke-opacity:0.5"
          d="M -1.5e-4,0.8818 H 96.907094"
          id="path2987-7"
        />
      </g>

      <g transform="translate(80 0)" inkscape:label="cota" id="g1153" v-if="drawPlant">
        <path
          :transform="'translate(0 '+(rootData.cota-26.8)+')'"
          inkscape:label="setaBaixo"
          id="path1089"
          style="color:#000000;font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:medium;line-height:normal;font-family:sans-serif;font-variant-ligatures:normal;font-variant-position:normal;font-variant-caps:normal;font-variant-numeric:normal;font-variant-alternates:normal;font-variant-east-asian:normal;font-feature-settings:normal;font-variation-settings:normal;text-indent:0;text-align:start;text-decoration:none;text-decoration-line:none;text-decoration-style:solid;text-decoration-color:#000000;letter-spacing:normal;word-spacing:normal;text-transform:none;writing-mode:lr-tb;direction:ltr;text-orientation:mixed;dominant-baseline:auto;baseline-shift:baseline;text-anchor:start;white-space:normal;shape-padding:0;shape-margin:0;inline-size:0;clip-rule:nonzero;display:inline;overflow:visible;visibility:visible;isolation:auto;mix-blend-mode:normal;color-interpolation:sRGB;color-interpolation-filters:linearRGB;solid-color:#000000;solid-opacity:1;vector-effect:none;fill:#000000;fill-opacity:1;fill-rule:evenodd;stroke:none;stroke-width:0.2616pt;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1;color-rendering:auto;image-rendering:auto;shape-rendering:auto;text-rendering:auto;enable-background:accumulate;stop-color:#000000"
          d="m -1.5e-4,21.000029 1.660366,5.812574 1.65985,-5.812574 -1.65985,1.660366 z"
        />/>
        <path
          inkscape:label="setaCima"
          id="path1081"
          style="color:#000000;font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:medium;line-height:normal;font-family:sans-serif;font-variant-ligatures:normal;font-variant-position:normal;font-variant-caps:normal;font-variant-numeric:normal;font-variant-alternates:normal;font-variant-east-asian:normal;font-feature-settings:normal;font-variation-settings:normal;text-indent:0;text-align:start;text-decoration:none;text-decoration-line:none;text-decoration-style:solid;text-decoration-color:#000000;letter-spacing:normal;word-spacing:normal;text-transform:none;writing-mode:lr-tb;direction:ltr;text-orientation:mixed;dominant-baseline:auto;baseline-shift:baseline;text-anchor:start;white-space:normal;shape-padding:0;shape-margin:0;inline-size:0;clip-rule:nonzero;display:inline;overflow:visible;visibility:visible;isolation:auto;mix-blend-mode:normal;color-interpolation:sRGB;color-interpolation-filters:linearRGB;solid-color:#000000;solid-opacity:1;vector-effect:none;fill:#000000;fill-opacity:1;fill-rule:evenodd;stroke:none;stroke-width:0.2616pt;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1;color-rendering:auto;image-rendering:auto;shape-rendering:auto;text-rendering:auto;enable-background:accumulate;stop-color:#000000"
          d="M 1.660216,0 -1.5e-4,5.810507 1.660216,4.15014 3.320066,5.810507 Z"
        />
        <path
          style="fill:none;stroke:#000000;stroke-width:0.765001;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
          :d="'M 1.779295,2.248864 V '+rootData.cota"
          id="path1118"
        />

        <text
          transform="rotate(-90)"
          id="text1157"
          y="12.241982"
          x="-29.188293"
          style="font-style:normal;font-weight:normal;font-size:9.20621px;line-height:1.25;font-family:sans-serif;fill:#000000;fill-opacity:1;stroke:none;stroke-width:0.230157"
          xml:space="preserve"
        >
          <tspan
            style="stroke-width:0.230157"
            y="12.241982"
            :x="-19-rootData.cota/2"
            id="tspan1155"
            sodipodi:role="line"
          >{{ $filters.round(cultureParameters.rootDepth,1)}} cm</tspan>
        </text>
      </g>

      <!-- Stagio -->
      <text
        inkscape:label="estagio"
        id="text1197"
        y="103.34827"
        x="51.70948"
        style="font-style:normal;font-weight:normal;font-size:10.5833px;line-height:1.25;font-family:sans-serif;text-align:center;text-anchor:middle;fill:#000000;fill-opacity:1;stroke:none;stroke-width:0.264584"
        xml:space="preserve"
      >
        <tspan
          style="text-align:center;text-anchor:middle;stroke-width:0.264584"
          y="103.34827"
          x="51.70948"
          id="tspan1195"
          sodipodi:role="line"
        >Desenvolvimento da raiz</tspan>
      </text>
    </g>
  </svg>
</template>

<script>
const svgSoilHeight = 100 - 6;
const svgRootHeight = 25;
const maxRootScale = 1.5;

export default {
  data() {
    return {
      soilHeight: 0
    };
  },

  props: {
    width: { type: [Number, String], default: 200 },
    height: { type: [Number, String], default: 200 },
    layers: {
      type: Array,
      default: function() {
        return [{ depth: 20 }, { depth: 20 }, { depth: 20 }];
      }
    },
    cultureParameters: { type: Object, default: function(){return{rootDepth:0}}},
    drawPlant:{type:Boolean,default:true}
  },

  computed: {
    rootData() {
      let soilHeight = this.soilHeight;

      if (soilHeight == 0) {
        return { roots:[] , cota:0 };
      }

      let roots = [];
      let rootSum = 0;
      let rootSvgSize =
        this.cultureParameters.rootDepth * (svgSoilHeight / soilHeight);
      let lastSize = 0;

      while (rootSum < rootSvgSize) {
        let localPos = -lastSize * 0.3;
        let diference = rootSvgSize - rootSum;
        let scale = (diference - localPos) / svgRootHeight;
        if (scale > maxRootScale / 2) {
          scale -= (scale - maxRootScale / 2) * 0.5;
        }
        if (scale > maxRootScale) scale = maxRootScale;
        lastSize = svgRootHeight * scale;
        roots.push({ pos: localPos + rootSum, scale: scale });
        rootSum += svgRootHeight * scale + localPos;
      }

      let cota = rootSum;

      return { roots, cota };
    },

    layersPos() {
      if (this.soilHeight == 0) {
        return [];
      }

      let ret = [];
      const k = svgSoilHeight / this.soilHeight;

      let pos = 0;
      this.layers.forEach(element => {
        if (pos > 0) {
          ret.push(pos);
        }
        pos += element.depth * k;
      });
      return ret;
    }
  },
  mounted() {
    let soilHeight = 0;
    this.layers.forEach(element => {
      soilHeight += element.depth;
    });
    this.soilHeight = soilHeight;
  }
};
</script>

<style>
</style>