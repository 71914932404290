<template>
  <div class="profile-container">
    <div class="avatar">
      <b-avatar></b-avatar>
    </div>

    <div class="info-container">
      <div class="name">
        {{invite.email}}
        <b-badge variant="primary">{{invite.role}}</b-badge>
      </div>

      <div class="info">Esperando um usuário para este mebro</div>
      <code class="info">https://app.kalliandra.com.br/#/join-group/{{invite.id}}</code>
    </div>

    <div class="actions">
      <b-button :disabled="removeButtonDisabled" size="sm" @click="remove" variant="secondary">excluir</b-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      removeButtonDisabled: false
    };
  },
  props: {
    invite: { type: Object, required: true }
  },
  methods: {
    remove() {
      this.removeButtonDisabled = true
      this.$emit("remove-invite", this.invite.id);
    }
  }
};
</script>

