<template lang="html">
<tbody v-if="dataIsValid">

  <vl-feature  :id="'station-'+station.id" :properties="{name:station.name , station: station,onClick:onClick}">
    <vl-geom-point :coordinates="[station.location.lon,station.location.lat]"></vl-geom-point>
    <vl-style-box :z-index="5">
       <vl-style-icon :scale="1" :anchor="[0.5, 1]" :size="[40, 40]"
            :src="require('./assets/stationPoint.png')"
        ></vl-style-icon>
      </vl-style-box>
      
  </vl-feature>
</tbody>
</template>

<script>

export default {
  props: {
    station: {type:Object, required:true}
  },
  
  computed: {
    dataIsValid(){
      if(this.station.location == undefined){
          return false
      }else{
        return true
      }
    }
  },
  methods :{
    onClick(){
      this.$emit("click");
    }
  },
};
</script>

<style lang="css" scoped>
</style>
