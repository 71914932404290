<template>
    <b-card header="Equipamentos e parcelas ativas">

        <div v-if="loadStatus == 'loaded'">

            <div v-for="eqp in equipments" :key="eqp.id">
                <div v-if="eqp.fields.length > 0"  class="equipment d-flex">
                    <div class="equipment-name">{{ eqp.name }}</div>
                    <div class="d-flex">
                        <div v-for="field in eqp.fields" :key="field.id" class="field">
                            <img v-b-tooltip.hover :title="field.culture.name" class="culture-img"
                                :src="require('@irrigcomponents/culture/assets/48/' + field.culture.icon + '.png')" />
                            {{ field.name }}
                        </div>
                    </div>
                </div>
            </div>


        </div>
        <c-loading v-else :status="loadStatus"></c-loading>
    </b-card>
</template>
<script>
export default {

    data() {
        return {
            equipments: undefined,
            loadStatus: "loading"
        }
    },

    props: {
        clientId: String
    },

    mounted() {
        this.loadClient()
    },

    methods: {
        loadClient() {
            this.loadStatus = "loading";
            this.$http.get("/irrig/equipment/client/" + this.clientId).then(
                data => {
                    this.equipments = data;
                    this.loadStatus = "loaded";
                },
                error => {
                    console.error(error)
                    this.loadStatus = "error";
                }
            );
        },
    }

}
</script>
<style lang="scss" scoped>
.equipment {

    .culture-img {
        height: 25px;
        background-color: rgba(255, 255, 255, 0.5);
        border-radius: 30%;
    }

    padding:0.5rem;
    border-bottom:1px solid $border-color;

    &-name{
        min-width:100px;
        font-weight:700;
        line-height: 40px;
    }

    .field {
        padding: 0.5rem;
        margin-right: 10px;
        border: 1px solid $border-color;
        background-color:#eee;
    }
}
</style>