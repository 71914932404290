<template>
  <v-chart
    style="width:100%;height:100%;"
    :option="serie"
    :theme="theme"
    autoresize
    @click="onclick"
    @brushselected="brushselected"
  />
</template>

<script>
import { DateTime } from "luxon";



// function renderItem(params, api) {
//     var yValue = api.value(1);
//     var start = api.coord([api.value(0), yValue]);
//     var size = api.size([ 86400000, yValue]);
//     var style = api.style({
//         stroke: api.visual('color'),
//         // fill: null
//     })
//     // var currentSeriesIndices = api.currentSeriesIndices();
//     // var barLayout = api.barLayout({
//     //     barGap: '20%', barCategoryGap: '20%', count: currentSeriesIndices.length - 1
//     // });

//     let halfsize = (size[0]/2)*0.8;

//     return {
//         type: 'line',
//         shape: {
//             x1: start[0] +halfsize,
//             y1: start[1],
//             x2: start[0] -halfsize,
//             y2: start[1]
//         },
//         style: style
//     };
// }

function renderItem(params, api) {
  var xValue = api.value(0);
  var highPoint = api.coord([xValue, api.value(1)]);
  var lowPoint = api.coord([xValue, api.value(2)]);
  var halfWidth = api.size([86400000, 0])[0] * 0.1;
  var style = api.style({
    stroke: api.visual("color"),
    fill: null,
  });

  return {
    type: "group",
    children: [
      {
        type: "line",
        shape: {
          x1: highPoint[0] - halfWidth,
          y1: highPoint[1],
          x2: highPoint[0] + halfWidth,
          y2: highPoint[1],
        },
        style: style,
      },
      {
        type: "line",
        shape: {
          x1: highPoint[0],
          y1: highPoint[1],
          x2: lowPoint[0],
          y2: lowPoint[1],
        },
        style: style,
      },
      {
        type: "line",
        shape: {
          x1: lowPoint[0] - halfWidth,
          y1: lowPoint[1],
          x2: lowPoint[0] + halfWidth,
          y2: lowPoint[1],
        },
        style: style,
      },
    ],
  };
}

export default {

  props: {
    data: Array,
    theme:Object
  },

  methods:{
    onclick(v){
      this.$emit("date-select", v.data[0])
    },
    brushselected(params) {
      let area = params.batch[0].areas[0];
      if(area){
        let coord =area.coordRange;
        this.$emit("update:rangeselect", {
          start: DateTime.fromSeconds(coord[0] / 1000,).toISO(),
          end:   DateTime.fromSeconds(coord[1] / 1000,).toISO(),
        });
      }else{
        this.$emit("update:rangeselect", undefined);
      }
    },
  },
  computed: {
    serie: function() {
      let serie_max_min = this.data.map((item) => [
        DateTime.fromISO(item.date).toJSDate(),
        item.max,
        item.min,
      ]);

      let serie_avg = this.data.map(function(item) {
        let value = item.avg;
        if (value >= 100) value = value.toFixed(0);
        else if (value >= 10) value = value.toFixed(1);
        else value = value.toFixed(2);

        return [DateTime.fromISO(item.date).toJSDate(), value];
      });

      return {
        title: {
          // text: "Variação e Média",
          // subtext: "Maior, menor e média das chuvas por dia",
          // left: "right",
          // align: "right",
        },
        legend: {
          data: ["Max~Min", "Média"],
          left: 0,
          top:0,
        },
        toolbox: {},
         brush: {
          toolbox: ["lineX", "clear"],
          xAxisIndex: 0,
        },
        dataZoom: {
          type: "inside",
          realtime: true,
          start: 0,
          end: 100,
        },
        grid: [{ top:40, bottom: 5, right: 0, left:0, containLabel: true}],
        tooltip: {
          trigger: "axis",
          axisPointer: { type: "cross", crossStyle: { color: "#000" } },
          // formatter:"{a0}: {c0} <br/> {a1}: {c1}"
          formatter: function(params) {
            let out = DateTime.fromJSDate(params[0].value[0]).toLocaleString();
            out += "<br>";
            params.forEach((element) => {

              out+= `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${element.color};"></span>`
              out+= ' '

              out += element.seriesName + ": " + element.value[1];
              if (element.value[2]!== undefined) {
                out += " ~ " + element.value[2];
              }
              out += "<br>"
            });
            return out;
          },

          
        },
        xAxis: {
          type: "time",
          axisPointer: {
            label: {
              formatter: function(value) {
                return DateTime.fromMillis(value.value).toLocaleString();
              },
            },
          },
        },
        yAxis: [{ /* name: "mm", */ type: "value" }],
        series: [
          {
            name: "Média",
            data: serie_avg,
            showBackground: true,
            type: "bar",
            label: {
              show: true,
              position: "top",
              fontFamily: 'monospace',
              // color:'#fff'
            },
            color: "rgba(0, 172, 230, 0.6)",
            animation: true,
            legendHoverLink: false,
            z: 3,
          },
          {
            name: "Max~Min",
            data: serie_max_min,
            type: "custom",
            renderItem: renderItem,
            color: "#a60",
            itemStyle: {
              normal: {
                borderWidth: 1.5,
              },
            },
            z: 1,
          },
        ],
      };
    },
  },
};
</script>

