<template lang="html">
  <div>

    <div class="widget-box-header">
      <span class="name">{{sensor.name}}</span>
      <span class="time">
        <font-awesome-icon icon="clock"/> {{$filters.secondsToStr(elapsedTime)}}
      </span>
    </div>


    <div class="widget-box-value">
      <waterSvg :width="200" :height="80" :sensor="sensor"/>
    </div>

  </div>
</template>

<script>
import sensWater from './sensWater.js'
import waterSvg from './waterSvg'
export default {
  extends:sensWater,
  components:{
    waterSvg
  }
}
</script>
