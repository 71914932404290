<template>
    <b-table stacked="sm" small responsive :items="items" :fields="fields" :filter="filter" :current-page="currentPage"
        :per-page="perPage" @filtered="onFiltered">

        <template v-slot:cell(icon)="row">
            <img class="culture-img" :src="require('./assets/48/' + row.item.icon + '.png')" />
        </template>

        <template v-slot:cell(details)="row">
            <b-link @click="row.toggleDetails">
                <font-awesome-icon v-if="row.detailsShowing" icon="chevron-up" />
                <font-awesome-icon v-else icon="chevron-down" />
            </b-link>
        </template>


        <template v-slot:row-details="row">
            <div class="culture-details">

                <p><i>{{ row.item.description }}</i></p>

                <cultureFaseDates :culture="row.item"/>
                <cultureStageChart :stages="row.item.stages" style="height: 200px;"/>

                <div class="d-flex mt-2">
                    <div class="ml-auto">
                        <b-button size="sm" v-if="$can('edit', $subject('Culture',row.item))" v-b-tooltip.hover title="Remover" variant="danger"
                            @click="$emit('removeculture', row.item.id)"> <font-awesome-icon icon="trash" /> Remover
                        </b-button>
                    </div>
                </div>





                <!-- <div class="stages-container" v-for="(stage,index) in row.item.stages" :key="index">
                <div class="stages-item">
                    <b>{{stage.name}}</b>
                </div>
                <div class="stages-item">
                    dias:<b>{{stage.durationDays}}</b>
                </div>
                <div class="stages-item">
                    kc:<b>{{stage.initialKc}}</b>
                </div>
                <div class="stages-item">
                    raiz:<b>{{stage.initialRootDepth}}</b>
                </div>
                <div class="stages-item">
                    area:<b>{{stage.initialShadedArea}}</b>
                </div>
                <div class="stages-item">
                    segurança:<b>{{stage.safetyFactor}}</b>
                </div>
            </div> -->
            </div>
        </template>

        <template v-slot:cell(action)="row">
            <b-button-toolbar class="text-center">
                <b-button v-if="$can('create', 'Culture')" size="sm" v-b-tooltip.hover title="Copiar" variant="secondary"
                    @click="$emit('copyculture', row.item.id)"> <font-awesome-icon icon="copy" /> Clonar </b-button>
                <b-button v-if="$can('edit', $subject('Culture',row.item))" size="sm" class="mx-1" v-b-tooltip.hover title="Editar" variant="primary"
                    @click="$emit('editculture', row.item.id)"><font-awesome-icon icon="edit" /> Editar </b-button>
            </b-button-toolbar>
        </template>
    </b-table>
</template>

<script>

import cultureStageChart from './cultureStageChart';
import cultureFaseDates from './cultureFaseDates.vue';

//calcula o número de camadas
var stagesFormat = function (value, col, row) {
    return row.stages.length;
}

export default {

    data() {
        return {
            fields: [
                { key: 'details', label: '', sortable: false },
                { key: 'icon', label: '', sortable: false },
                { key: 'name', label: 'Nome', sortable: true },
                { key: 'stages', label: 'Fases Fenológicas', formatter: stagesFormat, sortable: true },
                { key: 'action', label: '', sortable: false, class: "text-center" }
            ],
        }
    },
    props: {
        cultureList: Array,
        currentPage: Number,
        perPage: Number,
        filter: [String, RegExp, Object]
    },
    computed: {
        items() {
            let list = []
            this.cultureList.forEach((v) => {
                list.push({
                    id: v.id,
                    name: v.name,
                    description: v.description,
                    icon: v.icon,
                    stages: v.stages,
                    __type: v.__type,
                    workspace: v.workspace,
                })
            });
            return list;
        }
    },
    methods: {
        onFiltered(filteredItems) {
            this.$emit('onnumrows', filteredItems.length)
        }
    },
    components: {
        cultureStageChart,
        cultureFaseDates
    }
}
</script> 

<style lang="scss" scoped>
// .stages-container{
//      display: flex;
//      background-color: rgb(209, 209, 209);
//      padding: 5px;
//      border-top: 1px solid rgb(151, 151, 151);
//      //padding-left: 50px;

//      .stages-item{
//         //  flex: 1; /*grow*/
//         margin-left: 50px;
//      }
// }

.culture-details {
    background-color: rgb(240, 240, 240);
    margin-bottom: 2rem;
    padding: 1rem;
    border: 1px dashed #aaa;
}



.culture-img {
    height: 30px;
}
</style>