<template>
  <div>
    <b-container class="user-page">
      <div class="header">
        <h2>Usuários</h2>
        <small>
          <font-awesome-icon icon="info-circle"/>
          Lista de todos os usuários cadastrados no sistema
        </small>
      </div>

      <!-- MENU de ferramentas  -->
      <b-row class="user-page-header">
        <b-col cols="1">
          <b-button @click="newUser" variant="primary">novo</b-button>
        </b-col>

        <!-- <b-col>
          <div class="float-right">
            <b-pagination
              v-model="currentPage"
              :total-rows="numRows"
              :per-page="perPage"
              aria-controls="my-table"
            ></b-pagination>
          </div>
        </b-col> -->

        <b-col md="6" >
          <b-form-group
            label="Pesquisar"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="filterInput"
          >
            <b-input-group size="sm">
              <b-form-input
                v-model="filter"
                type="search"
                id="filterInput"
                placeholder="Digite o que procurar"
              ></b-form-input>
              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''">Limpar</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>

      <!-- LISTA -->
      <div v-if="loadStatus=='loaded'">
        <b-row>
          <b-col md="6" lg="4"  v-for="user in filteredUsers" :key="user.id">
            <!-- <b-link :to="{name:'userDetails',params:{id:user.id} }"> -->
             <userCard :user="user"></userCard>
            <!-- </b-link> -->
          </b-col>
        </b-row>

        <router-view v-on:update-user="updateList($event)" v-on:remove-user="removeUser($event)"></router-view>
      </div>
      <c-loading :status="loadStatus" v-else></c-loading>
    </b-container>
  </div>
</template>

<script>
//usado apenas para testes. comente esta linha caso não esteja testando
//import dataTeste from "./testes/data.json";

import userCard from "./userCard";

export default {
  data() {
    return {
      userList: [],
      loadStatus: String,
      perPage: 10,
      currentPage: 1,
      filter: null
    };
  },
  props: {
    editable: { type: Boolean, default: true }
  },
  beforeMount() {
    this.loadStatus = "loading"
    this.$http.get("account/user").then(
      data => {
        this.userList = data;
        this.loadStatus = "loaded";
      },
      error => {
        console.error("load user error",error)
        this.loadStatus = "error"
      }
    );
  },
  components: {
    userCard
  },
  computed: {
    numRows() {
      return this.userList.length;
    },

    filteredUsers() {
      if(this.filter!= null){
        return this.userList.filter(item => {
          return item.name.toLowerCase().indexOf(this.filter.toLowerCase()) > -1
      })
        }else{
          return this.userList
        }
    }
  
  },
  methods: {
    //atualiza a lista de usuários
    updateList(newUser) {
      const index = this.userList.findIndex(e => e.id === newUser.id);
      if (index === -1) {
        this.userList.push(newUser);
      } else {
        let dataObj = this.userList[index];
        Object.entries(newUser).forEach(([key, value]) => {
          dataObj[key] = value;
        });
      }
    },


    removeUser(id) {
      this.$bvModal
        .msgBoxConfirm(
          "Deseja realmente apagar este registro permanentemente?",
          {
            title: "Por favor confirme",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "Sim",
            cancelTitle: "Não",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true
          }
        )
        .then(value => {
          if (value === true) {
            this.$http.delete("account/user/" + id).then(
              () => {
                let index = this.userList.findIndex(e => e.id === id);
                this.userList.splice(index, 1);
                this.$router.push({ name: "userList" });
              },
              error => {
                console.error("delete user error",error)
                //API error
              }
            );
          }
        })
    },

    newUser() {
      this.$router.push({ name: "userNew" });
    }
  }
};
</script>

<style lang="scss" scoped>
.user-page {
  padding-top: 30px;

  .header {
    margin: 0 0 20px;
    small {
      color: #666;
    }

    h2 {
      font-family: "Open Sans", Helvetica, Arial, sans-serif;
      color: #444;
      font-size: 27px;
      font-weight: 700;
      line-height: 27px;
      margin: 0;
    }
  }

  .user-page-header {
    padding-bottom: 10px;
  }
}
</style>