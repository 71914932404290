<template>
  <b-tr v-if="weather!== undefined">
    <b-td stacked-heading="Estação" class="id-r-line field-label">{{name}}</b-td>
    
    <b-td stacked-heading="Temperatura Máxima  (ºC)">
      <ValidationProvider name="temp_max" :rules="{required: false,between:[0,50]}" v-slot="v">
        <b-input @update="weatherUpdate" v-model="weather.tempMax" size="sm" type='number' min=0 max=50 step="any"  :state="$validateState(v)" />
      </ValidationProvider>
    </b-td>

    <b-td stacked-heading="Temperatura Mínima  (ºC)">
      <ValidationProvider name="temp_min" :rules="{required: false,between:[0,weather.tempMax]}" v-slot="v">
      <b-input @update="weatherUpdate" v-model="weather.tempMin" size="sm" type='number' min=0 max=60 step="any" :state="$validateState(v)"/>
      </ValidationProvider>
    </b-td>

    <b-td stacked-heading="Temperatura Média (ºC)">
      <ValidationProvider :rules="{required: false,between:[weather.tempMin,weather.tempMax]}" v-slot="v">
      <b-input @update="weatherUpdate" v-model="weather.tempAvg" size="sm" type='number' min=0 max=60 step="any" :state="$validateState(v)"/>
      </ValidationProvider>
    </b-td>

    <b-td stacked-heading="Umidade (%)">
      <ValidationProvider :rules="{required: false,between:[0,100]}" v-slot="v">
      <b-input @update="weatherUpdate" v-model="weather.humiRel" size="sm" type='number' min=0 max=100 step="any" :state="$validateState(v)"/>
      </ValidationProvider>
    </b-td>

    <b-td stacked-heading="Velocidade do vento (m/s)">
      <ValidationProvider :rules="{required: false,between:[0,20]}" v-slot="v">
      <b-input @update="weatherUpdate" v-model="weather.velWind2m" size="sm" type='number' min=0 step="any" :state="$validateState(v)"/>
      </ValidationProvider>
    </b-td>

    <b-td stacked-heading="Radiação (MJ/dia)">
      <ValidationProvider :rules="{required: false,between:[0,40]}" v-slot="v">
      <b-input @update="weatherUpdate" v-model="weather.radi" size="sm" type='number' min=0 max=40 step="any" :state="$validateState(v)"/>
      </ValidationProvider>
    </b-td> 
    
    <b-td stacked-heading="Chuva (mm)">
      <ValidationProvider :rules="{required: false,between:[0,300]}" v-slot="v">
      <b-input @update="weatherUpdate" v-model="weather.rain" size="sm" type='number' min=0 max=300 step="any" :state="$validateState(v)"/>
      </ValidationProvider>
    </b-td> 

  </b-tr>
</template>

<script>
export default {
  data() {
    return {
      weather: undefined
    };
  },
  methods: {
    weatherUpdate() {
      this.$emit("input",JSON.parse(JSON.stringify(this.weather)));
      this.$emit("onchange");
    }
  },
  props: {
    value: Object,
    name:  String
  },
  
  mounted() {
    this.weather = Object.assign({}, this.value);  
  }
};
</script>
