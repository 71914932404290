<template lang="html">
<tbody v-if="dataIsValid">
  <vl-feature  :id="'gateway-'+gateway.id" :properties="{gateway: gateway}">
    <vl-geom-point :coordinates="[gateway.location.lon,gateway.location.lat]"></vl-geom-point>
    <vl-style-box :z-index="5">
       <vl-style-icon :scale="1" :anchor="[0.5, 1]" :size="[40, 40]"
            :src="require('./assets/gatewayPoint.png')"
        ></vl-style-icon>
      </vl-style-box>
  </vl-feature>
</tbody>
</template>

<script>

export default {
  props: {
    gateway: {type:Object, required:true}
  },
  computed: {
    dataIsValid(){
      if(this.gateway.location == undefined){
          return false
      }else{
        return true
      }
    }
  },
  methods:{
    
  }
};
</script>

<style lang="css" scoped>
</style>
