<template>
<div style="width:100% ; height: 100%;">
    <v-chart :option="serie"  autoresize />
</div>

</template>

<script>

export default {
    props:{
        stages:Array
    },
    computed:{
      serie: function(){

        let list = [];
        let daySum = 0;
        let element = {}
        this.stages.forEach(v=>{
            element ={
                stage:v.name,
                day:daySum,
                kc:v.initialKc,
                root:v.initialRootDepth,
                area:v.initialShadedArea,
                safety: v.safetyFactor
            }
            list.push(element)
            daySum += parseInt(v.durationDays)
        })
         element = Object.assign({},element);
         element.day = daySum;
         list.push(element);

        return  {
            legend:{
                data:['kc','raiz','Área sombreada','seguranca'],
                bottom: 0,
            },
            grid: { right: 25, bottom: 30, top: 30, left: 10, containLabel: true }, 
            tooltip: {
                trigger: "axis",
                axisPointer: { type: "cross", crossStyle: { color: "#999" } }
            },
            xAxis: {
                type: 'value',
                data: list.map(item=>item.day)
            },
            yAxis: [
                { name:'mm', type: 'value',position:'left'},
                { name:'cm', type: 'value', position:'left',offset:30},
                { name:'%', type: 'value',position:'right', offset:30},
                { name:'', type: 'value',position:'right'}
                ],
            series: [
                { yAxisIndex:0, name:'kc', data:  list.map(item=>[item.day, item.kc]), type: 'line'},
                { yAxisIndex:1, name:'raiz', data:  list.map(item=>[item.day,item.root]), type: 'line'},
                { yAxisIndex:2, name:'Área sombreada', data:  list.map(item=>[item.day,item.area]),type: 'line'},
                { yAxisIndex:3, name:'seguranca', data:  list.map(item=>[item.day,item.safety]), type: 'line',areaStyle: {}},
            ]
        }
      }
    }
}
</script>