import Vue from 'vue'

import './icon.less'
import calendarExclamation from "./calendarExclamation"
import calendarEdit from "./calendarEdit"
import farm from "./farm"
import fileChartPie from "./fileChartPie"
import handHoldingSeedling from "./handHoldingSeedling"



Vue.component('kli-calendar-exclamation',calendarExclamation)
Vue.component('kli-calendar-edit',calendarEdit)
Vue.component('kli-farm',farm)
Vue.component('kli-file-chart',fileChartPie)
Vue.component('kli-hand-holding-seedling',handHoldingSeedling)