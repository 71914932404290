<template lang="html">
  <sensorWidget :sensor="sensor" :color="'#333'">
    <template v-slot:icon>
      <hydroFlow :sensor="sensor" />
    </template>

    <template v-slot:content>
      <div>
        <div class="mb-2">
          Acumulado
        </div>
        <div class="widget-box-value mx-2">
          <span class="label">Hoje </span>
          <span class="value">
            {{ $filters.localeNumber(totalDay,0) }}<small> {{ sensor.unit }} </small>
          </span>
        </div>
        <div class="widget-box-value mx-2">
          <span class="label">Este Mês</span>
          <span class="value">
            {{ $filters.localeNumber(totalMonth,0) }}<small> {{ sensor.unit }} </small>
          </span>
          <value-bar v-if="maxMonth" :value="totalMonth"  :max="maxMonth" />
        </div>
      </div>
    </template>
  </sensorWidget>
</template>

<script>
import sensHydro from "./sensHydro.js";
import hydroFlow from "./hydroFlow";
import sensorWidget from "../sensorWidget.vue";


export default {
  extends: sensHydro,
  methods: {
    showModal() {
      this.$store.dispatch("select/setSensor", this.sensor);
    },
  },

  components: {
    sensorWidget,
    hydroFlow
  },

};
</script>

