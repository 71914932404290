<template>
<div>

  <b-row>
    <!-- unidade  -->
    <ValidationProvider
      slim
      vid="unit"
      name="Unidade"
      :rules="{ required: true, min: 1 }"
      v-slot="v"
    >
      <b-form-group label="Unidade" class="col-sm-3">
        <b-form-input
          name="sensor.pressureUnit"
          v-model="editable.pressureUnit"
          type="text"
          maxlength="10"
          placeholder="unidade pressão"
          :state="$validateState(v)"
        ></b-form-input>
        <span class="invalid-input">{{ v.errors[0] }}</span>
      </b-form-group>
    </ValidationProvider>

    <!-- Pressão mínima-->
    <ValidationProvider
      slim
      vid="fullLevel"
      name="Pressão mínima"
      :rules="{ required: true, minValue: 0 }"
      v-slot="v"
    >
      <b-form-group label="Pressão mínima" class="col-sm-4">
        <b-input-group append="kpa" :state="$validateState(v)">
          <b-form-input
            name="sensor.minPressureIrrig"
            v-model="editable.minPressureIrrig"
            type="number"
            min="0"
            :state="$validateState(v)"
          ></b-form-input>
        </b-input-group>
        <span class="invalid-input">{{ v.errors[0] }}</span>
      </b-form-group>
    </ValidationProvider>

    <!-- Pressão Alerta-->
    <ValidationProvider
      slim
      vid="warnPressure"
      name="Pressão de alerta"
      :rules="{ required: true, minValue: 0 }"
      v-slot="v"
    >
      <b-form-group label="Pressão de alerta" class="col-sm-5">
        <b-input-group append="kpa" :state="$validateState(v)">
          <b-form-input
            name="sensor.warnPressure"
            v-model="editable.warnPressure"
            type="number"
            min="0"
            :state="$validateState(v)"
          ></b-form-input>
        </b-input-group>
        <span class="invalid-input">{{ v.errors[0] }}</span>
      </b-form-group>
    </ValidationProvider>
  </b-row>



  <pivotParams v-model="editable.pivot" />
      
</div>
</template>

<script>

import pivotParams from './pivotParams.vue';

export default {
  data() {
    return {
      editable: undefined,
    };
  },
  props: {
    value: { type: Object, required: true },
  },
  beforeMount() {
    this.editable = JSON.parse(JSON.stringify(this.value));
    if (this.editable.pivot == undefined) {
      this.$set(this.editable, "pivot", {});
      this.editable.pivot = {
        centerLocation: { ele: 0, lat: 0, lon: 0 },
        radius: 580,
        areaStartPos: 0,
        areaEndPos: 360,
        offsetPos: 0,
        overhang: 0,
        velocityMax: 280,
        displayOffsetPos: 0,
      };
    }
  },
  watch: {
    editable: {
      deep: true,
      handler(v) {
        this.$emit("input", v);
      },
    },
  },

  components:{
    pivotParams
  }
};
</script>

<style></style>
