
import {findPointOnSurface} from 'vuelayers/lib/ol-ext'

export const pointSensor = {

  data(){
     return{
    //  featureId: '',
      feature : null
     }
  },

  props:{
    showDetail:{ type: Boolean, default: false},
  },

  methods :{
    pointOnSurface: findPointOnSurface,
    showInfo() {
      //this.$emit('set-pivot-current',this.pivot)
      //this.$store.dispatch('select/setSensor',this.sensor)
      this.$router.push('/sensor/'+this.sensor.id)
    }
  },

  computed:{
    coordinates:function(){
      return [this.sensor.location.lon,this.sensor.location.lat];
    },
  },

  mounted(){
    this.feature = this.$refs.SensorFeature;
    //this.featureId = this.$refs.SensorFeature.id;
  },
}
