'use strict';
Object.defineProperty(exports, '__esModule', { value: true });
var prefix = 'fal';
var iconName = 'cloud-rain';
var width = 512;
var height = 512;
var aliases = [];
var unicode = 'f73d';
var svgPathData = 'M96 112c0-44.2 35.8-80 80-80c32.2 0 59.9 19 72.6 46.4c2.2 4.8 6.6 8.1 11.8 9s10.4-.8 14.1-4.5c11.6-11.7 27.7-19 45.5-19c35.3 0 64 28.7 64 64l0 .3c0 4.5 1.9 8.8 5.2 11.8s7.8 4.5 12.2 4.1c2.2-.2 4.3-.3 6.6-.3c39.8 0 72 32.2 72 72s-32.2 72-72 72H104c-39.8 0-72-32.2-72-72c0-33.5 22.9-61.7 53.9-69.7c8.2-2.1 13.3-10.3 11.7-18.6c-1-5.1-1.5-10.3-1.5-15.7zM512 216c0-55.2-43-100.3-97.3-103.8C407.2 66.7 367.6 32 320 32c-19.3 0-37.3 5.7-52.4 15.5C247.3 18.8 213.9 0 176 0C114.1 0 64 50.1 64 112c0 2.6 .1 5.3 .3 7.9C26.6 135.5 0 172.6 0 216c0 57.4 46.6 104 104 104H408c57.4 0 104-46.6 104-104zM82.3 451.9L96 426.7l13.7 25.2c1.5 2.8 2.3 5.9 2.3 9.1v3c0 8.8-7.2 16-16 16s-16-7.2-16-16v-3c0-3.2 .8-6.3 2.3-9.1zM54.2 436.6C50.1 444.1 48 452.5 48 461v3c0 26.5 21.5 48 48 48s48-21.5 48-48v-3c0-8.5-2.1-16.9-6.2-24.3l-23.6-43.5-.4-.8-5.4-9.9L102.8 372c-1.3-2.5-3.9-4-6.8-4s-5.4 1.5-6.8 4l-5.6 10.4-5.4 9.9-.4 .8L54.2 436.6zm188.1 15.3L256 426.7l13.7 25.2c1.5 2.8 2.3 5.9 2.3 9.1v3c0 8.8-7.2 16-16 16s-16-7.2-16-16v-3c0-3.2 .8-6.3 2.3-9.1zm-28.1-15.3c-4.1 7.5-6.2 15.8-6.2 24.3v3c0 26.5 21.5 48 48 48s48-21.5 48-48v-3c0-8.5-2.1-16.9-6.2-24.3l-23.6-43.5-.4-.8-5.4-9.9L262.8 372c-1.3-2.5-3.9-4-6.8-4s-5.4 1.5-6.8 4l-5.6 10.4-5.4 9.9-.4 .8-23.6 43.5zm201.8-10l13.7 25.2c1.5 2.8 2.3 5.9 2.3 9.1v3c0 8.8-7.2 16-16 16s-16-7.2-16-16v-3c0-3.2 .8-6.3 2.3-9.1L416 426.7zm-18.2-33.5l-23.6 43.5c-4.1 7.5-6.2 15.8-6.2 24.3v3c0 26.5 21.5 48 48 48s48-21.5 48-48v-3c0-8.5-2.1-16.9-6.2-24.3l-23.6-43.5-.4-.8-5.4-9.9L422.8 372c-1.3-2.5-3.9-4-6.8-4s-5.4 1.5-6.8 4l-5.6 10.4-5.4 9.9-.4 .8z';

exports.definition = {
  prefix: prefix,
  iconName: iconName,
  icon: [
    width,
    height,
    aliases,
    unicode,
    svgPathData
  ]};

exports.falCloudRain = exports.definition;
exports.prefix = prefix;
exports.iconName = iconName;
exports.width = width;
exports.height = height;
exports.ligatures = aliases;
exports.unicode = unicode;
exports.svgPathData = svgPathData;
exports.aliases = aliases;