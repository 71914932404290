<template>
    <router-view></router-view>
</template>

<script>
export default {
  props: {
    appName: { type: String, default: "app" }
  }
};
</script>


