<template>
  <div>
    <!-- <div v-if="status==='loaded'"> -->
    <template v-if="client">

      <multipane layout="horizontal" @pane-resize-stop="panelResize">
      <farmMap  ref="farmmap" class="home-map non-print" :style="{height:mapHeight}" :visible="true"></farmMap>


      <multipane-resizer class="resizer"></multipane-resizer>
      <div class="home-view">
        <!-- <div class="home-select-container">
          <b-button size="sm"> select </b-button>
        </div> -->

          <fieldOverviewList v-if="viewMode == 'irrigation'">
            <!-- <b-button
              v-if="this.sensorEnabled"
              size="sm"
              variant="primary"
              @click="viewSelected = 'sensor'"
              >Sensores</b-button
            > -->

            <b-nav class="nav-border"  >
              <b-nav-item active>Irrigação</b-nav-item>
              <b-nav-item v-if="this.sensorEnabled" @click="viewSelected = 'sensor'">Sensores</b-nav-item>
            </b-nav>

          </fieldOverviewList>

          <sensorsList v-if="viewMode == 'sensor'">
            <!-- <b-button
              v-if="this.irrigEnabled"
              size="sm"
              variant="success"
              @click="viewSelected = 'irrigation'"
              >Irrigação</b-button
            > -->
            <b-nav class="nav-border" >
              <b-nav-item  v-if="this.irrigEnabled" @click="viewSelected = 'irrigation'">Irrigação</b-nav-item>
              <b-nav-item active>Sensores</b-nav-item>
            </b-nav>
          </sensorsList>
 
      </div>

      <!-- JANELAS MODAL  -->
      <!-- FIXME: deixar o modal aparecer apenas se tiver sido carregado os
        dados dos sensores o dos equipamentos. talvez ter um router-view específico pra
        cada um desses modals -->
      <router-view></router-view>
      </multipane>
    </template>
    <b-container v-else class="no-client-container">
      <div class="text-center m-5">
        <div>
          <img style="width: 140px;" src="@/assets/empty-box.png" alt="" />
        </div>
        <div class="mt-4 mx-auto" style="max-width: 460px;">
          <h5>Nenhum cliente ativo</h5>
          <p>
            Este grupo não tem nenhum cliente ativo para este usuário.
          </p>

          <b-button
            v-if="$can('create', 'Client')"
            variant="success"
            :to="{ name: 'clientList' }"
          >
            Adicionar Cliente</b-button
          >
         
        </div>
      </div>
    </b-container>
    <!-- </div> -->
    <!-- <c-loading v-else :status="status" /> -->
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import farmMap from "@components/map/farmMap.vue";

import fieldOverviewList from "@irrigcomponents/overview/fieldOverviewList";

import sensorsList from "@sensorcomponents/sensorsList";


export default {
  data() {
    return {
      viewSelected: "irrigation",
    };
  },

  methods:{
    panelResize(){
       this.$refs.farmmap.updateSize()
       localStorage.setItem('home-map-height',(this.$refs.farmmap.$el.clientHeight+"px"));
    }
  },
  computed: {

    mapHeight(){
      let mapHeight = localStorage.getItem('home-map-height');
      if(!mapHeight)
        mapHeight = "50vh"
       return mapHeight
    },


    viewMode() {

      if (this.viewSelected === "irrigation" && this.irrigEnabled) {
        return "irrigation";
      }

      if (this.viewSelected === "sensor" && this.sensorEnabled) {
        return "sensor";
      }

      if(this.irrigEnabled)  return "irrigation";
      if(this.sensorEnabled) return "sensor";

      return undefined;
    },

    irrigEnabled() {
      return this.equipmentsStatus === "loaded" && this.equipments.length > 0;
    },
    sensorEnabled() {
      return this.sensorsStatus === "loaded" && this.allSensors.length > 0;
    },

    showIrrigManagerPage: function() {
      return this.fields.length > 0;
    },
    ...mapGetters({
      stationsList: "sensors/stationsList",
      pivotsList: "sensors/pivotsList",
      soilsList: "sensors/soilsList",
      pluvioList: "sensors/pluvioList",
      waterList: "sensors/waterList",
      fields: "equipments/fields",
      client: "accounts/client",
    }),

    ...mapState({
      equipments: (state) => state.equipments.equipments,
      allSensors: (state) => state.sensors.sensors,
      equipmentsStatus: (state) => state.equipments.status,
      sensorsStatus: (state) => state.sensors.status,
    }),
  },

  components: {
    farmMap,
    fieldOverviewList,
    sensorsList,
  },
};
</script>

<style lang="scss" scoped>
.home-map {
  // height: calc(70vh - 50px);
  min-height: 12rem;
}

.home-view {
  position: relative;
  .home-select-container {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.no-client-container {
  height: 100vh;
  padding-top: 20px;
  justify-content: center;
}

.resizer{
  // margin: 0; left: 0;
  // position: relative;
      
  // width: 100%;
   height: 10px;
  // margin-top: -5px;
   top: 0px;
  // cursor: row-resize;

  &:hover{
    border-bottom: 5px solid #05f;
    // transition-delay:0.1s;
    transition-duration: 0.1s;
  }

  // &:before {
  //   display: block;
  //   content: "";
  //   width: 40px;
  //   height: 3px;
  //   position: absolute;
  //   top: 50%;
  //   left: 50%;
  //   margin-left: -20px;
  //   margin-bottom: -1.5px;
  //   border-top: 1px solid #ccc;
  //   border-bottom: 1px solid #ccc;
  // }
}
</style>
