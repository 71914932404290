<template>
  <b-row>
    <!-- unidade  -->
    <ValidationProvider
      slim
      vid="unit"
      name="Unidade"
      :rules="{ required: true, min: 1 }"
      v-slot="v"
    >
      <b-form-group label="Unidade" class="col-sm-4">
        <b-form-input
          name="sensor.unit"
          v-model="editable.unit"
          type="text"
          maxlength="10"
          placeholder="unidade"
          :state="$validateState(v)"
        ></b-form-input>
        <span class="invalid-input">{{ v.errors[0] }}</span>
      </b-form-group>
    </ValidationProvider>

    <div class="col-12" v-for="(layer, index) in editable.layers" :key="index">
      <hr />
      <b-row>
        <!-- dataStrem Id  -->
        <ValidationProvider
          slim
          vid="datastream"
          name="Datastream ID"
          :rules="{ required: true, between: [0, 8] }"
          v-slot="v"
        >
          <b-form-group label="DatastreamID" class="col-sm-6 col-lg-2">
            <b-form-input
              name="sensor.datastreamId"
              v-model="layer.datastreamId"
              type="number"
              min="0"
              step="1"
              :state="$validateState(v)"
            ></b-form-input>
            <span class="invalid-input">{{ v.errors[0] }}</span>
          </b-form-group>
        </ValidationProvider>

        <!-- profundidade  -->
        <ValidationProvider
          slim
          vid="depth"
          name="Profundidade"
          :rules="{ required: true, between: [0, 100] }"
          v-slot="v"
        >
          <b-form-group label="Profundidade" class="col-sm-6 col-lg-3">
            <b-input-group append="cm">
              <b-form-input
                name="sensor.depth"
                v-model="layer.depth"
                type="number"
                min="0"
                step="1"
                :state="$validateState(v)"
              ></b-form-input>
            </b-input-group>
            <span class="invalid-input">{{ v.errors[0] }}</span>
          </b-form-group>
        </ValidationProvider>

        <!-- formula -->
        <ValidationProvider
          slim
          vid="formula"
          name="formula"
          :rules="{ required: false, min: 2 }"
          v-slot="v"
        >
          <b-form-group label="formula" class="col-sm-12 col-lg-7">
            <b-form-input
              name="sensor.formula"
              v-model="layer.formula"
              type="text"
              placeholder="fn de v1 e v2 (Ex: v1+v2)"
              :state="$validateState(v)"
            ></b-form-input>
            <span class="invalid-input">{{ v.errors[0] }}</span>
          </b-form-group>
        </ValidationProvider>
        
        <b-col>
          <b-button
            size="sm"
            title="remover Camada"
            variant="danger"
            @click="removeLayer(index)"
          >
            remover</b-button
          >
        </b-col>

      </b-row>
    </div>
    <b-col cols="12">
      <hr>
      <b-button variant="primary" @click="addLayer()"> adicionar camada </b-button>
    </b-col>
    <!-- {{ form }} -->
  </b-row>
</template>

<script>
export default {
  data() {
    return {
      editable: undefined,
    };
  },
  props: {
    value: { type: Object, required: true },
  },
  beforeMount() {
    this.editable = JSON.parse(JSON.stringify(this.value));
  },
  methods: {
    addLayer() {
      if (this.editable.layers == undefined)
        this.$set(this.editable, "layers", []);
      this.editable.layers.push({
        depth: 20,
        formula: "",
        datastreamId: this.editable.layers.length + 1,
      });
    },

    removeLayer(index) {
      this.editable.layers.splice(index, 1);
    },
  },
  watch: {
    editable: {
      deep: true,
      handler(v) {
        this.$emit("input", v);
      },
    },
  },
};
</script>

<style></style>
