<template>
    <div>
        <div v-for="(day) in irrigScheduleDays" :key="day.date">
            <dayTable :day="day"/>
        </div>
    </div>
</template>
<script>
import dayTable from './dayTable.vue'
export default {



    props: {
        irrigSchedule: { type: Array, required: true }
    },

    components:{
        dayTable
    },

    computed: {
        irrigScheduleDays() {
            let scheduleDays = {}
            this.days.forEach(day => {
                this.irrigSchedule.forEach(schedule => {
                    let schedule_day = schedule.days[day]
                    if (schedule_day != null) {
                        if (scheduleDays[day] == undefined) {
                            scheduleDays[day] = { date: day, fields: [] }
                        }
                        scheduleDays[day].fields.push({ ...schedule, ...schedule_day })
                    }

                })

            })

            return scheduleDays
        },
        days() {
            let days = undefined
            this.irrigSchedule.forEach(schedule => {
                if (schedule !== undefined) {
                    days = Object.keys(schedule.days)
                    return
                }

                if (days != undefined) {
                    return
                }
            });
            return days
        },
    },

    methods: {
        startDate() {
            return this.days[0]
        },
        dateInterval() {
            let startday = this.days[0];
            let endDay = this.days[this.days.length - 1];
            return { start: startday, end: endDay }
        }
    }

}
</script>
