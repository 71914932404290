<template>
  <div class="day-box">
    <div class="fill" v-bind:style="calcBoxFill(day)"></div>
    <div v-if="day.irrig" class="text">


      <div v-if="day.irrig.hour" class="itn"> 
          <div> ITN:</div>
          <div> {{ $filters.round(day.irrig.itn, 1) }} mm </div>
      </div>
      <div class="laa">
        <div> Diponível:</div>
        <div> {{ $filters.round(day.laa, 1) }} mm </div>
      </div>

      <div class="mt-4">
        <div title="Irrigação total necessária" class="deficit">
         
        </div>
      </div>



      <!-- <span>{{s.irrigationParameters.value+s.irrigationParameters.unit}}</span> -->
      <!-- {{day.stress}} -->
    </div>
  </div>
</template>

<script>
import fieldColor from "../fieldColors";
export default {
  mixins: [fieldColor],
  props: {
    day: { type: Object, required: true }
  },

  methods: {
    calcBoxFill(s) {
      return {
        //backgroundColor: calcFiledColor(s.colorLevel),
        backgroundColor: this.color,
        height: s.ccPercent + "%",
        transition: "height 1s"
      };
    }
  },
  computed: {
    colorCode() {
      return this.day.color;
    }
  }
};
</script>


<style lang="scss" scoped>
.day-box {
  // display: inline-block;
  margin: auto;
  width: 65px;
  height: 65px;
  border-radius: 20%;
  text-align: center;
  overflow: hidden;
  position: relative;
  background: rgba(0, 0, 0, 0.4);

  .text {
    text-shadow: 1px 1px rgba(0, 0, 0, 0.4);
    font-size: 1.2em;
    z-index: 2;
    position: relative;
    color: #fff;
    margin: 0%;


    .itn {
      font-size: 12px;
      line-height: 12px
    }

    .laa {
      font-size: 12px;
      line-height: 12px;
      margin-top: 12px;
    }
  }

  .fill {
    z-index: 1;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}
</style>