<template>
  <v-chart class="echarts" :option="serie" :theme="theme" autoresize />
</template>

<script>
import { DateTime } from "luxon";

//import chartTheme from "@kl-framework/charts/themes/dark.js";
import { LinearGradient } from "echarts/lib/util/graphic";

const areaStyle = {
  normal: {
     origin :'end',
    color: new LinearGradient(0, 0, 0, 1, [
      {
        offset: 0,
        color: "rgba(77, 210, 255, 0.7)",
      },
      {
        offset: 1,
        color: "rgba(0, 0, 0, 0.7)",
      },
    ]),
  },
};


export default {
  data: function() {
    return {
      theme: undefined, // chartTheme,
    };
  },
  props: {
    data: Object,
    sensorLevel: { type: Number },
    maxLevel: { type: Number },
  },
  computed: {
    serie: function() {
      let data = this.data;
      if (!data) return;
      let series = [];
      if (data.levelSeries) {
        series.push({
          yAxisIndex: 0,
          name: "Nível",
          type: "line",
          data: data.levelSeries,
          areaStyle: areaStyle,
          color: "#3c8dbc",
           showSymbol: false,
            symbolSize: 10,
        });
      }

    

      return {
        title: { left: "center", padding: 1, textStyle: { color: "#fff" } },
        dataZoom: [
          {
            type: "inside",
            show: true,
            realtime: true,
            start: 0,
            end: 100,
          },
        ],
        legend: {
          show: true,
          bottom: 0,
          //  data:['Nível','tendência','Alertas']
        },
        tooltip: {
          trigger: "axis",
          axisPointer: { type: "cross", crossStyle: { color: "#999" } },
           formatter: function (params) {
             params = params[0];
             var date = new Date(params.axisValue);
             return DateTime.fromJSDate(date).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)+' <br> ' + params.value[1]+' m';
         }
        },
        grid: { left:0, bottom: 40, top:25, right: 0, containLabel: true },
        yAxis: [
          {
            scale: false,
            max: this.sensorLevel,
            min: this.maxLevel,
            axisLabel: { formatter: "{value} m" },
            inverse:true
          },
        ],

        xAxis: {
          type: "time",
        },
        series: series,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.echarts {
  width: 100%;
  height: 300px;
  background-color: #fff;
}
</style>
