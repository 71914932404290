<template>

      <vl-feature :id="'map-station-'+sensor.id" :properties="{ name:sensor.name, compId: sensor.id }">
          
          <vl-geom-point :coordinates="coordinates"></vl-geom-point>
          <vl-style-box v-if="isOnline" :z-index="7">
            <vl-style-icon :scale="1" :anchor="[0.5, 1]" :size="[40, 40]"
            :src="require('../../assets/mapPin/station.png')"
            ></vl-style-icon>
          </vl-style-box>
          <vl-style-box v-else :z-index="4">
            <vl-style-icon :scale="1" :anchor="[0.4, 1]" :size="[40, 40]"
                :src="require('../../assets/mapPin/station_off.png')"
            ></vl-style-icon>
          </vl-style-box>

          <receivingIcon v-if="receiving" />

          <vl-overlay v-if="showDetail===true"
          :position="coordinates" :offset="[-48, -54]"
          positioning="bottom-left"
          :auto-pan="true" :auto-pan-animation="{ duration: 300 }">

             <div class="map-popup popup-station"  @click="showInfo">
               <div class="widget-box-header">
                 <span class="name">{{sensor.name}}</span>
                 <span class="time">
                   <font-awesome-icon icon="clock"/> {{$filters.secondsToStr(elapsedTime)}}
                 </span>
               </div>
               <station-info :sensor="sensor" />
            </div>
          </vl-overlay>
      
      </vl-feature>

</template>

<script>


import stationInfo from './stationInfo'
import {pointSensor} from '@components/map/mapCompMixin.js'
import receivingIcon from "../receivingIconMap.vue"

import station from './station'

export default {
  extends:station,
  mixins: [pointSensor],

  components:{
    'station-info':stationInfo,
    receivingIcon
  }
}
</script>

<style lang="css" scoped>
  .popup-station{
    width:250px;
    cursor: pointer;
    padding: 5px;
  }
</style>
