function colorToPalett(imageData, in_min, in_max, colorstops) {

    for (let i = 0; i < imageData.data.length; i += 4) {
        let avg = (imageData.data[i] + imageData.data[i + 1] + imageData.data[i + 2]) / 3;
        let x = avg / 255
        let out_max = 1
        let out_min = 0
        x = (x - in_min) * (out_max - out_min) / (in_max - in_min) + out_min
        if (x > 1) x = 1
        if (x < 0) x = 0

        avg = x * 255

        // find the closest color in the custom palette
        let closestColorIndex = 0;
        for (let j = 0; j < colorstops.length - 1; j++) {
            if (avg >= (j * 255 / (colorstops.length - 1)) && avg <= ((j + 1) * 255 / (colorstops.length - 1))) {
                closestColorIndex = j;
                break;
            }
        }


        // determine the values for each color channel based on linear interpolation
        let color1 = colorstops[closestColorIndex];
        let color2 = colorstops[closestColorIndex + 1];
        let t = (avg - (closestColorIndex * 255 / (colorstops.length - 1))) / (255 / (colorstops.length - 1));

        imageData.data[i] = color1[0] + (color2[0] - color1[0]) * t;
        imageData.data[i + 1] = color1[1] + (color2[1] - color1[1]) * t;
        imageData.data[i + 2] = color1[2] + (color2[2] - color1[2]) * t;
        //imageData.data[i + 3] = 255

    }
}



function createHistogram(imageData) {
    let histogram = new Array(255).fill(0);
    for (let i = 0; i < imageData.data.length; i += 4) {
        let avg = (imageData.data[i] + imageData.data[i + 1] + imageData.data[i + 2]) / 3;
        let x = avg

        if (imageData.data[i + 3] > 100) {
            histogram[Math.trunc(x)] += 1
        }
    }
    return histogram
}


function clipMask(context, points, width, height) {
    if (points.length > 0) {

        context.beginPath();

        points.forEach(p => {
            var point = p[0];
            context.moveTo(point[0] * width, point[1] * height);
            for (var i = 1; i < p.length; ++i) {
                point = p[i];
                context.lineTo(point[0] * width, point[1] * height);
            }
        });

        context.closePath();
        context.clip();

    }
}


function drawHistogram( colorstops, ctx, width, height, minValue, maxValue, histogram,ypos=0,legendColor="white") {

    ctx.save()
    ctx.translate(0, ypos);

    // ctx.clearRect(0, 0,  width, height);
    const grd = ctx.createLinearGradient(0, 0, width, 0);
    grd.addColorStop(0, "black");
    grd.addColorStop(1, "white");
    ctx.fillStyle = grd;
    ctx.fillRect(0, height-10, width, 10);

    ctx.save();
    let yoffset = height-15
    if(histogram.length>0){

        let max = Math.max(...histogram)

        let factorY = -(yoffset/max)
        let factorx = width/255
        
        ctx.beginPath();
        ctx.moveTo(0, histogram[0]*factorY+yoffset);

        for (var i = 1; i < histogram.length; ++i) {
            ctx.lineTo(i * factorx, histogram[i] * factorY+yoffset);
        }

        ctx.lineTo(254 * factorx, yoffset);
        ctx.lineTo(0, yoffset);
        ctx.closePath();
        ctx.clip();
    }
    ctx.fillRect(0, 0, width, yoffset);
    ctx.restore()

    const imageData = ctx.getImageData(0, ypos, width, height);
    colorToPalett(imageData, minValue, maxValue, colorstops)
    ctx.putImageData(imageData, 0, ypos);
  

    //linha máxima e minima
    ctx.beginPath();
    ctx.moveTo(maxValue*width, 0);
    ctx.lineTo(maxValue*width, height);
    ctx.moveTo(minValue*width, 0);
    ctx.lineTo(minValue*width, height);

    ctx.save();
    ctx.strokeStyle = "blue";
    // Draw the Path
    ctx.shadowColor = "rgba(160,160,255, 0.5)";
    ctx.shadowOffsetX = 1; 
    ctx.shadowOffsetY = 0;
    ctx.stroke();
    ctx.restore();


    ctx.strokeStyle = legendColor;
    ctx.fillStyle = legendColor;
    ctx.font = "14px Arial";

    let centerValue = (maxValue+minValue)/2
    let values = [maxValue,minValue]

    if( Math.abs(maxValue -minValue) >0.3)
        values.push(centerValue)

    values.forEach(e=>{
        ctx.fillText(e.toFixed(2) ,e*width -e*30,height+20);
        ctx.beginPath();
        ctx.moveTo(e*width, height-10);
        ctx.lineTo(e*width, height+5);
        ctx.stroke();
    })

    ctx.restore()
}

export {
    colorToPalett,
    createHistogram,
    clipMask,
    drawHistogram
}